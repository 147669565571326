import { Enum, EnumValue } from "../../../utils/enum";
import { ChannelKindEnum } from "../ChannelKindEnum";

type ValueIconSupplier = Maybe<(value:any) => string>;

export class StandardMetadata extends EnumValue {
  public static prefix = 'metadata.standard.';

  public readonly fieldName:string;
  public readonly valueIconSupplier:Maybe<ValueIconSupplier>;

  /**
   * @param {string} fieldSuffix
   * @param {function} valueIconSupplier
   */
  constructor(fieldSuffix:string, valueIconSupplier:Maybe<ValueIconSupplier> = null) {
    super();
    this.fieldName = `${StandardMetadata.prefix}${fieldSuffix}`;
    this.valueIconSupplier = valueIconSupplier;
  }

  getIconForValue(value):Maybe<string> {
    return this.valueIconSupplier ? this.valueIconSupplier(value) : null;
  }

  getFieldName():string {
    return this.fieldName;
  }

  static findByFieldName(fieldName:string):Maybe<StandardMetadata> {
    return StandardMetadataEnum.values.find(sf => sf.fieldName === fieldName);
  }
}

class StandardMetadataType extends Enum<StandardMetadata> {
  CHANNEL_KIND = new StandardMetadata('channelKind', (v) => {
    const channelKind = ChannelKindEnum.valueOf(v);
    return channelKind ? channelKind.icon : null;
  });
  CHANNEL_NAME = new StandardMetadata('channelName');

  constructor() {
    super();
    this.initEnum('StandardMetadata');
  }
}

export const StandardMetadataEnum = new StandardMetadataType();
