import { toArray } from './collectionUtils';
import _ from 'lodash';

export const forEachNodes = (nodes: Array<any>, nodeChildrenGetter, fn: (node: any, i: number, depth: number) => boolean|void, depth:number = 0) => {
  nodes.some((node, i) => {
    const result = fn(node, i, depth);

    if (result === false) {
      return true;
    }

    if (hasChildren(node, nodeChildrenGetter)) {
      forEachNodes(nodeChildrenGetter(node), nodeChildrenGetter, fn, depth + 1)
    }
  });
};

export const forEachLeaves = (nodes, nodeChildrenGetter, fn, depth = 0) => {
  const array = toArray(nodes);

  array.forEach((node, i) => {
    if (isLeaf(node, nodeChildrenGetter)) {
      fn(node, i, depth);
    } else {
      forEachLeaves(nodeChildrenGetter(node), nodeChildrenGetter, fn, depth + 1)
    }
  });
};

export const mapTreeNodes = (nodes:Array<any>, nodeChildrenGetter, nodeChildrenSetter, fn: (node:any, index:number, depth:number )=> any, depth = 0) => {
  return nodes.map((node, i) => {
    const mappedNode = fn(node, i, depth);

    if (mappedNode && hasChildren(node, nodeChildrenGetter)) {
      nodeChildrenSetter(mappedNode, mapTreeNodes(nodeChildrenGetter(node), nodeChildrenGetter, nodeChildrenSetter,
        fn, depth + 1));
    }

    return mappedNode;
  });
};

export const filterTreeNodes = (nodes, nodeChildrenGetter, fn) => {
  const result = [];

  forEachNodes(nodes, nodeChildrenGetter, (node, i, depth) => {
    if (fn(node, i, depth)) {
      result.push(node);
    }
  });

  return result;
};

export const findFirstTreeNode = (nodes, nodeChildrenGetter, fn) => {
  let result = undefined;

  forEachNodes(nodes, nodeChildrenGetter, (node, i, depth) => {
    if (fn(node, i, depth)) {
      result = node;
      return false;
    }
  });

  return result;
};

export const getTreeLeaves = (node, nodeChildrenGetter, includeMyself) => {
  const result = [];
  if (isLeaf(node, nodeChildrenGetter)) {
    if (includeMyself) {
      result.push(node);
    }
    return result;
  }

  return filterTreeNodes(nodeChildrenGetter(node), nodeChildrenGetter, n => isLeaf(n, nodeChildrenGetter));
};

export const flattenTreeNodes = (nodes, nodeChildrenGetter) => {
  const result = [];

  forEachNodes(nodes, nodeChildrenGetter, (node) => {result.push(node)});

  return result;
};

export const getTreeNodesButLeaves = (node, nodeChildrenGetter, includeMyself) => {
  const result = [];
  if (!isLeaf(node, nodeChildrenGetter)) {
    if (includeMyself) {
      result.push(node);
    }

    forEachNodes(nodeChildrenGetter(node), nodeChildrenGetter, n => {
      if (!isLeaf(n, nodeChildrenGetter)) {
        result.push(n);
      }
    });
  }

  return result;
};

export const hasChildren = (node, nodeChildrenGetter: (node: any) => OneOrMany<any>): boolean => {
  const children = nodeChildrenGetter(node);
  return !_.isNil(children) && _.isArray(children) && !_.isEmpty(children);
};

export const isLeaf = (node:any, nodeChildrenGetter: (node: any) => OneOrMany<any>) => {
  return !hasChildren(node, nodeChildrenGetter);
};

export const calculateDepth = (node:any, nodeChildrenGetter: (node: any) => OneOrMany<any>) => {
  return innerDepth(node, nodeChildrenGetter);
};

const innerDepth = (node:any, nodeChildrenGetter: (node: any) => OneOrMany<any>, currentDepth: number = 1) => {
  if (hasChildren(node, nodeChildrenGetter)) {
    return _.max(nodeChildrenGetter(node).map(child => innerDepth(child, nodeChildrenGetter, currentDepth + 1)));
  }
  return currentDepth;
};
