import { toArray } from '../../utils/collectionUtils';
import { AbstractFilterCtrl, TagValue } from './AbstractFilterCtrl';
import _ from "lodash";
import { StateConverter } from "./stateConverters/StateConverter";
import { BoolOperator, BoolOperatorEnum } from "./utils/BoolOperatorEnum";
import { TFunction } from "i18next";

export class CompositeFilterCtrl extends AbstractFilterCtrl<Array<any>> {
  private _filters: Array<AbstractFilterCtrl<any>> = []
  private readonly _boolOperator;

  /**
   * @param {string} id
   * @param {BoolOperator} boolOperator
   */
  constructor(id, boolOperator = BoolOperatorEnum.MUST) {
    super(id);
    this._boolOperator = boolOperator;
  }

  get filters() {
    return this._filters;
  }

  get boolOperator():BoolOperator {
    return this._boolOperator;
  }

  getValue():Array<any> {
    const values = this._filters.map(f => f.getValue()).filter(a => !_.isNil(a));
    if (_.isEmpty(values)) {
      return null;
    }
    return values;
  }

  setValue(value) {
    throw new Error('Use add method instead')
  }

  add(filter:OneOrMany<AbstractFilterCtrl<any>>):CompositeFilterCtrl {
    if (_.isNil(filter)) {
      throw new Error('Cannot add nil filter to CompositeFilterCtrl');
    }

    const filters = toArray(filter);

    if (!_.isEmpty(filters)) {
      this._filters = this._filters.concat(filters);
    }
    return this;
  }

  stateConverter(): StateConverter<Array<any>> {
    throw new Error('No StateConverter for a CompositeFilterCtrl');
  }

  getTagValues(t: TFunction): Array<TagValue<any>> {
    return undefined;
  }
}