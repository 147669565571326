import _ from 'lodash';
import { feedbacksListState } from '../../../../application/store/state/tools/feedbacksListState';
import { SatTypeAspect } from '../../../../utils/query/aspect/SatTypeAspect';
import { TopicAndPolarityAspect } from '../../../../utils/query/aspect/TopicAndPolarityAspect';
import { parseFilter } from '../../../../utils/query/filter/parseFilter';
import { Highlight } from '../../../../utils/query/Highlight';
import { getEditedViz } from '../../../state/selectors/dashboardSelector';
import { actionNamespacer } from '../../../state/utils/actionNamespacer';

//---

const restoreInternalState = (namespace, state) => {
  const storedInternalState = _.get(state, ['internalState', namespace]) || {};
  state.currentTopicIndex = storedInternalState.currentTopicIndex || 0;
  state.page = storedInternalState.page || 1;
  state.pageIndexes = storedInternalState.pageIndexes || {};
};

const saveInternalState = (namespace, state) => {
  _.set(state, ['internalState', namespace], {
    currentTopicIndex: state.currentTopicIndex,
    page: state.page,
    pageIndexes: state.pageIndexes
  });
};

//---

export const topicSatScoreEditorStateName = 'topicSatScoreEditor';

export const stateSlice = (rootState) => {
  return rootState[topicSatScoreEditorStateName];
};

export const dispatchSlice = (dispatch) => {
  return dispatch[topicSatScoreEditorStateName];
};

const fls = feedbacksListState(topicSatScoreEditorStateName, null,{
  queryBuilderDecorator(query, rootState) {
    const viz = getEditedViz(rootState);
    const state = stateSlice(rootState);

    const vizParams = viz.definition.params;
    const topic = viz.data[state.currentTopicIndex].topic;

    query.filter = vizParams.constraint ? parseFilter(vizParams.constraint) : null;
    query.addAspect(new SatTypeAspect(vizParams.satType));
    query.addAspect(new TopicAndPolarityAspect(vizParams.satType, topic, vizParams.polarity));
    query.highlight = new Highlight(true, ['topic']);

    return query;
  },
  includeGlobalFilter: true,
  namespaced: true,
  async afterPageChange(payload, rootState, dispatch) {
    await dispatch.setPageIndexAt(payload.data);
    await dispatch.saveInternalState(payload);
  },
});

export const topicSatScoreEditorState = () => ({
  state: { pageIndexes: {}, currentTopicIndex: 0, ...fls.state },
  reducers: {
    reset(state, payload) {
      state.currentTopicIndex = 0;
      state.page = 1;
      state.pageIndexes = {};
      delete state.internalState;
      return state;
    },
    restoreInternalState(state, payload) {
      restoreInternalState(payload.namespace, state);
      return state;
    },
    saveInternalState(state, payload) {
      saveInternalState(payload.namespace, state);
      return state;
    },
    setPageIndexAt(state, payload) {
      state.pageIndexes[state.currentTopicIndex] = payload;
      return state;
    },
    setCurrentTopicIndex(state, payload) {
      state.currentTopicIndex = payload;
      return state;
    },
    ...fls.reducers
  },
  effects: (dispatch) => ({
    async init(payload, rootState) {
      dispatchSlice(dispatch).restoreInternalState(payload);
      const viz = getEditedViz(rootState);

      if (viz.hasData()) {
        await dispatchSlice(dispatch).fetchFeedbacks();

        const preselectedFeedbacks = _.map(viz.data, (data) => {
          return {
            kind: 'data',
            key: `feedbacks.${data.topic}`,
            value: data.feedback
          };
        });
        dispatch.dashboardVizEditor.setEditValues(preselectedFeedbacks);
      } else {
        dispatchSlice(dispatch).setSearchResult(null);
      }
    },
    async changeCurrentTopicIndex(payload, rootState) {
      const currentTopicIndex = stateSlice(rootState).currentTopicIndex;
      const newTopicIndex = payload.data;
      if (currentTopicIndex !== newTopicIndex) {
        dispatchSlice(dispatch).setCurrentTopicIndex(newTopicIndex);
        dispatchSlice(dispatch).saveInternalState(payload);

        const pageIndex = stateSlice(rootState).pageIndexes[newTopicIndex] || 1;

        await dispatchSlice(dispatch).changePage(actionNamespacer(payload.namespace)(pageIndex));
      }
    },
    ...fls.effects(dispatch)
  })
});
