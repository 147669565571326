import { Tonality } from '@eptica/vecko-js-commons';
import { SatTypeEnum } from '../../../application/model/SatTypeEnum';
import { SearchAspect } from './SearchAspect';
import _ from "lodash";

export class TopicAndPolarityAspect extends SearchAspect {
  static parse(object) {
    return new TopicAndPolarityAspect(object.satType, object.topic, object.polarity);
  }

  /**
   * @type {SatType}
   */
  _satType;

  /**
   * @type {string}
   */
  _topic;

  /**
   * @type {Tonality}
   */
  _polarity;

  constructor(satType, topic, polarity) {
    super('topicAndPolarity');
    this._satType = _.isString(satType) ? SatTypeEnum.valueOf(satType) : satType;
    this._topic = topic;
    this._polarity = _.isString(polarity) ? Tonality.getValueOf(polarity) : polarity;
  }

  get polarity() {
    return this._polarity;
  }

  toPlainObject() {
    if (_.isNil(this._satType)) {
      throw new Error('satType cannot be nil')
    }

    if (_.isNil(this._topic)) {
      throw new Error('topic cannot be nil')
    }

    if (_.isNil(this._polarity)) {
      throw new Error('polarity cannot be empty')
    }

    if (this._polarity === Tonality.NEUTRAL) {
      throw new Error('NEUTRAL is not a Polarity')
    }

    const result:any = super.toPlainObject();
    result.satType = this._satType.name;
    result.topic = this._topic;
    result.polarity = this._polarity.name();

    return result;
  }
}