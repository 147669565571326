import { Button } from '@blueprintjs/core';
import { Tooltip } from "@blueprintjs/core";
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { services } from '../../application/service/services';
import { DateRangeLabel } from '../../component/DateRangeLabel';
import "./visualization.scss";
import classnames from 'classnames';
import { VisualizationInstance } from "../model/viz/VisualizationInstance";
import { getVisualizationTheme, VizThemeDefinition } from "./utils/visualizationTheme";
import { SmartIcon } from "../../component/icon/SmartIcon";
import html2canvas from "html2canvas";
import { connect } from "react-redux";
import { getDashboardResolvedParams } from "../state/selectors/dashboardSelector";
import { ResolvedParams } from "../model/params/resolve/ResolvedParams";

interface VisualizationComponentProps extends WithTranslation {
  viz: VisualizationInstance
  editable: boolean,
  resolvedParams: ResolvedParams,
  onStartEdit: () => void
}

class VisualizationComponent extends React.PureComponent<VisualizationComponentProps> {
  private element: HTMLDivElement;

  private ref;

  renderViz() {
    const { viz } = this.props;

    if (viz.hasData()) {
      const VizComponent = viz.definition.getComponent();
      return <VizComponent viz={viz} data={viz.data} ref={(el) => this.ref = el}/>;
    } else {
      const NoDataComponent = viz.definition.type.getNoDataComponent();
      return <NoDataComponent/>;
    }
  }

  capture = () => {
    const { viz } = this.props;

    const title = document.getElementById(`${viz.id}-title`).textContent;

    const noPrintElements = Array.from(this.element.getElementsByClassName('no-print'));
    noPrintElements.forEach(value => {
      (value as any).style.display = 'none';
    });

    html2canvas(this.element)
      .then(function (canvas) {
        const link = document.createElement('a');
        link.download = title + '.jpg';
        link.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        link.click();
        noPrintElements.forEach(value => {
          (value as any).style.display = '';
        });
      })
      .catch(function (error) {
        noPrintElements.forEach(value => {
          (value as any).style.display = '';
        });
        services.getApplicationService().notifyMessage({
          level: 'ERROR',
          text: {
            key: 'error.runtime',
          },
          error: {
            type: 'RUNTIME',
            error: error
          }
        });
      });
  }

  exportCSV(): void {

    const { t, viz, resolvedParams } = this.props;
    const dataToExport = this.ref.getDataToExport();
    const dataToExportAsString = dataToExport.map(line => line.join(',')).join("\n");
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(dataToExportAsString);
    hiddenElement.target = '_blank';

    //provide the name for the CSV file to be downloaded
    let title = viz.getTitle(t, resolvedParams).replace(" ", "-")
    hiddenElement.download = title + '.csv';
    hiddenElement.click();
  }

  renderHelp() {
    const { viz } = this.props;
    const help = services.getI18nService().translateSilently(`dashboard.viz.${viz.id}.description.short`, null)

    if (!help) {
      return null;
    }

    return <Tooltip content={<div style={{ maxWidth: 300 }}>{help}</div>}>
      <div className="visualizationComponent-header-helpButton no-print"><SmartIcon icon='info-sign'/></div>
    </Tooltip>;
  }

  render() {
    const { t, editable, onStartEdit, viz, resolvedParams } = this.props;

    const showDateRange = viz.definition.uiParams.showDateRange;

    const theme = getVisualizationTheme(viz.definition.uiParams.theme as VizThemeDefinition);
    const className = classnames('visualizationComponent', theme.isDark ? 'vizDarkTheme' : null);

    const style = {
      ...viz.definition.style || {},
      backgroundColor: theme.color
    }
    const component = this.renderViz();

    return <div className={className} style={style} ref={instance => this.element = instance}>
      <div className='visualizationComponent-header'>
        <div className="visualizationComponent-header-title" id={`${viz.id}-title`}>
          {viz.getTitle(t, resolvedParams)}
        </div>
        {
          showDateRange ? <div className="visualizationComponent-header-dateRange">
            <DateRangeLabel dateRange={services.getDashboardParamsService().getDateRange(viz)} format={'dd/MM/yyyy'}/>
          </div> : null
        }
        {
          editable ? <Button icon='edit' className="visualizationComponent-header-editButton no-print" minimal={true}
                             onClick={onStartEdit}/> : null
        }

        <Button icon='document-share' className="visualizationComponent-header-captureButton no-print" minimal={true}
                onClick={this.capture}/>
        {
          viz.isExportable() ?
            <Button icon='export' className="visualizationComponent-header-exportButton no-print"
                    minimal={true}
                    onClick={this.exportCSV.bind(this)}/> : null
        }
        {
          this.renderHelp()
        }
      </div>

      <div className='visualizationComponent-content'>
        {
          component
        }
      </div>
    </div>;

  }
}

const mapStateToProps = (state) => {
  return {
    resolvedParams: getDashboardResolvedParams(state),
  };
};

export const Visualization = withTranslation()(connect(mapStateToProps, null)(VisualizationComponent));

