import { FieldParamValue } from '../values/FieldParamValue';
import { ListParamValue } from '../values/ListParamValue';
import { NoneParamValue } from '../values/NoneParamValue';
import { SatTypeParamValue } from '../values/SatTypeParamValue';
import { SwitchParamValue } from '../values/SwitchParamValue';
import { ParamValue } from "../values/ParamValue";
import _ from "lodash";
import { ParseError } from "../../../../utils/errors/ParseError";
import { FieldValueParamValue } from "../values/FieldValueParamValue";
import { DateRangeParamValue } from "../values/DateRangeParamValue";

export const parseParamValue = (object: any): ParamValue => {
  if(_.isNil(object))return null;
  const type = object['@type'];

  if (_.isNil(type)) {
    throw new Error('No @type defined when parsing ParamValue');
  }

  if (type === 'field') {
    return FieldParamValue.parse(object);
  }
  if (type === 'fieldValue') {
    return FieldValueParamValue.parse(object);
  }
  if (type === 'list') {
    return ListParamValue.parse(object);
  }
  if (type === 'none') {
    return NoneParamValue.parse(object);
  }
  if (type === 'satType') {
    return SatTypeParamValue.parse(object);
  }
  if (type === 'switch') {
    return SwitchParamValue.parse(object);
  }
  if (type === 'dateRange') {
    return DateRangeParamValue.parse(object);
  }

  throw new ParseError(`Cannot parse object with type ${type} into ParamValue`);
};