import {
  findFirstTreeNode,
  flattenTreeNodes,
  getTreeLeaves,
  getTreeNodesButLeaves,
  isLeaf
} from '../../../utils/treeUtils';
import { services } from '../../service/services';
import _ from "lodash";
import { Field } from "./Field";
import { TFunction } from "i18next";

export class FieldValue {
  public static equalityTester: EqualComparator<FieldValue> = (fieldValue1, fieldValue2): boolean => {
    if (_.isNil(fieldValue1)) {
      return _.isNil(fieldValue2);
    }
    return fieldValue1.equals(fieldValue2);
  };

  public static comparator: Comparator<FieldValue> = (fieldValue1, fieldValue2): number => {
    const safeLabel = (fieldValue) => (fieldValue ? fieldValue.getLabel() : "").toLowerCase();

    return safeLabel(fieldValue1).localeCompare(safeLabel(fieldValue2), undefined, { ignorePunctuation: true });
  };

  public static nodeChildrenGetter = node => node._children;

  private readonly _field: Field;
  private readonly _name: string;
  private readonly _fullName: string;
  private readonly _id: string;
  private readonly _parent: Maybe<FieldValue>;
  private readonly _path: Array<FieldValue>;
  private readonly _level: number;
  private readonly _children: Array<FieldValue> = [];
  private readonly _color;

  constructor(field: Field, fieldConfiguration: Vecko.FieldValueConfiguration, parent?: Maybe<FieldValue>, color?: string) {
    this._field = field;
    this._name = fieldConfiguration.name;
    this._color = fieldConfiguration.color;


    // parent must be on the same field
    if (!_.isNil(parent)) {
      if (!(parent instanceof FieldValue)) {
        throw new TypeError('the given parent must be a FieldValue');
      }
      if (parent.field !== field) {
        throw new Error(`the given parent must be on the same Field instance.`);
      }
    }

    this._parent = parent;
    this._fullName = this._parent ? `${this._parent.fullName}/${this.name}` : this.name;
    this._id = `${this.field.name}/${this._fullName}`;
    this._level = this._parent ? this._parent.level + 1 : 0;
    this._path = this._parent ? this._parent.path.concat([this]) : [this];
    if (this._parent) {
      if (this._parent.containsChild(this)) {
        throw new Error('The given parent already contains this value');
      }
      this._parent.children.push(this);
    }
    this._parent = parent;
  }

  get field(): Field {
    return this._field;
  }

  get name(): string {
    return this._name;
  }

  get color(): string {
    return this._color;
  }

  getId(): string {
    return this._id;
  }

  getLabel(t: TFunction): string {
    return services.getI18nService().translateSilently(this.getId(), this._name);
  }

  sort(): void {
    this._children.sort(FieldValue.comparator);
    this._children.forEach(child => child.sort());
  }

  equals(fieldValue: FieldValue): boolean {
    if (_.isNil(fieldValue)) {
      return false;
    }
    if (!(fieldValue instanceof FieldValue)) {
      return false;
    }

    return fieldValue.getId() === this.getId();
  }

  addChild(fieldConfiguration: Vecko.FieldValueConfiguration): FieldValue {
    return new FieldValue(this.field, fieldConfiguration, this);
  }

  get path(): Array<FieldValue> {
    return this._path;
  }

  getNamesPath(): Array<string> {
    return this.path.map(t => t.name);
  }

  get level(): number {
    return this._level;
  }

  get parent(): Maybe<FieldValue> {
    return this._parent;
  }

  get children(): Array<FieldValue> {
    return this._children;
  }

  get fullName(): string {
    return this._fullName;
  }

  hasParent(): boolean {
    return !_.isNil(this._parent);
  }

  isLeaf(): boolean {
    return isLeaf(this, FieldValue.nodeChildrenGetter);
  }

  getLeaves(includeMyself: boolean = false): Array<FieldValue> {
    return getTreeLeaves(this, FieldValue.nodeChildrenGetter, includeMyself);
  }

  getAllButLeaves(includeMyself: boolean = false): Array<FieldValue> {
    return getTreeNodesButLeaves(this, FieldValue.nodeChildrenGetter, includeMyself);
  }

  flattenValues(includeMyself: boolean = false): Array<FieldValue> {
    return flattenTreeNodes(includeMyself ? [this] : this.children, FieldValue.nodeChildrenGetter)
  }

  containsChild(fieldValue: FieldValue, deep: boolean = false): boolean {
    if (_.isNil(fieldValue)) {
      return false;
    }

    return this.containsWhere(fv => fv.equals(fieldValue), deep, false);
  }

  containsWhere(fn: (fieldValue: FieldValue) => boolean, deep: boolean = false, includeMyself: boolean = false): boolean {
    if (includeMyself && fn(this)) {
      return true;
    }

    if (!_.isNil(this._children.find(fn))) {
      return true;
    }

    if (deep) {
      const foundedNode = findFirstTreeNode(this._children, FieldValue.nodeChildrenGetter, fn);
      return !_.isNil(foundedNode);
    }

    return false;
  }

  toString(): string {
    return this.getId();
  }
}