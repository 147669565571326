import axios from 'axios';


const purgeAll = async () => {
  return axios
    .post(`/api/purge/`, {type:'BY_DATES', end: null},{
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
};

const purgeErrors = async () => {
  return axios
    .post(`/api/purge/errors`, {},{
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
};

const purgeByDate = async (value) => {
  return axios
    .post(`/api/purge/_by_date`, value,{
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
};

const purgeByConfiguration = async () => {
  return axios
    .post(`/api/purge/_by_configuration`, null,{
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
};

const purgeByDuration = async (value) => {
  return axios
    .post(`/api/purge/_by_retention`, value,{
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
};

export default { purgeAll, purgeByDate, purgeByDuration, purgeByConfiguration, purgeErrors };