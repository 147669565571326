import { FieldDisplay } from '../../../application/model/field/FieldDisplay';
import { SortOrderEnum } from '../../../utils/query/SortOrder';
import { services } from '../../../application/service/services';
import { feedbacksListState } from '../../../application/store/state/tools/feedbacksListState';
import { FeedbacksListViewModeEnum } from "../../../application/model/FeedbacksListViewModeEnum";

const fls = feedbacksListState('topicsStat', FieldDisplay.FieldDisplayKind.TOPICS_STAT_VIEW,
  { queryBuilderDecorator: query => services.getTopicsStatService().decorateQuery(query) });

const defaultOrder = {
  column: 'volume',
  direction: SortOrderEnum.DESC.name,
};

export const topicsStatState = () => ({
  state: {
    ...fls.state,
    isRightPaneOpen: false,
    orderColumn: defaultOrder.column,
    orderDirection: defaultOrder.direction,
    distributionKind: services.getTopicsStatService().distributionKind,
    expansionState: [],
    sequences: [],
    rightPaneMode: FeedbacksListViewModeEnum.FEEDBACKS
  },
  reducers: {
    setSelectedStat(state, payload) {
      state.selectedStat = payload;
      return state;
    },
    setDistributionItem(state, payload) {
      state.distributionItem = payload;
      return state;
    },
    setDistributionKind(state, payload) {
      state.distributionKind = payload;
      delete state.distributionItem;
      return state;
    },
    setRightPanelOpen(state, payload) {
      state.isRightPaneOpen = payload;
      return state;
    },
    ...fls.reducers,
    sort(state, payload) {
      state.orderColumn = payload.column;
      state.orderDirection = payload.direction;
      return state;
    },
    expandAll(state) {
      state.expansionState = services.getTopicsStatService().getTopicsStats(true)
        .filter(ts => ts.hasChildren())
        .map(ts => ts.id);
      return state;
    },
    expand(state, payload) {
      if (!state.expansionState.includes(payload)) {
        state.expansionState = [...state.expansionState, payload];
      }
      return state;
    },
    collapseAll(state) {
      state.expansionState = [];
      return state;
    },
    collapse(state, payload) {
      state.expansionState = state.expansionState.filter(i => i !== payload);
      return state;
    },
    setSequences(state, payload) {
      state.sequences = payload;
      return state;
    },
    setRightPaneMode(state, payload) {
      state.rightPaneMode = payload;
      return state;
    }
  },
  effects: (dispatch) => {
    const fetch = async () => {
      await services.getTopicsStatService().refreshTopicsStats();
      if (services.getTopicsStatService().feedbacksCount > 0) {
        await dispatch.topicsStat.fetchFeedbacks(true);
        dispatch.topicsStat.setPage(1);

        await dispatch.topicsStat.fetchSequences();
      } else {
        dispatch.topicsStat.setSearchResult(null);
      }
    };

    return ({
      async refresh() {
        const distributionKinds = services.getTopicsStatService().getDistributionKinds();
        if (!distributionKinds.map(dk => dk.getId()).includes(
          services.getTopicsStatService().distributionKind.getId())) {
          services.getTopicsStatService().distributionKind = distributionKinds[0];
          dispatch.topicsStat.setDistributionKind(services.getTopicsStatService().distributionKind);
        }
        await fetch();
      },
      async resetSelection() {
        services.getTopicsStatService().setSelectedStat(null);
        dispatch.topicsStat.setSelectedStat(null);
        await dispatch.topicsStat.setRightPanelOpen(false);
        await dispatch.topicsStat.fetchFeedbacks(true);
        dispatch.topicsStat.setPage(1);

        await dispatch.topicsStat.fetchSequences();
      },
      async changeDistributionKind(payload, rootState) {
        const currentValue = rootState.topicsStat.distributionKind;
        const currentId = currentValue ? currentValue.getId() : '';
        const newId = payload ? payload.getId() : '';
        if (currentId !== newId) {
          services.getTopicsStatService().distributionKind = payload;
          dispatch.topicsStat.setDistributionKind(payload);
          dispatch.topicsStat.sort(defaultOrder);

          await fetch();
        }
      },
      async fetch() {
        await fetch();
      },
      async fetchSequences() {
        dispatch.topicsStat.setSequences(await services.getTopicsStatService().getSequences());
      },
      async selectStat(payload) {
        services.getTopicsStatService().setSelectedStat(payload);
        dispatch.topicsStat.setSelectedStat(payload);

        if (services.getTopicsStatService().selectedStat.hasChildren()) {
          dispatch.topicsStat.expand(payload);
        }

        await dispatch.topicsStat.fetchFeedbacks(true);
        await dispatch.topicsStat.fetchSequences();
        await dispatch.topicsStat.setRightPanelOpen(true);
      },
      async changeDistributionItem(payload, rootState) {
        if (rootState.topicsStat.distributionItem !== payload) {
          dispatch.topicsStat.setDistributionItem(payload);
          services.getTopicsStatService().distributionItem =
            payload ? rootState.topicsStat.distributionKind.findItemById(payload) : undefined;
          await dispatch.topicsStat.fetchFeedbacks(true);
          await dispatch.topicsStat.fetchSequences();
        }
      },
      async save(payload, rootState){
        await services.getTopicsStatService().save();
      },
      ...fls.effects(dispatch),
    });
  }
});

