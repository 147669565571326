import { init } from '@rematch/core';
import immerPlugin from '@rematch/immer';
import createLoadingPlugin from '@rematch/loading';
import selectPlugin from '@rematch/select';
import { administrationState } from '../../administration/state/administrationState';
import { createMessagePlugin } from './plugin/messagePlugin';
import { getVizEditorStates } from '../../report/model/viz/visualizationsManager';
import { dashboardDataState } from '../../report/state/dashboardDataState';
import { dashboardExportState } from '../../report/state/dashboardExportState';
import { dashboardParamsState } from '../../report/state/dashboardParamsState';
import { dashboardState } from '../../report/state/dashboardState';
import { dashboardVizEditorState } from '../../report/state/dashboardVizEditorState';
import { analysisState } from './state/analysisState';
import { applicationState } from './state/applicationState';
import { feedbacksState } from './state/feedbacksState';
import { filterState } from './state/filterState';
import { messagesState } from './state/messagesState';
import { topicsStatState } from '../../explore/topicsStat/state/topicsStatState';
import { storageState } from '../../administration/state/storageState';
import { systemState } from "../../administration/state/systemState";
import { cacheState } from "../../administration/state/cacheState";
import { purgeState } from "../../administration/state/purgeState";
import { sourcingState } from "../../administration/state/sourcingState";
import { progressState } from "../../administration/state/progressState";
import { tenantState } from "../../administration/state/tenantState";
import { gitConfigurationManagerState } from "../../administration/state/gitConfigurationManagerState";
import { configurationEditorState } from "../../administration/state/configurationEditorState";
import { exportState } from "../../administration/state/exportState";
import { dataIntegrationState } from "../../administration/state/dataIntegrationState";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";


const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});
export const createReduxHistoryFn = createReduxHistory;

// plugins
const immer = immerPlugin();
const loading = createLoadingPlugin();
const select = selectPlugin();
const message = createMessagePlugin();

export const createStore = () => {
  const config = {
    models: {
      application: applicationState(),
      filter: filterState(),
      topicsStat: topicsStatState(),
      analysis: analysisState(),
      feedbacks: feedbacksState(),
      dashboard: dashboardState(),
      dashboardParams: dashboardParamsState(),
      dashboardData: dashboardDataState(),
      dashboardVizEditor: dashboardVizEditorState(),
      dashboardExport: dashboardExportState(),
      messages: messagesState(),
      ...Object.fromEntries(getVizEditorStates().map(state => [state.name, state.create()])),
      administration: administrationState(),
      storage: storageState(),
      system: systemState(),
      cache: cacheState(),
      purge: purgeState(),
      sourcing: sourcingState(),
      progress: progressState(),
      tenant: tenantState(),
      gitConfigurationManager: gitConfigurationManagerState(),
      configurationEditor: configurationEditorState(),
      export: exportState(),
      dataIntegration: dataIntegrationState(),
    },
    redux: {
      reducers: {
        router: routerReducer
      },
      middlewares: [routerMiddleware]
    },
    plugins: [immer, loading, select, message]
  };
  return init(config);
};
