export class SearchAspect {
  /**
   * @type {string}
   */
  type;

  /**
   * @param {string} type
   */
  constructor(type) {
    this.type = type;
  }

  toPlainObject() {
    return {
      type: this.type
    }
  }
}