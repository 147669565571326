import React from 'react';
import PropTypes from 'prop-types';
import './actionListViz.scss';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';

// const EmptyActionList = () => {
//   const { t } = useTranslation();
//
//   return <div className="emptyActionList">
//     <span dangerouslySetInnerHTML={{__html: t('dashboard.viz.actionList.emptyLabel')}} />
//   </div>;
// };
//
// const NotEmptyActionList = ({ data }) => {
//   return <React.Fragment>
//     {
//       data.map((d, index) => <div key={index}>N°{index + 1} : <b>{d}</b></div>)
//     }
//   </React.Fragment>;
// };

export const ActionListViz = ({ viz }) => {
  return <div className="action-list">
    {
      viz.data.map((d, index) => <div key={index}>N°{index + 1} : <span className='action-list-item'>{d}</span></div>)
    }
  </div>;
};

ActionListViz.propTypes = {
  viz: PropTypes.instanceOf(VisualizationInstance).isRequired
};
