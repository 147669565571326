import _ from 'lodash';
import { parseParamValue } from './parse/parseParamValue';
import { Param } from './Param';
import { ListParamValue } from './values/ListParamValue';
import { NoneParamValue } from './values/NoneParamValue';
import { ParamValue } from "./values/ParamValue";
import { firstItem, toArray } from "../../../utils/collectionUtils";

export class SelectorParam<T extends ParamValue> extends Param<T> {
  static parse<T extends ParamValue>(reportId: string, object: any): SelectorParam<T> {
    if (_.isEmpty(object.name)) {
      throw new Error('name is mandatory');
    }
    if (_.isNil(object.values)) {
      throw new Error('fields is mandatory');
    }
    return new SelectorParam(reportId, object.name, parseParamValue(object.values), object.allowMultiSelection);
  }

  private readonly values: ParamValue;
  readonly allowMultiSelection: boolean;

  constructor(reportId: string, name, values, allowMultiSelection: boolean = false) {
    super(reportId, 'selector', name);
    this.values = values;
    this.allowMultiSelection = allowMultiSelection;
  }

  resolvePossibleValues(state: Dict<OneOrMany<ParamValue>>): NoneParamValue | ListParamValue<T> {
    const resolvedValues = this.values.resolve(state);

    if (resolvedValues instanceof NoneParamValue) {
      return resolvedValues;
    }

    if (!(resolvedValues instanceof ListParamValue)) {
      throw new Error(`Invalid dashboard param ${this.name} : SelectorParam values should resolve into a ListParamValue`);
    }

    return resolvedValues;
  }

  resolveValue(state: Dict<OneOrMany<ParamValue>>, init: boolean): Maybe<OneOrMany<ParamValue>> {
    const currentValue = this.getValue(state);
    const possibleValues: NoneParamValue | ListParamValue<T> = this.resolvePossibleValues(state);

    if (possibleValues instanceof NoneParamValue) {
      return null;
    }

    if (_.isEmpty(possibleValues.items)) {
      return null;
    }

    let result: OneOrMany<ParamValue>;
    if (!_.isNil(currentValue)) {
      result = possibleValues.findLike(currentValue);
    }

    if (init && _.isEmpty(result) && !this.optional) {
      result = possibleValues.items[0];
    }

    // forge result according to allowMultiSelection prop

    result = toArray(result);
    if (!this.allowMultiSelection) {
      if (result.length > 1) {
        throw new Error(`multi selection is not supported for param ${this.name}`);
      }

      result = firstItem(result);
    }

    return result;
  }

  valueAsPlainObject(value: OneOrMany<T>): any {
    if (this.allowMultiSelection) {
      return toArray(value).map(v => v.getValueAsPlainObject());
    }

    if (_.isArray(value)) {
      throw new Error("Multiple selection is not allowed");
    }

    return (value as T).getValueAsPlainObject();
  }
}