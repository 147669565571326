import axios from 'axios';
import i18next from 'i18next';
import {saveHttpResponseAsFile} from '../../../utils/http/saveHttpResponseAsFile';
import {services} from '../../service/services';

const initiateExport = (exportState, fileName) => {
    const json = JSON.stringify(exportState);
  console.log(json);
    return axios
        .post(`/api/report/export/?lng=${i18next.language}&timeZone=${services.getTimeZoneService().getTimeZone()}`, json, {
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {'Content-type': 'application/json'}
        }).then(function (response) {
            if (response.status === 200) {
              saveHttpResponseAsFile(response, fileName);
              //open file with fit horizontal
              var binaryData = [];
              binaryData.push(response.data);
              const href =  window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}))
              const link = document.createElement('a');
              link.href = href+ "#view=fitH";
              link.setAttribute('target', '_blank');
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            }
        });
};

export default {initiateExport};
