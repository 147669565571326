import { ReactNode } from "react";
import _ from 'lodash';
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart, {TooltipItem, TooltipModel} from "chart.js";
import { VeckoFonts } from "../../style/VeckoFont";

const labelFontSize = 18;


export enum Sort {
  ASC='ASC', DSC="DSC"
}

export abstract class ChartBuilder {

    protected constructor(type: string) {
        this.type = type;
    }

    private _onclick: (event, array, chart) => void;
    _tooltip;
    _showLegend: boolean;
    _legend;
    _displayLabelsInline: boolean;
    _customTooltipCallback: (tooltipCallback: {chart: Chart, tooltip: TooltipModel<any>}) => void;
    type: String;



  onclick(onclick: (event, array , chart) => void) {
    this._onclick = onclick;
    return this;
  }

    tooltip(tooltip) {
        this._tooltip = tooltip;
        return this;
    }

    showLegend(showLegend) {
        this._showLegend = showLegend;
        return this;
    }


    legend(legend) {
        this._legend = legend;
        return this;
    }

    customTooltipCallback(customTooltipCallback) {
        this._customTooltipCallback = customTooltipCallback;
        return this;
    }

    displayLabelsInline(displayLabelsInline) {
        this._displayLabelsInline = displayLabelsInline;
        return this;
    }

    build() {
        const plugins = [];
        const chartOptions: any = {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            },
            plugins: {
                tooltip: {
                    mode: 'index',
                    position: 'top'
                }
            }
        };


        if (this._onclick) {
            chartOptions.onClick = this._onclick;
        }
        if (this._legend) {
            chartOptions.plugins.legend = this._legend;
        }
        if (!_.isNil(this._showLegend)) {
            if (chartOptions.plugins.legend) {
                chartOptions.plugins.legend.display = this._showLegend;
            } else {
                chartOptions.plugins.legend = {display: this._showLegend};
            }
        }

        if (this._tooltip) {
            chartOptions.plugins.tooltip = this._tooltip;
        }
        if (this._customTooltipCallback) {
            chartOptions.plugins.tooltip.enabled = false;
            chartOptions.plugins.tooltip.external = this._customTooltipCallback;
        }
        if (this._displayLabelsInline) {
            plugins.push(ChartDataLabels);

            chartOptions.plugins.datalabels = {
                display: 'auto',
                font: {
                    fontFamily: VeckoFonts.textFont,
                    weight: 800,
                    size: labelFontSize
                }
            };
        }
        return {
            type: this.type,
            options: chartOptions,
            plugins: plugins
        };
        ;
    }

    abstract tooltipRenderer(tooltipItem: TooltipItem<any>): (value: any, tooltipItem?: TooltipItem<any>) => ReactNode ;

    tooltipValueTransformer(value: any) {
        return value;
    }

    getFont():string {
        return "'"+VeckoFonts.textFont+"'"
    }
}