import { arrayRetainAll, equalsInSameOrder } from '../../../../utils/collectionUtils';
import { parseParamValue } from '../parse/parseParamValue';
import { ParamValue } from './ParamValue';
import _ from "lodash";
import { TFunction } from "i18next";
import { NotImplementedError } from "../../../../utils/errors/NotImplementedError";

export class ListParamValue<T extends ParamValue> extends ParamValue {
  static parse<T extends ParamValue>(object: any): ListParamValue<T> {
    return new ListParamValue((object.items || []).map(parseParamValue))
  }

  readonly items: Array<T>;

  constructor(items: Array<T> = []) {
    super('list');
    this.items = items;
  }

  getId(): string {
    throw new NotImplementedError();
  }

  getLabel(t: TFunction): string {
    throw new NotImplementedError();
  }

  getValueAsPlainObject(): any {
    throw new NotImplementedError();
  }

  asPlainObject(): any {
    return {
      ... super.asPlainObject(),
      items: this.items.map(it => it.asPlainObject())
    };
  }

  isValid(): boolean {
    throw new NotImplementedError();
  }

  isEmpty() {
    return _.isEmpty(this.items);
  }

  includes(paramValue: T): boolean {
    return _.isNil(this.findLike(paramValue));
  }

  findLike(paramValue: OneOrMany<T>): Maybe<OneOrMany<T>> {
    if (_.isArray(paramValue)) {
      return arrayRetainAll(this.items, paramValue as Array<T>, (a, b) => a.equals(b));
    } else {
      return this.items.find(it => it.equals(paramValue as T));
    }
  }

  findWhere(fn: (paramValue: T) => boolean): Maybe<T> {
    return this.items.find(fn);
  }

  equals(paramValue: ParamValue) {
    return paramValue instanceof ListParamValue &&
        equalsInSameOrder(this.items, paramValue.items, ParamValue.equalComparator);
  }
}
