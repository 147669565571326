import { ResizeSensor } from '@blueprintjs/core';
import React from 'react';
import _ from "lodash";

export const responsiveWrapper = Component => {
  return class ResponsiveChart extends React.PureComponent<any> {
    state = {
      containerWidth: null,
      containerHeight: null,
      animate: true,
      containerRef: React.createRef<HTMLElement>()
    };

    constructor(props) {
      super(props);

      this.onResize = _.debounce(this.onResize, 10);
    }


    componentDidMount() {
      this.fitToParentContainer();
    }

    fitToParentContainer = () => {
      const { containerWidth, containerHeight } = this.state;
      const boundingClientRect = this.state.containerRef.current.getBoundingClientRect();
      const currentContainerWidth = boundingClientRect.width;
      const currentContainerHeight = boundingClientRect.height;

      const shouldResize = containerWidth !== currentContainerWidth || containerHeight !== currentContainerHeight;

      if (shouldResize) {
        this.setState({
                        containerWidth: currentContainerWidth,
                        containerHeight: currentContainerHeight,
                        animate: true
                      });
      }
    };

    renderChart() {
      const { containerWidth, containerHeight, animate } = this.state;

      return (
        <Component {...this.props}
                   parentWidth={containerWidth}
                   parentHeight={containerHeight}
                   animate={animate}/>
      );
    }

    onResize = (component) => {
      this.setState({
                      containerWidth: component[0].contentRect.width,
                      containerHeight: component[0].contentRect.height,
                      animate: false
                    });
    };

    onResizeDebounced = _.debounce(this.onResize, 50);

    render() {
      const { containerWidth, containerHeight, containerRef } = this.state;
      const shouldRenderChart = containerWidth !== null && containerHeight !== null &&
                                containerWidth > 0 && containerHeight > 0;

      return (
        <ResizeSensor targetRef={containerRef} onResize={this.onResizeDebounced}>
          <div style={{ width: '100%', height: '100%' }} ref={containerRef as any}>
            {shouldRenderChart && this.renderChart()}
          </div>
        </ResizeSensor>
      );
    }
  };
};
