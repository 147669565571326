import { Switch } from '@blueprintjs/core';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { EditorItem } from '../../../component/editor/EditorItem';
import _ from "lodash";
import { parseDistributionKind } from "../../../application/model/distribution/parseDistributionKind";
import { DistributionKindSelector } from "../../../component/selector/DistributionKindSelector";
import { DistributionKind } from "../../../application/model/distribution/DistributionKind";
import { services } from "../../../application/service/services";
import { LabelIcon } from "../../../component/LabelIcon";

interface Model {
  enabled: boolean;
  showNeutral: boolean;
  showPercent: boolean;
  showVolume: boolean;
  showParticipants: boolean;
}

interface SatDistributionEditorProps extends WithTranslation {
  model: Model;
  onChanged: (newModel: Model) => void;
  distributionKind?: DistributionKind;
  displayParticipantsSwitch?: boolean;
  onDistributionKindChanged?: (newDistributionKind: DistributionKind) => void
}

class SatDistributionEditorComponent extends React.PureComponent<SatDistributionEditorProps> {
  onEnabledChanged = () => {
    this.switchProp('enabled');
  };

  onShowNeutralChanged = () => {
    this.switchProp('showNeutral');
  };

  onShowPercentChanged = () => {
    this.switchProp('showPercent');
  };

  onShowVolumeChanged = () => {
    this.switchProp('showVolume');
  };

  onShowParticipantsChanged = () => {
    this.switchProp('showParticipants');
  };

  switchProp(prop) {
    const { model } = this.props;
    this.fireChanged({
      [prop]: !model[prop]
    });
  }

  fireChanged(slice) {
    const { model, onChanged } = this.props;

    const newModel = Object.assign({}, model, slice);
    if (!_.isEqual(model, newModel)) {
      onChanged(newModel);
    }
  }

  render() {
    const { t, model, distributionKind, displayParticipantsSwitch } = this.props;

    return <EditorItem title={t('dashboard.editor.distribution.title')}>
      {
        this.renderDistributionKindSelector(distributionKind)
      }
      <Switch checked={model.enabled}
              label={t('dashboard.editor.distribution.enabled')}
              onChange={this.onEnabledChanged}/>
      <Switch checked={model.showNeutral}
              disabled={!model.enabled}
              label={t('dashboard.editor.distribution.showNeutral')}
              onChange={this.onShowNeutralChanged}/>
      <Switch checked={model.showPercent}
              disabled={!model.enabled}
              label={t('dashboard.editor.distribution.showPercent')}
              onChange={this.onShowPercentChanged}/>
      <Switch checked={model.showVolume}
              disabled={!model.enabled}
              label={t('dashboard.editor.distribution.showVolume')}
              onChange={this.onShowVolumeChanged}/>

    {
    displayParticipantsSwitch ?
    <Switch checked={model.showParticipants}
              disabled={!model.enabled}
              label={t('dashboard.editor.distribution.showParticipants')}
              onChange={this.onShowParticipantsChanged}/>
        : null
    }
    </EditorItem>
  }

  private renderDistributionKindSelector(distributionKind: DistributionKind) {
    if (distributionKind && this.props.onDistributionKindChanged) {
      const distributionKinds = services.getSatService().getDistributionKinds();
      return <div style={{marginBottom: "10px"}}>
        <span style={{paddingRight:"12px"}}>{this.props.t('dashboard.editor.distribution.distributionKind')}</span>
        <DistributionKindSelector items={distributionKinds}
                                       selectedItem={parseDistributionKind(distributionKind)}
                                       onSelectionChanged={this.props.onDistributionKindChanged}
        />
      </div>;
    }
    return null;
  }
}

export const SatDistributionEditor = withTranslation()(SatDistributionEditorComponent);
