import {Button, InputGroup} from '@blueprintjs/core';
import React from 'react';
import {withTranslation} from 'react-i18next';
import './queryStringInput.scss';
import _ from "lodash";
import {IconName} from "@blueprintjs/core/lib/esm/components/icon/icon";

interface QueryStringInputProps {
    value: String,
    icon?: IconName,
    onApply: (value: String) => void,
    expandedWidth: number;
    collapsedWidth: number;
    style: any;
}

class QueryStringInputComponent extends React.PureComponent<QueryStringInputProps> {

    static defaultProps: Partial<QueryStringInputProps> = {
        value: '',
        expandedWidth: 500,
        collapsedWidth: 200
    };

    state = {
        value: '',
        dirty: false,
        expanded: false
    };

    constructor(props) {
        super(props);

        this.state.value = props.value;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setValue(this.props.value);
        }
    }

    onChange = (event) => {
        this.setValue(event.target.value);
    };

    onValidate = () => {
        this.props.onApply(this.getValue());
    };

    onClear = () => {
        this.props.onApply('');
    };

    setValue(value) {
        this.setState({value: value, dirty: value !== this.props.value});
    }

    getValue() {
        return this.state.value;
    }

    isDirty() {
        return this.state.dirty;
    }

    isExpanded() {
        return this.state.expanded;
    }

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.onValidate();
            e.preventDefault();
        }
    };

    onKeyDown = (e) => {
        if (e.key === 'Escape') {
            this.setValue(this.props.value);
            e.preventDefault();
        }
    };

    onFocus = () => {
        this.setState({expanded: true});
    };

    onBlur = () => {
        this.setState({expanded: false});
    };

    getRightElement() {
        if (this.isDirty()) {
            return <Button icon='arrow-right' onClick={this.onValidate} minimal={true}/>;
        }

        if (_.isEmpty(this.getValue())) {
            return null;
        }

        return <Button icon='cross' onClick={this.onClear} minimal={true}/>;
    }

    getWidth() {
        if (this.isExpanded()) {
            return this.props.expandedWidth;
        }

        const value = this.getValue();
        if (value.length > 23) {
            return (value.length + 2) + 'ch';
        }
        return this.props.collapsedWidth;
    }

    render() {

        let style = this.props.style ? this.props.style : {};
        style = {
            ...style,
            width: this.getWidth(),
            maxWidth: this.props.expandedWidth
        };
        return <InputGroup className='vui-queryStringInput' value={this.getValue()}
                           leftIcon={this.props.icon}
                           onFocus={this.onFocus}
                           onBlur={this.onBlur}
                           onChange={this.onChange}
                           onKeyPress={this.onKeyPress}
                           onKeyDown={this.onKeyDown}
                           rightElement={this.getRightElement()}
                           style={style}
        />;
    }
}

export const QueryStringInput = withTranslation()(QueryStringInputComponent);


