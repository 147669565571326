import { Kpi } from './Kpi';
import { FieldValue } from "../field/FieldValue";
import { SatType } from "../SatTypeEnum";
import { DateTime } from 'luxon';
import { KpiEvolution } from "./KpiInstance";
import {ChartType} from "../../../utils/chart/SerieChartBuilder";

export class SatByCategoriesKpi extends Kpi {
  readonly satType: SatType;
  readonly categories: Array<FieldValue>;
  readonly tree: string;

  constructor(name: string, satType: SatType, tree: string, categories: Array<FieldValue>) {
    super(name);
    this.satType = satType;
    this.categories = categories;
    this.tree = tree;
  }

  getType() {
    return 'satByCategories';
  }

  getLabel(kpiInstance, t) {
    return this._i18n(t, 'kpi.satByCategories.label');
  }

  getNumber(kpiInstance, t) {
    return this._number(t, kpiInstance.data.value);
  }

  getLegend(kpiInstance, t) {
    return this._i18n(t, 'kpi.satByCategories.legend');
  }

  getEvolution(kpiInstance, t): KpiEvolution {
    return {
      type: ChartType.TIME_LINE,
      yAxis: {
        autoAdapted: { min: this.satType.min, max: this.satType.max },
        tickLabelCallback: (value) => {
          return this._number(t, value);
        }
      },
      data: kpiInstance.data.evolution.map(e => ({ x: DateTime.fromMillis(e.date).toJSDate(), y: e.value })),
      tooltipRenderer: (value) => {
        return this._number(t, value);
      }
    };
  }

  getApiParams() {
    return {
      satType: this.satType.name,
      tree: this.tree,
      categories: this.categories.map(c => c.fullName)
    };
  }

  private _number(t, value) {
    return this._i18n(t, 'kpi.satByCategories.number', { count: value });
  }

  private _i18n(t, key, obj = {}) {
    return t(key, { ...obj, satType: this.satType.getAliasedName() });
  }
}