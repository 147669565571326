import _ from 'lodash';
import { SatTypeEnum } from '../../../../application/model/SatTypeEnum';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { getTitle } from '../common';
import { CustomerJourneySatScoreViz } from './CustomerJourneySatScoreViz';
import { CustomerJourneySatScoreVizEditor } from './CustomerJourneySatScoreVizEditor';

const defaultUiParams = {
  distribution: {
    enabled: true,
    showNeutral: true,
    showPercent: true,
    showVolume: false
  }
};

const title = (vizInstance, key, t) => {
  const title = getTitle(vizInstance, t);
  return title ? title : t(key);
}

registerVizType({
  name: 'CUSTOMER_JOURNEY_SAT_SCORE',
  component: CustomerJourneySatScoreViz,
  title: (vizInstance, t) => title(vizInstance, 'dashboard.viz.customerJourneySatScore.title', t),
  defaultUiParams: (vizDef) => defaultUiParams,
  editor: {
    component: CustomerJourneySatScoreVizEditor,
    title: (vizInstance, t) => title(vizInstance, 'dashboard.viz.customerJourneySatScore.editor.title', t),
    init(vizInstance) {
      return {
        params: {
          distribution: vizInstance.definition.params.distribution
        },
        uiParams: _.merge({
          positiveThreshold: SatTypeEnum.valueOf(vizInstance.definition.params.satType).middle
        }, defaultUiParams, vizInstance.definition.uiParams)
      };
    },
    applyOnParams: (params, editedData) => {
      Object.assign(params, editedData);
    },
    applyOnUiParams: (uiParams, editedData) => {
      Object.assign(uiParams, editedData);
    }
  }
});
