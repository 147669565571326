import { defaultEqualComparator, noop } from "../../../utils/functionUtils";
import { arrayContains } from "../../../utils/collectionUtils";

export class MultiSelection<T> {
  private readonly _values: Array<T>;
  private _selection: Array<T>;
  private readonly _onSelectionChanged: OnChangeHandler<Array<T>>;
  private readonly _itemEqual: EqualComparator<T>;

  constructor(values: Array<T>,
              selection: Array<T>,
              onSelectionChanged?: OnChangeHandler<Array<T>>,
              itemEqual?: EqualComparator<T>) {
    this._values = values;
    this._selection = selection;
    this._onSelectionChanged = onSelectionChanged;
    this._itemEqual = itemEqual || defaultEqualComparator;
  }

  isSelected = (value: T): boolean => {
    return arrayContains(this.getSelection(), s => this._itemEqual(s, value));
  };

  toggleSelect = (value: T): void => {
    if (this.isSelected(value)) {
      this.unselect(value);
    } else {
      this.select(value);
    }
  };

  unselect = (value: T): void => {
    this.setSelection(this.getSelection().filter(s => !this._itemEqual(s, value)));
  };

  unselectAt = (index: number): void => {
    const newSelection = [...this.getSelection()];
    newSelection.splice(index, 1);
    this.setSelection(newSelection);
  };

  select = (value: T): void => {
    this.setSelection([...this.getSelection(), value]);
  };

  selectAll = (): void => {
    this.setSelection([...this._values]);
  };

  unselectAll = (): void => {
    this.setSelection([]);
  };

  setSelection = (selection: Array<T>): void => {
    if (this._onSelectionChanged) {
      this._onSelectionChanged(selection);
    } else {
      this._selection = selection;
    }
  };

  getSelection = (): Array<T> => {
    return this._selection;
  };
}