import { NavItem } from "./NavItem";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Location, NavigateFunction, Params } from "react-router-dom";

interface Redirect {
  from: string;
  to: string;
}

export class Nav {
  items: Array<NavItem> = [];
  redirects: Array<Redirect> = [];
  otherwiseComponent: React.ComponentType;

  /**
   *
   * @param {NavItem} navItem
   * @return {Nav}
   */
  withItem(navItem: NavItem): Nav {
    this.items.push(navItem);
    return this;
  }

  withRedirect(from: string, to: string): Nav {
    this.redirects.push({ from, to });
    return this;
  }

  withOtherwiseComponent(otherwiseComponent: React.ComponentType): Nav {
    this.otherwiseComponent = otherwiseComponent;
    return this;
  }

  /**
   * @return {Array<NavItem>}
   */
  getAllItems(): Array<NavItem> {
    return this.items.flatMap(it => {
      if (it.subNav) {
        return [it].concat(it.subNav.getAllItems())
      }
      return [it];
    });
  }
}

export interface WithRouterProps {
  router: RouterProps;
}

export interface RouterProps {
  location: Location;
  navigate: NavigateFunction;
  params: Params<string>;
}


export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }}/>;
  }

  return ComponentWithRouterProp;
}