import { Enum, EnumValue } from "../../utils/enum";
import { TFunction } from "i18next";
import _ from "lodash";

export class Metric extends EnumValue  {
  readonly min: number;
  readonly max: number;
  readonly precision: number;

  constructor(min:number, max:number, precision: number) {
    super();
    this.min = min;
    this.max = max;
    this.precision = precision;
  }

  getLabel(t:TFunction):string {
    return _.capitalize(t(`metric.${this.name}`));
  }
}

class MetricEnumType extends Enum<Metric> {
    SAT_SCORE = new Metric(-100, 100, 2);
    VOLUME = new Metric(0, undefined, 0);
    VOLUME_TREND = new Metric(undefined, undefined, 0);
    PERCENTAGE = new Metric(0, undefined, 2);
    TREND = new Metric(undefined, undefined, 0);
    POSITIVE_OPINIONS = new Metric(0, undefined, 0);
    NEGATIVE_OPINIONS = new Metric(0, undefined, 0);

    constructor() {
        super();
        this.initEnum('Metric');
    }
}

export const MetricEnum = new MetricEnumType();