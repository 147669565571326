import { Button, Intent } from '@blueprintjs/core';
import { Tooltip } from '@blueprintjs/core';
import React from 'react';
import { IconName } from "@blueprintjs/icons";

export interface ToolProps {
  tooltip?: string,
  icon?: IconName | JSX.Element,
  label?: string,
  onClick: () => void,
  intent?: Intent,
  minimal?: boolean,
  disabled?: boolean,
}

export class Tool extends React.PureComponent<ToolProps> {
  static defaultProps:Partial<ToolProps> = {
    minimal: true,
    disabled: false,
  }

  render() {
    const btn = <Button icon={this.props.icon}
                        intent={this.props.intent}
                        onClick={this.props.onClick}
                        minimal={this.props.minimal}
                        disabled={this.props.disabled}
    >
      {this.props.label}
    </Button>;

    if (this.props.tooltip && !this.props.disabled) {
      return <Tooltip content={this.props.tooltip} placement={'top'}>
        {btn}
      </Tooltip>;
    }

    return btn;
  }
}