import {distance, Enum, EnumBuilder} from '@eptica/js-utils';
import _ from 'lodash';
import groupBy from 'lodash/fp/groupBy';
import map from 'lodash/fp/map';
import flatten from 'lodash/fp/flatten';
import each from 'lodash/fp/each';
import {Tonality} from '@eptica/vecko-js-commons';

interface ActionPlanConfig {
    showLabel: boolean;
    position: 'outside',
    anchor: 'bottom_left' | 'bottom_right' | 'top_left' | 'top_right',
    color: string;
}

export class ActionPlanArea extends Enum {
    private x: number;
    private y: number;
    private width: number;
    private height: number;
    private comparator: (points: any[]) => any[];
    private ui: ActionPlanConfig;

    constructor(x: number, y: number, width: number, height: number, comparator, ui: ActionPlanConfig) {
        super();
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.comparator = comparator;
        this.ui = ui;
    }

    contains(x, y) {
        return x >= this.x && x <= this.x + this.width &&
            y >= this.y && y <= this.y + this.height;
    }
}

new EnumBuilder(ActionPlanArea)
    .register({
        WATCH: new ActionPlanArea(0, 0, 0.5, 0.5, (points) => {
            return _.orderBy(points, ['count'], ['desc']);
        }, {
            showLabel: true,
            position: 'outside',
            anchor: 'bottom_left',
            color: '#FF644D'
        }),
        ENHANCE: new ActionPlanArea(0, 0.5, 0.5, 0.5, (points) => {
            return _.orderBy(points, [(p) => distance({x: 0, y: 1}, {x: p.normalizedX, y: p.normalizedY})],
                ['asc']);
        }, {
            showLabel: true,
            position: 'outside',
            anchor: 'top_left',
            color: Tonality.NEGATIVE.color
        }),
        GENERALIZE: new ActionPlanArea(0.5, 0, 0.5, 0.5, (points) => {
            return _.orderBy(points, ['count'], ['desc']);
        }, {
            showLabel: true,
            position: 'outside',
            anchor: 'bottom_right',
            color: '#38C8D8'
        }),
        CAPITALIZE: new ActionPlanArea(0.5, 0.5, 0.5, 0.5, (points) => {
            return _.orderBy(points, [(p) => distance({x: 1, y: 1}, {x: p.normalizedX, y: p.normalizedY})],
                ['asc']);
        }, {
            showLabel: true,
            position: 'outside',
            anchor: 'top_right',
            color: Tonality.POSITIVE.color
        })
    })
    .build();


//---

const xAxis = {
    min: -1,
    max: 1
};

const yAxis = {
    min: 1,
    max: undefined,
    log: true
};

const defaultComparator = (points) => {
    return _.orderBy(points, ['count'], ['desc']);
};
const getBestPoints = (actionPlanArea, points, numberOfPoints) => {
    let sort = actionPlanArea ? actionPlanArea.comparator : defaultComparator;
    sort = sort || defaultComparator;

    return _.take(sort(points), numberOfPoints);
};

const log = (v) => {
    return Math.log10(v + 1);
};

const normalizeMin = 0;
const normalizeMax = 1;
const normalizeData = (value, axis, bounds) => {
    const v = axis.log ? log(value) : value;
    const b = axis.log ? {min: log(bounds.min), max: log(bounds.max)} : bounds;

    return ((normalizeMax - normalizeMin) * ((v - b.min) / (b.max - b.min))) + normalizeMin;
};

/**
 * This is just used by mock. Has got the same behaviour as the server side.
 * @param items
 */
export const dispatchToAreas = (items: Partial<{ category: string, score: number, count: number }>[]
) => {
    // set axis bounds
    const xAxisBounds = {
        min: _.isUndefined(xAxis.min) ? _.minBy(items, (o) => (o.score)).score : xAxis.min,
        max: _.isUndefined(xAxis.max) ? _.maxBy(items, (o) => (o.score)).score : xAxis.max
    };
    const yAxisBounds = {
        min: _.isUndefined(yAxis.min) ? _.minBy(items, (o) => (o.count)).count : yAxis.min,
        max: _.isUndefined(yAxis.max) ? _.maxBy(items, (o) => (o.count)).count : yAxis.max
    };

    // normalize data and find area
    _.each(items, (d) => {
        const normalized = {
            x: normalizeData(d.score, xAxis, xAxisBounds),
            y: normalizeData(d.count, yAxis, yAxisBounds)
        };
        (d as any).normalizedX = normalized.x;
        (d as any).normalizedY = normalized.y;

        const area = _.find(ActionPlanArea['values'], a => {
            return a.contains(normalized.x, normalized.y);
        });
        (d as any).area = area ? area.name() : undefined;
    });

    // find best points
    _.flow(
        groupBy((p: any) => (p.area ? p.area : 'NO_AREA')),
        map(points => {
            try {
                const area = ActionPlanArea['getValueOf'](points[0].area);
                return getBestPoints(area, points, 3);
            } catch (e) {
                return [];
            }
        }),
        flatten,
        each((point: any) => {
            point.best = true;
        })
    )(items);
};