import { NumericInput } from '@blueprintjs/core';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BlankSeparator } from '../../../../component/BlankSeparator';
import { EditorItem } from '../../../../component/editor/EditorItem';
import { SeparatedComponents } from '../../../../component/SeparatedComponents';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import { SatDistributionEditor } from '../../components/SatDistributionEditor';
import './customerJourneySatScoreViz.scss';
import { DistributionKindSelector } from "../../../../component/selector/DistributionKindSelector";
import { services } from "../../../../application/service/services";
import { DistributionKind } from "../../../../application/model/distribution/DistributionKind";
import { parseDistributionKind } from "../../../../application/model/distribution/parseDistributionKind";

interface CustomerJourneySatScoreVizEditorProps extends WithTranslation {
  viz: VisualizationInstance
  onValueChanged: (prop: string, key: string, value: any) => void,
  editorData?: any
}

class CustomerJourneySatScoreVizEditorComponent extends React.PureComponent<CustomerJourneySatScoreVizEditorProps> {
  onDistributionChanged = (d) => {
    this.props.onValueChanged('uiParams', 'distribution', d);
  };

  onPositiveThresholdChanged = (value) => {
    this.props.onValueChanged('uiParams', 'positiveThreshold', value);
  };

  onDistributionKindChanged = (distributionKind: DistributionKind) => {
    this.props.onValueChanged('params', 'distribution', distributionKind.toPlainObject());
  };

  render() {
    const { t, editorData } = this.props;
    return <SeparatedComponents separator={<BlankSeparator vertical={true}/>}>
      <EditorItem title={t('dashboard.viz.customerJourneySatScore.editor.positiveThreshold')}>
        <NumericInput value={editorData.uiParams.positiveThreshold} onValueChange={this.onPositiveThresholdChanged}/>
      </EditorItem>
      <SatDistributionEditor model={editorData.uiParams.distribution} onChanged={this.onDistributionChanged}
                             distributionKind={editorData.params.distribution}
      onDistributionKindChanged={this.onDistributionKindChanged}/>
    </SeparatedComponents>;
  }
}

export const CustomerJourneySatScoreVizEditor = withTranslation()(CustomerJourneySatScoreVizEditorComponent);