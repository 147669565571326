import { Checkbox, Tree, TreeNode, TreeNodeInfo } from '@blueprintjs/core';
import React from 'react';
import './treeSelector.scss';
import { MultiTreeSelectionCtrl, SelectionState } from './utils/tree/MultiTreeSelectionCtrl';
import { TreeExpansionCtrl } from './utils/tree/TreeExpansionCtrl';
import { InfiniteCache } from '../../utils/InfiniteCache';

interface TreeSelectorProp<T> {
  treeSelectionCtrl: MultiTreeSelectionCtrl<any>,
  treeExpansionCtrl: TreeExpansionCtrl,
  nodeId: (node: T) => void,
  nodeLabel: (node: T) => string,
  filterFn?: (node:T)=>boolean
};


export class TreeSelector<T> extends React.PureComponent<TreeSelectorProp<T>> {

  state = {
    expandedNodes: [],
    selectedNodes: []
  };

  nodeSelectHandler = new InfiniteCache((node) => () => this.toggleSelection(node));

  constructor(props) {
    super(props);
    this.bindTreeSelectionCtrl();
    this.bindTreeExpansionCtrl();
  }

  componentWillUnmount() {
    this.props.treeSelectionCtrl.unbind();
    this.props.treeExpansionCtrl.unbind();
  }

  bindTreeExpansionCtrl() {
    const treeExpansionCtrl = this.props.treeExpansionCtrl;
    if (treeExpansionCtrl.isUncontrolled()) {
      treeExpansionCtrl.bind(expandedNodes => {
        this.setState({ expandedNodes: expandedNodes })
      });
      this.state.expandedNodes = treeExpansionCtrl.getExpandedItems();
    }
  }

  bindTreeSelectionCtrl() {
    const treeSelectionCtrl = this.props.treeSelectionCtrl;
    if (treeSelectionCtrl.isUncontrolled()) {
      treeSelectionCtrl.bind(selection => {
        this.setState({ selectedNodes: selection })
      });
      this.state.selectedNodes = treeSelectionCtrl.getSelection();
    }
  }

  handleNodeCollapse = (node) => {
    this.props.treeExpansionCtrl.collapse(node.nodeData);
  };

  handleNodeExpand = (node) => {
    this.props.treeExpansionCtrl.expand(node.nodeData);
  };

  getSelectionState(node) {
    return this.props.treeSelectionCtrl.getSelectionState(node)
  }

  toggleSelection(node) {
    const { treeSelectionCtrl } = this.props;

    const selectionState = this.getSelectionState(node);
    if (selectionState !== SelectionState.SELECTED || selectionState === SelectionState.INDETERMINATE) {
      treeSelectionCtrl.select(node);
    } else {
      treeSelectionCtrl.unselect(node);
    }
  }

  nodeMapper = (node: TreeNodeInfo, children: Array<TreeNodeInfo>) => {
    node.childNodes = children
  };

  render() {

    const treeSelectionCtrl = this.props.treeSelectionCtrl;
    const treeExpansionCtrl = this.props.treeExpansionCtrl;

    const mapNode = (node) => {
      const treeNode = { ...node, nodeData: node, id: this.props.nodeId(node) };

      const selectionState = this.getSelectionState(node);
      const checked = selectionState === SelectionState.SELECTED;
      const indeterminate = selectionState === SelectionState.INDETERMINATE;

      treeNode.label = <Checkbox label={this.props.nodeLabel(node)}
                                 checked={checked}
                                 indeterminate={indeterminate}
                                 onChange={this.nodeSelectHandler.get(node)}/>;

      treeNode.isExpanded = treeExpansionCtrl.isExpanded(node);

      return treeNode;
    };

    let nodes = treeSelectionCtrl.tree.mapAllNodes(this.nodeMapper, mapNode);

    if(this.props.filterFn){
      nodes = nodes.filter(this.props.filterFn);
    }

    return <Tree
      contents={nodes}
      onNodeCollapse={this.handleNodeCollapse}
      onNodeExpand={this.handleNodeExpand}
    />;
  }
}