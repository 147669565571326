import { ApiFilter } from './ApiFilter';
import _ from "lodash";
import { parseFilter } from "./parseFilter";

export class NestedFilter extends ApiFilter {
  static parse(object) {
    return new NestedFilter(object.field, object.filter);
  }

  field:string;
  filter:ApiFilter;

  constructor(field:string, filter:ApiFilter) {
    super('nested');
    this.field = field;
    this.filter = parseFilter(filter);
  }

  toPlainObject() {
    if (_.isEmpty(this.field)) {
      throw new Error('field is mandatory')
    }

    if (_.isEmpty(this.filter)) {
      throw new Error('filter are mandatory')
    }

    const result:any = super.toPlainObject();
    result.field = this.field;
    result.filter = this.filter.toPlainObject();
    return result;
  }

  getCopy():ApiFilter {
    const apiFilter = new NestedFilter(this.field, this.filter.getCopy());
    return apiFilter
  }
}