import axios from 'axios';
import { fetchHttpUtil } from './httpUtils';
import { saveHttpResponseAsFile } from './saveHttpResponseAsFile';
import _ from "lodash";

const useStreamSaver = false;

export const saveStream = async ({url, method, body, headers, fallbackFileName}) => {
  const streamSaver = useStreamSaver ? saveStreamWithStreamSaver : saveStreamWithAxios;

  return streamSaver({url, method, body, headers, fallbackFileName, onError:null});
};

const saveStreamWithAxios = ({url, method, body, headers, onError, fallbackFileName}) => {
  return axios
    .request({
      method: method,
      url: url,
      data: body,
      headers: headers,
      responseType: 'arraybuffer',
      responseEncoding: 'binary'
    })
    .then(function (response) {
      if (response.status === 200) {
        saveHttpResponseAsFile(response, fallbackFileName);
      }
    });
}

const saveStreamWithStreamSaver = async ({url, method, body, headers, onError, fallbackFileName}) => {
  if (!window.WritableStream) {
    const pony = await import('web-streams-polyfill/ponyfill');
    (<any>window).WritableStream = pony.WritableStream;
  }
  const {default: streamSaver} = await import('streamsaver')

  const options:any = { method: method, headers: new Headers() };

  if (headers) {
    Object.entries(headers).forEach(([name, value]) => {
      options.headers.set(name, value);
    })
  }

  if (body) {
    if (_.isPlainObject(body)) {
      options.body = JSON.stringify(body);
    } else {
      options.body = body;
    }

    options.headers.set('Content-type', 'application/json');
  }

  return fetch(url, options)
    .then(res => {
      if (!res.ok) {
        return res.json().then(data => {
          const err:any = new Error('Request failed with status ' + res.status);
          err.config = {
            url: url,
            method: options.method,
            data: JSON.parse(options.body),
            headers: {}
          };

          err.response = {
            status: res.status,
            data: data,
            headers: {}
          }

          throw err;
        });
      }

      const filename = fetchHttpUtil.getFileName(res, fallbackFileName);

      const fileStream = streamSaver.createWriteStream(filename);
      const writer = fileStream.getWriter();

      // more optimized
      if (res.body.pipeTo) {
        // like as we never did fileStream.getWriter()
        writer.releaseLock();
        return res.body.pipeTo(fileStream);
      }

      const reader = res.body.getReader();
      const pump = () =>
        reader.read().then(({ value, done }) =>
          done
            ? // close the stream so we stop writing
            writer.close()
            : // Write one chunk, then get the next one
            writer.write(value).then(pump)
        );

      // Start the reader
      return pump();
    });
}