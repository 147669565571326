import { services } from '../../application/service/services';
import { ResolvedParams } from '../model/params/resolve/ResolvedParams';
import { getDashboardResolvedParams } from "./selectors/dashboardSelector";
import _ from "lodash";

export const dashboardDataState = () => ({
  state: {
  },
  reducers: {
    reset(state, payload) {
      delete state.payload;
      state.hasData = false;
      return state;
    },
    setData(state, payload) {
      if (payload) {
        state.hasData = true;
        state.payload = payload.vizResponses;
        state.total = payload.total;
      } else {
        state.hasData = false;
        delete state.payload;
        delete state.total;
      }

      return state;
    },
    setVizData(state, payload) {
      if (state.payload) {
        state.payload[payload.vizId] = payload.data;
      }

      return state;
    }
  },
  effects: (dispatch) => ({
    async fetchData(payload, rootState) {
      const dashboardId: string = rootState.dashboard.id;
      const resolvedSpec: any = rootState.dashboard.resolvedSpec;
      const resolvedParams:ResolvedParams = getDashboardResolvedParams(rootState);

      dispatch.dashboardData.setData(
        await services.getDashboardService().getDashboardData(dashboardId, resolvedSpec, resolvedParams));
    }
  })
});