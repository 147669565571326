import { SortOrderEnum } from './SortOrder';
import _ from "lodash";

export class Sort {
  /**
   * @param {Object} object
   * @return {Sort}
   */
  static parse(object) {
    return new Sort(object.field, SortOrderEnum.valueOf(object.order));
  }

  /**
   * @type {string}
   */
  field;

  /**
   * @type {SortOrder}
   */
  order;

  /**
   * @param {string} field
   * @param {SortOrder} order
   */
  constructor(field, order = SortOrderEnum.DESC) {
    this.field = field;
    this.order = order;
  }

  toPlainObject() {
    if (_.isNil(this.field)) {
      throw new Error('Invalid Sort : field is mandatory');
    }

    return {
      order: this.order.name,
      field: this.field
    }
  }
}
