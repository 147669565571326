import { Tonality } from '@eptica/vecko-js-commons';
import { FieldValueFilterBuilder, FieldValueFilterCtrl } from '../../filter/model/FieldValueFilterCtrl';
import { BoolFilter } from '../../utils/query/filter/BoolFilter';
import { ExistsFilter } from '../../utils/query/filter/ExistsFilter';
import { VerbatimNotEmptyFilter } from '../../utils/query/filter/VerbatimNotEmptyFilter';
import { FieldName } from '../model/field/Field';
import { CountByCategoriesKpi } from '../model/kpi/CountByCategoriesKpi';
import { CountByTonalityAndCategoriesKpi } from '../model/kpi/CountByTonalityAndCategoriesKpi';
import { KpiInstance } from '../model/kpi/KpiInstance';
import { SatImpactByCategoriesKpi } from '../model/kpi/SatImpactByCategoriesKpi';
import { SharedFilterNames } from './filterService';
import { services } from './services';
import { SatType } from '../model/SatTypeEnum';
import { FieldValue } from '../model/field/FieldValue';
import _ from 'lodash';
import { Kpi } from '../model/kpi/Kpi';
import { DistributionKind } from '../model/distribution/DistributionKind';
import { TonalitiesDistribution } from '../model/distribution/TonalitiesDistribution';
import { SatTypeDistribution } from '../model/distribution/SatTypeDistribution';
import { CountBySatPopulationAndCategoriesKpi } from '../model/kpi/CountBySatPopulationAndCategoriesKpi';
import { toArray } from "../../utils/collectionUtils";
import { SatByCategoriesKpi } from "../model/kpi/SatByCategoriesKpi";
import { ApiFilter } from "../../utils/query/filter/ApiFilter";
import { DateRangeFilterCtrl } from "../../filter/model/DateRangeFilterCtrl";

export class AnalysisService {
  private _tree = 'topic';
  private _kpiFetcher;
  topicFilter: FieldValueFilterCtrl;
  distributionKind: DistributionKind;

  init() {
    this._kpiFetcher = services.getFetcherService().getFetcher('kpi');

    const categoryTree = services.getFieldsService().getCategoryTree(this._tree);
    if (!categoryTree.isEmpty(false)) {
      this.topicFilter = new FieldValueFilterBuilder('topic')
        .fieldValues(services.getFieldsService().getCategoryTree(this._tree))
        .allowEmptySelection(false)
        .allowMultiSelection(true)
        .canSelectLeavesOnly(false)
        .build();
    }

    this.distributionKind = TonalitiesDistribution.get();
  }

  getTopics(): Maybe<OneOrMany<FieldValue>> {
    return this.topicFilter?.getValue();
  }

  async getAnalysis(): Promise<Array<Object>> {
    const kpis = this.getKpis();

    const additionalFilters:ApiFilter[]  = [AnalysisService.getActionableFilter()];

    if (this.distributionKind instanceof SatTypeDistribution) {
      additionalFilters.push(services.getSatService().getSatFilter(this.distributionKind.satType));
    }

    const filter = BoolFilter.must(
      services.getFilterService().getFilterForApi(null),
      additionalFilters
    ).toPlainObject();

    let kpiObjects = kpis.map(kpi => {
      const converted = kpi.convertForApi();
      converted.params.zoneId = services.getTimeZoneService().getTimeZone();
      const interval = (services.getFilterService().currentFilter.getFilter(SharedFilterNames.DATE_RANGE) as DateRangeFilterCtrl).getValue().getBestInterval();
      converted.params.interval = interval.convertForApi();
      return converted;
    });
    return this._kpiFetcher.calculateKpis(filter, kpiObjects);
  }

  toKpiInstances(kpiDTOs: Array<any>): Array<KpiInstance> {
    const kpis = this.getKpis();
    const kpisByName = Object.fromEntries(kpis.map(k => [k.name, k]));
    return kpiDTOs.map(kpiDTO => {
      const kpi = kpisByName[kpiDTO.name];
      if (!kpi) {
        console.log(`no kpi found for name ${kpiDTO.name}. Available kpis are : ${Object.keys(kpisByName)}`);
        return undefined;
      }
      return new KpiInstance(kpi, kpiDTO);
    }).filter(o => !_.isNil(o));
  }

  getKpis(): Array<Kpi> {
    const selectedTopics = this.getTopics();

    if (_.isEmpty(selectedTopics)) {
      return [];
    }

    const topicsSet:Set<FieldValue> = new Set();
    toArray(selectedTopics).forEach(topic => {
      if (topic.isLeaf()) {
        topicsSet.add(topic);
      } else {
        topic.getLeaves().forEach(t => topicsSet.add(t));
      }
    });
    const topics = Array.from(topicsSet);

    const result: Array<Kpi> = [
      new CountByCategoriesKpi('total', this._tree, topics)
    ];

    if (this.distributionKind instanceof TonalitiesDistribution) {
      this.distributionKind.items.forEach(di =>
        result.push(new CountByTonalityAndCategoriesKpi(di.id, Tonality.getValueOf(di.id), this._tree, topics))
      );

      services.getSatService().getSatTypes().forEach(satType =>
        result.push(this.getSatByCategoriesKpi(topics, satType))
      );
    }

    if (this.distributionKind instanceof SatTypeDistribution) {
      const satType = this.distributionKind.satType;

      this.distributionKind.items.forEach(di =>
        result.push(new CountBySatPopulationAndCategoriesKpi(di.id, satType, Tonality.getValueOf(di.id), this._tree, topics))
      );

      result.push(this.getSatByCategoriesKpi(topics, satType));
    }

    return result;
  }

  getDistributionKinds(): Array<DistributionKind> {
    return services.getSatService().getDistributionKinds();
  }

  private static getActionableFilter(): BoolFilter {
    const actionableFilter = new BoolFilter(null, null ,null);
    actionableFilter.addMust(new VerbatimNotEmptyFilter());
    actionableFilter.addMust(new ExistsFilter(FieldName.categoryTree('topic')));
    return actionableFilter;
  }

  private getSatImpactByCategoriesKpi(topics: Array<FieldValue>, satType: SatType): SatImpactByCategoriesKpi {
    return new SatImpactByCategoriesKpi(`satImpact-${satType.name}`, satType, this._tree, topics);
  }

  private getSatByCategoriesKpi(topics: Array<FieldValue>, satType: SatType): SatByCategoriesKpi {
    return new SatByCategoriesKpi(`sat-${satType.name}`, satType, this._tree, topics);
  }
}

services.registerService('analysisService', new AnalysisService());
