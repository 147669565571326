import { SatTypeDistribution } from './SatTypeDistribution';
import { TonalitiesDistribution } from './TonalitiesDistribution';
import { DistributionKind } from "./DistributionKind";

export const parseDistributionKind = (object: Object): DistributionKind => {
  const type = object['@type'];

  if (type === SatTypeDistribution.TYPE) {
    return SatTypeDistribution.parse(object);
  }

  if (type === TonalitiesDistribution.TYPE) {
    return TonalitiesDistribution.parse(object);
  }

  throw new Error('Cannot parse given object into DistributionKind. Unknown type ' + type);
}