import React from 'react';
import _ from 'lodash';
import './actionPlanEditor.scss';
import {Table} from '../../../../component/table/Table';
import {WithTranslation, withTranslation} from 'react-i18next';
import {BubbleChart} from '../../components/bubbleChart/bubbleChart';
import {ActionPlanDatasetBuilder} from './actionPlanDataset';
import {Button, ButtonGroup} from '@blueprintjs/core';
import {VisualizationInstance} from "../../../model/viz/VisualizationInstance";
import {SatTypeEnum} from "../../../../application/model/SatTypeEnum";

const colorized = (color, val) => {
    return <span style={{color: color}}>{val}</span>;
};


const defaultColumnsConfig = {
    topic: {
        renderer: (rowData) => {
            return colorized(rowData.area.color, rowData.label);
        },
        style: {
            flex: 1
        }
    },
    satisfaction: {
        renderer: (rowData) => {
            return colorized(rowData.area.color, _.round(rowData.data.x, 2));
        },
        style: {
            width: 80
        }
    },
    importance: {
        renderer: (rowData) => {
            return colorized(rowData.area.color, rowData.data.y);
        },
        style: {
            width: 80
        }
    }
};

interface ActionPlanEditorComponentProps extends WithTranslation {
    viz: VisualizationInstance,
    onValueChanged: (p1: string, p2: string, array: any[]) => string,
    editorData: any
}


class ActionPlanEditorComponent extends React.PureComponent<ActionPlanEditorComponentProps> {


    private columnsConfig ;

    state = {
        actionPlanDataset: null,
        selection: null,
    };

    constructor(props) {
        super(props);

        const {t, viz, editorData} = this.props;

        const satType = SatTypeEnum.valueOf(viz.definition.params.satType);

        this.columnsConfig = {...defaultColumnsConfig}

        _.forOwn(this.columnsConfig, (value, key) =>
            this.columnsConfig[key].headerLabel = key === 'satisfaction' ? satType.getAliasedName()
            : _.capitalize(t(`dashboard.viz.actionPlan.editor.header.${key}`)));

        const actionPlanDataset = new ActionPlanDatasetBuilder(viz.data, satType).build(t);

        let selection;
        if (editorData?.data?.selection) {
            const isSelected = (o) => {
                return _.find(editorData.data.selection, (s) => {
                    return _.isEqual(o.raw, s);
                });
            };

            selection = _.filter(actionPlanDataset.data, isSelected);

        } else {
            selection = actionPlanDataset.getBestPoints();
            this.fireSelectionChanged(selection);// todo : should be done by reducer
        }

        this.state = {actionPlanDataset, selection};
    }

    onNodeSelectionChanged = (newSelection) => {
        this.setSelection(newSelection);
    };

    clearSelection = () => {
        this.setSelection([]);
    };

    selectAll = () => {
        this.setSelection([...this.state.actionPlanDataset.data]);
    };

    bestSelection = () => {
        this.setSelection(this.state.actionPlanDataset.getBestPoints(3));
    };

    setSelection = (selection) => {
        this.setState({selection: selection});
        this.fireSelectionChanged(selection);
    };

    fireSelectionChanged(selection) {
        const {onValueChanged} = this.props;
        if (onValueChanged) {
            onValueChanged('data', 'selection', _.map(selection, 'raw'));
        }
    }

    render() {
        const {t} = this.props;

        const tableDataset = {
            data: this.state.actionPlanDataset.data,
            display: _.map(this.columnsConfig, (columnConfig, key) => ({
                headerRenderer: columnConfig.headerLabel,
                dataRenderer: columnConfig.renderer,
                style: columnConfig.style
            }))
        };

        return <div className='action-plan-editor'>

            <div className='action-plan-editor-top'>
                <ButtonGroup>
                    <Button minimal={true} icon='graph-remove' onClick={this.clearSelection}>
                        {t('action.clearSelection')}
                    </Button>
                    <Button minimal={true} icon='endorsed' onClick={this.selectAll}>
                        {t('action.selectAll')}
                    </Button>
                    <Button minimal={true} icon='heatmap' onClick={this.bestSelection}>
                        {t('dashboard.viz.actionPlan.editor.automaticSelection')}
                    </Button>
                </ButtonGroup>
            </div>

            <div className='action-plan-editor-bottom'>
                <div className='action-plan-editor-left'>
                    <Table color='#ccc' dataset={tableDataset} cellCanSelect={[0]}
                           selectable={true} selection={this.state.selection}
                           selectionChanged={this.onNodeSelectionChanged}
                    />
                </div>
                <div className='action-plan-editor-right'>
                    <BubbleChart dataset={this.state.actionPlanDataset.bubbleChartDataset}
                                 selectable={true} selection={this.state.selection}
                                 onSelectionChanged={this.onNodeSelectionChanged}
                    />
                </div>
            </div>
        </div>;
    }
}

export const ActionPlanEditor = withTranslation()(ActionPlanEditorComponent);
