import React, { CSSProperties } from 'react';
import classnames from "classnames";

interface HeaderProps {
  className?: string,
  style?: CSSProperties,
  title: JSX.Element|string,
  titleClassName?: string,
  titleStyle?: CSSProperties,
  leftComponent?: JSX.Element|string,
  rightComponent?: JSX.Element|string,
}

export class Header extends React.PureComponent<HeaderProps> {
  render() {
    const { className, title, leftComponent, rightComponent, titleStyle, titleClassName, ...htmlProps } = this.props;

    const { style, ...mainProps } = htmlProps;

    const mainStyle:CSSProperties = {
      alignItems: 'center',
      ...style
    };

    const tStyle:CSSProperties = {
      alignItems: 'center',
      whiteSpace: 'nowrap',
      display: 'flex',
      ...titleStyle
    };

    return <div className={classnames('vui-layout--horizontal', className)} style={mainStyle} {...mainProps}>
      <div style={tStyle} className={titleClassName}>{title}</div>
      {leftComponent}
      <div className='vui-layout--horizontal__center vui-layout--horizontal'/>
      {rightComponent}
    </div>;
  }
}
