export abstract class ApiFilter {

  _type: string;

  constructor(type: string) {
    this._type = type;
  }

  get type(): string {
    return this._type;
  }

  abstract getCopy():ApiFilter;

  toPlainObject() {
    return {
      '@type': this._type
    }
  }
}