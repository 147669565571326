import { FieldValuesResolver } from './FieldValuesResolver';
import { FieldValues } from '../FieldValues';
import { Field } from "../Field";
import _ from "lodash";

export class ConfigurationFieldValuesResolver extends FieldValuesResolver {

  resolve(field:Field, obj:any):FieldValues {
    const fieldValues = new FieldValues(field);

    if (!_.isNil(obj)) {
      if (obj.dynamic) {
        return fieldValues;
      }

      (obj.values || []).forEach(value => {
        const addChildren = (fieldValue, object) => {
          (object.children || []).forEach(child => {
            const childValue = fieldValue.addChild(child);
            addChildren(childValue, child);
          });
        };

        addChildren(fieldValues.add(value), value);
      });
    }

    return fieldValues;
  }
}