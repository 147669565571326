import axios from 'axios';
import { saveStream } from '../../../utils/http/saveStream';

const getTopicsStats = (topicsStatsArgs) => {
  const json = JSON.stringify(topicsStatsArgs);
  return axios
    .post('/api/ui/topics/stats', json, {
      headers: { 'Content-type': 'application/json'  }
    })
    .then(response => response.data);
};

const save = (topicsStatsArgs, lang) => {
  return saveStream({
    url: '/api/ui/topics/stats',
    method: 'POST',
    body: topicsStatsArgs,
    headers: {
      'Accept-Language': lang,
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    fallbackFileName: 'topics.xlsx'
  });
};


export default { getTopicsStats, save };
