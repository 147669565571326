import { CompositeFilterCtrl } from '../../filter/model/CompositeFilterCtrl';
import { DateRangeFilterCtrl } from '../../filter/model/DateRangeFilterCtrl';
import { FieldValueFilterCtrl } from '../../filter/model/FieldValueFilterCtrl';
import { QueryStringFilterCtrl } from '../../filter/model/QueryStringFilterCtrl';
import { BoolOperatorEnum } from '../../filter/model/utils/BoolOperatorEnum';
import { toArray } from '../../utils/collectionUtils';
import { BoolFilter } from '../../utils/query/filter/BoolFilter';
import { DateRangeFilter } from '../../utils/query/filter/DateRangeFilter';
import { MissingFilter } from '../../utils/query/filter/MissingFilter';
import { QueryStringFilter } from '../../utils/query/filter/QueryStringFilter';
import { TermFilter } from '../../utils/query/filter/TermFilter';
import { TermsFilter } from '../../utils/query/filter/TermsFilter';
import { NoneFieldValue } from '../model/field/NoneFieldValue';
import { services } from './services';
import { ApiFilter } from "../../utils/query/filter/ApiFilter";
import { AbstractFilterCtrl } from "../../filter/model/AbstractFilterCtrl";
import _ from "lodash";
import { FieldValue } from "../model/field/FieldValue";
import { DateRange } from "../model/DateRange";
import { dateFormat } from "../../utils/dateUtils";
import { NotEmptyVerbatimsFilterCtrl } from "../../filter/model/NotEmptyVerbatimsFilterCtrl";
import { VerbatimNotEmptyFilter } from "../../utils/query/filter/VerbatimNotEmptyFilter";
import {FieldQueryStringFilterCtrl} from "../../filter/model/FieldQueryStringFilterCtrl";

export class FilterConverterService {
  convertToApiFilter(filter: AbstractFilterCtrl<unknown>, excludedFilters: [string]): ApiFilter {
    if (_.isNil(filter)) {
      return null;
    }
    if (excludedFilters && excludedFilters.indexOf(filter.id) >= 0) {
      return null;
    }
    const value = filter.getValue();

    if (_.isNil(value)) {
      return null;
    }

    if (filter instanceof CompositeFilterCtrl) {
      const convertedFilters = filter.filters.map(f => this.convertToApiFilter(f, excludedFilters)).filter(a => !_.isNil(a));

      if (convertedFilters.length === 0) {
        return null;
      }

      if (convertedFilters.length === 1) {
        return convertedFilters[0];
      }

      const boolFilter = new BoolFilter(null, null, null);
      if (filter.boolOperator === BoolOperatorEnum.MUST) {
        boolFilter.must = convertedFilters;
      } else if (filter.boolOperator === BoolOperatorEnum.SHOULD) {
        boolFilter.should = convertedFilters;
      }
      return boolFilter;
    }

    if (filter instanceof FieldValueFilterCtrl) {
      const values: Array<FieldValue> = toArray(value as OneOrMany<FieldValue>);

      let hasNoneFieldValue = false;
      const terms = [];

      values.forEach(v => {
        if (v instanceof NoneFieldValue) {
          hasNoneFieldValue = true;
        } else if (!_.isNil(v)) {
          terms.push(v.fullName);
        }
      });

      let termsFilter;
      if (terms.length === 0) {
        termsFilter = null;
      } else if (terms.length === 1) {
        termsFilter = new TermFilter(filter.field.name, terms[0]);
      } else {
        termsFilter = new TermsFilter(filter.field.name, terms);
      }

      return BoolFilter.should(termsFilter, hasNoneFieldValue ? new MissingFilter(filter.field.name) : null);
    }

    if (filter instanceof DateRangeFilterCtrl) {
      const dateRange: DateRange = value as DateRange;

      return new DateRangeFilter(filter.field.name, services.getTimeZoneService().getTimeZone(),
        dateRange.start.toFormat(dateFormat), dateRange.end.toFormat(dateFormat));
    }

    if (filter instanceof QueryStringFilterCtrl) {
      if (_.isEmpty(value)) {
        return null;
      }
      return new QueryStringFilter(value as string, null, true);
    }

    if (filter instanceof FieldQueryStringFilterCtrl) {
      if (_.isEmpty(value)) {
        return null;
      }
      return new TermFilter(filter.field.name, value as string);
    }

    if (filter instanceof NotEmptyVerbatimsFilterCtrl) {
      return !value ? null : new VerbatimNotEmptyFilter();
    }

    return null;
  }
}

services.registerService('filterConverterService', new FilterConverterService());


