import { Tonality } from '@eptica/vecko-js-commons';
import { toArray } from '../../../utils/collectionUtils';
import { BoolFilter } from '../../../utils/query/filter/BoolFilter';
import { CategoriesFilter } from '../../../utils/query/filter/CategoriesFilter';
import { DistributionItem } from './DistributionItem';
import { DistributionKind } from './DistributionKind';
import { SearchQuery } from "../../../utils/query/SearchQuery";
import { getDistributionKind } from "./DistributionKindProvider";
import { CategoryField } from "../field/CategoryField";

export class TonalitiesDistribution extends DistributionKind {
  static TYPE: string = 'tonalities';

  static parse(object: Object) {
    return TonalitiesDistribution.get();
  }

  static get(): TonalitiesDistribution {
    return getDistributionKind(TonalitiesDistribution.TYPE, "", () => new TonalitiesDistribution());
  }

  private constructor() {
    super(TonalitiesDistribution.TYPE);
  }

  getIcon(): string {
    return 'tag';
  }

  createItems(): Array<DistributionItem> {
    return [Tonality.NEGATIVE, Tonality.POSITIVE, Tonality.NEUTRAL].map(tonality =>
      new DistributionItem(
            tonality,
            tonality.icon,
            t => t(`distributionKind.${this.type}.item`, {item: tonality.name()})
        )
    );
  }

  decorateFeedbackQuery(query:SearchQuery, fieldValue, item) {
    if (fieldValue && fieldValue.field.isCategoryTree() && (fieldValue.field as CategoryField).tree === 'topic' ) {
      query.filter = BoolFilter.must(query.filter, new CategoriesFilter((fieldValue.field as CategoryField).tree,
        fieldValue.getLeaves(true).map(l => l.fullName), toArray(item).map(it => Tonality.getValueOf(it.id))));
    }
  }
}