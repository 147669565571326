import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BlankSeparator } from '../../../../component/BlankSeparator';
import { SeparatedComponents } from '../../../../component/SeparatedComponents';
import { SatDistributionEditor } from '../../components/SatDistributionEditor';

interface SatScoreVizEditorProps extends WithTranslation {
        onValueChanged: (prop: string, key: string, value: any) => void,
        editorData: any
}

export class SatScoreVizEditor extends React.PureComponent<SatScoreVizEditorProps> {
  static propTypes = {};

  static defaultProps = {};

  onDistributionChanged = (d) => {
    this.props.onValueChanged('uiParams', 'distribution', d);
  };

  render() {
    const { editorData } = this.props;

    return <SeparatedComponents separator={<BlankSeparator vertical={true}/>}>
      <SatDistributionEditor model={editorData.uiParams.distribution} onChanged={this.onDistributionChanged} displayParticipantsSwitch={true} />
    </SeparatedComponents>;
  }
}
