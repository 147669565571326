import { FieldValues } from '../FieldValues';
import { FieldValuesResolver } from './FieldValuesResolver';
import { Field } from "../Field";
import _ from "lodash";

export class RemoteFieldValuesResolver extends FieldValuesResolver {
  resolve(field:Field, obj:any):FieldValues {
    const fieldValues = new FieldValues(field);

    if (!_.isNil(obj) && !_.isEmpty(obj)) {
      obj
        .filter(o => !_.isNil(o.value))
        .filter(o => !_.isEmpty(o.value))
        .forEach(o => fieldValues.add({name : o.value}));
    }

    fieldValues.sort();

    return fieldValues;
  }
}