import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { DistributionKind } from '../../application/model/distribution/DistributionKind';
import { SelectInput } from '../select/SelectInput';

class DistributionKindSelectorComponent extends PureComponent {
  /**
   *
   * @param {DistributionKind} distributionKind
   * @return {string}
   */
  getId = (distributionKind) => {
    return distributionKind.getId();
  };

  /**
   *
   * @param {DistributionKind} distributionKind
   * @return {string}
   */
  getLabel = (distributionKind) => {
    return distributionKind.getLabel(this.props.t);
  };

  /**
   *
   * @param {DistributionKind} distributionKind
   * @return {string}
   */
  getIcon = (distributionKind) => {
    return distributionKind.getIcon();
  }

  /**
   *
   * @param {DistributionKind} dk1
   * @param {DistributionKind} dk2
   * @return {boolean}
   */
  itemEqual= (dk1, dk2) => {
    return dk1.getId() === dk2.getId();
  }

  render() {
    const { t } = this.props;

    return <SelectInput itemLabel={this.getLabel}
                        itemId={this.getId}
                        itemIcon={this.getIcon}
                        itemEqual={this.itemEqual}
                        items={this.props.items}
                        selectedItem={this.props.selectedItem}
                        onSelectionChanged={this.props.onSelectionChanged}
                        allowEmptySelection={this.props.allowEmptySelection}
                        placeholder={t('distributionKind')}
    />;
  }
}

export const DistributionKindSelector = withTranslation()(DistributionKindSelectorComponent);

DistributionKindSelector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(DistributionKind)),
  selectedItem: PropTypes.instanceOf(DistributionKind),
  onSelectionChanged: PropTypes.func,
  allowEmptySelection: PropTypes.bool
};

DistributionKindSelector.defaultProps = {
  items: [],
  selectedItem: null,
  allowEmptySelection: false
};