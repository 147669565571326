import { Nav } from "./Nav";
import { IconName } from "@blueprintjs/icons";
import { MaybeElement } from "@blueprintjs/core/src/common/index";
import React from "react";

export enum Anchor {
  TOP,
  BOTTOM
}

type Icon = IconName | MaybeElement;

/**
 * @property {string} label
 * @property {string} to
 * @property {string|JSX.Element} icon
 * @property {boolean} exact
 * @property {JSX.Element} component
 * @property {Object} metadata
 * @property {Nav} subNav
 */
export class NavItem {
  label: string;
  anchor: Anchor;
  to?: string;
  linkPrefix?: string;
  completeLink?: string;
  component: React.ComponentType;
  exact: boolean;
  icon: Icon;
  subNav: Nav;
  metadata: string;

  constructor(label: string, to: string, component: React.ComponentType, exact = true, linkPrefix?: string) {
    this.label = label;
    this.to = to;
    this.component = component;
    this.linkPrefix = linkPrefix;
    this.exact = exact;
    this.anchor = Anchor.TOP;
    this.completeLink = (this.linkPrefix ? this.linkPrefix : null);
    this.completeLink = this.to ? (this.completeLink ? this.completeLink : "") + this.to : null;
  }

  withIcon(icon: Icon): NavItem {
    this.icon = icon;
    return this;
  }

  withMetadata(metadata: string): NavItem {
    this.metadata = metadata;
    return this;
  }

  withSubNav(subNav: Nav): NavItem {
    this.subNav = subNav;
    return this;
  }

  withAnchor(anchor: Anchor): NavItem {
    this.anchor = anchor;
    return this;
  }

  getLink() {
    return this.completeLink;
  }
}
