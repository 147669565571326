import { Colors } from '@blueprintjs/core';

export const VeckoColors = {
  WHITE: Colors.WHITE,
  DEFAULT_BG: Colors.LIGHT_GRAY4,
  DEFAULT_FG: Colors.DARK_GRAY5,
  DARK_THEME_BG: Colors.GRAY2,
  GREEN: '#003591',
  GREEN_LIGHT_1: '#45c8b2',
  GREEN_LIGHT_6: '#b9eae2',
  DANGER: Colors.RED3,
  WARNING: Colors.ORANGE3,
  BLUE: '#26547c'

};
