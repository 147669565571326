import { parseFilter } from "../../../../utils/query/filter/parseFilter";
import { ApiFilter } from "../../../../utils/query/filter/ApiFilter";
import { services } from "../../../../application/service/services";
import { BoolFilter } from "../../../../utils/query/filter/BoolFilter";
import { SatTypeEnum } from "../../../../application/model/SatTypeEnum";
import _ from "lodash";

export const parseCommonFilters = (commonFilters: any): ApiFilter => {

  if (_.isNil(commonFilters)) return null;
  const filters = [];
  if (commonFilters.constraint) {
    filters.push(parseFilter(commonFilters.constraint));
  }

  if (commonFilters.satType) {
    const satType = SatTypeEnum.valueOf(commonFilters.satType);
    filters.push(_.cloneDeep(services.getSatService().getSatFilter(satType)));
  }

  if (filters.length === 0) return null;
  if (filters.length === 1) return filters[0];

  let commonFiltersResult = filters.find(filter => filter instanceof BoolFilter);

  const result = BoolFilter.must(_.cloneDeep(commonFiltersResult), filters.filter(filter => filter !== commonFiltersResult));
  return result;
};