import _ from 'lodash';
import { SharedFilterNames } from '../../application/service/filterService';
import { services } from '../../application/service/services';
import { variableResolverService } from './variableResolverService';
import { VisualizationDefinition } from "../model/viz/VisualizationDefinition";
import { DashboardDefinition } from "../model/dashboard/DashboardDefinition";
import { VisualizationInstance } from "../model/viz/VisualizationInstance";
import { traverseObject } from "../../utils/objectUtils";
import { toArray } from "../../utils/collectionUtils";
import { TFunction } from "i18next";
import { ResolvedParams } from "../model/params/resolve/ResolvedParams";
import { DateRange } from '../../application/model/DateRange';
import { ApiFilter } from "../../utils/query/filter/ApiFilter";

export class DashboardParamsService {
  getDateRange(viz?: VisualizationInstance): DateRange {
    if (viz && viz.definition.params.dateRange) {
      return DateRange.parse(viz.definition.params.dateRange);
    }
    return services.getFilterService().getValue(SharedFilterNames.DATE_RANGE);
  }

  getDashboardParamsForApi(dashboardDefinition: DashboardDefinition, resolvedParams: ResolvedParams, commonFilters:ApiFilter): any {
    return {
      dateRange: this.getDateRange().toPlainObject(),
      commonFilters: commonFilters?.toPlainObject()
    };
  }

  resolveVizSpec(vizDefinition: VisualizationDefinition, resolvedParams: ResolvedParams): any {
    const result = _.cloneDeep(vizDefinition.spec);

    variableResolverService.resolveObject(result, resolvedParams.valueAsPlainObject());
    if(vizDefinition.type.specResolver){
      vizDefinition.type.specResolver(result, resolvedParams);
    }

    return result;
  }

  findParamLabelFromConstraint(t: TFunction, vizInstance: VisualizationInstance, full: boolean = false,
                               unresolvedConstraint?: any): string {
    const dashboardId = vizInstance.dashboard.id;

    const constraintToTraverse = unresolvedConstraint || services.getDashboardService().getOriginalVizSpec(vizInstance)?.params?.constraint;

    if (!constraintToTraverse) {
      return null;
    }

    // collect dashboard parameters involved in the viz param constraint
    const involvedParamNames = new Set<string>();
    traverseObject(constraintToTraverse, (o) => {
      const variableName = variableResolverService.resolveVariableName(o);
      if (variableName) {
        involvedParamNames.add(variableResolverService.getParamNameFromVariableName(variableName));
      }
    });

    // there are multiple params or no params at all involved in the viz constraint
    // so we cannot tell which param should be taken
    if (involvedParamNames.size !== 1) {
      return null;
    }

    // find param values related to this param
    const involvedParamName: string = involvedParamNames.values().next().value;
    const paramValues = toArray(vizInstance.dashboard.dashboardParams.findParamValues(involvedParamName));

    // if there are none or many values, we fallback to the params label (if it has got one)
    if (paramValues.length !== 1) {
      return DashboardParamsService.getParamLabel(dashboardId, involvedParamName);
    }

    const paramValue = paramValues[0];

    // if the single param value is considered as multi valued, we fallback to the params label (if it has got one)
    if (paramValue.isMultiValued()) {
      return DashboardParamsService.getParamLabel(dashboardId, involvedParamName);
    }

    return full ? paramValue.getFullLabel(t) : paramValue.getLabel(t);
  }

  private static getParamLabel(dashboardId: string, paramName: string): string {
    return services.getI18nService().translateSilently(`vizParam.${dashboardId}.${paramName}`, null);
  }
}

services.registerService('dashboardParamsService', new DashboardParamsService());
