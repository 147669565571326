import * as React from 'react';

interface BlankSeparatorProps {
  size?: number,
  vertical?: boolean,
}

export class BlankSeparator extends React.PureComponent<BlankSeparatorProps> {
  static defaultProps: Partial<BlankSeparatorProps> = {
    size: 8,
    vertical: false
  };

  render() {
    const { size, vertical } = this.props;

    if (size <= 0) {
      return null;
    }

    return vertical ? <div style={{height: size}}/> : <div style={{width: size, display:'inline-block'}}/>;
  }
}