import { equalsInAnyOrder } from '../../../../utils/collectionUtils';
import { TreeCtrl } from './TreeCtrl';
import {TreeModel} from "./TreeModel";

export class TreeExpansionCtrl extends TreeCtrl {
  private _treeModel: TreeModel<any>;
  private _expandedItems: any[];

  constructor(treeModel:TreeModel<any>, expandedItems, onExpansionChanged = undefined) {
    super(onExpansionChanged);
    this._treeModel = treeModel;
    this._expandedItems = expandedItems || [];
  }

  /**
   * @return {TreeModel}
   */
  get tree():TreeModel<any> {
    return this._treeModel;
  }

  isExpanded = (item) => {
    return this._treeModel.nodesContain(this.getExpandedItems(), item);
  };


  toggleExpansionState = (item, deep:boolean  = false) => {
    if (this.isExpanded(item)) {
      this.collapse(item, deep);
    } else {
      this.expand(item, deep);
    }
  };


  collapse = (item, deep = false) => {
    if (this._treeModel.isLeave(item)) {
      return;
    }

    const itemsToCollapse = deep ? this._treeModel.flatten(item) : [item];
    this.setExpandedItems(this.getExpandedItems().filter(s => !this._treeModel.nodesContain(itemsToCollapse, s)));
  };

  expand = (item, deep = false) => {
    if (this._treeModel.isLeave(item)) {
      return;
    }

    const newExpandedItems = [...this.getExpandedItems()];

    const action = (it) => {
      if (!this._treeModel.nodesContain(newExpandedItems, it)) {
        newExpandedItems.push(it)
      }
    };

    if (deep) {
      this._treeModel.flatten(item).forEach(action);
    } else {
      action(item);
    }

    this.setExpandedItems(newExpandedItems);
  };

  expandAll = () => {
    this.setExpandedItems(this._treeModel.flattenTree().filter(item => !this._treeModel.isLeave(item)));
  };

  collapseAll = () => {
    this.setExpandedItems([]);
  };

  setExpandedItems = (expandedItems) => {
    const hasChanged = !equalsInAnyOrder(expandedItems, this._expandedItems, this._treeModel.nodesEqual.bind(this._treeModel));

    this._expandedItems = expandedItems;

    if (hasChanged) {
      this.notify(expandedItems);
    }
  };

  getExpandedItems = () => {
    return this._expandedItems;
  };

  find = (fn) => {
    return this._treeModel.find(fn);
  };
}
