import React from 'react';
import CircularProgressbar from './circular_progress_bar';

interface RadialGaugeProps {
    data:any
}

export class RadialGauge extends React.PureComponent<RadialGaugeProps> {
    private chartInstance: any;

    render() {
        const counterClockwise = this.props.data.value < 0;
        const value = Math.abs(this.props.data.value);
        const text = counterClockwise ? '-' + value : '+' + value;
        return (
            <CircularProgressbar
                value={value}
                counterClockwise={counterClockwise}
                max={10}
                text={text}
                styles={{path: {stroke: this.props.data.color}}}
                ref={(ref: any) => (this.chartInstance = ref && ref.chartInstance)}
            />
        );
    }
}
