import axios from "axios";
import {Progress} from "../../../administration/model";
import {RelaunchStep} from "../../../administration/service/sourcingService";

const url = (tenantId: string, endpoint: string): string => {
    return tenantId
        ? `/api/sourcing/${tenantId}${endpoint}`
        : `/api/sourcing${endpoint}`
}

const getProgress = async (page: number, pageSize: number, tenantId?: string): Promise<Array<Progress>> => {
    const from = pageSize * (page - 1);
    return axios
        .get(url(tenantId, `/*/_counters?from=${from}&pageSize=${pageSize}`),)
        .then(response => response.data);
}

const cancelAll = async (tenantId?: string) => {
    return axios
        .get(url(tenantId, '/*/_cancel'))
        .then(response => response.data);
}

const deleteAll = async (tenantId?: string) => {
    return axios
        .delete(url(tenantId, '/*/_delete'))
        .then(response => response.data);
}

const replayAll = async (tenantId?: string) => {
    return axios
        .get(url(tenantId, '/*/_replay/vecko-sourcing-original,vecko-feedback-transcribed,vecko-feedback-api'))
        .then(response => response.data);
}

const reindex = (tenantId?: string) => {
    return axios
        .get('/api/sourcing/_reindex/' + tenantId)
        .then(response => response);
};

const reindexStreams = () => {
    return axios
        .get('/api/sourcing/streams/_reindex')
        .then(response => response);
};

const cleanup = async (tenantId?: string) => {
    return axios
        .get(url(tenantId, '/_cleanup'))
        .then(response => response.data);
}

const cancelSubstream = (substream: string, tenantId?: string) => {
    return axios
        .get(url(tenantId, `/${substream}/_cancel`))
        .then(response => response.data);
}

const deleteSubstream = (substream: string, tenantId?: string) => {
    return axios
        .delete(url(tenantId, `/${substream}/_delete`))
        .then(response => response.data);
}

const replaySubstream = (substream: string, tenantId?: string) => {
    return axios
        .get(url(tenantId, `/${substream}/_replay/vecko-sourcing-original,vecko-feedback-transcribed,vecko-feedback-api`))
        .then(response => response.data);
}

const relaunchProcess = (tenantId: string, substream: string, id: string,  step:RelaunchStep) => {
    return axios
        .get(url(null, `/tenant/${tenantId}/substream/${substream}/id/${id}/_relaunch?step=${step}`),)
        .then(response => response.data);
}

export default {
    getProgress,
    cancelAll,
    deleteAll,
    replayAll,
    cleanup,
    cancelSubstream,
    deleteSubstream,
    replaySubstream,
    reindex,
    reindexStreams,
    relaunchProcess
};
