import _ from "lodash";

export class TreeCtrl {
  private _controlledListener: (value) => void;
  private _uncontrolledListener: (value) => void;
  /**
   * @param {function} controlledListener
   */
  constructor(controlledListener: (value) => void = undefined) {
    this._controlledListener = controlledListener;
  }

  notify(value) {
    if (this._controlledListener) {
      this._controlledListener(value);
    }
    if (this._uncontrolledListener) {
      this._uncontrolledListener(value);
    }
  }

  isUncontrolled() {
    return _.isNil(this._controlledListener);
  }

  bind(uncontrolledListener:(value) => void) {
    this._uncontrolledListener = uncontrolledListener;
  }

  unbind() {
    this._uncontrolledListener = null;
  }
}
