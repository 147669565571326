import { VisualizationDefinition } from '../viz/VisualizationDefinition';
import { VisualizationInstance } from '../viz/VisualizationInstance';
import _ from "lodash";
import { index } from "../../../utils/collectionUtils";
import { ResolvedParams } from "../params/resolve/ResolvedParams";

interface DashboardSpec {
  visualizations: Array<any>
}

export class Dashboard {
  static create(dashboardId: string, dashboardParams:ResolvedParams, dashboardSpec: any, data: Dict<any>): Dashboard {
    const vizDefinitions = dashboardSpec.visualizations
      .filter(vizSpec => !_.isNil(data[vizSpec.id]))
      .map(vizSpec => new VisualizationDefinition(vizSpec));

    return new Dashboard(dashboardId, dashboardParams, vizDefinitions, data);
  }

  public readonly id: string;
  public readonly dashboardParams: ResolvedParams;
  public readonly visualizations: Array<VisualizationInstance>;
  private readonly visualizationsMap: Dict<VisualizationInstance>;

  private constructor(id: string, dashboardParams:ResolvedParams, vizDefinitions: Array<VisualizationDefinition>, data: Dict<any>) {
    this.id = id;
    this.dashboardParams = dashboardParams;
    this.visualizations = vizDefinitions.map(vizDefinition => new VisualizationInstance(this, vizDefinition, data[vizDefinition.id]));
    this.visualizationsMap = index(this.visualizations, viz => viz.id);
  }

  getVizById(id: string): VisualizationInstance {
    return this.visualizationsMap[id];
  }

  getSpec(): DashboardSpec {
    return {
      visualizations: this.visualizations.map(viz => viz.definition.spec)
    }
  }
}
