import { services } from '../../application/service/services';
import { FirstConfigurationType } from "../service/configurationManagerService";

export const configurationEditorState = () => ({
  state: {
    configurations: {},
    selectedConfigurationKind: null,
    hints: null,

  },
  reducers: {
    setConfigurations(state, payload) {
      state.configurations = payload;

      if (!state.selectedConfigurationKind) {
        state.selectedConfigurationKind = FirstConfigurationType
      }

      return state;
    },
    setSelectedConfigurationKind(state, payload) {
      state.selectedConfigurationKind = payload;
      return state;
    },
    setHints(state, payload) {
      state.hints = payload;
      return state;
    },
    clear(state, payload) {
      state.hints = null;
      return state;
    }
  },
  effects: (dispatch) => ({
    async loadConfigurations(payload, rootState) {
      dispatch.configurationEditor.setConfigurations(
        await services.getConfigurationManagerService().getConfigurations());
    },
    async save(payload, rootState) {
      dispatch.configurationEditor.clear();
      const hints = await services.getConfigurationManagerService().save(payload);
      dispatch.configurationEditor.setHints(hints);
      if (!(!!hints?.length)) {
        dispatch.configurationEditor.setConfigurations(payload);
      }
    },
    async validate(payload, rootState) {
      dispatch.configurationEditor.clear();
      dispatch.configurationEditor.setHints(
        await services.getConfigurationManagerService().validate(payload));
    }
  })
});