import {authMockData} from './data/authMockData';


const roles = authMockData;
const hasRole = (role) => {
    return roles.indexOf(role) >= 0;
};

const hasOneOfRoles = (rolesToFind: string[]) => {
    var result = rolesToFind.findIndex(role => roles.indexOf(role) >= 0 ) !== -1;
    return result;
};

export default {hasRole, hasOneOfRoles};