import React from "react";
import { signed } from "../../utils/text_transform";
import { TrendIcon } from "./TrendIcon";
import { LabelIcon } from "../../../../component/LabelIcon";
import { Circle } from "../Circle";

interface TrendProps {
  value: number,
  labelFontSize?: number
  labelColor?: string
  iconSize?: number
}

export class Trend extends React.PureComponent<TrendProps> {
  render() {
    const { value, labelFontSize, labelColor, iconSize } = this.props;

    return <LabelIcon label={<span style={{ fontSize: labelFontSize }}>{signed(value)}</span>}
                      color={labelColor}
                      icon={<Circle size={iconSize} text={<TrendIcon value={value} size={iconSize - 4} withTooltip={false}/>}/>}/>;
  }
}