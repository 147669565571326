import React from 'react';
import './circular_progress_bar.scss';

interface CircularProgressbarProps {
    value: number,
    className?: string,
    text?: string,
    classes?: any,
    styles?: any,
    strokeWidth?: number,
    background?: boolean,
    backgroundPadding?: number,
    initialAnimation?: boolean,
    counterClockwise?: boolean,
    centerX: any,
    centerY: any,
    radius: number,
    maxX?: number,
    maxY?: number,
    min?: number,
    max?: number
}

class CircularProgressbar extends React.Component<CircularProgressbarProps> {

    state = {
        value: null
    };
    static defaultProps = {
        min: 0,
        max: 100,
        maxX: 100,
        maxY: 100,
        radius: 50,
        centerX: 50,
        centerY: 50,
        strokeWidth: 10,
        className: '',
        text: null,
        classes: {
            root: 'CircularProgressbar',
            trail: 'CircularProgressbar-trail',
            path: 'CircularProgressbar-path',
            text: 'CircularProgressbar-text',
            background: 'CircularProgressbar-background'
        },
        styles: {
            root: {},
            trail: {},
            path: {},
            text: {},
            background: {}
        },
        background: false,
        backgroundPadding: null,
        initialAnimation: false,
        counterClockwise: false
    };
    private initialTimeout: any;
    private requestAnimationFrame: number;

    constructor(props) {
        super(props);

        this.state = {
            value: props.initialAnimation ? 0 : props.value
        };
    }

    componentDidMount() {
        if (this.props.initialAnimation) {
            this.initialTimeout = setTimeout(() => {
                this.requestAnimationFrame = window.requestAnimationFrame(() => {
                    this.setState({
                        value: this.props.value
                    });
                });
            }, 0);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        });
    }

    componentWillUnmount() {
        clearTimeout(this.initialTimeout);
        window.cancelAnimationFrame(this.requestAnimationFrame);
    }

    getBackgroundPadding() {
        if (this.props.background) {
            // default padding to be the same as strokeWidth
            // compare to null because 0 is falsy
            if (this.props.backgroundPadding == null) {
                return this.props.strokeWidth;
            }
            return this.props.backgroundPadding;
        }
        // don't add padding if not displaying background
        return 0;
    }

    getPathDescription() {
        const radius = this.getPathRadius();
        const rotation = this.props.counterClockwise ? 1 : 0;

        // Move to center of canvas
        // Relative move to top canvas
        // Relative arc to bottom of canvas
        // Relative arc to top of canvas
        return `
      M ${this.props.centerX},${this.props.centerY}
      m 0,-${radius}
      a ${radius},${radius} ${rotation} 1 1 0,${2 * radius}
      a ${radius},${radius} ${rotation} 1 1 0,-${2 * radius}
    `;
    }

    getPathStyles() {
        const diameter = Math.PI * 2 * this.getPathRadius();
        const truncatedValue = Math.min(Math.max(this.state.value, this.props.min), this.props.max);
        const dashoffset = (1.0 - (truncatedValue / this.props.max)) * diameter;

        return {
            strokeDasharray: `${diameter}px ${diameter}px`,
            strokeDashoffset: `${this.props.counterClockwise ? -dashoffset : dashoffset}px`
        };
    }

    getPathRadius() {
        // the radius of the path is defined to be in the middle, so in order for the path to
        // fit perfectly inside the 100x100 viewBox, need to subtract half the strokeWidth
        return this.props.radius - this.props.strokeWidth / 2 - this.getBackgroundPadding();
    }

    render() {
        const {
            centerX,
            centerY,
            className,
            classes,
            styles,
            strokeWidth,
            text
        } = this.props;
        const pathDescription = this.getPathDescription();

        return (
            <svg className={`${classes.root} ${className}`} style={styles.root}
                 viewBox={`0 0 ${this.props.maxX} ${this.props.maxY}`}>
                {this.props.background ? (
                    <circle className={classes.background} style={styles.background} cx={centerX} cy={centerY}/>
                ) : null}

                <path
                    className={classes.trail}
                    style={styles.trail}
                    d={pathDescription}
                    strokeWidth={strokeWidth}
                    fillOpacity={0}
                />

                <path
                    className={classes.path}
                    d={pathDescription}
                    strokeWidth={strokeWidth}
                    fillOpacity={0}
                    style={Object.assign({}, styles.path, this.getPathStyles())}
                />

                {text ? (
                    <text className={classes.text} style={styles.text} x={centerX} y={centerY}>
                        {text}
                    </text>
                ) : null}
            </svg>
        );
    }
}


export default CircularProgressbar;
