import { Button, InputGroup, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import React from 'react';
import { InfiniteCache } from '../utils/InfiniteCache';
import { ListEditor } from './ListEditor';
import './stringListEditor.scss';

export class StringListEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueToAdd: '',
      canAdd: false,
      values: [...props.values]
    };
  }

  onValueChangedHandler = new InfiniteCache(i => (event) => {
    this.onValueChange(i, event);
  });

  componentWillReceiveProps(nextProps, nextContext) {
    if (!_.isEqual(this.props.values, nextProps.values)) {
      this.setState({ values: [...nextProps.values] });
    }
  }

  onValueToAddFieldChange = (event) => {
    this.setValueToAdd(event.target.value);
  };

  onAddValue = () => {
    if (this.state.canAdd) {
      this.setState({ values: [...this.state.values, this.state.valueToAdd] });
      this.setValueToAdd('');
      this._addField.focus();
    }
  };

  setValueToAdd = (value) => {
    this.setState({ valueToAdd: value, canAdd: !_.isEmpty(value) });
  };

  onListEditorChanged = (values) => {
    this.setState({ values: values });
  };

  onValueChange = (index, event) => {
    const { values } = this.state;
    const value = event.target.value;

    const oldValue = values[index];
    if (oldValue !== value) {
      this.setState({ values: [...values.slice(0, index), value, ...values.slice(index + 1)] });
    }
  };

  notifyValuesChanged = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.values);
    }
  };

  catchReturn = (e) => {
    if (e.key === 'Enter') {
      this.onAddValue();
      e.preventDefault();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.values, this.state.values)) {
      this.notifyValuesChanged();
    }
  }

  renderListEditorItem = (item, i) => {
    return <InputGroup fill={true} value={item} onChange={this.onValueChangedHandler.get(i)}/>;
  };

  render() {
    const { values } = this.state;
    const clazz = classNames(this.props.className, 'list-editor');

    return <div className={clazz}>
      <div className="list-editor-add">
        <InputGroup fill={true}
                    value={this.state.valueToAdd}
                    inputRef={field => this._addField = field}
                    placeholder={this.props.addValueFieldLabel}
                    onKeyPress={this.catchReturn}
                    onChange={this.onValueToAddFieldChange}
                    rightElement={<Button intent={Intent.PRIMARY} minimal={true} icon="add"
                                          disabled={!this.state.canAdd} onClick={this.onAddValue}/>}
        />
      </div>
      {
        <ListEditor onChanged={this.onListEditorChanged}
                    renderItem={this.renderListEditorItem}
                    items={values}
                    fill={true}
                    enableItemOrdering={true}/>
      }
    </div>;
  }
}

ListEditor.propTypes = {
  values: PropTypes.array.isRequired,
  className: PropTypes.string,
  addValueFieldLabel: PropTypes.string,
  onChange: PropTypes.func,
};