import { services } from "../../application/service/services";
import { DataIntegrationApi } from "../service/dataIntegrationService";
import { firstItem } from "../../utils/collectionUtils";

export interface ApiRef {
  api: DataIntegrationApi,
  isNew: boolean
}

export const dataIntegrationState = () => ({
  state: {
    username: null,
    password: null,
    apis: [],
    selectedApi: null,

    intervals: [],
  },
  reducers: {
    setUsernamePassword(state, payload) {
      state.username = payload.username;
      state.password = payload.password;
      return state;
    },
    setApis(state, payload) {
      state.apis = payload.sort((api1, api2) => api1.api.api.localeCompare(api2.api.api));
      state.selectedApi = firstItem(state.apis);
      return state;
    },
    selectApi(state, payload) {
      state.selectedApi = payload;
      return state;
    },
    selectApiById(state, payload) {
      state.selectedApi = state.apis.find(api => api.api.id === payload);
      return state;
    },
    addNewApi(state, payload) {
      const newApi = { api: services.getDataIntegrationService().newApi(payload), isNew: true };
      state.apis = [... state.apis, newApi];
      state.selectedApi = newApi;
      return state;
    },

    setIntervals(state, payload) {
      state.intervals = payload;
      return state;
    }
  },
  effects: (dispatch) => ({
    async loadConfiguration(payload, rootState) {
      const configuration = await services.getDataIntegrationService().getConfiguration();
      dispatch.dataIntegration.setUsernamePassword({
        username: configuration?.login,
        password: configuration?.password
      });
    },
    async saveConfiguration(payload, rootState) {
      await services.getDataIntegrationService().saveConfiguration(payload.username, payload.password);
    },
    async loadApis(payload, rootState) {
      const apis = await services.getDataIntegrationService().getApis() || [];
      dispatch.dataIntegration.setApis(apis.map(api => ({ api: api, isNew: false })));
    },
    async saveApi(payload, rootState) {
      const savedApi = await services.getDataIntegrationService().saveApi(payload.api);
      await dispatch.dataIntegration.loadApis();

      dispatch.dataIntegration.selectApiById(savedApi.id);
    },
    async deleteApi(payload, rootState) {
      await services.getDataIntegrationService().deleteApi(payload.api);
      dispatch.dataIntegration.setApis([
        ...rootState.dataIntegration.apis.filter(api => api.api.id !== payload.api.id),
      ]);
      dispatch.dataIntegration.selectApi(null);
    },

    async loadIntervals(payload, rootState) {
      if (rootState.dataIntegration.selectedApi.isNew) {
        dispatch.dataIntegration.setIntervals([]);
      } else {
        dispatch.dataIntegration.setIntervals(await services.getDataIntegrationService().getIntervals(rootState.dataIntegration.selectedApi.api));
      }
    },
    async deleteIntervals(payload, rootState) {
      await services.getDataIntegrationService().deleteIntervals(rootState.dataIntegration.selectedApi.api);
      await dispatch.dataIntegration.loadIntervals();
    }
  })
});