import { SatTypeEnum } from '../../../../application/model/SatTypeEnum';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { getTitle } from '../common';
import { SatEvolutionViz } from './satEvolutionViz';

registerVizType({
  name: 'SAT_EVOLUTION',
  component: SatEvolutionViz,
  title: (vizInstance, t) => {
    const title = getTitle(vizInstance, t);
    if(title){
      return title;
    }
    const satType = SatTypeEnum.valueOf(vizInstance.definition.params.satType);
    return t(`dashboard.viz.satEvolution.title`, { satType: satType.getAliasedName() });
  },
  noDataPolicy: {
    hasData: vizInstance => {
      return vizInstance.data?.values?.length > 0;
    }
  }
});