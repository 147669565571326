import { PopoverPosition } from '@blueprintjs/core';
import _ from 'lodash';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { services } from '../../../../application/service/services';
import { BlankSeparator } from '../../../../component/BlankSeparator';
import { EditorItem } from '../../../../component/editor/EditorItem';
import { FieldValueSelector } from '../../../../component/selector/FieldValueSelector';
import { SeparatedComponents } from '../../../../component/SeparatedComponents';
import { SortOrderEnum } from '../../../../utils/query/SortOrder';
import { MetricEnum } from '../../../model/MetricEnum';
import { MetricListEditor } from '../../components/editor/MetricListEditor';
import { MetricRangesEditor } from '../../components/editor/MetricRangesEditor';
import { MetricSortEditor } from '../../components/editor/MetricSortEditor';
import './rankingEditor.scss';
import { VisualizationInstance } from "../../../model/viz/VisualizationInstance";


interface RankingEditorProps extends WithTranslation {
  viz: VisualizationInstance,
  editorData: any,
  onValueChanged: (paramPropertyName: 'uiParams' | 'params', paramName: string, data: any) => void
}

class RankingEditorComponent extends React.PureComponent<RankingEditorProps> {
  onMetricRangesChanged = (metricRanges) => {
    this.props.onValueChanged('params', 'ranges', metricRanges ?
      metricRanges.map(r => ({ metric: r.metric.name, range: r.range }))
      : null);
  };

  onExclusionsChanged = (exclusions) => {
    this.props.onValueChanged('params', 'exclusions', exclusions ?
      exclusions.map(e => e.fullName)
      : null);
  };

  onInclusionsChanged = (inclusions) => {
    this.props.onValueChanged('params', 'inclusions', inclusions ?
      inclusions.map(e => e.fullName)
      : null);
  };

  onMetricSortChanged = (metricSort) => {
    this.props.onValueChanged('params', 'sort', metricSort ?
      metricSort.map(r => ({ metric: r.key.name, order: r.order.name }))
      : null);
  };

  onMetricDisplayChanged = (metrics) => {
    this.props.onValueChanged('uiParams', 'metrics', metrics ? metrics.map(m => m.name) : null);
  };

  render() {
    const { t, viz, editorData } = this.props;
    let metrics = MetricEnum.values;
    if (_.isNil(viz.definition.params.satType)) {
      metrics = metrics.filter(enumValue => enumValue != MetricEnum.SAT_SCORE && enumValue != MetricEnum.TREND);
    }
    const ranges = (editorData.params.ranges || [])
      .map(r => ({ metric: MetricEnum.valueOf(r.metric), range: r.range }));

    const fieldValues = services.getFieldsService().getFieldValues(viz.definition.params.field);
    const exclusions = (editorData.params.exclusions || [])
      .map(e => fieldValues.find(e));
    const inclusions = (editorData.params.inclusions || [])
      .map(e => fieldValues.find(e));

    const sort = (editorData.params.sort || [])
      .map(o => ({ key: MetricEnum.valueOf(o.metric), order: SortOrderEnum.valueOf(o.order) }));

    const displayedMetrics = (editorData.uiParams.metrics || [])
      .map(o => MetricEnum.valueOf(o));

    return <div className="rankingEditor">
      <SeparatedComponents separator={<BlankSeparator vertical={true}/>}>
        <EditorItem title={t('dashboard.viz.ranking.editor.metricRanges')}>
          <MetricRangesEditor metrics={metrics} items={ranges} onChanged={this.onMetricRangesChanged}
                              annihilateEnterKey={true}/>
        </EditorItem>
        <EditorItem title={t('dashboard.viz.ranking.editor.exclusions')}>
          <FieldValueSelector onSelectionChanged={this.onExclusionsChanged}
                              allowEmptySelection={true}
                              allowMultiSelection={true}
                              canSelectLeavesOnly={true}
                              fieldValues={fieldValues}
                              selectedValue={exclusions}
                              selectInputProps={{
                                selectProps: {
                                  fill: true,
                                  popoverProps: {
                                    position: PopoverPosition.BOTTOM_LEFT
                                  },
                                  tagInputProps: {
                                    className: 'exclusions-select-input',
                                  }
                                }
                              }}
          />
        </EditorItem>
        <EditorItem title={t('dashboard.viz.topicSatScore.editor.inclusions')}>
          <FieldValueSelector onSelectionChanged={this.onInclusionsChanged}
                              allowEmptySelection={true}
                              allowMultiSelection={true}
                              canSelectLeavesOnly={true}
                              fieldValues={fieldValues}
                              selectedValue={inclusions}
                              selectInputProps={{
                                selectProps: {
                                  fill: true,
                                  popoverProps: {
                                    position: PopoverPosition.BOTTOM_LEFT
                                  },
                                  tagInputProps: {
                                    className: 'exclusions-select-input',
                                  }
                                }
                              }}
          />
        </EditorItem>
        <EditorItem title={t('dashboard.viz.ranking.editor.sort')}>
          <MetricSortEditor metrics={metrics} items={sort} onChanged={this.onMetricSortChanged}
                            annihilateEnterKey={true}/>
        </EditorItem>
        <EditorItem title={t('dashboard.viz.ranking.editor.display')}>
          <MetricListEditor metrics={metrics} items={displayedMetrics}
                            onChanged={this.onMetricDisplayChanged}
                            annihilateEnterKey={true}/>
        </EditorItem>
      </SeparatedComponents>
    </div>;
  }
}

export const RankingEditor = withTranslation()(RankingEditorComponent);