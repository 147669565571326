import React, { ReactNode } from 'react';
import { ItemRendererProps, ItemRenderer } from "@blueprintjs/select/src/common/itemRenderer";
import { ItemPredicate } from "@blueprintjs/select";

export type ISelectItemRendererContext = {
  id: string;
  text: string,
  highlightedText: ReactNode,
  icon?: JSX.Element,
  selected: boolean
}

export type ISelectItemRenderer<T = any> = (item: T, context: ISelectItemRendererContext, itemProps: ItemRendererProps) => JSX.Element | null;

export interface ISelectUtilProps<T> {
  itemId?: (item: T) => string,
  itemLabel?: (item: T) => string,
  itemIcon?: (item: T) => JSX.Element,
  itemRenderer: ISelectItemRenderer<T>;
}

export class SelectUtil<T> {
  itemLabel: (item: T) => string;
  itemIcon: (item: T) => JSX.Element;
  itemId: (item: T) => string;
  fallbackItemRenderer: ISelectItemRenderer<T>;
  props: ISelectUtilProps<T>;
  isSelectedFn: (item: T) => boolean;

  constructor(fallbackItemRenderer: ISelectItemRenderer<T>) {
    this.fallbackItemRenderer = fallbackItemRenderer;
  }

  getItemLabel = (item: T): string => {
    const fn = this.itemLabel || this.props.itemLabel;
    return fn ? fn(item) : item as any as string;
  };

  getItemIcon = (item: T): JSX.Element => {
    const fn = this.itemIcon || this.props.itemIcon;
    return fn ? fn(item) : null;
  };

  getItemId = (item: T): string => {
    const fn = this.itemId || this.props.itemId;
    return fn ? fn(item) : item as any as string;
  }

  itemPredicate: ItemPredicate<T> = (query: string, item: T, index: number, exactMatch: boolean): boolean => {
    const normalizedTopic = this.getItemLabel(item).toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTopic === normalizedQuery;
    } else {
      return normalizedTopic.indexOf(normalizedQuery) >= 0;
    }
  };

  renderItem: ItemRenderer<T> = (item, itemProps): JSX.Element | null => {
    if (!itemProps.modifiers.matchesPredicate) {
      return null;
    }
    const text = this.getItemLabel(item);
    const highlightedText = SelectUtil.highlightText(text, itemProps.query);

    const renderer = this.props.itemRenderer || this.fallbackItemRenderer;

    return renderer(item, {
          id: this.getItemId(item),
          text: text,
          highlightedText: highlightedText,
          icon: this.getItemIcon(item),
          selected: this.isSelectedFn(item)
        },
        itemProps
    );
  }

  private static highlightText = (text, query):ReactNode => {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(SelectUtil.escapeRegExpChars);
    if (words.length === 0) {
      return [text];
    }
    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens = [];
    while (true) {
      const match = regexp.exec(text);
      if (!match) {
        break;
      }
      const length = match[0].length;
      const before = text.slice(lastIndex, regexp.lastIndex - length);
      if (before.length > 0) {
        tokens.push(before);
      }
      lastIndex = regexp.lastIndex;
      tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
      tokens.push(rest);
    }
    return tokens;
  }

  private static escapeRegExpChars(text) {
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  }
}
