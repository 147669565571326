import _ from 'lodash';
import { services } from '../../application/service/services';
import { getVizEditorStates } from '../model/viz/visualizationsManager';
import { getDashboard, getDashboardResolvedParams, getEditedViz } from './selectors/dashboardSelector';

const clearEditorState = (state) => {
  delete state.vizId;
  delete state.dataInEdition;
};

export const dashboardVizEditorState = () => ({
  state: { validatedData: {} },
  reducers: {
    setCurrentViz(state, payload) {
      state.vizId = payload.vizId;
      state.dataInEdition = payload.dataInEdition;
      return state;
    },
    clear(state, payload) {
      clearEditorState(state);
      state.validatedData = {};
      return state;
    },
    clearEditValues(state, payload) {
      delete state.dataInEdition[payload.kind];
      return state;
    },
    setEditValue(state, payload) {
      _.set(state.dataInEdition, `${payload.kind}.${payload.key}`, payload.value);
      return state;
    },
    setEditValues(state, payload) {
      payload.forEach(p => {
        _.set(state.dataInEdition, `${p.kind}.${p.key}`, p.value);
      });
      return state;
    },
    cancel(state, payload) {
      clearEditorState(state);
    },
    setValidatedData(state, payload) {
      state.validatedData[state.vizId] = state.dataInEdition;
      clearEditorState(state);
    }

  },
  effects: (dispatch) => ({
    start(payload, rootState) {
      const vizId = payload;

      let dataInEdition;

      // restore previous validated data if exists
      const previousValidatedData = rootState.dashboardVizEditor.validatedData[vizId];
      if (previousValidatedData) {
        dataInEdition = _.cloneDeep(previousValidatedData);
      } else {
        // ask editor to create initial data (only if vizEditor has an init method)
        const dashboard = getDashboard(rootState);
        const viz = dashboard.getVizById(vizId);
        const vizEditor = viz.definition.getEditor();
        if (vizEditor.init) {
          dataInEdition = vizEditor.init(viz);
        }
      }

      dispatch.dashboardVizEditor.setCurrentViz({vizId, dataInEdition: dataInEdition || {}});
    },
    reset(payload, rootState) {
      getVizEditorStates()
        .map(state => dispatch[state.name].reset)
        .filter(reset => !_.isUndefined(reset))
        .forEach(reset => reset());
    },
    async validate(payload, rootState) {
      const viz = getEditedViz(rootState);
      const shouldUpdate = viz.applyEdition(rootState.dashboardVizEditor.dataInEdition);

      if (shouldUpdate) {
        dispatch.dashboardVizEditor.clearEditValues({ kind: 'data' });
        await services.getDashboardService().refreshVizInstanceData(viz, getDashboardResolvedParams(rootState));

        // refresh dashboard data state
        dispatch.dashboardData.setVizData({vizId: viz.id, data: viz.data});
      }

      //refresh resolvedSpec state
      dispatch.dashboard.setVizResolvedSpec({vizId: viz.id, spec: rootState.dashboardVizEditor.dataInEdition});

      // set validated data
      dispatch.dashboardVizEditor.setValidatedData();
    }
  })
});