import { CategoryField } from './CategoryField';
import { Field } from './Field';
import { FieldDisplay } from './FieldDisplay';

export const parseField = (name: string, obj: any):Field => {
  let field;
  if (name.match(CategoryField.NAME_REGEX)) {
    field = new CategoryField(name);
  } else {
    field = new Field(name);
  }

  if (obj.enabled === false) {
    field.enabled = false;
  }

  if (obj.exportable) {
    field.exportable = true;
  }

  if (obj.display) {
    Object.entries(obj.display)
      .filter(([, v]) => (v as any).enabled === true)
      .map(([k, v]) => new FieldDisplay(k, (v as any).order))
      .forEach(fd => field.addDisplay(fd));
  }
  return field;
};