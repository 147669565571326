import { ParamValue } from "../values/ParamValue";

export abstract class Condition {
  readonly type: string;

  protected constructor(type: string) {
    this.type = type;
  }

  abstract matches(state: Dict<ParamValue>): boolean;

  asPlainObject(): any {
    return {
      '@type': this.type
    };
  }

}