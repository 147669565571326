import _ from 'lodash';
import flatten from 'lodash/fp/flatten';
import map from 'lodash/fp/map';
import {services} from '../../../../application/service/services';
import {BubbleChartDataset, BubbleChartLine, ChartBubbleAxis} from '../../components/bubbleChart/bubbleChartData';
import {ActionPlanArea} from './actionPlanArea';
import {SatType} from "../../../../application/model/SatTypeEnum";

const areas = ActionPlanArea['values'].map(a => ({
    name: a.name(),
    ...a.ui,
    rect: {
        x: a.x,
        y: a.y,
        width: a.width,
        height: a.height
    }
}));

const getAreas = (t) => {
    return areas.map(a => {
        a.label = t(`dashboard.viz.actionPlan.area.${_.toLower(a.name)}`);
        a.showLabel = false;
        return a;
    });
};

export class ActionPlanDatasetBuilder {
    private rawData: any;
    private satType: SatType;

    constructor(rawData, satType: SatType) {
        this.rawData = rawData;
        this.satType = satType;
    }

    build(t): ActionPlanDataset {
        let data;

        if (this.rawData.items.length === 0) {
            data = [];
        } else {
            data = _.flow([
                map((a:any) => {
                    const points = _.filter(this.rawData.items, it => ActionPlanArea['getValueOf'](it.area) === a);
                    return a.comparator(points);
                }),
                flatten,
                map((point:any) => {
                    const x = point.score;
                    const y = point.count;

                    const normalized = {
                        x: point.normalizedX,
                        y: point.normalizedY
                    };

                    const areaKind = ActionPlanArea['getValueOf'](point.area);
                    const area = _.find(areas, a => a.name === point.area);

                    const label = services.getFieldsService().findCategoryLabel('topic', point.category, t);

                    return {
                        label: label,
                        areaKind: areaKind,
                        area: area,
                        raw: point,
                        data: {
                            x: _.round(x, 2),
                            y: y
                        },
                        normalizedData: normalized
                    };
                })
            ])(ActionPlanArea['values']);
        }

        const xAxis = {
            label: this.satType.getLabel(t),
            min: this.satType ? this.satType.min : null,
            max: this.satType ? this.satType.max : null
        }

        const yAxis = {
            label: t(`dashboard.viz.actionPlan.axis.frequency`)
        }

        return new ActionPlanDataset(data, xAxis, yAxis, getAreas(t), null, this.rawData.satScore ? [{
            value:
            this.rawData.satScore.value,
            label: t("dashboard.viz.actionPlan.overall", {
                value: (this.rawData.satScore.value > 0 ? '+' : '') + this.rawData.satScore.value,
                satType: this.satType.getAliasedName()
            })
        }] : []);
    }
}

export class ActionPlanDataset {
    public bubbleChartDataset: BubbleChartDataset;

    public data: any;

    constructor(data, xAxis: ChartBubbleAxis, yAxis: ChartBubbleAxis, areas, horizontalLines: BubbleChartLine[], verticalLines: BubbleChartLine[]) {
        this.data = data;
        this.bubbleChartDataset = new BubbleChartDataset(data, xAxis, yAxis, areas, horizontalLines, verticalLines);
    }

    hasData() {
        return this.bubbleChartDataset.hasData();
    }

    getBestPoints() {
        return _.filter(this.data, p => p.raw.best);
    }

    keepOnlyBestPoints() {
        this._setData(this.getBestPoints());
    }

    keepOnly(items) {
        this._setData(_.filter(this.data, d => {
            return _.find(items, (it) => it.category === d.raw.category);
        }));
    }

    _setData(data) {
        this.data = data;
        this.bubbleChartDataset.data = data;
    }
}
