import { SatType, SatTypeEnum } from '../../../../application/model/SatTypeEnum';
import { ParamValue } from './ParamValue';
import _ from "lodash";
import { TFunction } from "i18next";

export class SatTypeParamValue extends ParamValue {
  static parse(object: any): SatTypeParamValue {
    if (_.isEmpty(object.name)) {
      throw new Error(`satType name is mandatory`);
    }
    const satType = SatTypeEnum.valueOf(object.name);
    if (_.isNil(satType)) {
      throw new Error(`satType ${object.name} is not valid`);
    }

    return new SatTypeParamValue(satType);
  }

  readonly satType: SatType;

  constructor(satType: SatType) {
    super('satType');
    this.satType = satType;
  }

  getId(): string {
    return this.satType.name;
  }

  getLabel(t: TFunction): string {
    return this.satType.getLabel(t);
  }

  getValueAsPlainObject(): any {
    return this.satType.name;
  }

  asPlainObject(): any {
    return {
      ... super.asPlainObject(),
      name: this.satType.name
    };
  }

  isValid(): boolean {
    return !_.isNil(this.satType);
  }

  equals(item: ParamValue): boolean {
    return item instanceof SatTypeParamValue && this.satType === item.satType
  }
}