import { ReportDT0 } from "../dashboard/ReportDTO";

export class VizFeedbacksQueryContext {
  private readonly "@type": string;

  constructor(type?: string) {
    this["@type"] = type ? type : "FeedBacksQueryContext";
  }

  report: ReportDT0;
}

export class MultiFieldsVizFeedbacksQueryContext extends VizFeedbacksQueryContext {
  fields: Array<FieldVizFeedbacksQueryContext>;

  constructor(type?: string) {
    super(type ? type : "MultiFieldsVizFeedbacksQueryContext");
  }
}

export class FieldVizFeedbacksQueryContext extends VizFeedbacksQueryContext {
  field: string;
  selectedValue: any;

  constructor(type?: string) {
    super(type ? type : "FieldFeedBacksQueryContext");
  }
}

export class EvolutionVizFeedbacksQueryContext extends FieldVizFeedbacksQueryContext {
  serieName: string;
  startPeriod: string;
  endPeriod: string;

  constructor(type?: string) {
    super(type ? type : "EvolutionFeedBacksQueryContext");
  }
}