import { Button, Icon } from '@blueprintjs/core';
import React, { MouseEventHandler } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NavItem } from '../../component/nav/NavItem';
import _ from 'lodash';

interface MainMenuItemProps extends WithTranslation {
  navItem: NavItem,
  isOpen: boolean,
  isExpanded: boolean,
  onExpand: MouseEventHandler,
  onCollapse: MouseEventHandler,
  onMouseEnter: MouseEventHandler,
  onClick: MouseEventHandler
}

class MainMenuItemComponent extends React.PureComponent<MainMenuItemProps> {
  render() {
    const {navItem, isOpen, isExpanded, onExpand, onCollapse, onMouseEnter, onClick} = this.props;

    const hasChildren = !_.isNil(navItem.subNav);

    const mainItem = <div key={navItem.to}
                          className='vui-layout--horizontal vui-mainMenu-item'
                          style={{ cursor: navItem.to? 'pointer': 'default'}}
                          onMouseEnter={onMouseEnter}>
      <div className='vui-layout--horizontal__center'>
        <Link to={navItem.to? navItem.to: 'null'}
              style={{ cursor: navItem.to? 'pointer': 'default'}}
              onClick={navItem.to? onClick : (event) => event.preventDefault() }>
          <Button large={true}
                  style={{ cursor: navItem.to? 'pointer': 'default'}}
                  icon={<Icon icon={navItem.icon} iconSize={24}/>}
                  minimal={true}
          />
          {isOpen ? <span>{navItem.label}</span> : null}
        </Link>
      </div>
      {
        isOpen && hasChildren ?
        <Button className='vui-mainMenu-item-expander' minimal={true}
                icon={isExpanded ? 'chevron-up' : 'chevron-down'}
                onClick={isExpanded ? onCollapse : onExpand}/> : null
      }
    </div>;

    if (!isOpen || !isExpanded || !hasChildren) {
      return mainItem;
    }

    return <React.Fragment key={navItem.to}>
      {mainItem}
      <div className='vui-layout--vertical vui-mainMenu-subItem'>
        {navItem.subNav.items
                .map(child =>
                       <Link key={child.to} to={child.to}>
                         <Button minimal={true} onClick={onClick}>{child.label}</Button>
                       </Link>)}
      </div>
    </React.Fragment>;
  }
}

export const MainMenuItem = withTranslation()(MainMenuItemComponent);