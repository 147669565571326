import { Colors, ResizeSensor } from '@blueprintjs/core';
import _ from 'lodash';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FeedbackForReport } from '../../../../feedback/FeedbackForReport';
import { VeckoFonts } from '../../../../style/VeckoFont';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import './customersToRecallViz.scss';
import { services } from "../../../../application/service/services";
import { VeckoColors } from "../../../../style/VeckoColors";
import { InfiniteCache } from "../../../../utils/InfiniteCache";
import {
  mapWithFeedbacksListDispatch,
  mapWithFeedbacksListState
} from "../../../../application/store/state/tools/feedbacksListState";
import { connect } from "react-redux";
import { WithFeedbacksList } from "../../../../feedback/FeedbacksList";

const canvas = document.createElement('canvas');
const getTextWidth = (text, font) => {
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return Math.ceil(metrics.width);
};

const flexItemStyles = {
  titleFontWeight: 600,
  titleFontSize: 20,
  titlePadding: 15,
  itemMargin: 5,
};

const inlineItemStyles = {
  titleFontWeight: 600,
  titleFontSize: 17,
  titleSidePadding: 15,
  titleTopPadding: 0,
  titleBottomPadding: 10,
  itemMargin: 5,
};


interface CustomersToRecallVizProps extends WithTranslation, WithFeedbacksList {
  viz: VisualizationInstance
};

interface Item {
  value: number;
  label: string;
  id: string;
  feedbacks: any;
}

interface State {
  containerWidth: number;
  containerHeight: number;
  selectedValueId: string;
  orderedItems: Item[];
}

interface ItemDimensions {
  titleFontSize?: number;
  height?: number;
  maxHeight?: number;
  valueFontSize?: number;
  width?: any;
}

export class CustomersToRecallVizComponent extends React.PureComponent<CustomersToRecallVizProps> {
  state: State = {
    containerWidth: null,
    containerHeight: null,
    selectedValueId: null,
    orderedItems: null
  };

  onResize = (component) => {
    this.setState({
      containerWidth: component[0].contentRect.width,
      containerHeight: component[0].contentRect.height
    });
  };

  onClick(event, item: Item) {
    this.setState({ selectedValueId: item.id })
  }


  feedbackClickHandlers = new InfiniteCache((id) => () => this.props.getFeedbackDetails(id));

  static getDerivedStateFromProps = (props, state) => {
    const { viz, t } = props;
    const values = _.map(viz.data, (value, key) => ({
      value: value.value,
      label: t(key),
      id: key,
      feedbacks: value.feedbacks
    } as Item));
    const orderedItems = _.orderBy(values, ['value', 'label'], ['desc', 'asc'])
    return {
      orderedItems: orderedItems,
      selectedValueId: state.selectedValueId ? state.selectedValueId : (orderedItems.length > 0 ? orderedItems[0].id : null)
    };
  }

  getInlineItemDimensions(items: Item[]): ItemDimensions {
    let biggestLabelWidth = 0;

    for (const item of items) {
      const textWidth = getTextWidth(item.label,
        `normal ${inlineItemStyles.titleFontWeight} ${inlineItemStyles.titleFontSize}px ${VeckoFonts.textFont}`);
      biggestLabelWidth = Math.max(biggestLabelWidth, textWidth);
    }
    biggestLabelWidth += inlineItemStyles.titleSidePadding * 2;
    return {
      titleFontSize: inlineItemStyles.titleFontSize,
      maxHeight: Math.floor(this.state.containerHeight / 2),
      valueFontSize: 30,
      width: biggestLabelWidth,
    } as ItemDimensions;
  }

  getFlexItemDimensions(items: Item[]): ItemDimensions {
    if (_.isNil(this.state.containerWidth)) {
      return {};
    }
    const itemsCount = items.length;
    const itemMargin = flexItemStyles.itemMargin * 2;

    let numberOfItemsPerLine;
    let titleFontSize = flexItemStyles.titleFontSize;
    let biggestLabelWidth;

    while (true) {
      biggestLabelWidth = 0;
      for (const item of items) {
        const textWidth = getTextWidth(item.label,
          `normal ${flexItemStyles.titleFontWeight} ${titleFontSize}px ${VeckoFonts.textFont}`);
        biggestLabelWidth = Math.max(biggestLabelWidth, textWidth);
      }
      biggestLabelWidth += flexItemStyles.titlePadding * 2;

      numberOfItemsPerLine = Math.floor(this.state.containerWidth / (biggestLabelWidth + itemMargin));

      if (numberOfItemsPerLine > 1 || titleFontSize <= 10) {
        break;
      }
      titleFontSize--;
    }

    const numberOfLines = Math.ceil(itemsCount / numberOfItemsPerLine);
    const itemHeight = Math.floor((this.state.containerHeight / numberOfLines) - itemMargin);

    return {
      titleFontSize: titleFontSize,
      height: itemHeight,
      maxHeight: Math.floor(this.state.containerHeight / 2),
      valueFontSize: Math.min(100, itemHeight * 0.5),
      width: biggestLabelWidth
    } as ItemDimensions;
  }

  renderSamples(samples) {
    return samples.map((sample, index) => {
      const result = [
        <div key={index} className='vui-interactive'
             onClick={this.feedbackClickHandlers.get(sample.id)}
             style={{ padding: 5 }}>
          <FeedbackForReport feedback={sample}/>
        </div>];
      if (index < samples.length - 1) {
        result.push(<hr key={index + '_sep'}/>);
      }

      return result;
    });
  }

  render() {
    const { viz, t } = this.props;
    const uiParams = viz.definition.uiParams;

    const inline =  viz.definition.params.clickable || false;

    const items = this.state.orderedItems;
    const color = uiParams?.color || Colors.GRAY1;


    const maxOpacity = 1;
    const minOpacity = 0.4;
    if (!items) return <div></div>;
    const maxValue = items[0].value;
    const minValue = items[items.length - 1].value;


    const opacity = (val) => (((maxOpacity - minOpacity) / (maxValue - minValue)) * val) + minOpacity;
    let feedbacks = null;

    const itemDimensions = inline ? this.getInlineItemDimensions(items) : this.getFlexItemDimensions(items);
    const wrapperClasses = 'vuiLayoutFlowCentered ' + (inline ? 'customers-to-recall-inline' : 'customers-to-recall-item-flex');
    const itemContainerClasses = 'customers-to-recall-item-container ' + (inline ? 'customers-to-recall-item-container-inline' : 'customers-to-recall-item-container-flex');
    return <ResizeSensor onResize={this.onResize}>
      <div className="customers-to-recall">
        <div className={wrapperClasses}>
          {
            _.map(items, (item) => {
              const op = opacity(item.value);
              const isSelectedItem = item.id === this.state.selectedValueId;
              if (isSelectedItem) {
                feedbacks = item.feedbacks;
              }

              const itemParams = uiParams?.items?.[item.id];

              const styleFn = inline ? this.getInlineItemStyle : this.getFlexItemStyle;

              const titleStyleFn = inline ? this.getInlineItemTitleStyle : this.getFlexItemTitleStyle;

              const valueStyleFn = inline ? this.getInlineItemValueStyle : this.getFlexItemValueStyle;
              const fnArgs = [item, itemParams, itemDimensions, color, op];
              const itemClass = "customers-to-recall-item" + (isSelectedItem ? ' customers-to-recall-item-selected' : '')
              const titleClass = "customers-to-recall-item-title" + (inline ? ' customers-to-recall-item-title-inline' : ' customers-to-recall-item-title-flex')
              const valueClass = "customers-to-recall-item-value" + (inline ? ' customers-to-recall-item-value-inline' : ' customers-to-recall-item-value-flex')
              return <div key={item.id} className={itemClass} style={styleFn.apply(this, fnArgs)}
                          onClick={(event => {
                            this.onClick(event, item)
                          }).bind(this)}>
                <div className={itemContainerClasses}>
                  <div className={titleClass}
                       style={titleStyleFn.apply(this, fnArgs)}>{item.label}</div>
                  <div className={valueClass}
                       style={valueStyleFn.apply(this, fnArgs)}>{item.value}</div>
                </div>
              </div>;
            })
          }
        </div>
        {
          feedbacks?.length > 0 ?
            <div className="customers-to-recall-feedbacks">{this.renderSamples(feedbacks)}</div> : null
        }
      </div>
    </ResizeSensor>;
  }

  private getFlexItemStyle(item: Item, itemParams: any, itemDimensions: ItemDimensions, color: any, op: number) {
    return {
      height: itemDimensions.height,
      maxHeight: itemDimensions.maxHeight,
      margin: flexItemStyles.itemMargin,
      width: itemDimensions.width
    };
  }

  private getFlexItemTitleStyle(item: Item, itemParams: any, itemDimensions: ItemDimensions, color: any, op: number) {
    return {
      backgroundColor: itemParams?.color || color,
      opacity: op,
      fontSize: itemDimensions.titleFontSize,
      fontWeight: flexItemStyles.titleFontWeight,
      padding: flexItemStyles.titlePadding
    };
  }

  private getFlexItemValueStyle(item: Item, itemParams: any, itemDimensions: ItemDimensions, color: any, op: number) {
    return {
      color: itemParams?.color || color,
      opacity: op,
      fontSize: itemDimensions.valueFontSize
    }
  }


  private getInlineItemStyle(item: Item, itemParams: any, itemDimensions: ItemDimensions, color: any, op: number) {
    return {
      height: itemDimensions.height,
      maxHeight: itemDimensions.maxHeight,
      margin: inlineItemStyles.itemMargin,
      width: itemDimensions.width,
      backgroundColor: item.id === this.state.selectedValueId ? itemParams?.color || color : '',
      borderColor: item.id === this.state.selectedValueId ? itemParams?.color || color : null
    };
  }

  private getInlineItemTitleStyle(item: Item, itemParams: any, itemDimensions: ItemDimensions, color: any, op: number) {
    const bgColor = item.id === this.state.selectedValueId ? itemParams?.color || color : VeckoColors.WHITE;
    return {
      color: services.getColorService().getContrastColor(bgColor),
      fontSize: itemDimensions.titleFontSize,
      fontWeight: inlineItemStyles.titleFontWeight,
      padding: inlineItemStyles.titleTopPadding +"px "+ inlineItemStyles.titleSidePadding+"px "+
        inlineItemStyles.titleBottomPadding+"px "+ inlineItemStyles.titleSidePadding+"px"
    };
  }

  private getInlineItemValueStyle(item: Item, itemParams: any, itemDimensions: ItemDimensions, color: any, op: number) {
    const bgColor = item.id === this.state.selectedValueId ? itemParams?.color || color : VeckoColors.WHITE;
    return {
      color: services.getColorService().getContrastColor(bgColor),
      fontSize: itemDimensions.valueFontSize,
      padding: "0px "+ inlineItemStyles.titleSidePadding+"px "+
        "0px "+ inlineItemStyles.titleSidePadding+"px"
    }
  }
}


const mapState = (state) => {
  return ({
  });
};

const mapDispatch = dispatch => ({
  ...mapWithFeedbacksListDispatch(dispatch, 'dashboard')
});

export const CustomersToRecallViz = withTranslation(undefined, { withRef: true })(connect(mapState, mapDispatch, null, {forwardRef: true})(CustomersToRecallVizComponent));

