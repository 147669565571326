import { ApiFilter } from './ApiFilter';

export class VerbatimNotEmptyFilter extends ApiFilter {
  static parse(object):ApiFilter {
    return (new VerbatimNotEmptyFilter() as ApiFilter);
  }

  constructor() {
    super('verbatimNotEmpty');
  }

  getCopy(): ApiFilter {
    const apiFilter = new VerbatimNotEmptyFilter();
    return apiFilter
  }
}