import axios from "axios";
import { CsvOption } from "../../../administration/model";

const sourcingAudio = async (data) => {
  const audioFile: File = data.audioFile;
  const language: string = data.language;
  const audioMetadata: any = data.audioMetadata;
  const metadata: File = data.metadata;

  const formData = new FormData();
  formData.append('audioFile', audioFile)
  formData.append('audioMetadata', new Blob([JSON.stringify(audioMetadata)], { type: 'application/json' }))
  if (metadata) {
    formData.append('metadata', new Blob([await metadata.text()], { type: 'application/json' }))
  }

  return axios
    .post(`/api/sourcing`, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
        'Content-language': language
      }
    })
    .then(response => response.data);
}

const sourcingXlsx = async (file: File) => {
  return axios
    .post(`/api/sourcing?filename=${encodeURIComponent(file.name)}`, file, {
      headers: {
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })
    .then(response => response.data);
}

const sourcingCsv = async (data) => {
  const file: File = data.file;
  const csvOptions: CsvOption = data.csvOptions;

  return axios
    .post(
      `/api/sourcing?filename=${encodeURIComponent(file.name)}&quote=${csvOptions.quoteChar}&delimiter=${csvOptions.delimiterChar}`,
      file, {
      headers: {
        'Content-type': `text/csv;charset=${csvOptions.charset}`
      }
    })
    .then(response => response.data);
}

const sourcingJson = async (file: File) => {
  return axios
    .post(`/api/sourcing`, file, {
      headers: {
        'Content-type': 'application/json'
      }
    })
    .then(response => response.data);
}

export default { sourcingAudio, sourcingXlsx, sourcingCsv, sourcingJson };