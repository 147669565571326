import { services } from '../../application/service/services';
import { asyncComponent } from '../../component/asyncComponent';
import {StorageKind, StorageKindEnum} from '../storage/model/StorageKind';
import { AdministrationGroupModel, AdministrationViewModel } from "../model";
import { Nav } from "../../component/nav/Nav";
import { NavItem } from "../../component/nav/NavItem";
import './storageService';
import './systemService';
import './cacheService';
import './purgeService';
import './sourcingService';
import './sourcingErrorService';
import './progressService';
import './kafkaService';
import './tenantService';
import './configurationManagerService';
import './exportService';
import './dataIntegrationService';

export class AdministrationService {
  private static GROUP_SYSTEM: AdministrationGroupModel = {
    name: 'system',
    icon: 'globe-network'
  };

  private static GROUP_SOURCING: AdministrationGroupModel = {
    name: 'sourcing',
    icon: 'cloud-upload'
  };
  private static GROUP_CONFIGURATION: AdministrationGroupModel = {
    name: 'configuration',
    icon: 'settings'
  };
  private static GROUP_EXPORT: AdministrationGroupModel = {
    name: 'export',
    icon: 'export'
  };
  private static DATA_INTEGRATION: AdministrationGroupModel = {
    name: 'di',
    icon: 'feed'
  };
  private static GROUPS = [
    AdministrationService.GROUP_SYSTEM,
    AdministrationService.GROUP_SOURCING,
    AdministrationService.GROUP_CONFIGURATION,
    AdministrationService.GROUP_EXPORT,
    AdministrationService.DATA_INTEGRATION,
  ]

  private nav: Nav;
  private views: Array<AdministrationViewModel>;

  async init(): Promise<any> {
    await services.getTenantService().init();
    await services.getSystemService().init();
    await services.getStorageService().init();
    await services.getCacheService().init();
    await services.getPurgeService().init();
    await services.getSourcingService().init();
    await services.getSourcingErrorService().init();
    await services.getProgressService().init();
    await services.getKafkaService().init();
    await services.getConfigurationManagerService().init();
    await services.getExportService().init();
    await services.getDataIntegrationService().init();

    this.views = [
      {
        name: 'corpus',
        group: AdministrationService.GROUP_SYSTEM,
        component: asyncComponent(
          {
            loader: () => import('../system/CorpusView'),
            props: {}
          }),
        isAuthorized: () => services.getSystemService().isAuthorized()
      },
      {
        name: 'cache',
        group: AdministrationService.GROUP_SYSTEM,
        component: asyncComponent(
          {
            loader: () => import('../system/CacheView'),
            props: {}
          }),
        isAuthorized: () => services.getCacheService().isAuthorized()
      },
      {
        name: 'purge',
        group: AdministrationService.GROUP_SYSTEM,
        component: asyncComponent(
          {
            loader: () => import('../system/PurgeView'),
            props: {}
          }),
        isAuthorized: () => services.getPurgeService().isAuthorized()
      },
      {
        name: 'storage.demo',
        group: AdministrationService.GROUP_SOURCING,
        component: asyncComponent(
          {
            loader: () => import('../storage/StorageExplorer'),
            props: {
              storageKind: StorageKindEnum.FEEDBACK_DEMO
            }
          }),
        isAuthorized: () => services.getStorageService().isAuthorizedFor(StorageKindEnum.FEEDBACK_DEMO)
      },
      {
        name: 'storage.mirrored',
        group: AdministrationService.GROUP_SOURCING,
        component: asyncComponent(
          {
            loader: () => import('../storage/StorageExplorer'),
            props: {
              storageKind: StorageKindEnum.FEEDBACK_MIRRORED
            }
          }),
        isAuthorized: () => services.getStorageService().isAuthorizedFor(StorageKindEnum.FEEDBACK_MIRRORED)
      },
      {
        name: 'local',
        group: AdministrationService.GROUP_SOURCING,
        component: asyncComponent(
          {
            loader: () => import('../sourcing/SourcingView'),
            props: {}
          }),
        isAuthorized: () => services.getSourcingService().isAuthorized()
      },
      {
        name: 'progress',
        group: AdministrationService.GROUP_SOURCING,
        component: asyncComponent(
          {
            loader: () => import('../sourcing/ProgressView'),
            props: {}
          }),
        isAuthorized: () => services.getProgressService().isAuthorized()
      },
      {
        name: 'logs',
        group: AdministrationService.GROUP_SOURCING,
        component: asyncComponent(
          {
            loader: () => import('../sourcing/LogsView'),
            props: {}
          }),
        isAuthorized: () => services.getKafkaService().isAuthorized()
      },
      {
        name: 'errors',
        group: AdministrationService.GROUP_SOURCING,
        component: asyncComponent(
          {
            loader: () => import('../sourcing/ErrorsView'),
            props: {}
          }),
        isAuthorized: () => services.getSourcingErrorService().isAuthorized()
      },
      {
        name: 'debug',
        group: AdministrationService.GROUP_SOURCING,
        component: asyncComponent(
            {
              loader: () => import('../sourcing/DebugView'),
              props: {}
            }),
        isAuthorized: () => services.getSecurityService().hasRole('vecko.feedback.debug')
      },
      {
        name: 'git',
        group: AdministrationService.GROUP_CONFIGURATION,
        component: asyncComponent(
          {
            loader: () => import('../configuration/GitConfigurationManagerView'),
            props: {}
          }),
        isAuthorized: () => services.getConfigurationManagerService().isAuthorizedForGit()
      },
      {
        name: 'edit',
        group: AdministrationService.GROUP_CONFIGURATION,
        component: asyncComponent(
          {
            loader: () => import('../configuration/ConfigurationsEditorView'),
            props: {}
          }),
        isAuthorized: () => services.getConfigurationManagerService().isAuthorizedForEdit()
      },
      {
        name: 'export',
        group: AdministrationService.GROUP_EXPORT,
        component: asyncComponent(
          {
            loader: () => import('../export/ExportView'),
            props: {}
          }),
        isAuthorized: () => services.getExportService().isAuthorized()
      },
      {
        name: 'di.configuration',
        group: AdministrationService.DATA_INTEGRATION,
        component: asyncComponent(
          {
            loader: () => import('../di/DataIntegrationConfigurationView'),
            props: {}
          }),
        isAuthorized: () => services.getDataIntegrationService().isAuthorizedForConfiguration()
      },
      {
        name: 'di.apis',
        group: AdministrationService.DATA_INTEGRATION,
        component: asyncComponent(
          {
            loader: () => import('../di/DataIntegrationApisView'),
            props: {}
          }),
        isAuthorized: () => services.getDataIntegrationService().isAuthorizedForApis()
      },
    ];
    this.nav = this.createNav();
  }

  getRootPath(): string {
    return '/admin';
  }

  getGroups(): Array<AdministrationGroupModel> {
    return AdministrationService.GROUPS;
  }

  getNav(): Nav {
    return this.nav;
  }

  getDefaultViewName(): string {
    const availableViews = this.getAuthorizedViews();
    if (availableViews.length > 0) {
      return availableViews[0].name;
    }
    return null;
  }

  getViewByName(viewName: string): AdministrationViewModel {
    return this.views.find(v => v.name === viewName);
  }

  getAuthorizedViews(): Array<AdministrationViewModel> {
    return this.views.filter(v => v.isAuthorized());
  }

  getAuthorizedViewsForGroup(group: AdministrationGroupModel): Array<AdministrationViewModel> {
    return this.getAuthorizedViews().filter(v => v.group === group);
  }

  isAuthorized(): boolean {
    return this.getAuthorizedViews().length > 0;
  }

  getPathForView(view: AdministrationViewModel): string {
    return `${this.getPathForGroup(view.group)}/${view.name.replace('.', '-')}`
  }

  getPathForGroup(group: AdministrationGroupModel): string {
    return `${group.name}`
  }

  getViewByUrlPath(urlPath: string): AdministrationViewModel {
    return this.views.find(v => {
      return urlPath === this.getPathForView(v);
    });
  }

  private createNav(): Nav {
    const nav: Nav = new Nav();
    this.views
      .filter(v => v.isAuthorized())
      .forEach(v => {
        nav.withItem(new NavItem(v.name, this.getPathForView(v), v.component))
      });
    if (nav.items.length) {
      nav.withRedirect('*', nav.items[0].to);
    }
    AdministrationService.GROUPS.forEach(g => {
      const firstViewForGroup = this.getFirstViewForGroup(g);
      if (firstViewForGroup) {
        nav.withRedirect(this.getPathForGroup(g), this.getPathForView(firstViewForGroup));
      }
    });

    return nav;
  }

  private getFirstViewForGroup(group: AdministrationGroupModel): AdministrationViewModel {
    return this.views.find(v => v.group === group && v.isAuthorized());
  }
}

services.registerService('administrationService', new AdministrationService());