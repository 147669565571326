import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { Metric } from '../../report/model/MetricEnum';
import { SelectInput } from '../select/SelectInput';

class MetricSelectorComponent extends PureComponent {
  getId = (metric) => {
    return metric.name;
  };

  getLabel = (metric) => {
    return metric.getLabel(this.props.t);
  };

  render() {
    const { t, annihilateEnterKey } = this.props;

    return <SelectInput itemLabel={this.getLabel}
                        itemId={this.getId}
                        items={this.props.items}
                        selectedItem={this.props.selectedItem}
                        onSelectionChanged={this.props.onSelectionChanged}
                        allowEmptySelection={this.props.allowEmptySelection}
                        placeholder={t('satType')}
                        annihilateEnterKey={annihilateEnterKey}
    />;
  }
}

export const MetricSelector = withTranslation()(MetricSelectorComponent);

MetricSelector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Metric)),
  selectedItem: PropTypes.instanceOf(Metric),
  onSelectionChanged: PropTypes.func,
  allowEmptySelection: PropTypes.bool,
  annihilateEnterKey: PropTypes.bool
};

MetricSelector.defaultProps = {
  items: [],
  selectedItem: null,
  allowEmptySelection: false
};