import loadable from '@loadable/component';
import _ from 'lodash';
import React from 'react';
import {asyncComponent} from '../../../component/asyncComponent';
import {Nav} from '../../../component/nav/Nav';
import {Anchor, NavItem} from '../../../component/nav/NavItem';
import {services} from '../../service/services';

const reportComponent = (dashboardDefinition) => {
    return asyncComponent(
        {
            loader: () => import('../../../report/ReportEntryPoint'),
            props: {
                dashboardDefinition: dashboardDefinition
            }
        });
};

const dashboardNav = (nav) => {
    const dashboardDefinitions = services.getDashboardService().dashboardDefinitions;

    const subNav = new Nav();

    const dashboards = dashboardDefinitions.filter(dashboardDefinition => dashboardDefinition.isDashboard);
    dashboards.forEach(dashboardDefinition => {
        subNav.withItem(
            new NavItem(dashboardDefinition.getLabel(services.getI18nService().translateFn),
                `/vecko-dashboard/${dashboardDefinition.id}`,
                reportComponent(dashboardDefinition)
            ));
    });
    const redirect = subNav.items[0] ? subNav.items[0].to : null;
    nav.withItem(new NavItem(services.getI18nService().translate('dashboard_plural').toUpperCase(), redirect,
        reportComponent(dashboards[0]))
        .withIcon('control')
        .withSubNav(subNav));
};

const reportNav = (nav) => {
    const dashboardDefinitions = services.getDashboardService().dashboardDefinitions;

    if (_.isEmpty(dashboardDefinitions)) {
        return;
    }

    const subNav = new Nav();
    const reports = dashboardDefinitions.filter(dashboardDefinition => !dashboardDefinition.isDashboard);
    reports.forEach(dashboardDefinition => {
        subNav.withItem(
            new NavItem(dashboardDefinition.getLabel(services.getI18nService().translateFn),
                `/report/${dashboardDefinition.id}`,
                reportComponent(dashboardDefinition)
            ));
    });
    const redirect = subNav.items[0] ? subNav.items[0].to : null;
    nav.withItem(new NavItem(services.getI18nService().translate('report_plural').toUpperCase(), redirect,
        reportComponent(reports[0]))
        .withIcon('presentation')
        .withSubNav(subNav));
};

export const topicsNav = new Nav()
    .withItem(
        new NavItem(undefined, '/bubble', loadable(() => import('../../../explore/topicsStat/TopicsStatBubbleView')), null, "/topics")
            .withMetadata('heatmap')
    )
    .withItem(
        new NavItem(undefined, '/list', loadable(() => import('../../../explore/topicsStat/TopicsStatListView')), null, "/topics")
            .withMetadata('th')
    )
    .withRedirect('*', '/topics/bubble');

export const createMainNav = (): Nav => {
    const exploreNav = new Nav()
        .withItem(new NavItem(services.getI18nService().translate('customerJourney'), '/topics/*',
            loadable(() => import('../../../explore/topicsStat/TopicsStatView')), false))
        .withItem(new NavItem(services.getI18nService().translate('analysis.title'), '/analysis',
            loadable(() => import('../../../explore/analysis/AnalysisView'))))
        .withItem(new NavItem(_.capitalize(services.getI18nService().translate('misc.conversation_plural')), '/feedbacks',
            loadable(() => import('../../../explore/feedbacks/FeedbacksExplorerView'))))
    ;

    const nav: Nav = new Nav()
        .withItem(
            new NavItem(services.getI18nService().translate('explore.title').toUpperCase(), '/topics',
                loadable(() => import('../../../explore/topicsStat/TopicsStatView')), false)
                .withIcon('chat')
                .withSubNav(exploreNav)
        );

    dashboardNav(nav);

    reportNav(nav);

    nav.withRedirect('/', '/topics');

    if (services.getAdministrationService().isAuthorized()) {
        nav.withItem(new NavItem(services.getI18nService().translate('administration').toUpperCase(),
            services.getAdministrationService().getRootPath() + "/*",
            loadable(() => import('../../../administration/AdministrationView')))
            .withIcon('cog')
            .withAnchor(Anchor.BOTTOM)
        );
    }

    return nav;
};
