import _ from 'lodash';
import { Kpi } from './Kpi';
import { mdiForum } from '@mdi/js';
import { ChartType } from '../../../utils/chart/SerieChartBuilder';
import { FieldValue } from "../field/FieldValue";
import { DateTime } from 'luxon';
import { KpiEvolution } from "./KpiInstance";

export class CountByCategoriesKpi extends Kpi {
  readonly categories: Array<FieldValue>
  readonly tree: string;

  constructor(name: string, tree: string, categories: Array<FieldValue>) {
    super(name);
    this.categories = categories;
    this.tree = tree;
  }

  getType(): string {
    return 'countByCategories';
  }

  getLabel(kpiInstance, t) {
    return _.capitalize(t('kpi.countByCategories.label'));
  }

  getNumber(kpiInstance, t) {
    return t('kpi.countByCategories.number', { count: kpiInstance.data.value });
  }

  getLegend(kpiInstance, t) {
    return t('kpi.countByCategories.legend', {
      count: kpiInstance.data.value,
      percent: _.round(kpiInstance.data.percent, 2)
    });
  }

  getIcon(kpiInstance) {
    return mdiForum;
  }

  getColor(kpiInstance) {
    return '#48B9C1';
  }

  getEvolution(kpiInstance, t): KpiEvolution {
    return {
      type: ChartType.TIME_BAR,
      yAxis: {
        autoAdapted: { min: null, max: 100 },
        min: 0,
        tickLabelCallback: (value) => {
          return `${value} %`;
        }
      },
      data: kpiInstance.data.evolution.map(e => ({ x: DateTime.fromMillis(e.date).toJSDate(), y: e.value })),
      tooltipRenderer: (value) => {
        return `${this.getLabel(kpiInstance, t)} : ${_.round(value, 2)} %`
      }
    };
  }

  getApiParams() {
    return {
      tree: this.tree,
      categories: this.categories.map(c => c.fullName)
    };
  }
}