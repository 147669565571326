import _ from 'lodash';
import {Field, FieldName, FieldValuesResolverKind} from '../model/field/Field';
import {NoneFieldValue} from '../model/field/NoneFieldValue';
import {parseField} from '../model/field/parseField';
import {ConfigurationFieldValuesResolver} from '../model/field/resolve/ConfigurationFieldValuesResolver';
import {RemoteFieldValuesResolver} from '../model/field/resolve/RemoteFieldValuesResolver';
import {VeckoFields} from '../model/field/VeckoFields';
import {services} from './services';
import {FieldValues} from "../model/field/FieldValues";
import {FieldValue} from "../model/field/FieldValue";
import {TFunction} from "i18next";
import {CategoryField} from "../model/field/CategoryField";
import {FieldDisplayKindType} from "../model/field/FieldDisplay";
import {LazyLoadedField} from "../model/field/lazy/LazyLoadedField";
import {LazyLoadedFieldValue} from "../model/field/lazy/LazyLoadedFieldValue";

const configurationFieldValuesResolver = new ConfigurationFieldValuesResolver();
const remoteFieldValuesResolver = new RemoteFieldValuesResolver();

export class FieldsService {
    private _fieldValuesFetcher;
    private fields: Array<Field>;
    private lazyLoadedFields: Array<LazyLoadedField> = [];
    private lazyLoadedFieldValues: Array<LazyLoadedFieldValue> = [];
    private fieldsByName: { [fieldName: string]: Field } = {};
    private fieldValues: Array<FieldValues> = [];
    private fieldValuesByFieldName: { [fieldName: string]: FieldValues } = {};
    private _remoteFieldValues: any;

    async init(fieldValueFetcher = null) {
        this._fieldValuesFetcher = services.getFetcherService().getFetcher('fieldValues');
        if (fieldValueFetcher) {
            this._fieldValuesFetcher = fieldValueFetcher;
        }

        // parse fields from configuration
        this.fields = Object.entries(services.getConfigurationService().fieldsConfiguration)
            .filter(([fieldName,]) => this.userHasRightForField(fieldName))
            .map(([fieldName, obj]) => parseField(fieldName, obj));
        // index fields by name
        this.fieldsByName = Object.fromEntries(this.fields.map(f => [f.name, f]));


        // Load lazy loaded Fields
        this.lazyLoadedFields.forEach(lazyLoadedField => {
            this.loadLazyLoadedField(lazyLoadedField);
        });

        // validate mandatory fields
        const mandatoryFields = [
            VeckoFields.DATE,
            VeckoFields.GLOBAL_SATISFACTION,
            FieldName.standardMetadata('channelKind'),
            FieldName.standardMetadata('channelName')
        ];
        for (const f of mandatoryFields) {
            if (!Object.keys(this.fieldsByName).includes(f)) {
                console.error(`The mandatory feedback field [${f}] is not defined in FIELD configuration.`);
            }
        }

        // init field values
        const remoteFields = [];
        this.fieldValues = this.fields
            .filter(f => this.isDisplayedSomewhere(f))
            .map(f => {
                const valuesFromConfiguration = configurationFieldValuesResolver
                    .resolve(f, services.getConfigurationService().getFieldConfiguration(f.name));
                if (!valuesFromConfiguration.isEmpty()) {
                    f.valuesResolver = FieldValuesResolverKind.CONFIGURATION
                    return valuesFromConfiguration;
                }
                remoteFields.push(f.name);
                f.valuesResolver = FieldValuesResolverKind.REMOTE
                return null;
            }).filter(fv => !_.isNil(fv));

        //Load Fields value
        this._remoteFieldValues = await this._fieldValuesFetcher.getFieldValues(remoteFields);
        Object.entries(this._remoteFieldValues)
            .forEach(([f, d]) => this.fieldValues.push(remoteFieldValuesResolver.resolve(this.fieldsByName[f], d)));


        // add NoneFieldValue if user has right for it.
        this.fieldValues
            .filter(fv => this.userCanFilterOnNoneFieldValue(fv))
            .forEach(fv => {
                fv.addFieldValue(new NoneFieldValue(fv.field));
            });

        // index fieldValues by field name
        this.fieldValuesByFieldName = Object.fromEntries(this.fieldValues.map(fv => [fv.field.name, fv]));


        // Load lazy loaded FieldValues
        this.lazyLoadedFieldValues.forEach(lazyLoadedFieldValue => {
            this.loadLazyLoadedFieldValue(lazyLoadedFieldValue);
        });

    }

    get remoteFieldValues(): any {
        return this._remoteFieldValues;
    }


    registerLazyLoadedField(lazyLoadedField: LazyLoadedField) {
        const field = this.getField(lazyLoadedField.name);
        if (field) {
            // load immediately if field already loaded
            this.loadLazyLoadedField(lazyLoadedField, field);
        } else {
            // register for loading in the future
            this.lazyLoadedFields.push(lazyLoadedField);
        }
    }

    registerLazyLoadedFieldValue(lazyLoadedFieldValue: LazyLoadedFieldValue) {
        const fieldValue = this.findFieldValue(lazyLoadedFieldValue.fieldName, lazyLoadedFieldValue.value);
        if (fieldValue) {
            // load immediately if field value is present
            this.loadLazyLoadedFieldValue(lazyLoadedFieldValue, fieldValue);
        } else {
            // register for loading in the future
            this.lazyLoadedFieldValues.push(lazyLoadedFieldValue);
        }
    }

    getAllFieldValues(view: FieldDisplayKindType = undefined): Array<FieldValues> {
        if (_.isNil(view)) {
            return this.fieldValues;
        }
        return this.prepareFieldValuesForView(view, this.fieldValues);
    }

    getAllFields(view: FieldDisplayKindType = undefined): Array<Field> {
        if (_.isNil(view)) {
            return this.fields;
        }
        return this.prepareFieldsForView(view, this.fields);
    }

    prepareFieldValuesForView(view: FieldDisplayKindType, fieldValues: Array<FieldValues>): Array<FieldValues> {
        return fieldValues
            .filter(fv => fv.field.isVisible(view))
            .sort((fv1, fv2) => fv1.field.order(view) - fv2.field.order(view));
    }

    prepareFieldsForView(view: FieldDisplayKindType, fields: Array<Field>): Array<Field> {
        return fields
            .filter(f => f.isVisible(view))
            .sort((f1, f2) => f1.order(view) - f2.order(view));
    }

    getField(fieldName: string): Field {
        if (_.isNil(fieldName)) return null;
        if (this.isFirstLevelField(fieldName)) {
            /* search for parent field*/
            return this.fieldsByName[fieldName.substr(0, fieldName.lastIndexOf('.'))];
        }
        return this.fieldsByName[fieldName];
    }

    isFirstLevelField(fieldName: string): boolean {
        return fieldName.endsWith(".first_level");
    }

    getExportableFields(): Array<Field> {
        return this.fields.filter(f => f.exportable === true)
    }

    getFieldValues(fieldName: string): FieldValues {
        return this.fieldValuesByFieldName[fieldName];
    }

    findFieldValue(fieldName: string, value: OneOrMany<string>): FieldValue {
        const field = this.getField(fieldName);
        if (!field) return null;
        const fieldValues = this.getFieldValues(field.name);
        if (_.isNil(fieldValues)) {
            return null;
        }
        return fieldValues.find(value);
    }

    getCategoryTree(tree: string): FieldValues {
        return this.getFieldValues(FieldName.categoryTree(tree));
    }

    findCategory(tree: string, name: OneOrMany<string>): FieldValue {
        return this.findFieldValue(FieldName.categoryTree(tree), name);
    }

    findCategoryLabel(tree: string, name: string, t: TFunction): string {
        const category = this.findCategory(tree, name);
        let label = name;
        if (category) {
            label = category.getLabel(t);
        }
        return label;
    }

    /**
     * @param {string} tree
     * @return {CategoryField}
     */
    getCategoryTreeField(tree: string): CategoryField {
        return <CategoryField>this.getField(FieldName.categoryTree(tree));
    }

    getCategoryTrees(): Array<CategoryField> {
        return this.fields.filter(f => f.isCategoryTree()).map(f => f as CategoryField)
    }

    resolveFieldValuesFromConfiguration(fieldName: string) {
        const field = this.getField(fieldName);

        if (field.valuesResolver === FieldValuesResolverKind.CONFIGURATION) {
            return this.getFieldValues(fieldName);
        }

        const valuesFromConfiguration = configurationFieldValuesResolver
            .resolve(field, services.getConfigurationService().getFieldConfiguration(field.name));
        if (!valuesFromConfiguration.isEmpty()) {
            field.valuesResolver = FieldValuesResolverKind.CONFIGURATION;

            this.fieldValues.push(valuesFromConfiguration);

            return valuesFromConfiguration;
        }

        return null;
    }

    private loadLazyLoadedField(lazyLoadedField: LazyLoadedField, field?: Field) {
        const f = field || this.getField(lazyLoadedField.name);
        if (!f) {
            console.error(`Field ${lazyLoadedField.name} is not defined in configuration`)
            return;
        }
        lazyLoadedField.load(f);
    }

    private loadLazyLoadedFieldValue(lazyLoadedFieldValue: LazyLoadedFieldValue, fieldValue?: FieldValue) {
        const fv = fieldValue || this.findFieldValue(lazyLoadedFieldValue.fieldName, lazyLoadedFieldValue.value);
        if (!fv) {
            console.error(`FieldValue ${lazyLoadedFieldValue.value} for field ${lazyLoadedFieldValue.fieldName} has not been loaded`);
            return;
        }
        lazyLoadedFieldValue.load(fv);
    }

    /**
     * Check whether the logged user is authorized to see the given field.
     * @param {string} fieldName
     * @return {boolean}
     */
    private userHasRightForField(fieldName: string): boolean {
        if (fieldName === FieldName.categoryTree('tag')) {
            return services.getSecurityService().isAdmin();
        }
        return true;
    }

    /**
     * Check whether the logged user is authorized to filter on none value.
     * @param {FieldValues} fieldValues
     * @return {boolean}
     */
    private userCanFilterOnNoneFieldValue(fieldValues: FieldValues): boolean {
        return services.getSecurityService().isAdmin();

    }

    private isDisplayedSomewhere(field: Field): boolean {
        // topics are always displayed in topicsStat and analysis views
        if (field instanceof CategoryField && field.tree === 'topic') {
            return true;
        }
        return field.isDisplayedSomewhere();
    }
}

services.registerService('fieldsService', new FieldsService());
