import _ from 'lodash';
import { SatTypeEnum } from '../../../../application/model/SatTypeEnum';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { getTitle } from '../common';
import { TopicSatScoreEditor } from './TopicSatScoreEditor';
import { topicSatScoreEditorState, topicSatScoreEditorStateName } from './topicSatScoreEditorState';
import { TopicSatScoreViz } from './TopicSatScoreViz';

const titleFn = (vizInstance, t) => {
  const title = getTitle(vizInstance, t);
  if(title){
    return title;
  }
  const satType = SatTypeEnum.valueOf(vizInstance.definition.params.satType);
  return t(
    `dashboard.viz.topicSatScore.title.${satType.getAliasedName()}.${vizInstance.definition.params.polarity}`);
};

registerVizType(
  {
    name: 'TOPIC_SAT_SCORE',
    title: titleFn,
    component: TopicSatScoreViz,
    noDataPolicy: {
      hasData: vizInstance => (vizInstance.data.length > 0)
    },
    editor: {
      component: TopicSatScoreEditor,
      title: titleFn,
      init(vizInstance) {
        return {
          params: {
            ranges: vizInstance.definition.params.ranges,
            exclusions: vizInstance.definition.params.exclusions,
            inclusions: vizInstance.definition.params.inclusions,
            sort: vizInstance.definition.params.sort
          },
          data: {
            feedbacks: Object.fromEntries(vizInstance.data
              .filter(d => !_.isNil(d.feedback))
              .map(d => ([d.topic, d.feedback])))
          }
        }
      },
      applyOnParams: (params, editedParams) => {
        Object.assign(params, editedParams);
      },
      applyOnData: (data, editedData) => {
        _.each(data, (d) => {
          d.feedback = editedData.feedbacks[d.topic];
        });
      },
      focusOnMount: false,
      state: {
        name: topicSatScoreEditorStateName,
        create: () => topicSatScoreEditorState()
      }
    }
  }
);