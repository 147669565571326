export type FieldDisplayKindType = typeof FieldDisplay.FieldDisplayKind[keyof typeof FieldDisplay.FieldDisplayKind];

export class FieldDisplay {
  static FieldDisplayKind = {
    FILTER_VIEW: 'feedbackFilterView',
    DETAILS_VIEW: 'feedbackDetailsView',
    DASHBOARD_VIEW: 'dashboardView',
    DASHBOARD_FEEDBACK_LIST: 'dashboardFeedbackList',
    FEEDBACKS_LIST_VIEW: 'feedbacksListView',
    TOPICS_STAT_VIEW: 'topicsStatListView',
  };

  view:string;
  order:number;

  constructor(view:string, order:number) {
    this.view = view;
    this.order = order;
  }
}