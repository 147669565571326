import { Tonality } from '@eptica/vecko-js-commons';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SatTypeEnum } from '../../../../application/model/SatTypeEnum';
import { services } from '../../../../application/service/services';
import { LabelIcon } from '../../../../component/LabelIcon';
import { VeckoColors } from '../../../../style/VeckoColors';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import './customerJourneySatScoreViz.scss';
import _ from "lodash";
import { BubbleSatDistribution } from "../../components/bubbleSatDistribution";
import { parseDistributionKind } from "../../../../application/model/distribution/parseDistributionKind";
import { CustomerJourneyItem, Score } from "../../../model/model";
import { ClickableViz } from "../ClickableViz";
import { connect } from "react-redux";
import { FieldValue } from "../../../../application/model/field/FieldValue";
import {
  FieldVizFeedbacksQueryContext,
  MultiFieldsVizFeedbacksQueryContext
} from "../../../model/viz/VizFeedbacksQueryContext";
import { SatTypeDistribution } from "../../../../application/model/distribution/SatTypeDistribution";

interface CustomerJourneySatScoreVizProps extends WithTranslation {
  viz: VisualizationInstance,
  openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => void
}


interface StateProps {
  clickItem: EnrichedCustomerJourneyItem
}


interface EnrichedCustomerJourneyItem extends CustomerJourneyItem {
  parent: EnrichedCustomerJourneyItem;
  label: string;
  fieldObject: FieldValue,
  enrichedChildren: Array<EnrichedCustomerJourneyItem>;
}


class CustomerJourneySatScoreVizComponent extends React.Component<CustomerJourneySatScoreVizProps> implements ClickableViz {


  state: StateProps = {
    clickItem: null,
  }

  onFirstLevelClick(item: EnrichedCustomerJourneyItem, event: MouseEvent): void {
    this.state.clickItem = item;
    this.props.openFeedbacksPanel(this, item.label);
    event.stopPropagation();
    event.preventDefault();
  }

  onSecondLevelClick(item: EnrichedCustomerJourneyItem, event: MouseEvent): void {
    this.state.clickItem = item;
    this.props.openFeedbacksPanel(this, item.label);
    event.stopPropagation();
    event.preventDefault();
  }

  getTonality(score: Score) {
    const { viz } = this.props;
    const threshold = _.get(viz.definition.uiParams, 'positiveThreshold');
    if (_.isNil(threshold)) {
      const satType = SatTypeEnum.valueOf(viz.definition.params.satType);
      return satType.getTonality(score.score);
    }
    return score.score < threshold ? Tonality.NEGATIVE : Tonality.POSITIVE;
  }

  renderSecondLevel(item: EnrichedCustomerJourneyItem) {
    const { t, viz } = this.props;
    const distributionKind = viz.definition.params.distribution ? parseDistributionKind({
      ...viz.definition.params.distribution,
      satType: viz.definition.params.satType
    }) : SatTypeDistribution.get(SatTypeEnum.valueOf(viz.definition.params.satType));
    let content = <BubbleSatDistribution {...viz.definition.uiParams.distribution} score={item}
                                         distributionKind={distributionKind}
                                         className={"customerJourneySatScoreVizTopicSatDistribution vui-layout--vertical"}></BubbleSatDistribution>
    if (_.isNil(item.fieldObject)) {
      content = <div className='customerJourneySatScoreVizSecondLevelUnknownValue'>
        <LabelIcon label={t('dashboard.viz.unknownValue')}
                   color={VeckoColors.DANGER}
                   icon='warning-sign'
                   iconColor={VeckoColors.DANGER}/>
      </div>
    }

    if (item.count === 0) {
      content = <div className='customerJourneySatScoreVizSecondLevelNoData'>{t('dashboard.viz.noData.light')}</div>;
    }

    return <div key={item.fieldValue} className='vui-layout--vertical vui-panel-bordered'
                onClick={event => {
                  if(!_.isNil(item.fieldObject) && item.count > 0){
                    this.onSecondLevelClick(item, event as any);
                  }else{
                    event.stopPropagation();
                    event.preventDefault();
                  }
                }}
                style={{
                  backgroundColor: services.getColorService().addTransparency(this.getTonality(item).color, 70),
                  cursor: "pointer"
                }}>
      <div className='customerJourneySatScoreVizSecondLevelLabel'>{item.label}</div>
      {content}
    </div>;
  }

  renderFirstLevelAggregation(item: EnrichedCustomerJourneyItem) {
    const { t, viz } = this.props;
    if (this.isSingleLevel(viz)) {
      return <div
        className='customerJourneySatScoreVizSecondLevelsWrapper'>{this.renderSecondLevel(item)}</div>;
    } else
      return item.enrichedChildren && item.enrichedChildren.length > 0 ? <div
        key={item.fieldValue}
        className='vui-layout--vertical vui-layout--vertical__center customerJourneySatScoreVizItem'
        style={{
          borderLeftColor: services.getColorService().getColorForFieldValue(item.field, item.fieldValue),
          cursor: "pointer"
        }}
      >
        <div className='vui-layout--horizontal__center customerJourneySatScoreVizFirstLevelWrapper'>
          <div className='customerJourneySatScoreVizFirstLevelLabelWrapper'
               onClick={this.onFirstLevelClick.bind(this, item)}>
            <div className='customerJourneySatScoreVizFirstLevelLabel'>{item.label}</div>
            <div className='customerJourneySatScoreVizFirstLevelScore'>{item.score}</div>
          </div>
          {
            item.enrichedChildren ?
              <div
                className='customerJourneySatScoreVizSecondLevelsWrapper'>{item.enrichedChildren.map(child => this.renderSecondLevel(child))}</div>
              : null
          }
        </div>
      </div> : null;
  }

  private isSingleLevel(viz: VisualizationInstance) {
    return viz.definition.params.fields.length == 1;
  }

  render() {
    const { viz } = this.props;
    const data = this.enrichItems(null, viz.data as Array<CustomerJourneyItem>);
    const clazz = 'customerJourneySatScoreViz_content' + (this.isSingleLevel(viz) ? ' customerJourneySatScoreViz_singleLevel' : '');
    return <div style={{ flex: 1, minHeight: 0 }}>
      <div className='customerJourneySatScoreViz'>
        <div className={clazz}>
          {data.map(
            (item) => this.renderFirstLevelAggregation(item))}
        </div>
      </div>
    </div>;
  }

  getFeedBacksQueryContext() {
    const result = new MultiFieldsVizFeedbacksQueryContext();
    if (this.state.clickItem) {
      const { viz } = this.props;
      result.fields = this.getFieldsFromItem(this.state.clickItem);
    }
    return result;
  }

  getClickedFieldValue(): FieldValue {
    if (this.state.clickItem) {
      return this.state.clickItem.fieldObject;
    }
    return null;
  }

  private enrichItems(parent: EnrichedCustomerJourneyItem, data: Array<CustomerJourneyItem>): Array<EnrichedCustomerJourneyItem> {
    if (!data) return null;
    const result = []
    data.forEach(item => {
      const fieldValue = services.getFieldsService().findFieldValue(item.field, item.fieldValue);
      const enrichedItem: EnrichedCustomerJourneyItem = {
        ...item,
        parent,
        label: fieldValue ? fieldValue.getLabel(this.props.t) : item.fieldValue,
        fieldObject: fieldValue,
        enrichedChildren: null
      };
      enrichedItem.enrichedChildren = this.enrichItems(enrichedItem, item.children);
      result.push(enrichedItem);
    });
    return result;
  }

  private getFieldsFromItem(clickItem: EnrichedCustomerJourneyItem): Array<FieldVizFeedbacksQueryContext> {
    const result = [];
    if (clickItem.parent) {
      result.push(...this.getFieldsFromItem(clickItem.parent));
    }
    const field = new FieldVizFeedbacksQueryContext();
    field.field = clickItem.field;
    field.selectedValue = clickItem.fieldValue;
    result.push(field);
    return result;
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => {
      dispatch.dashboard.openFeedBackPanel({ viz, panelTitle });
    },
  };
};


export const CustomerJourneySatScoreViz = withTranslation(undefined, { withRef: true })(connect(null, mapDispatchToProps, null, { forwardRef: true })(CustomerJourneySatScoreVizComponent));
