import axios from 'axios';

const getFieldValues = async (fields) => {
  const json = JSON.stringify(fields);

  return axios
    .post('/api/ui/fields/_values', json, {
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data)
};

export default { getFieldValues };
