import {saveStream} from "../../../utils/http/saveStream";
import {ExportOutputType} from "../../../administration/service/exportService";
import {SearchQuery} from "../../../utils/query/SearchQuery";
import {ApiFilter} from "../../../utils/query/filter/ApiFilter";

const exportFeedbacks = (exportType: ExportOutputType, feedbackDataType: 'indexed' | 'analyzed', exportFilterKind: string, elasticRawFilter: string, query: ApiFilter, fields, maxSize, lang) => {
    const data = {
        exporterOptions: {
            '@type': exportType
        },
        exportFilterKind: exportFilterKind,
        query: query,
        filter: elasticRawFilter,
        fields: fields,
        maxSize: maxSize,
        locale: lang
    };

    return saveStream({
        url: '/api/export/feedbacks/' + feedbackDataType,
        method: 'POST',
        body: data,
        headers: {
            'Accept-Language': lang
        },
        fallbackFileName: 'export.' + exportType
    } as any);
};

const exportHighlight = async (query, categoryTree, tonality, maxSize, lang) => {
    const data = {
        query: query,
        categoryTree: categoryTree,
        tonality: tonality,
        maxSize: maxSize,
        lang: lang
    };

    return saveStream({
        url: '/api/export/_highlight',
        method: 'POST',
        body: data,
        fallbackFileName: 'highlights.zip'
    } as any);
};


export default {exportFeedbacks, exportHighlight}