import _ from 'lodash';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { services } from '../../../../application/service/services';
import { Table } from '../../../../component/table/Table';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import './tetraClasseViz.scss';

export function TetraClasseViz({ viz }) {
  const { t } = useTranslation();
  const data = viz.data;

  const ui:any = {
    data: data,
    display: [
      {
        headerRenderer: _.capitalize(t('dashboard.viz.tetraClasse.header.topic')),
        dataRenderer: (rowValue) => {
          return services.getFieldsService().findCategoryLabel('topic', rowValue.category, t);
        },
        style: {
          flex: 1,
          fontWeight: 400
        }
      },
      {
        headerRenderer: _.capitalize(t('dashboard.viz.tetraClasse.header.x')),
        dataRenderer: (rowValue) => (rowValue.x + ' %'),
        style: {
          width: 200
        }
      },
      {
        headerRenderer: _.capitalize(t('dashboard.viz.tetraClasse.header.y')),
        dataRenderer: (rowValue) => (rowValue.y + ' %'),
        style: {
          width: 200
        }
      },
      {
        headerRenderer: _.capitalize(t('dashboard.viz.tetraClasse.header.classe')),
        dataRenderer: (rowValue) => (_.capitalize(t("tetraClasse."+rowValue.classe))),
        style: {
          width: 150
        }
      },
      {
        headerRenderer: _.capitalize(t('dashboard.viz.tetraClasse.header.count')),
        dataRenderer: (rowValue) => (rowValue.count),
        style: {
          width: 150
        }
      }
    ]
  };

  return <div className="stores-ranking">
    <Table color="#4d5353"
           separatorColor="#bcbcbc"
           dataset={ui}
    />
  </div>;
}

TetraClasseViz.propTypes = {
  viz: PropTypes.instanceOf(VisualizationInstance).isRequired
};
