import { FilterLocation, FilterLocationEnum } from '../FilterLocationEnum';
import _ from "lodash";
import { StateConverter } from "./stateConverters/StateConverter";
import { toArray } from "../../utils/collectionUtils";
import { TFunction } from "i18next";


export interface TagValue<T> {
  tagValue: string,
  value: T
}

/**
 * This an abstract base class for implementing a filter controller
 */
export abstract class AbstractFilterCtrl<T> {
  public readonly id: string;

  public displayed : ()=> boolean = () => true;

  protected constructor(id: string) {
    if (_.isNil(id)) {
      throw new Error('id is mandatory')
    }
    if (!_.isString(id)) {
      throw new Error('id must be a string')
    }
    if (_.isEmpty(id)) {
      throw new Error('id cannot be empty')
    }

    this.id = id;
  }

  uiComponent(t: TFunction = null, onChanged, filterLocation: FilterLocation = FilterLocationEnum.TOP_START, additionalProps: Maybe<any> = undefined): JSX.Element {
    throw new Error('Method not implemented');
  }

  abstract stateConverter(): StateConverter<T>;

  abstract getValue(): T;

  abstract getTagValues(t: TFunction): Array<TagValue<T>>;

  abstract setValue(value: T): void;

  hasValue(): boolean {
    return !_.isEmpty(this.getValue());
  }

  reset(): void {
    this.setValue(null);
  }

  getStateValue(): any | Array<any> {
    const value = this.getValue();
    if (_.isArray(value)) {
      return toArray(value).map(v => this.stateConverter().toState(v));
    } else {
      return this.stateConverter().toState(value);
    }
  }

}

