import _ from "lodash";

const strictEqual = (a, b) => a === b;

export class SingleSelection {
    private _selection: any;
    private _onSelectionChanged: any;
    private _itemEqual: (a, b) => boolean;

    constructor(selection, onSelectionChanged, itemEqual) {
        this._selection = selection;
        this._onSelectionChanged = onSelectionChanged || _.noop();
        this._itemEqual = itemEqual || strictEqual;
    }

    isSelected = (value) => {
        return this._itemEqual(this.getSelection(), value)
    };

    unselect = () => {
        this.setSelection(null);
    };

    select = (value) => {
        this.setSelection(value);
    };

    setSelection = (selection) => {
        if (this._onSelectionChanged) {
            this._onSelectionChanged(selection);
        }
    };

    getSelection = () => {
        return this._selection;
    };
}