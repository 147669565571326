import _ from 'lodash';

const getCombinations = (array, size, start, initialStuff, output) => {
  if (initialStuff.length >= size) {
    output.push(initialStuff);
  } else {
    for (let i = start; i < array.length; ++i) {
      getCombinations(array, size, i + 1, initialStuff.concat(array[i]), output);
    }
  }
};

export const combinations = (array, size) => {
  if (size < 0) {
    throw new RangeError();
  }
  if (size > array.length) {
    throw new RangeError();
  }
  const output = [];
  getCombinations(array, size, 0, [], output);
  return output;
};

export const safeMin = (...items) => {
  return _.min(items.filter(it => !_.isNil(it)))
}

export const safeMax = (...items) => {
  return _.max(items.filter(it => !_.isNil(it)))
}