import React from 'react';
import { Metric } from '../../components/metric';
import './volumeViz.scss';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';

interface VolumeVizProp {
  viz: VisualizationInstance
}

export function VolumeViz({ viz }: VolumeVizProp) {

  return <div className="volume">
    <Metric value={viz.data.count} unit="dashboard.viz.volume.unit"/>
  </div>;
}
