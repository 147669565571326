import { FormGroup } from '@blueprintjs/core';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Metric } from '../../../model/MetricEnum';
import { Range, RangeEditor } from '../../../../component/RangeEditor';

interface MetricRangeEditorProps extends WithTranslation {
  metric: Metric,
  range: Range,
  inFormGroup?: boolean
  onRangeChanged: (range: Range) => void,
}

class MetricRangeEditorComponent extends React.PureComponent<MetricRangeEditorProps> {
  static defaultProps: Partial<MetricRangeEditorProps> = {
    inFormGroup: false
  }

  renderEditor() {
    const { metric, range, onRangeChanged } = this.props;

    return <RangeEditor range={range}
                        onRangeChanged={onRangeChanged}
                        min={metric.min}
                        max={metric.max}
                        precision={metric.precision}
    />;
  }

  render() {
    const { t, metric, inFormGroup } = this.props;

    return inFormGroup ? <FormGroup label={metric.getLabel(t)}>
      {this.renderEditor()}
    </FormGroup> : this.renderEditor();
  }
}

export const MetricRangeEditor = withTranslation()(MetricRangeEditorComponent);