import * as PropTypes from 'prop-types';
import React from 'react';
import {Navigate, Route, Routes} from 'react-router';
import {Error404} from './Error404';
import {Nav} from './Nav';

/**
 *
 * @param {Nav} nav
 * @return {*}
 * @constructor
 */

interface NavViewsProps {
    nav: Nav
}

export class NavViews extends React.PureComponent<NavViewsProps> {
    static props = {
        nav: PropTypes.instanceOf(Nav)
    };

    render() {
        const {nav} = this.props;
        const OtherwiseComponent = nav.otherwiseComponent;
        return <Routes>
            {
                nav.getAllItems().filter(it => it.to).map(it => {
                    const RouteComponent = it.component;
                    return <Route key={it.to} path={it.to} element={<RouteComponent></RouteComponent>}/>
                })
            }
            {
                nav.redirects.map(it => <Route key={it.from} path={it.from} element={<Navigate to={it.to}/>}/>)
            }
            {
                OtherwiseComponent ? <Route path="*" element={<OtherwiseComponent/>}/> :
                    <Route path="*" element={<Error404></Error404>}/>
            }
        </Routes>;
    }
}
