import { Colors } from '@blueprintjs/core';
import { normalize } from '@eptica/js-utils';
import Color from 'color';
import * as React from 'react';
import { responsiveWrapper } from '../../../../component/hoc/responsiveWrapper';
import { VeckoColors } from '../../../../style/VeckoColors';

const polarToCartesian = (center, radius, angleInDegrees) => {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: center.x + (radius * Math.cos(angleInRadians)),
    y: center.y + (radius * Math.sin(angleInRadians))
  };
};

const describeArc = (center, radius, startAngle, endAngle) => {
  const start = polarToCartesian(center, radius, endAngle);
  const end = polarToCartesian(center, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M', start.x, start.y,
    'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join(' ');

  return d;
};


interface LightGaugeProps {
  parentWidth: number,
  parentHeight: number,
  min?: number,
  max?: number,
  minAngle?: number,
  maxAngle?: number,
  trackWidth?: number,
  trackColor?: string,
  scoreColor?: string,
  pathColor?: string,
  pathWidth?: number,
  score: number,
}


export class LightGauge extends React.PureComponent<LightGaugeProps> {

  static defaultProps: Partial<LightGaugeProps> = {
    min: -100,
    max: 100,
    minAngle: -160,
    maxAngle: 160,
    trackColor: Colors.LIGHT_GRAY1,
    trackWidth: 5,
    pathColor: Colors.BLUE1,
    pathWidth: 5,
    scoreColor: VeckoColors.DEFAULT_FG
  }

  getMinMaxLabelHeight() {
    return 15;
  }

  getSize() {
    return Math.min(this.props.parentWidth, this.props.parentHeight);
  }

  getTrackCenter() {
    const size = this.getSize();
    return {
      x: size / 2,
      y: (size - this.getMinMaxLabelHeight()) / 2
    };
  }

  getTrackRadius(strokeWidth = 0) {
    const size = this.getSize();
    return (size - this.getMinMaxLabelHeight() - strokeWidth) / 2;
  }

  getAngle(value) {
    return normalize(value, this.props.min, this.props.max, this.props.minAngle, this.props.maxAngle);
  }

  renderTrack() {
    const arc = describeArc(this.getTrackCenter(), this.getTrackRadius(this.props.trackWidth), this.props.minAngle,
      this.props.maxAngle);
    return <path d={arc}
                 fill='transparent'
                 stroke={this.props.trackColor}
                 strokeWidth={this.props.trackWidth}/>;
  }

  renderPath() {
    let startValue;
    if (Math.abs(this.props.min) === Math.abs(this.props.max)) {
      startValue = 0;
    } else {
      startValue = this.props.min;
    }

    const angle0 = this.getAngle(startValue);
    const angleScore = this.getAngle(this.props.score);
    const arc = describeArc(this.getTrackCenter(),
      this.getTrackRadius(this.props.pathWidth),
      Math.min(angle0, angleScore),
      Math.max(angle0, angleScore));

    return <path d={arc} fill='transparent' stroke={this.props.pathColor} strokeWidth={this.props.pathWidth}/>;
  }

  renderScore() {
    const gaugeDiameter = this.getTrackRadius(20) * 2;
    const trackCenter = this.getTrackCenter();

    return <text x={trackCenter.x} y={trackCenter.y}
                 textAnchor='middle' dominantBaseline='middle'
                 style={{ fontWeight: 'lighter', fontSize: gaugeDiameter / 2, fill: this.props.scoreColor }}>
      {this.props.score}
    </text>;
  }

  renderBound(bound) {
    const point = polarToCartesian(this.getTrackCenter(), this.getTrackRadius(0), this.getAngle(bound));
    const color = Color(this.props.trackColor).darken(0.5);

    return <text x={point.x} y={point.y + 8}
                 textAnchor='middle' dominantBaseline='middle'
                 style={{ fontSize: 12, fill: color }}>
      {bound}
    </text>;
  }

  render() {
    const size = this.getSize();

    return <svg width='100%' height='100%' viewBox={`0 0 ${size} ${size}`}>
      {this.renderTrack()}
      {this.renderPath()}
      {this.renderScore()}
      {this.renderBound(this.props.min)}
      {this.renderBound(this.props.max)}
    </svg>;
  }
}


export const ResponsiveLightGauge = responsiveWrapper(LightGauge);

