import { Colors } from '@blueprintjs/core';
import React from 'react';
import * as PropTypes from 'prop-types';
import { GlobalSatisfaction, GlobalSatisfactionEnum } from '../../application/model/GlobalSatisfaction';
import { SmartIcon } from './SmartIcon';

interface SatisfactionIconProps {
  satisfaction: GlobalSatisfaction,
  revertColor?: boolean,
  size?: number,
  padding?: number,
}

export class SatisfactionIcon extends React.PureComponent<SatisfactionIconProps> {

  static defaultProps = {
    revertColor: false,
    size: null,
    padding: 2
  };

  render() {
    const { satisfaction, revertColor, size, padding } = this.props;

    if (revertColor) {
      const style: any = {
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: satisfaction.color
      };

      if (satisfaction === GlobalSatisfactionEnum.MIXED) {
        style.backgroundImage = `-webkit-linear-gradient(-45deg, ${GlobalSatisfactionEnum.POSITIVE.color} 50%, ${GlobalSatisfactionEnum.NEGATIVE.color} 50%)`;
        style.backgroundColor = GlobalSatisfactionEnum.POSITIVE.color;
      }

      return <div style={style}>
        <SmartIcon icon={satisfaction.icon} size={size - padding * 2} color={Colors.WHITE}/>
      </div>;
    }

    return <SmartIcon icon={satisfaction.icon} size={size} color={satisfaction.color}/>;
  }
}

