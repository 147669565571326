import { services } from '../../application/service/services';

export const purgeState = () => ({
  state: {
    date: null,
    duration: {nbDays: 0, nbMonths: 13 ,nbYears: 0 }
  },
  reducers: {
    setDate(state, payload) {
      state.date = payload;
      return state;
    },
    setDuration(state, payload) {
      state.duration = payload;
      return state;
    }
  },
  effects: (dispatch) => ({
    async purgeAll(payload, rootState) {
      await services.getPurgeService().purgeAll();
    },
    async purgeErrors(payload, rootState) {
      await services.getPurgeService().purgeErrors();
    },
    async purgeByDate(payload, rootState) {
      await services.getPurgeService().purgeByDate(rootState.purge.date);
    },
    async purgeByConfiguration(payload, rootState) {
      await services.getPurgeService().purgeByConfiguration();
    },
    async purgeByDuration(payload, rootState) {
      await services.getPurgeService().purgeByDuration(rootState.purge.duration);
    },
  })
});