import { Enum, EnumBuilder} from '@eptica/js-utils';
import { CustomerKind } from '@eptica/vecko-js-commons';

/**
 * Satisfaction
 */
export class Satisfaction extends Enum {
  private customerKind: CustomerKind;

  constructor(customerKind: CustomerKind) {
    super();
    this.customerKind = customerKind;
  }
}
new EnumBuilder(Satisfaction)
  .withLooseParser(true)
  .register({
    POSITIVE: new Satisfaction(CustomerKind.PROMOTER),
    NEGATIVE: new Satisfaction(CustomerKind.DETRACTOR)
  })
  .build();
