import { registerVizType } from '../../../model/viz/visualizationTypes';
import { DistributionChartViz } from "./distributionChartViz";
import { ResolvedParams } from "../../../model/params/resolve/ResolvedParams";
import { services } from "../../../../application/service/services";
import { getTitle } from "../common";

registerVizType({
  name: 'DISTRIBUTION_CHART',
  component: DistributionChartViz,
  title: getTitle,
  noDataPolicy: {
    hasData: vizInstance => {
      return vizInstance.data.items.length > 0;
    }
  },
  editable: true,
  specResolver: (vizDefinitionSpec, resolvedParams: ResolvedParams) => {
    const params = vizDefinitionSpec.params;
    const fieldName = params.field;
    const field = services.getFieldsService().getField(fieldName);

    /*check all  inclusions and exclusions values to replace first_level value with all corresponding values*/
    if (params.inclusions) {
      params.inclusions = services.getDashboardService().resolveFirstLevelValues(field, params.inclusions);
    }
    if (params.exclusions) {
      params.exclusions = services.getDashboardService().resolveFirstLevelValues(field, params.exclusions);
    }
    return vizDefinitionSpec;
  },
  prepareViz: (vizDefinitionSpec, data) => {
    const params = vizDefinitionSpec.params;
    const fieldName = params.field;
    data.items.forEach((entry) => {
      services.getColorService().registerFieldAndValue(fieldName, entry.group)
    });
  }
});