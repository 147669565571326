import { Enum, EnumValue } from "../../utils/enum";
import { TFunction } from "i18next";
import { mdiFacebook } from "@mdi/js";

export class ChannelKind extends EnumValue {
    readonly icon: string;
    readonly conversational: boolean

    constructor(icon: string, conversational: boolean) {
        super();
        this.icon = icon;
        this.conversational = conversational;
    }

    getLabel(t: TFunction): string {
        return t(`channelKind.${this.name}`);
    }
}

class ChannelKindEnumType extends Enum<ChannelKind> {
    EMAIL = new ChannelKind('envelope', false);
    SURVEY = new ChannelKind('form', false);
    CHAT = new ChannelKind('chat', true);
    FACEBOOK = new ChannelKind(mdiFacebook, false);
    PHONE = new ChannelKind('headset', true);
    WEBMAIL = new ChannelKind('form', false);

    constructor() {
        super();
        this.initEnum('ChannelKind');
    }
}

export const ChannelKindEnum = new ChannelKindEnumType();