import { push } from "redux-first-history";
import { services } from '../../service/services';
import _ from "lodash";

export const analysisState = () => ({
  state: {
    data: [],
    selectedKPI: undefined,
    topic: services.getAnalysisService().topicFilter?.getStateValue(),
    distributionKind: services.getAnalysisService().distributionKind
  },
  reducers: {
    setData(state, payload) {
      state.data = payload;

      if (_.isNil(state.data.find(d => d.name === state.selectedKPI))) {
        state.selectedKPI = state.data[0]?.name;
      }

      return state;
    },
    setTopic(state, payload) {
      state.topic = payload;
      return state;
    },
    selectKPI(state, payload) {
      state.selectedKPI = payload;
      return state;
    },
    setDistributionKind(state, payload) {
      state.distributionKind = payload;
      return state;
    }
  },
  effects: (dispatch) => {
    const fetch = async () => {
      const analysisData = await services.getAnalysisService().getAnalysis();
      dispatch.analysis.setData(analysisData);
    }

    return {
      async navigateHereFromTopicsStats(payload, rootState) {
        // set topic
        services.getAnalysisService().topicFilter.setValue(services.getTopicsStatService().selectedStat?.topic);
        await dispatch.analysis.setTopic(services.getAnalysisService().topicFilter.getStateValue());

        // set distributionKind
        const distributionKind = services.getTopicsStatService().distributionKind;
        services.getAnalysisService().distributionKind = distributionKind;
        await dispatch.analysis.setDistributionKind(distributionKind);

        dispatch(push('/analysis'));
      },
      async refresh(payload, rootState) {
        const distributionKinds = services.getAnalysisService().getDistributionKinds();
        if (!distributionKinds.map(dk => dk.getId()).includes(rootState.analysis.distributionKind.getId())) {
          services.getAnalysisService().distributionKind = distributionKinds[0];
        }
        await fetch();
        dispatch.analysis.setDistributionKind(services.getAnalysisService().distributionKind);
      },
      async fetch() {
        await fetch();
      },
      async changeTopic(payload, rootState) {
        const converter = services.getAnalysisService().topicFilter.stateConverter();

        const currentValue = converter.fromState(rootState.analysis.topic);
        const newValue = converter.fromState(payload);

        if (!converter.equals(currentValue, newValue)) {
          services.getAnalysisService().topicFilter.setValue(newValue);
          await fetch();
          dispatch.analysis.setTopic(payload);
        }
      },
      async changeDistributionKind(payload, rootState) {
        const currentValue = rootState.analysis.distributionKind;
        const currentId = currentValue?.getId();
        const newId = payload?.getId();
        if (currentId !== newId) {
          services.getAnalysisService().distributionKind = payload;
          await fetch();
          dispatch.analysis.setDistributionKind(payload);
        }
      },
    };
  }
});