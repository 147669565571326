import { services } from '../../application/service/services';

interface DataIntegrationConfiguration {
  id?: number;
  tenant?: string;
  stream: string
  active: boolean;
  apiUrl: string;
  realmUrl: string;
  clientId: string;
  login?: string;
  password?: string;
}

export type ChronoUnit = 'MILLIS' | 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS';
export const ApiKinds = ['critizr', 'easiware', 'tokywoky'];
export type ApiKind = typeof ApiKinds[number];

export const defaultApiConfigurationsByKind = {
  critizr: {
    url: '',
    token: '',
    requestRatePerHourSlow: 3600,
    maxConcurrencySlow: 1,
    requestRatePerHourFast: 3600 * 20,
    maxConcurrencyFast: 2
  },
  easiware: {
    url: '',
    user: '',
    password: '',
    siteid: '',
    token: '',
    easiwareData: {
      mainTableDateColumn: 'ClosingDate',
      tables: [
        {
          name: 'CustomerRequests',
          where: null,
          main: true,
          cached: false,
          cacheSeconds: 0,
          cacheSize: 0,
          columns: ["Id", "DemandeInitiale", "Channel", "RequestDate"],
          joins: []
        }
      ]
    },
    requestRatePerHourSlow: 3600,
    maxConcurrencySlow: 1,
    requestRatePerHourFast: 3600 * 20,
    maxConcurrencyFast: 2,
  },
  tokywoky: {
    url: '',
    apiKey: '',
    requestRatePerHour: 3600 * 10,
    maxConcurrency: 4
  }
}

export interface DataIntegrationApi {
  id?: number;
  tenant?: string;
  api: ApiKind;
  json: string;
  active: boolean;
  zoneId: string;
  initialDays: number;
  initialUnits: number;
  initialUnit: ChronoUnit;
  streamUnits: number;
  streamUnit: ChronoUnit;
  delayUnits: number;
  delayUnit: ChronoUnit;
}

type DiIntervalStatus = 'RUNNING' | 'DONE'

export interface DiInterval {
  id: number;
  tenant: string;
  api: string;
  start: string;
  end: string;
  status: DiIntervalStatus;
  _di_retries: number;
}

export class DataIntegrationService {
  private dataIntegrationFetcher;
  private configuration: DataIntegrationConfiguration;

  init() {
    this.dataIntegrationFetcher = services.getFetcherService().getFetcher('dataIntegration');
  }

  isAuthorized(): boolean {
    return this.isAuthorizedForConfiguration();
  }

  isAuthorizedForConfiguration(): boolean {
    return services.getSecurityService().hasRole('vecko.di.vecko');
  }

  isAuthorizedForApis(): boolean {
    return services.getSecurityService().hasRole('vecko.di.api');
  }

  isAuthorizedForIntervals(): boolean {
    return services.getSecurityService().hasRole('vecko.di.interval');
  }

  async getConfiguration(): Promise<DataIntegrationConfiguration> {
    this.configuration = await this.dataIntegrationFetcher.getConfiguration();
    return this.configuration;
  }

  async saveConfiguration(username: string, password: string): Promise<DataIntegrationConfiguration> {
    if (this.configuration) {
      this.configuration.login = username;
      this.configuration.password = password;
    } else {
      const currentTenant = services.getTenantService().currentTenant;
      this.configuration = {
        stream: 'default',
        active: true,
        apiUrl: window.location.origin,
        realmUrl: `${currentTenant.idpUrl}/realms/${currentTenant.idpRealm}`,
        clientId: currentTenant.idpClientId,
        login: username,
        password: password
      };
    }
    this.configuration = await this.dataIntegrationFetcher.saveConfiguration(this.configuration);

    services.getApplicationService().notifyMessage({
      level: 'SUCCESS',
      text: {
        key: `administration.di.configuration.save.success`
      }
    });

    return this.configuration;
  }

  async getApis(): Promise<Array<DataIntegrationApi>> {
    return await this.dataIntegrationFetcher.getApis();
  }

  async saveApi(api: DataIntegrationApi): Promise<DataIntegrationApi> {
    const savedApi = await this.dataIntegrationFetcher.saveApi(api);

    services.getApplicationService().notifyMessage({
      level: 'SUCCESS',
      text: {
        key: `administration.di.api.save.success`
      }
    });

    return savedApi;
  }

  async deleteApi(api: DataIntegrationApi) {
    await this.dataIntegrationFetcher.deleteApi(api.api, api.id);

    services.getApplicationService().notifyMessage({
      level: 'SUCCESS',
      text: {
        key: `administration.di.api.delete.success`
      }
    });
  }

  newApi(kind: ApiKind): DataIntegrationApi {
    return {
      api: kind,
      active: true,
      json: defaultApiConfigurationsByKind[kind],
      zoneId: 'UTC',
      initialDays: 365,
      initialUnits: 1,
      initialUnit: "HOURS",
      streamUnits: 10,
      streamUnit: "MINUTES",
      delayUnits: 10,
      delayUnit: "MINUTES",
    };
  }

  getCreateUserUrl(): string {
    const currentTenant = services.getTenantService().currentTenant;
    return this.idpUrl(`/admin/master/console/#/create/user/${currentTenant.idpRealm}`);
  }

  getListUsersUrl(): string {
    const currentTenant = services.getTenantService().currentTenant;
    return this.idpUrl(`/admin/master/console/#/realms/${currentTenant.idpRealm}/users`);
  }

  async getIntervals(api: DataIntegrationApi): Promise<Array<DiInterval>> {
    return await this.dataIntegrationFetcher.getIntervals(api.api);
  }

  async deleteIntervals(api: DataIntegrationApi) {
    await this.dataIntegrationFetcher.deleteIntervals(api.api);

    services.getApplicationService().notifyMessage({
      level: 'SUCCESS',
      text: {
        key: `administration.di.intervals.delete.success`
      }
    });
  }

  private idpUrl(path: string): string {
    const currentTenant = services.getTenantService().currentTenant;
    return services.getUrlService().forgeUrl(currentTenant.idpUrl, path);
  }
}

services.registerService('dataIntegrationService', new DataIntegrationService());