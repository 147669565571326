import React, { ReactNode } from "react";
import { ChartBuilder } from "./ChartBuilder";
import { TFunction } from "i18next";
import { services } from "../../application/service/services";
import { Field } from "../../application/model/field/Field";
import { Y_AXIS_DEFAULT_CONFIG } from "./chartUtils";
import { TooltipItem } from "chart.js";
import _ from "lodash";


export interface BarChartData {
  totalElement: number;
  items: { group: string; value: number; label: string }[];
}


export interface DataSet {
  label: string,
  backgroundColor: string[],
  data: any[],
  hidden?: boolean,
}

export interface ChartData {
  labels: string[],
  datasets: DataSet[]
}


export class BarChartBuilder extends ChartBuilder {

  protected static readonly otherKey = "dashboard.viz.distributionChart.group.other";

  protected data: BarChartData;

  protected displayedItems = [];

  protected datasetIndexByGroup = {};

  protected otherIndex;

  protected legendOnClickHandler = (event, legendItem, legend) => {
    if (legendItem.datasetIndex === this.otherIndex) return;
    const ci = legend.chart;

    if (!legendItem.hidden) {
      this.displayedItems.splice(this.displayedItems.indexOf(legendItem.text), 1);
    } else {
      this.displayedItems.push(legendItem.text);
    }

    ci.data = this.computeDatasetsAndLabels()
    ci.setDatasetVisibility(legendItem.datasetIndex, legendItem.hidden);
    ci.update();
  }
  protected readonly metricFieldLabel: string;

  protected readonly t: TFunction;

  protected labelField: Field;
  protected viz: any;
  protected percentageMode: boolean;
  protected stacked: any;
  protected parsingKey: string;
  protected field: string;
  protected displayVerticalGrid: boolean;
  protected otherGroupPresent: Boolean = false;

  constructor(viz: any, field: Field, t: TFunction) {
    super('bar');
    this.t = t;
    this.labelField = field;
    this.viz = viz;
    this.metricFieldLabel = field.getLabel(this.t);
    this.percentageMode = this.viz.definition?.uiParams?.percentageMode || false;
    this.stacked = this.viz.definition?.uiParams?.stacked || false;
    this.field = this.viz.definition.spec.params.field;
  }

  _tooltipRenderer = (value) => {
    const tdStyle = { borderTop: '4px solid  rgba(0,0,0,0.0)', borderLeft: '14px solid  rgba(0,0,0,0.0)' };
    return <table style={{ textAlign: 'left', margin: '0 auto', borderCollapse: 'collapse', borderStyle: 'hidden' }}>
      <tbody>
      <tr>
        <td style={tdStyle}><strong>{this.t('dashboard.viz.distributionChart.tooltip.count')}</strong></td>
        <td style={tdStyle}>{value.value}</td>
      </tr>
      <tr>
        {this.metricFieldLabel ? <td style={tdStyle}><strong>{this.metricFieldLabel}</strong></td> : null}
        <td style={tdStyle}>{value.label}</td>
      </tr>
      </tbody>
    </table>;
  };
  private formattedData: { datasets: DataSet[]; labels: string[] };


  withData(data: BarChartData): BarChartBuilder {
    this.data = {
      totalElement: data.totalElement,
      items: data.items.map(value => {
        return { ...value, label: null }
      })
    };
    return this;
  }

  build() {

    const chartObject = super.build();
    const chartOptions: any = chartObject.options;
    chartOptions.plugins.tooltip.mode = 'point';
    chartOptions.plugins.tooltip.callbacks = {
      title: function () {
      }
    };
    let orientation = this.viz.definition.spec.uiParams.orientation;
    orientation = (!_.isNil(orientation) && orientation.toLowerCase() === 'horizontal') ? 'horizontal' : 'vertical';
    this.parsingKey = orientation === 'horizontal' ? 'xAxisKey' : 'yAxisKey';
    chartOptions.indexAxis = orientation === 'horizontal' ? 'y' : 'x';

    chartOptions.scales = {};
    if (this.percentageMode) {

      chartOptions.scales.percentage = {
        ...Y_AXIS_DEFAULT_CONFIG, min: 0, max: 100, stacked: this.stacked,
        position: 'left',
        title: {
          display: true
        },
        grid: {
          drawOnChartArea: true,
        },
      };
    } else {
      chartOptions.scales.yAxis = { ...Y_AXIS_DEFAULT_CONFIG, stacked: this.stacked }
    }

    chartOptions.scales.xAxis = { stacked: this.stacked, grid: { display: this.displayVerticalGrid } }


    if (chartOptions.plugins.legend && chartOptions.plugins.legend.display) {
      chartOptions.plugins.legend.onClick = this.legendOnClickHandler;
      chartOptions.plugins.legend.labels = {
        font: {
          size: 14,
          family: this.getFont()
        }
      };
    }

    if (this._displayLabelsInline) {
      chartOptions.plugins.datalabels = {
        ...chartOptions.plugins.datalabels,
        ...{
          color: 'white',
          formatter: value => this.percentageMode ? Math.round(value.percentage) + "﹪" : ""
        }
      }
    }

    this.displayedItems = this.data.items.map((entry, index) => {
      //translate all label

      const field = services.getFieldsService().findFieldValue(this.labelField.name, entry.group);
      entry.label = (field ? field.getLabel(this.t) : entry.group);
      const labelWithCount = this.getLabelWithCount(entry);
      //populate map
      this.datasetIndexByGroup[labelWithCount] = index;

      //return label to be used in displayedItems
      return labelWithCount;
    });


    this.formattedData = this.computeDatasetsAndLabels();

    return { ...chartObject, data: this.formattedData };
  }

  protected computeDatasetsAndLabels() {
    const datasets: DataSet[] = [];
    let index = 0;
    const otherCount = this.computeOther();

    this.data.items.forEach((entry) => {
      const labelWithCount = this.getLabelWithCount(entry);
      const newDataset = {
        label: labelWithCount,
        data: [{ ...entry, percentage: entry.value * 100 / this.data.totalElement, x: 0, y: 0 }],
        backgroundColor: [services.getColorService().getColorForFieldValue(this.viz.definition.spec.params.field, entry.group)],
        parsing: { [this.parsingKey]: this.percentageMode ? 'percentage' : 'value' },
        hidden: this.displayedItems.filter(displayedItem => displayedItem === labelWithCount).length === 0,
      };


      datasets.push(newDataset);
      index++;
    });

    this.otherIndex = datasets.length;
    this.datasetIndexByGroup[BarChartBuilder.otherKey] = index;
    this.otherGroupPresent = false;
    if (otherCount !== 0) {
      this.otherGroupPresent = true;
      const otherData = {
        group: this.getOtherGroupName(),
        label: this.getOtherGroupName(),
        value: otherCount,
        percentage: otherCount * 100 / this.data.totalElement,
        x: 0,
        y: 0
      };
      const otherDataset = {
        label: this.getLabelWithCount(otherData),
        data: [otherData],
        backgroundColor: [services.getColorService().getOtherColor()],
        parsing: { [this.parsingKey]: this.percentageMode ? 'percentage' : 'value' }
      };
      datasets.push(otherDataset);
    }
    return { datasets: datasets, labels: [this.metricFieldLabel] };
  }

  protected getLabelWithCount(entry: { group: string; value: number; label: string }) {
    return entry.label + " (" + entry.value + ")";
  }

  computeOther(): number {
    let otherCount = this.data.totalElement;
    this.displayedItems.forEach(value => {
      const dataIndex = this.datasetIndexByGroup[value];
      otherCount -= this.data.items[dataIndex].value;
    })
    return otherCount;
  }


  tooltipRenderer(tooltipItem: TooltipItem<any>): (value: any, tooltipItem: TooltipItem<any>) => ReactNode {
    return this._tooltipRenderer;
  }

  protected getOtherGroupName(): string {
    return this.t(BarChartBuilder.otherKey);
  }

  public getDataToExport(): any[][] {
    return null;
  }
}