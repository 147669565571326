import { TreeCtrl } from "./TreeCtrl";
import { TreeModel } from "./TreeModel";

export abstract class TreeSelectionCtrl<T> extends TreeCtrl {

  _treeModel: TreeModel<T>;

  constructor(treeModel: TreeModel<T>, controlledListener: (value) => void = undefined) {
    super(controlledListener);
    this._treeModel = treeModel;
  }

  get tree(): TreeModel<T> {
    return this._treeModel;
  }

  abstract toggleSelect(item: T): void;

  abstract select(item: T): void;
  abstract unselectAll(): void;
}
