import { DistributionItem } from "./DistributionItem";
import _ from "lodash";
import { SearchQuery } from "../../../utils/query/SearchQuery";
import { FieldValue } from "../field/FieldValue";
import { TFunction } from "i18next";

export abstract class DistributionKind {
  private readonly _type: string;
  private _items: Array<DistributionItem>;

  protected constructor(type: string) {
    this._type = type;
  }

  get type(): string {
    return this._type;
  }

  get items(): Array<DistributionItem> {
    if (_.isNil(this._items)) {
      this._items = this.createItems();
    }
    return this._items;
  }

  getId():string {
    return this._type;
  }

  getLabel(t: TFunction): string {
    return t(`distributionKind.${this._type}`);
  }

  getIcon(): string {
    return 'blank';
  }

  toPlainObject(): Object {
    return {
      '@type': this._type
    };
  }

  decorateFeedbackQuery(query: SearchQuery, topic: FieldValue, item: DistributionItem): void {
    // no decoration by default
  }

  abstract createItems(): Array<DistributionItem>;

  findItemById(id: string): DistributionItem {
    return this.items.find(item => item.id === id);
  }


}