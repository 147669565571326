import { Field } from "../Field";
import { FieldDisplay } from "../FieldDisplay";

export class LazyLoadedField {
  readonly name: string;
  onLoaded: (loadedField: Field) => void;
  display: FieldDisplay;

  /**
   * @param name field name
   * @param displayKind on which displayKind it has been encountered
   * @param onLoaded the function to apply when field has been loaded
   */
  constructor(name: string, displayKind: FieldDisplay, onLoaded: (field: Field) => void) {
    this.name = name;
    this.onLoaded = onLoaded;
    this.display = displayKind;
  }

  load(loadedField: Field) {
    // add display to the loaded field
    loadedField.addDisplay(this.display);

    // apply the onLoaded function with the loaded field
    this.onLoaded(loadedField);
  }
}

