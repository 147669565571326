import { services } from '../../application/service/services';
import { TenantInfoDTO } from "../model";

const AdminDomains = [
  'tenant.dev.vecko.com',
  'tenant.vecko.com',
  'admin.us.vecko.com',
  'tenant.local.vecko.com',
  'tenant.lab.vecko.com'
];

export class TenantService {
  _tenantFetcher;
  private _currentTenant: TenantInfoDTO;

  async init() {
    this._tenantFetcher = services.getFetcherService().getFetcher('tenant');
    this._currentTenant = await this.getTenant();
  }

  get currentTenant(): TenantInfoDTO {
    return this._currentTenant;
  }

  isAuthorized(): boolean {
    return this.isAuthorizedForGetTenant();
  }

  isAuthorizedForGetTenant(): boolean {
    return services.getSecurityService().hasRole('vecko.admin.tenant.get');
  }

  isAuthorizedForGetTenants(): boolean {
    return services.getSecurityService().hasRole('vecko.admin.tenant.list');
  }

  isAdminTenant(tenant?: TenantInfoDTO): boolean {
    return AdminDomains.includes((tenant || this.currentTenant)?.domain);
  }

  private async getTenant(): Promise<TenantInfoDTO> {
    if (this.isAuthorizedForGetTenant() && !services.getUrlService().isStandAlone()) {
      return this._tenantFetcher.getTenant();
    }
    return Promise.resolve(null);
  }

  async getTenants(): Promise<Array<TenantInfoDTO>> {
    if (this.isAuthorizedForGetTenants() && !services.getUrlService().isStandAlone()) {
      return this._tenantFetcher.getTenants();
    }
    return Promise.resolve([]);
  }
}

services.registerService('tenantService', new TenantService());