import cacheRemote from './cacheRemote';
import configurationRemote from './configurationRemote';
import dashboardDataRemote from './dashboardDataRemote';
import dashboardExportRemote from './dashboardExportRemote';
import feedbackRemote from './feedbackRemote';
import fieldValuesRemote from './fieldValuesRemote';
import kpiRemote from './kpiRemote';
import purgeRemote from './purgeRemote';
import sequencesRemote from './sequencesRemote';
import sourcingRemote from './sourcingRemote';
import storageRemote from './storageRemote';
import tenantRemote from './tenantRemote';
import topicsStatRemote from './topicsStatRemote';
import progressRemote from './progressRemote';
import exportRemote from './exportRemote';
import dataIntegrationRemote from "./dataIntegrationRemote";
import kafkaRemote from "./kafkaRemote";
import sourcingErrorRemote from "./sourcingErrorRemote";

export const remoteFetchers = {
  configuration: configurationRemote,
  feedback: feedbackRemote,
  topicsStat: topicsStatRemote,
  sequences: sequencesRemote,
  kpi: kpiRemote,
  fieldValues: fieldValuesRemote,
  dashboardData: dashboardDataRemote,
  dashboardExport: dashboardExportRemote,
  storage: storageRemote,
  sourcing: sourcingRemote,
  cache: cacheRemote,
  purge: purgeRemote,
  tenant: tenantRemote,
  progress: progressRemote,
  export: exportRemote,
  dataIntegration: dataIntegrationRemote,
  kafka: kafkaRemote,
  sourcingError: sourcingErrorRemote,
};