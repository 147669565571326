import { services } from '../../application/service/services';

export const systemState = () => ({
  state: {
  },
  reducers: {
    setFeedbackStats(state, payload) {
      state.feedbackStats = payload;
      return state;
    }
  },
  effects: (dispatch) => ({
    async loadFeedbackStats(payload, rootState) {
      if (services.getSystemService().isAuthorizedForStats()) {
        const feedbackStats = await services.getSystemService().getFeedbackStats();
        dispatch.system.setFeedbackStats(feedbackStats);
      }
    },
    async exportFeedbackStats(payload, rootState) {
      await services.getSystemService().exportFeedbackStats();
    }
  })
});