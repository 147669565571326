import {Enum, EnumValue} from "../../../utils/enum";

export class StorageKind extends EnumValue {
    constructor(name: string) {
        super(name);
    }

    getLabel(t: any = undefined) {
        const key = `storageKind.${this.name}`;
        return t ? t(key) : key;
    }

}

class StorageKindEnumType extends Enum<StorageKind> {
    FEEDBACK_MIRRORED = new StorageKind('FEEDBACK_MIRRORED');
    FEEDBACK_DEMO = new StorageKind('FEEDBACK_DEMO');

    constructor() {
        super();
        this.initEnum('StorageKind');
    }
}

export const StorageKindEnum = new StorageKindEnumType();