import { identitySupplier } from "./functionUtils";

export class InfiniteCache<K = any, V = any> {
  private readonly items = new Map<any, V>();
  private readonly creator: (key:K) => V;
  private readonly keyGetter: (key:K) => any;

  constructor(creator: (key:K) => V, keyGetter?: (key:K) => any) {
    this.creator = creator;
    this.keyGetter = keyGetter ?? identitySupplier;
  }

  get(key: K): V {
    const k = this.keyGetter(key);

    if (!this.items.has(k)) {
      this.items.set(k, this.creator(key));
    }

    return this.items.get(k);
  }
}