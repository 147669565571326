import { Tonality } from '@eptica/vecko-js-commons';
import { SatTypeEnum } from '../../../application/model/SatTypeEnum';
import { SearchAspect } from './SearchAspect';
import _ from "lodash";

export class SatAspect extends SearchAspect {
  static parse(object) {
    return new SatAspect(object.satType, object.sat);
  }

  /**
   * @type {SatType}
   */
  _satType;

  /**
   * @type {Tonality}
   */
  _sat;

  /**
   *
   * @param {SatType|string} satType
   * @param {Tonality|string}  sat
   */
  constructor(satType, sat) {
    super('sat');
    this._satType = _.isString(satType) ? SatTypeEnum.valueOf(satType) : satType;
    this._sat = _.isString(sat) ? Tonality.getValueOf(sat) : sat;
  }

  get sat() {
    return this._sat;
  }

  toPlainObject() {
    if (_.isNil(this._satType)) {
      throw new Error('satType cannot be nil')
    }

    if (_.isNil(this._sat)) {
      throw new Error('sat cannot be nil')
    }

    const result:any = super.toPlainObject();
    result.satType = this._satType.name;
    result.sat = this._sat.name();

    return result;
  }
}