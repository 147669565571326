import {services} from '../../application/service/services';

export const cacheState = () => ({
    state: {},
    reducers: {},
    effects: (dispatch) => ({
        async invalidateAllLocalCaches(payload, rootState) {
            await services.getCacheService().invalidateAllLocalCaches();
        },
        async invalidateCurrentTenantPersistentCache(payload, rootState) {
            await services.getCacheService().invalidateCurrentTenantPersistentCache();
        },
        async invalidateAllTenantsPersistentCache(payload, rootState) {
            await services.getCacheService().invalidateAllTenantsPersistentCache();
        }
    })
});