import { EnhancedMetric } from "./EnhancedMetric";
import { TFunction } from "i18next";
import _ from "lodash";
import { Tonality } from '@eptica/vecko-js-commons';

export class OpinionsMetric extends EnhancedMetric {
  public static TYPE = "OPINIONS";

  public static parse(object: any): OpinionsMetric {
    if (_.isEmpty(object.tonality)) {
      throw new Error("tonality is mandatory");
    }
    if (_.isNil(object.countFeedbacks)) {
      throw new Error("countFeedbacks is mandatory");
    }
    return new OpinionsMetric(Tonality.getValueOf(object.tonality), object.countFeedbacks);
  }

  private readonly tonality: Tonality;
  private readonly countFeedbacks: boolean;

  constructor(tonality: Tonality, countFeedbacks: boolean) {
    super(OpinionsMetric.TYPE);
    this.tonality = tonality;
    this.countFeedbacks = countFeedbacks;
  }

  defaultUnitLabel(t: TFunction): string {
    return this.countFeedbacks ? t("misc.feedback_plural") : t("misc.opinion_plural");
  }

  supportPercentage(): boolean {
    return this.countFeedbacks;
  }

  getLabel(t: TFunction): string {
    return t(`metric.${this.countFeedbacks ? 'OPINIONS_FEEDBACK' : 'OPINIONS'}`, { tonality: this.tonality.name() });
  }

  asPlainObject(): any {
    return {
      ... super.asPlainObject(),
      tonality: this.tonality.name(),
      countFeedbacks: this.countFeedbacks
    };
  }

}