import { Tonality } from '@eptica/vecko-js-commons';
import { StateConverter } from './StateConverter';

export class TonalityStateConverter extends StateConverter<Tonality> {
  clazz(): Class<Tonality> {
    return Tonality;
  }

  innerFromState(obj: Maybe<any>): Maybe<Tonality> {
    return obj ? Tonality.getValueOf(obj) : undefined;
  }

  innerToState(obj: Maybe<Tonality>) {
    return obj ? obj.name() : undefined
  }
}