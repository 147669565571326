import {Checkbox, Colors, InputGroup, MenuItem, Radio} from '@blueprintjs/core';
import {mdiCheckboxMultipleBlankOutline, mdiCheckboxMultipleMarked, mdiSwapHorizontal} from '@mdi/js';
import React, {Key, PureComponent, ReactNode} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {NoneFieldValue} from '../../application/model/field/NoneFieldValue';
import {InfiniteCache} from '../../utils/InfiniteCache';
import {CollapsablePanel} from '../collapse/CollapsablePanel';
import {SmartIcon} from '../icon/SmartIcon';
import {Tools} from '../tools/Tools';
import {TreeSelector} from './TreeSelector';
import {MultiTreeSelectionCtrl} from './utils/tree/MultiTreeSelectionCtrl';
import {SingleTreeSelectionCtrl} from './utils/tree/SingleTreeSelectionCtrl';
import {TreeExpansionCtrl} from './utils/tree/TreeExpansionCtrl';
import {TreeModel} from './utils/tree/TreeModel';
import {TreeSelectionCtrl} from "./utils/tree/TreeSelectionCtrl";
import {ItemPredicate, Suggest} from "@blueprintjs/select";
import {Tool} from "../tools/Tool";
import './selectPanel.scss';


interface SelectPanelProp extends WithTranslation {
    label: string,
    items: Array<any>,
    itemId: (item: any) => Key,
    itemLabel: (o: any) => string,
    itemEqual: (o1: any, o2: any) => boolean,
    treeModel: TreeModel<any>,

    selection: OneOrMany<any>,
    allowEmptySelection: boolean,
    allowMultiSelection: boolean,
    canSelectLeavesOnly: boolean,
    allowNoneFieldValue: boolean,
    displayItemsFilter: boolean,
    onSelectionChanged: (value: any) => void,

    expandedItems: Array<any>,
    onExpansionChanged: (t: Array<any>) => void,

    collapsable: boolean,
    isOpen: boolean,
    onCollapseChanged: (isCollapsed: boolean) => void,
    itemChildrenSupplier?: (o: any) => Array<any>
    className?: string,

    suggestSearch?: boolean
};

interface SelectPanelState {
    suggestQuery?: any;
    itemsFilterOpened: boolean,
    itemsFilterValue: string,
}

class SelectPanelComponent extends PureComponent<SelectPanelProp> {

    static defaultProps = {
        items: [],
        canSelectLeavesOnly: true,
        allowEmptySelection: true,
        allowMultiSelection: true,
        allowNoneFieldValue: true,
        expandedItems: null,
        collapsable: false,
        isOpen: true
    };

    state: SelectPanelState = {
        itemsFilterOpened: false,
        itemsFilterValue: '',
    }

    private singleSelectionChangeCache: InfiniteCache<any, () => void>;
    private multiSelectionChangeCache: InfiniteCache<any, () => void>;
    private treeModel: TreeModel<any>;
    private selectionCtrl: TreeSelectionCtrl<any>;
    private expansionCtrl: TreeExpansionCtrl;

    private filterSuggest: ItemPredicate<string> = (query, item) => {
        return query ? item.toLowerCase().indexOf(query.toLowerCase()) >= 0 : true;
    };
    private onSearchIconClick = () => {
        this.setState({itemsFilterOpened: !this.state.itemsFilterOpened});
    };
    private suggestActiveItem: string;

    constructor(props) {
        super(props);

        this.singleSelectionChangeCache = new InfiniteCache(this.createSingleSelectionChangeHandlers);
        this.multiSelectionChangeCache = new InfiniteCache(this.createMultiSelectionChangeHandlers);

        this.init();
    }

    init() {
        this.refreshModel();
        this.refreshSelectionCtrl();
        this.refreshExpansionCtrl();
    }

    refreshModel() {
        const items = this.props.allowNoneFieldValue ? this.props.items : this.props.items.filter(fv => !(fv instanceof NoneFieldValue));
        this.treeModel = new TreeModel(items, this.props.itemChildrenSupplier, this.props.itemEqual);
    }

    refreshSelectionCtrl() {
        this.selectionCtrl = this.props.allowMultiSelection ?
            new MultiTreeSelectionCtrl(this.treeModel, this.props.selection, this.props.onSelectionChanged, this.props.allowEmptySelection) :
            new SingleTreeSelectionCtrl(this.treeModel, this.props.selection, this.props.onSelectionChanged, this.props.allowEmptySelection);
    }

    refreshExpansionCtrl() {
        this.expansionCtrl = new TreeExpansionCtrl(this.treeModel, this.props.expandedItems, this.props.onExpansionChanged);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let needUpdate = false;
        if (this.props.items !== prevProps.items) {
            this.refreshModel();
            this.refreshSelectionCtrl();
            this.refreshExpansionCtrl();
            needUpdate = true;
        } else {
            if (this.props.selection !== prevProps.selection) {
                this.refreshSelectionCtrl();
                needUpdate = true;
            }
            if (this.props.expandedItems !== prevProps.expandedItems) {
                this.refreshExpansionCtrl();
                needUpdate = true;
            }
        }
        if (needUpdate) {
            this.forceUpdate();
        }
    }

    onCollapseChanged = (isOpen) => {
        if (this.props.collapsable && this.props.onCollapseChanged) {
            this.props.onCollapseChanged(isOpen);
        }
    };

    isOpen = () => {
        return this.props.isOpen;
    };

    createSingleSelectionChangeHandlers = (item) => {
        return () => {
            if (this.selectionCtrl) {
                this.selectionCtrl.select(item);
            }
        };
    };

    createMultiSelectionChangeHandlers = (item) => {
        return () => {
            if (this.selectionCtrl) {
                this.selectionCtrl.toggleSelect(item);
            }
        };
    };

    renderFlatItem = (item, treeSelection) => {
        if (this.props.allowMultiSelection) {
            return <Checkbox label={this.props.itemLabel(item)}
                             checked={treeSelection.isSelected(item)}
                             onChange={this.multiSelectionChangeCache.get(item)}/>;
        } else {
            return <Radio label={this.props.itemLabel(item)}
                          checked={treeSelection.isSelected(item)}
                          onChange={this.singleSelectionChangeCache.get(item)}/>;
        }
    };

    render() {
        const {
            t, label,
            itemLabel, itemId, itemChildrenSupplier,
            allowEmptySelection, allowMultiSelection,
            collapsable, displayItemsFilter
        } = this.props;

        const items = this.treeModel._nodes;

        const isOpen = this.isOpen();

        const renderValuesSelector = () => {
            if (this.selectionCtrl.tree.depth === 1) {
                return <>
                    {
                        items
                            .filter(value => this.state.itemsFilterValue && this.state.itemsFilterValue.length > 0 ?
                                this.props.itemLabel(value).toLowerCase().indexOf(this.state.itemsFilterValue.toLowerCase()) > -1 : true)
                            .map(item => (
                                <div key={itemId(item)} className='bp5-tree-node-content' style={{paddingLeft: 5}}>
                                    {
                                        this.renderFlatItem(item, this.selectionCtrl)
                                    }
                                </div>
                            ))
                    }
                </>;
            } else {
                const filterFn = (node: any): boolean => {
                    if (this.state.itemsFilterValue && this.state.itemsFilterValue.length > 0) {
                        //if node label contains itemsFilterValue keep node and his children
                        if (itemLabel(node.nodeData).toLowerCase().indexOf(this.state.itemsFilterValue.toLowerCase()) >= 0) {
                            return true;
                        }
                        if (node.childNodes && node.childNodes.length > 0) {
                            //filter child
                            node.childNodes = node.childNodes.filter(filterFn);

                            //If there is at least one child to display then display current node
                            if (node.childNodes.length > 0) return true;
                        }
                        return false
                    }
                    return true;
                };
                return <TreeSelector treeSelectionCtrl={this.selectionCtrl as MultiTreeSelectionCtrl<any>}
                                     treeExpansionCtrl={this.expansionCtrl}
                                     nodeLabel={itemLabel}
                                     nodeId={itemId}
                                     filterFn={filterFn}
                />;
            }
        };

        const selectAll = {
            id: 'selectAll',
            tooltip: t('action.selectAll'),
            icon: <SmartIcon icon={mdiCheckboxMultipleMarked} color={Colors.GRAY1}/>,
            onClick: (this.selectionCtrl as MultiTreeSelectionCtrl<any>).selectAll
        };
        const unselectAll = {
            id: 'unselectAll',
            tooltip: t('action.clearSelection'),
            icon: <SmartIcon icon={mdiCheckboxMultipleBlankOutline} color={Colors.GRAY1}/>,
            onClick: this.selectionCtrl.unselectAll
        };
        const invert = {
            id: 'invert',
            tooltip: t('action.invertSelection'),
            icon: <SmartIcon icon={mdiSwapHorizontal} color={Colors.GRAY1}/>,
            onClick: (this.selectionCtrl as MultiTreeSelectionCtrl<any>).invert
        };


        const tools = [];
        let itemsFilter = null;
        if (isOpen) {
            if (displayItemsFilter && !this.state.itemsFilterOpened) {

                tools.push({
                    id: 'search',
                    tooltip: t('action.search'),
                    icon: <SmartIcon icon={'search'} color={Colors.GRAY1}/>,
                    onClick: this.onSearchIconClick
                });
            }
            if (allowMultiSelection) {
                tools.push(selectAll);
                tools.push(invert);
            }
            if (allowEmptySelection) {
                tools.push(unselectAll);
            }


            if (this.state.itemsFilterOpened) {
                const style = {
                    margin: '2px',
                    height: 26
                };
                const nodes = this.selectionCtrl.tree.depth === 1 ? items : (this.selectionCtrl as MultiTreeSelectionCtrl<any>)._treeModel.flattenTree();
                const filterLabels = nodes.map(item => this.props.itemLabel(item))
                    .filter((value, index, array) => {
                        return index === array.indexOf(value);
                    });


                const LabelSuggest = Suggest.ofType<string>();

                itemsFilter = this.props.suggestSearch ? <LabelSuggest
                    inputProps={{
                        leftElement: <Tool icon={<SmartIcon icon={'search'} color={Colors.GRAY1}/>}
                                           onClick={this.onSearchIconClick}/>,
                        style: style
                    }}
                    items={filterLabels}
                    itemPredicate={this.filterSuggest}
                    itemRenderer={(item) => <MenuItem
                        key={item}
                        onClick={(event) => {
                            this.setState({itemsFilterValue: item});
                        }}
                        text={item}/>}
                    query={this.state.itemsFilterValue}
                    inputValueRenderer={(item) => item}
                    openOnKeyDown={true}
                    popoverContentProps={{className: 'hide'}}
                    noResults={<MenuItem disabled={true} text={t('noResult')}/>}
                    onItemSelect={(value) => {
                    }}
                    onQueryChange={(query: string, event?: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({itemsFilterValue: query});
                    }}
                /> : <InputGroup value={this.state.itemsFilterValue} leftIcon={"search"}
                                 onBlur={event => this.blurSearch(this.state.itemsFilterValue)}
                                 onValueChange={(value: string, event) => {
                                     this.setState({itemsFilterValue: value});
                                 }}/>
            }
        }
        const headerRight: ReactNode = <>{itemsFilter}<Tools tools={tools}/></>;

        return <CollapsablePanel collapsable={collapsable}
                                 isOpen={isOpen}
                                 onChange={this.onCollapseChanged}
                                 className={this.props.className}
                                 headerRight={headerRight}>
            <div style={{padding: '5px 0', fontWeight: isOpen ? 'bold' : 'normal'}}>{label}</div>
            {renderValuesSelector()}
        </CollapsablePanel>;
    }

    blurSearch(searchValue: string) {
        if (!searchValue || searchValue.length === 0) {
            this.setState({itemsFilterOpened: false});
        }
    }
}

export const SelectPanel = withTranslation()(SelectPanelComponent);

