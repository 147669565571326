import _ from 'lodash';
import { AllItemsMatch } from '../../../utils/matcher/AllItemsMatch';
import { Every } from '../../../utils/matcher/Every';
import { IsEmpty } from '../../../utils/matcher/IsEmpty';
import { IsNotNil } from '../../../utils/matcher/IsNotNil';
import { Not } from '../../../utils/matcher/Not';
import { TypeOf } from '../../../utils/matcher/TypeOf';
import { Field } from './Field';
import { FieldValue } from './FieldValue';
import { Predicate } from "../../../utils/matcher/Predicate";
import { NoneFieldValue } from "./NoneFieldValue";

/**
 * Represents a path to a value. It can be a string with / separator or an array of strings or a Path object
 */
type PathArg = OneOrMany<string> | Path;

export class FieldValues {
  private readonly _field: Field;
  private readonly _values: Array<FieldValue> = [];
  private readonly _valuesIndex: Dict<FieldValue> = {};

  constructor(field: Field) {
    if (_.isNil(field)) {
      throw new Error('field must be non null');
    }

    if (!(field instanceof Field)) {
      throw new Error(`field must be an instance of Field`);
    }

    this._field = field;
  }

  get field(): Field {
    return this._field;
  }

  get values(): Array<FieldValue> {
    return this._values;
  }

  sort(): void {
    this._values.sort(FieldValue.comparator);
    this._values.forEach(fieldValue => fieldValue.sort());
  }

  isEmpty(includeEmptyValue: boolean = true): boolean {
    if (includeEmptyValue) {
      return this._values.length === 0;
    }

    return this._values.filter(v => !(v instanceof NoneFieldValue)).length === 0;
  }

  addFieldValue(fieldValue: FieldValue): void {
    this._values.push(fieldValue);
    this._valuesIndex[fieldValue.name] = fieldValue;
  }

  add(childValue: any): FieldValue {
    const pathArg: OneOrMany<string> = childValue.name;
    const path: Path = this.convertToPath(pathArg);
    const pathLength = path.path.length;
    if (this.contains(path)) {
      throw new Error(`FieldValue at path ${pathArg} already exists`);
    }

    if (!this._field.isHierarchical() && path.isHierarchical()) {
      throw new Error('non hierarchical field doesn\'t support hierarchical path');
    }

    let name: string = path.path[0];
    let result: FieldValue = this.find(name);
    if (_.isNil(result)) {
      result = new FieldValue(this._field, pathLength ===1 ? {...childValue, name} : {name: name});
      this.addFieldValue(result);
    }

    for (let i = 1; i < path.path.length; i++) {
      name = path.path[i];
      let child = result.children.find(c => c.name === name);
      if (_.isNil(child)) {
        result = result.addChild(pathLength === i + 1 ? {...childValue, name} : {name: name});
      } else {
        result = child;
      }
    }

    return result;
  }

  getLeaves(): Array<FieldValue> {
    return this._values.flatMap(value => value.getLeaves(true));
  }

  getAllButLeaves(): Array<FieldValue> {
    return this._values.flatMap(value => value.getAllButLeaves(true));
  }

  flattenValues(): Array<FieldValue> {
    return this._values.flatMap(value => value.flattenValues(true));
  }

  find(pathArg: PathArg): Maybe<FieldValue> {
    if (_.isNil(pathArg) || _.isEmpty(pathArg)) {
      return undefined;
    }

    let path: Path;
    if (pathArg instanceof Path) {
      path = pathArg;
    } else {
      path = this.convertToPath(pathArg);
    }

    let result: FieldValue;
    let src: Dict<FieldValue> | Array<FieldValue> = this._valuesIndex;
    for (let pathItem of path) {
      if (_.isArray(src)) {
        result = (src as Array<FieldValue>).find(c => c.name === pathItem);
      } else {
        result = (src as Dict<FieldValue>)[pathItem];
      }
      if (_.isNil(result)) {
        return undefined;
      }
      src = result.children;
    }

    return result;
  }

  contains(pathArg: PathArg): boolean {
    return !_.isNil(this.find(pathArg));
  }

  private convertToPath(fieldValue: OneOrMany<string>): Path {
    if (!this._field.isHierarchical()) {
      if (_.isArray(fieldValue)) {
        throw new Error('non hierarchical field cannot handle fieldValue as path');
      }
      return new Path([fieldValue as string]);
    }

    return Path.parse(fieldValue);
  }
}

class Path implements Iterable<string> {
  private static SEPARATOR: string = '/';
  private static PREDICATE: Predicate<Array<string>> = new AllItemsMatch<string>(new Every([
    new IsNotNil(),
    new TypeOf('string'),
    new Not(new IsEmpty())
  ]));

  static parse(value: OneOrMany<string>): Path {
    let path: Array<string>;

    if (_.isString(value)) {
      path = (value as string).split(Path.SEPARATOR);
    } else if (_.isArray(value)) {
      path = value as Array<string>;
    }

    return new Path(path);
  }

  readonly path: Array<string>;

  constructor(path: Array<string>) {
    this.path = path;

    if (_.isNil(path)) {
      throw new Error('Path cannot be nil');
    }

    if (_.isEmpty(path)) {
      throw new Error('Path cannot be empty');
    }

    if (!Path.PREDICATE.match(path)) {
      throw new Error('Invalid path ' + path);
    }
  }

  isHierarchical(): boolean {
    return this.path.length > 1;
  }

  [Symbol.iterator](): IterableIterator<string> {
    return this.path.values();
  }
}
