import { services } from '../../application/service/services';

export const sourcingState = () => ({
  state: {
  },
  reducers: {
    setTranscribeConfiguration(state, payload) {
      state.transcribeConfiguration = payload;
      return state;
    }
  },
  effects: (dispatch) => ({
    async loadTenantInfo(payload, rootState) {
      dispatch.sourcing.setTranscribeConfiguration(services.getConfigurationService().transcribeConfiguration);
    },
    async sourceAudio(payload, rootState) {
      await services.getSourcingService().sourceAudio(payload);
    },
    async sourceXlsx(payload, rootState) {
      await services.getSourcingService().sourceXlsx(payload);
    },
    async sourceCsv(payload, rootState) {
      await services.getSourcingService().sourceCsv(payload);
    },
    async sourceJson(payload, rootState) {
      await services.getSourcingService().sourceJson(payload);
    },
  })
});