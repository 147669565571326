import {Spinner, SpinnerSize} from '@blueprintjs/core';
import {Tonality} from '@eptica/vecko-js-commons';
import _ from 'lodash';
import React from 'react';
import {Trans, WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {SatTypeEnum} from '../../../../application/model/SatTypeEnum';
import {services} from '../../../../application/service/services';
import {SwitchButton} from '../../../../component/SwitchButton';
import {SelectMode, Table} from '../../../../component/table/Table';
import {VisualizationInstance} from '../../../model/viz/VisualizationInstance';
import {getDashboardResolvedParams} from '../../../state/selectors/dashboardSelector';
import {TrendIcon} from '../../components/trend/TrendIcon';
import '../common.scss';
import './topicImpactViz.scss';
import {ResolvedParams} from "../../../model/params/resolve/ResolvedParams";
import {ClickableViz} from "../ClickableViz";
import {FieldValue} from "../../../../application/model/field/FieldValue";
import {FieldVizFeedbacksQueryContext, VizFeedbacksQueryContext} from "../../../model/viz/VizFeedbacksQueryContext";


interface TopicImpactVizProps extends WithTranslation {
    viz: VisualizationInstance,
    dashboardId: string,
    dashboardResolvedParams: ResolvedParams,
    openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => void
}

interface StateProps {
    clickItem?: any;
    showMore: boolean,
    inProgress: boolean,
    uiData?: any
};

class TopicImpactComponent extends React.PureComponent<TopicImpactVizProps> implements ClickableViz {

    state: StateProps = {
        showMore: false,
        inProgress: false
    };


    constructor(props) {
        super(props);
    }

    onSelectionChange(selection: Array<any>, newlySelectedItem: any, event: MouseEvent) {
        this.state.clickItem = newlySelectedItem;
        const {t} = this.props;
        this.props.openFeedbacksPanel(this, t(`enhancement.categories.topic/` + newlySelectedItem.topic, newlySelectedItem.topic) as string);
        event.preventDefault();
        event.stopPropagation();
    }

    toggleShow = () => {
        const {viz, dashboardResolvedParams} = this.props;

        this.state.showMore = !this.state.showMore;
        if (this.state.showMore) {
            this.setState({...this.state, inProgress: true});

            services.getDashboardService().getVizInstanceData(viz, dashboardResolvedParams, {count: 100})
                .then((result) => {
                    this.setData(result);
                })
                .catch((error) => {
                    this.setState({...this.state, error: error});
                });
        } else {
            // restore originalData
            this.setData(viz.data);
        }
    };

    setData = (data) => {
        const uiData = TopicImpactComponent.getUiData(this.props.viz, this.props.t, data);
        this.setState({...this.state, inProgress: false, uiData});
    };

    static getUiData = (viz, t, data) => {
        const renderers = {
            topic: (value) => services.getFieldsService().findCategoryLabel('topic', value.topic, t),
            impact: (value) => {
                const number= value.impact;
                return <div className="topic-impact-value">
                    <div className='topic-impact-value-inner'>
                        <div className='topic-impact-value-left'>
                            <Trans i18nKey="dashboard.misc.point" count={value.impact}
                                   values={{context: value.impact < 0 ? 'negative' : 'positive'}}>
                                <span className="vuiDephasis--font">point</span>
                            </Trans>
                        </div>
                        <div className='topic-impact-value-right'>
                            {
                                _.isNil(value.trend) ? null : <TrendIcon value={value.trend} size={18}/>
                            }
                        </div>
                    </div>
                </div>;
            },
            count: (value) => (value.count)
        };

        const styles = {
            topic: {
                flex: 1
            },
            impact: {
                width: 160
            },
            count: {
                width: 150
            }
        };

        const satType = SatTypeEnum.valueOf(viz.definition.params.satType);
        return {
            data: data,
            display: _.map(renderers, (renderer, key) => ({
                headerRenderer: _.capitalize(
                    t(`dashboard.viz.topicImpact.header.${key}`, {satType: satType.getAliasedName()})),
                dataRenderer: _.partialRight(renderer, t),
                style: styles[key]
            }))
        };
    };

    static getDerivedStateFromProps = (props, state) => {
        const {t, viz} = props;
        if (!state.showMore) {
            const uiData = TopicImpactComponent.getUiData(viz, t, viz.data);
            return {...state, uiData};
        }

        return state;
    };

    render() {
        const {t, viz} = this.props;
        const params = viz.definition.params;
        const {inProgress, uiData, showMore} = this.state;

        const color = Tonality.getValueOf(params.polarity).color;

        return <div className="topic-impact">
            <div className="topic-impact-table">
                {
                    inProgress ? <Spinner size={SpinnerSize.SMALL}/> : <Table color={color} dataset={uiData}
                                                                              selectMode={SelectMode.ROW}
                                                                              selectionChanged={this.onSelectionChange.bind(this)}
                                                                              cellCanSelect={true}
                                                                              selectable={true}/>
                }
            </div>
            <div>
                <SwitchButton isOn={showMore}
                              onChange={this.toggleShow}
                              onLabel={t('action.showLess')}
                              offLabel={t('action.showMore')}
                              buttonProps={{minimal: true, className: 'no-print', icon: 'search'}}
                />
            </div>
        </div>;
    }

    getClickedFieldValue(): FieldValue {
        if (this.state.clickItem) {
            const {viz} = this.props;
            return services.getFieldsService().findFieldValue(viz.definition.params.field, this.state.clickItem.category);
        }
        return null;
    }

    getFeedBacksQueryContext(): VizFeedbacksQueryContext {
        const result = new FieldVizFeedbacksQueryContext();
        if (this.state.clickItem) {
            const {viz, t} = this.props;
            result.field = 'enhancement.categories.topic';
            result.selectedValue = this.state.clickItem.topic;
        }
        return result;
    }

}

const mapStateToProps = (state) => {
    return {
        dashboardId: state.dashboard.id,
        dashboardResolvedParams: getDashboardResolvedParams(state)
    };
};
const mapDispatchToProps = (dispatch, props) => {
    return {
        openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => {
            dispatch.dashboard.openFeedBackPanel({viz, panelTitle});
        },
    }
};

export const TopicImpactViz = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(withTranslation(undefined, {withRef: true})(TopicImpactComponent));
