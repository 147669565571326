import React from 'react';
import { AbstractFilterCtrl, TagValue } from './AbstractFilterCtrl';
import { Checkbox } from "@blueprintjs/core";
import { StateConverter } from "./stateConverters/StateConverter";
import { stateConverterRegistry } from "./stateConverters/stateConverterRegistry";
import { TFunction } from "i18next";

export class BooleanFilterCtrl extends AbstractFilterCtrl<Boolean> {
  private tagKey: string;
  getTagValues(t:  TFunction): Array<TagValue<Boolean>> {
    return [{tagValue: t(this.tagKey), value: this.getValue()}];
  }
  getValue(): Boolean {
    return this.value;
  }

  setValue(value: Boolean): void {
    this.value = !!value;
  }

  private value: Boolean;

  constructor(id: string, initValue: boolean = false, tagKey:string,  displayed: () => boolean) {
    super(id);
    this.displayed = displayed;
    this.value = initValue;
    this.tagKey = tagKey;
  }

  uiComponent(t:TFunction, onSelectionChanged, options): JSX.Element {
    return <div key={this.id} className='bp5-tree-node-content' style={{ padding: '5px 0px 5px 5px'}}>
      <Checkbox checked={this.value.valueOf()} label={t(this.tagKey)}
                onClick={() => {
                  this.setValue(!this.value);
                  onSelectionChanged(this.value);
                }}/>
    </div>
  }

  hasValue(): boolean {
    return !!this.value;
  }

  stateConverter(): StateConverter<Boolean> {
    return stateConverterRegistry.get(Boolean);
  }
}