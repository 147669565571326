import React from 'react';
import './actionListViz.scss';
import { useTranslation } from 'react-i18next';

export function EmptyActionList() {
  const { t } = useTranslation();

  return <div className="action-list-empty">
    <span dangerouslySetInnerHTML={{__html: t('dashboard.viz.actionList.emptyLabel')}} />
  </div>;
}
