import { DateRange } from '../application/model/DateRange';
import { DateTime } from "luxon";

export const dateFormat = 'yyyy-MM-dd';

export const thisWeek = () => {
  const start = DateTime.now().startOf('week');
  const end = DateTime.now();

  return new DateRange(start, end);
};

export const lastWeek = () => {
  const start = DateTime.now().startOf('week').minus( {week:1});
  const end = DateTime.now().endOf('week').minus( {week:1});

  return new DateRange(start, end);
};

export const thisMonth = () => {
  const start = DateTime.now().startOf('month');
  const end = DateTime.now();

  return new DateRange(start, end);
};

export const lastMonth = () => {
  const start = DateTime.now().startOf('month').minus({month:1});
  const end = DateTime.now().minus({month:1}).endOf('month');

  return new DateRange(start, end);
};

export const thisQuarter = () => {
  const start = DateTime.now().startOf('quarter');
  const end = DateTime.now();

  return new DateRange(start, end);
};

export const lastQuarter = () => {
  const start = DateTime.now().startOf('quarter').minus({quarter:1});
  const end = DateTime.now().minus({quarter:1}).endOf('quarter');

  return new DateRange(start, end);
};

export const thisYear = () => {
  const start = DateTime.now().startOf('year');
  const end = DateTime.now();

  return new DateRange(start, end);
};

export const lastYear = () => {
  const start = DateTime.now().startOf('year').minus({year:1});
  const end = DateTime.now().endOf('year').minus({year:1});

  return new DateRange(start, end);
};

export const last12Months = () => {
  const end = DateTime.now();
  const start = end.minus( {month: 12});

  return new DateRange(start, end);
};

export const isLastDayOfMonth = (date:DateTime):boolean => {
  return date.day === date.daysInMonth;
};

export const isFirstDayOfMonth = (date:DateTime):boolean => {
  return date.day === 1;
};

export const getDateFromObject = (dateAsObject:any):DateTime => {
  return DateTime.now();
};



