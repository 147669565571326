import { Button, Classes, Colors, Dialog, Intent } from '@blueprintjs/core';
import _ from 'lodash';
import React, { KeyboardEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { VisualizationInstance } from '../model/viz/VisualizationInstance';
import { getEditedViz } from '../state/selectors/dashboardSelector';

interface VisualizationEditorDialogProps extends WithTranslation {
  viz: VisualizationInstance,
  vizEditorData: any,
  isVisible: boolean,
  isValidating: boolean,
  onValidate,
  onCancel,
  onVizEditorValueChanged
}

export class VisualizationEditorDialogComponent extends React.PureComponent<VisualizationEditorDialogProps> {

  static defaultProps: Partial<VisualizationEditorDialogProps> = {
    isVisible: false,
    isValidating: false
  };

  divRef = React.createRef<HTMLDivElement>();

  componentDidUpdate(prevProps) {
    if (this.props.isVisible && prevProps.isVisible != this.props.isVisible) {
      this.focusDiv();
    }
  }

  focusDiv() {
    if (this.divRef.current) {
      this.divRef.current.focus();
    }
  }

  onKeyPress(event: KeyboardEvent<any>) {
    const { onValidate } = this.props;
    if (event.key === 'Enter') {
      onValidate();
    }
  }
  onBackDropMouseDown(event) {
    setTimeout( this.focusDiv.bind(this),50);
  }

  render() {
    const {
      t,
      viz, vizEditorData,
      isVisible,
      isValidating,
      onValidate, onCancel,
      onVizEditorValueChanged
    } = this.props;

    if (!isVisible) {
      return <Dialog isOpen={false}/>;
    }

    const vizEditor = viz.definition.getEditor();
    const EditorComponent = vizEditor.component;
    const EditorTitle = viz.getEditorTitle(t);

    const dialogBodyStyle = {
      backgroundColor: Colors.WHITE,
      border: `1px solid ${Colors.GRAY5}`,
      borderRadius: 4
    };

    return <Dialog
      icon="edit"
      usePortal={true}
      onClose={onCancel}
      title={EditorTitle}
      isOpen={true}
      backdropProps={
        {
          onMouseDown: this.onBackDropMouseDown.bind(this)
        }
      }
      canOutsideClickClose={false}
      autoFocus={vizEditor.focusOnMount}
      style={{ width: vizEditor.width || 800 }}
    >
      <div tabIndex={-1} onKeyPress={this.onKeyPress.bind(this)} ref={this.divRef}>
        <div className={Classes.DIALOG_BODY} style={dialogBodyStyle}>
          <EditorComponent viz={viz}
                           editorData={vizEditorData}
                           onValueChanged={onVizEditorValueChanged}/>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={onCancel} disabled={isValidating}>{t('action.cancel').toUpperCase()}</Button>
            <Button intent={Intent.PRIMARY} onClick={onValidate} loading={isValidating}>{t(
              'action.apply').toUpperCase()}</Button>
          </div>
        </div>
      </div>
    </Dialog>;
  }
}

//-- connect
const mapStateToProps = (state) => {
  const viz = getEditedViz(state);

  if (_.isUndefined(viz)) {
    return {
      isVisible: false
    };
  }

  return {
    isVisible: true,
    viz: viz,
    isValidating: state.loading.effects.dashboardVizEditor.validate,
    vizEditorData: state.dashboardVizEditor.dataInEdition
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCancel: () => {
      dispatch.dashboardVizEditor.cancel();
    },
    onValidate: () => {
      dispatch.dashboardVizEditor.validate();
    },
    onVizEditorValueChanged: (kind, key, value) => {
      dispatch.dashboardVizEditor.setEditValue({ kind, key, value });
    }
  };
};

export const VisualizationEditorDialog = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(VisualizationEditorDialogComponent));