import { Tonality } from '@eptica/vecko-js-commons';
import { SatTypeEnum } from '../../../application/model/SatTypeEnum';
import { SearchAspect } from './SearchAspect';
import _ from "lodash";

export class SatAndTopicsAspect extends SearchAspect {
  static parse(object) {
    return new SatAndTopicsAspect(object.satType, object.sat, object.topics);
  }

  /**
   * @type {SatType}
   */
  _satType;

  /**
   * @type {Tonality}
   */
  _sat;

  /**
   * @type {Array<string>}
   */
  _topics;

  constructor(satType, sat, topics) {
    super('satAndTopics');
    this._satType = _.isString(satType) ? SatTypeEnum.valueOf(satType) : satType;
    this._sat = _.isString(sat) ? Tonality.getValueOf(sat) : sat;
    this._topics = topics;
  }

  get sat() {
    return this._sat;
  }

  toPlainObject() {
    if (_.isNil(this._satType)) {
      throw new Error('satType cannot be nil')
    }

    if (_.isNil(this._sat)) {
      throw new Error('sat cannot be nil')
    }

    if (_.isEmpty(this._topics)) {
      throw new Error('topics cannot be empty')
    }

    const result:any = super.toPlainObject();
    result.satType = this._satType.name;
    result.sat = this._sat.name();
    result.topics = this._topics;

    return result;
  }
}