import * as React from 'react';
import { BlankSeparator } from './BlankSeparator';

interface SeparatedComponentsProps {
  separator: JSX.Element,
  children?: React.ReactNode
}

export class SeparatedComponents extends React.PureComponent<SeparatedComponentsProps> {
  static defaultProps: Partial<SeparatedComponentsProps> = {
    separator: <BlankSeparator/>
  };

  render() {
    const children = React.Children.toArray(this.props.children);

    if (children.length === 0) {
      return null;
    }

    if (children.length === 1) {
      return children;
    }

    const result = [];
    for (let i = 0; i < children.length - 1; i++) {
      result.push(children[i]);
      result.push(React.cloneElement(this.props.separator, {key: `separator-${i}`}));
    }
    result.push(children[children.length - 1]);

    return <>{result}</>;
  }
}