import React from 'react';
import './circle.scss';
import classNames from 'classnames';

interface CircleProps {
  text: string | React.ReactNode,
  size?: number,
  backgroundColor?: string,
  color?: string,
  className?: string
}

export class Circle extends React.PureComponent<CircleProps> {
  static defaultProps: Partial<CircleProps> = {
    size: 30,
    backgroundColor: '#fff',
    color: '#000',
  };

  render() {
    const { size, backgroundColor, color, text, className } = this.props;

    const bubbleStyle = {
      backgroundColor: backgroundColor,
      color: color,
      width: size,
      minWidth: size,
      height: size,
      minHeight: size,
      fontSize: size
    };

    const clazz = classNames('circle', className);

    return <div className={clazz} style={bubbleStyle}>{text}</div>;
  }
}
