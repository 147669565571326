import { Enum, EnumValue } from "../../utils/enum";
import { TFunction } from "i18next";
import _ from "lodash";

export class FeedbacksListViewMode extends EnumValue {
  readonly icon: string;

  constructor(icon: string) {
    super();
    this.icon = icon;
  }

  getLabel(t: TFunction): string {
    return _.capitalize(t(`topicStatDetailViewMode.${this.name}`));
  }

  getIcon(): string {
    return this.icon;
  }
}

class FeedbacksListViewModeType extends Enum<FeedbacksListViewMode> {
  FEEDBACKS = new FeedbacksListViewMode('document');
  SEQUENCES = new FeedbacksListViewMode('array');

  constructor() {
    super();
    this.initEnum('TopicStatDetailViewMode');
  }
}

export const FeedbacksListViewModeEnum = new FeedbacksListViewModeType();