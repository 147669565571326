import * as React from 'react';
import { WithTranslation } from 'react-i18next';
import { MapEditor } from "./MapEditor";

interface FlatMapEditorProps<T> extends WithTranslation {
    className?: string,
    possibleItems: Array<T>,
    items: Array<T>,
    itemId: (item: T) => string,
    itemLabel: (item: T) => string,
    itemSelector: (items: Array<T>, selectedItem:T, onSelectionChanged: (item:T) => void) => JSX.Element,
    onChanged: (items: Array<T>) => void,
    disabled?: boolean,
    enableItemOrdering?: boolean,
    fill?: boolean,
    annihilateEnterKey?:boolean
}

export class FlatMapEditor<T> extends React.PureComponent<FlatMapEditorProps<T>> {
    static defaultProps: Partial<FlatMapEditorProps<any>> = {
        disabled: false,
        enableItemOrdering: false,
        fill: true
    }

    itemKeyGetter = (item: T): T => {
        return item;
    };

    itemValueGetter = (item: T): T => {
        return item;
    };

    itemEditor = () => {
        return null;
    };

    createItem = (key: T): T => {
        return key;
    };

    createEmptyItem = (key: T): T => {
        return key;
    };

    render() {
        const { className, possibleItems, items, itemId, itemLabel, itemSelector, enableItemOrdering, fill, disabled, onChanged, annihilateEnterKey } = this.props;

        return <MapEditor className={className}
                          keys={possibleItems}
                          items={items}
                          itemKeyGetter={this.itemKeyGetter}
                          itemValueGetter={this.itemValueGetter}
                          keyId={itemId}
                          keyLabel={itemLabel}
                          keySelector={itemSelector}
                          itemEditor={this.itemEditor}
                          createItem={this.createItem}
                          createEmptyItem={this.createEmptyItem}
                          onChanged={onChanged}
                          enableItemOrdering={enableItemOrdering}
                          fill={fill}
                          disabled={disabled}
                          annihilateEnterKey={annihilateEnterKey}
        />;
    }
}