import {services} from '../../application/service/services';
import {PaginatedResult, Progress} from "../model";

export const PROGRESS_ACTIONS_ROLE = {
    viewProgress: 'vecko.sourcing.counters',
    viewNumbers: 'vecko.sourcing.counters',
    cancel: 'vecko.sourcing.cancel',
    delete: 'vecko.sourcing.delete',
    replay: 'vecko.sourcing.replay',
    cleanup: 'vecko.sourcing.cleanup',
    reindex: 'vecko.data.reindex',
}
const ROLES = Object.values(PROGRESS_ACTIONS_ROLE);

export type ProgressAction = keyof typeof PROGRESS_ACTIONS_ROLE;

export class ProgressService {
    _progressFetcher;

    init() {
        this._progressFetcher = services.getFetcherService().getFetcher('progress');
    }

    isAuthorized(): boolean {
        return services.getTenantService().isAuthorizedForGetTenant() &&
            services.getSecurityService().hasOneOfRoles(ROLES);
    }

    isAuthorizedFor(action: ProgressAction, selectedTenantId: string): boolean {
        const isCurrentTenant = this.isCurrentTenant(selectedTenantId);
        const role = PROGRESS_ACTIONS_ROLE[action];
        return services.getSecurityService().hasRole(role) &&
            (!isCurrentTenant ? services.getSecurityService().hasRole('vecko.admin.data.multitenant') : true);
    }

    async getProgress(page: number, pageSize: number, tenantId: string): Promise<PaginatedResult<Progress>> {
        return this._progressFetcher.getProgress(page, pageSize, this.getTenantForFetch(tenantId));
    }

    async cancelAll(tenantId: string) {
        await this._progressFetcher.cancelAll(this.getTenantForFetch(tenantId));
        this.displaySuccess('cancelAll');
    }

    async deleteAll(tenantId: string) {
        await this._progressFetcher.deleteAll(this.getTenantForFetch(tenantId));
        this.displaySuccess('deleteAll');
    }

    async replayAll(tenantId: string) {
        await this._progressFetcher.replayAll(this.getTenantForFetch(tenantId));
        this.displaySuccess('replayAll');
    }

    async reindex(tenantId: string): Promise<void> {
        await this._progressFetcher.reindex(tenantId);
        this.displaySuccess('reindex');
    }

    async reindexStreams(): Promise<void> {
        await this._progressFetcher.reindexStreams();
        this.displaySuccess('reindexStreams');
    }


    async cleanup(tenantId: string) {
        await this._progressFetcher.cleanup(this.getTenantForFetch(tenantId));
        this.displaySuccess('cleanup');
    }

    async cancelSubstream(substream: string, tenantId: string) {
        await this._progressFetcher.cancelSubstream(substream, this.getTenantForFetch(tenantId));
        this.displaySuccess('cancel', substream);
    }

    async deleteSubstream(substream: string, tenantId: string) {
        await this._progressFetcher.deleteSubstream(substream, this.getTenantForFetch(tenantId));
        this.displaySuccess('delete', substream);
    }

    async replaySubstream(substream: string, tenantId: string) {
        await this._progressFetcher.replaySubstream(substream, this.getTenantForFetch(tenantId));
        this.displaySuccess('replay', substream);
    }

    private displaySuccess(operation: string, substream?: string) {
        services.getApplicationService().notifyMessage({
            level: 'SUCCESS',
            text: {
                key: `administration.sourcing.progress.${operation}.success`,
                args: {
                    substream: substream
                }
            }
        });
    }

    private isCurrentTenant(tenantId: string): boolean {
        return services.getTenantService().currentTenant.id === tenantId;
    }

    private getTenantForFetch(tenantId: string): string | null {
        return this.isCurrentTenant(tenantId) ? null : tenantId;
    }
}

services.registerService('progressService', new ProgressService());