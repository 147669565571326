import {Divider, NonIdealState, Spinner, SpinnerSize} from '@blueprintjs/core';
import classnames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Pagination} from './Pagination';
import _ from "lodash";

interface PaginatedListProps extends WithTranslation {
    isLoading?: boolean,
    renderLoading?: any,
    items?: any[],
    renderEmpty?: any,
    paginationProps?: any,
    itemsWrapperClassName?: any,
    className?: any,
    paginationWrapperClassName?: string,
    paginationWrapperStyle?: any,
    renderItem: any,
    style?: any,
    itemId?: any,
    itemsWrapperStyle?: any
    renderItemSeparator?: any
}

class PaginatedListComponent extends React.PureComponent<PaginatedListProps> {


    private itemsWrapperElement: any;
    renderEmpty = () => {
        return <NonIdealState
            icon='search'
            title={this.props.t('misc.search.noResult')}
        />;
    };

    renderItemSeparator = () => {
        return <Divider/>;
    };

    renderLoading = () => {
        return <Spinner size={SpinnerSize.SMALL}/>;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.itemsWrapperElement && !_.isEqual(prevProps.items, this.props.items)) {
            this.itemsWrapperElement.scrollTo(0, 0);
        }
    }

    render() {
        if (this.props.isLoading) {
            const loadingRenderer = this.props.renderLoading || this.renderLoading;
            return loadingRenderer();
        }

        if (_.isEmpty(this.props.items)) {
            const emptyRenderer = this.props.renderEmpty || this.renderEmpty;
            return emptyRenderer();
        }

        const className = classnames('vui-layout--vertical', this.props.className);
        const itemsWrapperClassName = classnames('vui-layout--vertical__center',
            'vui-layout--vertical--scrollable',
            this.props.itemsWrapperClassName);

        const paginationProps = {
            ...this.props.paginationProps,
            style: {
                marginTop: 10,
                ...this.props.paginationProps.style
            }
        };

        const itemsCount = this.props.items.length;

        const renderItem = (item, i) => {
            const separatorRenderer = this.props.renderItemSeparator || this.renderItemSeparator;

            return <React.Fragment key={this.props.itemId(item, i)}>
                {this.props.renderItem(item, i)}
                {i < itemsCount - 1 ? separatorRenderer(item, i) : null}
            </React.Fragment>;
        };

        return <div className={className} style={this.props.style}>
            <div className={itemsWrapperClassName} style={this.props.itemsWrapperStyle}
                 ref={e => this.itemsWrapperElement = e}>
                {
                    this.props.items.map(renderItem)
                }
            </div>
            <div className={this.props.paginationWrapperClassName} style={this.props.paginationWrapperStyle}>
                <Pagination {...paginationProps}/>
            </div>
        </div>;
    }
}

export const PaginatedList = withTranslation()(PaginatedListComponent);
