export class Interval {
  static units = ['year', 'quarter', 'month', 'week', 'day', 'hour', 'minute'];
  static units_str = ['y', 'q', 'M', 'w', 'd', 'h', 'm'];
  private _amount: number;
  private _unit: string;

  static parse(str:string): Interval {
    const groups = str.match(/(\d+)([yqMwdhm])/);
    const unit = Interval.units[Interval.units_str.indexOf(groups[2])];
    return new Interval(Number.parseInt(groups[1]), unit);
  }

  constructor(amount:number, unit:string) {
    if (amount < 0) {
      throw new Error('Invalid Interval. amount must be greater than 0');
    }
    if (!Interval.units.includes(unit)) {
      throw new Error(`Invalid Interval. unit "${unit}" is not valid`);
    }

    this._amount = amount;
    this._unit = unit;
  }

  convertForApi() {
    return `${this._amount}${this._unitToString()}`;
  }

  _unitToString() {
    return Interval.units_str[Interval.units.indexOf(this._unit)];
  }

  get unit(){
    return this._unit;
  }

  get amount(){
    return this._amount;
  }

}