import { toArray } from '../../collectionUtils';
import { ApiFilter } from './ApiFilter';
import { parseFilter } from './parseFilter';
import _ from "lodash";

export class BoolFilter extends ApiFilter {

  static must(filter: ApiFilter, otherFilters: ApiFilter | Array<ApiFilter>): ApiFilter {
    const filtersToAdd = toArray(otherFilters).filter(f => !_.isNil(f));

    let result;

    if (_.isNil(filter)) {
      if (filtersToAdd.length === 0) {
        return null;
      }

      if (filtersToAdd.length === 1) {
        return filtersToAdd[0];
      }

      result = new BoolFilter(null, null, null);
      ;
    } else if (filter instanceof BoolFilter) {
      result = filter;
    } else {
      result = new BoolFilter(null, null, null);
      ;
      result.addMust(filter);
    }

    if (filtersToAdd.length === 0) {
      return filter;
    }

    filtersToAdd.forEach(f => result.addMust(f));

    return result;
  }

  static should(filter: ApiFilter, otherFilters: ApiFilter | Array<ApiFilter>): ApiFilter {
    const filtersToAdd = toArray(otherFilters).filter(f => !_.isNil(f));

    let result;

    if (_.isNil(filter)) {
      if (filtersToAdd.length === 0) {
        return null;
      }

      if (filtersToAdd.length === 1) {
        return filtersToAdd[0];
      }

      result = new BoolFilter(null, null, null);
      ;
    } else if (filter instanceof BoolFilter) {
      result = filter;
    } else {
      result = new BoolFilter(null, null, null).addShould(filter);
    }

    if (filtersToAdd.length === 0) {
      return filter;
    }

    filtersToAdd.forEach(f => result.addShould(f));

    return result;
  }

  static parse(object) {
    const boolFilter = new BoolFilter(null, null, null);
    if (object.must) {
      boolFilter.must = object.must.map(parseFilter);
    }
    if (object.should) {
      boolFilter.should = object.should.map(parseFilter);
    }
    if (object.mustNot) {
      boolFilter.mustNot = object.mustNot.map(parseFilter);
    }
    return boolFilter;
  }

  should: ApiFilter[] = [];
  must: ApiFilter[] = [];
  mustNot: ApiFilter[] = [];

  constructor(should: ApiFilter[], must: ApiFilter[], mustNot: ApiFilter[]) {
    super('bool');
    this.should = should ? should : this.should;
    this.must = must ? must : this.must;
    this.mustNot = mustNot ? mustNot : this.mustNot;
  }

  addShould(filter: ApiFilter) {
    if (filter) {
      if (!(filter instanceof ApiFilter)) {
        throw new Error('given filter must be an instance of ApiFilter');
      }
      this.should.push(filter);
    }
    return this;
  }

  addMust(filter: ApiFilter) {
    if (filter) {
      if (!(filter instanceof ApiFilter)) {
        throw new Error('given filter must be an instance of ApiFilter');
      }
      this.must.push(filter);
    }
    return this;
  }

  addMustNot(filter: ApiFilter) {
    if (filter) {
      if (!(filter instanceof ApiFilter)) {
        throw new Error('given filter must be an instance of ApiFilter');
      }
      this.mustNot.push(filter);
    }
    return this;
  }

  toPlainObject() {
    if (_.isEmpty(this.must) && _.isEmpty(this.should)) {
      throw new Error('must and should cannot be both empty');
    }
    const result: any = super.toPlainObject();
    if (!_.isEmpty(this.must)) {
      result.must = this.must.map(f => f.toPlainObject());
    }
    if (!_.isEmpty(this.should)) {
      result.should = this.should.map(f => f.toPlainObject());
    }
    if (!_.isEmpty(this.mustNot)) {
      result.mustNot = this.mustNot.map(f => f.toPlainObject());
    }

    return result;
  }

  getCopy(): ApiFilter {
    const apiFilter = new BoolFilter([...this.should], [...this.must], [...this.mustNot]);
    return apiFilter;
  }
}