import _ from 'lodash';
import React from 'react';
import {DateTime} from 'luxon';
import {Chart} from '../../../../component/chart/Chart';
import {VeckoColors} from '../../../../style/VeckoColors';
import {VisualizationInstance} from '../../../model/viz/VisualizationInstance';
import './evolutionViz.scss';
import {withTranslation, WithTranslation} from "react-i18next";
import {ChartType, SerieChartBuilder, SerieData, YAxis} from "../../../../utils/chart/SerieChartBuilder";
import {TooltipItem} from "chart.js";
import {connect} from "react-redux";

interface EvolutionVizProps extends WithTranslation {
    viz: VisualizationInstance,
    getSerieLabel: (serieParam: any) => string,
    yAxis?: YAxis,
    setRightPanelOpen: (opened: boolean) => void,
    onClick: (event, array: [], chart: Chart) => void,
}

export class EvolutionVizComponent extends React.PureComponent<EvolutionVizProps> {

    clickTime:number;
    clickedDataPoint:number;

    render() {
        let {viz, getSerieLabel, yAxis, t} = this.props;
        const chartBuilder = new SerieChartBuilder(t)
            .showLegend(true)
            .legend(viz.definition.uiParams.legend)
            .displayLabelsInline(true);


        if (this.props.onClick) {
            const onClickFn = (event, array: [], chart: Chart) => {
                if (this.clickTime && new Date().getTime() - this.clickTime < 500 && this.clickedDataPoint === event.x) {
                    this.clickTime = new Date().getTime();
                    this.clickedDataPoint = event.x;
                    event.type= 'dblClick';
                    this.props.onClick(event, array, chart);
                } else {
                    const clickTime = new Date().getTime()
                    this.clickTime = clickTime;
                    this.clickedDataPoint = event.x;
                    setTimeout((() => {
                        if(clickTime ===this.clickTime){
                            /*no click has happen*/
                            this.props.onClick(event, array, chart)
                        }
                    }).bind(this), 600);
                }

            }
            chartBuilder.onclick( onClickFn.bind(this));
        }

        const seriesName = viz.data.series;

        _.each(seriesName, serie => {
            const serieParam = _.find(viz.definition.params.series, ['name', serie]);
            const serieUiParam = _.assign({color: VeckoColors.DEFAULT_FG}, viz.definition.uiParams.series[serie]);

            const data = viz.data.values.map(dataValue => {
                let date = DateTime.fromISO(dataValue.date).toJSDate();
                const {values, ...result} = dataValue;
                return {...result, x: date, value: dataValue.values[serie]};
            });

            this.prepareChartSerie(serieParam.name, serieParam.field, getSerieLabel(serieParam), serieUiParam.color, serieUiParam, chartBuilder, data, yAxis);
        });

        return <div className="evolutionViz">
            <div className='evolutionViz__chartWrapper'>
                <Chart chartBuilder={chartBuilder}/>
            </div>
        </div>;
    }

    private prepareChartSerie(name: string, field: string, label: string, color: string, serieUiParam, chartBuilder: SerieChartBuilder, chartData: SerieData, yAxis: YAxis) {

        const tooltipRender = (value, tooltipItem: TooltipItem<any>) => {
            return <div>{tooltipItem.dataset.label} : {value}</div>;
        }
        chartBuilder.withSerie(
            {
                name: name,
                field: field,
                percentageMode: serieUiParam.percentageMode,
                stacked: serieUiParam.stacked,
                label: label,
                color: color,
                type: serieUiParam.chartType ? serieUiParam.chartType : ChartType.TIME_LINE,
                data: chartData,
                tooltipRenderer: tooltipRender
            }
        ).withYAxis(yAxis);

    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch, props) => {
    return {};
};

export const EvolutionViz = withTranslation(undefined, {withRef: true})(connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(EvolutionVizComponent));

