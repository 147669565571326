import { equalsInAnyOrder, toArray } from '../../../../utils/collectionUtils';
import { TreeModel } from "./TreeModel";
import { TreeSelectionCtrl } from "./TreeSelectionCtrl";

export const SelectionState = {
  SELECTED: Symbol('selected'),
  UNSELECTED: Symbol('unselected'),
  INDETERMINATE: Symbol('indeterminate'),
};

export type SelectionStateType = typeof SelectionState[keyof typeof SelectionState]

export class MultiTreeSelectionCtrl<T> extends TreeSelectionCtrl<T> {
  _selection: Array<T>;
  _allowEmptySelection: boolean;


  constructor(treeModel: TreeModel<T>, selection: Array<T>, onSelectionChanged: (value) => void, allowEmptySelection: boolean) {
    super(treeModel, onSelectionChanged);
    this._selection = selection || [];
    this._allowEmptySelection = allowEmptySelection;
  }

  isSelected = (item: T): boolean => {
    return this._treeModel.nodesContain(this.getSelection(), item);
  };

  getSelectionState = (item: T): SelectionStateType => {
    if (!this._treeModel.hasChildren(item)) {
      return this.isSelected(item) ? SelectionState.SELECTED : SelectionState.UNSELECTED;
    }

    const children = this._treeModel.getChildren(item);
    let foundSelected = false;
    let foundUnselected = false;

    for (let child of toArray(children)) {
      const childSelectionState = this.getSelectionState(child);

      if (childSelectionState === SelectionState.INDETERMINATE) {
        return SelectionState.INDETERMINATE;
      }

      if (childSelectionState === SelectionState.SELECTED) {
        foundSelected = true;
      } else {
        foundUnselected = true;
      }

      if (foundSelected && foundUnselected) {
        return SelectionState.INDETERMINATE;
      }
    }

    return foundSelected ? SelectionState.SELECTED : SelectionState.UNSELECTED;
  };

  toggleSelect = (item: T): void => {
    if (this.isSelected(item)) {
      this.unselect(item);
    } else {
      this.select(item);
    }
  };

  unselect = (item: T): void => {
    const itemsToUnselect = this._treeModel.flatten(item);
    this.setSelection(this.getSelection().filter(s => !this._treeModel.nodesContain(itemsToUnselect, s)));
  };

  select = (item: T): void => {
    const newSelection = [...this.getSelection()];
    this._treeModel.forEachLeaves(item, l => {
      if (!this._treeModel.nodesContain(newSelection, l)) {
        newSelection.push(l)
      }
    });
    this.setSelection(newSelection);
  };

  selectAll = (): void => {
    const newSelection = [];
    this._treeModel.forAllLeaves(l => newSelection.push(l));
    this.setSelection(newSelection);
  };

  unselectAll = (): void => {
    this.setSelection([]);
  };

  invert = (): void => {
    const newSelection = [];
    const currentSelection = this.getSelection();
    this._treeModel.forAllLeaves(l => {
      if (currentSelection.indexOf(l) < 0) {
        newSelection.push(l)
      }
    });
    if (!this._allowEmptySelection && newSelection.length === 0) {
      //select first item
      newSelection.push( this._treeModel.flattenTree().filter(node => this._treeModel.isLeave(node))[0]);
    }
    this.setSelection(newSelection);
  };

  setSelection = (selection) => {
    const hasChanged = !equalsInAnyOrder(selection, this._selection, this._treeModel._nodeEqual);

    this._selection = selection;

    if (hasChanged) {
      this.notify(selection);
    }
  };

  getSelection = () => {
    return this._selection;
  };

  findNode = (node) => {
    return this._treeModel.findNode(node);
  };
}
