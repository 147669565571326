import {Button, FormGroup, Intent, NumericInput, Tooltip} from '@blueprintjs/core';
import classNames from 'classnames';
import * as React from 'react';
import { BlankSeparator } from './BlankSeparator';
import _ from "lodash";
import { safeMax, safeMin } from "../utils/mathUtils";
import { WithTranslation, withTranslation } from "react-i18next";

interface OptionalNumberEditorProps extends WithTranslation {
  label: string,
  value?: number,
  min?: number,
  max?: number,
  precision?: number,
  onChanged: (value?: number) => void
}

class OptionalNumberEditor extends React.PureComponent<OptionalNumberEditorProps> {
  static defaultProps: Partial<OptionalNumberEditorProps> = {
    precision: 0
  }

  onClear = () => {
    this.onValueChanged(undefined);
  }

  isSelected() {
    return !_.isNil(this.props.value);
  }

  onValueChanged = (value) => {
    this.props.onChanged(value);
  };

  getPrecisionNumber(): number {
    const { precision } = this.props;

    if (precision <= 0) {
      return 1;
    }

    let result = "0.";
    for (let i = 0; i < precision - 1; i++) {
      result += "0";
    }
    return Number.parseFloat(result + "1");
  }

  isValid() {
    const { value, min, max } = this.props;

    if (_.isNil(value)) {
      return true;
    }

    return (_.isNil(min) || value >= min) && (_.isNil(max) || value <= max) ;
  }

  getInvalidRangeLabel() {
    const { t, min, max } = this.props;

    if (!_.isNil(min) && !_.isNil(max)) {
      return t('rangeEditor.error.minMax', {min: min, max: max})
    }
    if (!_.isNil(min)) {
      return t('rangeEditor.error.min', {min: min})
    }
    if (!_.isNil(max)) {
      return t('rangeEditor.error.max', {max: max})
    }
    return null;
  }

  render() {
    const { label, value } = this.props;

    const isValid = this.isValid();
    const precision = this.getPrecisionNumber();

    return <FormGroup className='vui-layout--horizontal__center' label={label} inline={true}>
      <Tooltip content={isValid ? null : this.getInvalidRangeLabel()} placement='top'>
        <NumericInput value={value ?? NumericInput.VALUE_EMPTY} onValueChange={this.onValueChanged} fill={true}
                      asyncControl={precision !== 1}
                      intent={isValid ? null : Intent.DANGER }
                      minorStepSize={precision}
                      rightElement={<Button icon='key-backspace' minimal={true} onClick={this.onClear}/>}/>
      </Tooltip>
    </FormGroup>;
  }
}

interface RangeLabels {
  from?: string,
  to?: string
}

export interface Range {
  from: number,
  to: number
}

interface RangeEditorProps extends WithTranslation {
  className?: string,
  range: Range,
  labels?: RangeLabels,
  min?: number,
  max?: number,
  precision?: number,
  onRangeChanged: (range: Range) => void
}

export class RangeEditorComponent extends React.PureComponent<RangeEditorProps> {
  onFromChanged = (value) => {
    this.props.onRangeChanged({
      from: value,
      to: this.props.range.to
    })
  };

  onToChanged = (value) => {
    this.props.onRangeChanged({
      from: this.props.range.from,
      to: value
    })
  };

  getLabels() {
    const { t } = this.props;

    return {
      from: this.props?.labels?.from || t('rangeEditor.min'),
      to: this.props?.labels?.to || t('rangeEditor.max')
    };
  }

  render() {
    const { t, range, min, max, precision, className } = this.props;

    const labels = this.getLabels();
    const fromMax = safeMin(max, range.to);
    const toMin = safeMax(min, range.from);

    const clazz = classNames('vui-layout--horizontal', className);

    return <div className={clazz}>
      <div className='vui-layout--horizontal__center'>
        <OptionalNumberEditor t={t} value={range.from}
                              min={min} max={fromMax} precision={precision}
                              label={labels.from} onChanged={this.onFromChanged}/>
      </div>
      <BlankSeparator size={10}/>
      <div className='vui-layout--horizontal__center'>
        <OptionalNumberEditor t={t} value={range.to}
                              min={toMin} max={max} precision={precision}
                              label={labels.to} onChanged={this.onToChanged}/>
      </div>
    </div>;
  }
}

export const RangeEditor = withTranslation()(RangeEditorComponent);