import { EnhancedMetric } from "./EnhancedMetric";
import { ParseError } from "../../../utils/errors/ParseError";
import { VolumeMetric } from "./VolumeMetric";
import _ from "lodash";
import { SatScoreMetric } from "./SatScoreMetric";
import { OpinionsMetric } from "./OpinionsMetric";

export const parseMetric = (object: any): EnhancedMetric => {
  const type = object['@type'];

  if (_.isEmpty(type)) {
    throw new Error('No @type defined when parsing Metric');
  }

  if (type === VolumeMetric.TYPE) {
    return VolumeMetric.parse(object)
  }
  if (type === SatScoreMetric.TYPE) {
    return SatScoreMetric.parse(object);
  }
  if (type === OpinionsMetric.TYPE) {
    return OpinionsMetric.parse(object);
  }

  throw new ParseError(`Cannot parse object with type ${type} into Condition`);
}