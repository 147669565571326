import { ApiFilter } from './ApiFilter';
import _ from "lodash";

export class MissingFilter extends ApiFilter {
  static parse(object) {
    return new MissingFilter(object.field);
  }

  field:string;

  constructor(field:string) {
    super('missing');
    this.field = field;
  }

  toPlainObject() {
    if (_.isEmpty(this.field)) {
      throw new Error('field is mandatory')
    }

    const result:any = super.toPlainObject();
    result.field = this.field;
    return result;
  }

  getCopy():ApiFilter {
    const apiFilter = new MissingFilter(this.field);
    return apiFilter;
  }
}