import {isMock} from '../Mock';

import {services} from './services';
import {AbstractApplicationService} from "./AbstractApplicationService";


export class StandaloneApplicationService extends AbstractApplicationService {
    async initServices() {
        await services.getFetcherService().init(isMock(), services.getUrlService().shouldSimulateWait(),
            this._messageHandler);
        await services.getI18nService().init();
        await services.getTimeZoneService().init();
        await services.getSecurityService().init();
        await services.getConfigurationService().init(false);
        await services.getDashboardService().init();
        await services.getFilterService().init();
        await services.getFeedbackService().init();
        await services.getTopicsStatService().init();
        await services.getSatService().init();
        //await services.getAnalysisService().init();
        await services.getDashboardExportService().init();
        await services.getAdministrationService().init();
    }
}

services.registerService('standaloneApplicationService', new StandaloneApplicationService());