import { getTitle } from '../common';
import { TetraClasseViz } from './tetraClasseViz';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { Trans } from 'react-i18next';
import React from 'react';

registerVizType({
  name: 'TETRA_CLASSE',
  component: TetraClasseViz,
  title: (vizInstance, t) => {
    const title = getTitle(vizInstance, t);
    if(title){
      return title;
    }
    const i18nKey = `dashboard.viz.tetraClasse.title`;
    return <Trans i18nKey={i18nKey} />
  }

});