import { Button } from '@blueprintjs/core';
import * as PropTypes from 'prop-types';
import * as React from 'react';

export class SwitchButton extends React.PureComponent {
  static propTypes = {
    isOn: PropTypes.bool.isRequired,
    onLabel: PropTypes.string.isRequired,
    offLabel: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    buttonProps: PropTypes.object
  };

  static defaultProps = {
    isOn: false,
    buttonProps: {}
  }

  render() {
    const {isOn, onLabel, offLabel, onChange, buttonProps} = this.props;

    return <Button {...buttonProps} onClick={onChange} >
      {
        isOn ? onLabel : offLabel
      }
    </Button>
  }
}