export class Highlight {
  /**
   * @param {Object} object
   * @return {Highlight}
   */
  static parse(object) {
    return new Highlight(object.sentences, object.categoryTrees);
  }

  /**
   * @type {Highlight}
   */
  static HIGHLIGHT_ALL = new Highlight(true, null);

  /**
   * @type {boolean}
   */
  sentences;

  /**
   * @type {Array<string>}
   */
  categoryTrees;

  /**
   * @param {boolean} sentences
   * @param {Array<string>} categoryTrees
   */
  constructor(sentences, categoryTrees) {
    this.sentences = sentences;
    this.categoryTrees = categoryTrees;
  }

  toPlainObject() {
    return {
      sentences: this.sentences,
      categoryTrees: this.categoryTrees
    }
  }
}