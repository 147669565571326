import _ from 'lodash';
import React from 'react';
import { ColoredProgressBar } from './progressBar/ColoredProgressBar';

export const ProgressItem = ({ label, value, count = undefined, color }) => {
  return <div style={{ marginTop: 8 }}>
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, paddingRight: 10 }}>{label}</div>
      <div style={{ fontWeight: 600 }}>{_.round(value, 2)} %{count ? ` (${count})` : null}</div>
    </div>
    <div style={{ marginTop: 3 }}>
      <ColoredProgressBar value={value / 100} color={color}/>
    </div>
  </div>;
};

