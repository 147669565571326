import { PopoverPosition, Tab, Tabs } from '@blueprintjs/core';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FieldName } from '../../../../application/model/field/Field';
import { services } from '../../../../application/service/services';
import { BlankSeparator } from '../../../../component/BlankSeparator';
import { EditorItem } from '../../../../component/editor/EditorItem';
import { FieldValueSelector } from '../../../../component/selector/FieldValueSelector';
import { SeparatedComponents } from '../../../../component/SeparatedComponents';
import { InfiniteCache } from '../../../../utils/InfiniteCache';
import { SortOrderEnum } from '../../../../utils/query/SortOrder';
import { MetricEnum } from '../../../model/MetricEnum';
import { actionNamespacer } from '../../../state/utils/actionNamespacer';
import { MetricRangesEditor } from '../../components/editor/MetricRangesEditor';
import { MetricSortEditor } from '../../components/editor/MetricSortEditor';
import { FeedbackSelector } from '../../components/FeedbackSelector';
import './topicSatScoreEditor.scss';
import { dispatchSlice, stateSlice } from './topicSatScoreEditorState';
import { VisualizationInstance } from "../../../model/viz/VisualizationInstance";
import _ from "lodash";

interface TabContentProps {
  children?: React.ReactNode
}

class TabContent extends React.PureComponent<TabContentProps> {
  render() {
    return <div className='tab-content'>{this.props.children}</div>;
  }
}

interface TopicSatScoreEditorData {
  params: { ranges: any, exclusions: any, inclusions: any, sort: any }
}

interface TopicSatScoreEditorProps extends WithTranslation {
  viz: VisualizationInstance,
  init: () => void,
  setPage: (page: number) => void,
  setCurrentTopicIndex: (index: number) => void,
  onValueChanged: (prop: string, key: string, value: any) => void,
  editorData: TopicSatScoreEditorData
  currentTopicIndex,
  feedbacks,
  loadingFeedbacks,
  page,
  pageSize,
  total: number
}

class TopicSatScoreEditorComponent extends React.PureComponent<TopicSatScoreEditorProps> {
  feedbackSelectionHandler = new InfiniteCache(
    (topic) => (value) => this.onFeedbackSelect(topic, value)
  );

  componentDidMount() {
    this.props.init();
  }

  onFeedbackSelect = (topic, value) => {
    this.props.onValueChanged('data', `feedbacks.${topic}`, value);
  };

  onPageChange = (page) => {
    this.props.setPage(page);
  };

  onTabChange = tabId => {
    this.props.setCurrentTopicIndex(parseInt(tabId));
  };

  onMetricRangesChanged = (metricRanges) => {
    this.props.onValueChanged('params', 'ranges', metricRanges ?
      metricRanges.map(r => ({ metric: r.metric.name, range: r.range }))
      : null);
  };

  onExclusionsChanged = (exclusions) => {
    this.props.onValueChanged('params', 'exclusions', exclusions ?
      exclusions.map(e => e.fullName)
      : null);
  };

  onInclusionsChanged = (inclusions) => {
    this.props.onValueChanged('params', 'inclusions', inclusions ?
      inclusions.map(e => e.fullName)
      : null);
  };

  onMetricSortChanged = (metricSort) => {
    this.props.onValueChanged('params', 'sort', metricSort ?
      metricSort.map(r => ({ metric: r.key.name, order: r.order.name }))
      : null);
  };

  renderFeedbacksChooser() {
    const { t, viz, editorData, currentTopicIndex, feedbacks, loadingFeedbacks, page, pageSize, total } = this.props;
    const currentTabId = `${currentTopicIndex}`;

    return <Tabs className='flex-tabs' large={false} selectedTabId={currentTabId}
                 onChange={this.onTabChange}>
      {
        viz.data
          .map(d => ([d.topic, _.get(editorData, ['data', 'feedbacks', d.topic])]))
          .filter(([, feedback]) => !_.isNil(feedback))
          .map(([topic, feedback], i) => {
            const tabId = `${i}`;
            const pagination = {
              current: page,
              total: total,
              pageSize: pageSize,
              onChange: this.onPageChange
            };

            const tabBody =
              <div className='inner-tab-content'>
                <FeedbackSelector
                  items={feedbacks}
                  pagination={pagination}
                  fetchingData={loadingFeedbacks}
                  selectedFeedbackId={feedback.id}
                  onSelectFeedback={this.feedbackSelectionHandler.get(topic)}
                /></div>;

            return <Tab id={tabId}
                        key={topic}
                        title={services.getFieldsService().findCategoryLabel('topic', topic, t)}
                        panel={tabBody}
            />;
          })
      }
    </Tabs>;
  }

  renderParamEditor() {
    const { t, editorData } = this.props;

    const ranges = (editorData.params.ranges || [])
      .map(r => ({ metric: MetricEnum.valueOf(r.metric), range: r.range }));

    const fieldValues = services.getFieldsService().getFieldValues(FieldName.categoryTree('topic'));
    const exclusions = (editorData.params.exclusions || [])
      .map(e => fieldValues.find(e));
    const inclusions = (editorData.params.inclusions || [])
      .map(e => fieldValues.find(e));

    const sort = (editorData.params.sort || [])
      .map(o => ({ key: MetricEnum.valueOf(o.metric), order: SortOrderEnum.valueOf(o.order) }));

    return <SeparatedComponents separator={<BlankSeparator vertical={true}/>}>
      <EditorItem title={t('dashboard.viz.topicSatScore.editor.metricRanges')}>
        <MetricRangesEditor metrics={MetricEnum.values} items={ranges} onChanged={this.onMetricRangesChanged}
                            annihilateEnterKey={true}/>
      </EditorItem>
      <EditorItem title={t('dashboard.viz.topicSatScore.editor.exclusions')}>
        <FieldValueSelector onSelectionChanged={this.onExclusionsChanged}
                            allowEmptySelection={true}
                            allowMultiSelection={true}
                            canSelectLeavesOnly={true}
                            fieldValues={fieldValues}
                            selectedValue={exclusions}
                            selectInputProps={{
                              selectProps: {
                                fill: true,
                                popoverProps: {
                                  position: PopoverPosition.BOTTOM_LEFT
                                },
                                tagInputProps: {
                                  className: 'exclusions-select-input',
                                }
                              }
                            }}
        />
      </EditorItem>
      <EditorItem title={t('dashboard.viz.topicSatScore.editor.inclusions')}>
        <FieldValueSelector onSelectionChanged={this.onInclusionsChanged}
                            allowEmptySelection={true}
                            allowMultiSelection={true}
                            canSelectLeavesOnly={true}
                            fieldValues={fieldValues}
                            selectedValue={inclusions}
                            selectInputProps={{
                              fill: true,
                              popoverProps: {
                                position: PopoverPosition.BOTTOM_LEFT
                              },
                              tagInputProps: {
                                className: 'exclusions-select-input',
                              }
                            }}
        />
      </EditorItem>
      <EditorItem title={t('dashboard.viz.topicSatScore.editor.sort')}>
        <MetricSortEditor metrics={MetricEnum.values} items={sort} onChanged={this.onMetricSortChanged}
                          annihilateEnterKey={true}/>
      </EditorItem>
    </SeparatedComponents>;
  }

  render() {
    const { t, viz } = this.props;

    return <Tabs className='topicSatScoreEditor flex-tabs horizontal' vertical={true}>
      {
        viz.definition.params.noSamples || !viz.hasData() ? null
          : <Tab id='feedbacks' title={t('dashboard.viz.topicSatScore.editor.verbatims')}
                 panel={<TabContent>{this.renderFeedbacksChooser()}</TabContent>}/>
      }
      <Tab id='params' title={t('dashboard.viz.topicSatScore.editor.params')}
           panel={<TabContent>{this.renderParamEditor()}</TabContent>}/>
    </Tabs>;
  }
}

const mapStateToProps = state => {
  return stateSlice(state);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const wrapActionPayload = actionNamespacer(ownProps.viz.id);
  dispatch = dispatchSlice(dispatch);

  return {
    init: () => {
      dispatch.init(wrapActionPayload());
    },
    setPage: page => {
      dispatch.changePage(wrapActionPayload(page));
    },
    setCurrentTopicIndex: index => {
      dispatch.changeCurrentTopicIndex(wrapActionPayload(index));
    }
  };
};


export const TopicSatScoreEditor = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TopicSatScoreEditorComponent));
