import { Enum, EnumValue } from "../../../utils/enum";

export class BoolOperator extends EnumValue {
    constructor() {
        super();
    }
}

class BoolOperatorEnumType extends Enum<BoolOperator> {
    MUST = new BoolOperator();
    SHOULD = new BoolOperator();

    constructor() {
        super();
        this.initEnum('BoolOperator');
    }
}

export const BoolOperatorEnum = new BoolOperatorEnumType();