import { toArray } from '../collectionUtils';
import { parseAspect } from './aspect/parseAspect';
import { parseFilter } from './filter/parseFilter';
import { Highlight } from './Highlight';
import { Sort } from './Sort';
import _ from "lodash";

export class SearchQuery {
  static createFromPage(page, pageSize) {
    return new SearchQuery(pageSize * (page - 1), pageSize);
  }

  static parse(object) {
    const query = new SearchQuery(object.from, object.size);

    if (object.filter) {
      query.filter = parseFilter(object.filter);
    }
    if (object.aspects) {
      query.aspects = object.aspects.filter(aspect => !!aspect).map(parseAspect);
    }
    query.addField(object.fields);
    if (object.sort) {
      query.sort = object.sort.map(Sort.parse);
    }
    if (object.highlight) {
      query.highlight = Highlight.parse(object.highlight);
    }

    return query;
  }

  /**
   * @type {ApiFilter}
   * @private
   */
  _filter;

  /**
   * @type {number}
   * @private
   */
  _from;

  /**
   * @type {number}
   * @private
   */
  _size;

  /**
   * @type {Set<string>}
   * @private
   */
  _fields = new Set();

  /**
   * @type {Array<Sort>}
   */
  _sort;

  /**
   * @type {Array<SearchAspect>}
   */
  _aspects;

  /**
   * @type {Highlight}
   */
  _highlight;

  /**
   * @param {number} from
   * @param {number} size
   */
  constructor(from = 0, size = 10) {
    this._from = from;
    this._size = size;
  }

  get filter() {
    return this._filter;
  }

  set filter(value) {
    this._filter = value;
  }

  get from() {
    return this._from;
  }

  set from(value) {
    this._from = value;
  }

  get size() {
    return this._size;
  }

  set size(value) {
    this._size = value;
  }

  /**
   * @return {Array<SearchAspect>}
   */
  get aspects() {
    return this._aspects;
  }

  set aspects(value) {
    this._aspects = value;
  }

  /**
   * @return {Highlight}
   */
  get highlight() {
    return this._highlight;
  }

  set highlight(value) {
    this._highlight = value;
  }

  /**
   * @param {SearchAspect|Array<SearchAspect>} aspect
   */
  addAspect(aspect) {
    const array = toArray(aspect);
    if (_.isNil(this._aspects)) {
      this._aspects = [];
    }
    array.forEach(a => this._aspects.push(a));
  }

  fields(fields = undefined) {
    if (_.isUndefined(fields)) {
      return this._fields;
    }
    const array = toArray(fields);
    this._fields.clear();
    array.forEach(f => this._fields.add(f));
  }

  /**
   * @param {string|Array<string>} field
   */
  addField(field) {
    const array = toArray(field);
    array.forEach(f => this._fields.add(f));
  }

  /**
   * @param {Sort|Array<Sort>|undefined} sort
   * @return {Array<Sort>|undefined}
   */
  sort(sort = undefined) {
    if (_.isUndefined(sort)) {
      return this._sort;
    }
    const array = toArray(sort);
    this._sort = [];
    array.forEach(s => this._sort.push(s));
  }

  /**
   * @param {Sort|Array<Sort>} sort
   */
  addSort(sort) {
    const array = toArray(sort);
    if (_.isNil(this._sort)) {
      this._sort = [];
    }
    this._sort = this._sort.concat(array);
  }

  toPlainObject() {
    const result:any = {
      from: this._from,
      size: this._size
    };

    if (!_.isNil(this._filter)) {
      result.filter = this._filter.toPlainObject();
    }

    if (this._fields.size > 0) {
      result.fields = Array.from(this._fields);
    }

    if (!_.isEmpty(this._sort)) {
      result.sort = this._sort.map(s => s.toPlainObject());
    }

    if (!_.isEmpty(this._aspects)) {
      result.aspects = this._aspects.map(a => a.toPlainObject());
    }

    if (!_.isNil(this._highlight)) {
      result.highlight = this._highlight.toPlainObject();
    }

    return result;
  }
}