import * as d3 from 'd3';
import _ from 'lodash';
import { styleReactToCss } from './css_style_converter';

export default (text, options) => {
  if (!text || text.length === 0) {
    return { height: 0, width: 0 };
  }

  const container = d3.select('body').append('svg');
  if (options && options.className) {
    container.attr('class', options.className);
  }
  if (options && options.style) {
    if (_.isString(options.style)) {
      container.attr('style', options.style);
    } else if (_.isPlainObject(options.style)) {
      container.attr('style', styleReactToCss(options.style));
    }
  }

  container.append('text').attr('x', -1000).attr('y', -1000).text(text);

  const bbox = container.node().getBBox();
  container.remove();

  return { height: bbox.height, width: bbox.width };
};
