import { InstanceOf } from './matcher/InstanceOf';
import { TypeOf } from './matcher/TypeOf';
import _ from 'lodash';
import { Predicate } from "./matcher/Predicate";

const defaultComparator = <T>(): EqualComparator<T> => {
  return (a, b) => a === b;
}

export const equalsInAnyOrder = <T>(arr1: Array<T>,
                                    arr2: Array<T>,
                                    equalityTester: EqualComparator<T> = defaultComparator<T>()): boolean => {
  if (!arr1 && !arr2) {
    return true;
  }
  if (arr1 && !arr2 || !arr1 && arr2) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (_.isNil(arr2.find(it => equalityTester(it, arr1[i])))) {
      return false;
    }
  }
  return true;
};

export const equalsInSameOrder = <T>(arr1: Array<T>,
                                     arr2: Array<T>,
                                     equalityTester: EqualComparator<T> = defaultComparator<T>()): boolean => {
  if (!arr1 && !arr2) {
    return true;
  }
  if (arr1 && !arr2 || !arr1 && arr2) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (!equalityTester(arr1[i], arr2[i])) {
      return false;
    }
  }
  return true;
};

export const checkArrayItemsInstanceOf = <T>(array: Array<T>, expectedClass: Class<T>, removeNils = true):Array<T> => {
  return checkArrayItems(
      array,
      new InstanceOf(expectedClass),
      removeNils);
};

export const checkArrayItemsTypeOf = <T>(array: Array<T>, expectedType: string, removeNils: boolean = true):Array<T> => {
  return checkArrayItems(
      array,
      new TypeOf(expectedType),
      removeNils);
};

export const checkArrayItems = <T>(array: Array<T>, predicate: Predicate<T>, removeNils: boolean = true): Array<T> => {
  if (_.isNil(array)) {
    return [];
  }

  const result = [];

  array.forEach((item, index) => {
    if (!_.isNil(item)) {
      if (!predicate.match(item)) {
        throw new TypeError(`Item ${item} found at index ${index} is not valid. Expected item to be ${predicate.message()}`)
      }
      result.push(item);
    } else if (!removeNils) {
      result.push(item);
    }
  });

  return result;
};

export const arrayContains = <T>(array: Array<T>, predicate: (item: T) => boolean): boolean => {
  if (_.isEmpty(array)) {
    return false;
  }
  return !_.isNil(array.find(predicate));
};

export const arrayRetainAll = <T>(array: Array<T>,
                                  toRetain: Array<T>,
                                  equalityTester: EqualComparator<T> = defaultComparator<T>()):Array<T> => {
  return array.filter(it => arrayContains(toRetain, (toRetainItem) => equalityTester(it, toRetainItem)));
}

export const arrayRemoveAll = <T>(array: Array<T>,
                                  toRemove: Array<T>,
                                  equalityTester: EqualComparator<T> = defaultComparator<T>()):Array<T> => {
  return array.filter(it => !arrayContains(toRemove, (toRetainItem) => equalityTester(it, toRetainItem)));
}

export const toArray = <T>(element: OneOrMany<T>): Array<T> => {
  if (_.isNil(element)) {
    return [];
  }

  return _.isArray(element) ? element as Array<T> : [element as T];
};

/**
 * moves the item at the given index on the left. It doesn't mutate the given array.
 *
 * @param array
 * @param index
 */
export const moveLeft = <T>(array: Array<T>, index: number): Array<T> => {
  if (index <= 0) {
    throw Error('Cannot move first item on the left');
  }
  if (index >= array.length) {
    throw Error(`index ${index} is out of bounds`);
  }

  const valueAtIndex = array[index];
  const valueAtPreviousIndex = array[index - 1];
  const result = [...array];
  result[index] = valueAtPreviousIndex;
  result[index - 1] = valueAtIndex;
  return result;
}

/**
 * moves the item at the given index on the right. It doesn't mutate the given array.
 *
 * @param array
 * @param index
 */
export const moveRight = <T>(array: Array<T>, index: number) => {
  if (index < 0) {
    throw Error(`index ${index} is out of bounds`);
  }
  if (index >= array.length - 1) {
    throw Error('Cannot move last index on the right');
  }

  const valueAtIndex = array[index];
  const valueAtNextIndex = array[index + 1];
  const result = [...array];
  result[index] = valueAtNextIndex;
  result[index + 1] = valueAtIndex;
  return result;
}

/**
 * Remove the item at the given index. It doesn't mutate the given array.
 *
 * @param array
 * @param index
 */
export const deleteAt = <T>(array: Array<T>, index: number): Array<T> => {
  if (index < 0 || index >= array.length) {
    throw Error(`index ${index} is out of bounds`);
  }

  return [...array.slice(0, index), ...array.slice(index + 1)];
}

/**
 * returns the last item of the given array, or undefined if the given array is empty
 * @param array
 */
export const lastItem = <T>(array: Array<T>): Maybe<T> => {
  if (array.length === 0) {
    return undefined;
  }
  return array[array.length - 1];
}

export const firstItem = <T>(arg: OneOrMany<T>): Maybe<T> => {
  if (Array.isArray(arg)) {
    if (arg.length === 0) {
      return undefined;
    }
    return arg[0];
  }
  return arg;
}

export const index = <T>(array: Array<T>, keyGetter: (item: T) => string): Dict<T> => {
  return asDict(array, keyGetter, it => it);
}

export const asDict = <T, V>(array: Array<T>, keyGetter: (item: T) => string, valueGetter: (item: T) => V): Dict<V> => {
  return Object.fromEntries(array.map(it => {
    const key = keyGetter(it);
    if (_.isNil(key)) {
      throw new Error('key cannot be null or undefined');
    }
    if (!_.isString(key)) {
      throw new Error('key must be a string');
    }
    return [key, valueGetter(it)];
  }));
}

export const doFor = <T, R>(obj: OneOrMany<T>, fn: (o: T) => R, filterFn?: (o: R) => boolean): OneOrMany<R> => {
  let arr = toArray(obj).map(fn);
  if (filterFn) {
    arr = arr.filter(filterFn);
  }

  if (Array.isArray(obj)) {
    return arr;
  } else {
    return firstItem(arr);
  }
}