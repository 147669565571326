import { services } from '../../application/service/services';
import { PromisePool } from "../../utils/PromisePool";

const doGitValidation = async (dispatch, rootState, tenant, bitbucketToken) => {
  dispatch.gitConfigurationManager.setValidation(
    {
      tenant: tenant,
      state: 'running',
    }
  );

  try {
    const validation = await services.getConfigurationManagerService()
      .gitValidate(rootState.gitConfigurationManager.branch, tenant.id, bitbucketToken);

    dispatch.gitConfigurationManager.setValidation(
      {
        tenant: tenant,
        state: 'done',
        validation: validation
      }
    );

  } catch (e) {
    dispatch.gitConfigurationManager.setValidation(
      {
        tenant: tenant,
        state: 'error',
        error: e,
      }
    );
  }
};

const doGitPush = async (dispatch, rootState, tenant, bitbucketToken) => {
  dispatch.gitConfigurationManager.setPush(
    {
      tenant: tenant,
      state: 'running',
    }
  );

  try {
    const validation = await services.getConfigurationManagerService()
      .gitPush(rootState.gitConfigurationManager.branch, tenant.id, bitbucketToken);

    dispatch.gitConfigurationManager.setPush(
      {
        tenant: tenant,
        state: 'done',
        validation : validation
      }
    );

  } catch (e) {
    dispatch.gitConfigurationManager.setPush(
      {
        tenant: tenant,
        state: 'error',
        error: e,
      }
    );
  }
}

export const gitConfigurationManagerState = () => ({
  state: {
    branch: 'dev',
    validations: [],
    pushes: [],
  },
  reducers: {
    setBranch(state, payload) {
      state.branch = payload;
      return state;
    },
    clear(state, payload) {
      state.validations = [];
      state.pushes = [];
      return state;
    },
    setValidation(state, payload) {
      state.validations = [
        ...state.validations.filter(v => v.tenant.id !== payload.tenant.id), payload
      ].sort((v1, v2) => v1.tenant.name.localeCompare(v2.tenant.name));
      return state;
    },
    setPush(state, payload) {
      state.pushes = [
        ...state.pushes.filter(v => v.tenant.id !== payload.tenant.id), payload
      ].sort((v1, v2) => v1.tenant.name.localeCompare(v2.tenant.name));
      return state;
    }
  },
  effects: (dispatch) => ({
    async bitbucketInit(payload, rootState) {
      await services.getConfigurationManagerService().bitbucketInit();
    },
    async gitPush(payload, rootState) {
      dispatch.gitConfigurationManager.clear();

      const bitbucketToken = await services.getConfigurationManagerService().getBitbucketToken();

      if (rootState.tenant.selectedTenantId === '*') {
        const pool = new PromisePool(3 , {
          timeout: rootState.tenant.tenants.length * 30000
        });
        rootState.tenant.tenants.forEach(tenant => {
          dispatch.gitConfigurationManager.setPush(
            {
              tenant: tenant,
              state: 'pending',
            }
          );

          pool.queue(async () => {
            await doGitPush(dispatch, rootState, tenant, bitbucketToken);
          });
        });
        await pool.settle();
      } else {
        await doGitPush(dispatch, rootState, rootState.tenant.selectedTenant, bitbucketToken);
      }
    },
    async gitValidate(payload, rootState) {
      dispatch.gitConfigurationManager.clear();

      const bitbucketToken = await services.getConfigurationManagerService().getBitbucketToken();

      if (rootState.tenant.selectedTenantId === '*') {
        const pool = new PromisePool(3 , {
          timeout: rootState.tenant.tenants.length * 30000
        });
        rootState.tenant.tenants.forEach(tenant => {
          dispatch.gitConfigurationManager.setValidation(
            {
              tenant: tenant,
              state: 'pending',
            }
          );

          pool.queue(async () => {
            await doGitValidation(dispatch, rootState, tenant, bitbucketToken);
          });
        });
        await pool.settle();
      } else {
        await doGitValidation(dispatch, rootState, rootState.tenant.selectedTenant, bitbucketToken);
      }
    }
  })
});