import { arrayContains } from "../../../../utils/collectionUtils";
import { ResolvedParamValue } from "./ResolvedParamValue";
import { ParamValue } from "../values/ParamValue";
import { Param } from "../Param";
import { parseParamValue } from "../parse/parseParamValue";

export class ResolvedParams {
  private readonly resolvedParamValues: Array<ResolvedParamValue>;
  private readonly paramValuesByParamName: Dict<OneOrMany<ParamValue>>;

  constructor(resolvedParamValues: Array<ResolvedParamValue>) {
    this.resolvedParamValues = resolvedParamValues || [];
    this.paramValuesByParamName = Object.fromEntries(this.resolvedParamValues.map(rpv => [rpv.param.name, rpv.value]));
  }

  isValid() {
    return !arrayContains(this.resolvedParamValues, resolvedParamValue => !resolvedParamValue.isValid());
  }

  valueAsPlainObject(): Dict<any> {
    return Object.fromEntries(this.resolvedParamValues
      .map(resolvedParamsValue => [resolvedParamsValue.param.name, resolvedParamsValue.varValueAsPlainObject()])
    );
  }

  asPlainObject(): Dict<any> {
    return Object.fromEntries(this.resolvedParamValues
      .map(resolvedParamsValue => [resolvedParamsValue.param.name, resolvedParamsValue.valueAsPlainObject()])
    );
  }

  getParamValuesByParamName(): Dict<OneOrMany<ParamValue>> {
    return this.paramValuesByParamName;
  }

  findParamValues(paramName: string): Maybe<OneOrMany<ParamValue>> {
    return this.paramValuesByParamName[paramName];
  }

  public static parse(plainObject: any, params:Dict<Param>):ResolvedParams {
    return new ResolvedParams(Object.entries(plainObject)
      .filter(([paramName, ]) => params.hasOwnProperty(paramName))
      .map(([paramName, paramValue]) => {
        return new ResolvedParamValue<ParamValue>(params[paramName], parseParamValue(paramValue));
      }));
  }
}