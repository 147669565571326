import axios from 'axios';
import { saveHttpResponseAsFile } from '../../../utils/http/saveHttpResponseAsFile';

const list = async (storageKind, path) => {
  return axios
    .post(`/api/sourcing/storage/${storageKind}/_list`, { path }, {
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
};

/**
 *
 * @param storageKind
 * @param {string} path
 * @return {Promise<void>}
 */
const downloadStoragePath = async (storageKind, path) => {
  return axios
    .request({
      method: 'GET',
      url: `/api/sourcing/storage/${storageKind}/${path}`,
      responseType: 'arraybuffer',
      responseEncoding: 'binary'
    })
    .then(function (response) {
      if (response.status === 200) {
        saveHttpResponseAsFile(response, 'storage');
      }
    });
};


const downloadStoragePaths = async (storageKind, paths, fileName) => {
  return axios
    .request({
      method: 'POST',
      url: `/api/sourcing/storage/${storageKind}/_download`,
      data: paths,
      responseType: 'arraybuffer',
      responseEncoding: 'binary'
    })
    .then(function (response) {
      if (response.status === 200) {
        saveHttpResponseAsFile(response, fileName);
      }
    });
};

const uploadStorageFile = async (storageKind, path, file, options:any = {}, onProgress: Function = () => {
}) => {
  let contentType = file.type;
  options = options || {};
  if (options.charset) {
    contentType += `;charset=${options.charset}`;
  }

  const config = {
    onUploadProgress: function (progressEvent) {
      onProgress(progressEvent.loaded, progressEvent.total);
    },
    headers: { 'Content-type': contentType }
  };

  let uri = `/api/sourcing/storage/${storageKind}/${path}`;
  let query = [];
  if (options.delimiterChar) {
    query.push(`delimiterChar=${options.delimiterChar}`);
  }
  if (options.quoteChar) {
    query.push(`quoteChar=${options.quoteChar}`);
  }
  if (query.length > 0) {
    uri += `?${query.join('&')}`;
  }

  return axios.put(encodeURI(uri), file, config);
};

const deleteStoragePaths = async (storageKind, paths) => {
  if (paths.length === 1) {
    return axios.delete(`/api/sourcing/storage/${storageKind}/${paths[0]}`);
  }
  return axios
    .post(`/api/sourcing/storage/${storageKind}/_delete`, paths, {
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
};

const sourceStoragePaths = async (storageKind, paths) => {
  return axios
    .post(`/api/sourcing/storage/${storageKind}/_source`, { paths }, {
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
};

export default { list, downloadStoragePath, downloadStoragePaths, uploadStorageFile, deleteStoragePaths, sourceStoragePaths };