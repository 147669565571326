import { FieldDisplay } from '../../model/field/FieldDisplay';
import { services } from '../../service/services';
import { feedbacksListState } from './tools/feedbacksListState';

const fls = feedbacksListState('feedbacks',  FieldDisplay.FieldDisplayKind.FEEDBACKS_LIST_VIEW);

export const feedbacksState = () => ({
  state: {
    ...fls.state
  },
  reducers: {
    ...fls.reducers,
  },
  effects: (dispatch) => ({
    async refresh(payload, rootState) {
      await dispatch.feedbacks.fetchFeedbacks();
    },
    async exportHighlights(payload, rootState) {
      await services.getFeedbackService().exportHighlights(payload);
    },
    ...fls.effects(dispatch)
  })
});