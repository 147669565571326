import React from 'react';
import PropTypes from 'prop-types';
import { trendSpec } from './trendSpec';

export function TrendPath({ value, ...rest }) {
  const trend = trendSpec(value);

  return <path
    {...rest}
    d={trend.iconPath}
    style={{ fill: trend.color }}/>;
}

TrendPath.propTypes = {
  value: PropTypes.number.isRequired
};
