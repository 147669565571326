import { services } from '../../application/service/services';
import { getDashboard } from './selectors/dashboardSelector';
import {Dashboard} from "../model/dashboard/Dashboard";

export const dashboardExportState = () => ({
  state: {},
  reducers: {
    setDashboard(state, dashboard){
      state.dashboard = dashboard;
      return state;
    }
  },
  effects: (dispatch) => ({
    /*Call to prepare data for export*/
    async initiateExport(payload, rootState) {
      await services.getDashboardExportService().initiateExport(rootState);
    },
    /* Build Dashboard objet with all data present in state
    * dashboard is then use by dashboardExport view */
    buildDashboard(payload, rootState) {
      dispatch.dashboardExport.setDashboard(getDashboard(rootState));
    }
  })
});