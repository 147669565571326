import React from "react";
import "./gridFormGroup.scss";
import { flattenReactChildren } from "../../utils/reactUtils";
import classnames from "classnames";

interface GridFormGroupProps {
  columnsCount?: number,
  fill?: boolean,
  className?: string,
  children?: React.ReactNode,
}

export class GridFormGroup extends React.PureComponent<GridFormGroupProps> {
  static defaultProps:Partial<GridFormGroupProps> = {
    columnsCount: 2,
    fill: false
  };

  render() {
    const { columnsCount, fill } = this.props;

    const children = flattenReactChildren(this.props.children);

    if ( children.length % columnsCount !== 0) {
      throw new Error(`Number of children should be a multiple of ${columnsCount}`);
    }

    const className = classnames("vui-gridFormGroup", this.props.className)

    return <div className={className} style={{gridTemplateColumns: `max-content repeat(${columnsCount - 1}, ${fill ? 'auto' : 'max-content'})`}}>
      {this.props.children}
    </div>;
  }
}