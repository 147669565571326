import {Button, ButtonGroup, Colors} from '@blueprintjs/core';
import React from 'react';
import * as PropTypes from 'prop-types';
import {WithTranslation, withTranslation} from 'react-i18next';

interface PaginationProps extends WithTranslation {
    onChange: any,
    total: any,
    pageSize: any,
    maxSize: any,
    style: any,
    showPage: any,
    current: any
}

class PaginationComponent extends React.PureComponent<PaginationProps> {
    constructor(props) {
        super(props);

        this.state = {
            current: props.current
        };
    }

    state = {
        current: null
    };

    handleFirstClick = () => {
        this.setCurrentPage(1);
    };

    handleLastClick = () => {
        this.setCurrentPage(this.getPagesCount());
    };

    handlePreviousClick = () => {
        this.setCurrentPage(this.getCurrentPage() - 1);
    };

    handleNextClick = () => {
        this.setCurrentPage(this.getCurrentPage() + 1);
    };

    setCurrentPage(index) {
        if (this.props.onChange) {
            this.props.onChange(index);
        } else {
            this.setState({current: index});
        }
    }

    getCurrentPage() {
        if (this.props.onChange) {
            return this.props.current;
        } else {
            return this.state.current;
        }
    }

    getPagesCount() {
        const {total, pageSize, maxSize} = this.props;
        return Math.ceil(Math.min(total, maxSize - pageSize) / pageSize);
    }

    render() {
        const current = this.getCurrentPage();

        // count number of pages
        const pagesCount = this.getPagesCount();
        if (pagesCount <= 1) {
            return null;
        }
        if (current > pagesCount) {
            console.warn(`current page is ${current} but there is only ${pagesCount} pages`);
        }

        const style = {
            ...this.props.style,
            alignItems: 'center'
        };

        const spanStyle = {
            color: Colors.DARK_GRAY1,
            padding: '0 8px'
        };

        const currentPageLabel = this.props.showPage ? this.props.t('misc.search.pagination.currentPage', {
            current,
            pagesCount
        }) : " ";

        return <div className='vui-layout--horizontal' style={style}>
            <ButtonGroup style={{alignItems: 'center'}}>
                <Button onClick={this.handleFirstClick} icon='double-chevron-left' disabled={current === 1}/>
                <Button onClick={this.handlePreviousClick} icon='chevron-left' disabled={current === 1}/>
                <span style={spanStyle}>{currentPageLabel}</span>
                <Button onClick={this.handleNextClick} icon='chevron-right' disabled={current === pagesCount}/>
                <Button onClick={this.handleLastClick} icon='double-chevron-right' disabled={current === pagesCount}/>
            </ButtonGroup>
        </div>;
    }
}

export const Pagination = withTranslation()(PaginationComponent);

Pagination.propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
    pageSize: PropTypes.number,
    onChange: PropTypes.func,
    style: PropTypes.object,
    maxSize: PropTypes.number,
    showPage: PropTypes.bool
};

Pagination.defaultProps = {
    total: 0,
    current: 1,
    pageSize: 10,
    maxSize: 10000,
    showPage: true
};