import { Button, Intent, NonIdealState } from '@blueprintjs/core';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, WithRouterProps } from "./Nav";

interface Error404Props extends WithTranslation, WithRouterProps {
}


class Error404Component extends React.PureComponent<Error404Props> {

  backToHomePage = () => {
    this.props.router.navigate('/');
  }

  render() {
    const { t } = this.props;

    const description: string = t('error.404.description');
    return (
      <NonIdealState
        icon='heart-broken'
        title={t('error.404.title')}
        description={description}
        action={<Button minimal={true}
                        intent={Intent.PRIMARY}
                        text={t('error.404.backToHome')}
                        onClick={this.backToHomePage}/>}
      />
    );
  }
}

export const Error404 = withTranslation()(withRouter(Error404Component));
