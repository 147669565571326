import { Field } from './Field';

export class CategoryField extends Field {
  static NAME_REGEX = 'enhancement\.categories\.([a-zA-Z0-9]+)';

  readonly tree: string;

  constructor(name: string) {
    super(name);

    this.tree = name.match(CategoryField.NAME_REGEX)[1];
  }

  isHierarchical(): boolean {
    return true;
  }

  isCategoryTree(): boolean {
    return true;
  }

  isStandardMetadata(): boolean {
    return false;
  }
}
