import { Colors } from '@blueprintjs/core';
import { Tooltip } from '@blueprintjs/core';
import { Tonality } from '@eptica/vecko-js-commons';
import React from 'react';
import { services } from '../../application/service/services';
import _ from "lodash";
import { OffsetDTO } from "../../application/model";
import { TFunction } from "i18next";

export const feedbackHighlight = (offset: OffsetDTO, text: string, t: TFunction) => {
  const offsetTonality = offset.tonality ? Tonality.getValueOf(offset.tonality) : Tonality.NEUTRAL;
  const color = offsetTonality === Tonality.NEUTRAL ? Colors.DARK_GRAY1 : offsetTonality.color;

  const span = <span style={{ color: color, fontWeight: 600 }}>{text}</span>;

  if (offset.category) {
    const category = services.getFieldsService().findCategory(offset.tree, offset.category);

    if (_.isNil(category)) {
      return span;
    }
    return <Tooltip placement='top' content={category.getLabel(t)}>
      {span}
    </Tooltip>;
  } else {
    return span;
  }
};