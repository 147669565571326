import {services} from '../../application/service/services';
import {PaginatedResult, Progress} from "../model";

const resolveTenantId = (rootState) => {
    return rootState.tenant.selectedTenantId;
}

export const progressState = () => ({
    state: {
        refreshRate: null,
        progress: [],
        totalProgressElement: 0,
        page: 1,
        pageSize: 15
    },
    reducers: {
        setProgress(state, payload: PaginatedResult<Progress>) {
            state.progress = payload.hits;
            state.totalProgressElement = payload.total
            state.isLoading = false;
            return state;
        },
        setRefreshRate(state, payload) {
            state.refreshRate = payload;
            return state;
        },
        setPage(state, payload) {
            state.page = payload
            return state;
        },
        setLoading(state, payload) {
            state.isLoading = payload
            return state;
        },
    },
    effects: (dispatch) => ({
        async changePage(payload, rootState) {
            dispatch.progress.setPage(payload);
            dispatch.progress.refresh()
        },

        async refresh(resetPage, rootState) {
            dispatch.progress.setLoading(true);
            if (resetPage) {
                dispatch.progress.changePage(1)
            } else {
                dispatch.progress.setProgress(await services.getProgressService().getProgress(rootState.progress.page, rootState.progress.pageSize, resolveTenantId(rootState)));
            }
        },
        async cancelAll(payload, rootState) {
            await services.getProgressService().cancelAll(resolveTenantId(rootState));

            dispatch.progress.refresh();
        },
        async deleteAll(payload, rootState) {
            await services.getProgressService().deleteAll(resolveTenantId(rootState));

            dispatch.progress.refresh();
        },
        async reindex(payload, rootState) {
            await services.getProgressService().reindex(resolveTenantId(rootState));

            dispatch.progress.refresh();
        },
        async reindexStreams(payload, rootState) {
            await services.getProgressService().reindexStreams();
            dispatch.progress.refresh();
        },
        async replayAll(payload, rootState) {
            await services.getProgressService().replayAll(resolveTenantId(rootState));

            dispatch.progress.refresh();
        },
        async cleanup(payload, rootState) {
            await services.getProgressService().cleanup(resolveTenantId(rootState));

            dispatch.progress.refresh();
        },
        async cancelSubstream(payload, rootState) {
            await services.getProgressService().cancelSubstream(payload, resolveTenantId(rootState));

            dispatch.progress.refresh();
        },
        async deleteSubstream(payload, rootState) {
            await services.getProgressService().deleteSubstream(payload, resolveTenantId(rootState));

            dispatch.progress.refresh();
        },
        async replaySubstream(payload, rootState) {
            await services.getProgressService().replaySubstream(payload, resolveTenantId(rootState));

            dispatch.progress.refresh();
        }
    })
});