import _ from 'lodash';
import { Tonality } from '@eptica/vecko-js-commons';
import { Kpi } from './Kpi';
import { ChartType } from '../../../utils/chart/SerieChartBuilder';
import { ProgressItem } from '../../../component/ProgressItem';
import { FieldValue } from "../field/FieldValue";
import React from "react";
import { DateTime } from 'luxon';
import { KpiEvolution } from "./KpiInstance";

export class CountByTonalityAndCategoriesKpi extends Kpi {
  readonly tonality: Tonality;
  readonly categories: Array<FieldValue>;
  readonly tree: string;

  constructor(name: string, tonality: Tonality, tree: string, categories: Array<FieldValue>) {
    super(name);
    this.tonality = tonality;
    this.categories = categories;
    this.tree = tree;
  }

  getType() {
    return 'countByTonalityAndCategories';
  }

  getLabel(kpiInstance, t) {
    return _.capitalize(t('kpi.countByTonalityAndCategories.label', {tonality: this.tonality.name()}));
  }

  getNumber(kpiInstance, t) {
    return t('kpi.countByTonalityAndCategories.number', {count: _.round(kpiInstance.data.percent, 2)});
  }

  getLegend(kpiInstance, t) {
    return t('kpi.countByTonalityAndCategories.legend', {tonality: this.tonality.name()});
  }

  getIcon(kpiInstance) {
    return this.tonality.icon;
  }

  getColor(kpiInstance) {
    return this.tonality.color;
  }

  getEvolution(kpiInstance, t): KpiEvolution {

    return {
      type: ChartType.TIME_BAR,
      yAxis: {
        autoAdapted: { min:null, max:100 },
        min: 0,
        tickLabelCallback: (value) => {
          return `${value} %`;
        }
      },
      data: kpiInstance.data.evolution.map(e => ({x: DateTime.fromMillis(e.date).toJSDate(), y: e.value})),
      tooltipRenderer: (value) => {
        return <ProgressItem label={this.getLabel(kpiInstance, t)} color={this.tonality.color} value={value}/>;
      }
    };
  }

  getApiParams() {
    return {
      tonality: this.tonality.name(),
      tree: this.tree,
      categories: this.categories.map(c => c.fullName)
    };
  }
}