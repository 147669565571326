import axios from 'axios';

const calculateKpis = async (filter, kpis) => {
  const json = JSON.stringify({
    filter: filter,
    kpis: kpis
  });
  return axios
    .post('/api/ui/topics/analysis', json, {
      headers: { 'Content-type': 'application/json'  }
    })
    .then(response => response.data);
};

export default { calculateKpis };