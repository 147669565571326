import { Colors } from '@blueprintjs/core';
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  FontSpec,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PieController,
  PointElement,
  TimeScale,
  TimeUnit,
  Tooltip
} from 'chart.js';
import Color from 'color';
import _ from 'lodash';
import { DateTime, Duration } from 'luxon';
import { VeckoFonts } from '../../style/VeckoFont';
import { Align } from "chartjs-plugin-datalabels/types/options";
import { ChartSerie, ChartSerieData, CoordinatedData, YAxis } from "./SerieChartBuilder";


/********* element imports *********/
Chart.register(LineElement);
Chart.register(Filler);
Chart.register(BarElement);
Chart.register(PointElement)

/********* controller imports *********/
Chart.register(BarController);
Chart.register(LineController);
Chart.register(PieController);

/********* scale imports *********/
Chart.register(TimeScale);
Chart.register(LinearScale);
Chart.register(CategoryScale);

/********* plugin imports *********/
Chart.register(Tooltip);
Chart.register(Legend);

Chart.register(ArcElement);

const tooltipPlugin: any = Chart.registry.getPlugin('tooltip');
const DEFAULT_ADAPTED_AXIS_GAP = 10;
export const Y_AXIS_DEFAULT_CONFIG: any = {
  position: 'left',
  ticks: {
    font: 'normal',
    padding: 15, // padding arrount axis displayed value
    color: Colors.GRAY1
  },
  grid: {
    drawTicks: true,
    tickLength: 5,
    borderDashOffset: 10,
    offset: false,
    color: Colors.GRAY5,
    zeroLineColor: Colors.GRAY3
  }
};


tooltipPlugin.positioners.top = function (elements, eventPosition) {
  if (elements.length > 0) {
    const hit = elements.find(e => {
        return eventPosition.x >= e.element.x - e.element.width / 2 && eventPosition.x <= e.element.x + e.element.width / 2 &&
          eventPosition.y >= e.element.y && eventPosition.y <= e.element.y + e.element.base
      }
    );
    if (hit) {
      return {
        x: hit.element.x,
        y: hit.element.y
      };
    }

    const nearest = tooltipPlugin.positioners.nearest(elements, eventPosition);
    return {
      x: nearest.x,
      y: nearest.y - 5
    };
  }
  return false;
};

/*********** Chart default line properties    **********/
Chart.defaults.elements.line.tension = 0.4;

/*********** Chart default Font properties    **********/
if ("family" in Chart.defaults.font) {
  Chart.defaults.font.family = "'" + VeckoFonts.textFont + "'";
}

/*********** Chart default Legend properties    **********/
Chart.defaults.plugins.legend.labels.boxWidth = 15;

/************ Chart Animation  *************/
Chart.defaults.elements.bar.borderWidth = 2;
const default_animation_duration = Chart.defaults.animation && Chart.defaults.animation.duration;
export const disableAnimation = () => {
  Chart.defaults.animation = false
};
export const enableAnimation = () => {
  Chart.defaults.animation = { duration: default_animation_duration }
};


const DAY_AS_MILLI = 24 * 60 * 60 * 1000;

type dateFormatType = { axis: string, tooltip: string };
type dateFormatsType = Partial<{
  [keys in TimeUnit]: dateFormatType;
}>;

const dateFormats: dateFormatsType = {
  month: {
    axis: 'MMM yyyy',
    tooltip: 'MMMM yyyy'
  },
  week: {
    axis: 'd MMM yyyy',
    tooltip: 'd MMMM yyyy (#W)'
  },
  day: {
    axis: 'd MMM',
    tooltip: 'd MMMM yyyy'
  },
  hour: {
    axis: 'd MMM H[h]',
    tooltip: 'd MMMM yyyy, H[h]'
  }
};

export const getTimeSale = (serie: ChartSerie): any => {
  const { data } = serie;

  let duration: Duration = Duration.fromMillis(DAY_AS_MILLI);

  if (data.length > 1) {
    if (typeof data[0] !== 'object' || !data[0].hasOwnProperty('x')) {
      throw new Error("Expect Charpoint(x/y)")
    }

    const data0AsChartPoint = data[0] as any;
    const data1AsChartPoint = data[1] as any;

    if (typeof data0AsChartPoint.x !== 'object' || !data[0].hasOwnProperty('x')) {
      throw new Error("Expect Charpoint(x/y)")
    }

    if (!(data0AsChartPoint.x instanceof Date) || !(data1AsChartPoint.x instanceof Date)) {
      throw new Error("Expect Date object for x value")
    }

    duration = DateTime.fromJSDate(data1AsChartPoint.x).diff(DateTime.fromJSDate(data0AsChartPoint.x));
  }

  const unit = Object.keys(dateFormats)
    .find(u => Duration.fromObject({ [u]: 1 }).as('milliseconds') <= duration.as('milliseconds')) as TimeUnit;
  const displayFormats = Object.fromEntries(Object.entries(dateFormats).map(([k, v]) => [k, (v as dateFormatType).axis]));
  const tooltipFormat = dateFormats[unit].tooltip;

  const fontSpec: Partial<FontSpec> = { style: 'normal' };

  return {
    offset: true,
    type: 'time',
    //minBarLength: 20,
    time: {
      unit: unit,
      displayFormats: displayFormats,
      tooltipFormat: tooltipFormat
    },
    ticks: {
      font: fontSpec,
      source: 'data',
      color: Colors.GRAY1
    },
    grid: {
      display: serie.gridLines ? serie.gridLines : false
    }
  };
};

export const getYAxis = (yAxis: YAxis, series: ChartSerie[]): any => {
  const result = { ...Y_AXIS_DEFAULT_CONFIG };
  if (yAxis) {
    const { tickLabelCallback } = yAxis;
    let { min, max, stacked } = yAxis;

    if (!_.isNil(tickLabelCallback) && _.isFunction(tickLabelCallback)) {
      result.ticks.callback = tickLabelCallback;
    }
    if (yAxis.autoAdapted) {
      const mergedDate = series.filter(serie => !serie.yAxisId || serie.yAxisId === yAxis.id).flatMap(serie => serie.data);
      let adaptedMin, adaptedMax = null;
      if (typeof yAxis.autoAdapted !== 'boolean') {
        adaptedMax = yAxis.autoAdapted.max;
        adaptedMin = yAxis.autoAdapted.min;
      }
      const minMax = computeBounds(mergedDate, adaptedMin, adaptedMax);
      min = min | minMax.min
      max = max | minMax.max
    }
    if (!_.isNil(min) && _.isNumber(min)) {
      result.min = min;
    }
    if (!_.isNil(max) && _.isNumber(max)) {
      result.max = max;
    }
    if (!_.isNil(stacked) && _.isBoolean(stacked)) {
      result.stacked = stacked;
    }
  }

  return result;
};

export const getBarChartDataset = (serie: ChartSerie) => {
  const c = Color(serie.color);
  const borderColor = c.darken(0.2);

  const hoverColor = c.darken(0.11);
  const hoverBorderColor = hoverColor.darken(0.2);

  return {
    ...serie,
    type: 'bar',
    backgroundColor: serie.color,
    borderColor: borderColor.toString(),
    hoverBackgroundColor: hoverColor.toString(),
    hoverBorderColor: hoverBorderColor.toString(),
    borderWidth: 1,
    barPercentage: 0.6,
    categoryPercentage: 0.7
  };
};

export const getLineChartDataset = (serie: ChartSerie): any => {
  return {
    label: serie.label,
    type: 'line',
    fill: serie.stacked,
    backgroundColor: serie.color,
    borderColor: serie.color,
    borderJoinStyle: 'miter',
    pointBorderColor: serie.color,
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: serie.color,
    pointHoverBorderColor: serie.color,
    pointHoverBorderWidth: 2,
    pointStyle: 'circle',
    pointRadius: 5,
    pointHitRadius: 10,
    clip: false,
    data: serie.data,
    yAxisID: serie.yAxisId ? serie.yAxisId : null,
    datalabels: {
      offset: 8,
      align: datalabelsAlignFunction,
    }
  };
};

export const getYMinMax = (series) => {
  const data = series
    ?.flatMap(s => s.data)
    ?.map(d => d.y);
  if (!data?.length) {
    return {
      min: 0,
      max: 0
    };
  }

  const result = { min: null, max: null };
  series
    .flatMap(s => s.data)
    .map(d => d.y)
    .forEach(d => {
      result.min = _.isNil(d) || _.isNaN(d) ? result.min : Math.min(result.min, d);
      result.max = _.isNil(d) || _.isNaN(d) ? result.max : Math.max(result.max, d);
    });
  return { min: result.min || 0, max: result.max || 100 };
};

export const datalabelsAlignFunction = (context): Align => {
  const lineDatasets: any[] = context.chart.data.datasets.filter(dataset => dataset.type === 'line')

  if (lineDatasets.length === 1) {
    const data = context.dataset.data[context.dataIndex].y;
    let previousData = context.dataIndex === 0 ? 0 : context.dataset.data[context.dataIndex - 1].y;
    let nextData = context.dataIndex === context.dataset.data.length - 1 ? 0 :
      context.dataset.data[context.dataIndex + 1].y;

    let align: Align = 'start';
    if (previousData < data && nextData < data) {
      align = 'end';
    } else if (previousData < data) {
      align = -120;
    } else if (nextData < data) {
      align = -60;
    }

    return align;
  }

  const otherDatasetIndex = lineDatasets.indexOf(context.dataset) === 0 ? 1 : 0;
  const otherDataSet = lineDatasets[otherDatasetIndex];

  const otherData = otherDataSet.data[context.dataIndex].y;
  const data = context.dataset.data[context.dataIndex].y;

  if (otherData < data) {
    return 'end';
  } else if (otherData === data) {
    if (otherDatasetIndex < context.datasetIndex) {
      return 'end';
    } else {
      return 'start';
    }
  } else {
    return 'start';
  }
};


interface MinMax {
  min: number,
  max: number
}

export const computeBounds = (data: ChartSerieData, min: number, max: number, gap = DEFAULT_ADAPTED_AXIS_GAP): MinMax => {
  const minMax = data.reduce((r, e) => ({
    min: Math.min(getYValue(e), r.min),
    max: Math.max(getYValue(e), r.max),
  }), { min: 0, max: 0 });

  return {
    min: Math.max(min, Math.round((minMax.min - gap) / 10) * 10),
    max: Math.min(max, Math.round((minMax.max + gap) / 10) * 10)
  };
};

export function getYValue(e: number | number[] | CoordinatedData): number {
  if (_.isNil(e)) {
    return;
  }
  if (typeof e === 'number') {
    return e;
  }

  if (isCoordinatedData(e)) {
    return e.y as number;
  }

}

function isCoordinatedData(item: number | number[] | CoordinatedData): item is CoordinatedData {
  return 'y' in (item as CoordinatedData) && 'x' in (item as CoordinatedData);
}

