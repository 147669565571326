import { Tonality } from '@eptica/vecko-js-commons';
import * as PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { services } from '../../../../application/service/services';
import { Header } from '../../../../component/Header';
import { FeedbackForReport } from '../../../../feedback/FeedbackForReport';
import { FeedbackHeaderProps } from '../../../../feedback/FeedbackHeader';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import { RadialGauge } from '../../components/radial_gauge';
import './topicImpactTopEvolutionViz.scss';

function renderCharts(t, charts) {
  return charts.map((chart, index) => {
    const negative = chart.contrib < 0;
    const contribAbs = Math.abs(chart.contrib);
    const contrib = negative ? '-' + contribAbs : '+' + contribAbs;
    const contribStr = t('dashboard.viz.topicImpactTopEvolution.contrib', {contrib});
    return (
      <div key={index} className="top-n__chart-wrapper">
        <div className={'top-n__chart'}>
          <RadialGauge data={chart} />
        </div>
        <div className={'top-n__chart-topic'}>{chart.topic}</div>
        <div className={'top-n__chart-score'} style={{color: chart.color}}>{contribStr}</div>
      </div>
    );
  });
}

function renderSamples(samples) {
  return samples.map((sample, index) => {
    const result = [<Sample key={index} {...sample} />];
    if (index < samples.length - 1) {
      result.push(<hr key={index + '_sep'} />);
    }

    return result;
  });
}

function Sample({ sample, topic }) {
  const headerProps = {
    renderDate: false,
    renderChannel: false,
    renderSatisfactionIndicator: false,
    headerElements: [{
      id:'title',
      component: <Header title={topic} titleStyle={{ fontWeight: 600, textTransform: 'uppercase' }}/>,
      anchor: 'left'
    }]
  } as FeedbackHeaderProps;
  return (
    <FeedbackForReport feedback={sample} headerProps={headerProps} />
  );
}

export function TopicImpactTopEvolutionVizFunction({ viz }, ref) {
  const data = viz.data;
  const params = viz.definition.params;
  const { polarity } = params;
  const color = Tonality.getValueOf(polarity).color;
  const { t } = useTranslation();

  // prepare data
  const charts = [];
  const samples = [];
  data.forEach(d => {
    const label = services.getFieldsService().findCategoryLabel('topic', d.topic, t);

    charts.push({
      value: d.trend,
      contrib: d.value,
      color: color,
      topic: label
    });

    if (d.feedback) {
      samples.push({
        sample: d.feedback,
        topic: label
      });
    }
  });

  return (
    <div className="top-n">
      <div className="top-n__left">
        <div className="top-n__left__charts">{renderCharts(t, charts)}</div>
      </div>
      {
        samples.length > 0 ? <div className="top-n__right">{renderSamples(samples)}</div> : null
      }
    </div>
  );
}



export const TopicImpactTopEvolutionViz = forwardRef(TopicImpactTopEvolutionVizFunction);

TopicImpactTopEvolutionViz.propTypes = {
  viz: PropTypes.instanceOf(VisualizationInstance).isRequired
};
