import { StorageItem } from './StorageItem';

export class StorageFile extends StorageItem {
  static parse(object) {
    return new StorageFile(object.path, object.contentType, object.contentLength, object.lastModified);
  }

  /**
   * @type {string}
   */
  contentType;
  /**
   * @type {number}
   */
  contentLength;

  lastModified;

  constructor(path, contentType, contentLength, lastModified) {
    super(path);
    this.contentType = contentType;
    this.contentLength = contentLength;
    this.lastModified = lastModified;
  }

  isDir() {
    return false;
  }

  name() {
    return this.path.split('/').pop();
  }
}