import {Radio} from '@blueprintjs/core';
import React, {PureComponent} from 'react';
import {PaginatedList} from '../../../component/pagination/PaginatedList';
import {FeedbackForReport} from '../../../feedback/FeedbackForReport';
import './feedbackSelector.scss';

const FeedbackToggle = ({id, selected, feedback, onSelectFeedback}) => {
    let onSelect = onSelectFeedback.bind(this, feedback);

    return <div className="feedbackSelector-toggle" onClick={onSelect}>
        <div className="feedbackSelector-toggle-left">
            <Radio checked={selected} value={id} onChange={onSelect}/>
        </div>
        <div className="feedbackSelector-toggle-right">
            <FeedbackForReport feedback={feedback} condensed={false}/>
        </div>
    </div>;
};

interface FeedbackSelectorProps {
    selectedFeedbackId: any,
    onSelectFeedback: any,
    fetchingData: any,
    items: any,
    pagination: any
    className?: any
}

export class FeedbackSelector extends PureComponent<FeedbackSelectorProps> {

    static defaultProps = {
        showCategories: false
    };

    renderFeedback = (feedback) => {
        const {selectedFeedbackId, onSelectFeedback} = this.props;

        return <FeedbackToggle feedback={feedback}
                               id={feedback.id}
                               selected={selectedFeedbackId === feedback.id}
                               onSelectFeedback={onSelectFeedback}/>;
    };

    feedbackId = (feedback) => {
        return feedback.id;
    };

    render() {
        const {fetchingData, items, pagination} = this.props;

        return <PaginatedList className='vui-layout--vertical__center feedbackSelector'
                              items={items}
                              renderItem={this.renderFeedback}
                              itemId={this.feedbackId}
                              isLoading={fetchingData}
                              paginationProps={pagination}
        />;
    }
}


