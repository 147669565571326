import { services } from '../../../../application/service/services';
import { ParamValue } from './ParamValue';
import _ from "lodash";
import { Field } from "../../../../application/model/field/Field";
import { TFunction } from "i18next";
import {LazyLoadedField} from "../../../../application/model/field/lazy/LazyLoadedField";
import {FieldDisplay} from "../../../../application/model/field/FieldDisplay";

export class FieldParamValue extends ParamValue {
  static parse(object: any): FieldParamValue {
    if (_.isEmpty(object.name)) {
      throw new Error(`field name is mandatory`);
    }

    const fieldParamValue: FieldParamValue =  new FieldParamValue();

    services.getFieldsService().registerLazyLoadedField(new LazyLoadedField(
      object.name,
      new FieldDisplay(FieldDisplay.FieldDisplayKind.DASHBOARD_VIEW, 0),
      (field) => fieldParamValue._field = field));

    return fieldParamValue;
  }

  private _field: Field;

  constructor() {
    super('field');
  }

  get field() {
    if (this._field == null) {
      throw new Error("Field was not loaded !!");
    } else {
      return this._field;
    }
  }

  getId(): string {
    return this.field.name;
  }

  getLabel(t: TFunction): string {
    return this.field.getLabel(t);
  }

  getValueAsPlainObject(): any {
    return this.field.name;
  }

  asPlainObject(): any {
    return {
      ... super.asPlainObject(),
      name: this.field.name
    };
  }

  isValid(): boolean {
    return !_.isNil(this.field) ;
  }

  equals(paramValue: ParamValue): boolean {
    return paramValue instanceof FieldParamValue && this.field === paramValue.field;
  }
}