import { Card, H5, Switch } from '@blueprintjs/core';
import React from 'react';
import * as PropTypes from 'prop-types';

interface EditorItemProps {
  title: string | React.ReactNode,
  children?: React.ReactNode;
  className?: string
}

export class EditorItem extends React.PureComponent<EditorItemProps> {
  renderTitle() {
    const title = this.props.title;

    if (typeof title === 'string') {
      return <H5>{title}</H5>;
    }
    return title;
  }

  render() {
    return <Card className={this.props.className}>
      {this.renderTitle()}
      {this.props.children}
    </Card>;
  }
}