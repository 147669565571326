import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import { Chart } from "../../../../component/chart/Chart";
import { PieChartBuilder } from "../../../../utils/chart/PieChartBuilder";
import { services } from "../../../../application/service/services";
import './distributionViz.scss';
import { connect } from "react-redux";
import { ClickableViz } from "../ClickableViz";
import { FieldVizFeedbacksQueryContext } from "../../../model/viz/VizFeedbacksQueryContext";
import { FieldValue } from "../../../../application/model/field/FieldValue";
import { DistributionChartBuilder } from "../../../../utils/chart/DistributionChartBuilder";
import { CSVExportableViz } from "../CSVExportableViz";

interface DistributionVizProps extends WithTranslation {
  viz: VisualizationInstance,
  setRightPanelOpen: (opened: boolean) => void,
  openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => void
}


interface StateProps {
  clickItem: any
}

class DistributionChartVizComponent extends React.PureComponent<DistributionVizProps> implements ClickableViz, CSVExportableViz {


  state: StateProps = {
    clickItem: null,
  }
  private chartBuilder: PieChartBuilder | DistributionChartBuilder;

  getFeedBacksQueryContext() {
    const result = new FieldVizFeedbacksQueryContext();
    if (this.state.clickItem) {
      const { viz } = this.props;
      result.field = viz.definition.params.field;
      result.selectedValue = this.state.clickItem.group;
    }
    return result;
  }

  getClickedFieldValue(): FieldValue {
    if (this.state.clickItem) {
      const { viz } = this.props;
      return services.getFieldsService().findFieldValue(viz.definition.params.field, this.state.clickItem.group);
    }
    return null;
  }

  onclick(event, items: any[], chart: Chart) {
    this.state.clickItem = items[0].element.$context.raw;
    this.props.openFeedbacksPanel(this, this.state.clickItem.label);
  }

  getDataToExport():any[][]{
    return this.chartBuilder.getDataToExport();
  }

  render() {
    let { viz, t } = this.props;

    const field = services.getFieldsService().getField(viz.definition.spec.params.field);
    this.chartBuilder = viz.definition.spec.uiParams.chartType === 'pie' ? new PieChartBuilder(viz, field, t) : new DistributionChartBuilder(viz, field, t);
    this.chartBuilder.showLegend(true)
      .onclick(this.onclick.bind(this))
      .withData(viz.data)
      .legend(viz.definition.uiParams.legend)
      .displayLabelsInline(true);


    return <div className="distributionChartViz">
      <div className='distributionChartViz__chartWrapper'>
        <Chart chartBuilder={this.chartBuilder}/>
      </div>
    </div>;
  }

}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => {
      dispatch.dashboard.openFeedBackPanel({ viz, panelTitle });
    },
  }
};

export const DistributionChartViz = withTranslation(undefined,{ withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(DistributionChartVizComponent));

