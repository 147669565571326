import { SatTypeEnum } from '../../../application/model/SatTypeEnum';
import { SearchAspect } from './SearchAspect';
import _ from "lodash";

export class SatTypeAspect extends SearchAspect {
  static parse(object) {
    return new SatTypeAspect(object.satType);
  }

  /**
   * @type {SatType}
   */
  _satType;

  /**
   *
   * @param {SatType|string} satType
   */
  constructor(satType) {
    super('satType');
    this._satType = _.isString(satType) ? SatTypeEnum.valueOf(satType) : satType;
  }

  toPlainObject() {
    if (_.isNil(this._satType)) {
      throw new Error('satType cannot be nil')
    }

    const result:any = super.toPlainObject();
    result.satType = this._satType.name;

    return result;
  }
}