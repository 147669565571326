import _ from 'lodash';
import { services } from '../../../application/service/services';
import { Param } from './Param';
import { DateRangeParamValue } from './values/DateRangeParamValue';
import { ParamValue } from "./values/ParamValue";

export class DateRangeParam extends Param<DateRangeParamValue> {
  static parse(reportId: string, object: any): DateRangeParam {
    if (_.isEmpty(object.name)) {
      throw new Error('name is mandatory');
    }
    return new DateRangeParam(reportId, object.name);
  }

  constructor(reportId:string, name: string) {
    super(reportId,'dateRange', name);
  }

  resolveValue(state: Dict<OneOrMany<ParamValue>>, init: boolean): Maybe<OneOrMany<DateRangeParamValue>> {
    const currentValue = this.getValue(state);

    if (init && _.isNil(currentValue)) {
      return new DateRangeParamValue(services.getDashboardParamsService().getDateRange().shift(-1));
    }

    return currentValue;
  }

  valueAsPlainObject(value: OneOrMany<DateRangeParamValue>): any {
    if (_.isArray(value)) {
      throw new Error('Multiple values is not supported');
    }

    return (value as DateRangeParamValue).getValueAsPlainObject();
  }
}