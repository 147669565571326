import _ from 'lodash';
import { BoolFilter } from '../../utils/query/filter/BoolFilter';
import { VerbatimNotEmptyFilter } from '../../utils/query/filter/VerbatimNotEmptyFilter';
import { Highlight } from '../../utils/query/Highlight';
import { TonalitiesDistribution } from '../model/distribution/TonalitiesDistribution';
import { services } from './services';
import { TopicStat, TopicStatUtils, TopicStatValue } from "../model/TopicStat";
import { DistributionKind } from "../model/distribution/DistributionKind";
import { DistributionItem } from "../model/distribution/DistributionItem";
import { SearchQuery } from "../../utils/query/SearchQuery";
import { FieldName } from "../model/field/Field";
import { ExistsFilter } from "../../utils/query/filter/ExistsFilter";
import { FieldValue } from "../model/field/FieldValue";
import { SequencesSummaryArgs } from "./sequencesService";
import { Sequence } from "../model";
import { SatTypeDistribution } from "../model/distribution/SatTypeDistribution";

interface InternalTopicStat {
  rawValue: any,
  topic: FieldValue
}

interface TopicsStatsArgs {
  filter: Object,
  distribution: Object
}

export class TopicsStatService {
  private _topicsStatFetcher;
  private _topicsStats: Array<TopicStat> = [];
  private _topicsStatsMap: Dict<TopicStat> = {};
  private _feedbacksCount: number = 0;
  private _selectedStat: TopicStat;
  private _distributionKind: DistributionKind;
  private _distributionItem: DistributionItem;

  init() {
    this._topicsStatFetcher = services.getFetcherService().getFetcher('topicsStat');
    this._distributionKind = TonalitiesDistribution.get();
  }

  async refreshTopicsStats() {
    const topicTree = services.getFieldsService().getCategoryTree('topic');

    const stats = await this._topicsStatFetcher.getTopicsStats(this.getTopicsStatsArgs());

    this._feedbacksCount = stats.total;

    this._topicsStats = [];
    this._topicsStatsMap = {};

    const firstLevelStatsByTopicId = {};
    const topicStats: Array<InternalTopicStat> = (stats.stats || [])
      .map(topicStat => ({
        rawValue: topicStat,
        topic: topicTree.find(topicStat.topic)
      }))
      .filter(internalTopicStat => !_.isNil(internalTopicStat.topic));

    // first level
    topicStats
      .filter(topicStat => topicStat.topic.level === 0)
      .forEach(topicStat => {
        const ts = TopicStat.create(topicStat.topic, new TopicStatValue(topicStat.rawValue.value.count, topicStat.rawValue.value.percent));

        this._distributionKind.items.forEach(distributionItem => {
          const v = topicStat.rawValue.distribution[distributionItem.id];
          ts.putDistribution(distributionItem, v.count, v.percent);
        });

        this._topicsStats.push(ts);
        this._topicsStatsMap[ts.id] = ts;
        firstLevelStatsByTopicId[topicStat.topic.getId()] = ts;
      });

    // second level
    topicStats
      .filter(topicStat => topicStat.topic.level === 1)
      .forEach(topicStat => {
        const parent = firstLevelStatsByTopicId[topicStat.topic.parent.getId()];
        const ts = TopicStat.create(topicStat.topic, new TopicStatValue(topicStat.rawValue.value.count, topicStat.rawValue.value.percent), parent);

        this._distributionKind.items.forEach(distributionItem => {
          const v = topicStat.rawValue.distribution[distributionItem.id];
          ts.putDistribution(distributionItem, v.count, v.percent);
        });

        this._topicsStatsMap[ts.id] = ts;
      });

    // refresh selectedStat according to new instances
    this._selectedStat = this._topicsStatsMap[this._selectedStat?.id]
  }

  set distributionItem(distributionItem: DistributionItem) {
    this._distributionItem = distributionItem;
  }

  get distributionKind(): DistributionKind {
    return this._distributionKind;
  }

  set distributionKind(distributionKind: DistributionKind) {
    this._distributionKind = distributionKind;
    this._distributionItem = undefined;
  }

  setSelectedStat(statId: string): void {
    this._selectedStat = this._topicsStatsMap[statId];
  }

  get selectedStat(): TopicStat {
    return this._selectedStat;
  }

  get feedbacksCount(): number {
    return this._feedbacksCount;
  }

  getTopicsStatsForBubbleView(): Array<TopicStat> {
    if (_.isNil(this._selectedStat)) {
      return this.getTopicsStats(false);
    } else if (this._selectedStat.hasChildren()) {
      return this.getTopicsStats(true)
        .filter(ts => ts.parent === this._selectedStat || ts === this._selectedStat);
    } else {
      return this.getTopicsStats(true)
        .filter(ts => ts === this._selectedStat.parent || ts.parent === this._selectedStat.parent);
    }
  }

  getTopicsStats(flatten: boolean): Array<TopicStat> {
    let result = this._topicsStats;
    if (flatten) {
      result = TopicStatUtils.flatten(result);
    }
    return result;
  }

  getTopicStatById(id: string): Maybe<TopicStat> {
    return this._topicsStatsMap[id];
  }

  decorateQuery(query: SearchQuery): void {
    this._distributionKind.decorateFeedbackQuery(query, this._selectedStat?.topic, this._distributionItem);

    query.filter = BoolFilter.must(query.filter, this.getActionableFilter());
    query.highlight = new Highlight(false, ['topic']);
  }

  getDistributionKinds(): Array<DistributionKind> {
    return services.getSatService().getDistributionKinds();
  }

  async save() {
    await this._topicsStatFetcher.save(this.getTopicsStatsArgs(), services.getI18nService().getCurrentLanguage());
  }

  async getSequences(): Promise<Array<Sequence>> {
    const mainFilter = services.getFilterService().getFilterForApi(null);
    const args: Partial<SequencesSummaryArgs> = {
      filter: this.getActionableFilter().addMust(mainFilter),
      category: this._selectedStat ? {
        tree: 'topic',
        category: this._selectedStat.topic.fullName
      } : undefined,
      sat: (this._distributionKind instanceof SatTypeDistribution) ? {
        satType: this._distributionKind.satType,
        tonality: this._distributionItem?.tonality
      } : undefined,
      tonality: (this._distributionKind instanceof TonalitiesDistribution) ? this._distributionItem?.tonality : undefined,
    }

    return services.getSequencesService().getSequences(args);
  }

  private getTopicsStatsArgs(): TopicsStatsArgs {
    const filter = BoolFilter.must(
      services.getFilterService().getFilterForApi(null),
      this.getActionableFilter()
    ).toPlainObject();

    return {
      filter: filter,
      distribution: this._distributionKind.toPlainObject()
    };
  }

  private getActionableFilter(): BoolFilter {
    const actionableFilter = new BoolFilter(null, null, null);
    actionableFilter.addMust(new VerbatimNotEmptyFilter());
    actionableFilter.addMust(new ExistsFilter(FieldName.categoryTree('topic')))
    return actionableFilter;
  }
}

services.registerService('topicsStatService', new TopicsStatService());
