import { Button, Card, Divider, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import { CSSProperties, Key, ReactNode } from 'react';
import { moveLeft, moveRight } from '../utils/collectionUtils';
import { InfiniteCache } from '../utils/InfiniteCache';
import './listEditor.scss';


interface ListEditorProps<T> {
  items?: Array<T>,
  itemId?: (item: T) => Key | null,
  itemLabel?: (item: T, index: number) => ReactNode,
  renderItem: (item: T, index: number) => ReactNode,
  onChanged: (newItems: Array<T>) => void,
  beforeDelete?: (item: T) => void,
  afterDelete?: (item: T) => void,
  enableItemDeletion?: boolean,
  enableItemOrdering?: boolean,
  fill?: boolean,
  disabled?: boolean
  style?: CSSProperties
  renderItemSeparator?: boolean
  annihilateEnterKey?: boolean
}

export class ListEditor<T> extends React.PureComponent<ListEditorProps<T>> {

  static defaultProps: Partial<ListEditorProps<any>> = {
    items: [],
    enableItemDeletion: true,
    enableItemOrdering: false,
    fill: true,
    disabled: false,
    renderItemSeparator: false
  }

  private hiddenButtonRef: HTMLAnchorElement;

  annihilateEnterKey(event, funct) {
    if (event.isTrusted || !this.props.annihilateEnterKey) {
      funct();
      if (this.props.annihilateEnterKey) {
        //Workaround
        //in case of enter annihilation, give the focus to the hidden a to capture key press event
        setTimeout(args => {
          this.hiddenButtonRef.focus();
        }, 50);
      }
    }
  }

  onDeleteHandlers = new InfiniteCache((item) => (event) => {
    this.annihilateEnterKey(event,
      () => {
        const { items, beforeDelete, afterDelete, onChanged } = this.props;
        if (beforeDelete) {
          beforeDelete(item);
        }
        onChanged(items.filter(it => it !== item));
        if (afterDelete) {
          afterDelete(item);
        }
      })
  });

  onUpHandlers = new InfiniteCache((item) => (event) => {
    this.annihilateEnterKey(event,
      () => {
        const { items, onChanged } = this.props;
        onChanged(moveLeft(items, this.findIndex(item)));
      })
  });

  onDownHandlers = new InfiniteCache((item) => (event) => {
    this.annihilateEnterKey(event,
      () => {
        const { items, onChanged } = this.props;
        onChanged(moveRight(items, this.findIndex(item)));
      })
  });

  findIndex(item) {
    return this.props.items.indexOf(item);
  }

  hasItems() {
    return this.props.items.length > 0;
  }

  renderItemSeparator = () => {
    return <Divider className={'divider'}/>;
  };

  renderActions(item, i, isFirst, isLast) {
    const { enableItemDeletion, enableItemOrdering, disabled, annihilateEnterKey } = this.props;
    return <>
      {
        enableItemDeletion ?
          <Button intent={Intent.DANGER} minimal={true} disabled={disabled} icon='delete'
                  onClick={this.onDeleteHandlers.get(item)}/>
          : null
      }
      {
        enableItemOrdering ?
          <Button minimal={true} icon="arrow-up"
                  disabled={disabled || isFirst} onClick={this.onUpHandlers.get(item)}/>
          : null
      }
      {
        enableItemOrdering ?
          <Button minimal={true} icon='arrow-down' disabled={disabled || isLast}
                  onClick={this.onDownHandlers.get(item)}/>
          : null
      }
    </>;
  }

  getItemId(item, i) {
    const { itemId } = this.props;
    return itemId ? itemId(item) : i;
  }

  hasLabelColumn() {
    const { itemLabel } = this.props;
    return !_.isNil(itemLabel);
  }

  renderItem(item, i, isFirst, isLast) {
    const { itemLabel, renderItem } = this.props;

    const id = this.getItemId(item, i);
    return <React.Fragment key={id}>
      {
        this.hasLabelColumn() ?
          <div className='label' style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>
            {itemLabel(item, i)}
          </div>
          : null
      }
      <div className='item'>
        {
          renderItem(item, i)
        }
      </div>
      <div className='actions'>
        {
          this.renderActions(item, i, isFirst, isLast)
        }
      </div>
      {this.props.renderItemSeparator && !isLast ? this.renderItemSeparator() : null}
    </React.Fragment>;
  }

  renderItems() {
    const { items, fill, style, annihilateEnterKey } = this.props;

    let clazz = 'listEditor';
    if (fill) {
      clazz = classNames(clazz, 'fill');
    }
    if (this.hasLabelColumn()) {
      clazz = classNames(clazz, 'withLabel');
    }


    return <React.Fragment>
      <Card className={clazz} style={{ ...{ padding: 5 }, ...style }}>
        {items.map((item, i) => this.renderItem(item, i, i === 0, i === items.length - 1))}
      </Card>
      {
        //Workaround
        //in case of enter annihilation, create a to capture key press event
        annihilateEnterKey ?
          <a tabIndex={-1} ref={ref => this.hiddenButtonRef = ref} style={{ width: 0, height: 0 }}></a> : null
      }
    </React.Fragment>

  }

  render() {
    return this.hasItems() ? this.renderItems() : null;
  }
}