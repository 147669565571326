import { services } from './services';

export class ConfigurationService {
  private _configurations: Vecko.VeckoConfigurations;

  async init(loadConfig = false): Promise<void> {
    const configurationFetcher = services.getFetcherService().getFetcher('configuration');
      this.initWithConfig(loadConfig ? await configurationFetcher.getConfigurations(['FIELDS', 'UI', 'PURGE', 'TRANSCRIBE']): {UI: "{}" , FIELDS: "{}", PURGE: "{}"});
  }

  initWithConfig(configuration: Dict<string>): void {
    this._configurations = Object.fromEntries(Object.entries(configuration).map(([k, v]) => ([k, JSON.parse(v) as Vecko.VeckoConfigurations])));
    Object.values(this._configurations).forEach(c => ConfigurationService.removeVersion(c));
    services.getI18nService().addTranslationsFromConfiguration(this.fieldsConfiguration, this.uiConfiguration);
  }


  get fieldsConfiguration(): Vecko.FieldsConfiguration {
    return this._configurations['FIELDS'];
  }

  getFieldConfiguration(fieldName): Vecko.FieldConfiguration {
    return this.fieldsConfiguration[fieldName];
  }

  get uiConfiguration(): Vecko.UIConfiguration {
    return this._configurations['UI'];
  }

  get purgeConfiguration(): Vecko.PurgeConfiguration {
    return this._configurations['PURGE'];
  }

  get transcribeConfiguration(): Vecko.TranscribeConfiguration {
    return this._configurations['TRANSCRIBE'];
  }

  private static removeVersion(configuration: Vecko.VeckoConfiguration) {
    delete configuration['@version'];
    return configuration;
  }
}

services.registerService('configurationService', new ConfigurationService());
