import i18next, {TFunction} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {Tonality} from "@eptica/vecko-js-commons";
import _ from "lodash";

const DEFAULT_NAMESPACE = 'translations';

const DEFAULT_OPTIONS = {
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: [DEFAULT_NAMESPACE],
    defaultNS: DEFAULT_NAMESPACE,

    keySeparator: false, // we use content as keys

    interpolation: {
        //escapeValue: false, // not needed for react!!
        formatSeparator: ','
    },
    allowObjectInHTMLChildren: true,
    react: {
        wait: true
    }
};

const defaultChangeHandler = (locale: string) => {
    console.log('language change to ' + locale);
}


/**
 *
 * @param {*} resources
 * @param {*} options
 * @param {Function} callback
 */
export const initI18n = (resources, options = {}, localChangeHandler: (locale: string) => void = defaultChangeHandler, callback = undefined): Promise<TFunction> => {

    const getCurrentLanguage = (): string => {
        const language = i18next.language;
        if (!language) {
            return language;
        }
        if (language.includes('-')) {
            return language.split('-')[0];
        }
        return language;
    }


    i18next.on('languageChanged', localChangeHandler);


    const commonTranslations = [
        Tonality.i18n
    ];

    const res = _.merge({},
        ...commonTranslations.map(c => (
                _.mapValues(c, v => ({[DEFAULT_NAMESPACE]: v}))
            )
        ),
        resources
    );

    const opt = _.merge({},
        DEFAULT_OPTIONS,
        options,
        {resources: res}
    );


    return i18next
        .use(LanguageDetector)
        .use(initReactI18next)
        .init(opt as any, (error, t) => {
            localChangeHandler(getCurrentLanguage());
            if (callback) {
                callback(error, t);
            }
        });
};