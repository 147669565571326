const INITIALIZED: symbol = Symbol();

export abstract class EnumValue {
    private _name: string; // set in Enum.enumValuesFromObject

    /**
     * `initEnum()` on Enum closes the class, so subsequent calls to this
     * constructor throw an exception.
     */
    protected constructor(name?:string) {
        this._name = name;
        if ({}.hasOwnProperty.call(new.target, INITIALIZED)) {
            throw new Error('EnumValue classes can’t be instantiated individually');
        }
    }

    toString() {
        return `${this.constructor.name}.${this._name}`;
    }

    /**
     * Returns the property name used for this instance in the Enum.
     *
     * @returns {string} the property name used for this instance in the Enum
     */
    get name(): string {
        return this._name;
    }
}

/**
 * This is an abstract class that is not intended to be used directly. Extend it
 * to turn your class into an enum (initialization is performed via
 * `this.initEnum()` within the constructor).
 */
export abstract class Enum<T extends EnumValue> {
    private static enumValues: Map<string, EnumValue[]> = new Map<string, EnumValue[]>();
    private name: string;

    /**
     * Set up the enum and close the class. This must be called after the
     * constructor to set up the logic.
     *
     * @param theEnum The enum to process
     */
    private static initEnum<T extends EnumValue>(name:string, theEnum: Enum<T>): void {
        if (Enum.enumValues.has(name)) {
            throw new Error("Duplicate enum name: " + name);
        }
        let enumValues: T[] = this.enumValuesFromObject(theEnum);

        if (enumValues.length === 0) {
            throw new Error(`Empty enum is not allowed`);
        }

        theEnum.name = name;

        Object.freeze(theEnum);
        Enum.enumValues.set(theEnum.name, enumValues);
    }

    /**
     * Extract the enumValues from the Enum. We set the ordinal and propName
     * properties on the EnumValue. We also freeze the objects and lock the Enum
     * and EnumValue to prevent future instantiation.
     *
     * @param theEnum The enum to process
     * @returns {T[]} The array of EnumValues
     */
    private static enumValuesFromObject<T extends EnumValue>(theEnum: Enum<T>): T[] {
        const values: T[] = Object.getOwnPropertyNames(theEnum)
            .filter((propName: string) => theEnum[propName] instanceof EnumValue)
            .map((propName: string) => {
                const enumValue: T = theEnum[propName];
                Object.defineProperty(enumValue, '_name', {
                    value: propName,
                    configurable: false,
                    writable: false,
                    enumerable: true
                });
                Object.freeze(enumValue);
                return enumValue;
            });
        if (values.length) {
            values[0].constructor[INITIALIZED] = true;
        }

        return values;
    }

    private static values(name: string): EnumValue[] {
        let values: EnumValue[] | undefined = this.enumValues.get(name);
        return values ? [...values] : [];
    }

    /**
     * Given the property name of an enum constant, return its value.
     *
     * @param name The name to search by
     * @returns {undefined|T} The matching instance
     */
    valueOf(name: string): T | undefined {
        return this.values.find((x: T) => x.name.toLowerCase() === name?.toLowerCase());
    }

    /**
     * Return a defensively-copied array of all the elements of the enum.
     *
     * @returns {T[]} The array of EnumValues
     */
    get values(): T[] {
        return Enum.values(this.name) as T[];
    }

    /**
     * Returns a simple representation of the type.
     *
     * @returns {string} a simple representation of the type
     */
    toString(): string {
        return this.name;
    }

    /**
     * Set up the enum and close the class.
     */
    protected initEnum(name:string): void {
        Enum.initEnum(name, this);
    }
}