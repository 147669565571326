import { StorageDirectory } from './StorageDirectory';
import { StorageFile } from './StorageFile';

/**
 *
 * @param {Object} object
 * @return {StorageItem}
 */
export const parseStorageItem = (object) => {
  const type = object['@type'];

  if (type === 'file') {
    return StorageFile.parse(object);
  }
  if (type === 'directory') {
    return StorageDirectory.parse(object);
  }

  throw new Error('Cannot parse object into StorageItem');
};