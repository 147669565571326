import { ParamValue } from './ParamValue';
import { FieldParamValue } from "./FieldParamValue";
import { FieldValue } from "../../../../application/model/field/FieldValue";
import { doFor, equalsInAnyOrder, firstItem, toArray } from "../../../../utils/collectionUtils";
import _ from "lodash";
import { TFunction } from "i18next";
import { services } from "../../../../application/service/services";
import { LazyLoadedFieldValue } from "../../../../application/model/field/lazy/LazyLoadedFieldValue";

export class FieldValueParamValue extends ParamValue {
  static parse(object: any): FieldValueParamValue {
    if (_.isEmpty(object.field)) {
      throw new Error(`property field is mandatory`);
    }

    const fieldParam: FieldParamValue = FieldParamValue.parse(object.field);

    let fieldName;
    if (fieldParam.field) {
      fieldName = fieldParam.field.name;
    } else {
      // field is null or not yet loaded
      throw new Error("Could not load value on a unknown field");
    }

    const fieldValueParamValue = new FieldValueParamValue(fieldParam, undefined);

    if (object.value) {
      if (Array.isArray(object.value)) {
        fieldValueParamValue._value = [];
        object.value.forEach(v => {
          services.getFieldsService().registerLazyLoadedFieldValue(new LazyLoadedFieldValue(
            fieldName,
            v,
            (fieldValue) => (fieldValueParamValue._value as Array<FieldValue>).push(fieldValue)
          ));
        })
      } else {
        services.getFieldsService().registerLazyLoadedFieldValue(new LazyLoadedFieldValue(
          fieldName,
          object.value,
          (fieldValue) => fieldValueParamValue._value = fieldValue
        ));
      }
    }

    return fieldValueParamValue;
  }

  readonly field: FieldParamValue;
  _value: OneOrMany<FieldValue>;

  constructor(field: FieldParamValue, value: OneOrMany<FieldValue>) {
    super('fieldValue');
    this.field = field;
    this._value = value;
  }

  get value(): OneOrMany<FieldValue> {
    return this._value;
  }

  getId(): string {
    return toArray(this.value).map(v => v.fullName).join();
  }

  getLabel(t: TFunction): string {
    return toArray(this.value).map(v => v.getLabel(t)).join();
  }

  getFullLabel(t: TFunction): string {
    return `${this.field.getLabel(t)} (${this.getLabel(t)})`
  }

  getValueAsPlainObject(): any {
    let value = null;
    if (!_.isNil(this.value)) {
      const values = toArray(this.value).map(v => v.fullName);
      if (Array.isArray(this.value)) {
        value = values;
      } else {
        value = firstItem(values);
      }
    }

    return {
      field: this.field ? this.field.field.name : null,
      value: value
    }
  }

  asPlainObject(): any {
    return {
      ...super.asPlainObject(),
      field: this.field.asPlainObject(),
      value: doFor(this.value, fv => fv.fullName)
    };
  }

  isValid(): boolean {
    return this.field.isValid() && !_.isEmpty(this.value);
  }

  isMultiValued() {
    return Array.isArray(this.value) && this.value.length > 1;
  }

  equals(paramValue: ParamValue): boolean {
    return paramValue instanceof FieldValueParamValue && this.field === paramValue.field &&
      equalsInAnyOrder(toArray(this.value), toArray(paramValue.value));
  }
}