import { Predicate } from './Predicate';
import _ from 'lodash';

export class AllItemsMatch<T> extends Predicate<Array<T>> {
  private readonly _predicate: Predicate<T>;

  constructor(predicate:Predicate<T>) {
    super();
    if (_.isNil(predicate)) {
      throw new Error('given predicate cannot be nil');
    }
    if (!(predicate instanceof Predicate)) {
      throw new Error('given predicate must be instance of Predicate');
    }

    this._predicate = predicate;
  }

  match(object:Array<T>): boolean {
    if (_.isNil(object)) {
      throw Error('cannot handle nil');
    }

    if (!_.isArray(object)) {
      throw Error('can handle only arrays');
    }

    for (let item of object) {
      if (!this._predicate.match(item)) {
        return false;
      }
    }
    return true;
  }

  message() {
    return `all items should match predicate ${this._predicate}`;
  }
}