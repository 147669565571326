import { Predicate } from './Predicate';
import _ from 'lodash';

export class TypeOf<T> extends Predicate<T> {
  private readonly type:string;
  private readonly allowNil:boolean;

  constructor(type:string, allowNil:boolean = true) {
    super();

    if (_.isNil(type)) {
      throw new Error('Given type cannot be nil');
    }
    if (!_.isString(type)) {
      throw new Error(`Given type ${type} must be a string`);
    }

    this.type = type;
    this.allowNil = allowNil;
  }

  match(object:T):boolean {
    if (_.isNil(object)) {
      return this.allowNil;
    }
    return typeof object === this.type;
  }


  message():string {
    return `type of ${this.type}`;
  }
}