export const messagesState = () => ({
  state: [],
  reducers: {
    createMessage(state, payload) {
      state.push({text: payload});
      return state;
    },
    removeMessage(state, payload) {
      if (state.length) {
        state.shift();
      }
    }
  }
});
