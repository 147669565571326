import React from 'react';
import {NoneFieldValue} from '../../application/model/field/NoneFieldValue';
import {FieldValueSelector} from '../../component/selector/FieldValueSelector';
import {FieldValueSideSelector} from '../../component/selector/FieldValueSideSelector';
import {FilterLocation, FilterLocationEnum} from '../FilterLocationEnum';
import {equalsInAnyOrder, toArray} from '../../utils/collectionUtils';
import {FieldValue} from '../../application/model/field/FieldValue';
import {AbstractFilterCtrl, TagValue} from './AbstractFilterCtrl';
import {stateConverterRegistry} from './stateConverters/stateConverterRegistry';
import {ConstrainedValuesOnAllowed} from './utils/ConstrainedValuesOnAllowed';
import {FieldValues} from "../../application/model/field/FieldValues";
import _ from "lodash";
import {Field} from "../../application/model/field/Field";
import {StateConverter} from "./stateConverters/StateConverter";
import {TFunction} from "i18next";

export class FieldValueFilterBuilder {
    private readonly _id: string;
    private _fieldValues: FieldValues;
    private _allowEmptySelection: boolean = true;
    private _allowMultiSelection: boolean = false;
    private _canSelectLeavesOnly: boolean = true;
    private _selectFirstIfSingleValue: boolean = false;
    private _allowNoneFieldValue: boolean = false;
    private _values: Array<FieldValue> = [];

    constructor(id: string) {
        this._id = id;
    }

    fieldValues(fieldValues: FieldValues): FieldValueFilterBuilder {
        this._fieldValues = fieldValues;
        return this;
    }

    allowEmptySelection(allowEmptySelection: boolean): FieldValueFilterBuilder {
        this._allowEmptySelection = allowEmptySelection;
        return this;
    }

    allowMultiSelection(allowMultiSelection: boolean): FieldValueFilterBuilder {
        this._allowMultiSelection = allowMultiSelection;
        return this;
    }

    canSelectLeavesOnly(canSelectLeavesOnly: boolean): FieldValueFilterBuilder {
        this._canSelectLeavesOnly = canSelectLeavesOnly;
        return this;
    }

    selectFirstIfSingleValue(selectFirstIfSingleValue: boolean): FieldValueFilterBuilder {
        this._selectFirstIfSingleValue = selectFirstIfSingleValue;
        return this;
    }

    allowNoneFieldValue(allowNoneFieldValue: boolean): FieldValueFilterBuilder {
        this._allowNoneFieldValue = allowNoneFieldValue;
        return this;
    }

    initialValue(value: Maybe<FieldValue | Array<FieldValue>>): FieldValueFilterBuilder {
        if (_.isNil(value)) {
            this._values = [];
        } else {
            this._values = toArray(value);
        }

        return this;
    }

    build(): FieldValueFilterCtrl {
        if (_.isNil(this._fieldValues)) {
            throw new Error('fieldValues cannot be nil');
        }

        let allowedValues = this._canSelectLeavesOnly ? this._fieldValues.getLeaves() : this._fieldValues.flattenValues();

        if (!this._allowNoneFieldValue) {
            allowedValues = allowedValues.filter(v => !(v instanceof NoneFieldValue));
        }

        let values: Maybe<OneOrMany<FieldValue>> = this._values;

        // automatically select the first allowed value
        if (_.isEmpty(this._values) && !this._allowEmptySelection) {
            if (_.isEmpty(allowedValues)) {
                throw new Error('Trying to set initial value but allowed values list is empty');
            }

            values = allowedValues[0];
        }
        // automatically select the first value if only one value
        if (_.isEmpty(this._values) && allowedValues.length === 1 && this._selectFirstIfSingleValue) {
            values = allowedValues[0];
        }

        const fieldValueFilter = new FieldValueFilterCtrl(this._id, this._fieldValues, allowedValues, this._allowEmptySelection,
            this._allowMultiSelection, this._canSelectLeavesOnly,
            this._allowNoneFieldValue);

        fieldValueFilter.setValue(values);

        return fieldValueFilter;
    }
}

export class FieldValueFilterCtrl extends AbstractFilterCtrl<Maybe<OneOrMany<FieldValue>>> {
    private readonly _fieldValues: FieldValues;
    private readonly _allowEmptySelection: boolean;
    private readonly _allowMultiSelection: boolean;
    private readonly _canSelectLeavesOnly: boolean;
    private readonly _allowNoneFieldValue: boolean;
    private readonly _constraint: ConstrainedValuesOnAllowed<FieldValue>;

    constructor(id: string, fieldValues: FieldValues, allowedValues: Array<FieldValue>, allowEmptySelection: boolean,
                allowMultiSelection: boolean, canSelectLeavesOnly: boolean, allowNoneFieldValue: boolean) {
        super(id);

        this._fieldValues = fieldValues;
        this._allowMultiSelection = allowMultiSelection;
        this._allowEmptySelection = allowEmptySelection;
        this._canSelectLeavesOnly = canSelectLeavesOnly;
        this._allowNoneFieldValue = allowNoneFieldValue;

        this._constraint = new ConstrainedValuesOnAllowed(FieldValue, allowedValues, allowEmptySelection,
            allowMultiSelection);
    }

    get field(): Field {
        return this._fieldValues.field;
    }

    getValue(): Maybe<OneOrMany<FieldValue>> {
        return this._constraint.value;
    }

    setValue(value: Maybe<OneOrMany<FieldValue>>) {
        this._constraint.value = value;
    }

    /**
     *
     * @param {function} onChanged
     * @param {FilterLocation} filterLocation
     * @param {Object} additionalProps
     * @return {*}
     */
    uiComponent(t: TFunction, onChanged, filterLocation: FilterLocation = FilterLocationEnum.TOP_START, additionalProps: Maybe<any> = undefined): JSX.Element {
        if (filterLocation === FilterLocationEnum.TOP_START || filterLocation === FilterLocationEnum.TOP_END) {
            return <FieldValueSelector
                key={this.id}
                fieldValues={this._fieldValues}
                selectedValue={this.getValue()}
                onSelectionChanged={onChanged}
                canSelectLeavesOnly={this._canSelectLeavesOnly}
                allowEmptySelection={this._allowEmptySelection}
                allowMultiSelection={this._allowMultiSelection}
                allowNoneFieldValue={this._allowNoneFieldValue}
                {...additionalProps}
            />;
        } else if (filterLocation === FilterLocationEnum.SIDE) {
            return <FieldValueSideSelector
                key={this.id}
                fieldValues={this._fieldValues}
                selectedValue={this.getValue()}
                onSelectionChanged={onChanged}
                canSelectLeavesOnly={this._canSelectLeavesOnly}
                allowEmptySelection={this._allowEmptySelection}
                allowMultiSelection={this._allowMultiSelection}
                allowNoneFieldValue={this._allowNoneFieldValue}
                {...additionalProps}
            />;
        }
    }

    stateConverter(): StateConverter<FieldValue> {
        return stateConverterRegistry.get(FieldValue);
    }

    equals(filter) {
        return equalsInAnyOrder(toArray(this.getValue()), toArray(filter.getValue()));
    }

    getTagValues(t: TFunction): Array<TagValue<FieldValue>> {
        return toArray(this.getValue())
            .map(value => {
                return {tagValue: this.field.getLabel(t) + " : " + value.getLabel(t), value: value};
            });
    }
}