import { FeedbackService } from "./feedbackService";
import { FieldsService } from "./fieldsService";
import { TopicsStatService } from "./topicsStatService";
import { ConfigurationService } from "./configurationService";
import { AnalysisService } from "./analysisService";
import { I18nService } from "./i18nService";
import { FilterConverterService } from "./filterConverterService";
import { TimeZoneService } from "./timeZoneService";
import { DashboardService } from "../../report/service/dashboardService";
import { SecurityService } from "./securityService";
import { StorageService } from "../../administration/service/storageService";
import { CacheService } from "../../administration/service/cacheService";
import { PurgeService } from "../../administration/service/purgeService";
import { SatService } from "./satService";
import { FilterService } from "./filterService";
import { FetcherService } from "../fetch/fetcherService";
import { DashboardExportService } from "../../report/service/dashboardExportService";
import { DashboardParamsService } from "../../report/service/dashboardParamsService";
import { AdministrationService } from "../../administration/service/administrationService";
import { SystemService } from "../../administration/service/systemService";
import { UrlService } from "./urlService";
import {AbstractApplicationService} from "./AbstractApplicationService";
import { SourcingService } from "../../administration/service/sourcingService";
import { SequencesService } from "./sequencesService";
import { ProgressService } from "../../administration/service/progressService";
import { TenantService } from "../../administration/service/tenantService";
import { ConfigurationManagerService } from "../../administration/service/configurationManagerService";
import { ExportService } from "../../administration/service/exportService";
import { DataIntegrationService } from "../../administration/service/dataIntegrationService";
import { ColorService } from "../../utils/styling/ColorService";
import { KafkaService } from "../../administration/service/kafkaService";
import { SourcingErrorService } from "../../administration/service/sourcingErrorService";

class Services {
  services = {};

  registerService(name, service) {
    this.services[name] = service;
    console.log('registering service ' + name);
    return service;
  }

  getService(name) {
    return this.services[name];
  }

  /**
   * @return {AbstractApplicationService}
   */
  getApplicationService(): AbstractApplicationService {
    return this.getService( this.getUrlService().isStandAlone()? 'standaloneApplicationService':'applicationService');
  }

  /**
   * @return {AnalysisService}
   */
  getAnalysisService(): AnalysisService {
    return this.getService('analysisService');
  }

  /**
   * @return {I18nService}
   */
  getI18nService(): I18nService {
    return this.getService('i18nService');
  }

  getFeedbackService(): FeedbackService {
    return this.getService('feedbackService');
  }

  getFieldsService(): FieldsService {
    return this.getService('fieldsService');
  }

  /**
   * @return {FilterConverterService}
   */
  getFilterConverterService(): FilterConverterService {
    return this.getService('filterConverterService');
  }

  /**
   * @return {FilterService}
   */
  getFilterService(): FilterService {
    return this.getService('filterService');
  }

  getConfigurationService(): ConfigurationService {
    return this.getService('configurationService');
  }

  /**
   * @return {SatService}
   */
  getSatService(): SatService {
    return this.getService('satService');
  }

  /**
   * @return {SecurityService}
   */
  getSecurityService(): SecurityService {
    return this.getService('securityService');
  }

  /**
   * @return {TimeZoneService}
   */
  getTimeZoneService(): TimeZoneService {
    return this.getService('timeZoneService');
  }

  getTopicsStatService(): TopicsStatService {
    return this.getService('topicsStatService');
  }

  getSequencesService(): SequencesService {
    return this.getService('sequencesService');
  }

  /**
   * @return {UrlService}
   */
  getUrlService(): UrlService {
    return this.getService('urlService');
  }

  /**
   * @return {FetcherService}
   */
  getFetcherService(): FetcherService {
    return this.getService('fetcherService');
  }

  /**
   * @return {DashboardService}
   */
  getDashboardService():DashboardService {
    return this.getService('dashboardService');
  }

  /**
   * @return {DashboardExportService}
   */
  getDashboardExportService():DashboardExportService {
    return this.getService('dashboardExportService');
  }

  /**
   * @return {DashboardParamsService}
   */
  getDashboardParamsService(): DashboardParamsService {
    return this.getService('dashboardParamsService');
  }

  /**
   * @return {AdministrationService}
   */
  getAdministrationService():AdministrationService {
    return this.getService('administrationService');
  }

  /**
   * @return {StorageService}
   */
  getStorageService():StorageService {
    return this.getService('storageService');
  }

  getSourcingService(): SourcingService {
    return this.getService('sourcingService');
  }

  getSourcingErrorService(): SourcingErrorService {
    return this.getService('sourcingErrorService');
  }

  getProgressService(): ProgressService {
    return this.getService('progressService');
  }

  getKafkaService(): KafkaService {
    return this.getService('kafkaService');
  }

  getTenantService(): TenantService {
    return this.getService('tenantService');
  }

  /**
   * @return {SystemService}
   */
  getSystemService(): SystemService {
    return this.getService('systemService');
  }

  /**
   * @return {CacheService}
   */
  getCacheService():CacheService {
    return this.getService('cacheService');
  }

  /**
   * @return {PurgeService}
   */
  getPurgeService():PurgeService  {
    return this.getService('purgeService');
  }

  getConfigurationManagerService():ConfigurationManagerService  {
    return this.getService('configurationManagerService');
  }

  getExportService():ExportService  {
    return this.getService('exportService');
  }

  getDataIntegrationService(): DataIntegrationService {
    return this.getService('dataIntegrationService');
  }

  getColorService(): ColorService {
    return this.getService('colorService');
  }
}

export const services = new Services();