import { Button, Icon } from '@blueprintjs/core';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import SmallLogo from '../../assets/vecko_c_white_small.png';
import LargeLogo from '../../assets/vecko_full_white_small.png';
import { InfiniteCache } from '../../utils/InfiniteCache';
import './mainMenu.scss';
import { MainMenuItem } from './MainMenuItem';
import _ from 'lodash';
import { Anchor } from "../../component/nav/NavItem";
import { services } from "../service/services";

class MainMenuComponent extends React.PureComponent<WithTranslation> {
  closingTimerId: NodeJS.Timeout|null;

  onMouseEnterHandlers = new InfiniteCache((navItem) => () => {
    if (this.closingTimerId) {
      clearTimeout(this.closingTimerId);
      this.closingTimerId = null;
    }

    const newExpandedItem = this.isOpen() ? this.state.currentExpandedItem : navItem;

    this.setState({isOpen: true, currentExpandedItem: newExpandedItem})
  });
  onExpandHandlers = new InfiniteCache((navItem) => () => {
    this.setState({currentExpandedItem: navItem})
  });

  state = {
    isOpen: false,
    currentExpandedItem: null
  };

  componentWillUnmount() {
    if (this.closingTimerId) {
      clearTimeout(this.closingTimerId);
      this.closingTimerId = null;
    }
  }

  isOpen() {
    return this.state.isOpen;
  }

  doClose() {
    this.setState({ isOpen: false, currentExpandedItem: null });
  }

  close(delay) {
    if (delay) {
      this.closingTimerId = _.delay(() => {
        this.doClose();
      }, 500) as any;
    } else {
      this.doClose();
    }
  }

  onMouseLeave = () => {
    this.close(true);
  };

  onItemClick = () => {
    this.close(false);
  };

  collapse = () => {
    this.setState({currentExpandedItem: null})
  };

  /**
   * @param {NavItem} navItem
   */
  renderItem(navItem) {
    return <MainMenuItem key={navItem.to}
                         navItem={navItem}
                         isOpen={this.isOpen()}
                         isExpanded={this.state.currentExpandedItem === navItem}
                         onClick={this.onItemClick}
                         onMouseEnter={this.onMouseEnterHandlers.get(navItem)}
                         onExpand={this.onExpandHandlers.get(navItem)}
                         onCollapse={this.collapse}
    />;
  }

  onLogout = () => {
    services.getSecurityService().getAuth().logout();
  };

  render() {
    const {t} = this.props;
    const isOpen = this.isOpen();

    const topItems = services.getApplicationService().mainNav.items
        .filter(it => it.anchor === Anchor.TOP);
    const bottomItems = services.getApplicationService().mainNav.items
        .filter(it => it.anchor === Anchor.BOTTOM);

    const className = `vui-layout--vertical vui-mainMenu ${isOpen ? 'open' : ''}`;

    return <div className={className} onMouseLeave={this.onMouseLeave}>
      {/*logo*/}
      <div className='vui-mainMenu-logo'>
        <img src={isOpen ? LargeLogo : SmallLogo} alt='Vecko'/>
      </div>

      <div className='vui-layout--vertical vui-layout--vertical__center'>
        {
          topItems.map(it => this.renderItem(it))
        }
        <div className='vui-layout--vertical__center'/>
        {
          bottomItems.map(it => this.renderItem(it))
        }
        {
          <div className='vui-layout--horizontal vui-mainMenu-item'>
            <div className='vui-layout--horizontal__center'>
              <Button large={true}
                      icon={<Icon icon='log-out' iconSize={24}/>}
                      minimal={true}
                      onClick={this.onLogout}
              />
            </div>
          </div>
        }
      </div>

      {/*copyright*/}
      {
        isOpen ? <div className='vui-mainMenu-copyright'>
          {t('application.copyright')}
        </div> : null
      }
    </div>;
  }
}

export const MainMenu = withTranslation()(MainMenuComponent);