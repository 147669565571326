import { parseCondition } from '../parse/parseCondition';
import { parseParamValue } from '../parse/parseParamValue';
import { NoneParamValue } from './NoneParamValue';
import { ParamValue } from './ParamValue';
import { TFunction } from "i18next";
import { NotImplementedError } from "../../../../utils/errors/NotImplementedError";
import _ from "lodash";
import { Condition } from "../condition/Condition";

export class SwitchParamValue extends ParamValue {
  static parse(object) {
    if (_.isEmpty(object.cases)) {
      throw new Error('switch param should have at least one switch case')
    }

    return new SwitchParamValue(object.cases.map(SwitchCase.parse));
  }

  private readonly cases: Array<SwitchCase>;

  constructor(cases: Array<SwitchCase>) {
    super('switch');
    this.cases = cases;
  }

  getId(): string {
    throw new NotImplementedError();
  }

  getLabel(t: TFunction): string {
    throw new NotImplementedError();
  }

  getValueAsPlainObject(): any {
    throw new NotImplementedError();
  }

  asPlainObject(): any {
    return {
      ... super.asPlainObject(),
      cases: this.cases.map(c => c.asPlainObject())
    };
  }

  isValid(): boolean {
    throw new NotImplementedError();
  }

  resolve(state: any): ParamValue {
    for (let switchCase of this.cases) {
      if (switchCase.condition.matches(state)) {
        return switchCase.returns.resolve(state);
      }
    }
    return new NoneParamValue();
  }
}

class SwitchCase {
  static parse(object):SwitchCase {
    if (_.isNil(object.condition)) {
      throw new Error('switch case condition is mandatory');
    }
    if (_.isNil(object.returns)) {
      throw new Error('switch case returns is mandatory');
    }

    return new SwitchCase(parseCondition(object.condition), parseParamValue(object.returns))
  }

  readonly condition: Condition;
  readonly returns: ParamValue;

  constructor(condition, returns) {
    this.condition = condition;
    this.returns = returns;
  }

  asPlainObject(): any {
    return {
      condition: this.condition.asPlainObject(),
      returns: this.returns.asPlainObject()
    };
  }
}