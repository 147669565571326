import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {SatTypeEnum} from '../../../../application/model/SatTypeEnum';
import {DateLabel} from '../../../../component/dateLabel/DateLabel';
import {VisualizationInstance} from '../../../model/viz/VisualizationInstance';
import {GaugeChart} from '../../components/gauge';
import {SatDistribution} from '../../components/SatDistribution';
import './satScoreViz.scss';
import {FormDialog} from "../../../../component/dialog/FormDialog";
import {FormGroup, NumericInput} from "@blueprintjs/core";
import {connect} from "react-redux";
import {Tonality} from '@eptica/vecko-js-commons';
import {ResolvedParams} from "../../../model/params/resolve/ResolvedParams";
import {getDashboardResolvedParams} from "../../../state/selectors/dashboardSelector";

type Distribution = { [key: string]: number }

interface SatScoreItemProps extends WithTranslation {
    params: any,
    title: string,
    uiParams: any,
    count: number,
    countLabel: string,
    value: number,
    trend?: number,
    distribution?: Distribution,
    showParticipants?: boolean
    updateItemData?: (values: { count: number, value: number, trend: number, distribution: Distribution }) => void
}

interface SatScoreVizProps extends WithTranslation {
    viz: VisualizationInstance,
    resolvedParams: ResolvedParams,
    updateVizData: (vizId, data) => void
}

interface StateProps {
    isDialogOpen: boolean;
    newCount: number,
    newValue: number,
    newTrend?: number,
    newDistribution?: Distribution,
    newNeutral?: number,

}


class SatScoreItemComponent extends React.PureComponent<SatScoreItemProps> {
    static defaultProps = {
        params: {},
        uiParams: {},
        showParticipants: false
    };

    state: StateProps = {
        isDialogOpen: false,
        newValue: null,
        newCount: null
    }

    renderDistribution() {
        const {distribution, count, uiParams} = this.props;

        return <SatDistribution distribution={distribution}
                                total={count}
                                showNeutral={_.get(uiParams, 'distribution.showNeutral')}
                                showPercent={_.get(uiParams, 'distribution.showPercent')}
                                showVolume={_.get(uiParams, 'distribution.showVolume')}
        />;
    }

    /**
     * @return {boolean}
     */
    isDistributionEnabled() {
        const {uiParams} = this.props;
        return _.get(uiParams, 'distribution.enabled') === true;
    }

    onValueClick(event) {
        this.setState({
            isDialogOpen: true,
            newValue: this.props.value,
            newTrend: this.props.trend,
            newCount: this.props.count,
            newDistribution: {...this.props.distribution}
        });
    }

    getNewDistribution(tonality: Tonality, newValue: number) {
        const newDistribution = {...this.state.newDistribution};
        newDistribution[tonality.name()] = newValue;
        return newDistribution;
    }

    render() {
        const {params, uiParams, value, trend, count, countLabel, showParticipants, t} = this.props;

        const satType = SatTypeEnum.valueOf(params.satType);
        const tonalities = [
            Tonality.NEGATIVE,
            Tonality.NEUTRAL

        ];


        return <div className="satScore">
            {this.state.isDialogOpen ?
                <FormDialog isOpen={this.state.isDialogOpen}
                            title={this.props.title}
                            onCancel={props => this.setState({isDialogOpen: false})}
                            onValidate={props => {
                                this
                                this.props.updateItemData({
                                    value: this.state.newValue,
                                    trend: this.state.newTrend,
                                    count: this.state.newCount,
                                    distribution: this.state.newDistribution
                                });
                                this.setState({isDialogOpen: false})
                            }}>
                    <FormGroup
                        label={t('dashboard.viz.satScore.edit-dialog.count')}
                        labelFor="value-input">
                        <NumericInput id="value-input" value={this.state.newValue} className={"numericInput"} max={100}
                                      onValueChange={newValue => this.setState({newValue})}></NumericInput>
                    </FormGroup>
                    <FormGroup
                        label={t('dashboard.viz.satScore.edit-dialog.trend')}
                        labelFor="trend-input">
                        <NumericInput id="trend-input" value={this.state.newTrend} className={"numericInput"} max={100}
                                      onValueChange={newValue => this.setState({newTrend: newValue})}></NumericInput>
                    </FormGroup>
                    {
                        <>
                            <div
                                style={{marginBottom: "15px"}}>{t('dashboard.viz.satScore.edit-dialog.participants')}&nbsp;{this.state.newCount}</div>
                            <FormGroup
                                label={t('dashboard.viz.satScore.edit-dialog.distribution.negative')}
                                labelFor="participant-input">
                                <NumericInput id="distribution-negative-input"
                                              value={this.state.newDistribution[Tonality.NEGATIVE.name()]}
                                              className={"distributionInput"}
                                              onValueChange={newValue => this.setState({
                                                  newDistribution: this.getNewDistribution(Tonality.NEGATIVE, newValue),
                                                  newCount: this.state.newCount - this.state.newDistribution[Tonality.NEGATIVE.name()] + newValue
                                              })}></NumericInput>
                                &nbsp;({this.renderPercent(this.state.newDistribution[Tonality.NEGATIVE.name()], this.state.newCount)})
                            </FormGroup>
                            <FormGroup
                                label={t('dashboard.viz.satScore.edit-dialog.distribution.neutral')}
                                labelFor="distribution-neutral-input">
                                <NumericInput id="distribution-neutral-input"
                                              value={this.state.newDistribution[Tonality.NEUTRAL.name()]}
                                              className={"distributionInput"}
                                              onValueChange={newValue => this.setState({
                                                  newDistribution: this.getNewDistribution(Tonality.NEUTRAL, newValue),
                                                  newCount: this.state.newCount - this.state.newDistribution[Tonality.NEUTRAL.name()] + newValue
                                              })}>
                                </NumericInput>
                                &nbsp;({this.renderPercent(this.state.newDistribution[Tonality.NEUTRAL.name()], this.state.newCount)})

                            </FormGroup>
                            <FormGroup
                                label={t('dashboard.viz.satScore.edit-dialog.distribution.positive')}
                                labelFor="distribution-positive-input">
                                <NumericInput value={this.state.newDistribution[Tonality.POSITIVE.name()]}
                                              className={"distributionInput"}
                                              onValueChange={newValue => this.setState({
                                                  newDistribution: this.getNewDistribution(Tonality.POSITIVE, newValue),
                                                  newCount: this.state.newCount - this.state.newDistribution[Tonality.POSITIVE.name()] + newValue
                                              })}></NumericInput>
                                &nbsp;({this.renderPercent(this.state.newDistribution[Tonality.POSITIVE.name()], this.state.newCount)})
                            </FormGroup>
                        </>
                    }


                </FormDialog> : null
            }
            <div className='vui-layout--vertical__center vui-layout--vertical'>
                <GaugeChart value={this.props.value} trend={this.props.trend} min={satType.min} max={satType.max}
                            onDoubleClick={this.onValueClick.bind(this)}
                            arcOpacity={uiParams.arcOpacity} valueStyle={uiParams.valueStyle}/>
            </div>

            {
                showParticipants ?
                    <div className="satScore_count">
                        <span className="satScore_count_value">{this.props.count}</span> {countLabel}
                    </div>
                    : null
            }

            {
                this.isDistributionEnabled() ? this.renderDistribution() : null
            }
        </div>;
    }

    renderPercent(volume: number, total: number) {
        if (total === 0) return '-';
        return _.round(volume / total * 100, 2) + '%';
    }
}

const SatScoreItem = withTranslation(undefined, {withRef: true})(SatScoreItemComponent)

class SatScoreVizComponent extends React.PureComponent<SatScoreVizProps> {
    /**
     * @return {boolean}
     */
    isComparisonEnabled() {
        const {viz} = this.props;

        return _.get(viz.definition.params, ['comparison', 'enabled']) === true && viz.data.comparison;
    }

    render() {
        const {t, viz} = this.props;
        let satScoreItemTitle = this.props.viz.getTitle(t, this.props.resolvedParams);
        if (this.isComparisonEnabled()) {
            satScoreItemTitle += t('dashboard.viz.satScore.edit-dialog.title.period', {index: 1});
        }

        const periodSatScore = <SatScoreItem uiParams={viz.definition.uiParams}
                                             params={viz.definition.params}
                                             value={viz.data.value}
                                             trend={viz.data.trend}
                                             count={viz.data.count}
                                             countLabel={t('dashboard.viz.satScore.count')}
                                             distribution={viz.data.distribution}
                                             showParticipants={_.get(viz.definition.uiParams, 'distribution.showParticipants')}
                                             title={satScoreItemTitle}
                                             updateItemData={newData => {
                                                 this.props.updateVizData(this.props.viz.id, {
                                                     ...viz.data,
                                                     value: newData.value,
                                                     trend: newData.trend,
                                                     count: newData.count,
                                                     distribution: newData.distribution
                                                 })
                                             }}/>;

        if (this.isComparisonEnabled()) {
            return <div className='vui-layout--horizontal__center vui-layout--horizontal'
                        style={{justifyContent: 'space-evenly'}}>
                <div className='vui-layout--vertical vui-layout--horizontal__center'>
                    <div className='satScorePeriod'><DateLabel date={viz.data.from}/> - <DateLabel date={viz.data.to}/>
                    </div>
                    {periodSatScore}
                </div>
                <div style={{width: 20}}/>
                <div className='vui-layout--vertical vui-layout--horizontal__center'>
                    <div className='satScorePeriod'><DateLabel date={viz.data.comparison.from}/> - <DateLabel
                        date={viz.data.comparison.to}/></div>
                    <SatScoreItem uiParams={viz.definition.uiParams} params={viz.definition.params}
                                  value={viz.data.comparison.value} trend={viz.data.comparison.trend}
                                  count={viz.data.comparison.count}
                                  countLabel={t('dashboard.viz.satScore.count')}
                                  distribution={viz.data.comparison.distribution}
                                  showParticipants={_.get(viz.definition.uiParams, 'distribution.showParticipants')}
                                  title={this.props.viz.getTitle(t, this.props.resolvedParams) + t('dashboard.viz.satScore.edit-dialog.title.period', {index: 2})}
                                  updateItemData={newData => {
                                      this.props.updateVizData(this.props.viz.id, {
                                          ...viz.data,
                                          comparison: {
                                              ...viz.data.comparison,
                                              value: newData.value,
                                              trend: newData.trend,
                                              count: newData.count,
                                              distribution: newData.distribution
                                          }
                                      })
                                  }}
                    />
                </div>
            </div>;

        } else {
            return periodSatScore;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        resolvedParams: getDashboardResolvedParams(state),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        updateVizData: (vizId, data) => {
            dispatch.dashboardData.setVizData({vizId: vizId, data: data});
        }
    };
};
export const SatScoreViz = withTranslation(undefined, {withRef: true})(connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(SatScoreVizComponent));

SatScoreViz.propTypes = {
    viz: PropTypes.instanceOf(VisualizationInstance).isRequired
};
