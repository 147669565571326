import React from "react";
import { AnchorButton, Button, Collapse, Pre } from "@blueprintjs/core";
import _ from "lodash";
import { SeparatedComponents } from "../../../component/SeparatedComponents";
import { BlankSeparator } from "../../../component/BlankSeparator";
import copy from "copy-to-clipboard";
import { IMessageDetail } from "./MessageTypes";

interface MessageContentState {
    detailOpened: boolean
}

interface MessageContentProps {
    message: string,
    details: IMessageDetail
}

export class MessageContent extends React.PureComponent<MessageContentProps, MessageContentState> {
    state = {
        detailOpened: false
    };

    onDetailButtonClick = () => {
        this.setState({detailOpened: !this.state.detailOpened});
    };

    renderPre(value) {
        return <Pre className="vui-layout--vertical__center vui-layout--vertical--scrollable vui-horizontal-scroll">
            {_.isObject(value) ? JSON.stringify(value, null, 2) : value}
        </Pre>;
    }

    renderDetailItems(items) {
        if (items == null || items.length === 0) {
            return null;
        }

        return <div className='vui-layout--vertical'>
            <SeparatedComponents separator={<BlankSeparator vertical={true}/>}>
                {
                    items.map(di => <div className='vui-layout--vertical' key={di.name}>
                        {<div className='message-detail-item-label'>{di.name}</div>}
                        {this.renderPre(di.value)}
                    </div>)
                }
            </SeparatedComponents>
        </div>;
    }

    onCopyClick = () => {
        const {message, details} = this.props;
        const value = {message, details};
        copy(JSON.stringify(value, null, 2));
    };

    renderDetails() {
        const {message, details} = this.props;

        return <div className='vui-layout--vertical'>
            <div className='vui-layout--horizontal' style={{alignItems: 'center'}}>
                <AnchorButton icon={this.state.detailOpened ? 'chevron-up' : 'chevron-down'}
                              minimal={true}
                              className={'message-collapse-button'}
                              onClick={this.onDetailButtonClick}/>
                <span className='vui-layout--horizontal__center'>{message}</span>
            </div>

            <Collapse isOpen={this.state.detailOpened}>
                <SeparatedComponents separator={<BlankSeparator vertical={true} size={15}/>}>
                    <div className='message-detail-message vui-layout--horizontal'>
                        <div className='vui-layout--horizontal__center'>{details.message}</div>
                        <Button icon={'clipboard'} minimal={true} onClick={this.onCopyClick}>Copy</Button>
                    </div>
                    {this.renderDetailItems(details.items)}
                </SeparatedComponents>
            </Collapse>
        </div>;
    }

    render() {
        const {message, details} = this.props;

        if (details) {
            return this.renderDetails();
        }

        return message;
    }
}