import { Offset } from "../../application/model";

export function overlap(o1: Offset, o2: Offset) {
  return (o1.start < o2.end && o2.start < o1.end);
}

export function union(o1: Offset, o2: Offset): Offset {
  return {
    start: Math.min(o1.start, o2.start),
    end: Math.max(o1.end, o2.end)
  };
}

export function translateOffset(offset: Offset, amount: number) {
  return {
    ...offset,
    start: offset.start + amount,
    end: offset.end + amount
  };
}