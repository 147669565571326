import axios from "axios";

const getConfiguration = () => {
  return axios
    .get('/api/di/vecko')
    .then(response => response.data);
}


const saveConfiguration = (configuration) => {
  const json = JSON.stringify(configuration);
  return axios
    .post('/api/di/vecko', json, {
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
}

const getApis = () => {
  return axios
    .get('/api/di/api')
    .then(response => response.data);
}

const saveApi = (api) => {
  const json = JSON.stringify(api);
  return axios
    .post('/api/di/api', json, {
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
}

const deleteApi = (api: string, id: number) => {
  return axios
    .delete(`/api/di/api/${api}/${id}`)
    .then(response => response.data);
}

const getIntervals = (api: string) => {
  return axios
    .get(`/api/di/interval/${api}`)
    .then(response => response.data);
}

const deleteIntervals = (api: string) => {
  return axios
    .delete(`/api/di/interval/${api}`)
    .then(response => response.data);
}

export default {
  getConfiguration,
  saveConfiguration,
  getApis,
  saveApi,
  deleteApi,
  getIntervals,
  deleteIntervals,
}