import React from 'react';
import { SmartIcon } from '../../../../component/icon/SmartIcon';
import { signed } from '../../utils/text_transform';
import { trendSpec } from './trendSpec';
import { Tooltip } from "@blueprintjs/core";

interface TrendIconProps {
  value: number,
  size?: number,
  withTooltip?: boolean
}

export class TrendIcon extends React.PureComponent<TrendIconProps> {
  static defaultProps: Partial<TrendIconProps> = {
    withTooltip: true
  }

  render() {
    const { value, size, withTooltip } = this.props;

    const trend = trendSpec(value);
    const icon = <SmartIcon icon={trend.iconPath} color={trend.color} size={size}/>;

    return withTooltip ? <Tooltip content={signed(value)}>{icon}</Tooltip> : icon;
  }
}
