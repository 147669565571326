import _ from 'lodash';

const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
export const randomString = (len = 10) => {
  let text = '';
  for (let i = 0; i < len; i++) {
    text += randomPick(possible);
  }
  return text;
};

export const randomPick = (array) => {
  return array[_.random(0, array.length - 1)];
};

export const randomPicks = (array, count) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(_.cloneDeep(randomPick(array)));
  }
  return result;
};