import { ParseError } from '../../../../utils/errors/ParseError';
import { DateRangeParam } from '../DateRangeParam';
import { FieldAndValueSelectorParam } from '../FieldAndValueSelectorParam';
import { Param } from '../Param';
import { SelectorParam } from '../SelectorParam';
import { ParamValue } from "../values/ParamValue";
import _ from "lodash";

const doParse = (reportId: string, type: string, object: any): Param<any> => {
  if (type === 'selector') {
    return SelectorParam.parse(reportId, object);
  }
  if (type === 'fieldAndValueSelector') {
    return FieldAndValueSelectorParam.parse(reportId, object)
  }
  if (type === 'dateRange') {
    return DateRangeParam.parse(reportId, object)
  }

  throw new ParseError(`Cannot parse object with type ${type} into a Param object`);
};

export const parseParam = <T extends ParamValue>(object: any, reportId: string): Param<T> => {
  const type = object['@type'];

  if (_.isNil(type)) {
    throw new ParseError('No @type defined when parsing Param');
  }

  return Param.applyCommonsDefinition(doParse(reportId, type, object), object);
};