import { ParamValue } from './ParamValue';
import { TFunction } from "i18next";

export class NoneParamValue extends ParamValue {
  static parse(object?: any): NoneParamValue {
    return new NoneParamValue();
  }

  constructor() {
    super('none');
  }

  getId(): string {
    return 'none';
  }

  getLabel(t: TFunction): string {
    return '?';
  }

  getValueAsPlainObject(): null {
    return null;
  }

  isValid(): boolean {
    return true;
  }

  equals(paramValue: ParamValue): boolean {
    return paramValue instanceof NoneParamValue;
  }
}