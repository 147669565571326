import { ApiFilter } from './ApiFilter';
import _ from "lodash";

export class DateRangeFilter extends ApiFilter {
  static parse(object) {
    return new DateRangeFilter(object.field, object.zoneId, object.start, object.end);
  }
  field:string;
  start:string;
  end:string;
  zoneId:string;

  constructor(field:string, zoneId:string, start:string, end:string) {
    super('dateRange');
    this.field = field;
    this.zoneId = zoneId;
    this.start = start;
    this.end = end;
  }

  toPlainObject() {
    if (_.isEmpty(this.field)) {
      throw new Error('field is mandatory')
    }

    const result:any = super.toPlainObject();
    result.field = this.field;
    result.zoneId = this.zoneId;
    result.start = this.start;
    result.end = this.end;
    return result;
  }

  getCopy():ApiFilter {
    const apiFilter = new DateRangeFilter(this.field, this.zoneId, this.start, this.end);
    return apiFilter;
  }
}