import _ from 'lodash';
import { Tonality } from '@eptica/vecko-js-commons';
import { Kpi } from './Kpi';
import { ProgressItem } from '../../../component/ProgressItem';
import { FieldValue } from "../field/FieldValue";
import React from "react";
import { SatType } from "../SatTypeEnum";
import { DateTime } from 'luxon';
import { KpiEvolution } from "./KpiInstance";
import {ChartType} from "../../../utils/chart/SerieChartBuilder";

export class CountBySatPopulationAndCategoriesKpi extends Kpi {
  readonly satType: SatType;
  readonly satMood: Tonality;
  readonly categories: Array<FieldValue>;
  readonly tree: string;

  constructor(name, satType: SatType, satMood: Tonality, tree: string, categories: Array<FieldValue>) {
    super(name);
    this.satType = satType;
    this.satMood = satMood;
    this.categories = categories;
    this.tree = tree;
  }

  getType() {
    return 'countBySatPopulationAndCategories';
  }

  getLabel(kpiInstance, t) {
    return _.capitalize(t('kpi.countBySatPopulationAndCategories.label', {satType: this.satType.getAliasedName(), satMood: this.satMood.name()}));
  }

  getNumber(kpiInstance, t) {
    return t('kpi.countBySatPopulationAndCategories.number', {count: _.round(kpiInstance.data.percent, 2)});
  }

  getLegend(kpiInstance, t) {
    return t('kpi.countBySatPopulationAndCategories.legend', {satType: this.satType.getAliasedName(), satMood: this.satMood.name()});
  }

  getIcon(kpiInstance) {
    return this.satType.icon;
  }

  getColor(kpiInstance) {
    return this.satMood.color;
  }

  getEvolution(kpiInstance, t): KpiEvolution {

    return {
      type: ChartType.TIME_BAR,
      yAxis: {
        autoAdapted: { min:null, max:100 },
        min: 0,
        tickLabelCallback: (value) => {
          return `${value} %`;
        }
      },
      data: kpiInstance.data.evolution.map(e => ({x: DateTime.fromMillis(e.date).toJSDate(), y: e.value})),
      tooltipRenderer: (value) => {
        return <ProgressItem label={this.getLabel(kpiInstance, t)} color={this.satMood.color} value={value}/>;
      }
    };
  }

  getApiParams() {
    return {
      satType: this.satType.name,
      satMood: this.satMood.name(),
      tree: this.tree,
      categories: this.categories.map(c => c.fullName)
    };
  }
}