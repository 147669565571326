import {FormGroup, InputGroup, Switch, Tab, Tabs} from '@blueprintjs/core';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {services} from '../../../../application/service/services';
import {StringListEditor} from '../../../../component/StringListEditor';
import {actionNamespacer} from '../../../state/utils/actionNamespacer';
import {FeedbackSelector} from '../../components/FeedbackSelector';
import './standardProfileEditor.scss';
import {dispatchSlice, stateSlice} from './standardProfileEditorState';

interface StandardProfileEditorProps extends WithTranslation {
    init: any,
    onValueChanged: any,
    setPage: any,
    onSelectedTopicsChanged: any,
    setSelectedTopics: any,
    selectedTopics: any,
    editorData: any,
    viz: any,
    feedbacks: any,
    loadingFeedbacks: any,
    page: any,
    pageSize: any,
    total: any

}

class StandardProfileEditorComponent extends React.PureComponent<StandardProfileEditorProps> {
    componentWillMount() {
        this.props.init();
    }

    onFeedbackSelect = (value) => {
        this.props.onValueChanged('data', 'feedback', value);
    };

    onFeedbackTitleChange = (event) => {
        this.props.onValueChanged('data', 'feedbackTitle', event.target.value);
    };

    onProfileItemsChanged = (value) => {
        this.props.onValueChanged('data', 'profileItems', value);
    };

    onPageChange = (pageIndex) => {
        this.props.setPage(pageIndex);
    };

    onSelectedTopicsChanged = (topic) => {
        if (this.isSelected(topic)) {
            this.props.setSelectedTopics(this.props.selectedTopics.filter(t => t !== topic));
        } else {
            this.props.setSelectedTopics([...this.props.selectedTopics, topic]);
        }
    };

    isSelected(topic) {
        return this.props.selectedTopics.includes(topic);
    }

    render() {
        const {t, editorData, viz, feedbacks, loadingFeedbacks, page, pageSize, total} = this.props;
        const bestTopics = viz.data.bestTopics;

        const pagination = {
            current: page,
            total: total,
            pageSize: pageSize,
            onChange: this.onPageChange
        };

        const feedbackTab = <div className='standardProfileEditor--tabContent'>
            <FormGroup inline={true} style={{justifyContent: 'center'}}>
                {
                    bestTopics.map(topic => {
                        return <Switch key={topic}
                                       inline={true}
                                       checked={this.isSelected(topic)}
                                       label={services.getFieldsService().findCategoryLabel('topic', topic, t)}
                                       onChange={this.onSelectedTopicsChanged.bind(this, topic)}/>;
                    })
                }
            </FormGroup>

            <FeedbackSelector
                items={feedbacks}
                pagination={pagination}
                fetchingData={loadingFeedbacks}
                selectedFeedbackId={editorData?.data?.feedback?.id}
                onSelectFeedback={this.onFeedbackSelect}
            />
            <FormGroup label={t('dashboard.viz.standardProfile.editor.feedbackTitle')} labelFor='feedbackTitle'>
                <InputGroup id="comment" value={editorData?.data?.feedbackTitle} onChange={this.onFeedbackTitleChange}/>
            </FormGroup>
        </div>;

        const profileTab = <StringListEditor
            className='tab-content'
            addValueFieldLabel={t('dashboard.viz.standardProfile.editor.profileItem')}
            values={editorData?.data?.profileItems || []}
            onChange={this.onProfileItemsChanged}/>;

        return <Tabs large={true}>
            <Tab id='feedback-tab'
                 title={t('dashboard.viz.standardProfile.editor.feedback')}
                 panel={feedbackTab}/>
            <Tab id='profile-tab' title={t('dashboard.viz.standardProfile.editor.profile')} panel={profileTab}/>
        </Tabs>;
    }
}

const mapStateToProps = state => {
    return stateSlice(state);
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const wrapActionPayload = actionNamespacer(ownProps.viz.id);
    dispatch = dispatchSlice(dispatch);

    return {
        init: () => {
            dispatch.init(wrapActionPayload());
        },
        setPage: pageIndex => {
            dispatch.changePage(wrapActionPayload(pageIndex));
        },
        setSelectedTopics: selectedTopics => {
            dispatch.changeSelectedTopics(wrapActionPayload(selectedTopics));
        }
    };
};


export const StandardProfileEditor = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(StandardProfileEditorComponent));
