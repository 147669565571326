export class StoragePath {

  /**
   *
   * @param {string} string
   */
  static parse(string) {
    if (string === '') {// root
      return new StoragePath(string, true);
    }
    return new StoragePath(string, string.endsWith('/'));
  }

  /**
   * @type {string}
   */
  path;

  /**
   * @type {boolean}
   */
  isDirectory;


  constructor(path, isDirectory) {
    this.path = path;
    this.isDirectory = isDirectory;
  }

  isDir() {
    return this.isDirectory;
  }

  isFile() {
    return !this.isDirectory;
  }

  child(string) {
    if (string === '') {
      throw new Error('cannot create empty child')
    }
    if (this.path === '') {
      return StoragePath.parse(string);
    }
    return StoragePath.parse(this.path + '/' + string);
  }


}