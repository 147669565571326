import _ from 'lodash';
import { TonalitiesDistribution } from '../../application/model/distribution/TonalitiesDistribution';
import { FeedbacksListViewModeEnum } from '../../application/model/FeedbacksListViewModeEnum';
import { FieldDisplay } from '../../application/model/field/FieldDisplay';
import { services } from '../../application/service/services';
import { feedbacksListState } from '../../application/store/state/tools/feedbacksListState';
import { Highlight } from '../../utils/query/Highlight';
import { getDashboardResolvedParams } from './selectors/dashboardSelector';

const fls = feedbacksListState(
  'dashboard',
  FieldDisplay.FieldDisplayKind.DASHBOARD_VIEW,
  {
    includeGlobalFilter: false,
    queryBuilderDecorator: (query, rootState) => {
      const vizQuery = rootState.dashboard.vizQuery;


      //create a copy of filter because filter can change after  that and we need to keep the initial filter
      query.filter = vizQuery.filter ? vizQuery.filter.getCopy() : null;
      query.aspects = vizQuery.aspects;
      query.highlight = vizQuery.highlight;

      if (rootState.dashboard.feedbackListDistributionItem) {
        const clickedFieldValue = rootState.dashboard.feedbackPanelViz.getClickedFieldValue();
        TonalitiesDistribution.get().decorateFeedbackQuery(query, clickedFieldValue,
          rootState.dashboard.feedbackListDistributionItem);
      }
    }
  });


export const dashboardState = () => ({
  state: {
    ...fls.state,
    feedbackPanelOpen: false,
    feedbackPanelViz: null,
    rightPaneMode: FeedbacksListViewModeEnum.FEEDBACKS
  },
  reducers: {
    ...fls.reducers,
    setFeedbackPanelOpen(state, payload) {
      state.feedbackPanelOpen = payload;
      return state;
    },
    setFeedbackPanelViz(state, payload) {
      state.feedbackPanelViz = payload;
      return state;
    },
    setFeedbackPanelTitle(state, payload) {
      state.feedbackPanelTitle = payload;
      return state;
    },
    setFeedbackListDistributionItem(state, payload) {
      state.feedbackListDistributionItem = payload;
      return state;
    },
    setId(state, payload) {
      state.id = payload;
      return state;
    },
    setResolvedParams(state, payload) {
      state.resolvedParams = payload;
      return state;
    },
    setResolvedSpec(state, payload) {
      state.resolvedSpec = payload;
      return state;
    },
    setVizResolvedSpec(state, payload) {
      if (state.resolvedSpec) {
        const vizSpecState = state.resolvedSpec.visualizations.find(v => v.id === payload.vizId);
        vizSpecState.params = _.assign({}, vizSpecState.params, payload.spec.params);
        vizSpecState.uiParams = _.assign({}, vizSpecState.uiParams, payload.spec.uiParams);
      }
      return state;
    },
    setVizQuery(state, payload) {
      state.vizQuery = payload;
      return state;
    },
    setRightPaneMode(state, payload) {
      state.rightPaneMode = payload;
      return state;
    },
    setSequences(state, payload) {
      state.sequences = payload;
      return state;
    },
  },
  effects: (dispatch) => ({
    ...fls.effects(dispatch),
    init(payload, rootState) {
      dispatch.dashboardData.reset();
      dispatch.dashboard.setId(payload);
      dispatch.dashboard.setResolvedParams(null);
      dispatch.dashboard.setResolvedSpec(null);
      dispatch.dashboardParams.init(payload);
    },
    async refresh(payload, rootState) {
      await dispatch.dashboardParams.applyParams(null);
    },
    async openFeedBackPanel(payload, rootState) {
      dispatch.dashboard.setFeedbackPanelViz(payload.viz);
      dispatch.dashboard.setFeedbackPanelTitle(payload.panelTitle);
      dispatch.dashboard.setFeedbackPanelOpen(true);
      dispatch.dashboard.setSequences(null);
      dispatch.dashboard.reset();
      if (open) {
        const vizQuery = await services.getDashboardService()
          .getFeedBacksQuery(payload.viz.props.viz, getDashboardResolvedParams(rootState), null,
            payload.viz.getFeedBacksQueryContext());

        const clickedFieldValue = payload.viz.getClickedFieldValue();
        if (clickedFieldValue && clickedFieldValue.field.isCategoryTree()) {
          /* The clicked data corresponds to a fieldValue and this one is a category tree.
           * Set highLight on the query to highlight parts of the verbatim corresponding to the fieldValue
           */
          vizQuery.highlight = new Highlight(false, [clickedFieldValue.field.tree]);
        }

        dispatch.dashboard.setVizQuery(vizQuery);
        //reset distribution item
        dispatch.dashboard.setFeedbackListDistributionItem(null);

        //fetch feedbacks and sequences
        const feedbacks = await dispatch.dashboard.fetchFeedbacks(true);
        const sequences = await dispatch.dashboard.fetchSequences();
        await Promise.all([feedbacks, sequences]);

        dispatch.dashboard.setPage(1);
      }
    },
    async changeFeedbackListDistributionItem(payload, rootState) {
      dispatch.dashboard.setFeedbackListDistributionItem(payload);
      //fetch feedbacks and sequences
      const feedbacks = await dispatch.dashboard.fetchFeedbacks(true);
      const sequences = await dispatch.dashboard.fetchSequences();
      await Promise.all([feedbacks, sequences]);
    },
    closeFeedBacksPanel(payload, rootState) {
      dispatch.dashboard.setFeedbackPanelOpen(false);
      dispatch.dashboard.reset()
      dispatch.dashboard.setFeedbackPanelViz(null);
    },

    async fetchSequences(payload, rootState) {
      const clickedFieldValue = rootState.dashboard.feedbackPanelViz.getClickedFieldValue();
      if (clickedFieldValue && clickedFieldValue.field.isCategoryTree()) {
        const feedbackListDistributionItem = rootState.dashboard.feedbackListDistributionItem;
        const args = {
          filter:  rootState.dashboard.vizQuery.filter,
          category: clickedFieldValue ? {
            tree: clickedFieldValue.field.tree,
            category: clickedFieldValue.fullName
          } : undefined,
          tonality: feedbackListDistributionItem?.tonality,
        };
        dispatch.dashboard.setSequences(await services.getSequencesService().getSequences(args));
      }
    }
  })
});