import { EnhancedMetric } from "./EnhancedMetric";
import { TFunction } from "i18next";

export class VolumeMetric extends EnhancedMetric {
  public static TYPE = "VOLUME";

  static parse(object: any): VolumeMetric {
    return new VolumeMetric();
  }

  constructor() {
    super(VolumeMetric.TYPE);
  }

  supportPercentage(): boolean {
    return true;
  }

  defaultUnitLabel(t: TFunction): string {
    return t('misc.feedback_plural');
  }
}