import { Predicate } from './Predicate';
import _ from 'lodash';
import { defaultEqualComparator } from "../functionUtils";

export class IsOneOf<T> extends Predicate<T> {
  private readonly items: Array<T>;
  private readonly equalComparator: EqualComparator<T>;

  constructor(items: Array<T>, equalComparator: EqualComparator<T> = defaultEqualComparator) {
    super();
    if (_.isNil(items)) {
      throw new Error('Given items should not be nil');
    }
    if (!_.isArray(items)) {
      throw new Error(`Given items ${items} should be an array`);
    }
    this.items = items;
    this.equalComparator = equalComparator;
  }

  match(object: T): boolean {
    return this.items ? this.items.findIndex(o => this.equalComparator(object, o)) !== -1 : false;
  }

  message(): string {
    return `one of ${this.items}`;
  }
}