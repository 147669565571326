import { services } from '../../application/service/services';

export const exportState = () => ({
  state: {
  },
  reducers: {
  },
  effects: (dispatch) => ({
    async exportFeedbacks(payload, rootState) {
      await services.getExportService().exportFeedbacks(payload);
    },
    async exportHighlight(payload, rootState) {
      await services.getExportService().exportHighlight(payload);
    }
  })
});