import { ApiFilter } from './ApiFilter';
import _ from "lodash";

export class TermFilter extends ApiFilter {
  static parse(object):ApiFilter {
    return new TermFilter(object.field, object.term);
  }

  field:string;
  term:string;

  constructor(field:string, term:string) {
    super('term');
    this.field = field;
    this.term = term;
  }

  toPlainObject() {
    if (_.isEmpty(this.field)) {
      throw new Error('field is mandatory')
    }

    if (_.isEmpty(this.term)) {
      throw new Error('term is mandatory')
    }

    const result:any = super.toPlainObject();
    result.field = this.field;
    result.term = this.term;
    return result;
  }

  getCopy():ApiFilter {
    const apiFilter = new TermFilter(this.field, this.term);
    return apiFilter
  }
}