import {Intent, OverlayToaster, Position, ToasterPosition} from '@blueprintjs/core';
import * as React from 'react';
import './message.scss';
import { IMessageDetail, IMessageItem } from "./MessageTypes";
import { MessageContent } from "./MessageContent";
import { services } from "../../service/services";

const levelIntent = {
  'ERROR': Intent.DANGER,
  'INFO': Intent.PRIMARY,
  'SUCCESS': Intent.SUCCESS,
  'WARNING': Intent.WARNING
};

const levelIcon = {
  'ERROR': 'warning-sign',
  'INFO': 'info-sign',
  'SUCCESS': 'tick',
  'WARNING': 'hand'
};

interface IMessageProps {
  position?: ToasterPosition
  defaultTimeout?: number,
}

export interface IError {
  type: 'HTTP' | 'RUNTIME' | 'RUNTIME_UI',
  message?: string,
  response?: { status: number, data?: any, headers?: object }
  request?: any
  error?: Error,
  errorInfo?: React.ErrorInfo
}

export interface IMessage {
  level: 'ERROR' | 'INFO' | 'SUCCESS' | 'WARNING',
  text: string | { key: string, args?: object }
  timeout?: number,
  error?: IError
}

export class Message extends React.PureComponent<IMessageProps> {
  public static defaultProps: IMessageProps = {
    position: Position.BOTTOM_LEFT,
    defaultTimeout: 5000
  };

  toaster;
  refHandlers = {
    toaster: (ref) => (this.toaster = ref),
  };

  componentDidMount() {
    services.getApplicationService().addMessageHandler(this.handler);
  }

  componentWillUnmount() {
    services.getApplicationService().removeMessageHandler(this.handler);
  }

  getMessage(message: IMessage):string {
    const text = message.text;
    if (typeof text === 'string') {
      return text;
    }

    const keys = [];
    if (message.error && message.error.type === 'HTTP' && message.error.response) {
      keys.push(`${text.key}.${message.error.response.status}`);
    }
    keys.push(text.key);
    if (message.error) {
      if (message.error.response) {
        keys.push(`error.${message.error.type}.${message.error.response.status}`.toLowerCase());
      }
      keys.push(`error.${message.error.type}`.toLowerCase());
      keys.push('error.runtime');
    }

    return services.getI18nService().translateWithFallbackKeys(keys, '', text.args);
  }

  getDetailMessage(error:IError):string {
    if (error.type === 'RUNTIME' || error.type === 'RUNTIME_UI') {
      return error.error.message ? error.error.message : error.error.toString();
    }

    if (error.type === 'HTTP') {
      return error.message;
    }

    return error.type + ' error';
  }

  getDetailItems(error:IError):Array<IMessageItem> {
    if (error.type === 'RUNTIME') {
      return [
        {
          name: 'Stacktrace',
          value: error.error.stack
        }
      ];
    }

    if (error.type === 'RUNTIME_UI') {
      return [
        {
          name: 'Stacktrace',
          value: error.error.stack
        },
        {
          name: 'ErrorInfo',
          value: error.errorInfo
        }
      ];
    }

    if (error.type === 'HTTP') {
      const result = [
        {
          name: 'Request',
          value: error.request
        }
      ];

      if (error.response) {
        result.push({
          name: 'Response',
          value: error.response
        });
      }

      return result;
    }

    return [];
  }

  getDetails(message: IMessage):IMessageDetail {
    const hasRoleForDetails = services.getSecurityService().hasRole('vecko.feedback.debug');

    if (!hasRoleForDetails) {
      return null;
    }

    if (message.error) {
      return {
        message: this.getDetailMessage(message.error),
        items: this.getDetailItems(message.error)
      };
    }

    return null;
  }

  handler = (message: IMessage) => {
    const details = this.getDetails(message);

    const toast = {
      className: 'message-toast',
      intent: levelIntent[message.level] || Intent.NONE,
      icon: levelIcon[message.level] || 'blank',
      message: <MessageContent message={this.getMessage(message)} details={details}/>,
      timeout: message.timeout || this.props.defaultTimeout
    };

    if (!details) {
      toast.className = toast.className + ' message-toast-simple';
    }

    this.toaster.show(toast);
  };

  render() {
    return <OverlayToaster position={this.props.position} ref={this.refHandlers.toaster}/>;
  }
}

