import axios from 'axios';


const invalidateAllCaches = async () => {
  return axios
    .delete(`/api/cache/local`, {
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
};

const invalidateCurrentTenantPersistentCache = async () => {
    return axios
        .delete(`/api/cache/persistent`, {
            headers: { 'Content-type': 'application/json' }
        })
        .then(response => response.data);
};

const invalidateAllTenantsPersistentCache = async () => {
  return axios
    .delete(`/api/cache/persistent/_all`, {
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
};

export default { invalidateAllCaches, invalidateCurrentTenantPersistentCache, invalidateAllTenantsPersistentCache };