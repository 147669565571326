import { Enum, EnumValue } from "../utils/enum";

export class FilterLocation extends EnumValue {
    constructor() {
        super();
    }
}

class FilterLocationEnumType extends Enum<FilterLocation> {
    TOP_START = new FilterLocation();
    TOP_END = new FilterLocation();
    SIDE = new FilterLocation();

    constructor() {
        super();
        this.initEnum('FilterLocation');
    }
}

export const FilterLocationEnum = new FilterLocationEnumType();
