import {KeycloakAuth} from '@eptica/auth';
import axios from 'axios';
import _ from 'lodash';
import {isMock} from '../Mock';
import {services} from './services';
import authMock from "../fetch/mock/authMock";

export class SecurityService {
    auth: KeycloakAuth;

    constructor() {
        this.auth = new KeycloakAuth(true);
    }

    init() {
        if (isMock() || services.getUrlService().isStandAlone()) {
            return;
        }

        const bearerAuthHeader = () => {
            return 'Bearer ' + this.getAuth().getToken();
        };

        return axios.get('/api/security/info')
            .then(response => {
                return this.auth.init(response.data);
            })
            .then(() => {
                axios.interceptors.request.use(config => {
                    config.headers.Authorization = bearerAuthHeader();
                    return Promise.resolve(config);
                });

                // @ts-ignore
                fetch = (originalFetch => {
                    return (url, options) => {
                        const opts = Object.assign({}, options || {});
                        if (_.isNil(opts.headers)) {
                            opts.headers = [];
                        }
                        opts.headers.append('Authorization', bearerAuthHeader());
                        return originalFetch(url, opts);
                    };
                })(fetch);
            });
    }

    getAuth(): KeycloakAuth {
        return this.auth;
    }

    hasRole(role: string): boolean {
        return this.verifyMockOrElse([role], this.hasAuthRole.bind(this));
    }

    hasOneOfRoles(roles: Array<string>): boolean {
        return this.verifyMockOrElse(roles, this.hasAuthRole.bind(this));
    }

    private hasAuthRole(roles) {
        return roles.findIndex(role => this.auth.hasRole(role)) !== -1
    }

    private verifyMockOrElse(roles: Array<string>, callback: (roles: string[]) => boolean) {
        if (isMock() || services.getUrlService().isStandAlone()) {
            // To test authorisation in mock mode uncomment following line and configure the roles in authMockeData.js file
            return authMock.hasOneOfRoles(roles);
        }

        return callback(roles);
    }

    isAdmin() {
        return this.hasRole('c-vecko-admin');
    }
}

services.registerService('securityService', new SecurityService());
