import { Tonality } from '@eptica/vecko-js-commons';
import React from 'react';
import { Trans } from 'react-i18next';
import { SatTypeEnum } from '../../../../application/model/SatTypeEnum';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { getTitle } from '../common';
import { TopicImpactViz } from './topicImpactViz';

registerVizType({
  name: 'TOPIC_IMPACT',
  component: TopicImpactViz,
  title: (vizInstance, t) => {
    const title = getTitle(vizInstance, t);
    if(title){
      return title;
    }
    const satType = SatTypeEnum.valueOf(vizInstance.definition.params.satType);
    const tonality = Tonality.getValueOf(vizInstance.definition.params.polarity);
    const i18nKey = `dashboard.viz.topicImpact.title`;

    return <Trans i18nKey={i18nKey}
                  count={2}
                  values={{ polarity: tonality.getLabel(t), satType: satType.getAliasedName() }}>
      <span style={{ color: tonality.color }}/>
    </Trans>;
  }
});