import {Classes} from '@blueprintjs/core';
import React from 'react';
import classNames from 'classnames';

interface StyledProgressBarProps {
    animate?: boolean,
    className?: any,
    intent?: any,
    stripes?: boolean,
    value?: any,
    style?: any,
    meterStyle?: any
}

/**
 * This is Blueprint ProgressBar with 2 additional props : style and meterStyle for setting custom style to
 * the progress bar track and the progress bar meter
 */
export class StyledProgressBar extends React.PureComponent<StyledProgressBarProps> {
    render() {
        const {animate = true, className, intent, stripes = true, value, style, meterStyle} = this.props;
        const classes = classNames(
            Classes.PROGRESS_BAR,
            Classes.intentClass(intent),
            {[Classes.PROGRESS_NO_ANIMATION]: !animate, [Classes.PROGRESS_NO_STRIPES]: !stripes},
            className,
        );
        // don't set width if value is null (rely on default CSS value)
        const width = value == null ? null : 100 * this.clamp(value, 0, 1) + "%";
        const minWidth = '15px';

        return (
            <div className={classes} style={style}>
                <div className={Classes.PROGRESS_METER} style={{...meterStyle, width, minWidth}}/>
            </div>
        );
    }

    clamp(val, min, max) {
        if (val == null) {
            return val;
        }
        if (max < min) {
            throw new Error('min cannot be greater than max');
        }
        return Math.min(Math.max(val, min), max);
    }
}