import { Predicate } from './Predicate';
import _ from "lodash";

export class IsEmpty<T> extends Predicate<T> {
  match(object): boolean {
    return _.isEmpty(object);
  }

  message(): string {
    return `empty`;
  }
}