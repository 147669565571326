export class MessageHandler {
  _handlers = [];

  notify(message) {
    this._handlers.forEach(handler => handler(message));
  }

  addHandler(handler) {
    this._handlers.push(handler);
  }

  removeHandler(handler) {
    this._handlers = this._handlers.filter(h => h !== handler);
  }
}