import React from 'react';
import './distributionPanel.scss';
import { ColoredProgressBar } from "../../../../component/progressBar/ColoredProgressBar";
import _ from "lodash";
import { TrendIcon } from "../trend/TrendIcon";
import classNames from 'classnames';

interface DistributionPanelProps {
  items: Array<DistributionItem>,
  total: number,
  showPercent: boolean,
  showVolume: boolean,
  showTrend: boolean,
  className?: string,
  onClick?: (DistributionItem) => any;
}

export interface DistributionItem {
  value: string,
  label: string,
  volume: number,
  total: number,
  trend: number,
  color: string
}

export class DistributionPanel extends React.PureComponent<DistributionPanelProps> {
  renderPercent(volume: number) {
    const { total } = this.props;
    if (total === 0) return '-';
    return _.round(volume / total * 100, 2) + '%';
  }

  render() {
    const { className, items, total, showPercent, showVolume, showTrend } = this.props;

    return <div className={classNames('distributionPanel', className)}>
      {
        items.map(item => (<React.Fragment key={item.label}>
          <div className="distributionPanelLabel" onClick={this.getOnClickHandler(item)}>{item.label}</div>
          <div className="distributionPanelBar" onClick={this.getOnClickHandler(item)}>
            <ColoredProgressBar value={total === 0 ? 0 : item.volume / total} color={item.color}/></div>
          {showPercent ? <div className="distributionPanelPercent">{this.renderPercent(item.volume)}</div> : null}
          {showVolume ? <div className="distributionPanelVolume">{showPercent ? "  " : ""}({item.volume})</div> : null}
          {showTrend && !_.isNil(item.trend) ?
            <div className="distributionPanelTrend" onClick={this.getOnClickHandler(item)}><TrendIcon value={item.trend} size={18}/>
            </div> : null}
        </React.Fragment>))
      }
    </div>;
  }

  private getOnClickHandler(item: DistributionItem) {
    return (event) => {
      if(this.props.onClick) {
        event.stopPropagation();
        this.props.onClick(item);
      }
    }
  }
}