import { toArray } from '../collectionUtils';
import { Predicate } from './Predicate';
import { InstanceOf } from './InstanceOf';
import { AllItemsMatch } from './AllItemsMatch';

export class Every<T> extends Predicate<T> {
  private _predicates:Array<Predicate<T>>;

  constructor(predicates:OneOrMany<Predicate<T>>) {
    super();
    this.predicates = predicates;
  }

  get predicates():OneOrMany<Predicate<T>> {
    return this._predicates;
  }

  set predicates(predicates:OneOrMany<Predicate<T>>) {
    const arrayOfPredicates = toArray(predicates);
    if (!new AllItemsMatch(new InstanceOf(Predicate as Class<Predicate<T>>, false)).match(arrayOfPredicates)) {
      throw new Error(`Given predicates ${predicates} must be not nil and instance of Predicate`);
    }

    this._predicates = arrayOfPredicates;
  }

  match(object) {
    for (let predicate of this._predicates) {
      if (!predicate.match(object)) {
        return false;
      }
    }
    return true;
  }

  message(): string {
    return `match all predicates ${this._predicates}`;
  }
}