import { FieldValue } from '../../../application/model/field/FieldValue';
import { services } from '../../../application/service/services';
import { ParseError } from '../../../utils/errors/ParseError';
import { StateConverter, StateConverterParser } from './StateConverter';
import _ from "lodash";

export class FieldValueStateConverter extends StateConverter<FieldValue> {
  clazz(): Class<FieldValue> {
    return FieldValue;
  }

  getParser(): Maybe<StateConverterParser<FieldValue>> {
    return FieldValueStateConverter.parser;
  }

  innerFromState(obj: Maybe<any>): Maybe<FieldValue> {
    return obj ? FieldValueStateConverter.findFieldValue(obj.field, obj.value) : null;
  }

  innerToState(obj: Maybe<FieldValue>): Maybe<any> {
    return obj ? { field: obj.field.name, value: obj.fullName } : null;
  }

  private static findFieldValue(fieldName: string, value): FieldValue {
    return services.getFieldsService().findFieldValue(fieldName, value);
  }

  private static parser = (str:string): Maybe<FieldValue> => {
    if (_.isEmpty(str)) {
      return null;
    }

    const idx = str.indexOf('/');

    if (idx === -1) {
      throw new ParseError('Cannot find tree name');
    }

    if (idx === str.length - 1) {
      throw new ParseError('Cannot find name');
    }

    const fieldName = str.substring(0, idx);
    const fieldValue = str.substring(idx + 1);
    const result = FieldValueStateConverter.findFieldValue(fieldName, fieldValue)

    if (_.isNil(result)) {
      throw new ParseError(`Could not find field value ${str}`);
    }

    return result;
  }
}