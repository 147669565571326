import React from 'react';
import { Trans } from 'react-i18next';
import { SatTypeEnum } from '../../../../application/model/SatTypeEnum';
import { Satisfaction } from '../../../model/Satisfaction';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { getTitle } from '../common';
import { StandardProfileEditor } from './standardProfileEditor' ;
import { standardProfileEditorState, standardProfileEditorStateName } from './standardProfileEditorState';
import { StandardProfileViz } from './standardProfileViz';

const titleTrans = (vizInstance, keyPrefix, t ) => {
  const title = getTitle(vizInstance, t);
  if(title){
    return title;
  }
  const satType = SatTypeEnum.valueOf(vizInstance.definition.params.satType);
  const satisfaction = Satisfaction['getValueOf'](vizInstance.definition.params.sat);
  const customerKind = satisfaction.customerKind;
  const color = customerKind.color;

  return <Trans i18nKey={keyPrefix}
                values={{ sat: satisfaction.name(), satType: satType.getAliasedName() }}>
    <span style={{ color: color }}/>
  </Trans>;
};

registerVizType({
  name: 'STANDARD_PROFILE',
  component: StandardProfileViz,
  title: (vizInstance,t) => titleTrans(vizInstance, 'dashboard.viz.standardProfile.title', t),
  editor: {
    component: StandardProfileEditor,
    title: (vizInstance, t) => titleTrans(vizInstance, 'dashboard.viz.standardProfile.editor.title', t),
    enabled: vizInstance => {
      return vizInstance.hasData();
    },
    applyOnData: (data, editedData) => {
      data.feedback = editedData.feedback;
      data.feedbackTitle = editedData.feedbackTitle;
      data.profileItems = editedData.profileItems;
    },
    focusOnMount: false,
    state: {
      name: standardProfileEditorStateName,
      create: () => standardProfileEditorState()
    }
  },
  noDataPolicy: {
    hasData: vizInstance => (vizInstance.data.bestTopics && vizInstance.data.bestTopics.length > 0)
  }
});