import _ from "lodash";
import { doFor, equalsInAnyOrder, toArray } from "../../../utils/collectionUtils";

export type StateConverterParser<T> = (str:string) => Maybe<T>;

export abstract class StateConverter<T> {
  abstract clazz(): Class<T>;

  equals(o1:Maybe<OneOrMany<T>>, o2:Maybe<OneOrMany<T>>):boolean {
    return equalsInAnyOrder(toArray(o1), toArray(o2), (i, j) => this.innerEquals(i, j));
  }

  protected innerEquals(o1:T, o2:T):boolean {
    return o1 === o2;
  }

  /**
   * Parse the given string into a instance of OneOrMany<T>.
   *
   * @throws ParseError
   */
  fromString(str: Maybe<string>): Maybe<OneOrMany<T>> {
    if (_.isEmpty(str)) {
      return null;
    }

    const parser = this.getParser();

    if (_.isNil(parser)) {
      throw new Error('parse not supported');
    }

    return doFor(str.split(','), parser, o => !_.isNil(o));
  }

  protected getParser(): Maybe<StateConverterParser<T>> {
    const clazz: any = this.clazz();
    if (_.isFunction(clazz.parse)) {
      return clazz.parse;
    }
    return null;
  }

  toString(obj): string {
    //todo : check if really called somewhere
    if (_.isNil(obj)) {
      return '';
    }
    return obj.toString();
  }

  fromState(obj: Maybe<OneOrMany<any>>): Maybe<OneOrMany<T>> {
    return doFor(obj, (o) => this.innerFromState(o), o => !_.isNil(o));
  }

  protected innerFromState(obj: Maybe<any>): Maybe<T> {
    return obj;
  }

  toState(obj: Maybe<OneOrMany<T>>): Maybe<OneOrMany<any>> {
    return doFor(obj, (o) => this.innerToState(o), o => !_.isNil(o));
  }

  protected innerToState(obj: Maybe<T>): Maybe<any> {
    return obj;
  }
}