import { services } from '../../service/services';
import _ from "lodash";

export const applicationState = () => ({
  state: {},
  reducers: {
    '@@router/LOCATION_CHANGE'(state, payload) {
      state.currentView = services.getApplicationService().getViewIdByUrlPathname(payload.location.pathname);
      return state;
    }
  },
  effects: (dispatch) => ({
    refresh(payload, rootState) {
      const currentView = services.getApplicationService().getView(rootState.application.currentView);
      if (!_.isNil(currentView)) {
        const stateSliceName = currentView.stateSliceName;
        if (stateSliceName && dispatch[stateSliceName] && dispatch[stateSliceName].refresh) {
          dispatch[stateSliceName].refresh();
        }
      }
    }
  })
});