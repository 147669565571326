import { TFunction } from "i18next";
import _ from "lodash";
import { equalComparator } from "../../../../utils/functionUtils";

export abstract class ParamValue {
  static equalComparator: EqualComparator<ParamValue> = equalComparator;

  private readonly type:string;

  protected constructor(type) {
    this.type = type;
  }

  abstract getId(): string;

  abstract getLabel(t:TFunction): string

  getFullLabel(t: TFunction): string {
    return this.getLabel(t);
  }

  getIcon(): string {
    return null;
  }

  abstract getValueAsPlainObject(): any;

  asPlainObject(): any {
    return {
      '@type': this.type
    }
  }

  abstract isValid(): boolean;

  isMultiValued(): boolean {
    return false;
  }

  resolve(state: any): ParamValue {
    return this;
  }

  equals(paramValue: ParamValue): boolean {
    if (this === paramValue) {
      return true;
    }
    if (_.isNil(paramValue)) {
      return false;
    }
    if (!(paramValue instanceof ParamValue)) {
      return false;
    }

    if (paramValue.type !== this.type) {
      return false;
    }

    return true;
  }
}