import { ApiFilter } from './ApiFilter';
import { Tonality } from '@eptica/vecko-js-commons';
import _ from "lodash";

export class CategoriesFilter extends ApiFilter {
  static parse(object) {
    return new CategoriesFilter(object.tree, object.categories, (object.tonalities || []).map(Tonality.getValueOf));
  }

  tree:string;
  categories:string[];
  tonalities:Tonality[];

  constructor(tree:string, categories:string[], tonalities:Tonality[]) {
    super('categories');
    this.tree = tree;
    this.categories = categories;
    this.tonalities = tonalities;
  }

  toPlainObject() {
    if (_.isEmpty(this.tree)) {
      throw new Error('tree is mandatory');
    }

    if (_.isEmpty(this.categories)) {
      throw new Error('categories are mandatory');
    }

    const result:any = super.toPlainObject();
    result.tree = this.tree;
    result.categories = this.categories;
    if (!_.isEmpty(this.tonalities)) {
      result.tonalities = this.tonalities.map(t => t.name());
    }
    return result;
  }

  getCopy():ApiFilter {
    const apiFilter = new CategoriesFilter(this.tree, this.categories? [...this.categories]: null, this.tonalities? [...this.tonalities]: null );
    return apiFilter
  }
}