import { Predicate } from './Predicate';
import _ from 'lodash';

export class IsNotNil<T> extends Predicate<T> {
  match(object: T): boolean {
    return !_.isNil(object);
  }

  message(): string {
    return `not nil`;
  }
}