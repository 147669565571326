/**
 * abstract class representing a storage item
 */
export class StorageItem {
  /**
   * @type {string} path
   */
  path;

  constructor(path) {
    this.path = path;
  }

  isDir():boolean {
    throw new Error('not implemented');
  }

  isFile():boolean {
    return !this.isDir();
  }

  name() {
    throw new Error('not implemented');
  }
}