import axios from 'axios';
import { SearchQuery } from '../../../utils/query/SearchQuery';
import { VizFeedbacksQueryContext } from "../../../report/model/viz/VizFeedbacksQueryContext";
import { ReportDT0 } from "../../../report/model/dashboard/ReportDTO";

const getData = (reportDto:ReportDT0) => {
  const json = JSON.stringify(reportDto);
  return axios
    .post('/api/report/data', json, {
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => response.data);
};

const getFeedBacksQuery = async (vizFeedbacksQueryParam:VizFeedbacksQueryContext) => {
  const json = JSON.stringify(vizFeedbacksQueryParam);
  return axios
    .post('/api/report/feedbacks_query', json, {
      headers: { 'Content-type': 'application/json' }
    })
    .then(response => SearchQuery.parse(response.data));
};



export default { getData, getFeedBacksQuery };
