import { FieldDisplay } from './FieldDisplay';
import { FieldValue } from './FieldValue';
import { StandardMetadata } from './StandardMetadataEnum';
import _ from "lodash";
import { TFunction } from "i18next";

const categoryTreePrefix = 'enhancement.categories.';
const customMetadataPrefix = 'metadata.custom.';
const systemPrefix = 'system.';

export enum FieldValuesResolverKind {
  CONFIGURATION,
  REMOTE
}

export class Field {
  public static equalityTester: EqualComparator<Field> = (field1, field2): boolean => {
    if (_.isNil(field1)) {
      return _.isNil(field2);
    }
    return field1.equals(field2);
  };

  /**
   * @type {string}
   */
  name;

  /**
   * @type {function(*):{string|JSXElement}}
   */
  valueIconSupplier;

  /**
   * @type {Object<string, FieldDisplay>}
   */
  displays: { [viewName: string]: FieldDisplay } = {};

  /**
   * @type {boolean}
   */
  enabled = true;

  /**
   * @type {boolean}
   */
  exportable = false;

  /**
   * where the values are resolved from
   */
  valuesResolver: FieldValuesResolverKind;

  constructor(name) {
    this.name = name;

    if (this.isStandardMetadata()) {
      const standardField = StandardMetadata.findByFieldName(this.name);
      if (standardField) {
        this.valueIconSupplier = standardField.valueIconSupplier;
      }
    }
  }

  isCategoryTree(): boolean {
    return false;
  }

  isStandardMetadata(): boolean {
    return this.name.startsWith(StandardMetadata.prefix);
  }

  isHierarchical(): boolean {
    return false;
  }

  getLabel(t: TFunction): string {
    return t(this.name);
  }

  getIconForValue(value) {
    if (value instanceof FieldValue) {
      value = value.fullName;
    }
    return this.valueIconSupplier ? this.valueIconSupplier(value) : null;
  }

  /**
   * @param {FieldDisplay} fieldDisplay
   */
  addDisplay(fieldDisplay: FieldDisplay) {
    this.displays[fieldDisplay.view] = fieldDisplay;
  }

  /**
   * @return {boolean}
   */
  isDisplayedSomewhere() {
    return this.enabled && Object.entries(this.displays).length > 0;
  }

  /**
   * @param {FieldDisplay.FieldDisplayKind} view
   * @return {boolean}
   */
  isVisible(view) {
    if (!this.enabled) {
      return false;
    }
    const fieldDisplay = this.displays[view];
    return !_.isNil(fieldDisplay);
  }

  order(view) {
    const fieldDisplay = this.displays[view];
    if (_.isNil(fieldDisplay)) {
      return -1;
    }
    return fieldDisplay.order;
  }

  equals(field: Field): boolean {
    if (_.isNil(field)) {
      return false;
    }
    if (!(field instanceof Field)) {
      return false;
    }

    return field.name === this.name;
  }
}

export class FieldName {
  static categoryTree(tree) {
    return `${categoryTreePrefix}${tree}`;
  }

  static standardMetadata(name) {
    return `${StandardMetadata.prefix}${name}`;
  }

  static customMetadata(name) {
    return `${customMetadataPrefix}${name}`;
  }

  static system(name) {
    return `${systemPrefix}${name}`;
  }
}