export class SearchResult<T> {
  /**
   * @type Array
   */
  _hits: Array<T>;

  /**
   * @type number
   */
  _total: number;

  constructor(hits: Array<T>, total) {
    this._hits = hits;
    this._total = total;
  }

  get hits(): Array<T> {
    return this._hits;
  }

  get total(): number {
    return this._total;
  }
}