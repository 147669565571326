import { Tonality } from '@eptica/vecko-js-commons';
import _ from 'lodash';
import { SatAndTopicsAspect } from '../../../utils/query/aspect/SatAndTopicsAspect';
import { SatAspect } from '../../../utils/query/aspect/SatAspect';
import { SatTypeAspect } from '../../../utils/query/aspect/SatTypeAspect';
import { BoolFilter } from '../../../utils/query/filter/BoolFilter';
import { CategoriesFilter } from '../../../utils/query/filter/CategoriesFilter';
import { TermFilter } from '../../../utils/query/filter/TermFilter';
import { FieldName } from '../field/Field';
import { DistributionItem } from './DistributionItem';
import { DistributionKind } from './DistributionKind';
import { SatType, SatTypeEnum } from "../SatTypeEnum";
import { getDistributionKind } from "./DistributionKindProvider";

export class SatTypeDistribution extends DistributionKind {
  static TYPE = 'satType';

  static parse(object) {
    return SatTypeDistribution.get(SatTypeEnum.valueOf(object.satType));
  }

  static get(satType:SatType): SatTypeDistribution {
    return getDistributionKind(SatTypeDistribution.TYPE, satType.name, () => new SatTypeDistribution(satType));
  }

  readonly satType:SatType;

  private constructor(satType:SatType) {
    super(SatTypeDistribution.TYPE);
    this.satType = satType;
  }

  getId() {
    return super.getId() + '-' + this.satType.name;
  }

  getLabel(t) {
    return t(`distributionKind.${this.type}`, { satType: this.satType.getAliasedName() });
  }

  getIcon() {
    return this.satType.getIcon();
  }

  toPlainObject() {
    return {
      ...super.toPlainObject(),
      satType: this.satType.name
    };
  }

  decorateFeedbackQuery(query, topic, item) {
    const hasDistributionItem = !_.isNil(item);

    if (topic) {
      query.filter =
        BoolFilter.must(query.filter, new CategoriesFilter('topic', topic.getLeaves(true).map(l => l.fullName), []));

      if (hasDistributionItem) {
        query.addAspect(new SatAndTopicsAspect(this.satType.name, Tonality.getValueOf(item.id), [topic.fullName]));
      } else {
        query.filter = BoolFilter.must(query.filter, new TermFilter(FieldName.categoryTree('topic'), topic.fullName));
        query.addAspect(new SatTypeAspect(this.satType.name));
      }
    } else {
      if (hasDistributionItem) {
        query.addAspect(new SatAspect(this.satType.name, Tonality.getValueOf(item.id)));
      } else {
        query.addAspect(new SatTypeAspect(this.satType.name));
      }
    }
  }

  createItems() {
    return [Tonality.NEGATIVE, Tonality.POSITIVE]
      .concat(this.satType.hasNeutral ? [Tonality.NEUTRAL] : [])
      .map(tonality =>
        new DistributionItem(
          tonality,
          this.satType.icon,
          t => t(`distributionKind.${this.type}.item`,
            { satType: this.satType.getAliasedName(), item: tonality.name() })
        )
      );
  }
}