import {Tonality} from '@eptica/vecko-js-commons/lib';
import _ from 'lodash';
import * as d3 from 'd3';
import React from 'react';
import * as ReactDOM from "react-dom";
import {VeckoFonts} from "../../../style/VeckoFont";
import {parseSvg} from "../../../utils/d3-interpolate/parse";
import {square} from '@eptica/js-utils/dist';
import {TextBox} from 'd3plus-text/es';
import {responsiveWrapper} from "../../../component/hoc/responsiveWrapper";
import {withTranslation} from "react-i18next";
import {SmartIcon} from "../../../component/icon/SmartIcon";
import {DistributionKind} from "../../../application/model/distribution/DistributionKind";
import {Score} from "../../model/model";


interface BubbleSatDistributionProps {
    className?: string,
    score: Score,
    distributionKind: DistributionKind,
    showNeutral?: boolean,
    showPercent?: boolean,
    showVolume?: boolean,
};


interface DistributionItem {
    tonality: Tonality;
    value: number;
}

interface BubbleSatDistributionSVGProps {
    score: Score,
    distribution: Array<DistributionItem>;
    distributionKind: DistributionKind;
    parentWidth: number,
    parentHeight: number
}


interface BubbleSatDistributionState {
    paintedOnce: boolean;
}

class BubbleSatDistributionSVGComp extends React.PureComponent<BubbleSatDistributionSVGProps> {

    state: BubbleSatDistributionState = {
        paintedOnce: false,
    };

    private svg: SVGSVGElement;
    private svgRef = (e: SVGSVGElement) => this.svg = e;

    componentDidUpdate(prevProps: BubbleSatDistributionSVGProps, prevState, snapshot) {
        const shouldRepaint = !_.isEqual(prevProps.score, this.props.score) ||
            prevProps.parentWidth !== this.props.parentWidth ||
            prevProps.parentHeight !== this.props.parentHeight ||
            !this.state.paintedOnce;
        if (shouldRepaint) {
            this.paint();
        }
    }

    paint() {
        const props = this.props;

        const element = ReactDOM.findDOMNode(this) as Element;
        if (_.isNil(element)) {
            return;
        }
        if (!this.hasData()) {
            return;
        }

        // clear all
        const svg = d3.select(this.svg);
        svg.selectAll('*').remove();

        const self = this;
        const width = props.parentWidth;
        const height = props.parentHeight;

        // set the svg dimension
        svg.attr('width', '100%').attr('height', '100%');

        var radius = Math.min(width, height) / 2 - 10

        // Compute the position of each group on the pie:
        const pie = d3.pie()
            .value(d => d[1].value)
            .sort(null);


        const orderedDistribution = [];

        props.distributionKind.items
            .forEach(distributionKindItem => {
                let tonalityValue = props.distribution.filter(distributionItem => distributionItem.tonality === distributionKindItem.tonality)[0];
                if (!tonalityValue) {
                    tonalityValue = {tonality: distributionKindItem.tonality, value: 0};
                }
                orderedDistribution.push(tonalityValue);
            });
        const distributionEntries = Object.entries(orderedDistribution);
        const data_ready = pie(distributionEntries as any)


        // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
        svg
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
            .selectAll('whatever')
            .data(data_ready)
            .join('path')
            .attr('d', (d3 as any).arc()
                .innerRadius(radius - 8)         // This is the size of the donut hole
                .outerRadius(radius)
            )
            .attr('fill', d => d.data[1].tonality.color)
            .style("opacity", 0.7)


        // label
        const clipRadius = radius - 1;
        const labelBox = svg.append('g')
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
            .attr('clip-path', `url(#clip-textbox)`);

        new TextBox()
            .data([this.props.score])
            .select(labelBox.node())
            .text(d => this.props.score.score)
            .fontFamily(VeckoFonts.textFont)
            .verticalAlign('middle')
            .textAnchor('middle')
            .fontResize(true)
            .fontMax(d => 47)
            .fontMin(47)
            .overflow(true)
            .width(d => radius * 2)
            .height(d => radius * 2)
            .padding(10)
            .x(d => -radius)
            .y(d => -radius)
            .render(function () {
                const node = d3.select(labelBox.node());
                const textBox = node.select('.d3plus-textBox');
                const allText = textBox.selectAll('text');
                allText.each(function () {
                    const self = this as SVGGraphicsElement;
                    const text = self.textContent;

                    // y : position of the line of text relative to the text box
                    let ys = self.getAttribute('y');
                    ys = ys.substring(0, ys.length - 2);
                    const y = parseInt(ys);

                    // offset : the position of the text box relative to the bubble
                    const parentTransform = parseSvg(self.parentElement.getAttribute('transform'));
                    const offset = parentTransform.translateY;

                    // d : the distance of the line of text from the bubble center
                    let d = Math.abs(offset) - y;
                    if (d > 0) { // if the line is above the center, the chord should be calculate on the text top (not on the text baseline)
                        d = d + self.getBBox().height / 3;
                    }

                    // chordLength : the available with to print the line of text
                    const chordLength = 2 * Math.sqrt(square(clipRadius) - square(d));

                    // while text width is bigger than the available width, remove a char and add ellipsis
                    const textWidth = () => self.getBBox().width;
                    let len = text.length - 4;
                    while (textWidth() > chordLength && len > 0) {
                        d3.select(this).text(text.substring(0, len) + '...');
                        len--;
                    }
                });
            });

        this.setState({paintedOnce: true});
    };

    render() {
        return <svg ref={this.svgRef}/>;
    }

    private hasData() {
        return this.props.distribution && this.props.distribution.length > 0;
    }
}

const BubbleSatDistributionSVG = responsiveWrapper(BubbleSatDistributionSVGComp);

class BubbleSatDistributionComp extends React.PureComponent<BubbleSatDistributionProps> {
    static defaultProps = {
        showNeutral: true,
        showPercent: true,
        showVolume: false
    };

    renderPercent(total: number, volume: number) {
        if (total === 0) return '-';
        return _.round(volume / total * 100, 2) + '%';
    }

    getDistribution(): Array<DistributionItem> {
        const {showNeutral, score} = this.props;
        const tonalities = [];
        Object.keys(score.distribution).forEach(value => {
                const tonality = Tonality.getValueOf(value);
                if (tonality) {
                    tonalities.push(tonality);
                }
            }
        );
        return tonalities.map(tonality => {
            return {tonality: tonality, value: this.props.score.distribution[tonality.name()]}
        });
    }


    render() {
        const {className, showPercent, showVolume, score, distributionKind} = this.props;

        const clazz = className;
        const style: any = {flexGrow: 1, minHeight: 0}
        if (!showPercent && !showVolume) {
            style.paddingBottom = 3;
        }
        const distribution = this.getDistribution();
        const total = distribution.reduce((previousValue, currentValue) => previousValue + currentValue.value, 0)
        return <div className={clazz} style={style}>
            <div style={{flexGrow: 1, minHeight: "50px"}}>
                <BubbleSatDistributionSVG score={score} distributionKind={distributionKind}
                                          distribution={distribution}></BubbleSatDistributionSVG>
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
                {

                    distribution.map((distribution, index) => {
                        const volume = distribution.value;
                        return <div key={distribution.tonality + index} style={{
                            flex: "1 1 0",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <SmartIcon icon={distributionKind.getIcon()} color={distribution.tonality.color}/>
                            {
                                showPercent ? <div className='percent'
                                                   style={{fontSize: "18px"}}>{this.renderPercent(total, volume)}</div> : null
                            }
                            {
                                showVolume ? <div className='volume'>{volume}</div> : null
                            }
                        </div>;
                    })
                }
            </div>
        </div>;
    }

    private hasData() {
        return true;
    }
}

export const BubbleSatDistribution = withTranslation()(BubbleSatDistributionComp)
