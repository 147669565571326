import _ from 'lodash';
import { traverseObject } from '../../utils/objectUtils';

export class VariableResolverService {
  constructor() {
  }

  /**
   * parse a variable reference : "${var.name}" => "var.name".
   * If the given string is not in the expected format returns undefined.
   *
   * @param {string} string
   * @return {Maybe<string>}
   */
  resolveVariableName(string: string): Maybe<string> {
    if (_.isNil(string)) {
      return undefined;
    }
    if (!_.isString(string)) {
      return undefined;
    }
    if (string.startsWith('${') && string.endsWith('}')) {
      return string.substring(2, string.length - 1);
    }
    return undefined;
  }

  getParamNameFromVariableName(variable: string): string {
    return variable.includes('.') ? variable.substring(0, variable.indexOf('.')) : variable;
  }

  resolveVariable(variable: string, params:Dict<any>): any {
    if (!_.has(params, variable)) {
      throw new Error(`No params defined for variable ${variable}`);
    }
    return _.get(params, variable);
  }

  /**
   * Mutates the given object
   */
  resolveObject(object:Dict<any>, params:Dict<any>): void {
    traverseObject(object, (value) => {
      const variable = this.resolveVariableName(value);
      if (!_.isUndefined(variable)) {
        return this.resolveVariable(variable, params);
      }
      return value;
    }, true)
  }

  /**
   * Mutates the given object
   */
  resolveParamInString(stringValue:string, params:Dict<any>): string {
      const vars = stringValue.match(/\$\{(.*?)\}/g)
      if(vars){
        let transformedString = stringValue;
        vars.map(key => {
          const variable = this.resolveVariableName(key);
          while (transformedString.indexOf(key)>=0) {
            transformedString = transformedString.replace(key, this.resolveVariable(variable, params));
          }
        })
        return transformedString;
      }
      return stringValue;
  }
}

export const variableResolverService = new VariableResolverService();
