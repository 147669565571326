import _ from 'lodash';
import { services } from '../../../../application/service/services';
import { ResolvedParams } from '../../../model/params/resolve/ResolvedParams';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { DistributionEditor } from './DistributionEditor';
import { DistributionViz } from './DistributionViz';
import { getTitle } from "../common";

const defaultParams = {
  size: 5
};

const defaultUiParams = {
  showPercent: true,
  showVolume: false,
  showTrend: false
};

registerVizType(
  {
    name: 'DISTRIBUTION',
    title: getTitle,
    component: DistributionViz,
    defaultParams: (vizDef) => defaultParams,
    defaultUiParams: (vizDef) => defaultUiParams,
    editor: {
      component: DistributionEditor,
      title: getTitle,
      init(vizInstance) {
        return {
          uiParams: _.merge({}, defaultUiParams, vizInstance.definition.uiParams),
          params: _.merge({}, defaultParams, vizInstance.definition.params),
        };
      },
      applyOnParams: (params, editedParams) => {
        Object.assign(params, editedParams);
      },
      applyOnUiParams: (uiParams, editedUiParams) => {
        Object.assign(uiParams, editedUiParams);
      }
    },
    noDataPolicy: {
      hasData: vizInstance => (vizInstance.data.total > 0)
    },
    specResolver: (vizDefinitionSpec, resolvedParams: ResolvedParams) => {
      const params = vizDefinitionSpec.params;
      const fieldName = params.field;
      const field = services.getFieldsService().getField(fieldName);

      /*check all  inclusions and exclusions values to replace first_level value with all corresponding values*/
      if (params.inclusions) {
        params.inclusions = services.getDashboardService().resolveFirstLevelValues(field, params.inclusions);
      }
      if (params.exclusions) {
        params.exclusions = services.getDashboardService().resolveFirstLevelValues(field, params.exclusions);
      }
      return vizDefinitionSpec;
    },
    prepareViz: (vizDefinitionSpec, data) => {
      const params = vizDefinitionSpec.params;
      const fieldName = params.field;
      /*for each item in date, we register the value in color service to assign it a color*/
      data.distribution.forEach(item => {
        services.getColorService().registerFieldAndValue(fieldName, item.value);
      });
    }
  }
);