import { saveAs } from '@elastic/filesaver';
import { axiosHttpUtil } from './httpUtils';

export function saveHttpResponseAsFile(response, fallbackFileName) {
  const file = new Blob([response.data],
    {
      type: axiosHttpUtil.getContentType(response),
      size: axiosHttpUtil.getContentSize(response)
    } as any);
  saveAs(file, axiosHttpUtil.getFileName(response, fallbackFileName));
}
