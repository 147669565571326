import { services } from '../../../../application/service/services';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { MetricViz } from './MetricViz';
import _ from "lodash";
import { MetricVizEditor } from "./MetricVizEditor";
import { VisualizationInstance } from "../../../model/viz/VisualizationInstance";
import { TFunction } from "i18next";
import { parseMetric } from "../../../model/metric/parseMetric";
import { VeckoColors } from "../../../../style/VeckoColors";
import { Colors } from "@blueprintjs/core";
import { getVisualizationTheme, VizThemeDefinition } from "../../utils/visualizationTheme";
import { getTitle } from "../common";

const defaultUiParams = {
  percentageMode: false,
  showTrend: true,
  showSecondary: true,
  valueUnit: null,
  mainFont: {
    size: 40,
    color: VeckoColors.DEFAULT_FG
  },
  trendFont: {
    size: 24,
    color: VeckoColors.DEFAULT_FG
  },
  secondaryFont: {
    size: 20,
    color: Colors.GRAY1
  }
};

const getDefaultUiParams = (themeDefinition: VizThemeDefinition) => {
  const visualizationTheme = getVisualizationTheme(themeDefinition);

  return _.merge({}, defaultUiParams, {
    mainFont: { color: visualizationTheme.isDark ? VeckoColors.WHITE : VeckoColors.DEFAULT_FG },
    trendFont: { color: visualizationTheme.isDark ? VeckoColors.WHITE : VeckoColors.DEFAULT_FG },
    secondaryFont: { color: visualizationTheme.isDark ? VeckoColors.WHITE : Colors.GRAY1 },
  });
}

const title = (vizInstance: VisualizationInstance, t: TFunction) => {
  const title = getTitle(vizInstance, t);
  if(title){
    return title;
  }
  const metric = parseMetric(vizInstance.definition.params.metric);

  const i18nContext: any = {
    title: metric.getLabel(t)
  };
  let i18nKey = 'dashboard.viz.metric.title_noSubtitle';

  let subtitle = services.getDashboardParamsService().findParamLabelFromConstraint(t, vizInstance);
  if (subtitle) {
    i18nKey = 'dashboard.viz.metric.title';
    i18nContext.subtitle = subtitle;
  }

  return t(i18nKey, i18nContext);
};

registerVizType({
  name: 'METRIC',
  component: MetricViz,
  defaultUiParams: (vizDef) => getDefaultUiParams(_.get(vizDef.spec, ['uiParams', 'theme'])),
  title: title,
  editor: {
    component: MetricVizEditor,
    title: title,
    init(vizInstance) {
      return {
        params: vizInstance.definition.params,
        uiParams: _.merge({}, getDefaultUiParams(vizInstance.definition.uiParams.theme), vizInstance.definition.uiParams)
      };
    },
    applyOnUiParams: (uiParams, editedUiParam) => {
      Object.assign(uiParams, editedUiParam);
    }
  },
  noDataPolicy: {
    hasData: vizInstance => {
      return !_.isNil(vizInstance.data.value);
    }
  }
});