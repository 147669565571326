import { BoolFilter } from './BoolFilter';
import { CategoriesFilter } from './CategoriesFilter';
import { DateRangeFilter } from './DateRangeFilter';
import { DateTimeRangeFilter } from './DateTimeRangeFilter';
import { ExistsFilter } from './ExistsFilter';
import { MissingFilter } from './MissingFilter';
import { NestedFilter } from './NestedFilter';
import { QueryStringFilter } from './QueryStringFilter';
import { RangeFilter } from './RangeFilter';
import { TermFilter } from './TermFilter';
import { TermsFilter } from './TermsFilter';
import { VerbatimNotEmptyFilter } from './VerbatimNotEmptyFilter';
import { ApiFilter } from "./ApiFilter";

export const parseFilter = (object):ApiFilter => {
  const type = object['@type'];

  if (type === 'bool') {
    return BoolFilter.parse(object);
  }
  if (type === 'categories') {
    return CategoriesFilter.parse(object);
  }
  if (type === 'dateRange') {
    return DateRangeFilter.parse(object);
  }
  if (type === 'datetimeRange') {
    return DateTimeRangeFilter.parse(object);
  }
  if (type === 'range') {
    return RangeFilter.parse(object);
  }
  if (type === 'term') {
    return TermFilter.parse(object);
  }
  if (type === 'terms') {
    return TermsFilter.parse(object);
  }
  if (type === 'queryString') {
    return QueryStringFilter.parse(object);
  }
  if (type === 'missing') {
    return MissingFilter.parse(object);
  }
  if (type === 'exists') {
    return ExistsFilter.parse(object);
  }
  if (type === 'verbatimNotEmpty') {
    return VerbatimNotEmptyFilter.parse(object);
  }
  if (type === 'nested') {
    return NestedFilter.parse(object);
  }
  throw new Error('Cannot parse object into filter');
};