import {Spinner, Position, SpinnerSize} from '@blueprintjs/core';
import React from 'react';
import { Message } from './view/message/Message';

export const ApplicationLoading = () => {
  return <>
    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Spinner size={SpinnerSize.STANDARD}/>
      </div>
    </div>
    <Message position={Position.TOP} defaultTimeout={0}/>
  </>;
};
