import { EnhancedMetric } from "./EnhancedMetric";
import { TFunction } from "i18next";
import { SatType, SatTypeEnum } from "../../../application/model/SatTypeEnum";
import _ from "lodash";

export class SatScoreMetric extends EnhancedMetric {
  public static TYPE = "SAT_SCORE";

  public static parse(object: any): SatScoreMetric {
    if (_.isEmpty(object.satType)) {
      throw new Error("satType is mandatory");
    }
    return new SatScoreMetric(SatTypeEnum.valueOf(object.satType));
  }

  private readonly satType: SatType;

  constructor(satType: SatType) {
    super(SatScoreMetric.TYPE);
    this.satType = satType;
  }

  defaultUnitLabel(t: TFunction): string {
    return "";
  }

  supportPercentage(): boolean {
    return false;
  }

  getLabel(t: TFunction): string {
    return this.satType.getLabel(t);
  }

  asPlainObject(): any {
    return {
      ... super.asPlainObject(),
      satType: this.satType.name
    };
  }
}