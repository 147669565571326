import _ from "lodash";
import { AxiosResponse } from "axios";
import { toArray } from "../collectionUtils";

type ResponseHeaderSupplier<T> = (response: T, header: string) => string;
class HttpUtil<T> {
  private readonly headerSupplier:ResponseHeaderSupplier<T>;

  constructor(supplier: ResponseHeaderSupplier<T>) {
    this.headerSupplier = supplier;
  }

  public getFileName(response:T, fallback: string): string {
    return this.getHeader(response,
      {
        header: 'Content-Disposition',
        extract: (header) => {
          return header.split(';')[1].trim().split('=')[1].replace(/"/g, '');
        },
        fallback: fallback
      });
  }

  public getContentType(response: T): string {
    return this.getHeader(response,
      {
        header: 'Content-Type',
        fallback: 'application/octet-stream'
      });
  }

  public getContentSize(response: T): number {
    return this.getHeader(response,
      {
        header: ['Content-Length', 'content-len'],
        extract: (header) => {
          return parseInt(header);
        },
      });
  }

  private getHeader = (response, spec) => {
    const headers = toArray(spec.header);

    let header;
    for (let i = 0; i < headers.length; i++) {
      header = this.headerSupplier(response, headers[i].toLowerCase());
      if (header) {
        break;
      }
    }

    if (!header) {
      return spec.fallback;
    }
    if (_.isFunction(spec.extract)) {
      try {
        header = spec.extract(header);
      } catch (e) {
        return spec.fallback;
      }
    }
    return header || spec.fallback;
  }
}

///
const axiosResponseHeaderSupplier:ResponseHeaderSupplier<AxiosResponse> = (response, header) => {
  return response.headers[header];
};

const fetchResponseHeaderSupplier:ResponseHeaderSupplier<Response> = (response, header) => {
  return response.headers.get(header);
}

export const axiosHttpUtil = new HttpUtil(axiosResponseHeaderSupplier);
export const fetchHttpUtil = new HttpUtil(fetchResponseHeaderSupplier);
