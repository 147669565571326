import { Tab, Tabs } from '@blueprintjs/core';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { services } from '../../../../application/service/services';
import { InfiniteCache } from '../../../../utils/InfiniteCache';
import { actionNamespacer } from '../../../state/utils/actionNamespacer';
import { FeedbackSelector } from '../../components/FeedbackSelector';
import './topicImpactTopEvolutionEditor.scss';
import { dispatchSlice, stateSlice } from './topicImpactTopEvolutionEditorState';
import _ from "lodash";
import { VisualizationInstance } from "../../../model/viz/VisualizationInstance";


interface TopicImpactTopEvolutionEditorData {
  data: { feedback: any }
}

interface TopicImpactTopEvolutionEditorProps extends WithTranslation {
  viz: VisualizationInstance,
  init: () => void,
  setPage: (page: number) => void,
  setCurrentTopicIndex: (index: number) => void,
  onValueChanged: (prop: string, key: string, value: any) => void,
  editorData: TopicImpactTopEvolutionEditorData
  currentTopicIndex,
  feedbacks,
  loadingFeedbacks,
  page,
  pageSize,
  total: number
}

class TopicImpactTopEvolutionEditorComponent extends React.PureComponent<TopicImpactTopEvolutionEditorProps> {
  feedbackSelectionHandler = new InfiniteCache(
    (topic) => (value) => this.onFeedbackSelect(topic, value)
  );

  componentDidMount() {
    this.props.init();
  }

  onFeedbackSelect = (topic, value) => {
    this.props.onValueChanged('data', topic, value);
  };

  onPageChange = (page) => {
    this.props.setPage(page);
  };

  onTabChange = tabId => {
    this.props.setCurrentTopicIndex(parseInt(tabId));
  };

  render() {
    const { t, editorData, currentTopicIndex, feedbacks, loadingFeedbacks, page, pageSize, total } = this.props;

    const currentTabId = `${currentTopicIndex}`;
    return <Tabs large={true} selectedTabId={currentTabId} onChange={this.onTabChange}>
      {
        Object.entries(editorData.data || {})
          .filter(([, feedback]) => !_.isNil(feedback))
          .map(([topic, feedback], i) => {
            const tabId = `${i}`;

            const pagination = {
              current: page,
              total: total,
              pageSize: pageSize,
              onChange: this.onPageChange
            };

            const tabBody = <div className='tab-content'>
              <FeedbackSelector
                items={feedbacks}
                pagination={pagination}
                fetchingData={loadingFeedbacks}
                selectedFeedbackId={feedback.id}
                onSelectFeedback={this.feedbackSelectionHandler.get(topic)}
              />
            </div>;

            return <Tab id={tabId}
                        key={topic}
                        title={services.getFieldsService().findCategoryLabel('topic', topic, t)}
                        panel={tabBody}
            />;
          })
      }
    </Tabs>;
  }
}

const mapStateToProps = state => {
  return stateSlice(state);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const wrapActionPayload = actionNamespacer(ownProps.viz.id);
  dispatch = dispatchSlice(dispatch);

  return {
    init: () => {
      dispatch.init(wrapActionPayload());
    },
    setPage: page => {
      dispatch.changePage(wrapActionPayload(page));
    },
    setCurrentTopicIndex: index => {
      dispatch.changeCurrentTopicIndex(wrapActionPayload(index));
    }
  };
};


export const TopicImpactTopEvolutionEditor = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TopicImpactTopEvolutionEditorComponent));
