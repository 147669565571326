import React from 'react';
import './bubbleMark.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export function BubbleMark({ size, backgroundColor, mark, unit, className }) {
  const bubbleStyle = {
    backgroundColor: backgroundColor,
    width: size,
    height: size
  };

  const fontSize = size / 2.2;

  const markStyle = {
    fontSize: fontSize
  };
  const unitStyle = {
    fontSize: fontSize * 0.6
  };

  const clazz = classNames('bubble-mark', className);

  return <div className={clazz} style={bubbleStyle}>
    <div className="bubble-mark-value" style={markStyle}>{mark}</div>
    <div className="bubble-mark-unit" style={unitStyle}>/{unit}</div>
  </div>
}

BubbleMark.propTypes = {
  mark: PropTypes.number.isRequired,
  unit: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
};


BubbleMark.defaultProps = {
  size: 40,
  className: null
};

