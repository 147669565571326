import { ParamMatchCondition } from '../condition/ParamMatchCondition';
import { Condition } from "../condition/Condition";
import _ from "lodash";
import { ParseError } from "../../../../utils/errors/ParseError";

export const parseCondition = (object: any): Condition => {
  const type = object['@type'];

  if (_.isNil(type)) {
    throw new Error('No @type defined when parsing Condition');
  }

  if (type === 'paramMatch') {
    return ParamMatchCondition.parse(object)
  }

  throw new ParseError(`Cannot parse object with type ${type} into Condition`);
};