import {Colors} from '@blueprintjs/core';
import classnames from 'classnames';
import * as React from 'react';
import {ReactNode} from 'react';
import {BlankSeparator} from './BlankSeparator';
import {SmartIcon} from './icon/SmartIcon';

interface LabelIconProps {
    color?: string,
    className?: string,
    style?: object,
    gap?: number,

    icon: ReactNode,
    iconSize?: number,
    iconColor?: string,
    label: ReactNode,
    iconPosition?: 'left' | 'right'
}

export class LabelIcon extends React.PureComponent<LabelIconProps> {

    static defaultProps: Partial<LabelIconProps> = {
        color: Colors.GRAY1,
        gap: 5,
        iconPosition: 'left'
    };

    render() {
        const {style, className, gap, color, icon, iconColor, iconSize, iconPosition, label} = this.props;

        const icn = <SmartIcon icon={icon as any} color={iconColor || color} size={iconSize}/>;

        return (
            <div className={classnames('vui-layout--horizontal', className)}
                 style={{alignItems: 'center', ...style, color: color}}>
                {iconPosition === 'left' ? <>{icn}<BlankSeparator size={gap}/></> : null}
                {label}
                {iconPosition === 'right' ? <><BlankSeparator size={gap}/>{icn}</> : null}
            </div>
        );
    }
}