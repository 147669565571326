import _ from 'lodash';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { services } from '../../../../application/service/services';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import { EvolutionViz } from '../common/EvolutionViz';
import { ClickableViz } from "../ClickableViz";
import { EvolutionVizFeedbacksQueryContext } from "../../../model/viz/VizFeedbacksQueryContext";
import { connect } from "react-redux";
import { Chart } from "chart.js";
import { CoordinatedData } from "../../../../utils/chart/SerieChartBuilder";
import { FieldValue } from "../../../../application/model/field/FieldValue";

interface VolumeEvolutionProps extends WithTranslation {
  viz: VisualizationInstance,
  openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => void,
};

interface StateProps {
  clickItem: VolumeEvolutionDataItem
}

interface VolumeEvolutionDataItem extends CoordinatedData {
  date: string;
  toDate: string;
}

class VolumeEvolutionVizComponent extends React.PureComponent<VolumeEvolutionProps> implements ClickableViz {

  state: StateProps = {
    clickItem: null,
  }

  getFeedBacksQueryContext(): EvolutionVizFeedbacksQueryContext {

    const result: EvolutionVizFeedbacksQueryContext = new EvolutionVizFeedbacksQueryContext();
    if (this.state.clickItem) {
      const { viz } = this.props;
      const series = viz.definition.params.series.filter(serie => serie.name === this.state.clickItem.serie);
      if (series === 0) throw new Error("Serie'" + this.state.clickItem.serie + "' not found.")
      result.field = series[0].field;
      result.serieName = this.state.clickItem.serie;
      result.selectedValue = this.state.clickItem.group;
      result.startPeriod = this.state.clickItem.date;
      result.endPeriod = this.state.clickItem.toDate;
    }
    return result;
  }

  getClickedFieldValue(): FieldValue {
    if (this.state.clickItem) {
      const { viz } = this.props;
      const series = viz.definition.params.series.filter(serie => serie.name === this.state.clickItem.serie);
      if (series === 0) throw new Error("Serie'" + this.state.clickItem.serie + "' not found.")


      return services.getFieldsService().findFieldValue(series[0].field, this.state.clickItem.group);
    }
    return null;
  }

  onclick(event, items: any[], chart: Chart) {
    if (items.length > 0) {
      this.state.clickItem = items[0].element.$context.raw as VolumeEvolutionDataItem;
      this.props.openFeedbacksPanel(this, this.state.clickItem.label);
    }
  }

  getSerieLabel = (serieParam) => {
    const { t, viz } = this.props;

    if (!_.isUndefined(serieParam.field)) {
      const field = services.getFieldsService().getField(serieParam.field);
      return field.getLabel(t);
    } else if (!_.isUndefined(serieParam.constraint)) {
      const constraint = services.getDashboardService().getOriginalVizSpec(viz).params.series.find(
        s => s.name === serieParam.name).constraint;
      const serieTitle = services.getDashboardParamsService().findParamLabelFromConstraint(t, viz, true, constraint);

      return serieTitle || serieParam.name;
    } else {
      const i18nKey = `dashboard.viz.volumeEvolution.serie.global`;
      return t(i18nKey);
    }
  };

  render() {
    let { viz } = this.props;

    return <EvolutionViz onClick={this.onclick.bind(this)} viz={viz} getSerieLabel={this.getSerieLabel}
                         yAxis={{ min: 0 }}/>;
  }


}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => {
      dispatch.dashboard.openFeedBackPanel({ viz, panelTitle });
    },
  };
};

export const VolumeEvolutionViz = withTranslation(undefined, { withRef: true })(connect(mapStateToProps, mapDispatchToProps,null, {forwardRef: true})(VolumeEvolutionVizComponent));

