import { mdiTrendingDown, mdiTrendingNeutral, mdiTrendingUp } from '@mdi/js';
import { Tonality } from '@eptica/vecko-js-commons';

export function trendSpec(value) {
  let iconPath;
  let color;

  if (value === 0) {
    iconPath = mdiTrendingNeutral;
    color = Tonality.NEUTRAL.color;
  } else if (value > 0) {
    iconPath = mdiTrendingUp;
    color = Tonality.POSITIVE.color;
  } else {
    iconPath = mdiTrendingDown;
    color = Tonality.NEGATIVE.color;
  }

  return { iconPath, color };
}
