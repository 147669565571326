import {services} from '../../application/service/services';

export const tenantState = () => ({
    state: {
        isCrossTenant: services.getTenantService().currentTenant?.name === "crosstenant" || services.getTenantService().currentTenant?.name === "tenant",
        tenants: [],
        selectedTenantId: services.getTenantService().currentTenant?.id,
        selectedTenant: services.getTenantService().currentTenant,
    },
    reducers: {
        setTenants(state, payload) {
            state.tenants = payload;
            return state;
        },
        setSelectedTenantId(state, payload) {
            state.selectedTenantId = payload;
            if (payload === '*') {
                state.selectedTenant = state.tenants;
            } else {
                state.selectedTenant = state.tenants.find(t => t.id === payload);
            }
            return state;
        },
        setSelectedTenant(state, payload) {
            state.selectedTenant = payload;
            state.selectedTenantId = payload.id;
            return state;
        }
    },
    effects: (dispatch) => ({
        async loadTenants(payload, rootState) {
            dispatch.tenant.setTenants(await services.getTenantService().getTenants());
        }
    })
});