import React from 'react';
import { trueSupplier } from '../utils/functionUtils';
import './feedbackForReport.scss';
import { InnerFeedback } from './InnerFeedback';
import { SatScore } from './SatScore';
import { FeedbackDTO } from "../application/model";
import { FeedbackHeader, FeedbackHeaderProps } from "./FeedbackHeader";

interface FeedbackForReportProps {
  feedback: FeedbackDTO,
  headerProps?: FeedbackHeaderProps,
  condensed?: boolean
}

export class FeedbackForReport extends React.PureComponent<FeedbackForReportProps> {
  static defaultProps: Partial<FeedbackForReportProps> = {
    condensed: true
  };

  render() {
    const { feedback, headerProps, condensed } = this.props;
    return <div className='vui-layout--horizontal' style={{ alignItems: 'center' }}>
      <SatScore feedback={feedback}/>
      <div className='vui-layout--horizontal__center' style={{ marginLeft: 10 }}>
        <InnerFeedback feedback={feedback}
                       centerOnOffset={condensed}
                       header={<FeedbackHeader {...headerProps} feedback={feedback}/>}
                       shouldHighlight={trueSupplier}
                       separatorHeight={4}
                       verbatimClassName={'sampleFeedback ' + (condensed ? 'condensed-verbatim' : undefined)}
        />
      </div>
    </div>
  }
}
