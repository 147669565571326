import { Button, Intent, MenuItem, Tag } from '@blueprintjs/core';
import { MultiSelectProps, MultiSelect } from '@blueprintjs/select';
import React, {ReactNode, RefCallback} from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { arrayContains } from '../../utils/collectionUtils';
import './multiSelectInput.scss';
import { MultiSelection } from './utils/MultiSelection';
import { ISelectItemRenderer, ISelectUtilProps, SelectUtil } from './utils/selectUtils';
import { Tooltip } from "@blueprintjs/core";

interface MultiSelectInputProps<T> extends WithTranslation, ISelectUtilProps<T> {
    items?: Array<T>;
    selectedItems?: Array<T>;
    onSelectionChanged: OnChangeHandler<Array<T>>,
    placeholder?: string,
    itemId?: (item: T) => string,
    itemLabel?: (item: T) => string,
    itemIcon?: (item: T) => JSX.Element,
    itemRenderer: ISelectItemRenderer<T>,
    itemEqual: EqualComparator<T>,
    selectProps?: Partial<MultiSelectProps<T>>,
    allowEmptySelection?: boolean,
    visibleItemsCount?: number
}

class MultiSelectInputComponent<T> extends React.PureComponent<MultiSelectInputProps<T>> {
    private static defaultEqualityTester = (a, b) => a === b;
    private static OVERFLOW_ITEM = Symbol('OVERFLOW');
    private static defaultItemRenderer: ISelectItemRenderer = (item, context, itemProps) => {
        if (item === MultiSelectInputComponent.OVERFLOW_ITEM) {
            return null;
        }
        return (
            <MenuItem
                active={itemProps.modifiers.active}
                icon={context.selected ? 'tick' : 'blank'}
                disabled={itemProps.modifiers.disabled}
                key={context.id}
                onClick={itemProps.handleClick}
                text={context.highlightedText}
            />
        );
    };

    private input: HTMLInputElement;
    private overflowTag: HTMLElement;
    private readonly inputRef: RefCallback<HTMLInputElement> = (o) => this.input = o;
    private readonly overflowRef: RefCallback<HTMLInputElement> = (o) => this.overflowTag = o;

    private selectUtil: SelectUtil<T>;
    private multiSelection: MultiSelection<T>;

    static defaultProps: Partial<MultiSelectInputProps<any>> = {
        items: [],
        selectedItems: [],
        selectProps: {},
        allowEmptySelection: true
    }

    constructor(props) {
        super(props);

        this.selectUtil = new SelectUtil(MultiSelectInputComponent.defaultItemRenderer);
        this.selectUtil.itemLabel = this.getItemLabel;
        this.selectUtil.itemIcon = this.getItemIcon;
        this.selectUtil.itemId = this.getItemId;
    }

    private readonly getItemLabel = (item: T | Symbol): string => {
        if (item === MultiSelectInputComponent.OVERFLOW_ITEM) {
            return '';
        }
        const { itemLabel } = this.props;
        return itemLabel ? itemLabel(item as T) : item as any as string;
    };

    private getItemIcon = (item: T | Symbol): JSX.Element => {
        if (item === MultiSelectInputComponent.OVERFLOW_ITEM) {
            return null;
        }
        const { itemIcon } = this.props;
        return itemIcon ? itemIcon(item as T) : null;
    };

    private getItemId = (item: T | Symbol): string => {
        if (item === MultiSelectInputComponent.OVERFLOW_ITEM) {
            return '$$$$$$$$$_OVERFLOW_ITEM_$$$$$$$$$';
        }
        const { itemId } = this.props;
        return itemId ? itemId(item as T) : item as any as string;
    };

    private getVisibleItems(): Array<T> {
        const { selectedItems, visibleItemsCount } = this.props;

        if (visibleItemsCount && visibleItemsCount > -1) {
            return selectedItems.slice(0, visibleItemsCount);
        }
        return selectedItems;
    }

    private getOverflowItems(): Array<T> {
        const { selectedItems, visibleItemsCount } = this.props;

        if (visibleItemsCount && visibleItemsCount > -1) {
            return selectedItems.slice(visibleItemsCount);
        }
        return [];
    }

    private renderOverflow(): ReactNode {
        const overflowItems = this.getOverflowItems();
        return <Tooltip className={'vui-multi-select-overflow'}
                         content={<div>{overflowItems.map(it => <div
                           key={this.getItemId(it)}>{this.getItemLabel(it)}</div>)}</div>}>
            {overflowItems.length > 0 ? <Tag ref={this.overflowRef}>{`+ ${overflowItems.length}`}</Tag> :
              <span/>}
        </Tooltip>;
    }

    private renderItemTag = (o: T | Symbol): ReactNode => {
        if (o === MultiSelectInputComponent.OVERFLOW_ITEM) {
            return this.renderOverflow();
        }

        const item = o as T;

        const { itemEqual } = this.props;
        const equalityTester = itemEqual || MultiSelectInputComponent.defaultEqualityTester;

        const visibleItems = this.getVisibleItems();

        if (arrayContains(visibleItems, (it) => equalityTester(it, item))) {
            return this.getItemLabel(item);
        } else {
            return null;
        }
    };

    private isValid(): boolean {
        return this.props.allowEmptySelection || this.props.selectedItems.length > 0;
    }

    private openPopover = () => {
        if (this.input) {
            this.input.focus();
        }
    };

    private onClearAll = (event) => {
        event.stopPropagation();// this prevents the input from getting focus (and thus, avoids showing the popover)
        this.multiSelection.unselectAll();
    };

    private onRemoveItem = (tag, index) => {
            this.multiSelection.unselectAt(index);
    };

    private onItemSelect = (item: T) => {
        if (!(this.multiSelection.getSelection().length === 1 && this.multiSelection.isSelected(item) && this.props.allowEmptySelection === false)) {
            this.multiSelection.toggleSelect(item);
        }
    }

    private updateOverflowPosition() {
        // this will move the overflow indicator inside the input
        if (this.overflowTag) {
            this.input.parentElement.insertBefore(this.overflowTag.parentElement, this.input);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateOverflowPosition();
    }

    private refreshController(): void {
        this.multiSelection = new MultiSelection(this.props.items, this.props.selectedItems, this.props.onSelectionChanged, this.props.itemEqual);

        this.selectUtil.props = this.props;
        this.selectUtil.isSelectedFn = this.multiSelection.isSelected;
    }

    render() {
        const { t } = this.props;
        const { tagInputProps, popoverProps, ...selectProps } = this.props.selectProps;

        this.refreshController();

        const rightButton = (this.props.selectedItems?.length && this.props.allowEmptySelection) ?
            <Button icon='cross' onClick={this.onClearAll} minimal={true}/> :
            <Button icon='chevron-down' onClick={this.openPopover} minimal={true}/>;
        const rightElement = <>
            {rightButton}
            {this.renderOverflow()}
        </>;

        setTimeout(() => {
            this.updateOverflowPosition();
        }, 0);

        return <MultiSelect
            className='vui-multi-select-input'
            noResults={<MenuItem disabled={true} text={t('noResult')}/>}
            onItemSelect={this.onItemSelect}
            activeItem={null}
            popoverProps={{
                rootBoundary: 'viewport',
                ...popoverProps,
            }}
            resetOnSelect={false}
            {...selectProps}
            tagInputProps={{
                ...tagInputProps,
                rightElement: rightElement,
                inputRef: this.inputRef,
                onRemove: this.onRemoveItem,
                intent: this.isValid() ? undefined : Intent.DANGER
            }}
            items={this.props.items}
            selectedItems={this.props.selectedItems}
            itemPredicate={this.selectUtil.itemPredicate}
            itemRenderer={this.selectUtil.renderItem}
            tagRenderer={this.renderItemTag}
            placeholder={this.props.placeholder}
        />;
    }
}

export const MultiSelectInput = withTranslation()(MultiSelectInputComponent);