import React from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import _ from 'lodash';
import measureText from '../utils/d3_measure_text';
import uniqueId from 'react-html-id';
import {TrendPath} from './trend/trendPath';
import {trendSpec} from './trend/trendSpec';
import {signed} from '../utils/text_transform';
import {Tonality} from '@eptica/vecko-js-commons';

const percentToDegree = p => p * 360;

interface GaugeChartProps {
    value: number,
    trend?: number,
    min?: number,
    max?: number,
    showArc?: boolean,
    arcOpacity?: number,
    showMinMax?: boolean,
    showValue?: boolean,
    valueStyle?: any,
    strokeSize?: number,
    needleSize?: any,
    gradient?: any[]
    onClick?: (event) => void
    onDoubleClick?: (event) => void
    onContextMenu?: (event) => void
}

interface GaugeChartState {
    preferredWidth?: any,
    preferredHeight?: any,
    arc?: any,
    needle?: any,
    minMaxLabel?: any,
    trend?: any,
    valueLabel?: any
}

export class GaugeChart extends React.PureComponent<GaugeChartProps> {
    private mounted: boolean;
    private listener: any;

    state: GaugeChartState = {};

    static defaultProps = {
        min: -100,
        max: 100,
        strokeSize: 14,
        showArc: true,
        arcOpacity: 1,
        showMinMax: true,
        showValue: true,
        needleSize: (props) => {
            return props.strokeSize;
        },
        gradient: [
            {offset: '0%', color: Tonality.NEGATIVE.color},
            {offset: '50%', color: '#FFC418'},
            {offset: '100%', color: Tonality.POSITIVE.color}
        ]
    };

    constructor(props) {
        super(props);
        uniqueId.enableUniqueIds(this);
    }

    componentDidMount() {
        this.mounted = true;
        this.listener = _.debounce(() => {
            this.doLayout();
        }, 100);

        window.addEventListener('resize', this.listener);
        this.doLayout();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.listener);
    }

    componentDidUpdate(prevProps: Readonly<GaugeChartProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.trend !== this.props.trend) {
            this.setState({trend: this.computeTrend(this.props)})
        }
    }

    doLayout = () => {
        const props = this.props;
        const node = ReactDOM.findDOMNode(this);
        const baseWidth = (node.parentNode as any).offsetWidth;
        const baseHeight = (node.parentNode as any).offsetHeight;

        const isHorizontalShape = baseWidth >= baseHeight * 2;

        const sizeWeight = isHorizontalShape ? baseHeight * 2 : baseWidth;

        const margin = {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        };

        // needle ------------------------
        const needleSize = _.isFunction(props.needleSize) ? props.needleSize(props, sizeWeight) : props.needleSize;
        const needle = {
            size: needleSize,
            height: needleSize - 5,
            gap: 5
        };

        // A margin is needed when value is near min or near max
        margin.bottom = needleSize / 2;

        // min-max labels ------------------------
        let minMaxLabel;
        if (props.showMinMax) {
            const minMaxFontSize = Math.ceil(0.038 * sizeWeight);

            minMaxLabel = {
                gap: 0,
                style: {
                    fontSize: `${minMaxFontSize}px`
                }
            };

            const minLabelSize = measureText(props.min + '', {style: minMaxLabel.style});
            const maxLabelSize = measureText(props.max + '', {style: minMaxLabel.style});
            const minMaxLabelWidth = Math.max(minLabelSize.width, maxLabelSize.width);

            margin.left = margin.right = Math.max(0, (minMaxLabelWidth - props.strokeSize) / 2);

            const minMaxLabelHeight = Math.max(minLabelSize.height, maxLabelSize.height);
            minMaxLabel.y = minMaxLabelHeight + minMaxLabel.gap;

            // adjust bottom margin
            margin.bottom = Math.max(margin.bottom, minMaxLabel.y);
        }

        // round margin
        _.mapValues(margin, Math.ceil);

        // calculate preferred size
        let preferredWidth, preferredHeight;
        if (isHorizontalShape) {
            preferredHeight = baseHeight;
            preferredWidth = (baseHeight - margin.bottom) * 2;
        } else {
            preferredWidth = baseWidth - 1;
            preferredHeight = Math.floor(preferredWidth / 2 + margin.bottom);
        }

        // arc ------------------------
        const arc: any = {
            outerRadius: (preferredWidth - margin.left - margin.right) / 2
        };
        arc.innerRadius = arc.outerRadius - props.strokeSize;
        arc.center = {
            x: arc.outerRadius + margin.left,
            y: arc.outerRadius
        };
        // if (isHorizontalShape) {
        //   arc.center.x = arc.center.x + baseWidth/2;
        // } else {
        //   arc.center.y = arc.center.y + baseHeight/2;
        // }

        // value label ------------------------
        let valueLabel;
        if (props.showValue) {
            const valueFontSize = Math.ceil(0.22 * preferredWidth);

            const defaultValueStyle = {
                fontSize: `${valueFontSize}px`,
                fontWeight: '600',
                fill: '#4d5353'
            };

            const valueStyle = _.assign(defaultValueStyle, this.props.valueStyle);

            valueLabel = {
                style: valueStyle,
                y: -Math.ceil(preferredHeight * 0.22)
            };
        }

        // trend
        let trend = this.computeTrend(props);


        // set final state ------------------------
        const state = {preferredWidth, preferredHeight, needle, arc, minMaxLabel, valueLabel, trend};
        // console.log(state);

        if (!this.mounted) {
            this.state = state;
        } else {
            this.setState(state);
        }
    };

    private computeTrend(props: Readonly<GaugeChartProps>) {
        let trend;
        if (!_.isUndefined(props.trend)) {
            const trendSp = trendSpec(props.trend);

            const valueText = `${signed(props.trend)}`;
            const valueStyle = {
                fontSize: `${24}px`,
                fontWeight: 500,
                fill: trendSp.color
            };
            const valueDim = measureText(valueText, {style: valueStyle});

            const unitText = ` point` + (Math.abs(props.trend) > 1 ? 's' : '');
            const unitStyle = {
                fontSize: `${18}px`,
                fill: trendSp.color
            };
            const unitDim = measureText(unitText, {style: unitStyle});

            const iconGap = 10; //gap between icon and value
            const textGap = 5; //gap between value and unit
            const iconWidth = 20;
            const x = -(iconWidth + iconGap + valueDim.width + textGap + unitDim.width) / 2;

            trend = {
                value: {
                    text: valueText,
                    style: valueStyle,
                    x: iconWidth + iconGap
                },
                unit: {
                    text: unitText,
                    style: unitStyle,
                    x: iconWidth + iconGap + textGap + valueDim.width
                },
                x
            };
        }
        return trend;
    }

    render() {
        const state = this.state;

        if (!this.mounted) {
            if (state) {
                return (
                    <div style={{width: state.preferredWidth, height: state.preferredHeight}}/>
                );
            } else {
                return (
                    <div/>
                );
            }
        }

        const props = this.props;

        const translateToCenter = `translate(${state.arc.center.x},${state.arc.center.y})`;
        // const centerPointStyle = { stroke: '#aaa', strokeWidth: 1 };
        // const centerPointUi = <g>
        //   <line x1={-state.preferredWidth/2} y1="0" x2={state.preferredWidth/2} y2="0" style={centerPointStyle}/>
        //   <line x1="0" y1="-5" x2="0" y2="5" style={centerPointStyle}/>
        // </g>;

        // gradient ------------------------
        const gradientId = this['getUniqueId']('gradient');
        const gradientUi = <defs>
            <linearGradient id={gradientId}>
                {
                    props.gradient.map((g, index) => {
                        return <stop key={index} offset={g.offset} style={{stopColor: g.color}}/>;
                    })
                }
            </linearGradient>
        </defs>;

        // arc ------------------------
        const arc: any = d3.arc();
        arc
            .innerRadius(state.arc.innerRadius)
            .outerRadius(state.arc.outerRadius)
            .startAngle(-Math.PI / 2)
            .endAngle(Math.PI / 2)
            .cornerRadius(props.strokeSize / 2)
        ;
        const arcUi = <g width={state.arc.outerRadius} height={state.arc.outerRadius}>
            <path d={arc()} fill={'url(#' + gradientId + ')'}/>
        </g>;

        // needle ------------------------
        const lx = 0;
        const ly = -state.needle.size / 2;
        const rx = 0;
        const ry = state.needle.size / 2;
        const topx = -state.needle.height;
        const topy = 0;
        const needlePath = `M ${lx} ${ly} L ${topx} ${topy} L ${rx} ${ry}`;

        const min = Math.max(props.min, 0);
        const delta = min - props.min;
        const max = props.max + delta;
        let value = props.value + delta;
        if (value < min) {
            value = min;
        } else if (value > max) {
            value = max;
        }
        const percent = (value - min) / (max - min);
        const needleRotation = percentToDegree(percent) / 2;
        const needleTranslateX = -(state.arc.innerRadius - state.needle.height - state.needle.gap);
        const needleTransform = `rotate(${needleRotation})translate(${needleTranslateX},0)`;

        const needleUi = <g>
            <path d={needlePath} style={{fill: '#ccc'}} transform={needleTransform}/>
        </g>;

        // min/max labels ------------------------
        let minMaxUI: any = '';
        if (props.showMinMax) {
            const minLabelX = -state.arc.outerRadius + props.strokeSize / 2;
            const maxLabelX = state.arc.outerRadius - props.strokeSize / 2;

            minMaxUI = <g>
                <text x={minLabelX} y={state.minMaxLabel.y} textAnchor="middle"
                      fill="black" style={state.minMaxLabel.style}>{props.min}</text>
                <text x={maxLabelX} y={state.minMaxLabel.y} textAnchor="middle"
                      fill="black" style={state.minMaxLabel.style}>{props.max}</text>
            </g>;
        }

        let valueUi: any = '';
        if (props.showValue) {
            valueUi = <g>
                <text x={0} y={state.valueLabel.y} textAnchor="middle"
                      onContextMenu={event => {
                          if (props.onContextMenu) props.onContextMenu(event);
                      }}
                      onDoubleClick={event => {
                          if (props.onDoubleClick) props.onDoubleClick(event);
                      }}
                      onClick={event => {
                          if (props.onClick) props.onClick(event);
                      }}
                      fill="black" style={state.valueLabel.style}>{props.value}</text>
            </g>;
        }

        let trendUi: any = '';
        if (!_.isNil(props.trend)) {
            trendUi = <g transform={`translate(${state.trend.x}, -10)`}>
                <TrendPath value={props.trend}/>
                <text transform={`translate(${state.trend.value.x}, 20)`}
                      textAnchor="start"
                      style={state.trend.value.style}>
                    {state.trend.value.text}
                </text>
                <text transform={`translate(${state.trend.unit.x}, 20)`}
                      textAnchor="start"
                      style={state.trend.unit.style}>{state.trend.unit.text}</text>
            </g>;
        }

        const centerStyle: any = {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        };

        return <div style={centerStyle}>
            <svg className="c-chart-gauge" width={state.preferredWidth} height={state.preferredHeight + 2}>
                {gradientUi}

                <g transform={translateToCenter}>
                    {/*{centerPointUi}*/}

                    {
                        props.showArc ? <g opacity={props.arcOpacity}>{arcUi}{needleUi}{minMaxUI}</g> : ''
                    }
                    {valueUi}
                    {trendUi}
                </g>
            </svg>
        </div>;
    }
}


