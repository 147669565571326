import { NumericInput, Switch } from '@blueprintjs/core';
import * as React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import { BlankSeparator } from '../../../../component/BlankSeparator';
import { ColorPicker } from '../../../../component/color/ColorPicker';
import { EditorItem } from '../../../../component/editor/EditorItem';
import { SeparatedComponents } from '../../../../component/SeparatedComponents';
import './distributionEditor.scss';
import _ from "lodash";

interface DistributionEditorProps extends WithTranslation{
  onValueChanged:any,
  editorData:any
}

class DistributionEditorComponent extends React.PureComponent<DistributionEditorProps> {
  onSizeChanged = (size) => {
    const { onValueChanged } = this.props;
    onValueChanged('params', 'size', size);
  };

  onShowPercentChanged = () => {
    this.switchUiParamsProp('showPercent');
  };

  onShowVolumeChanged = () => {
    this.switchUiParamsProp('showVolume');
  };

  onShowTrendChanged = () => {
    this.switchUiParamsProp('showTrend');
  };

  switchUiParamsProp(prop) {
    const { onValueChanged, editorData } = this.props;

    onValueChanged('uiParams', prop, !_.get(editorData.uiParams, prop));
  }

  isLabelOverride() {
    const { editorData } = this.props;
    return !_.isNil(editorData.uiParams.label);
  }

  onOverrideLabelChanged = () => {
    const { onValueChanged } = this.props;
    if (this.isLabelOverride()) {
      onValueChanged('uiParams', 'label', null);
    } else {
      onValueChanged('uiParams', 'label', '');
    }
  };

  onLabelChanged = (event) => {
    const { onValueChanged } = this.props;
    onValueChanged('uiParams', 'label', event.target.value);
  };

  onColorChanged = (hex) => {
    const { onValueChanged } = this.props;
    onValueChanged('uiParams', 'color', hex);
  };

  render() {
    const { t, editorData } = this.props;

    return <div className='distributionEditor'>
      <SeparatedComponents separator={<BlankSeparator vertical={true}/>}>
        <EditorItem title={t('dashboard.viz.distribution.editor.size')}>
          <NumericInput value={editorData.params.size} max={100} onValueChange={this.onSizeChanged}/>
        </EditorItem>
        <EditorItem title={t('dashboard.viz.distribution.editor.display')}>
          <SeparatedComponents separator={<BlankSeparator vertical={true}/>}>
            <Switch checked={editorData.uiParams.showPercent}
                    label={t('dashboard.viz.distribution.editor.showPercent')}
                    onChange={this.onShowPercentChanged}/>
            <Switch checked={editorData.uiParams.showVolume}
                    label={t('dashboard.viz.distribution.editor.showVolume')}
                    onChange={this.onShowVolumeChanged}/>
            <Switch checked={editorData.uiParams.showTrend}
                    label={t('dashboard.viz.distribution.editor.showTrend')}
                    onChange={this.onShowTrendChanged}/>
          </SeparatedComponents>
        </EditorItem>
        <EditorItem title={t('dashboard.viz.distribution.editor.color')}>
          <ColorPicker color={editorData.uiParams.color} onChanged={this.onColorChanged}/>
        </EditorItem>
      </SeparatedComponents>
    </div>;
  }
}

export const DistributionEditor = withTranslation()(DistributionEditorComponent);