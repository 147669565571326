import { VolumeEvolutionViz } from './volumeEvolutionViz';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { services } from "../../../../application/service/services";
import _ from "lodash";
import { ResolvedParams } from "../../../model/params/resolve/ResolvedParams";
import { getTitle } from "../common";

registerVizType({
  name: 'VOLUME_EVOLUTION',
  component: VolumeEvolutionViz,
  title: getTitle,
  noDataPolicy: {
    hasData: vizInstance => {
      return vizInstance.data?.values?.length > 0;
    }
  },
  specResolver : (vizDefinitionSpec, resolvedParams: ResolvedParams) => {
    const params = vizDefinitionSpec.params;
    params.series.forEach(serie => {
      if(!_.isNil(serie.field)){
        const fieldName = serie.field;
        const field = services.getFieldsService().getField(fieldName);

        /*check all  inclusions and exclusions values to replace first_level value with all corresponding values*/
        if (serie.inclusions) {
          serie.inclusions = services.getDashboardService().resolveFirstLevelValues(field, serie.inclusions);
        }
        if (serie.exclusions) {
          serie.exclusions = services.getDashboardService().resolveFirstLevelValues(field, serie.exclusions);
        }
      }
    })

    return vizDefinitionSpec;
  },
  prepareViz: (vizDefinitionSpec, data) => {

    const seriesName = data.series;

    _.each(seriesName, serie => {
      const serieParam = _.find(vizDefinitionSpec.spec.params.series, ['name', serie]);

      const chartSeries = [];
      if (data.length === 0) return null;

      if (serieParam.field) {
        const valuesByDate = data.values;

        _.each(valuesByDate, valuesForDate => {
          const fieldValues = valuesForDate.values[serie].values;
          Object.entries(fieldValues).forEach(([fieldValue, value]) => {
            services.getColorService().registerFieldAndValue(serieParam.field, fieldValue);
          });
        });
      }
    });
  }
});