import { DateRangeStateConverter } from './DateRangeStateConverter';
import { FieldValueStateConverter } from './FieldValueStateConverter';
import { StringStateConverter } from './StringStateConverter';
import { TonalityStateConverter } from './TonalityStateConverter';
import { StateConverter } from "./StateConverter";
import { BooleanStateConverter } from "./BooleanStateConverter";

class StateConverterRegistry {
  private readonly _converters = new Map<Class<any>, StateConverter<any>>();

  constructor() {
    this._register(new StringStateConverter());
    this._register(new DateRangeStateConverter());
    this._register(new FieldValueStateConverter());
    this._register(new TonalityStateConverter());
    this._register(new BooleanStateConverter());
  }

  get<T>(clazz:Class<T>):StateConverter<T> {
    return this._converters.get(clazz);
  }

  private _register<T>(stateConverter: StateConverter<T>): void {
    this._converters.set(stateConverter.clazz(), stateConverter);
  }
}

export const stateConverterRegistry = new StateConverterRegistry();