import {HistoryRouter as Router} from 'redux-first-history/rr6';
import React from 'react';
import {Provider} from 'react-redux';
import {DashboardExport} from '../report/view/DashboardExport';
import {services} from "../application/service/services";
import {disableAnimation} from "../utils/chart/chartUtils";
import FontFaceObserver from "fontfaceobserver";
import {VeckoFonts} from "../style/VeckoFont";

export class ExportEntryPoint extends React.PureComponent {
    state = {
        fontLoaded: false
    };

    setFontActive() {
        this.setState({fontLoaded: true})
    }

    render() {
        /* active function is called when all font are loaded and available.
         * We use block configuration to prevent display if font is not retrieve */
        var font400 = new FontFaceObserver(VeckoFonts.textFont, {weight: 400});
        var font600 = new FontFaceObserver(VeckoFonts.textFont, {weight: 600});

        Promise.all([font400.load(), font600.load()]).then(this.setFontActive.bind(this)).catch(function (err) {
            console.warn('Some critical font are not available:', err);
        });

        disableAnimation();

        return <Provider store={services.getApplicationService().store}>
            <Router history={services.getApplicationService().history}>
                {this.state.fontLoaded ? <DashboardExport/> : undefined}
            </Router>
        </Provider>;
    }
}