import { ApiFilter } from './ApiFilter';
import _ from "lodash";

export class RangeFilter extends ApiFilter {
  static parse(object):ApiFilter {
    return new RangeFilter(object.field, object.start, object.end);
  }

  field:string;
  start:number;
  end:number;

  constructor(field:string, start:number, end:number) {
    super('range');
    this.field = field;
    this.start = start;
    this.end = end;
  }

  toPlainObject() {
    if (_.isEmpty(this.field)) {
      throw new Error('field is mandatory')
    }

    const result:any = super.toPlainObject();
    result.field = this.field;
    if (!_.isNil(this.start)) {
      result.start = this.start;
    }
    if (!_.isNil(this.end)) {
      result.end = this.end;
    }
    return result;
  }

  getCopy():ApiFilter {
    const apiFilter = new RangeFilter(this.field, this.start, this.end);
    return apiFilter
  }
}