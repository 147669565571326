import React from 'react';
import { Satisfaction } from '../../../model/Satisfaction';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import './customerCommentViz.scss';
import { FeedbackForReport } from "../../../../feedback/FeedbackForReport";
import { FeedbackHeaderProps } from "../../../../feedback/FeedbackHeader";
import { services } from "../../../../application/service/services";
import _ from "lodash";


class CustomerCommentProps {
  viz: VisualizationInstance
}

export class CustomerCommentViz extends React.PureComponent<CustomerCommentProps> {

  renderSamples(samples) {

    const { viz } = this.props;
    const headerFields = [];

    viz.definition.params.headerFields?.forEach((fieldName: string) => {
      headerFields.push(services.getFieldsService().getFieldValues(fieldName));
    })

    return samples.map((sample, index) => {

      const headerProps: FeedbackHeaderProps = {
        renderDate: true,
        renderChannel: false,
        renderSatisfactionIndicator: false,
        fieldsHeaderGetter: () => {
          return headerFields;
        }
      }

      const result = [
        <div key={index} className='vui-interactive'
             style={{ padding: 5 }}>
          <FeedbackForReport feedback={sample} headerProps={headerProps}/>
        </div>];
      if (index < samples.length - 1) {
        result.push(<hr key={index + '_sep'}/>);
      }

      return result;
    });
  }

  render() {
    const { viz } = this.props;
    let sat = viz.definition.params.sat;
    const customerKind = Satisfaction['getValueOf'](sat).customerKind;

    const bottomStyle = {
      backgroundColor: customerKind.color
    };
    const data = viz.data

    return <div className="customers-comment">

      {data.profileItems ?
        <div className='customers-comment-list'>
          <ul>
            {
              _.map(data.profileItems, (it, i) => {
                return <li key={i}>{it}</li>;
              })
            }
          </ul>
        </div>
        : null
      }

      {data.title ?
        <div className="customers-comment-title"
             style={{ textAlign: "center", fontWeight: 600, fontSize: 18, textTransform: 'uppercase' }}>
          {data.title}
        </div>
        : null}
      <div className="customers-comment-feedbacks">
        {
          data.feedbacks?.length > 0 ? this.renderSamples(data.feedbacks) : null
        }
      </div>

      {data.comments ?
        <div className='customers-comment-bottom' style={bottomStyle}>
          <div className='customers-comment-list'>
            <ul>
              {
                _.map(data.comments, (it, i) => {
                  return <li key={i}>{it}</li>;
                })
              }
            </ul>
          </div>
        </div>
        : null}

    </div>;
  }
}
