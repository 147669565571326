import { Predicate } from './Predicate';
import _ from 'lodash';

export class InstanceOf<T> extends Predicate<T> {
  private readonly expectedClass: Class<T>;
  private readonly allowNil: boolean;

  constructor(clazz: Class<T>, allowNil: boolean = true) {
    super();

    if (_.isNil(clazz)) {
      throw new Error('Given clazz cannot be nil');
    }
    if (!_.isFunction(clazz) || !clazz.name) {
      throw new Error(`Given clazz ${clazz} does not seam to be a class declaration`);
    }

    this.expectedClass = clazz;
    this.allowNil = allowNil;
  }

  match(object: T): boolean {
    if (_.isNil(object)) {
      return this.allowNil;
    }
    return object instanceof this.expectedClass;
  }

  message(): string {
    return `instance of ${this.expectedClass.name}`;
  }
}