import _ from "lodash";
import { services } from "../service/services";
import { dateFormat, isFirstDayOfMonth, isLastDayOfMonth } from "../../utils/dateUtils";
import { Interval } from "./Interval";
import { DateTime, Duration } from "luxon";

export class DateRange {

  start: DateTime;

  end: DateTime;

  constructor(start: DateTime, end: DateTime) {
    this.start = start.startOf('day');
    this.end = end.endOf('day');
  }

  static parse(object): DateRange {
    if (_.isEmpty(object.start)) {
      throw new Error(`start is mandatory`);
    }
    if (_.isEmpty(object.end)) {
      throw new Error(`end is mandatory`);
    }

    return new DateRange(
      DateTime.fromFormat(object.start, dateFormat),
      DateTime.fromFormat(object.end, dateFormat),
    );
  }

  /**
   *
   * @param {{start: Date, end: Date}} dateObject
   * @return {DateRange}
   */
  static fromDate(dateObject): DateRange {
    return new DateRange(DateTime.fromJSDate(dateObject.start), DateTime.fromJSDate(dateObject.end));
  }

  toDateArray(): Date[] {
    return [this.start.toJSDate(), this.end.toJSDate()];
  }

  /**
   * @return {{start: Date, end: Date}}
   */
  toDate() {
    return {
      start: this.start.toJSDate(),
      end: this.end.toJSDate()
    };
  }

  /**
   * @return {{start: string, end: string, timeZone: string}}
   */
  toPlainObject() {
    return {
      start: this.start.toFormat(dateFormat),
      end: this.end.toFormat(dateFormat),
      zoneId: services.getTimeZoneService().getTimeZone()
    };
  }

  endToNow(): DateRange {
    return new DateRange(this.start, DateTime.now());
  }

  equals(dateRange: DateRange): boolean {
    if (!dateRange) {
      return false;
    }
    if (!(dateRange instanceof DateRange)) {
      return false;
    }

    return dateRange.start.ordinal === this.start.ordinal
      && dateRange.end.ordinal === this.end.ordinal;
  }

  toString(): string {
    return this.format(dateFormat);
  }

  format(format: string): string {
    return `[${this.start.toFormat(format)} - ${this.end.toFormat(format)}]`;
  }

  shift(amount: number): DateRange {
    const monthsCount = this.numberOfMonths();
    if (monthsCount) {
      const monthsToAdd = amount * monthsCount;

      const start = this.start.plus({ 'month': monthsToAdd });
      const end = start.plus({ 'month': monthsCount - 1 }).endOf('month');

      return new DateRange(start, end);
    }

    const daysCount = Math.trunc(this.end.diff(this.start, 'day').days) + 1;

    return new DateRange(
      this.start.plus({ 'day': amount * daysCount }),
      this.end.plus({ 'day': amount * daysCount }),
    );
  }

  isFullMonthBounded(): boolean {
    return isFirstDayOfMonth(this.start) && isLastDayOfMonth(this.end);
  }

  numberOfMonths(): number {
    if (!this.isFullMonthBounded()) {
      return undefined;
    }

    return Math.trunc(this.end.diff(this.start, 'month').as('month')) + 1;
  }

  getBestInterval(): Interval {
    const duration = this.end.diff(this.start);

    const breakPoints = [
      {
        duration: Duration.fromObject({ month: 4 }),
        slice: new Interval(1, 'month')
      },
      {
        duration: Duration.fromObject({ month: 2 }),
        slice: new Interval(1, 'week')
      },
      {
        duration: Duration.fromObject({ month: 1 }),
        slice: new Interval(1, 'day')
      },
      {
        duration: Duration.fromObject({ day: 2 }),
        slice: new Interval(1, 'day')
      },
      {
        duration: Duration.fromObject({ day: 1 }),
        slice: new Interval(1, 'hour')
      }
    ];

    for (let breakPoint of breakPoints) {
      if (duration.as('milliseconds') >= breakPoint.duration.as('milliseconds')) {
        return breakPoint.slice;
      }
    }

    return new Interval(1, 'hour');
  }
}
