import { services } from '../../service/services';

export const filterState = () => ({
  state: {
    open: undefined
  },
  reducers: {
    init(state, payload) {
      if (services.getFilterService().currentFilter) {
        Object.entries(state).forEach(([key, value]) => {
          if (services.getFilterService().currentFilter.hasFilter(key)) {
            const converter = services.getFilterService().currentFilter.getFilter(key).stateConverter();
            services.getFilterService().setValue(key, converter.fromState(value));
          }
        });
      }
    },
    '@@router/LOCATION_CHANGE'(state, payload) {
      // set the current filter, based on the url path
      state.currentFilterId = payload.location.pathname.split('/')[1];
      services.getFilterService().setCurrentFilter(state.currentFilterId);

      if (services.getFilterService().currentFilter) {

        const stateConverter = services.getAnalysisService().topicFilter?.stateConverter();
        const value = services.getUrlService().parseUrlParam('topic', (string) => stateConverter.fromString(string));
        if (value) {
          services.getAnalysisService().topicFilter?.setValue(value);
          state.topic = stateConverter.toState(services.getAnalysisService().topicFilter?.getValue());
        }

        // parse search part of the url and change current filter accordingly
        const parsedParams = services.getUrlService().parseUrl(Object.fromEntries(
          services.getFilterService().currentFilter.getFilters().map(
            f => [f.id, (str) => f.stateConverter().fromString(str)])));

        services.getFilterService().currentFilter.getFilters().forEach(f => {
          if (parsedParams.hasOwnProperty(f.id)) {
            f.setValue(parsedParams[f.id]);
          }
          if(f.hasValue()){
            state[f.id] = f.getStateValue();
          }
        });
      }

      return state;
    },
    setValue(state, payload) {
      state[payload.key] = payload.value;
      return state;
    },
    clearAll(state, payload) {
      payload.forEach(k => delete state[k]);
      return state;
    }
  },
  effects: (dispatch) => ({

    setOpen(payload, rootState) {
      dispatch.filter.setValue({key:"open", value :payload});
    },
    changeValue(payload, rootState) {
      const converter = services.getFilterService().currentFilter.getFilter(payload.key).stateConverter();

      const currentValue = converter.fromState(rootState.filter[payload.key]);
      const newValue = converter.fromState(payload.value);

      if (!converter.equals(currentValue, newValue)) {
        // change our service model
        services.getFilterService().setValue(payload.key, newValue);
        // change state
        dispatch.filter.setValue(payload);
        // refresh application
        dispatch.application.refresh();
      }
    },
    clear(payload, rootState) {
      // change our service model
      const fields = services.getFilterService().currentFilter.clear(payload);
      // change state
      dispatch.filter.clearAll(fields);
      // refresh application
      dispatch.application.refresh();
    }
  })
});