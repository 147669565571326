import { services } from '../../../../application/service/services';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { VolumeViz } from './volumeViz';

registerVizType({
  name: 'VOLUME',
  component: VolumeViz,
  title: (vizInstance, t) => {
    const i18nContext:any = {};
    let i18nKey = 'dashboard.viz.volume.title_noSubtitle';

    let subtitle = services.getDashboardParamsService().findParamLabelFromConstraint(t, vizInstance);
    if (subtitle) {
      i18nKey = 'dashboard.viz.volume.title';
      i18nContext.subtitle = subtitle;
    }

    return t(i18nKey, i18nContext);
  },
  noDataPolicy: {
    hasData: vizInstance => {
      return vizInstance.data.count > 0;
    }
  }
});