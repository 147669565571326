import { HistoryRouter as Router } from 'redux-first-history/rr6';
import React from 'react';
import { Provider } from 'react-redux';
import { Application } from './view/Application';
import { services } from "./service/services";

export const EntryPoint = () => {
  return <Provider store={services.getApplicationService().store}>
    <Router history={services.getApplicationService().history} >
      <Application/>
    </Router>
  </Provider>;
};
