import {StateConverter, StateConverterParser} from './StateConverter';
import _ from "lodash";
import { DateRange } from "../../../application/model/DateRange";

export class DateRangeStateConverter extends StateConverter<DateRange> {
  clazz(): Class<DateRange> {
    return DateRange;
  }

  equals(o1: DateRange, o2: DateRange) {
    if (_.isNil(o1)) {
      return _.isNil(o2)
    }
    return o1.equals(o2);
  }

  innerFromState(obj: Maybe<any>): Maybe<DateRange> {
    return obj ? DateRange.fromDate(obj) : undefined;
  }

  innerToState(obj: Maybe<DateRange>): Maybe<any> {
    return obj ? obj.toDate() : undefined
  }

  protected getParser(): Maybe<StateConverterParser<DateRange>> {
    return DateRangeStateConverter.parser;
  }
  private static parser = (str:string): Maybe<DateRange> => {
    if(_.isNil(str)) return null;
    const datesTab = str.split("/");
    if(datesTab.length != 2) throw new Error("String date range in wrong format.");

    return DateRange.parse({start: datesTab[0], end:datesTab[1]});
  }
}