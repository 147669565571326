import { services } from '../../application/service/services';

import axios from "axios";
import { tokens } from "@eptica/auth";
import { isMock } from "../../application/Mock";

export const ConfigurationTypes = ['FIELDS', 'SOURCING', 'PROCESSING', 'INDEXER', 'PUSH', 'UI', 'PURGE', 'TRANSCRIBE'];
export const ConfigurationsOrder = {
  FIELDS: 0,
  SOURCING: 1,
  PROCESSING: 2,
  INDEXER: 3,
  PUSH: 4,
  UI: 5,
  PURGE: 6,
  TRANSCRIBE: 7,
}
export const FirstConfigurationType = ConfigurationTypes[0];
const HintLevelOrder = { 'ERROR': 0, 'WARN': 1, 'INFO': 2 };

export class ConfigurationManagerService {
  private _configurationFetcher;
  private _bitbucketInitialized: boolean = false;
  private _bitbucketTokens;

  async init() {
    this._configurationFetcher = services.getFetcherService().getFetcher('configuration');

    const mock = isMock();
    if (mock) {
      this._bitbucketInitialized = true;
    }

    this._bitbucketTokens = mock ? { getToken: async (s) => Promise.resolve(s) } : tokens;
  }

  isAuthorizedForEdit(): boolean {
    return this.isAuthorizedForGet() && (
      this.isAuthorizedForValidate() || this.isAuthorizedForSave()
    );
  }

  isAuthorizedForGet(): boolean {
    return true;
  }

  isAuthorizedForValidate(): boolean {
    return services.getSecurityService().hasRole('vecko.configuration.validate');
  }

  isAuthorizedForSave(): boolean {
    return services.getSecurityService().hasRole('vecko.configuration.push');
  }

  isAuthorizedForGit(): boolean {
    if (isMock()) {
      return true;
    }
    return services.getTenantService().isAuthorizedForGetTenant() && services.getTenantService().isAdminTenant()
      && (this.isAuthorizedForGitValidate() || this.isAuthorizedForGitPush())
  }

  isAuthorizedForGitValidate(): boolean {
    return this.isAuthorizedForValidate();
  }

  isAuthorizedForGitPush(): boolean {
    return this.isAuthorizedForSave();
  }

  async getConfigurations(): Promise<Dict<string>> {
    return this._configurationFetcher.getConfigurations(ConfigurationTypes);
  }

  async validate(configurations: Dict<string>): Promise<Array<Vecko.ValidationHint>> {
    return this.sortHints(await this._configurationFetcher.validateConfigurations(configurations));
  }

  async save(configurations: Dict<string>): Promise<Array<Vecko.ValidationHint>> {
    try {
      await this._configurationFetcher.saveConfigurations(configurations);

      services.getApplicationService().notifyMessage({
        level: 'SUCCESS',
        text: {
          key: 'administration.configuration.edit.save.success'
        }
      });
    } catch (e) {
      if (e.response?.data?.code === "INVALID_CONFIGURATION") {
        return this.sortHints(JSON.parse(e.response?.data?.message));
      }
    }
    return null;
  }

  async bitbucketInit() {
    if (!this._bitbucketInitialized) {
      await this._bitbucketTokens.initBitbucket((await axios.get("/api/security/bitbucket")).data, true, '/');
      this._bitbucketInitialized = true;
    }
  }

  async getBitbucketToken(): Promise<string> {
    return this._bitbucketTokens.getToken('bitbucket');
  }

  async gitPush(branch: string, tenantId: string, bitbucketToken?: string):Promise<Array<Vecko.ValidationHint>> {
    try {
      const token = bitbucketToken || (await this.getBitbucketToken());
      await this._configurationFetcher.bitbucketPush(tenantId, branch, token);
    } catch (e) {
      if (e.response?.data?.code === "INVALID_CONFIGURATION") {
        return this.sortHints(JSON.parse(e.response?.data?.message));
      }
      throw e;
    }
    return null;
  }

  async gitValidate(branch: string, tenantId: string, bitbucketToken?: string) {
    const token = bitbucketToken || (await this.getBitbucketToken());
    const validationResult = this._configurationFetcher.bitbucketValidate(tenantId, branch, token);
    if (validationResult.hints) {
      validationResult.hints = this.sortHints(validationResult.hints);
    }
    return validationResult;
  }

  private sortHints(hints: Array<Vecko.ValidationHint>) {
    return [...hints.sort((h1, h2) => HintLevelOrder[h1.level] - HintLevelOrder[h2.level])];
  }
}

services.registerService('configurationManagerService', new ConfigurationManagerService());