import { ApiFilter } from './ApiFilter';
import _ from "lodash";

export class QueryStringFilter extends ApiFilter {
  static parse(object):ApiFilter {
    return new QueryStringFilter(object.query, object.analyzeWildcard);
  }

  query:string;
  fields:Array<string>;
  analyzeWildcard:boolean;

  constructor(query:string, fields: Array<string>, analyzeWildcard= false) {
    super('queryString');
    this.query = query;
    this.fields = fields;
    this.analyzeWildcard = analyzeWildcard;
  }

  toPlainObject() {
    if (_.isEmpty(this.query)) {
      throw new Error('query is mandatory')
    }
    const result:any = super.toPlainObject();
    result.query = this.query;
    result.fields = this.fields;
    result.analyzeWildcard = this.analyzeWildcard;
    return result;
  }

  getCopy():ApiFilter {
    const apiFilter: ApiFilter = new QueryStringFilter(this.query, this.fields, this.analyzeWildcard);
    return apiFilter
  }
}