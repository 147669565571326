import React from "react";
import { BlankSeparator } from "../../../../component/BlankSeparator";
import { EditorItem } from "../../../../component/editor/EditorItem";
import { SeparatedComponents } from "../../../../component/SeparatedComponents";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, FormGroup, InputGroup, NumericInput, Switch } from "@blueprintjs/core";
import _ from "lodash";
import { parseMetric } from "../../../model/metric/parseMetric";
import { EnhancedMetric } from "../../../model/metric/EnhancedMetric";
import "./metricVizEditor.scss";
import { GridFormGroup } from "../../../../component/form/GridFormGroup";
import { ColorPicker } from "../../../../component/color/ColorPicker";

interface MetricVizEditorProps extends WithTranslation {
  editorData: any,
  onValueChanged: (paramPropertyName: 'uiParams' | 'params', paramName: string, data: any) => void
}

type FontHandlerId = 'mainFont' | 'trendFont' | 'secondaryFont';

interface FontChangeHandler {
  getSize: () => number,
  onSizeChanged: (size: number) => void,
  getColor: () => string,
  onColorChanged: (color: string) => void
}

class MetricVizEditorComponent extends React.PureComponent<MetricVizEditorProps> {
  private valueUnitInputRef: React.Ref<HTMLInputElement> = ref => this.valueUnitInput = ref;

  private valueUnitInput: HTMLInputElement;

  private handlers: Dict<FontChangeHandler> = {
    mainFont: this.getFontHandler('mainFont'),
    trendFont: this.getFontHandler('trendFont'),
    secondaryFont: this.getFontHandler('secondaryFont'),
  }

  private switchUiParamsProp(prop) {
    const { onValueChanged, editorData } = this.props;

    onValueChanged('uiParams', prop, !_.get(editorData.uiParams, prop));
  }

  private onPercentageModeChanged = () => {
    this.switchUiParamsProp('percentageMode');
  }

  private onShowTrendChanged = () => {
    this.switchUiParamsProp('showTrend');
  }

  private onShowSecondaryChanged = () => {
    this.switchUiParamsProp('showSecondary');
  }

  private overrideDefaultValueUnit(): boolean {
    const { editorData } = this.props;
    return !_.isNil(editorData.uiParams.valueUnit);
  }

  private onOverrideDefaultValueUnitChanged = () => {
    const { onValueChanged } = this.props;
    const newValue = this.overrideDefaultValueUnit() ? null : '';
    onValueChanged('uiParams', 'valueUnit', newValue);
    if (this.valueUnitInput && newValue !== null) {
      setTimeout(() => this.valueUnitInput.focus(), 0);
    }
  }

  private onValueUnitChanged = (event) => {
    const { onValueChanged } = this.props;
    onValueChanged('uiParams', 'valueUnit', event.target.value);
  }

  private onValueUnitCleared = () => {
    const { onValueChanged } = this.props;
    onValueChanged('uiParams', 'valueUnit', '');
  }

  private getFontHandler(name: FontHandlerId): FontChangeHandler {
    return {
      getSize: () => {
        return this.props.editorData.uiParams[name].size
      },
      onSizeChanged: (size: number) => this.props.onValueChanged('uiParams', `${name}.size`, size),
      getColor: () => {
        return this.props.editorData.uiParams[name].color
      },
      onColorChanged: (color: string) => this.props.onValueChanged('uiParams', `${name}.color`, color),
    }
  }

  private renderFontEditor(name: FontHandlerId): React.ReactNode {
    const { t } = this.props;
    const handler = this.handlers[name];

    return <GridFormGroup columnsCount={2} fill={false}>
      <label className="metricVizEditor-font-header"
             htmlFor={`${name}.size`}>{t('dashboard.viz.metric.editor.font.size')}</label>
      <span className="metricVizEditor-font-header">{t('dashboard.viz.metric.editor.font.color')}</span>

      <NumericInput id={`${name}.size`} min={1} value={handler.getSize()} onValueChange={handler.onSizeChanged}/>
      <ColorPicker color={handler.getColor()} onChanged={handler.onColorChanged}/>
    </GridFormGroup>;
  }

  render() {
    const { t, editorData } = this.props;

    const metric: EnhancedMetric = parseMetric(editorData.params.metric);
    const defaultValueUnit = metric.defaultUnitLabel(t);
    const supportPercentage = metric.supportPercentage();

    const overrideDefaultValueUnit = this.overrideDefaultValueUnit();
    const valueUnit = overrideDefaultValueUnit ? editorData.uiParams.valueUnit : '';

    return <div className="metricVizEditor">
      <SeparatedComponents separator={<BlankSeparator vertical={true}/>}>

        <EditorItem title={t('dashboard.viz.metric.editor.main')}>
          {
            supportPercentage ? <Switch checked={editorData.uiParams.percentageMode}
                                        label={t('dashboard.viz.metric.editor.percentageMode')}
                                        onChange={this.onPercentageModeChanged}/> : null
          }

          {
            this.renderFontEditor('mainFont')
          }
        </EditorItem>

        <EditorItem title={t('dashboard.viz.metric.editor.trend')}>
          <Switch checked={editorData.uiParams.showTrend}
                  label={t('dashboard.viz.metric.editor.showTrend')}
                  onChange={this.onShowTrendChanged}/>
          {
            this.renderFontEditor('trendFont')
          }
        </EditorItem>

        {
          supportPercentage ?
            <EditorItem title={t('dashboard.viz.metric.editor.secondary')}>
              <Switch checked={editorData.uiParams.showSecondary}
                      label={t('dashboard.viz.metric.editor.showSecondary')}
                      onChange={this.onShowSecondaryChanged}/>

              {
                this.renderFontEditor('secondaryFont')
              }
            </EditorItem>
            : null
        }

        <EditorItem title={t('dashboard.viz.metric.editor.valueUnit')}>
          <FormGroup helperText={<span>{t('dashboard.viz.metric.editor.valueUnit.helperText')}&nbsp;
            <strong>{defaultValueUnit}</strong></span>}
                     inline={true} contentClassName='metricVizEditor-unit'>
            <div>
              <Switch checked={overrideDefaultValueUnit}
                      label={t('dashboard.viz.metric.editor.valueUnit.override')}
                      onChange={this.onOverrideDefaultValueUnitChanged}/>
              <InputGroup fill={true}
                          value={valueUnit}
                          disabled={!overrideDefaultValueUnit}
                          onChange={this.onValueUnitChanged}
                          inputRef={this.valueUnitInputRef}
                          rightElement={<Button icon='cross' disabled={!overrideDefaultValueUnit}
                                                onClick={this.onValueUnitCleared}/>}
              />
            </div>
          </FormGroup>
        </EditorItem>
      </SeparatedComponents>
    </div>;
  }
}

export const MetricVizEditor = withTranslation()(MetricVizEditorComponent);