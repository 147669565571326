import { StorageItem } from './StorageItem';

export class StorageDirectory extends StorageItem {
  static parse(object) {
    return new StorageDirectory(object.path);
  }

  static root() {
    return new StorageDirectory('');
  }

  constructor(path) {
    super(path === '' ? path : (path === '/' ? '' : (path.endsWith('/') ? path : path + '/')));
  }

  isDir() {
    return true;
  }

  name() {
    if (this.isRoot()) {
      return 'ROOT';
    }
    const p = this.path.split('/');
    return p[p.length -2];
  }

  parent() {
    if (this.isRoot()) {
      return null;
    }
    const p = this.path.split('/');
    p.splice(-2, 2);
    return new StorageDirectory(p.join('/') + '/');
  }

  isRoot() {
    return this.path === '';
  }
}