import { MetricEnum } from '../../../model/MetricEnum';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { getTitle } from '../common';
import { RankingEditor } from './RankingEditor';
import { RankingViz } from './RankingViz';

const defaultUiParams = {
  metrics: [
    MetricEnum.SAT_SCORE, MetricEnum.TREND, MetricEnum.VOLUME
  ].map(m => m.name),
  color: '#4d5353',
  hideRanks: false,
  canSeeMore: false
};
const title = (vizInstance, t) => {
  const title = getTitle(vizInstance, t);
  if(title){
    return title;
  }
  return t('dashboard.viz.ranking.title', { field: vizInstance.definition.params.field });
}

registerVizType(
  {
    name: 'RANKING',
    title: title,
    component: RankingViz,
    defaultUiParams: (vizDef) => defaultUiParams,
    editor: {
      component: RankingEditor,
      title: title,
      init(vizInstance) {
        return {
          params: {
            ranges: vizInstance.definition.params.ranges,
            exclusions: vizInstance.definition.params.exclusions,
            inclusions: vizInstance.definition.params.inclusions,
            sort: vizInstance.definition.params.sort
          },
          uiParams: vizInstance.definition.uiParams
        };
      },
      applyOnParams: (params, editedParams) => {
        Object.assign(params, editedParams);
      },
      applyOnUiParams: (uiParams, editedUiParam) => {
        Object.assign(uiParams, editedUiParam);
      }
    },
    noDataPolicy: {
      hasData: vizInstance => (vizInstance.data && vizInstance.data.items.length > 0)
    }
  }
);