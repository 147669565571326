import { services } from '../../service/services';

export const createMessagePlugin = () => {
    return {
        config: {},
        onModel(namedModel, rematchStore) {
            const name = namedModel.name;
            const dispatch = rematchStore.dispatch;
            const modelActions = dispatch[name];

            // map over effects within models
            Object.keys(modelActions).forEach((action) => {
                const modelAction = modelActions[action];

                if (modelAction.isEffect !== true) {
                    return
                }

                // create function with pre & post loading calls
                const effectWrapper = async (...props) => {
                    try {
                        return await modelAction(...props);
                    } catch (error) {
                        services.getApplicationService().notifyMessage({
                            level: 'ERROR',
                            text: {
                                key: `error.runtime`
                            },
                            error: {
                                type: 'RUNTIME',
                                error: error
                            }
                        });
                        dispatch.loading.hide({ name, action });
                        throw error
                    }
                };

                effectWrapper.isEffect = true;

                // replace existing effect with new wrapper
                modelActions[action] = effectWrapper
            })
        },
    }
}