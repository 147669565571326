import {services} from '../../application/service/services';

const OUTPUT_TYPES = [
    "xlsx",
    "csv",
    "json"
];

export enum ExportOutputType {
    xlsx = "xlsx", json = "json", csv = "csv"
}

export class ExportService {
    _exportFetcher;

    init() {
        this._exportFetcher = services.getFetcherService().getFetcher('export');
    }

    isAuthorized(): boolean {
        return services.getSecurityService().hasRole('vecko.export');
    }

    async exportFeedbacks(params: Dict<any>) {
        await this._exportFetcher.exportFeedbacks(params.exportType, params.feedbackDataType, params.exportFilterKind, params.query, null, params.fields, params.maxSize, params.lang);
    }

    async exportHighlight(params: Dict<any>) {
        await this._exportFetcher.exportHighlight(null, params.categoryTree, params.tonality, params.maxSize, params.lang);
    }
}

services.registerService('exportService', new ExportService());