import {Button, Classes, Dialog, Intent} from '@blueprintjs/core';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import _ from "lodash";

interface FormDialogProps extends WithTranslation{
    isOpen: boolean,
    icon: any,
    title: string,
    onCancel: any,
    onValidate: any,
    children: any
}

class FormDialogComponent extends React.PureComponent<FormDialogProps> {
    static defaultProps: Partial<FormDialogProps> = {
        isOpen: false,
        onCancel: _.noop
    };

    onCancel = () => {
        const {onCancel} = this.props;
        onCancel();
    };

    onValidate = () => {
        const {onValidate} = this.props;
        onValidate();
    };

    render() {
        const {t, isOpen, icon, title, children} = this.props;

        return <Dialog isOpen={isOpen} icon={icon} title={title} onClose={this.onCancel}>
            <div className={Classes.DIALOG_BODY}>
                {children}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={this.onCancel}>{t('action.cancel').toUpperCase()}</Button>
                    <Button onClick={this.onValidate}
                            intent={Intent.PRIMARY}>{t('action.validate').toUpperCase()}</Button>
                </div>
            </div>
        </Dialog>;
    }
}

export const FormDialog = withTranslation()(FormDialogComponent);
