import { Tonality } from '@eptica/vecko-js-commons';
import _ from 'lodash';
import React from 'react';
import { Trans } from 'react-i18next';
import { SatTypeEnum } from '../../../../application/model/SatTypeEnum';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { getTitle } from '../common';
import { TopicImpactTopEvolutionEditor } from './topicImpactTopEvolutionEditor';
import {
  topicImpactTopEvolutionEditorState,
  topicImpactTopEvolutionEditorStateName
} from './topicImpactTopEvolutionEditorState';
import { TopicImpactTopEvolutionViz } from './topicImpactTopEvolutionViz';

const titleTrans = (vizInstance, keyPrefix, t) => {
  const title = getTitle(vizInstance, t);
  if(title){
    return title;
  }
  const polarity = Tonality.getValueOf(vizInstance.definition.params.polarity);
  const satType = SatTypeEnum.valueOf(vizInstance.definition.params.satType);

  return <Trans i18nKey={keyPrefix} values={{ tonality: polarity.name(), satType: satType.getAliasedName() }}><
    span style={{ color: polarity.color }}/>
  </Trans>;
};

registerVizType({
  name: 'TOPIC_IMPACT_TOP_EVOLUTION',
  component: TopicImpactTopEvolutionViz,
  title: (vizInstance, t) => titleTrans(vizInstance, `dashboard.viz.topicImpactTopEvolution.title`, t),
  editor: {
    enabled: vizInstance => {
      if (vizInstance.definition.params.noSamples === true) {
        return false;
      }
      return vizInstance.hasData();
    },
    component: TopicImpactTopEvolutionEditor,
    title: (vizInstance, t) => titleTrans(vizInstance, 'dashboard.viz.topicImpactTopEvolution.editor.title', t),
    applyOnData: (data, editedData) => {
      _.each(data, (d) => {
        d.feedback = editedData[d.topic];
      });
    },
    focusOnMount: false,
    state: {
      name: topicImpactTopEvolutionEditorStateName,
      create: () => topicImpactTopEvolutionEditorState()
    }
  }
});
