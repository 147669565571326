import { services } from '../../application/service/services';

export class CacheService {
    _cacheFetcher;

    init() {
        this._cacheFetcher = services.getFetcherService().getFetcher('cache');
    }

    isAuthorized(): boolean {
        return this.isLocalCacheInvalidationAllAuthorized()
          || this.isPersistentCacheInvalidationAllAuthorized()
          || this.isMultiTenantPersistentCacheInvalidationAllAuthorized();
    }

    isLocalCacheInvalidationAuthorized(): boolean {
        return services.getSecurityService().hasRole('vecko.cache.invalidate')
    }

    isLocalCacheInvalidationAllAuthorized(): boolean {
        return services.getSecurityService().hasRole('vecko.cache.invalidate')
    }

    isPersistentCacheInvalidationAllAuthorized(): boolean {
        return services.getSecurityService().hasRole('vecko.cache.invalidate')
    }

    isMultiTenantPersistentCacheInvalidationAllAuthorized(): boolean {
        return services.getSecurityService().hasRole('vecko.cache.invalidate') &&
            services.getSecurityService().hasRole('vecko.admin.data.multiTenant')
    }

    async invalidateAllLocalCaches() {
        await this._cacheFetcher.invalidateAllCaches();
        this.displaySuccessInvalidateMsg();
    }

    async invalidateCurrentTenantPersistentCache() {
        await this._cacheFetcher.invalidateCurrentTenantPersistentCache();
        this.displaySuccessInvalidateMsg();
    }

    async invalidateAllTenantsPersistentCache() {
        await this._cacheFetcher.invalidateAllTenantsPersistentCache();
        this.displaySuccessInvalidateMsg();
    }

    private displaySuccessInvalidateMsg() {
        services.getApplicationService().notifyMessage({
            level: 'SUCCESS',
            text: {
                key: 'administration.system.cache.invalidation.success',
            }
        });
    }
}

services.registerService('cacheService', new CacheService());