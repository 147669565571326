import { FocusStyleManager } from '@blueprintjs/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavViews } from '../../component/nav/NavViews';
import { StyledProgressBar } from '../../component/progressBar/StyledProgressBar';
import { VeckoColors } from '../../style/VeckoColors';
import { MainMenu } from './MainMenu';
import { TopBar } from './TopBar';
import { Message } from "./message/Message";
import _ from 'lodash';
import { services } from "../service/services";
import EILogo from '../../assets/ei-logo-stacked.svg';

FocusStyleManager.onlyShowFocusOnTabs();

type ApplicationProps = {
  init: Function,
  isLoading: boolean,
  hasFilter: boolean
}

class ApplicationComponent extends Component<ApplicationProps> {

  componentDidMount() {
    this.props.init();
  }

  render() {
    return <div className='vui-layout--horizontal vui-layout--vertical__center'
                style={{ justifyItems: 'stretch' }}>

      {
        this.props.isLoading ?
        <StyledProgressBar
          style={{ position: 'absolute', width: '100%', height: 2, backgroundColor: VeckoColors.GREEN }}
          meterStyle={{ backgroundColor: VeckoColors.GREEN_LIGHT_1 }}/> :
        null
      }
      <MainMenu/>

      <div className='vui-layout--vertical vui-layout--vertical__center'
           style={{ marginLeft: 60 }}>
        <div className='vui-layout--horizontal vui-layout--vertical__top'
             style={{ alignItems: 'center', height: 50, padding: '0 10px' }}>
          {
            this.props.hasFilter ? <div className='vui-layout--horizontal vui-layout--horizontal__center'
                                        style={{ alignItems: 'center' }}>
              <TopBar/>
            </div> : null
          }
          <div className='vui-layout--vertical'>
            <img src={EILogo} alt='Enghouse Interactive' height={30}/>
          </div>
        </div>


        <div className='vui-layout--vertical__center vui-layout--vertical'
             style={{ backgroundColor: VeckoColors.DEFAULT_BG, padding: 15 }}>
          <NavViews nav={services.getApplicationService().mainNav}/>
        </div>
      </div>
      <Message/>
    </div>;
  }
}

const mapState = (state) => {
  return {
    hasFilter: !_.isNil(services.getFilterService().currentFilter),
    isLoading: state.loading.global
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    init: () => {
      dispatch.filter.init();
    }
  };
};

export const Application = connect(mapState, mapDispatchToProps)(ApplicationComponent);
