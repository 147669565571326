import * as PropTypes from 'prop-types';
import React from 'react';
import { DateRange } from '../application/model/DateRange';
import { DateLabel } from './dateLabel/DateLabel';

export class DateRangeLabel extends React.PureComponent {
  static props = {
    dateRange: PropTypes.instanceOf(DateRange).isRequired,
    format: PropTypes.string
  };
  static defaultProps = {
    format: 'LL'
  };

  render() {
    const { dateRange, format} = this.props;

    return <>
      [<DateLabel date={dateRange.start} dateFormat={format}/> - <DateLabel date={dateRange.end} dateFormat={format}/>]
    </>
  }
}