import { ApiFilter } from './ApiFilter';
import _ from "lodash";

export class TermsFilter extends ApiFilter {
  static parse(object) {
    return new TermsFilter(object.field, object.terms);
  }

  field:string;
  terms:string[];

  constructor(field:string, terms:string[]) {
    super('terms');
    this.field = field;
    this.terms = terms;
  }

  addTerm(term:string) {
    this.terms.push(term);
  }

  toPlainObject() {
    if (_.isEmpty(this.field)) {
      throw new Error('field is mandatory')
    }

    /* in some case we have to send termsFilter with empty terms to back
    if (_.isEmpty(this.terms)) {
      throw new Error('terms are mandatory')
    }*/

    const result:any = super.toPlainObject();
    result.field = this.field;
    result.terms = this.terms;
    return result;
  }

  getCopy():ApiFilter {
    const apiFilter = new TermsFilter(this.field, [...this.terms]);
    return apiFilter
  }
}