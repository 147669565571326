import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import { InfiniteCache } from "../../../../utils/InfiniteCache";

interface ActionListData {
  action1?: string,
  action2?: string,
  action3?: string,
}

interface ActionListEditorData {
  data?: ActionListData
}

interface ActionListEditorProps extends WithTranslation {
  onValueChanged: (prop: string, key: string, value: any) => void,
  editorData?: ActionListEditorData
}

export class ActionListEditorComponent extends React.PureComponent<ActionListEditorProps> {
  onInputChanged = new InfiniteCache(index => (event) => this.props.onValueChanged('data', `action${index}`, event.target.value));

  render() {
    const { t, editorData } = this.props;
    const uiData = [1, 2, 3].map(i => ({
      index: i,
      value: editorData?.data?.[`action${i}`] || ""
    }));

    return <div>
      {
        uiData.map(data => {
            return <FormGroup key={data.index}
                              label={t('dashboard.viz.actionList.editor.actionListNumber', { index: data.index })}>
              <InputGroup key={data.index} fill={true}
                          value={data.value}
                          onChange={this.onInputChanged.get(data.index)}/>
            </FormGroup>;
          }
        )
      }
    </div>;
  }
}

export const ActionListEditor = withTranslation()(ActionListEditorComponent);