import { services } from '../../application/service/services';
import _ from "lodash";
import {DateTime} from "luxon";
import {DateRange} from "../../application/model/DateRange";

export class DashboardExportService {
  _dashboardExportFetcher;

  async init() {
    this._dashboardExportFetcher = services.getFetcherService().getFetcher('dashboardExport');
  }

  shouldExport() {
    return services.getUrlService().getUrlPathname().startsWith("/report/export");
  }

  isExportAuthorized(){
    return services.getSecurityService().hasRole('vecko.report.export');
  }

  /**
   *
   * @param {*} state
   * @return {Promise<void>}
   */
  initiateExport(state) {
    const dashboardId = state.dashboard.id;
    const validatedData = {};
    Object.entries(state.dashboardVizEditor.validatedData).forEach(([key, val]) => {
      const value:any = val;
      if (!_.isNil(value.data)) {
        validatedData[key] = { data: value.data };
      }
      if (!_.isNil(value.uiParams)) {
        validatedData[key] = {  ...(validatedData[key] || {}), uiParams: value.uiParams };
      }
    });

    const exportState = {
      filter: {
        dateRange: services.getFilterService().getValue<DateRange>("dateRange").toPlainObject()
      },
      dashboard: {
        id: dashboardId,
        data: { vizResponses : state.dashboardData.payload, total: state.dashboardData.total},
        resolvedParams: state.dashboard.resolvedParams,
      },
      dashboardVizEditor: {
        validatedData: validatedData
      },
      uiConfig : {...services.getConfigurationService().uiConfiguration},
      fields: services.getConfigurationService().fieldsConfiguration,
      fieldsValue: services.getFieldsService().remoteFieldValues
    };

    /*keep only one report*/
    exportState.uiConfig.reports =[exportState.uiConfig.reports.find(report => report.id === dashboardId)];
    const dashboardLabel = services.getI18nService().translate(`report.${dashboardId}`);
    const timestamp = DateTime.now().toFormat('yyyyMMdd-HHmmss');

    return this._dashboardExportFetcher.initiateExport(exportState, `${dashboardLabel}-${timestamp}.pdf`);
  }
}

services.registerService('dashboardExportService', new DashboardExportService());
