import {Colors} from '@blueprintjs/core';
import { Tooltip } from '@blueprintjs/core';
import * as d3 from 'd3';
import {bboxCollide} from 'd3-bboxCollide';
import _ from 'lodash';
import each from 'lodash/fp/each';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import React from 'react';
import * as ReactDOM from 'react-dom';
import d3_measure_text from '../../utils/d3_measure_text';
import './bubbleChart.scss';
import {BubbleChartDataset} from './bubbleChartData';
import {services} from "../../../../application/service/services";


interface BubbleChartProps {
    dataset: BubbleChartDataset,
    radius: number,
    avoidCollision: boolean,
    axisColor: string,
    selectable: boolean,
    selection: any[],
    onSelectionChanged: (p1: any, p2: any, p3: any) => void,
    onClick: (event: any, d, any) => void,

};

export class BubbleChart extends React.Component<BubbleChartProps> {
    static defaultProps: Partial<BubbleChartProps> = {
        radius: 5,
        avoidCollision: true,
        axisColor: Colors.GRAY2,
        selectable: false,
        selection: [],
        onSelectionChanged: _.noop
    };

    state = {
        currentItem: null
    };
    private opacityPolicy: (d) => (number);
    private listener: () => void;
    private svg: SVGSVGElement;
    private nodes: any[];
    private bubbles: any;

    constructor(props) {
        super(props);

        this.opacityPolicy = d => (this.props.selectable && !d.selected ? 0.2 : 0.8);
    }

    componentDidMount() {
        this.listener = _.debounce(() => {
            this.repaint(this.props);
        }, 100);

        window.addEventListener('resize', this.listener);
        window.setTimeout(() => {
            this.repaint(this.props);
        }, 0);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.listener);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.selection, this.props.selection)) {
            //refresh ui data model
            if (this.nodes) {
                this.nodes.forEach(n => {
                    n.selected = this.isSelected(n.raw);
                });
            }
            // refresh ui
            if (this.bubbles) {
                this.bubbles.style('opacity', this.opacityPolicy);
            }
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (!_.isEqual(nextProps.dataset, this.props.dataset)) {
            this.repaint(nextProps);
        }
    }

    refreshBubbleSelection = (bubble) => {
        d3.select(bubble).style('opacity', this.opacityPolicy(bubble.__data__));
    };

    getSelection = () => {
        if (!this.nodes) {
            return [];
        }

        return _.flow(
            filter('selected'),
            map('raw')
        )(this.nodes);
    };


    isSelected(val) {
        return this.props.selection.indexOf(val) !== -1;
    }

    repaint = (props) => {

        const {dataset, radius, avoidCollision, axisColor} = props;
        const self = this;
        const element: any = ReactDOM.findDOMNode(this);

        // make svg element take full available space
        const bw = element.parentNode.offsetWidth;
        const bh = element.parentNode.offsetHeight;
        this.svg.setAttribute('width', bw);
        this.svg.setAttribute('height', bh);


        const arrowWidth = 16;
        const arrowHeight = 16;
        const axisWidth = 1;
        const gapBetweenAxisAndGrid = 5;

        const gapBetweenSvgAndAxis = 25;
        const axisMargins = {
            left: gapBetweenSvgAndAxis,
            top: 0,
            right: 15,
            bottom: gapBetweenSvgAndAxis
        };
        const overAllLineColor = services.getColorService().getPrimaryThemeColor();

        let additionalTopMargin = 0;

        /*if vertical line is present or top areas labels are displayed */
        const topAreasWithLabels = dataset.areas.filter(area => {
            return (area.anchor || 'bottom_left').split('_')[0] === 'top';
        }).filter(areas => areas.showLabel)

        if (dataset.verticalLines.length > 0 || topAreasWithLabels.length > 0) {
            additionalTopMargin = 20;
        }

        let gapBetweenLeftAxisAndGrid = gapBetweenAxisAndGrid;
        if (dataset.horizontalLines.length > 0) {
            gapBetweenLeftAxisAndGrid = 20;
        }

        const bottomAreasWithLabels = dataset.areas.filter(area => {
            return (area.anchor || 'bottom_left').split('_')[0] === 'bottom';
        }).filter(areas => areas.showLabel)

        let gapBetweenBottomAxisAndGrid = gapBetweenAxisAndGrid;
        if (bottomAreasWithLabels.length > 0) {
            gapBetweenBottomAxisAndGrid = 20;
        }

        const gridMargins = {
            left: axisMargins.left + arrowWidth / 2 + axisWidth + gapBetweenLeftAxisAndGrid,
            top: axisMargins.top + additionalTopMargin,
            right: axisMargins.right,
            bottom: axisMargins.bottom + arrowWidth / 2 + axisWidth + gapBetweenBottomAxisAndGrid
        };

        const gridWidth = bw - gridMargins.left - gridMargins.right;
        const gridHeight = bh - gridMargins.top - gridMargins.bottom;


        const svg = d3.select(this.svg);

        // clear svg element
        svg.selectAll('*').remove();

        // main group
        const container = svg.append('g');


        const dataGridPadding = radius * 2;
        const dataGridWidth = gridWidth - dataGridPadding * 2;
        const dataGridHeight = gridHeight - dataGridPadding * 2;
        const bubbleLabelStyle = 'font-size: 20px; font-weight: 600';

        const areaBorder = 5;

        this.nodes = _.map(dataset.data, (d) => {
            const labelDimension = d3_measure_text(d.label, {style: bubbleLabelStyle});
            const dimension = {
                width: Math.max(labelDimension.width, radius * 2),
                height: radius * 2 + labelDimension.height
            };


            const coord = {
                x: dataGridWidth * d.normalizedData.x,
                y: dataGridHeight * (1 - d.normalizedData.y)
            };

            const shouldAlignLabelOnTheRight = (coord.x + labelDimension.width * 2) > dataGridWidth;
            const shouldAlignLabelOnTheLeft = (coord.x - labelDimension.width * 2) < 0;

            return {
                raw: d,
                normalizedData: d.normalizedData,
                x: coord.x,
                y: coord.y,
                labelDimension: labelDimension,
                labelAnchor: shouldAlignLabelOnTheRight ? 'end' : (shouldAlignLabelOnTheLeft? 'left' : 'middle'),
                labelX: shouldAlignLabelOnTheRight ? radius + radius / 2 : 0,
                dimension: dimension,
                radius: radius,
                selected: this.isSelected(d)
            };
        });

        // -----------------
        // -- axis
        // -----------------
        const axisOriginPoint = {
            x: axisMargins.left,
            y: bh - axisMargins.bottom
        };


        //point at the bottom
        const vAxisStartPoint = {
            x: axisOriginPoint.x,
            y: bh - gridMargins.bottom - areaBorder / 2
        };
        // vertical axis
        const vAxisLength = vAxisStartPoint.y - gridMargins.top - areaBorder / 2;

        //point at the top
        const vAxisEndPoint = {x: axisOriginPoint.x, y: vAxisStartPoint.y - vAxisLength};
        container.append('line')
            .style('stroke', axisColor)
            .style('stroke-width', axisWidth)
            .attr('x1', vAxisStartPoint.x)
            .attr('y1', vAxisStartPoint.y)
            .attr('x2', vAxisEndPoint.x)
            .attr('y2', vAxisEndPoint.y);
        container.append('line')
            .attr('stroke', axisColor)
            .style('stroke-width', axisWidth)
            .attr('x1', vAxisEndPoint.x - arrowWidth / 2)
            .attr('y1', vAxisEndPoint.y + arrowHeight)
            .attr('x2', vAxisEndPoint.x)
            .attr('y2', vAxisEndPoint.y);
        container.append('line')
            .attr('stroke', axisColor)
            .style('stroke-width', axisWidth)
            .attr('x1', vAxisEndPoint.x)
            .attr('y1', vAxisEndPoint.y)
            .attr('x2', vAxisEndPoint.x + arrowWidth / 2)
            .attr('y2', vAxisEndPoint.y + arrowHeight);


        // horizontal axis
        const hAxisStartPoint = {
            x: gridMargins.left + areaBorder / 2,
            y: axisOriginPoint.y
        };

        const hAxisLength = bw - hAxisStartPoint.x - gridMargins.right - areaBorder / 2;


        const hAxisEndPoint = {x: hAxisStartPoint.x + hAxisLength, y: axisOriginPoint.y};
        container.append('line')
            .style('stroke', axisColor)
            .style('stroke-width', axisWidth)
            .attr('x1', hAxisStartPoint.x)
            .attr('y1', hAxisStartPoint.y)
            .attr('x2', hAxisEndPoint.x)
            .attr('y2', hAxisEndPoint.y);
        container.append('line')
            .attr('stroke', axisColor)
            .style('stroke-width', axisWidth)
            .attr('x1', hAxisEndPoint.x - axisWidth / 2)
            .attr('y1', hAxisEndPoint.y - arrowWidth / 2)
            .attr('x2', hAxisEndPoint.x - axisWidth / 2)
            .attr('y2', hAxisEndPoint.y + arrowWidth / 2);
        container.append('line')
            .attr('stroke', axisColor)
            .style('stroke-width', axisWidth)
            .attr('x1', hAxisStartPoint.x + axisWidth / 2)
            .attr('y1', axisOriginPoint.y - arrowWidth / 2)
            .attr('x2', hAxisStartPoint.x + axisWidth / 2)
            .attr('y2', axisOriginPoint.y + arrowWidth / 2);


        // -----------------
        // -- axis labels
        // -----------------

        const gapBetweenAxisLabelAndAxis = 5;

        // vertical axis label
        container.append('text')
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'ideographic')
            .attr('font-weight', '600')
            .attr('fill', axisColor)
            .attr('transform',
                `translate(${axisOriginPoint.x - gapBetweenAxisLabelAndAxis},${axisOriginPoint.y / 2}),rotate(-90)`)
            .text(dataset.yAxis.label)
        ;

        // horizontal axis label
        container.append('text')
            .attr('x', axisOriginPoint.x + hAxisLength / 2)
            .attr('y', axisOriginPoint.y + gapBetweenAxisLabelAndAxis)
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'hanging')
            .attr('font-weight', '600')
            .attr('fill', axisColor)
            .text(dataset.xAxis.label)
        ;

        // horizontal axis min max
        if (!_.isNil(dataset.xAxis.min)) {
            container.append('text')
                .attr('x', gridMargins.left + areaBorder / 2)
                .attr('y', axisOriginPoint.y)
                .attr('text-anchor', 'middle')
                .attr('dy', '10')
                .attr('dominant-baseline', 'hanging')
                .attr('font-weight', '600')
                .attr('fill', axisColor)
                .text(dataset.xAxis.min)
            ;
        }

        if (!_.isNil(dataset.xAxis.max)) {
            container.append('text')
                .attr('x', hAxisEndPoint.x - areaBorder / 2)
                .attr('y', hAxisEndPoint.y)
                .attr('text-anchor', 'middle')
                .attr('dy', '10')
                .attr('dominant-baseline', 'hanging')
                .attr('font-weight', '600')
                .attr('fill', axisColor)
                .text(dataset.xAxis.max)
            ;
        }

        // -----------------
        // -- grid
        // -----------------
        const grid = container.append('g').attr('transform', `translate(${gridMargins.left},${gridMargins.top})`);


        // -----------------
        // -- areas
        // -----------------

        const areasContainer = grid.append('g');

        const areasUi = {};
        _.each(dataset.areas, area => {
            const areaHeight = gridHeight * area.rect.height;
            areasUi[area.name] = {
                area: area,
                x: gridWidth * area.rect.x,
                y: (gridHeight * (1 - area.rect.y)) - areaHeight,
                width: gridWidth * area.rect.width,
                height: gridHeight * area.rect.height
            };
        });

        const drawArea = (areaUi) => {
            areasContainer.append('rect')
                .attr('x', areaUi.x)
                .attr('y', areaUi.y)
                .attr('width', areaUi.width)
                .attr('height', areaUi.height)
                .attr('fill', areaUi.area.color)
                .attr('opacity', 0.1)
                .attr('stroke', '#fff')
                .attr('stroke-width', areaBorder)
            ;
        };
        _.each(areasUi, drawArea);


        // -----------------
        // -- areas labels
        // -----------------
        const areaLabelFontSize = 18;
        const areaInsideLabelPadding = 5;
        const areaOutsideLabelPadding = 20;

        _.flow(
            filter((a: any) => a.showLabel),
            each((area: any) => {
                const areaShape = areasUi[area.name];

                const position = area.position || 'inside';
                const anchor = area.anchor || 'bottom_left';
                const anchors = anchor.split('_');
                const vAnchor = anchors[0];
                const hAnchor = anchors[1];

                let x, y, vAlign, hAlign;

                if (vAnchor === 'bottom') {
                    y = areaShape.y + areaShape.height;

                    if (position === 'inside') {
                        vAlign = 'ideographic';
                        y -= areaInsideLabelPadding;
                    } else {
                        vAlign = 'hanging';
                    }

                } else {
                    y = areaShape.y;

                    if (position === 'inside') {
                        vAlign = 'hanging';
                        y += areaInsideLabelPadding;
                    } else {
                        vAlign = 'ideographic';
                    }
                }

                if (hAnchor === 'left') {
                    x = areaShape.x;

                    if (position === 'outside') {
                        x += areaOutsideLabelPadding;
                    } else {
                        x += areaInsideLabelPadding;
                    }

                    hAlign = 'start';

                } else {
                    x = areaShape.x + areaShape.width;

                    if (position === 'outside') {
                        x -= areaOutsideLabelPadding;
                    } else {
                        x -= areaInsideLabelPadding;
                    }

                    hAlign = 'end';
                }

                areasContainer.append('text')
                    .text(area.label)
                    .attr('fill', area.color)
                    .attr('font-weight', '600')
                    .attr('font-size', areaLabelFontSize)
                    .attr('dominant-baseline', vAlign)
                    .attr('text-anchor', hAlign)
                    .attr('x', x)
                    .attr('y', y);
            })
        )(dataset.areas);

        // -----------------
        // -- tooltip
        // -----------------
        const bubbleColorPolicy = d => (d.raw.area ? d.raw.area.color : '#000');
        const showTooltip = function (e, d) {
            const globalRect = element.getBoundingClientRect();
            const bubbleRect = this.getBoundingClientRect();
            const top = bubbleRect.top - globalRect.top;
            const left = bubbleRect.left - globalRect.left;

            const currentItem = {
                shape: {
                    left,
                    top,
                    width: bubbleRect.width,
                    height: bubbleRect.height
                },
                data: {
                    ...d.raw
                }
            };

            self.setState({currentItem});
        };
        const hideTooltip = function () {
            self.setState({currentItem: null});
        };

        // -----------------
        // -- bubbles
        // -----------------

        const bubblesGroup = grid.append('g').attr('transform', `translate(${dataGridPadding}, ${dataGridPadding})`);

        const items = bubblesGroup.selectAll('items')
            .data(this.nodes)
            .enter()
            .append('g')
        ;

        // bubble

        this.bubbles = items.append('circle')
            .attr('r', d => (d.radius))
            .attr('fill', bubbleColorPolicy)
            .attr('stroke', bubbleColorPolicy)
            .attr('stroke-width', 2)
            .attr('opacity', this.opacityPolicy)
            .attr('class', 'vko-bubble-chart-bubble')
            .on('mouseover', showTooltip)
            .on('mouseleave', hideTooltip);


        if (this.props.selectable || this.props.onClick) {
            this.bubbles.on('click', function (e, d) {
                if(this.props.selectable) {
                    d.selected = !d.selected;
                    self.refreshBubbleSelection(this);

                    if (self.props.onSelectionChanged) {
                        self.props.onSelectionChanged(self.getSelection(), d.raw, d.selected);
                    }
                }
                if(this.props.onClick){
                    this.props.onClick(e, d);
                }
            }.bind(this));
        }

        // label
        items
            .append('text')
            .attr('y', d => (d.radius + 1))
            .attr('x', d => (d.labelX))
            .text(d => (d.raw.label))
            .attr('text-anchor', d => d.labelAnchor)
            .attr('dominant-baseline', 'hanging')
            .attr('style', bubbleLabelStyle)
            .attr('fill', Colors.GRAY2)
        ;
        const setPosition = () => {
            items.attr('transform', d => {
                const areaUi = areasUi[d.raw.area.name] || {
                    x: 0,
                    y: gridHeight,
                    width: gridWidth,
                    height: gridHeight
                };

                // force node to stay within its area box
                const x = Math.max(areaUi.x, Math.min(areaUi.x + areaUi.width - 2 * dataGridPadding, d.x));
                const y = Math.max(areaUi.y, Math.min(areaUi.y + areaUi.height - 2 * dataGridPadding, d.y));

                return `translate(${x},${y})`;
            });
        };

        setPosition();

        // avoid collision
        if (avoidCollision) {
            // var collisionForce = d3
            //   .forceCollide((n) => {
            //     return Math.max(n.dimension.width / 2, n.dimension.height / 2);
            //   })
            //   .strength(1)
            //   .iterations(10);

            const collisionForce = bboxCollide((n) => {
                const w = n.dimension.width / 2;
                const h = n.dimension.height / 2;
                return [
                    [-w, -h],
                    [w, h]
                ];
            })
                .strength(0.005)
                .iterations(10);

            const simulation = d3.forceSimulation(this.nodes).force('boxCollide', collisionForce);
            simulation.on('tick', setPosition);
        }

        // -----------------
        // -- vertical Lines & label
        // -----------------

        dataset.verticalLines.forEach(line => {
            if (_.isNil(dataset.xAxis.min) || _.isNil(dataset.xAxis.max)) {
                throw new Error("Expect min and max for x axis are mandatory to display vertical lines")
            }

            const x = Math.abs(line.value - dataset.xAxis.min) * gridWidth / Math.abs(dataset.xAxis.max - dataset.xAxis.min);

            areasContainer.append('line')
                .attr('stroke', overAllLineColor)
                .attr('x1', x)
                .attr('y1', gridHeight - areaBorder / 2)
                .attr('x2', x)
                .attr('y2', 0 + areaBorder / 2);

            areasContainer.append('text')
                .text(line.label)
                .attr('fill', overAllLineColor)
                .attr('font-weight', '600')
                .attr('font-size', areaLabelFontSize)
                .attr('dominant-baseline', 'ideographic')
                .attr('text-anchor', 'middle')
                .attr('x', x)
                .attr('y', -3);
        });
        // -----------------
        // -- horizontal Lines & label
        // -----------------

        dataset.horizontalLines.forEach(line => {
            if (_.isNil(dataset.yAxis.min) || _.isNil(dataset.yAxis.max)) {
                throw new Error("Expect min and max for y axis are mandatory to display horizontal lines")
            }
            const y = Math.abs (dataset.yAxis.max  - line.value ) * gridHeight / Math.abs(dataset.yAxis.max - dataset.yAxis.min) ;

            areasContainer.append('line')
                .attr('stroke', overAllLineColor)
                .attr('x1', gridWidth - areaBorder / 2)
                .attr('y1', y)
                .attr('x2', 0 + areaBorder / 2)
                .attr('y2', y);

            areasContainer.append('text')
                .text(line.label)
                .attr('fill', overAllLineColor)
                .attr('font-weight', '600')
                .attr('font-size', areaLabelFontSize)
                .attr('dominant-baseline', 'ideographic')
                .attr('text-anchor', 'middle')
                .attr('transform',
                    `translate(-3,${y}),rotate(-90)`);
        });
    };

    renderTooltipTarget = (props) => {
        return <span ref={props.popoverRef} {...props} style={{
            position: 'absolute',
            left: this.state.currentItem.shape.left,
            top: this.state.currentItem.shape.top,
            width: this.state.currentItem.shape.width,
            height: this.state.currentItem.shape.height,
            zIndex: -1
        }}/>;
    };

    renderTooltip() {
        const tooltipVisible = this.state.currentItem !== null;

        if (!tooltipVisible) {
            return null;
        }

        const {dataset} = this.props;

        const TooltipContent = <div>
            <div style={{fontWeight: 700, fontSize: 16}}>{this.state.currentItem.data.label}</div>
            <div style={{paddingLeft: 5}}>{dataset.xAxis.label} : {this.state.currentItem.data.data.x}</div>
            <div style={{paddingLeft: 5}}>{dataset.yAxis.label} : {this.state.currentItem.data.data.y}</div>
        </div>;

        return <Tooltip isOpen={true} content={TooltipContent}
                         placement={'bottom'}
                         renderTarget={this.renderTooltipTarget}
        >
            <span/>
        </Tooltip>;
    }

    render() {
        return <div className='vko-bubble-chart'>
            <svg ref={e => {
                this.svg = e;
            }}/>
            {this.renderTooltip()}
        </div>;
    }

}
