import React from 'react';
import { stateConverterRegistry } from './stateConverters/stateConverterRegistry';
import { StateConverter } from "./stateConverters/StateConverter";
import { BooleanFilterCtrl } from "./BooleanFilterCtrl";

export class NotEmptyVerbatimsFilterCtrl extends BooleanFilterCtrl {


  constructor(id: string, initValue: boolean = false, displayed: () => boolean) {
    super(id, initValue, 'filter.tag.notEmptyVerbatim', displayed);
  }

  stateConverter(): StateConverter<Boolean> {
    return stateConverterRegistry.get(Boolean);
  }
}