import { Condition } from './Condition';
import _ from "lodash";
import { ParamValue } from "../values/ParamValue";

export class ParamMatchCondition extends Condition {
  static parse(object: any): ParamMatchCondition {
    if (_.isNil(object.param)) {
      throw new Error('param is mandatory');
    }
    if (_.isNil(object.value)) {
      throw new Error('value is mandatory');
    }

    return new ParamMatchCondition(object.param, object.value);
  }

  private readonly paramProperty: string;
  readonly param: string;
  readonly var: string;
  readonly value: string;

  constructor(param: string, value: string) {
    super('paramMatch');

    this.paramProperty = param;
    this.value = value;

    // decode param
    const idx = param.indexOf('.');
    if (idx > 0 && idx < param.length - 1) {
      this.param = param.substring(0, idx);
      this.var = param.substring(idx + 1);
    } else {
      this.param = param;
    }
  }

  matches(state: Dict<ParamValue>): boolean {
    let o;
    const stateElement = state[this.param];

    if (_.isNil(stateElement)) {
      return false;
    }

    o = stateElement.getValueAsPlainObject();
    if (!_.isEmpty(this.var)) {
      o = o[this.var];
    }
    return o === this.value;
  }

  asPlainObject(): any {
    return {
      ... super.asPlainObject(),
      param: this.paramProperty,
      value: this.value
    };
  }
}