import {Popover} from "@blueprintjs/core";
import React from 'react';
import ReactDOM from "react-dom";
import {ApplicationLoading} from './application/ApplicationLoading';
import {EntryPoint} from './application/EntryPoint';
import {ErrorBoundary} from './application/view/ErrorBoundary';
import {ExportEntryPoint} from './export/ExportEntryPoint';
import './style/main.scss';
import './utils/fromEntries';
import {services} from "./application/service/services";
import "./application/service/applicationService";
import "./application/service/standaloneApplicationService";
import {createRoot} from "react-dom/client";

Popover.defaultProps.modifiers = {computeStyles: {options: {gpuAcceleration: false}}};

async function start() {
    const applicationService = services.getApplicationService();
    const container = document.getElementById('app');
    const root = createRoot(container!);
    root.render(<ApplicationLoading/>);

    try {
        console.log('loading application');
        await applicationService.init();
        console.log('Application loaded');
    } catch (error) {
        console.error('Something went wrong during application initialization', error);
        applicationService.notifyMessage({
            level: 'ERROR',
            text: {
                key: `error.application.load`
            },
            error: {
                type: 'RUNTIME',
                error: error
            }
        })
        return;
    }

    const View = services.getDashboardExportService().shouldExport() ? ExportEntryPoint : EntryPoint;
    root.render(<ErrorBoundary><View/></ErrorBoundary>);

    // if (module.hot) {
    //   module.hot.accept();
    // }
}

start().then();
