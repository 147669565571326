import { Divider } from '@blueprintjs/core';
import { Tonality } from '@eptica/vecko-js-commons';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SatTypeEnum } from '../../../../application/model/SatTypeEnum';
import { services } from '../../../../application/service/services';
import { Header } from '../../../../component/Header';
import { SeparatedComponents } from '../../../../component/SeparatedComponents';
import { FeedbackForReport } from '../../../../feedback/FeedbackForReport';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import { ResponsiveLightGauge } from './LightGauge';
import _ from "lodash";
import { FeedbackHeaderProps } from "../../../../feedback/FeedbackHeader";

interface TopicSatScoreVizProps extends WithTranslation{
  viz: VisualizationInstance
}

class TopicSatScoreVizComponent extends React.PureComponent<TopicSatScoreVizProps> {

  renderSample(sample) {

    const { t } = this.props;
    const title = services.getFieldsService().findCategoryLabel('topic', sample.topic, t);
    const headerProps = {
      renderDate: false,
      renderChannel: false,
      renderSatisfactionIndicator: false,
      headerElements: [{
        id:'title',
        component: <Header title={title} titleStyle={{ fontWeight: 600, textTransform: 'uppercase' }}/>,
        anchor: 'left'
      }]
    } as FeedbackHeaderProps;
    return (
      <FeedbackForReport key={sample.topic}  feedback={sample.feedback} headerProps={headerProps} />
    );
  }


  renderSamples() {
    const { viz, t } = this.props;

    return <div className='vui-layout--vertical' style={{ flex: 0.55 }}>
      <SeparatedComponents separator={<Divider/>}>
        {
          viz.data
            .filter(d => !_.isNil(d.feedback))
            .map(d => this.renderSample(d))
        }
        {
          viz.data
            .filter(d => _.isNil(d.feedback))
            .map(d => <div>{services.getFieldsService().findCategoryLabel('topic', d.topic, t)}</div>)
        }
      </SeparatedComponents>
    </div>;
  }

  render() {
    const { viz, t } = this.props;

    const satType = SatTypeEnum.valueOf(viz.definition.params.satType);
    const tonality = Tonality.getValueOf(viz.definition.params.polarity);

    const flex = viz.definition.params.noSamples ? 1 : 0.45;

    return <div className='vui-layout--horizontal vui-layout--horizontal__center'>
      <div className='vui-layout--vertical' style={{ flex: flex }}>
        <div className='vui-layout--vertical__center vui-layout--horizontal'>
          {viz.data.map(d => <div key={d.topic} className='vui-layout--vertical'
                                  style={{ flex: 1, padding: 3 }}>
            <ResponsiveLightGauge score={d.score} pathColor={tonality.color}
                                  min={satType.min} max={satType.max}/>
          </div>)}
        </div>
        <div className='vui-layout--vertical__bottom vui-layout--horizontal'>
          {viz.data.map(d => <div key={d.topic}
                                  style={{
                                    flex: 1,
                                    padding: 3,
                                    textAlign: 'center',
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    textTransform: 'uppercase'
                                  }}>
            {services.getFieldsService().findCategoryLabel('topic', d.topic, t)}
          </div>)}
        </div>
      </div>

      {
        viz.definition.params.noSamples ? null
          : this.renderSamples()
      }

    </div>;
  }
}

export const TopicSatScoreViz = withTranslation(undefined, { withRef: true })(TopicSatScoreVizComponent);
