import _ from 'lodash';
import { SatTypeEnum } from '../../../../application/model/SatTypeEnum';
import { services } from '../../../../application/service/services';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { getTitle } from '../common';
import { SatScoreViz } from './SatScoreViz';
import { SatScoreVizEditor } from './SatScoreVizEditor';

const defaultUiParams = {
  distribution: {
    enabled: true,
    showNeutral: true,
    showPercent: true,
    showVolume: false
  }
};

const title = (vizInstance, t) => {
  const title = getTitle(vizInstance, t);
  if (title) {
    return title;
  }
  const subtitle = services.getDashboardParamsService().findParamLabelFromConstraint(t, vizInstance);
  const i18nContext = {
    satType: SatTypeEnum.valueOf(vizInstance.definition.params.satType).getAliasedName(),
    subtitle: subtitle ? subtitle : null
  };
  const i18nKey = subtitle ? 'dashboard.viz.satScore.title' : 'dashboard.viz.satScore.title_noSubtitle';

  return t(i18nKey, i18nContext);
};

registerVizType({
  name: 'SAT_SCORE',
  component: SatScoreViz,
  defaultUiParams: (vizDef) => defaultUiParams,
  title: title,
  noDataPolicy: {
    hasData: vizInstance => {
      return vizInstance.data.count > 0;
    }
  },
  editor: {
    component: SatScoreVizEditor,
    title: title,
    init(vizInstance) {
      return {
        uiParams: _.merge({}, defaultUiParams, vizInstance.definition.uiParams)
      };
    },
    applyOnUiParams: (uiParams, editedUiParams) => {
      Object.assign(uiParams, editedUiParams);
    }
  }
});