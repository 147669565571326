import {Position, Tooltip} from '@blueprintjs/core';
import _ from 'lodash';
import React, {RefObject} from 'react';
import {Chart as ChartJS} from 'react-chartjs-2';
import {ChartBuilder} from '../../utils/chart/ChartBuilder';
import './chart.scss';
import "chartjs-adapter-luxon";
import {TooltipModel} from "chart.js";

export interface ChartProps {
    chartBuilder: ChartBuilder,
    tooltip?: any
}

export class Chart extends React.PureComponent<ChartProps> {


    _chartRef: RefObject<any> = React.createRef();
    state: any;

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        this.props.chartBuilder.customTooltipCallback(this.tooltipHandler);
        const chart = this.props.chartBuilder.build();
        return {
            chartBuilder: this.props.chartBuilder,
            chart: chart,
            tooltip: null
        };
    }

    isTooltipVisible = () => {
        return !_.isNil(this.state.tooltip);
    };

    hideTooltip = () => {
        this.setState({tooltip: null});
    };


    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.chartBuilder !== nextProps.chartBuilder) {
            return {...prevState, chartBuilder: nextProps.chartBuilder, chart: nextProps.chartBuilder.build()};
        }

        return prevState;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.chartBuilder !== prevProps.chartBuilder) {
            this.setState(this.getInitialState());
        }
    }

    getTooltipContent = () => {
        if (!this.isTooltipVisible()) {
            return null;
        }

        const {tooltip} = this.state;
        const tooltipModal: TooltipModel<any> = tooltip.model;

        return <div>
            {tooltip.title ? <div className='vui-ellipsis' style={{
                fontWeight: 600,
                fontSize: 18,
                marginBottom: 10
            }}>{tooltip.title}</div> : null}
            <div>
                {
                    tooltipModal.dataPoints?.map(point => (
                        <React.Fragment key={point.datasetIndex}>
                            {this.props.chartBuilder.tooltipRenderer(point)(this.props.chartBuilder.tooltipValueTransformer(point.dataset.data[point.dataIndex]), point)}
                        </React.Fragment>
                    ))
                }
            </div>
        </div>;
    };

    tooltipHandler = (tooltipModel: { chart: Chart, tooltip: TooltipModel<any>, replay: boolean }) => {
        const chart = this._chartRef.current;
        if (!chart) {
            return;
        }

        if (!tooltipModel || tooltipModel.tooltip.opacity === 0) {
            this.hideTooltip();
            return;
        }

        const tooltip = {
            model: tooltipModel.tooltip,
            title: (tooltipModel.tooltip.title || [''])[0],
            position: {
                x: tooltipModel.tooltip.caretX,
                y: tooltipModel.tooltip.caretY
            }
        };

        this.setState({tooltip: tooltip});
    };

    renderTooltipTarget = ({isOpen, ref, ...targetProps}) => {
        return <span ref={ref} {...targetProps} style={{
            position: 'absolute',
            left: this.state.tooltip.position.x,
            top: this.state.tooltip.position.y
        }}/>;
    }

    renderTooltip() {
        if (this.isTooltipVisible()) {
            return <Tooltip isOpen={true} content={this.getTooltipContent()}
                            placement={Position.TOP}
                            renderTarget={this.renderTooltipTarget}
            />;
        }

        return null;
    }

    render() {
        const chart = this.state.chart;

        if (!chart) {
            return null;
        }

        return <div className='chart-wrapper'>
            <div>
                <div>
                    <ChartJS type={chart.type ? chart.type : 'bar'} width={null} height={null} data={chart.data}
                             options={chart.options} plugins={chart.plugins}
                             ref={this._chartRef}/>
                    {this.renderTooltip()}
                </div>
            </div>
        </div>;
    }
}
