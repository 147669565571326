import { SatAndTopicsAspect } from './SatAndTopicsAspect';
import { SatAspect } from './SatAspect';
import { SatTypeAspect } from './SatTypeAspect';
import { TopicAndPolarityAspect } from './TopicAndPolarityAspect';

export const parseAspect = (object) => {
  if (object.type === 'satAndTopics') {
    return SatAndTopicsAspect.parse(object);
  }
  if (object.type === 'sat') {
    return SatAspect.parse(object);
  }
  if (object.type === 'satType') {
    return SatTypeAspect.parse(object);
  }
  if (object.type === 'topicAndPolarity') {
    return TopicAndPolarityAspect.parse(object);
  }

  throw new Error('Cannot parse object into filter');
};