import { Colors } from '@blueprintjs/core';
import { KpiEvolution, KpiInstance } from "./KpiInstance";
import { TFunction } from "i18next";

/**
 * @property {string} name
 */
export abstract class Kpi {
  name: string;

  protected constructor(name: string) {
    this.name = name;
  }

  abstract getType(): string;

  abstract getLabel(kpiInstance: KpiInstance, t: TFunction): string;

  abstract getNumber(kpiInstance: KpiInstance, t: TFunction): string;

  abstract getLegend(kpiInstance: KpiInstance, t: TFunction): string;

  getIcon(kpiInstance: KpiInstance): string {
    return 'dashboard';
  }

  getColor(kpiInstance: KpiInstance): string {
    return Colors.GRAY1;
  }

  abstract getEvolution(kpiInstance: KpiInstance, t: TFunction): KpiEvolution;

  getApiParams(): any {
    return null;
  }

  convertForApi(): any {
    const result: any = {
      '@type': this.getType(),
      name: this.name
    };
    const params = this.getApiParams();
    result.params = params || {};

    return result
  }
}

// /**
//  * @property {Array<Kpi>} children
//  */
// export class CompositeKpi extends Kpi {
//   constructor(name, children) {
//     super(name);
//     this.children = children;
//   }
// }

