import React, { ReactNode } from "react";
import { ChartBuilder } from "./ChartBuilder";
import { TFunction } from "i18next";
import { services } from "../../application/service/services";
import { Field } from "../../application/model/field/Field";
import { TooltipItem } from "chart.js";
import _ from "lodash";


interface PieChartData {
  totalElement: number;
  items: { group: string; value: number, label: string }[];
}


interface FormattedData {
  labels: string[];
  datasets: { backgroundColor: string[]; data: any[] }[];
}

export class PieChartBuilder extends ChartBuilder {

  private static otherKey = "dashboard.viz.distributionChart.group.other";

  private data: PieChartData;

  private displayedItems = [];

  private dataIndexByGroup = {};

  private otherIndex;

  private legendOnClickHandler = (event, legendItem, legend) => {
    if (legendItem.index === this.otherIndex) return;
    const ci = legend.chart;

    ci.toggleDataVisibility(legendItem.index);
    if (!legendItem.hidden) {
      this.displayedItems.splice(this.displayedItems.indexOf(legendItem.text), 1);
    } else {
      this.displayedItems.push(legendItem.text);
    }
    ci.data.datasets[0].data = this.computeData();
    this.addOtherLabelIfNeeded(ci.data);
    this.formattedData = ci.data;
    ci.update();
  }
  private metricFieldLabel: string;

  private t: TFunction;

  private labelField: Field;
  private viz: any;
  private field: string;
  private formattedData: FormattedData;

  constructor(viz: any, labelField: Field, t: TFunction) {
    super('pie');
    this.t = t;
    this.labelField = labelField;
    this.viz = viz;
    this.metricFieldLabel = labelField.getLabel(this.t);
    this.field = this.viz.definition.spec.params.field;
    return this;
  }

  _tooltipRenderer = (value: any, tooltipItem: TooltipItem<any>) => {
    const tdStyle = { borderTop: '4px solid  rgba(0,0,0,0.0)', borderLeft: '14px solid  rgba(0,0,0,0.0)' };
    return <table style={{ textAlign: 'left', margin: '0 auto', borderCollapse: 'collapse', borderStyle: 'hidden' }}>
      <thead>
      <tr>
        {this.metricFieldLabel ? <th style={tdStyle}>{this.t('dashboard.viz.pieChart.tooltip.field')}</th> : null}
        <th style={tdStyle}>{this.t('dashboard.viz.pieChart.tooltip.value')}</th>
        <th style={tdStyle}>{this.t('dashboard.viz.pieChart.tooltip.count')}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        {this.metricFieldLabel ? <td style={tdStyle}>{this.metricFieldLabel}</td> : null}
        <td style={tdStyle}>{value.label}</td>
        <td style={tdStyle}>{value.value + '(' + value.percentage.toFixed(2) + ')'}</td>
      </tr>
      </tbody>
    </table>;
  };
  private otherGroupPresent: Boolean = false;

  withData(data: PieChartData): PieChartBuilder {
    this.data = {totalElement: data.totalElement,
      items: data.items.map(value => {return {...value, label: null}})
    };
    return this;
  }

  build() {

    const chartObject = super.build();
    const chartOptions: any = chartObject.options;
    chartOptions.parsing = { key: 'percentage' };
    if (chartOptions.plugins.legend && chartOptions.plugins.legend.display) {
      chartOptions.plugins.legend.onClick = this.legendOnClickHandler;
      chartOptions.plugins.legend.labels = {
        font: {
          size: 14,
          family: this.getFont()
        }
      };
    }

    if (this._displayLabelsInline) {
      chartOptions.plugins.datalabels = {
        ...chartOptions.plugins.datalabels,
        ...{
          anchor: 'end',
          offset: 10,
          align: 'start',
          color: 'white',
          formatter: value => Math.round(value.percentage) + "﹪",
        }
      }
    }

    this.formattedData = {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: []
      }]
    };
    let index = 0;

    this.data.items.forEach((entry) => {
      const field = services.getFieldsService().findFieldValue(this.labelField.name, entry.group);
      entry.label = field? field.getLabel(this.t): entry.group;
      const key = this.getLabelWithCount(entry);
      this.dataIndexByGroup[key] = index;
      this.displayedItems.push(key);
      this.formattedData.labels.push(key);
      this.formattedData.datasets[0].backgroundColor.push(services.getColorService().getColorForFieldValue(this.field, entry.group));
      index++;
    });

    this.displayedItems = [...this.formattedData.labels];

    this.formattedData.datasets[0].data = this.computeData();


    this.addOtherLabelIfNeeded(this.formattedData)
    this.formattedData.datasets[0].backgroundColor.push(services.getColorService().getOtherColor());

    return { ...chartObject, data: this.formattedData };
  }

  private getLabelWithCount(entry: { group: string; value: number; label: string }) {
    return (_.isNil(entry.label) ? entry.group : entry.label) + " (" + entry.value + ")";
  }

  addOtherLabelIfNeeded(computedData: FormattedData) {
    //Reset other group
    if (this.otherGroupPresent) {
      computedData.labels.pop();
      this.otherGroupPresent = false;
    }
    if (computedData.datasets[0].data.length > this.data.items.length) {
      computedData.labels.push(this.getOtherItemLabel(computedData));
      this.otherGroupPresent = true;
    }
  }

  private getOtherItemLabel(formattedData: FormattedData) {
    return this.getOtherGroupName() + " (" + formattedData.datasets[0].data[formattedData.datasets[0].data.length - 1].value + ")";
  }

  computeData():any[] {
    const data = [];
    let index = 0;
    const otherCount = this.computeOther();

    this.data.items.forEach((entry) => {
      data.push({ ...entry, percentage: entry.value * 100 / this.data.totalElement });
      index++;
    });

    this.otherIndex = data.length;
    this.dataIndexByGroup[PieChartBuilder.otherKey] = index;
    if (otherCount !== 0) {
      data.push({
        group: this.getOtherGroupName(),
        label: this.getOtherGroupName(),
        value: otherCount,
        percentage: otherCount * 100 / this.data.totalElement
      });
    }
    return data;
  }

  computeOther(): number {
    let otherCount = this.data.totalElement;
    this.displayedItems.forEach(value => {
      const dataIndex = this.dataIndexByGroup[value];
      otherCount -= this.data.items[dataIndex].value;
    })
    return otherCount;
  }


  tooltipRenderer(tooltipItem: TooltipItem<any>): (value: any, tooltipItem: TooltipItem<any>) => ReactNode {
    return this._tooltipRenderer;
  }

  private getOtherGroupName(): string {
    return this.t(PieChartBuilder.otherKey);
  }

  getDataToExport(): any[][]{
    const dataToExport = [];
    //Add Header
    const header = [];
    header.push(this.metricFieldLabel);
    header.push(this.t('dashboard.viz.pieChart.tooltip.count'));
    header.push(this.t('dashboard.viz.pieChart.tooltip.percentage'));
    dataToExport.push(header);

    //Add value
    this.formattedData.datasets[0].data
      .filter(value => this.displayedItems.indexOf(this.getLabelWithCount(value)) >= 0 || value.group === this.getOtherGroupName())
      .forEach(value => {
      const lineToExport = [];
      lineToExport.push(value.label);
      lineToExport.push(value.value);
      lineToExport.push(value.percentage.toFixed(2));
      dataToExport.push(lineToExport);
    })
    return dataToExport;
  }
}