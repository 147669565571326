import { services } from '../../application/service/services';
import { ResolvedParams } from '../model/params/resolve/ResolvedParams';
import { ResolvedParamValue } from '../model/params/resolve/ResolvedParamValue';

/**
 *
 * @param dashboardId
 * @param state redux state
 * @return the resolved parameters
 */
export const getResolvedParamsFromState = (dashboardId:string, state):ResolvedParams  => {
  const dashboardDefinition = services.getDashboardService().getDashboardDefinition(dashboardId);
  return new ResolvedParams(
    dashboardDefinition.parameters.map(param => new ResolvedParamValue(param, state[param.name])));
};

/**
 *
 * @para dashboardId
 * @param  state
 * @param  init
 * @return
 */
export const resolveParams = (dashboardId:string, state, init:boolean): ResolvedParams => {
  const dashboardDefinition = services.getDashboardService().getDashboardDefinition(dashboardId);

  const clonedState = { ...state };

  return new ResolvedParams(dashboardDefinition.parameters.map(param => {
    const value = param.resolveValue(clonedState, init && !param.optional);
    clonedState[param.name] = value;
    return new ResolvedParamValue(param, value);
  }));
};