import { SearchQuery } from '../../../../utils/query/SearchQuery';
import { services } from '../../../service/services';
import _ from 'lodash';
import { PageIndexManager } from './PageIndexManager';
import { FieldDisplayKindType } from "../../../model/field/FieldDisplay";
import { FeedbackDTO } from "../../../model";
import { SearchResult } from "../../../../utils/query/SearchResult";

export const feedbacksListState = (parent: string, displayKind: FieldDisplayKindType,
                                   {
                                     queryBuilderDecorator = _.noop,
                                     includeGlobalFilter = true,
                                     beforePageChange = _.noop, afterPageChange = _.noop,
                                     beforeFetching = _.noop, afterFetching = _.noop,
                                     namespaced = false
                                   } = {}) => {

  const createQueryFromPage = (rootState, page: number, pageSize: number): SearchQuery => {
    const query = SearchQuery.createFromPage(page, pageSize);
    queryBuilderDecorator(query, rootState);
    return query;
  }

  /**
   * @param rootState
   * @return {PageIndexManager}
   */
  const createPageIndexManagerFromState = (rootState) => {
    return new PageIndexManager(
      rootState[parent].feedbacks,
      rootState[parent].page,
      rootState[parent].pageSize,
      rootState[parent].total);
  };

  const moveTo = async (inc: number, rootState, dispatch) => {
    const pageIndexManager = createPageIndexManagerFromState(rootState);
    const newRealIndex = rootState[parent].details.index + inc;

    const feedbackInPage = pageIndexManager.getItemByRealIndex(newRealIndex);
    let feedbackDetails: FeedbackDTO;
    if (feedbackInPage !== null) {
      feedbackDetails = await services.getFeedbackService().getFeedbackDetailsById(feedbackInPage.id);
    } else {
      const query = createQueryFromPage(rootState, newRealIndex + 1, 1);
      feedbackDetails = (await services.getFeedbackService().searchFeedbackDetails(query)).hits[0];
    }

    dispatch[parent].setDetails({
        details: feedbackDetails,
        index: newRealIndex,
        hasPrevious: !pageIndexManager.isFirst(newRealIndex),
        hasNext: !pageIndexManager.isLast(newRealIndex)
      }
    );
  }


  return ({
    state: {
      pageSize: 10,
      page: 1,
      total: 0,
      feedbacks: [],
      loadingFeedbacks: false,
      displayKind: displayKind,

      details: null,
      debug: null
    },
    reducers: {
      setPage(state, payload) {
        state.page = payload;
        return state;
      },
      setSearchResult(state, payload) {
        if (_.isNil(payload)) {
          state.feedbacks = [];
          state.total = 0;
        } else {
          state.feedbacks = payload.hits;
          state.total = payload.total;
        }

        state.loadingFeedbacks = false;

        afterFetching(state, payload);

        return state;
      },
      'filter/setValue'(state, payload) {
        state.page = 1;
        return state;
      },
      startFetchingFeedbacks(state, payload) {
        state.loadingFeedbacks = true;
        return state;
      },
      setDetails(state, payload) {
        state.details = payload;
        return state;
      },
      dropDetails(state, payload) {
        state.details = null;
        return state;
      },
      setDebug(state, payload) {
        state.debug = payload;
        return state;
      },
      dropDebug(state, payload) {
        state.debug = null;
        return state;
      }
    },
    effects: (dispatch) => ({
      async reset(payload, rootState) {
        dispatch[parent].setPage(1);
        dispatch[parent].setSearchResult(rootState, null);
      },
      async fetchFeedbacks(payload, rootState) {
        beforeFetching(payload, rootState, dispatch[parent]);
        dispatch[parent].startFetchingFeedbacks();
        let page = rootState[parent].page;
        if (payload === true) {
          page = 1;
          dispatch[parent].setPage(page);
        }
        const query: SearchQuery = createQueryFromPage(rootState, page, rootState[parent].pageSize);
        const result: SearchResult<FeedbackDTO> = await services.getFeedbackService().getFeedbacks(query, displayKind, includeGlobalFilter);
        dispatch[parent].setSearchResult(result);
      },
      async deleteFeedbacks(payload, rootState) {
        const query: SearchQuery = createQueryFromPage(rootState, 0, rootState[parent].pageSize);
        const result = await services.getFeedbackService().deleteFeedbacks(query, includeGlobalFilter);
      },
      async changePage(payload, rootState) {
        await beforePageChange(payload, rootState, dispatch[parent]);

        const page = namespaced ? payload.data : payload;

        await dispatch[parent].setPage(page);
        await dispatch[parent].fetchFeedbacks();

        await afterPageChange(payload, rootState, dispatch[parent]);
      },
      async exportFeedbacks(payload, rootState) {
        const test = payload;
        const query = createQueryFromPage(rootState, 1, rootState[parent].pageSize);
        await services.getFeedbackService().exportFeedbacks(payload.outputType, payload.maxSize, payload.fields, query, displayKind, includeGlobalFilter);
      },
      async getDetails(payload, rootState) {
        const pageIndexManager = createPageIndexManagerFromState(rootState);
        const feedbackDetails: FeedbackDTO = await services.getFeedbackService().getFeedbackDetailsById(payload);
        const realIndex = pageIndexManager.getRealIndex(feedbackDetails);

        dispatch[parent].setDetails({
            details: feedbackDetails,
            index: realIndex,
            hasPrevious: realIndex >= 0 ? !pageIndexManager.isFirst(realIndex) : false,
            hasNext: realIndex >= 0 ? !pageIndexManager.isLast(realIndex) : false
          }
        );
      },
      async next(payload, rootState) {
        await moveTo(1, rootState, dispatch);
      },
      async previous(payload, rootState) {
        await moveTo(-1, rootState, dispatch);
      },
      async getDebug(payload, rootState) {
        const feedbackDebug: FeedbackDTO = await services.getFeedbackService().getFeedbackDebug(payload.veckoId, payload.substream, payload.tenant);
        dispatch[parent].setDebug(feedbackDebug);
      }
    })
  });
};

export const mapWithFeedbacksListState = (state, parent) => ({
  feedbacks: state[parent].feedbacks,
  page: state[parent].page,
  pageSize: state[parent].pageSize,
  total: state[parent].total,
  feedbackDetails: state[parent].details,
  feedbackDebug: state[parent].debug,
  displayKind: state[parent].displayKind,
});

export const mapWithFeedbacksListDispatch = (dispatch, parent) => ({
  onPageChange: dispatch[parent].changePage,
  getFeedbackDetails: (id) => dispatch[parent].getDetails(id),
  dropFeedbackDetails: () => dispatch[parent].dropDetails(),
  next: () => dispatch[parent].next(),
  previous: () => dispatch[parent].previous(),
  getFeedbackDebug: (veckoId, substream, tenant) => dispatch[parent].getDebug({veckoId, substream, tenant}),
  dropFeedbackDebug: () => dispatch[parent].dropDebug(),
});