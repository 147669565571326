import { FieldValue } from './FieldValue';
import { TFunction } from "i18next";
import { Field } from "./Field";

export class NoneFieldValue extends FieldValue {
  constructor(field: Field) {
    super(field, {name :'$$$NONE$$$'});
  }

  getLabel(t: TFunction): string {
    if (this.field.isCategoryTree()) {
      return t('filter.category.value.none');
    } else {
      return t('filter.field.value.none');
    }
  }
}