import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { MapEditor } from '../../../../component/MapEditor';
import { MetricSelector } from '../../../../component/selector/MetricSelector';
import { Metric } from '../../../model/MetricEnum';
import { MetricRangeEditor } from './MetricRangeEditor';
import { Range } from "../../../../component/RangeEditor";

interface MetricRangesEditorItem {
  metric: Metric,
  range: Range
}

interface MetricRangesEditorProps extends WithTranslation {
  className?: string
  metrics: Array<Metric>,
  items: Array<MetricRangesEditorItem>,
  onChanged: (items: Array<MetricRangesEditorItem>) => void,
  annihilateEnterKey?: boolean
}

class MetricRangesEditorComponent extends React.PureComponent<MetricRangesEditorProps> {
  itemKeyGetter = (item) => {
    return item.metric;
  };

  itemValueGetter = (item) => {
    return item.range;
  };

  /**
   * @param {Metric} key
   * @return {string}
   */
  keyId = (key) => {
    return key.name;
  };

  /**
   * @param {Metric} key
   * @return {string}
   */
  keyLabel = (key) => {
    const { t } = this.props;
    return key.getLabel(t);
  };

  keySelector = (keys, selectedKey, onSelectionChanged) => {
    return <MetricSelector items={keys}
                           selectedItem={selectedKey}
                           onSelectionChanged={onSelectionChanged}
                           allowEmptySelection={false}
                           annihilateEnterKey={this.props.annihilateEnterKey} />;
  };

  itemEditor = (item, key, onChanged) => {
    return <MetricRangeEditor metric={key} range={item.range} onRangeChanged={onChanged}/>;
  };

  createItem = (key, itemEditorOutput) => {
    return {
      metric: key,
      range: itemEditorOutput
    };
  };

  createEmptyItem = (key) => {
    return {
      metric: key,
      range: {}
    };
  };

  render() {
    const { className, metrics, items, onChanged, annihilateEnterKey} = this.props;

    return <MapEditor className={className}
                      keys={metrics}
                      items={items || []}
                      itemKeyGetter={this.itemKeyGetter}
                      itemValueGetter={this.itemValueGetter}
                      keyId={this.keyId}
                      keyLabel={this.keyLabel}
                      keySelector={this.keySelector.bind(this)}
                      itemEditor={this.itemEditor}
                      createItem={this.createItem}
                      createEmptyItem={this.createEmptyItem}
                      onChanged={onChanged}
                      fill={true}
                      annihilateEnterKey={annihilateEnterKey}
    />;
  }
}

export const MetricRangesEditor = withTranslation()(MetricRangesEditorComponent);
