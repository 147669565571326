import {
  calculateDepth,
  findFirstTreeNode,
  forEachLeaves,
  forEachNodes,
  hasChildren,
  isLeaf,
  mapTreeNodes
} from '../../../../utils/treeUtils';
import _ from 'lodash';
import { toArray } from "../../../../utils/collectionUtils";

const strictEqual = (a, b) => a === b;

export class TreeModel<T> {

  _nodes: Array<T>;

  _nodeChildrenSupplier: (o: T) => OneOrMany<T>;

  _nodeEqual: (o1: T, o2: T) => boolean;

  _depth: number;

  constructor(nodes: Array<T>, nodeChildrenSupplier: (o: T) => Array<T> = undefined, nodeEqual: (o1: T, o2: T) => boolean = undefined) {
    this._nodes = nodes;
    this._nodeChildrenSupplier = nodeChildrenSupplier || _.noop as any;
    this._nodeEqual = nodeEqual || strictEqual;

    this._depth = _.max(this._nodes.map(n => calculateDepth(n, this._nodeChildrenSupplier))) as number;
  }

  get depth(): number {
    return this._depth;
  }

  get nodes(): Array<T> {
    return this._nodes;
  }

  hasChildren(node: T): boolean {
    return hasChildren(node, this._nodeChildrenSupplier);
  }

  isLeave(node:T): boolean {
    return isLeaf(node, this._nodeChildrenSupplier);
  }

  getChildren(node: T): OneOrMany<T> {
    return this._nodeChildrenSupplier(node);
  }

  /**
   * @param {function(*):void} fn
   */
  forAllNodes(fn: (node: any, i: number, depth: number) => boolean | void): void {
    forEachNodes(this._nodes, this._nodeChildrenSupplier, fn);
  }

  forAllLeaves(fn: (node: T) => void) {
    this.forEachLeaves(this._nodes, fn);
  }

  forEachLeaves(nodes: OneOrMany<T>, fn: (node: T) => void) {
    forEachLeaves(nodes, this._nodeChildrenSupplier, fn);
  }

  /**
   * @param {function(T, Array<T>):*} nodeChildrenSetter
   * @param {function(T):*} fn
   * @template T
   */
  mapAllNodes(nodeChildrenSetter: (node:T, children: Array<T>) => any, fn: (node:T)=> any) {
    return mapTreeNodes(this._nodes, this._nodeChildrenSupplier, nodeChildrenSetter, fn);
  }

  nodesEqual(node1:T, node2:T):boolean {
    return this._nodeEqual(node1, node2);
  }

  nodesContain(nodes:Array<T>, node:T):boolean {
    return !_.isNil(nodes.find(s => this.nodesEqual(s, node)));
  }

  flattenTree():Array<T> {
    return this._nodes.flatMap(n => this.flatten(n));
  }

  flatten(node:T):Array<T> {
    let result = [node];

    if (this.hasChildren(node)) {
      result = result.concat(toArray(this.getChildren(node)).flatMap(n => this.flatten(n)));
    }

    return result;
  }

  /**
   * @param {T} node
   * @return {T|undefined}
   * @template T
   */
  findNode = (node) => {
    return this.find(n => this.nodesEqual(n, node));
  };

  /**
   * @param {function(T):boolean} fn
   * @template T
   */
  find(fn) {
    return findFirstTreeNode(this._nodes, this._nodeChildrenSupplier, fn);
  }
}