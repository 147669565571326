import { services } from '../../application/service/services';
import { PaginatedResult, SourcingError } from "../model";
import { SearchQuery } from "../../utils/query/SearchQuery";

export class SourcingErrorService {
  _sourcingErrorFetcher;

  init() {
    this._sourcingErrorFetcher = services.getFetcherService().getFetcher('sourcingError');
  }

  isAuthorized(): boolean {
    return services.getSecurityService().hasRole('vecko.sourcing.errors');
  }

  async getErrors(searchQuery: SearchQuery): Promise<PaginatedResult<SourcingError>> {
    return this._sourcingErrorFetcher.getErrors(searchQuery);
  }

  getValues(column: string) {
    return this._sourcingErrorFetcher.getValues(column);
  }
}

services.registerService('sourcingErrorService', new SourcingErrorService());