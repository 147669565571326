import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { services } from '../../../../application/service/services';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import { DistributionItem, DistributionPanel } from '../../components/distribution/DistributionPanel';
import './distributionViz.scss';
import _ from "lodash";
import { ClickableViz } from "../ClickableViz";
import { FieldVizFeedbacksQueryContext } from '../../../model/viz/VizFeedbacksQueryContext';
import { connect } from "react-redux";
import { FieldValue } from "../../../../application/model/field/FieldValue";

interface DistributionVizProps extends WithTranslation {
  viz: VisualizationInstance,
  openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => void
}

interface StateProps {
  clickItem: DistributionItem
}


export class DistributionVizComponent extends React.Component<DistributionVizProps> implements ClickableViz {

  state: StateProps = {
    clickItem: null,
  }


  onClickItem(item: DistributionItem) {
    this.state.clickItem = item;
    this.props.openFeedbacksPanel(this, item.label);
  };

  getFeedBacksQueryContext() {
    const result = new FieldVizFeedbacksQueryContext();
    if (this.state.clickItem) {
      const { viz } = this.props;
      result.field = viz.definition.params.field;
      result.selectedValue = this.state.clickItem.value;
    }
    return result;
  }

  getClickedFieldValue(): FieldValue {
    if (this.state.clickItem) {
      const { viz } = this.props;
      return services.getFieldsService().findFieldValue(viz.definition.params.field, this.state.clickItem.value);
    }
    return null;
  }


  render() {
    const { t, viz } = this.props;
    const uiParams = viz.definition.uiParams;

    const field = services.getFieldsService().getField(viz.definition.params.field);
    const fieldValues = !field ? null : services.getFieldsService().resolveFieldValuesFromConfiguration(field.name);

    let items = [];
    if (!_.isNil(fieldValues)) {
      const fieldValuesMap = Object.fromEntries(fieldValues.flattenValues().map(item => ([item.fullName, item])));
      viz.data.distribution.forEach(item => {
        const fv = fieldValuesMap[item.value];
        items.push({
          value: item.value,
          label: fv ? fv.getLabel(t) : item.value,
          volume: item.count,
          trend: item.trend,
          color: uiParams.color ? uiParams.color :
            services.getColorService().getColorForFieldValue(viz.definition.params.field, item.value)
        });
      });
    } else {
      items = viz.data.distribution.map(item => ({
        label: item.value,
        volume: item.count,
        trend: item.trend,
        color: uiParams.color ? uiParams.color : services.getColorService().getColorForFieldValue(viz.definition.params.field, item.value)
      }));
    }

    return <div className="distribution">
      <DistributionPanel items={items}
                         total={viz.data.total}
                         showPercent={uiParams.showPercent}
                         showVolume={uiParams.showVolume}
                         showTrend={uiParams.showTrend}
                         onClick={this.onClickItem.bind(this)}
      />
    </div>;
  }

}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => {
      dispatch.dashboard.openFeedBackPanel({ viz, panelTitle });
    },
  };
};

export const DistributionViz = withTranslation(undefined, { withRef: true })(connect(null, mapDispatchToProps, null, { forwardRef: true })(DistributionVizComponent));
