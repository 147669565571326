import { StateConverter, StateConverterParser } from './StateConverter';

export class StringStateConverter extends StateConverter<String> {
  clazz():Class<String> {
    return String;
  }

  getParser(): Maybe<StateConverterParser<String>> {
    return StringStateConverter.parser;
  }

  private static parser = (str:string): Maybe<String> => {
    return str;
  }
}