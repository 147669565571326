import { services } from '../service/services';
import { fetcherProxy } from './fetcherProxy';
import { remoteFetchers } from './remote/remoteFetchers';
import _ from "lodash";

export class FetcherService {
  _fetchersProvider;
  _prefix;
  _shouldSimulateWait;
  _fetchers = {};
  /**
   * @type MessageHandler
   */
  _messageHandler;

  async init(mock, shouldSimulateWait, messageHandler) {
    this._fetchersProvider = mock ? (await import(/* webpackChunkName: "mockFetchers" */ './mock/mockFetchers')).mockFetchers : remoteFetchers;
    this._prefix = mock ? 'MOCK' : 'REMOTE';
    this._shouldSimulateWait = shouldSimulateWait;
    this._messageHandler = messageHandler;

    Object.entries(this._fetchersProvider).forEach(([k, v]) => {
      this._register(k, v);
    });
  }

  getFetcher(name) {
    const fetcher = this._fetchers[name];
    if (_.isUndefined(fetcher)) {
      throw new Error(`Unknown fetcher '${name}'`);
    }
    return fetcher;
  }

  _register = (name, fetcher) => {
    this._fetchers[name] = fetcherProxy(name, fetcher, this._prefix, this._shouldSimulateWait, this._messageHandler);
  };
}

services.registerService('fetcherService', new FetcherService());

