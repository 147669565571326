import { DistributionKind } from "./DistributionKind";

const distributionKindCache = new Map<string, DistributionKind>();

export const getDistributionKind = <T extends DistributionKind>(type: String, id: string, creator: () => T): T => {
  const key = `${type}-${id}`;
  if (!distributionKindCache.has(key)) {
    distributionKindCache.set(key, creator());
  }
  return distributionKindCache.get(key) as T;
}