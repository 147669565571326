import { FormGroup, InputGroup, MenuItem, PopoverPosition, Tab, Tabs } from '@blueprintjs/core';
import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actionNamespacer } from '../../../state/utils/actionNamespacer';
import './customerCommentEditor.scss';
import { dispatchSlice, stateSlice } from './customerCommentEditorState';
import { FeedbacksSelector } from "../../components/FeedbacksSelector";
import { StringListEditor } from "../../../../component/StringListEditor";
import { services } from "../../../../application/service/services";
import { FieldValueSelector } from "../../../../component/selector/FieldValueSelector";
import { FieldValue } from "../../../../application/model/field/FieldValue";
import { VisualizationInstance } from "../../../model/viz/VisualizationInstance";
import { MultiSelectInput } from "../../../../component/select/MultiSelectInput";
import { FieldDisplay } from "../../../../application/model/field/FieldDisplay";
import { Field } from "../../../../application/model/field/Field";
import { FeedbackDTO } from "../../../../application/model";

interface CustomerCommentEditorProps extends WithTranslation {
  viz: VisualizationInstance,
  init: () => void,
  onValueChanged: (s1: string, s2: string, value: any) => void,
  setPage: (pageIndex: number) => void,
  editorData: any,
  feedbacks: Array<FeedbackDTO>,
  loadingFeedbacks: boolean,
  page: number,
  pageSize: number,
  total: number,
  topics: Array<FieldValue>,
  setTopics: (topics: Array<FieldValue>) => void,
  fetchFeedbacks: () => void,
}

class CustomerCommentEditorComponent extends React.PureComponent<CustomerCommentEditorProps> {

  state = {
    topics: []
  };

  headerFieldRenderer = (item: Field, { id, text, highlightedText, icon, selected }, {
    handleClick,
    modifiers,
    query
  }) => {
    return <MenuItem
      active={false}
      icon={selected ? 'tick' : 'blank'}
      disabled={modifiers.disabled}
      key={id}
      onClick={handleClick}
      text={highlightedText}
    />;

  };

  componentWillMount() {
    this.props.init();
  }

  onFeedbackSelect = (value) => {
    this.props.onValueChanged('data', 'feedbacks', value);
  };

  onTitleChange = (event) => {
    this.props.onValueChanged('data', 'title', event.target.value);
  };

  onCommentsChange = (value) => {
    this.props.onValueChanged('data', 'comments', value);
  };

  onHeaderFieldsChange = (items:Array<Field>) => {
    this.props.onValueChanged('params', 'headerFields', items.map(value => value.name));

    /* reload all feedback */
  };

  onPageChange = (pageIndex: number) => {
    this.props.setPage(pageIndex);
  };

  render() {
    const { t, editorData, feedbacks, loadingFeedbacks, page, pageSize, total, viz } = this.props;

    const headersFields = services.getFieldsService().getAllFields(FieldDisplay.FieldDisplayKind.DASHBOARD_FEEDBACK_LIST);
    const pagination = {
      current: page,
      total: total,
      pageSize: pageSize,
      onChange: this.onPageChange
    };

    const selectedHeadersFields = (editorData?.params?.headerFields || []).map(headerField => {
      return services.getFieldsService().getField(headerField);
    });

    const feedbacksSelectorTab = <div className={'tab-content'}>
      <FormGroup inline={true} style={{ justifyContent: 'center' }}>
        <FieldValueSelector onSelectionChanged={(selection: OneOrMany<any>) => {
          this.props.setTopics(selection);
          this.props.fetchFeedbacks();
        }}
                            allowEmptySelection={true}
                            allowMultiSelection={true}
                            canSelectLeavesOnly={true}
                            fieldValues={services.getFieldsService().getFieldValues('enhancement.categories.topic')}
                            selectedValue={this.props.topics}
                            selectInputProps={{
                              selectProps: {
                                fill: true,
                                popoverProps: {
                                  position: PopoverPosition.BOTTOM_LEFT
                                },
                                tagInputProps: {
                                  className: 'exclusions-select-input',
                                }
                              }
                            }}
        />
      </FormGroup>

      <FeedbacksSelector
        items={feedbacks}
        limit={viz.definition.uiParams.limit}
        pagination={pagination}
        fetchingData={loadingFeedbacks}
        selectedFeedbacks={editorData?.data?.feedbacks || []}
        onSelectFeedback={this.onFeedbackSelect}
        onPagerClick={this.onPageChange}
      />
    </div>;

    const customProperties = <div className={'tab-content'}>
      <FormGroup label={t('dashboard.viz.customerComment.editor.vizTitle')}
                 labelFor='feedbackTitle'>
        <InputGroup id="tTitle" value={editorData?.data?.title} onChange={this.onTitleChange}/>
      </FormGroup>
      <FormGroup label={t('dashboard.viz.customerComment.editor.comment')} labelFor='comment'>
        <StringListEditor
          addValueFieldLabel={t('dashboard.viz.standardProfile.editor.profileItem')}
          values={editorData?.data?.comments || []}
          onChange={this.onCommentsChange}/>
      </FormGroup>
      <FormGroup label={t('dashboard.viz.customerComment.editor.headerFields')} labelFor='headerFields'>
        <MultiSelectInput
          selectedItems={selectedHeadersFields}
          itemEqual={Field.equalityTester}
          items={headersFields}
          allowEmptySelection={true}
          itemRenderer={this.headerFieldRenderer.bind(this)}
          itemLabel={(item: Field) => item.getLabel(t)}
          onSelectionChanged={this.onHeaderFieldsChange}
        />
      </FormGroup>
    </div>;


    return <Fragment>
      <Tabs large={true}>
        <Tab id='feedbacks-tab'
             title={t('dashboard.viz.customerComment.editor.feedbacks')}
             panel={feedbacksSelectorTab}/>
        <Tab id='customFields-tab' title={t('dashboard.viz.customerComment.editor.customFields')}
             panel={customProperties}/>
      </Tabs>
    </Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    ...stateSlice(state),
    topics: stateSlice(state).topics
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const wrapActionPayload = actionNamespacer(ownProps.viz.id);
  dispatch = dispatchSlice(dispatch);

  return {
    init: () => {
      dispatch.init(wrapActionPayload());
    },
    setPage: pageIndex => {
      dispatch.changePage(wrapActionPayload(pageIndex));
    },
    setTopics: topics => {
      dispatch.changeTopics(topics);
    },
    fetchFeedbacks: () => {
      dispatch.fetchFeedbacks();
    }
  };
};

export const CustomerCommentEditor = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomerCommentEditorComponent));