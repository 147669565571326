import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import GridLayout, {WidthProvider} from 'react-grid-layout';
import {connect} from 'react-redux';
import {BlankSeparator} from '../../component/BlankSeparator';
import {InfiniteCache} from '../../utils/InfiniteCache';
import {Dashboard} from '../model/dashboard/Dashboard';
import './dashboardPanel.scss';
import DashboardTitleHeader from './DashboardTitleHeader';
import {Visualization} from './Visualization';
import {VisualizationEditorDialog} from './VisualizationEditorDialog';
import {DashboardExportParamsView} from "./params/DashboardExportParamsView";
import {services} from "../../application/service/services";
import {VisualizationInstance} from "../model/viz/VisualizationInstance";

const ReactGridLayout = WidthProvider(GridLayout);


interface DashboardPanelProps {
    dashboard: Dashboard,
    label?: string,
    canEditViz?: boolean,
    canEditLayout?: boolean,
    filterIsOpen: boolean,
    onStartVizEdit: (vizId: string) => void,
    onClick?: (event) => void,
}

class DashboardPanelComponent extends React.PureComponent<DashboardPanelProps> {
    editHandler = new InfiniteCache((vizId) => () => this.props.onStartVizEdit(vizId));
    static defaultProps = {
        canEditViz: false,
        canEditLayout: false
    };

    state = {
        rightPanelOpen: false,
        vizByPage: undefined as { [key: string]: Array<VisualizationInstance> }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filterIsOpen !== this.props.filterIsOpen) {
            //hack : dispatch resize event to force reactgridlayout to resize
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'))
            }, 200);
        }
    }

    /* shouldComponentUpdate(nextProps, nextState, nextContext) {
       return !_.isEqual(this.props.dashboard, nextProps.dashboard);
     }
   */
    public static getDerivedStateFromProps = (props, state) => {
        if (_.isUndefined(props.dashboard)) {
            return {};
        }

        const vizByPage = {};
        _.each(props.dashboard.visualizations, (vizInstance) => {
            if (_.isUndefined(vizByPage[vizInstance.definition.layout.page])) {
                vizByPage[vizInstance.definition.layout.page] = [];
            }
            vizByPage[vizInstance.definition.layout.page].push(vizInstance);
        });
        return {vizByPage}
    };

    layoutChanged(newLayout: any) {

    }

    onClick(event) {
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }

    renderViz(viz: VisualizationInstance) {
        const isEditable = this.props.canEditViz && viz.isEditable();

        return <Visualization viz={viz} editable={isEditable} onStartEdit={this.editHandler.get(viz.id)}/>
    }

    render() {
        const {dashboard, label} = this.props;
        const {vizByPage} = this.state;
        const dashboardDefinition = services.getDashboardService().getDashboardDefinition(dashboard.id);

        if (_.isUndefined(dashboard)) {
            return <div/>;
        }

        if (_.isUndefined(vizByPage)) {
            return <div/>;
        }

        const cls = ['dashboard-panel'];
        if (!this.props.canEditLayout) {
            cls.push('not-editable')
        }

        return (
            <div onClick={this.onClick.bind(this)} className={classNames(cls)}>
                <div className="firstPage">
                    <DashboardTitleHeader label={label}/>
                    <DashboardExportParamsView params={dashboardDefinition.parameters}/>
                </div>
                <BlankSeparator vertical={true} size={10}/>
                {
                    _.map(vizByPage, (page, p:any) => {
                        return <React.Fragment key={p}>
                            <DashboardTitleHeader label={label}/>
                            {p > 0 ? <BlankSeparator vertical={true} size={10}/> : null}
                            <ReactGridLayout cols={48} rowHeight={10} autoSize={true}
                                             containerPadding={[0, 0]}
                                             compactType={null}
                                             isResizable={this.props.canEditLayout}
                                             isDraggable={this.props.canEditLayout}
                                             onLayoutChange={this.layoutChanged}>
                                {
                                    page.map((viz, index) => {
                                        return <div key={index} data-grid={viz.definition.layout}>
                                            {this.renderViz(viz)}
                                        </div>;
                                    })
                                }

                            </ReactGridLayout>
                        </React.Fragment>;
                    })
                }
                <VisualizationEditorDialog/>

            </div>
        );
    }


}


const mapDispatchToProps = dispatch => {
    return {
        onStartVizEdit: (vizId) => {
            dispatch.dashboardVizEditor.start(vizId);
        }
    };
};
const mapStateToProps = (state) => ({
    filterIsOpen: state.filter.open,
});


export const DashboardPanel = connect(mapStateToProps, mapDispatchToProps)(DashboardPanelComponent);
