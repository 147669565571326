import { TFunction } from "i18next";

export abstract class EnhancedMetric {
  protected type: string;

  protected constructor(type: string) {
    this.type = type;
  }

  abstract supportPercentage(): boolean;

  abstract defaultUnitLabel(t:TFunction): string;

  getLabel(t: TFunction): string {
    return t(`metric.${this.type}`);
  }

  naturalOrderIsReversed(): boolean {
    return false;
  }

  asPlainObject(): any {
    return {
      '@type': this.type
    }
  }
}