import Color from 'color';
import React from 'react';
import {StyledProgressBar} from './StyledProgressBar';

interface ColoredProgressBarProps {
    value: number,
    color: string
}

export class ColoredProgressBar extends React.PureComponent<ColoredProgressBarProps> {

    static defaultProps = {};

    render() {
        const {value, color} = this.props;

        return <StyledProgressBar value={value} stripes={false} animate={false}
                                  style={{border: `1px solid ${Color(color).darken(0.2).toString()}`}}
                                  meterStyle={{
                                      backgroundColor: color,
                                      borderBottomRightRadius: 0,
                                      borderTopRightRadius: 0
                                  }}/>;
    }
}