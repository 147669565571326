import axios from 'axios';
import {saveStream} from '../../../utils/http/saveStream';

const getFeedbacks = (query) => {
    const json = JSON.stringify(query);
    return axios
        .post('/api/feedback/_search', json, {
            headers: {'Content-type': 'application/json'}
        })
        .then(response => response.data);
};

/**
 * @return {Promise<T>}
 */
const getFeedback = (id: string, fields: Array<string> = undefined) => {
    let queryParams = '';
    if (fields) {
        queryParams += "?fields=" + fields.map(value => encodeURIComponent(value)).join(",");
    }

    let uri = `/api/feedback/${encodeURIComponent(id)}${queryParams}`;
    return axios.get(uri, {
        headers: {'Content-type': 'application/json', 'Accept': 'application/json'}
    }).then(response => response.data);
};

const getFeedbackDebug = (veckoId: string, substream: string, tenant: string) => {
    let queryParams = `?substream=${substream}&tenant=${tenant}`;
    let uri = `/api/feedback/debug/${encodeURIComponent(veckoId)}${queryParams}`;
    return axios.get(uri, {
        headers: {'Content-type': 'application/json', 'Accept': 'application/json'}
    }).then(response => response.data);
};

const getFeedbackStats = (filter) => {
    const json = JSON.stringify(filter);
    return axios
        .post('/api/feedback/_stats', json, {
            headers: {'Content-type': 'application/json', 'Accept': 'application/json'}
        })
        .then(response => response.data);
};

const exportFeedbackStats = (filter) => {
    return saveStream({
        url: '/api/feedback/_stats',
        method: 'POST',
        body: filter,
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        fallbackFileName: 'feedbacks-stats.xlsx'
    });
};


const deleteFeedbacks = (query) => {
    const json = JSON.stringify(query);
    return axios
        .delete('/api/feedback', {
            data: json,
            headers: {'Content-type': 'application/json'}
        })
        .then(response => response.data);
};


export default {
    getFeedbacks,
    getFeedback,
    getFeedbackDebug,
    getFeedbackStats,
    exportFeedbackStats,
    deleteFeedbacks
};