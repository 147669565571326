import colors from '../../style/_chartColors.module.scss';
import { services } from "../../application/service/services";
import _ from "lodash";
import { VeckoColors } from "../../style/VeckoColors";
import Color from "color";
import { FieldValue } from "../../application/model/field/FieldValue";


export class ColorService {
  private graphColors: any;
  private allColors: any;
  private colorsByField: Map<string, Map<string, string>> = new Map<string, Map<string, string>>();
  private declaredFieldValues: Map<string, FieldValue> = new Map<string, FieldValue>();
  private colorsByFieldValueIds: Map<string, string> = new Map<string, string>();

  constructor() {
    this.allColors = colors;
    this.graphColors = Object.fromEntries(Object.entries(colors).filter(([key, value]) => key.startsWith('color')));
  }

  getColorForFieldValue(fieldName: string, value: string): string {
    const field = services.getFieldsService().getField(fieldName);
    if (field) {
      const fieldValue = services.getFieldsService().findFieldValue(field.name, value);
      if (fieldValue) {
        if (fieldValue.color) {
          //check if color is an hexadecimal code
          if (/^#[0-9A-F]{6}$/i.test(fieldValue.color)) {
            return fieldValue.color;
          }

          //try to find color in colors
          const color = this.allColors[fieldValue.color];
          if (color) return color;

          //color not found -> ignore it
        }
        //search for color in declared fieldValues
        if (this.colorsByFieldValueIds.has(fieldValue.getId())) {
          return this.colorsByFieldValueIds.get(fieldValue.getId());
        }
      }
    }

    let fieldColorsByValue = this.colorsByField.get(fieldName)
    if (!fieldColorsByValue) {
      fieldColorsByValue = new Map<string, string>();
      this.colorsByField.set(fieldName, fieldColorsByValue);
    }
    if (!fieldColorsByValue.has(value)) {
      fieldColorsByValue.set(value, this.getColor(fieldColorsByValue.size));
    }
    return fieldColorsByValue.get(value);
  }

  getColor(index: number): string {
    const nbOfColor = _.size(this.graphColors);
    if (index >= nbOfColor) {
      //return random color code
      return Color.rgb(Math.random() * 255, Math.random() * 255, Math.random() * 255).hex();
    }
    return this.graphColors['color' + index];
  }

  getContrastColor(color: string) {
    if (color) {
      const colorObj = Color(color);
      if (colorObj.isDark()) {
        return VeckoColors.WHITE;
      }
    }
    return VeckoColors.DEFAULT_FG;
  }

  getPrimaryThemeColor() {
    return colors['primary'];
  }

  getOtherColor() {
    return "#AEB6BF";
  }

  reset() {
    this.declaredFieldValues = new Map<string, FieldValue>();
  }

  build() {
    let colorIndex = 0;
    let fieldValuesToProcess = new Map(this.declaredFieldValues);
    let levelIndex = 0;
    let securityIndex = 0;

    while (fieldValuesToProcess.size !== 0 && securityIndex < 10) {
      const nextFieldValuesToProcess = Array.from(fieldValuesToProcess.values()).filter(fieldValue => fieldValue.level === levelIndex);
      nextFieldValuesToProcess.forEach(value => {
        const color = value.color ? value.color : this.getColor(colorIndex++);
        this.colorsByFieldValueIds.set(value.getId(), color);
        fieldValuesToProcess.delete(value.getId());

        //set color for child
        //children are removed from fieldValuesToProcess if present
        this.assignColorToChildren(fieldValuesToProcess, value.children, color);
      });
      levelIndex++;
      securityIndex++;
    }
  }

  private assignColorToChildren(remainingFieldValuesToProcess: Map<string, FieldValue>, children: Array<FieldValue>, color: string) {
    if (!children || children.length === 0) return;
    let colorIndex = 1;
    children.forEach(child => {
      const childIndex = remainingFieldValuesToProcess.get(child.getId());
      let childColor = color;
      if (childIndex) {
        //assign color
        childColor =  this.lightenDarkenColor( color , (colorIndex % 5) * -30);
        colorIndex++;
        this.colorsByFieldValueIds.set(child.getId(), childColor);
        //fieldValue is assigned -> delete from array of element to process
        remainingFieldValuesToProcess.delete(child.getId());
      }

      this.assignColorToChildren(remainingFieldValuesToProcess, child.children, childColor);
    })
  }

  public lightenDarkenColor(color, amount) {
    const newColor = '#' + color
      .replace(/^#/, '')
      .replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16))
        .substr(-2));
    return newColor;
  }

  public addTransparency(color, amount: number) {
    const newColor = '#' + color
      .replace(/^#/, '');

    let alpha = -(amount * 50);
    while (alpha < 0) alpha = alpha + 255
    let alphaAsHex = alpha.toString(16)
    if (alphaAsHex.length < 2) {
      alphaAsHex = "0" + alphaAsHex;
    }
    return newColor + alphaAsHex;
  }

  registerFieldAndValue(fieldName: string, value) {
    const field = services.getFieldsService().getField(fieldName);
    if (field) {
      const fieldValue = services.getFieldsService().findFieldValue(field.name, value);
      if (fieldValue) {
        this.declaredFieldValues.set(fieldValue.getId(), fieldValue)
      }
    }
  }
}

services.registerService('colorService', new ColorService());