import axios from "axios";
import {PaginatedResult, SourcingError} from "../../../administration/model";
import {SearchQuery} from "../../../utils/query/SearchQuery";

const url = (endpoint: string): string => {
    return `/api/sourcing/${endpoint}`;
}

const getErrors = async (searchQuery: SearchQuery): Promise<PaginatedResult<SourcingError>> => {
    const json = JSON.stringify(searchQuery.toPlainObject())
    return axios.post(url('_errors'), json, {
        headers: {'Content-type': 'application/json'}
    }).then(response => response.data);
}


const getValues = async (field: string): Promise<Array<string>> => {
    return axios.get(url(`_errors/values/${field}`), {
        headers: {'Content-type': 'application/json'}
    }).then(response => response.data);
}

export default {getErrors, getValues};
