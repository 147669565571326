import {services} from '../../application/service/services';

export enum RelaunchStep {
    SOURCING = 'SOURCING',
    PROCESSING = 'PROCESSING',
    INDEXING = 'INDEXING',
    PUSH = 'PUSH',
}

export class SourcingService {
    _sourcingFetcher;
    _progressFetcher;

    init() {
        this._sourcingFetcher = services.getFetcherService().getFetcher('sourcing');
        this._progressFetcher = services.getFetcherService().getFetcher('progress');
    }

    isAuthorized(): boolean {
        return this.isAuthorizedForJson()
            || this.isAuthorizedForFile()
            || this.isAuthorizedForAudio();
    }

    isAuthorizedForJson() {
        return services.getSecurityService().hasRole('vecko.sourcing.push_raw');
    }

    isAuthorizedForFile() {
        return services.getSecurityService().hasRole('vecko.sourcing.push_file');
    }

    isAuthorizedForAudio() {
        return services.getSecurityService().hasRole('vecko.sourcing.push_audio');
    }

    async sourceAudio(data: any): Promise<void> {
        await this._sourcingFetcher.sourcingAudio(data);

        services.getApplicationService().notifyMessage({
            level: 'SUCCESS',
            text: {
                key: 'administration.sourcing.local.source.success'
            }
        });
    }

    async sourceXlsx(file: File): Promise<void> {
        await this._sourcingFetcher.sourcingXlsx(file);

        services.getApplicationService().notifyMessage({
            level: 'SUCCESS',
            text: {
                key: 'administration.sourcing.local.source.success'
            }
        });
    }

    async sourceCsv(data: any): Promise<void> {
        await this._sourcingFetcher.sourcingCsv(data);

        services.getApplicationService().notifyMessage({
            level: 'SUCCESS',
            text: {
                key: 'administration.sourcing.local.source.success'
            }
        });
    }

    async sourceJson(file: File): Promise<void> {
        await this._sourcingFetcher.sourcingJson(file);

        services.getApplicationService().notifyMessage({
            level: 'SUCCESS',
            text: {
                key: 'administration.sourcing.local.source.success'
            }
        });
    }

    async relaunch(tenant: string, substream: string, id: string, step: RelaunchStep) {
        await this._progressFetcher.relaunchProcess(tenant, substream, id, step);
        services.getApplicationService().notifyMessage({
            level: 'SUCCESS',
            text: {
                key: 'administration.sourcing.feedback.relaunch.success'
            }
        });
    }
}

services.registerService('sourcingService', new SourcingService());