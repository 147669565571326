import _ from 'lodash';
import { checkArrayItemsInstanceOf, toArray } from '../../../utils/collectionUtils';
import { AllItemsMatch } from '../../../utils/matcher/AllItemsMatch';
import { Every } from '../../../utils/matcher/Every';
import { InstanceOf } from '../../../utils/matcher/InstanceOf';
import { IsOneOf } from '../../../utils/matcher/IsOneOf';
import { Predicate } from "../../../utils/matcher/Predicate";

interface ConstrainedValuesOnAllowedAdditionalArgs<T> {
  additionalConstraint?:Predicate<T>,
  equalComparator?:EqualComparator<T>
}

export class ConstrainedValuesOnAllowed<T> {
  private readonly _allowedValues: Array<T>;
  private _values: Array<T> = [];
  public readonly allowEmpty: boolean;
  public readonly allowMulti: boolean;
  private readonly _valueVerifier: Predicate<Array<T>>;

  constructor(clazz: Class<T>, allowedValues: Array<T>, allowEmpty: boolean, allowMulti: boolean,
              { additionalConstraint = undefined, equalComparator = undefined }:ConstrainedValuesOnAllowedAdditionalArgs<T> = {}) {

    // allowed values must all be instance of the given clazz
    this._allowedValues = checkArrayItemsInstanceOf(allowedValues, clazz, true);

    this.allowEmpty = allowEmpty;
    this.allowMulti = allowMulti;

    // verifier that will validate value :
    //- must be instance of the given clazz
    //- must be one of the allowed values
    //- must match the additionalConstraint
    const predicates:Array<Predicate<T>> = [new InstanceOf(clazz, false), new IsOneOf(this._allowedValues, equalComparator)];
    if (!_.isNil(additionalConstraint)) {
      predicates.push(additionalConstraint);
    }
    this._valueVerifier = new AllItemsMatch<T>(new Every(predicates));
  }

  get allowedValues():Array<T> {
    return this._allowedValues;
  }

  /**
   * @return {Array}
   */
  getValues() {
    return this._values;
  }

  get value():Maybe<OneOrMany<T>> {
    if (this.allowMulti) {
      return this._values;
    }
    return this._values.length === 0 ? null : this._values[0];
  }

  set value(value:Maybe<OneOrMany<T>>) {
    let values;

    if (_.isNil(value)) {
      values = [];
    } else {
      values = toArray(value);
    }

    if (!this.allowMulti && values.length > 1) {
      throw new Error('Multiple selection is not allowed');
    }

    if (!this.allowEmpty && values.length === 0) {
      throw new Error('Empty selection is not allowed');
    }

    if (!this._valueVerifier.match(values)) {
      throw new Error(`Value "${value}" is not allowed`);
    }

    this._values = values;
  }
}
