import {Button, Collapse} from '@blueprintjs/core';
import React from 'react';
import {withTranslation} from 'react-i18next';
import classnames from 'classnames';

interface CollapsablePanelProps {
    className: string,
    collapsable: boolean,
    isOpen: boolean,
    initialOpen: boolean,
    onChange: any,
    data: any,
    headerRight: any,

    children: any
}

interface StateProps {
    isOpen?: boolean
};

class CollapsablePanelComponent extends React.PureComponent<CollapsablePanelProps> {
    static defaultProps: Partial<CollapsablePanelProps> = {
        collapsable: true,
        isOpen: false,
        initialOpen: false
    };

    state: StateProps = {}

    constructor(props, context) {
        super(props, context);
        this.state = {
            isOpen: props.initialOpen
        }
    }

    toggleCollapse = () => {
        if (this.props.onChange) {
            this.props.onChange(!this.isOpen(), this.props.data);
        } else {
            this.setState({isOpen: !this.isOpen()});
        }
    };

    isOpen = () => {
        if (this.props.onChange) {
            return this.props.isOpen;
        } else {
            return this.state.isOpen;
        }
    };

    render() {
        const children = React.Children.toArray(this.props.children);
        if (children.length === 0) {
            return null;
        }

        const {className, collapsable, headerRight} = this.props;
        const isOpen = this.isOpen();

        const collapseButton = collapsable ?
            <Button icon={isOpen ? 'chevron-up' : 'chevron-down'} onClick={this.toggleCollapse}
                    minimal={true}/> :
            null;

        const cls = classnames('vui-layout--horizontal', collapsable ? 'vui-interactive' : null);

        return <div className={className}>
            <div className={cls}>
                <div className='vui-layout--horizontal__center'
                     style={{alignItems: 'center', cursor: collapsable ? 'pointer' : 'default', paddingLeft: 5}}
                     onClick={this.toggleCollapse}>
                    {children[0]}
                </div>
                <div className='vui-layout--horizontal'>
                    {headerRight}
                    {collapseButton}
                </div>
            </div>
            <Collapse isOpen={isOpen}>{[...children.slice(1)]}</Collapse>
        </div>;
    }
}

export const CollapsablePanel = withTranslation()(CollapsablePanelComponent);
