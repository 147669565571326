import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { FieldValue } from '../../application/model/field/FieldValue';
import { FieldValues } from '../../application/model/field/FieldValues';
import { SelectPanel } from '../select/SelectPanel';

class FieldValueSideSelectorComponent extends PureComponent {
  getValueLabel = (value) => value.getLabel(this.props.t);

  getValueId = (value) => value.getId();

  fieldValueChildrenSupplier = (node) => node.children;

  render() {
    const { t, fieldValues, openToggle, isOpen } = this.props;

    return <SelectPanel label={fieldValues.field.getLabel(t)}
                        items={fieldValues.values}
                        itemId={this.getValueId}
                        itemLabel={this.getValueLabel}
                        itemEqual={FieldValue.equalityTester}
                        selection={this.props.selectedValue}
                        expandedItems={this.props.expandedItems}
                        itemChildrenSupplier={this.fieldValueChildrenSupplier}
                        collapsable={true}
                        isOpen={isOpen}
                        onCollapseChanged={openToggle}
                        onSelectionChanged={this.props.onSelectionChanged}
                        allowEmptySelection={this.props.allowEmptySelection}
                        allowMultiSelection={this.props.allowMultiSelection}
                        allowNoneFieldValue={this.props.allowNoneFieldValue}
                        displayItemsFilter={true}
    />;
  }
}

export const FieldValueSideSelector = withTranslation()(FieldValueSideSelectorComponent);

FieldValueSideSelector.propTypes = {
  fieldValues: PropTypes.instanceOf(FieldValues),
  selectedValue: PropTypes.oneOfType(
    [PropTypes.instanceOf(FieldValue), PropTypes.arrayOf(PropTypes.instanceOf(FieldValue))]),
  expandedItems: PropTypes.arrayOf(PropTypes.instanceOf(FieldValue)),
  canSelectLeavesOnly: PropTypes.bool,
  onSelectionChanged: PropTypes.func,
  allowEmptySelection: PropTypes.bool,
  allowMultiSelection: PropTypes.bool,
  allowNoneFieldValue: PropTypes.bool,
  openToggle: PropTypes.func,
  isOpen: PropTypes.bool
};

FieldValueSideSelector.defaultProps = {
  selectedValue: null,
  expandedItems: null,
  canSelectLeavesOnly: true,
  allowEmptySelection: true,
  allowMultiSelection: false,
  allowNoneFieldValue: false,
  isOpen: true
};