import _ from 'lodash';
import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {SatTypeEnum} from '../../../../application/model/SatTypeEnum';
import {services} from '../../../../application/service/services';
import {VisualizationInstance} from '../../../model/viz/VisualizationInstance';
import {EvolutionViz} from '../common/EvolutionViz';
import {ClickableViz} from "../ClickableViz";
import {FieldValue} from "../../../../application/model/field/FieldValue";
import {EvolutionVizFeedbacksQueryContext} from "../../../model/viz/VizFeedbacksQueryContext";
import {Chart} from "chart.js";
import {connect} from "react-redux";
import {firstItem} from "../../../../utils/collectionUtils";
import {FormDialog} from "../../../../component/dialog/FormDialog";
import {FormGroup, NumericInput} from "@blueprintjs/core";
import {getDashboardResolvedParams} from "../../../state/selectors/dashboardSelector";
import {ResolvedParams} from "../../../model/params/resolve/ResolvedParams";

interface SatEvolutionVizProps extends WithTranslation {
    viz: VisualizationInstance,
    resolvedParams: ResolvedParams,
    openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => void,

    updateVizData: (vizId, data) => void,
}

interface StateProps {
    isDialogOpen: boolean;
    clickItem: any,
    itemToUpdate?: { item: any, newValue: number },
    clickTime:any
}

class SatEvolutionVizComponent extends React.PureComponent<SatEvolutionVizProps> implements ClickableViz {

    state: StateProps = {
        clickItem: null,
        isDialogOpen: false,
        clickTime:null
    }

    getSerieLabel = (serieParam) => {
        const {t, viz} = this.props;

        if (!_.isUndefined(serieParam.constraint)) {
            const constraint = services.getDashboardService().getOriginalVizSpec(viz).params.series.find(
                s => s.name === serieParam.name).constraint;
            const serieTitle = services.getDashboardParamsService().findParamLabelFromConstraint(t, viz, true, constraint);

            return serieTitle || serieParam.name;
        } else {
            const satType = SatTypeEnum.valueOf(viz.definition.params.satType);
            return t('dashboard.viz.satEvolution.serie.global', {satType: satType.getAliasedName()});
        }
    };

    render() {
        let {viz, t} = this.props;

        const satType = SatTypeEnum.valueOf(viz.definition.params.satType);

        return <>
            <EvolutionViz viz={viz} onClick={this.onclick.bind(this)} getSerieLabel={this.getSerieLabel}
                          yAxis={{min: satType.min, max: satType.max}}/>
            <FormDialog isOpen={this.state.isDialogOpen}
                        title={this.props.viz.getTitle(t, this.props.resolvedParams)}
                        onCancel={props => this.setState({isDialogOpen: false})}
                        onValidate={props => {
                            this.updateData(this.state.itemToUpdate.item, this.state.itemToUpdate.newValue);
                            this.setState({isDialogOpen: false})
                        }}>
                <FormGroup
                    label={t('dashboard.viz.satEvolution.edit-dialog.score')}
                    labelFor="score-input">
                    <NumericInput id="score-input" value={this.state.itemToUpdate?.newValue} className={"numericInput"}
                                  max={100}
                                  onValueChange={newValue => this.setState({
                                      itemToUpdate: {
                                          ...this.state.itemToUpdate,
                                          newValue
                                      }
                                  })}></NumericInput>
                </FormGroup>
            </FormDialog>
        </>;
    }

    getClickedFieldValue(): FieldValue {
        return undefined;
    }

    getFeedBacksQueryContext(): EvolutionVizFeedbacksQueryContext {

        const result: EvolutionVizFeedbacksQueryContext = new EvolutionVizFeedbacksQueryContext();
        if (this.state.clickItem) {
            const {viz} = this.props;
            const series = viz.definition.params.series.filter(serie => serie.name === this.state.clickItem.serie);
            if (series === 0) throw new Error("Serie'" + this.state.clickItem.serie + "' not found.")
            result.field = series[0].field;
            result.serieName = this.state.clickItem.serie;
            result.selectedValue = this.state.clickItem.group;
            result.startPeriod = this.state.clickItem.date;
            result.endPeriod = this.state.clickItem.toDate;
        }
        return result;
    }

    onclick(event, items: any[], chart: Chart) {

        if (items.length > 0) {
            const item = items[0].element.$context.raw as any;
            if (event.type === 'click') {
                this.state.clickItem = items[0].element.$context.raw as any;
                this.props.openFeedbacksPanel(this, this.state.clickItem.label);
            } else if (event.type === "dblClick") {
                this.setState({isDialogOpen: true, itemToUpdate: {item, newValue: item.y}});
            }
        }
    }

    private updateData(item, newValue) {
        const data = {...this.props.viz.data};
        data.values = [...data.values];
        var itemToUpdate = firstItem(data.values.filter(value => value.date === item.date));
        const itemToUpdateIdx = data.values.indexOf(itemToUpdate)
        data.values[itemToUpdateIdx] = {...itemToUpdate};
        itemToUpdate = data.values[itemToUpdateIdx];
        itemToUpdate.values = {...itemToUpdate.values};
        itemToUpdate.values[item.serie] = {...itemToUpdate.values[item.serie]};
        (itemToUpdate.values[item.serie].value as any) = newValue;
        this.props.updateVizData(this.props.viz.id, data);
    }
}

const mapStateToProps = (state) => {
    return {
        resolvedParams: getDashboardResolvedParams(state),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => {
            dispatch.dashboard.openFeedBackPanel({viz, panelTitle});
        },
        updateVizData: (vizId, data) => {
            dispatch.dashboardData.setVizData({vizId: vizId, data: data});
        }
    };
};

export const SatEvolutionViz = withTranslation(undefined, {withRef: true})(connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(SatEvolutionVizComponent));