import _ from 'lodash';
import { services } from '../../../application/service/services';
import { AllItemsMatch } from '../../../utils/matcher/AllItemsMatch';
import { InstanceOf } from '../../../utils/matcher/InstanceOf';
import { Param } from './Param';
import { parseParamValue } from './parse/parseParamValue';
import { FieldParamValue } from './values/FieldParamValue';
import { FieldValueParamValue } from './values/FieldValueParamValue';
import { ListParamValue } from './values/ListParamValue';
import { NoneParamValue } from './values/NoneParamValue';
import { ParamValue } from "./values/ParamValue";
import { FieldValues } from "../../../application/model/field/FieldValues";
import { FieldValue } from "../../../application/model/field/FieldValue";
import { arrayRetainAll, firstItem, toArray } from "../../../utils/collectionUtils";

export class FieldAndValueSelectorParam extends Param<FieldValueParamValue> {
  static parse(reportId: string, object: any): FieldAndValueSelectorParam {
    if (_.isEmpty(object.name)) {
      throw new Error('name is mandatory');
    }
    if (_.isNil(object.fields)) {
      throw new Error('fields is mandatory');
    }

    return new FieldAndValueSelectorParam(reportId, object.name, parseParamValue(object.fields), object.allowMultiSelection);
  }

  private readonly fields: ParamValue;
  readonly allowMultiSelection: boolean;

  constructor(reportId: string, name, fields, allowMultiSelection: boolean = false) {
    super(reportId,'fieldAndValueSelector', name);
    this.fields = fields;
    this.allowMultiSelection = allowMultiSelection;
  }

  resolvePossibleFields(state: Dict<OneOrMany<ParamValue>>): NoneParamValue | ListParamValue<FieldParamValue> {
    const resolvedValues = this.fields.resolve(state);

    if (resolvedValues instanceof NoneParamValue) {
      return resolvedValues;
    }

    if (!(resolvedValues instanceof ListParamValue)) {
      throw new Error(`Invalid dashboard param ${this.name} : FieldAndValueSelectorParam should resolve into a ListParamValue`);
    }

    if (!new AllItemsMatch(new InstanceOf(FieldParamValue)).match(resolvedValues.items as Array<FieldParamValue>)) {
      throw new Error(`Invalid dashboard param ${this.name} : FieldAndValueSelectorParam can handle only FieldParamValue`);
    }

    return resolvedValues;
  }

  resolveValue(state: Dict<OneOrMany<ParamValue>>, init: boolean): Maybe<OneOrMany<FieldValueParamValue>> {
    const currentValue: FieldValueParamValue = this.getValue(state) as FieldValueParamValue;
    const possibleFields = this.resolvePossibleFields(state);

    if (possibleFields instanceof NoneParamValue) {
      return null;
    }

    if (_.isEmpty(possibleFields.items)) {
      return null;
    }

    let field: FieldParamValue;
    if (!_.isNil(currentValue)) {
      field = possibleFields.findLike(currentValue.field) as FieldParamValue;
    }
    if (_.isNil(field)) {
      field = possibleFields.items[0];
    }

    const fieldValues: FieldValues = services.getFieldsService().getFieldValues(field.field.name);
    if (_.isNil(fieldValues)) {
      return null;
    }
    const possibleFieldValues = fieldValues.flattenValues();

    let value: OneOrMany<FieldValue>;

    const retainedValues: Array<FieldValue> = arrayRetainAll(possibleFieldValues, toArray(currentValue?.value));
    if (!this.allowMultiSelection) {
      value = firstItem(retainedValues);
    } else {
      value = retainedValues;
    }

    if (init && _.isEmpty(value)) {
      value = possibleFieldValues[0];
    }

    return new FieldValueParamValue(field, this.forgeValue(value));
  }

  valueAsPlainObject(value: OneOrMany<FieldValueParamValue>): any {
    if (_.isArray(value)) {
      throw new Error('Multiple values is not supported');
    }

    const fieldValueParamValue = value as FieldValueParamValue;

    const plainObject = fieldValueParamValue.getValueAsPlainObject();

    if (this.allowMultiSelection) {
      return {
        field: plainObject.field,
        value: toArray(plainObject.value)
      }
    }

    if (_.isArray(plainObject.value)) {
      throw new Error("Multiple selection is not allowed");
    }

    return plainObject;
  }

  private forgeValue<T>(value: OneOrMany<T>): OneOrMany<T> {
    let result: OneOrMany<T> = toArray(value);
    if (!this.allowMultiSelection) {
      if (result.length > 1) {
        throw new Error(`multi selection is not supported for param ${this.name}`);
      }

      result = firstItem(result);
    }

    return result;
  }
}