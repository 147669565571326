import { Colors } from '@blueprintjs/core';
import { Tonality } from '@eptica/vecko-js-commons';
import { mdiEmoticonHappyOutline, mdiEmoticonNeutralOutline, mdiEmoticonSadOutline } from '@mdi/js';
import { Enum, EnumValue } from "../../utils/enum";
import { TFunction } from "i18next";

export class GlobalSatisfaction extends EnumValue {
  public readonly icon: string;
  public readonly color: string;

  constructor(icon: string, color:string) {
    super();
    this.icon = icon;
    this.color = color;
  }

  getLabel(t:TFunction):string {
    return t(`globalSatisfaction.${this.name}`);
  }

  static fromTonality(tonality:Tonality):GlobalSatisfaction {
    if (tonality === Tonality.POSITIVE) {
      return GlobalSatisfactionEnum.POSITIVE;
    }
    if (tonality === Tonality.NEUTRAL) {
      return GlobalSatisfactionEnum.NEUTRAL;
    }
    if (tonality === Tonality.NEGATIVE) {
      return GlobalSatisfactionEnum.NEGATIVE;
    }
    return null;
  }
}

class GlobalSatisfactionEnumType extends Enum<GlobalSatisfaction> {
  POSITIVE = new GlobalSatisfaction(mdiEmoticonHappyOutline, Tonality.POSITIVE.color);
  NEUTRAL = new GlobalSatisfaction(mdiEmoticonNeutralOutline, Tonality.NEUTRAL.color);
  NEGATIVE = new GlobalSatisfaction(mdiEmoticonSadOutline, Tonality.NEGATIVE.color);
  MIXED = new GlobalSatisfaction(mdiEmoticonNeutralOutline, Colors.ORANGE3);

  constructor() {
    super();
    this.initEnum('GlobalSatisfaction');
  }
}

export const GlobalSatisfactionEnum = new GlobalSatisfactionEnumType();
