import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { InfiniteCache } from '../../../utils/InfiniteCache';
import { DateRangeParam } from '../../model/params/DateRangeParam';
import { FieldAndValueSelectorParam } from '../../model/params/FieldAndValueSelectorParam';
import { Param } from '../../model/params/Param';
import { SelectorParam } from '../../model/params/SelectorParam';
import { NoneParamValue } from '../../model/params/values/NoneParamValue';
import _ from "lodash";
import { ParamValue } from "../../model/params/values/ParamValue";
import { DateRangeParamValue } from "../../model/params/values/DateRangeParamValue";
import { FieldValueParamValue } from "../../model/params/values/FieldValueParamValue";
import { toArray } from "../../../utils/collectionUtils";
import './dashboardExportParamsView.scss'
import { FieldValue } from "../../../application/model/field/FieldValue";

interface DashboardParamsProps extends WithTranslation {
  params: Array<Param>,
  values: Dict<OneOrMany<ParamValue>>,
  hasData: boolean,
  isValid: boolean,
  exportDisabled: boolean,

  init: () => void,
  setParamValue: (param: Param, value: ParamValue) => void
}

class DashboardExportParamsComponent extends React.Component<DashboardParamsProps> {
  private paramValueChangedHandlers = new InfiniteCache(param => value => this.setParamValue(param, value));

  getParams(): Array<Param> {
    return this.props.params;
  }

  setParamValue(param: Param, value: ParamValue) {
    this.props.setParamValue(param, value);
  }

  getParamValue(param: Param): OneOrMany<ParamValue> {
    return this.props.values[param.name];
  }

  renderParam(param: Param): React.ReactNode {
    const { t } = this.props;
    if (!param.displayInPDF) return null;

    if (param instanceof SelectorParam) {
      const selectorParam = param as SelectorParam<any>;
      const values = toArray(this.getParamValue(param));
      return values ?
        <>
          <span className="label">{selectorParam.getTitle(t)}</span>
          <span>{this.getStringFromValues(values)}</span>
        </> : null;
    }

    if (param instanceof FieldAndValueSelectorParam) {

      const fieldAndValueSelectorParam = param as FieldAndValueSelectorParam
      const fields = param.resolvePossibleFields(this.props.values);

      if (fields instanceof NoneParamValue) {
        return null;
      }
      const fieldValue = this.getParamValue(param) as FieldValueParamValue
      const values = toArray(fieldValue.value);
      return values ? <>
        <span className="label">{fieldValue.field.getLabel(t)}</span>
        <span>{this.getStringFromValues(values)}</span>
      </> : null;
    }

    if (param instanceof DateRangeParam) {
      const paramValue: DateRangeParamValue = this.getParamValue(param) as DateRangeParamValue;
      return paramValue ? <>
        <span className="label">{param.getTitle(t)}</span>
        <span>{paramValue.getLabel(t)}</span>
      </> : null;
    }

    throw new Error('Unsupported param');
  }

  render() {
    return <div className="dashboardExportParams">
      <div  className="dashboardExportParams__inner">
        {
          this.getParams().map(param => this.renderParam(param))
        }</div>
    </div>;
  }

  private getStringFromValues(values: Array<FieldValue | ParamValue>): string {
    const { t } = this.props;
    let result = "";
    let first = true;
    for (var i = 0; i < values.length; i++) {
      if (i > 2) {
        result += ', +' + (values.length - 3);
        break;
      } else {
        if (!first) {
          result += ', ';
        }
        first = false;
        result += values[i].getLabel(t);
      }
    }
    return result;
  }
}

const mapStateToProps = (state) => {
  return {
    hasData: state.dashboardData.hasData,
    values: state.dashboardParams.values,
    isValid: state.dashboardParams.isValid,
    exportDisabled: _.isNil(state.dashboardData.payload)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    init: () => {
      dispatch.dashboard.init(props.dashboardDefinition.id);
    },
    setParamValue: (param, value) => {
      dispatch.dashboardParams.changeParamValue({ param, value });
    }
  };
};

export const DashboardExportParamsView = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DashboardExportParamsComponent));
