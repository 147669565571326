import _ from 'lodash';
import { lastItem } from './collectionUtils';
import { falseSupplier, noop, trueSupplier } from './functionUtils';

/**
 * Symbol for stopping object traverse
 * @type {symbol}
 */
export const STOP = Symbol('stop object traverse');
type StopType = typeof STOP;

/**
 * Symbol for dropping property from its container (container can be either an array or an object)
 * @type {symbol}
 */
export const DROP = Symbol('drop property');
type DropType = typeof DROP;

export type TraverseCallbackResult = any | StopType | DropType;

/**
 * Callback executed at each traverse object iteration.
 *
 * return {@link STOP} for short circuiting.
 * return {@link DROP} for deleting value from its container.
 */
export type TraverseCallback = (value: OneOrMany<any>, context: TraverseContext) => TraverseCallbackResult;

interface TraverseContextIdentifier {
  isRoot: BooleanSupplier;
  isArray: BooleanSupplier;
  isObject: BooleanSupplier;
  container?: OneOrMany<any>;
  key?: number | string;
}

export interface TraverseContext extends TraverseContextIdentifier{
  parent?: TraverseContext;
  path: Array<TraverseContext>;
  pathString: string
}


interface TraverseConf {
  transform: boolean;
  leavesOnly: boolean;
}

const ROOT_CONTEXT: TraverseContext = {
  isRoot: trueSupplier,
  isArray: falseSupplier,
  isObject: falseSupplier,
  container: undefined,
  key: undefined,
  parent: undefined,
  path: [],
  pathString: '<ROOT>'
};

type Transformer = (context?:TraverseContext) => (v?: any) => void;
const objectDropTransform:Transformer = (context: TraverseContext) => () => delete context.container[context.key];
const objectTransform:Transformer = (context: TraverseContext) => v => context.container[context.key] = v;
const arrayDropTransform:Transformer = (context: TraverseContext) => () => context.container.splice(context.key, 1);
const arrayTransform:Transformer = (context: TraverseContext) => v => context.container[context.key] = v;
const noopTransformer:Transformer = () => noop

/**
 * Recursively traverse the given object, and execute the given function at each iteration.
 *
 * @param {Object} object object to traverse
 * @param {TraverseCallback} fn function executed at each iteration.
 * @param {boolean} shouldTransform whether we should transform the given object with the result of the given fn
 * @return {Object} the given object which may have been mutated
 */
export const traverseObject = <T>(object: T, fn: TraverseCallback, shouldTransform = false): T => {
  innerTraverseObject(object, fn, { transform: shouldTransform, leavesOnly: true });
  return object;
};

/**
 *
 * @param {any} object
 * @param {TraverseCallback} fn
 * @param {Object} conf
 * @param {Array<TraverseContext>} contextPath
 * @return {boolean} whether to stop
 */
const innerTraverseObject = (object: OneOrMany<any>,
                             fn: TraverseCallback,
                             conf: TraverseConf = { transform: false, leavesOnly: true },
                             contextPath: Array<TraverseContext> = [ROOT_CONTEXT]) => {
  const process = () => {
    const context = lastItem(contextPath);

    let result: TraverseCallbackResult;
    try {
      result = fn(object, context);
    } catch (e) {
      throw new Error(`Error when traversing object ${context.pathString} : ${e.message}`);
    }

    if (result === STOP) {
      // stop now !
      return true;
    } else if (conf.transform) {
      getTransformer(context, result)(context)(result);
    }
  };

  if (_.isPlainObject(object)) {
    if (conf.leavesOnly === false) {
      if (process()) {
        return true;
      }
    }

    for (const [key, value] of Object.entries(object)) {
      const shouldStop = innerTraverseObject(value, fn, conf,
        pushToContextPath(contextPath, createObjectContext(object, key)));
      if (shouldStop) {
        return true;
      }
    }
  } else if (_.isArray(object)) {
    if (conf.leavesOnly === false) {
      if (process()) {
        return true;
      }
    }

    for (let i = 0; i < (object as Array<any>).length; i++) {
      const shouldStop = innerTraverseObject(object[i], fn, conf,
        pushToContextPath(contextPath, createArrayContext(object, i)));
      if (shouldStop) {
        return true;
      }
    }
  } else {
    if (process()) {
      return true;
    }
  }

  return false;
};

const pathToString = (contextPath: Array<TraverseContext>): string => {
  return contextPath.map((p, i) => pathItemToString(p, i === 0)).join('');
};

const pathItemToString = (context: TraverseContext, isFirst: boolean): string => {
  if (context.isRoot()) {
    return ROOT_CONTEXT.pathString;
  }
  if (context.isArray()) {
    return `[${context.key}]`;
  }
  if (isFirst) {
    return `${context.key}`;
  }
  return `.${context.key}`;
};

const pushToContextPath = (contextPath: Array<TraverseContext>, context: TraverseContextIdentifier): Array<TraverseContext> => {
  const result = [...contextPath];
  const newContext:TraverseContext = {
    ... context,
    path: result,
    pathString: '',
    parent: lastItem(contextPath),
  }
  result.push(newContext);
  newContext.pathString = pathToString(result);
  return result;
};

const createObjectContext = (object: any, key: string): TraverseContextIdentifier => {
  return {
    isRoot: falseSupplier,
    isArray: falseSupplier,
    isObject: trueSupplier,
    container: object,
    key: key
  };
};

const createArrayContext = (array: Array<any>, index: number): TraverseContextIdentifier => {
  return {
    isRoot: falseSupplier,
    isArray: trueSupplier,
    isObject: falseSupplier,
    container: array,
    key: index
  };
};

const getTransformer = (context: TraverseContext, value: TraverseCallbackResult): Transformer => {
  if (context.isArray()) {
    return value === DROP ? arrayDropTransform : arrayTransform;
  }
  if (context.isObject()) {
    return value === DROP ? objectDropTransform : objectTransform;
  }

  return noopTransformer;
};

// ------------------
/**
 * Mutates the given object :
 * - removes all properties that have undefined value
 * - removes undefined values from arrays
 * @param {Object} object
 * @return {Object}
 */
export const dropUndefinedProperties = <T>(object: T): T => {
  innerTraverseObject(object, v => (v === undefined ? DROP : v), { transform: true, leavesOnly: true });
  return object;
};

/**
 * Checks whether the two given objects are deeply equals. Ignoring properties with undefined value.
 * @param {Object} o1
 * @param {Object} o2
 * @return {boolean}
 */
export const isDeepEqualIgnoreUndefined = (o1: any, o2: any) => {
  return _.isEqual(dropUndefinedProperties(_.cloneDeep(o1)), dropUndefinedProperties(_.cloneDeep(o2)));
};


export type FindCallback = (value: any, key: Maybe<number | string>, context:TraverseContext) => boolean;

/**
 * Find first object where the given callback returns true.
 */
export const findDeep = <T>(object: T, fn: FindCallback): Maybe<T> => {
  let result = undefined;
  innerTraverseObject(object, (value, context) => {
    if (fn(value, context.key, context) === true) {
      result = value;
      return STOP;
    }
  }, {
    transform: false,
    leavesOnly: false
  });
  return result;
}


function mergeCopyArrays(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return srcValue;
  }
}
export const mergeDeepExceptArrays = (object1: any, object2: any, object3: any = undefined, object4: any = undefined): any => {
  return _.mergeWith({}, object1, object2, object3, object4, mergeCopyArrays);
}
