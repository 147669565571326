import { Button } from '@blueprintjs/core';
import {DateRange as BPDateRange, DateRangeInput, DateRangeShortcut} from '@blueprintjs/datetime';
import { Tooltip } from '@blueprintjs/core';
import { mdiCalendarToday } from '@mdi/js';
import { DateTime, Settings } from 'luxon';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { DateRange } from '../../application/model/DateRange';
import {
  lastMonth,
  lastQuarter,
  lastWeek,
  lastYear,
  thisMonth,
  thisQuarter,
  thisWeek,
  thisYear
} from '../../utils/dateUtils';
import { SmartIcon } from '../icon/SmartIcon';
import {display} from "html2canvas/dist/types/css/property-descriptors/display";


interface DateRangeSelectorProps extends WithTranslation {
  dateRange: DateRange,
  enableShifters: boolean,
  onSelectionChanged: (dateRange: DateRange) => void,
  format: string
};


class DateRangeSelectorComponent extends React.PureComponent<DateRangeSelectorProps> {
  static defaultProps: Partial<DateRangeSelectorProps> = {
    enableShifters: true,
    format: 'dd/MM/yyyy'
  };
  shortcuts: boolean | DateRangeShortcut[];

  constructor(props, context) {
    super(props, context);

    const { t } = props;
    this.shortcuts = [
      { label: t('dateRange.thisWeek'), dateRange: thisWeek().toDateArray() as BPDateRange },
      { label: t('dateRange.lastWeek'), dateRange: lastWeek().toDateArray() as BPDateRange },
      { label: t('dateRange.thisMonth'), dateRange: thisMonth().toDateArray() as BPDateRange },
      { label: t('dateRange.lastMonth'), dateRange: lastMonth().toDateArray() as BPDateRange },
      { label: t('dateRange.thisQuarter'), dateRange: thisQuarter().toDateArray() as BPDateRange },
      { label: t('dateRange.lastQuarter'), dateRange: lastQuarter().toDateArray() as BPDateRange },
      { label: t('dateRange.thisYear'), dateRange: thisYear().toDateArray() as BPDateRange },
      { label: t('dateRange.lastYear'), dateRange: lastYear().toDateArray() as BPDateRange }
    ];
  }

  changeDateRange = (selectedRange: BPDateRange): void => {
    this.fireSelectionChanged(DateRange.fromDate({
      start: selectedRange[0],
      end: selectedRange[1]
    }));
  };

  setDateRangeEndToNow = () => {
    this.fireSelectionChanged(this.props.dateRange.endToNow());
  };

  fireSelectionChanged = (dateRange: DateRange) => {
    if (this.props.onSelectionChanged) {
      this.props.onSelectionChanged(dateRange);
    }
  };

  renderSetToNow() {
    const { t } = this.props;

    return <Tooltip content={t('dateRange.today')}>
      <Button onClick={this.setDateRangeEndToNow} icon={<SmartIcon icon={mdiCalendarToday}/>} minimal={true}/>
    </Tooltip>;
  };

  formatDate = (date: Date, locale?: string): string => {
    const { format } = this.props;
    return DateTime.fromJSDate(date).toFormat(format);
  };

  parseDate = (str: string, locale?: string): Date | false | null => {
    const { format } = this.props;
    return DateTime.fromFormat(str, format).toJSDate();
  };

  renderDateRange() {
    const { dateRange } = this.props;

    return <DateRangeInput
      allowSingleDayRange={true}
      formatDate={this.formatDate}
      parseDate={this.parseDate}
      endInputProps={{
        style: { width: 120 },
        rightElement: this.renderSetToNow()
      }}
      startInputProps={{
        style: { width: 98 }
      }}
      locale={Settings.defaultLocale}
      shortcuts={this.shortcuts}
      value={dateRange?.toDateArray() as BPDateRange}
      onChange={this.changeDateRange}
      maxDate={new Date()}
    />;
  }

  onShiftLeft = () => {
    const { dateRange } = this.props;

    this.fireSelectionChanged(dateRange.shift(-1));
  };

  onShiftRight = () => {
    const { dateRange } = this.props;

    this.fireSelectionChanged(dateRange.shift(1));
  };

  canShiftRight() {
    const { dateRange } = this.props;
    if( dateRange?.start.isValid && dateRange?.end.isValid) {

      const shiftedRange = dateRange.shift(1);
      return shiftedRange.end <= DateTime.now().endOf('day');
    }
    return false;
  }

  render() {
    const { enableShifters } = this.props;

    if (enableShifters) {
      return <div style={{display:'flex'}}>
        <Button minimal={true} onClick={this.onShiftLeft} icon='chevron-left'/>
        {this.renderDateRange()}
        <Button minimal={true} onClick={this.onShiftRight} disabled={!this.canShiftRight()} icon='chevron-right'/>
      </div>;
    } else {
      return this.renderDateRange();
    }
  }
}

export const DateRangeSelector = withTranslation()(DateRangeSelectorComponent);
