import { services } from '../../application/service/services';
import { parseStorageItem } from '../storage/model/parseStorageItem';
import {StorageKind, StorageKindEnum} from '../storage/model/StorageKind';
import { StoragePath } from '../storage/model/StoragePath';
import { StorageDirectory } from "../storage/model/StorageDirectory";
import { StorageItem } from "../storage/model/StorageItem";
import { CsvOption, ProgressHandler } from "../model";

export class StorageService {
  _storageFetcher;

  init() {
    this._storageFetcher = services.getFetcherService().getFetcher('storage');
  }

  isAuthorizedFor(storageKind: StorageKind): boolean {
    if (!this.isAuthorizedForList(storageKind)) {
      return false;
    }

    return this.isAuthorizedForList(storageKind) ||
        this.isAuthorizedForUploadStorageFile(storageKind) ||
        this.isAuthorizedForDeleteStoragePaths(storageKind) ||
        this.isAuthorizedForDownloadStoragePaths(storageKind) ||
        this.isAuthorizedForSourceStoragePath(storageKind);
  }

  isAuthorizedForList(storageKind: StorageKind): boolean {
    return services.getSecurityService().hasRole('vecko.storage.get');
  }

  isAuthorizedForDownloadStoragePaths(storageKind: StorageKind): boolean {
    return services.getSecurityService().hasRole('vecko.storage.download');
  }

  isAuthorizedForDeleteStoragePaths(storageKind: StorageKind): boolean {
    return services.getSecurityService().hasRole('vecko.storage.delete');
  }

  isAuthorizedForSourceStoragePath(storageKind: StorageKind): boolean {
    return services.getSecurityService().hasRole('vecko.storage.get') && services.getSecurityService().hasRole('vecko.sourcing.push_file');
  }

  isAuthorizedForUploadStorageFile(storageKind: StorageKind): boolean {
    if (storageKind === StorageKindEnum.FEEDBACK_MIRRORED) {
      return false;
    }
    return services.getSecurityService().hasRole('vecko.storage.upload');
  }

  async list(storageKind: StorageKind, directory: StorageDirectory): Promise<StorageItem> {
    return (await this._storageFetcher.list(storageKind.name, directory.path))
        .map(parseStorageItem);
  }

  async downloadStoragePaths(storageKind: StorageKind, paths: Array<string>) {
    const storagePaths = paths.map(StoragePath.parse);

    try {
      if (storagePaths.length === 1 && storagePaths[0].isFile()) {
        await this._storageFetcher.downloadStoragePath(storageKind.name, paths[0]);
      } else {
        await this._storageFetcher.downloadStoragePaths(storageKind.name, paths,
            services.getI18nService().translate(storageKind.getLabel()));
      }
    } catch (e) {
    }
  }

  async deleteStoragePaths(storageKind: StorageKind, paths: Array<string>) {
    try {
      const result = await this._storageFetcher.deleteStoragePaths(storageKind.name, paths);

      if (result) {
        const failedPaths = result.filter(r => !r.success);
        if (failedPaths.length > 0) {
          services.getApplicationService().notifyMessage({
            level: 'ERROR',
            text: {
              key: 'administration.sourcing.storage.delete.failure',
              args: { count: failedPaths.length }
            }
          });
        }
      }
    } catch (e) {


    }
  }

  async sourceStoragePaths(storageKind: StorageKind, paths: Array<string>) {
    try {
      const sourcingResult = await this._storageFetcher.sourceStoragePaths(storageKind.name, paths);

      let message;

      if (sourcingResult.length === 0) {
        message = {
          key: 'administration.sourcing.storage.source.result.none',
        };
      } else if (sourcingResult.length === 1) {
        message = {
          key: 'administration.sourcing.storage.source.result.one',
          args: sourcingResult[0]
        };
      } else {
        const total = sourcingResult.map(o => o.count).reduce(
          (accumulator, currentValue) => accumulator + currentValue);
        message = {
          key: 'administration.sourcing.storage.source.result.many',
          args: { count: sourcingResult.length, total: total }
        };
      }

      services.getApplicationService().notifyMessage({
        level: 'SUCCESS',
        text: message
      });
    } catch (e) {
    }
  }

  async uploadStorageFile(storageKind: StorageKind, path: string, file, options: CsvOption, progressHandler: ProgressHandler) {
    try {
      await this._storageFetcher.uploadStorageFile(storageKind.name, StoragePath.parse(path).child(file.name).path, file, options, progressHandler);

      services.getApplicationService().notifyMessage({
        level: 'SUCCESS',
        text: {
          key: 'administration.sourcing.storage.uploadFile.success',
          args: {file: file.name}
        }
      });
    } catch (e) {
      progressHandler(1, 1)
    }
  }
}

services.registerService('storageService', new StorageService());