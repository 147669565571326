import _ from 'lodash';
import '../../view/visualizations/visualizations';
import { visualizationTypes } from './visualizationTypes';

export function getVizEditorStates() {
  return visualizationTypes.types
    .filter(vt => vt.hasEditor())
    .map(vt => vt.editor.state)
    .filter(state => !_.isUndefined(state));
}


