import React from 'react';
import { DateRangeSelector } from '../../component/selector/DateRangeSelector';
import { DateRange } from '../../application/model/DateRange';
import { AbstractFilterCtrl, TagValue } from './AbstractFilterCtrl';
import { stateConverterRegistry } from './stateConverters/stateConverterRegistry';
import { Field } from "../../application/model/field/Field";
import _ from "lodash";
import { StateConverter } from "./stateConverters/StateConverter";
import { TFunction } from "i18next";

export class DateRangeFilterCtrl extends AbstractFilterCtrl<DateRange> {
  private readonly _field:Field;
  private dateRange:DateRange;

  constructor(id:string, field:Field, dateRange:DateRange) {
    super(id);
    this._field = field;
    this.setValue(dateRange);
  }

  get field():Field {
    return this._field;
  }

  getValue():DateRange {
    return this.dateRange;
  }

  setValue(value:DateRange):void {
    if(_.isArray(value)){
      const array  = value as unknown as Array<DateRange>;
      if(array.length>1) throw new Error("cannot set multiple values to dateRange filter.")
      value = array[0];
    }
    if (_.isNil(value)) {
      this.dateRange = value;
      return;
    }

    if (!(value instanceof DateRange)) {
      throw new TypeError(`given dateRange ${value} should be an instance of DateRange`);
    }
    this.dateRange = value;
  }

  uiComponent(t:TFunction, onSelectionChanged):JSX.Element {
    return <DateRangeSelector dateRange={this.getValue()} key={this.id} onSelectionChanged={onSelectionChanged}/>;
  }

  stateConverter():StateConverter<DateRange> {
    return stateConverterRegistry.get(DateRange);
  }

  getTagValues(t: TFunction): Array<TagValue<DateRange>> {
    return undefined;
  }
}