import { Tonality } from '@eptica/vecko-js-commons';
import { services } from './services';
import { Sequence } from "../model";
import { BoolFilter } from "../../utils/query/filter/BoolFilter";
import { ApiFilter } from "../../utils/query/filter/ApiFilter";
import { SatType } from "../model/SatTypeEnum";

export interface CategoryConstraint {
  tree: string,
  category: string,
}

export interface SatConstraint {
  satType: SatType,
  tonality?: Tonality
}

export interface SequencesSummaryArgs {
  filter?: ApiFilter,
  category?: CategoryConstraint,
  sat?: SatConstraint,
  tonality?: Tonality,
  size: number,
}

export class SequencesService {
  private _sequencesFetcher;

  init() {
    this._sequencesFetcher = services.getFetcherService().getFetcher('sequences');
  }

  async getSequences(args: Partial<SequencesSummaryArgs>):Promise<Array<Sequence>> {
    return (await this._sequencesFetcher.summarize(this.argsToDto(this.getSummaryArgs(args))))
      .sequences?.map(dto => ({
        content: dto.content,
        tonality: dto.tonality ? Tonality.getValueOf(dto.tonality) : undefined,
        frequency: dto.frequency,
      }));
  }

  private getSummaryArgs(args: Partial<SequencesSummaryArgs>): SequencesSummaryArgs {
    return  {
      filter: args.filter ,
      sat: args.sat,
      category: args.category,
      tonality: args.tonality,
      size: args.size ?? 100
    }
  }

  private argsToDto(args:SequencesSummaryArgs) {
    return {
      filter: args.filter?.toPlainObject(),
      category: args.category ? this.categoryToDto(args.category) : undefined,
      sat: args.sat ? this.satToDto(args.sat) : undefined,
      tonality: args.tonality?.name(),
      size: args.size,
    }
  }

  private categoryToDto(categoryConstraint:CategoryConstraint) {
    return {
      tree: categoryConstraint.tree,
      category: categoryConstraint.category
    };
  }

  private satToDto(satConstraint:SatConstraint) {
    return {
      satType: satConstraint.satType?.name,
      tonality: satConstraint.tonality?.name()
    };
  }
}

services.registerService('sequencesService', new SequencesService());
