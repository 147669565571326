import { Tonality } from '@eptica/vecko-js-commons';
import { TFunction } from "i18next";
import _ from "lodash";

export class DistributionItem {
    tonality: Tonality;
    id: string;
    color: string;
    icon: string;
    getLabel: (t: TFunction) => string;

    constructor(tonality: Tonality, icon: string, getLabel: (t: TFunction) => string) {
        this.tonality = tonality;
        this.id = tonality.name();
        this.color = tonality.color;
        this.icon = icon;
        this.getLabel = getLabel;
    }

    static equalComparator = (o1: DistributionItem, o2: DistributionItem): boolean => (_.isNil(o1) || _.isNil(o2)) ? o1 === o2 : o1.id === o2.id;
}