import {services} from '../../application/service/services';
import {KafkaLog} from "../model";

export class KafkaService {
    _kafkaFetcher;

    init() {
        this._kafkaFetcher = services.getFetcherService().getFetcher('kafka');
    }

    isAuthorized(): boolean {
        return services.getTenantService().isAuthorizedForGetTenant() && services.getSecurityService().hasRole('vecko.sourcing.logs');
    }

    async getLogs(): Promise<{ [key: string]: Array<KafkaLog> }> {
        return this._kafkaFetcher.getLogs();
    }
}

services.registerService('kafkaService', new KafkaService());