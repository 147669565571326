import React, { ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { services } from '../../application/service/services';
import './dashboardTitleHeader.scss';

interface DashboardTitleHeaderProps {
  label: string,
  centerLabel?: string | ReactNode,
  rightLabel?: string,
  rightSubLabel?: string
};

class DashboardTitleHeaderComponent extends React.PureComponent<DashboardTitleHeaderProps> {
  render() {
    const { label, rightLabel, rightSubLabel, centerLabel } = this.props;
    const rightLabelClasses = "dashboard-title-header__right" + (centerLabel ? "" : " dashboard-title-header__right_grow")
    const titleClass = "dashboard-title-header__title" + (!centerLabel ? " dashboard-title-header__title_extend" : "")
    return <div className="dashboard-title-header">
      <div className={titleClass}>{label}</div>
      {
        centerLabel ?
          <div className="dashboard-title-header__center">{centerLabel}</div> : null
      }
      {
        rightLabel ? <div className={rightLabelClasses}>
          <span className="dashboard-title-header__date">{rightLabel}</span>
          {rightSubLabel ? <span className='dashboard-title-header__date__sub'><br/>{rightSubLabel}</span> : ''}
        </div> : ''
      }
    </div>;
  }
}


//----------

const mapStateToProps = (state, ownProps) => {
  const dateRange = services.getDashboardParamsService().getDateRange();

  return {
    rightLabel: dateRange ? dateRange.start.toFormat('dd MMMM yyyy') + ' - ' + dateRange.end.toFormat('dd MMMM yyyy') : null
  };
};

export default withTranslation()(connect(mapStateToProps)(DashboardTitleHeaderComponent));