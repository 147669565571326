import { TreeModel } from "./TreeModel";
import { TreeSelectionCtrl } from "./TreeSelectionCtrl";

export class SingleTreeSelectionCtrl<T> extends TreeSelectionCtrl<T> {

  _selection: T;
  _allowEmptySelection: boolean;

  constructor(treeModel: TreeModel<T>, selection: T, onSelectionChanged: (value) => void, allowEmptySelection: boolean) {
    super(treeModel, onSelectionChanged);
    this._selection = selection;
    this._allowEmptySelection = allowEmptySelection;
  }

  isSelected = (item: T): boolean => {
    return this._treeModel.nodesEqual(this.getSelection(), item);
  };

  toggleSelect = (item: T): void => {
    if (this.isSelected(item)) {
      if (this._allowEmptySelection) {
        this.unselectAll();
      }
    } else {
      this.select(item);
    }
  };

  select = (item: T): void => {
    this.setSelection(item);
  };

  unselectAll = (): void => {
    this.setSelection(null);
  };

  setSelection = (selection: T): void => {
    this._selection = selection;

    this.notify(selection);
  };

  getSelection = (): T => {
    return this._selection;
  };
}