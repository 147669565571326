import { FieldValue } from "../FieldValue";

export class LazyLoadedFieldValue {
  readonly fieldName: string;
  readonly value: string;
  readonly onLoaded: (loadedFieldValue: FieldValue) => void;

  /**
   * @param fieldName field name
   * @param value value
   * @param onLoaded the function to apply when field value has been loaded
   */
  constructor(fieldName: string, value: string, onLoaded: (loadedFieldValue: FieldValue) => void) {
    this.fieldName = fieldName;
    this.value = value;
    this.onLoaded = onLoaded;
  }

  load(loadedFieldValue: FieldValue) {
    // apply the onLoaded function with the loaded field value
    this.onLoaded(loadedFieldValue);
  }
}

