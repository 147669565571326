import axios from 'axios';

const getConfigurations = async (types: Array<Vecko.ConfigurationKind>): Promise<Dict<string>> => {
  return axios
    .get(`/api/configuration/default?types=${types.join(',')}`)
    .then(response => response.data)
    .then(configurations => Object.fromEntries(configurations.map(conf => ([conf.type, conf.value]))))
    ;
};

const saveConfigurations = async (configurations: Dict<string>) => {
  return axios
    .post(`/api/configuration/default`, configurations)
    .then(response => response.data)
    ;
}

const validateConfigurations = async (configurations: Dict<string>): Promise<Array<Vecko.ValidationHint>> => {
  return axios
    .post(`/api/configuration/_validate`, configurations)
    .then(response => response.data)
    ;
}

const bitbucketValidate = async (tenantId: string, branch: string, bitbucketToken: string): Promise<Vecko.ValidationHint> => {
  return axios
    .get(`/api/configuration/${tenantId}/${branch}/_validate?bitbucketToken=${bitbucketToken}`)
    .then(response => response.data);
}

const bitbucketPush = async (tenantId: string, branch: string, bitbucketToken: string): Promise<Vecko.ValidationHint> => {
  return axios
    .get(`/api/configuration/${tenantId}/${branch}/default/_push?bitbucketToken=${bitbucketToken}`)
    .then(response => response.data);
}

export default { getConfigurations, saveConfigurations, validateConfigurations, bitbucketValidate, bitbucketPush };
