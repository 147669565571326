import { Colors, MenuDivider, MenuItem } from '@blueprintjs/core';
import React, { PureComponent } from 'react';
import { FieldValue } from '../../application/model/field/FieldValue';
import { NoneFieldValue } from '../../application/model/field/NoneFieldValue';
import { SmartIcon } from '../icon/SmartIcon';
import { MultiSelectInput } from '../select/MultiSelectInput';
import { SelectInput } from '../select/SelectInput';
import _ from "lodash";
import { WithTranslation, withTranslation } from 'react-i18next';


interface FieldValueSelectorComponentProps<T> extends WithTranslation {
  canSelectLeavesOnly: boolean,
  selectedValue: any,
  allowEmptySelection: boolean,
  allowMultiSelection: boolean,
  allowNoneFieldValue?: boolean,
  selectInputProps: any,
  onSelectionChanged: OnChangeHandler<T>,
  fieldValues: any,
}

class FieldValueSelectorComponent<T> extends PureComponent<FieldValueSelectorComponentProps<T>> {

  static defaultProps = {
    selectedValue: null,
    canSelectLeavesOnly: true,
    allowEmptySelection: true,
    allowMultiSelection: false,
    allowNoneFieldValue: false,
    selectInputProps: {}
  };


  /**
   * @param {FieldValue} item
   * @return {string}
   */
  getId = (item) => {
    return item.getId();
  };

  /**
   * @param {FieldValue} item
   * @return {string}
   */
  getLabel = (item) => {
    return item.getLabel(this.props.t);
  };

  /**
   * @param {FieldValue} item
   * @return {SmartIcon|null}
   */
  getIcon = (item) => {
    if (!item) {
      return null
    }

    const icon = item.field.getIconForValue(item.name);

    if (!icon) {
      return null;
    }

    return <SmartIcon icon={icon}/>;
  };

  /**
   * @param {FieldValue} item
   * @param {string} id
   * @param {string} text
   * @param {string} highlightedText
   * @param icon
   * @param {boolean} selected
   * @param {Function} handleClick
   * @param modifiers
   * @param query
   * @return {*}
   */
  itemRenderer = (item, { id, text, highlightedText, icon, selected }, { handleClick, modifiers, query }) => {
    if (this.props.canSelectLeavesOnly && !item.isLeaf()) {
      return <MenuDivider title={text} key={id}/>;
    }

    const indentClass = `vui-indent-${item.level}`;

    if (this.props.allowMultiSelection) {
      return <MenuItem
        active={false}
        icon={selected ? 'tick' : 'blank'}
        disabled={modifiers.disabled}
        key={id}
        onClick={handleClick}
        text={highlightedText}
        textClassName={indentClass}
      />;
    } else {
      return <MenuItem
        active={selected}
        disabled={modifiers.disabled}
        key={id}
        onClick={handleClick}
        text={highlightedText}
        textClassName={indentClass}
        icon={icon && selected ? React.cloneElement(icon, { color: Colors.WHITE }) : icon}
      />;
    }
  };

  render() {
    const { t, fieldValues, allowNoneFieldValue, allowMultiSelection } = this.props;

    const getItems = () => {
      if (_.isNil(fieldValues)) {
        return [];
      }
      const result = fieldValues.flattenValues();
      if (!allowNoneFieldValue) {
        return result.filter(fv => !(fv instanceof NoneFieldValue))
      }
      return result;
    };

    const getPlaceHolder = () => {
      if (_.isNil(fieldValues)) {
        return undefined;
      }
      return fieldValues.field.getLabel(t);
    };

    if (allowMultiSelection) {
      return <MultiSelectInput
        {...this.props.selectInputProps}
        itemLabel={this.getLabel}
        itemId={this.getId}
        itemIcon={this.getIcon}
        itemEqual={FieldValue.equalityTester}
        items={getItems()}
        selectedItems={this.props.selectedValue}
        onSelectionChanged={this.props.onSelectionChanged}
        placeholder={getPlaceHolder()}
        allowEmptySelection={this.props.allowEmptySelection}
        itemRenderer={this.itemRenderer}
      />;
    } else {
      return <SelectInput
        {...this.props.selectInputProps}
        itemLabel={this.getLabel}
        itemId={this.getId}
        itemIcon={this.getIcon}
        items={getItems()}
        selectedItem={this.props.selectedValue}
        onSelectionChanged={this.props.onSelectionChanged}
        placeholder={getPlaceHolder()}
        allowEmptySelection={this.props.allowEmptySelection}
        itemRenderer={this.itemRenderer}
      />;
    }
  }
}

export const FieldValueSelector = withTranslation()(FieldValueSelectorComponent);

