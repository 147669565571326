import { Button, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import * as React from 'react';
import { InfiniteCache } from '../utils/InfiniteCache';
import { ListEditor } from './ListEditor';

const firstItemOrNull = (array) => {
  if (array.length > 0) {
    return array[0];
  }
  return null;
};


interface MapEditorProps<T, K, V> {
  keys: Array<K>,
  items: Array<T>,
  itemKeyGetter: (item: T) => K,
  itemValueGetter: (item: T) => V,
  keyId: (key: K) => string,
  keyLabel: (key: K) => string,
  keySelector: (keys: Array<K>, selectedKey: K, onSelectedKeyChanged: (value: any) => void) => any,
  itemEditor: (item: T, key: K, onChangedHandler: (value: V) => void) => any,
  createItem: (key: K, value: V) => T,
  createEmptyItem: (key: K) => T,
  onChanged: (items: Array<T>) => void,
  enableItemOrdering?: boolean,
  fill?: boolean
  disabled: boolean,
  className?: string,
  annihilateEnterKey?: boolean
}

interface MapEditorState<K> {
  selectedKey: K
}

export class MapEditor<T, K, V> extends React.PureComponent<MapEditorProps<T, K, V>> {
  static defaultProps: Partial<MapEditorProps<any, any, any>> = {
    enableItemOrdering: false,
    fill: true,
    disabled: false
  };

  state: MapEditorState<K> = {
    selectedKey: null
  };

  private hiddenButtonRef: HTMLAnchorElement;

  constructor(props) {
    super(props);
    this.state.selectedKey = this.getFirstKey();
  }

  getFirstKey(): K {
    return firstItemOrNull(this.getAddableKeys());
  }

  getKey(item: T): K {
    return this.props.itemKeyGetter(item);
  }

  onChangeHandlers = new InfiniteCache((key) => (editorOutput) => {
    const { items, createItem, onChanged } = this.props;
    onChanged(items.map(item => {
      if (this.getKey(item) !== key) {
        return item;
      } else {
        return createItem(key, editorOutput);
      }
    }));
  });

  onAdd = (event) => {
    if (event.isTrusted || !this.props.annihilateEnterKey) {
      const { items, createEmptyItem, onChanged } = this.props;
      const currentKey = this.state.selectedKey;
      this.setState({ selectedKey: firstItemOrNull(this.getAddableKeysExcept(currentKey)) }, () => {
        onChanged([...items, createEmptyItem(currentKey)]);
      });
      if (this.props.annihilateEnterKey) {
        //Workaround
        //in case of enter annihilation, give the focus to the hidden a to capture key press event
        setTimeout(args => {
          this.hiddenButtonRef.focus();
        }, 50);
      }
    }
  };

  findIndexByKey(key: K) {
    return this.props.items.findIndex(it => this.getKey(it) === key);
  }

  hasItemByKey(key: K) {
    return this.findIndexByKey(key) !== -1;
  }

  getAddableKeys(): Array<K> {
    return this.props.keys.filter(k => !this.hasItemByKey(k));
  }

  getAddableKeysExcept(excludeKey: K): Array<K> {
    return this.getAddableKeys().filter(k => k !== excludeKey);
  }

  getAddableKeysInclude(includeKey: K): Array<K> {
    return this.props.keys.filter(k => k === includeKey || !this.hasItemByKey(k));
  }

  onSelectedKeyChanged = (value: any) => {
    this.setState({ selectedKey: value });
  };

  canAdd() {
    return this.getAddableKeys().length > 0;
  }

  renderItem = (item: T) => {
    const key = this.getKey(item);

    return this.props.itemEditor(item, key, this.onChangeHandlers.get(key));
  }

  itemId = (item: T) => {
    const { keyId } = this.props;

    return keyId(this.getKey(item));
  }

  itemLabel = (item: T, index: number) => {
    return this.props.keyLabel(this.getKey(item));
  }

  beforeDelete = (item) => {
    this.setState({ selectedKey: firstItemOrNull(this.getAddableKeysInclude(this.getKey(item))) });
  }

  renderItems() {
    const { items, onChanged, enableItemOrdering, fill, disabled, annihilateEnterKey } = this.props;

    return <ListEditor items={items}
                       itemId={this.itemId}
                       itemLabel={this.itemLabel}
                       renderItem={this.renderItem}
                       onChanged={onChanged}
                       enableItemOrdering={enableItemOrdering}
                       beforeDelete={this.beforeDelete}
                       fill={fill}
                       disabled={disabled}
                       annihilateEnterKey={annihilateEnterKey}
    />
  }

  render() {
    const { className, disabled, annihilateEnterKey } = this.props;

    const clazz = classNames('vui-layout--vertical', 'mapEditor', className);

    return <div className={clazz}>
      <div className='vui-layout--vertical__top vui-layout--horizontal' style={{ marginBottom: 5 }}>
        {
          this.props.keySelector(this.getAddableKeys(), this.state.selectedKey, this.onSelectedKeyChanged)
        }
        <Button intent={Intent.PRIMARY}
                minimal={true}
                icon='add'
                disabled={disabled || !this.canAdd()}
                tabIndex={-1}
                onClick={this.onAdd}/>
        {
          //Workaround
          //in case of enter annihilation, create a to capture key press event
          annihilateEnterKey ?
            <a tabIndex={-1} ref={ref => this.hiddenButtonRef = ref} style={{ width: 0, height: 0 }}></a> : null
        }
      </div>
      {
        this.renderItems()
      }
    </div>;
  }
}

