import { Enum } from '@eptica/js-utils';
import { services } from '../../application/service/services';
import { traverseObject } from '../../utils/objectUtils';
import { DashboardDefinition } from '../model/dashboard/DashboardDefinition';
import { parseParam } from '../model/params/parse/parseParam';
import { VisualizationDefinition } from '../model/viz/VisualizationDefinition';
import { index } from "../../utils/collectionUtils";
import { ResolvedParams } from "../model/params/resolve/ResolvedParams";
import _ from "lodash";
import { VisualizationInstance } from "../model/viz/VisualizationInstance";
import { ApiFilter } from "../../utils/query/filter/ApiFilter";
import { variableResolverService } from "./variableResolverService";
import { parseCommonFilters } from "../model/params/parse/parseCommonFilters";
import { BoolFilter } from "../../utils/query/filter/BoolFilter";
import { SharedFilterNames } from "../../application/service/filterService";
import { SearchQuery } from "../../utils/query/SearchQuery";
import { VizFeedbacksQueryContext } from "../model/viz/VizFeedbacksQueryContext";
import { ReportDT0 } from "../model/dashboard/ReportDTO";
import { Field } from "../../application/model/field/Field";

const deepTransformEnums = <T>(object: T): T => {
  return traverseObject(_.cloneDeep(object), (value) => {
    if (value instanceof Enum) {
      return value.name();
    }
    return value;
  }, true);
};

export class DashboardService {
  private dashboardDataFetcher;
  private _dashboardDefinitions: Array<DashboardDefinition> = [];
  private dashboardDefinitionsMap: Dict<DashboardDefinition> = {};

  init() {
    this.dashboardDataFetcher = services.getFetcherService().getFetcher('dashboardData');

    this._dashboardDefinitions = (services.getConfigurationService().uiConfiguration.reports || [])
      .map(dd => new DashboardDefinition(
        dd.id,
        typeof dd.isDashboard == 'string' ? JSON.parse(dd.isDashboard) : dd.isDashboard,
        dd.commonFilters,
        dd.parameters.map(param => parseParam(param, dd.id)),
        dd.visualizations.map(v => new VisualizationDefinition(v)))
      );
    this.dashboardDefinitionsMap = index(this._dashboardDefinitions, dd => dd.id);
  }

  get dashboardDefinitions(): Array<DashboardDefinition> {
    return this._dashboardDefinitions;
  }

  getDashboardDefinition(id: string): Maybe<DashboardDefinition> {
    return this.dashboardDefinitionsMap[id];
  }

  getOriginalVizSpec(vizInstance: VisualizationInstance) {
    return this.getDashboardDefinition(vizInstance.dashboard.id)?.getVizDefinition(vizInstance.id);
  }

  resolveDashboardSpec(dashboardId: string, dashboardResolvedParams: ResolvedParams): object {
    const dashboardDefinition = this.getDashboardDefinition(dashboardId);

    return {
      visualizations: (dashboardDefinition?.visualizations || [])
        .map(vizDefinition => services.getDashboardParamsService().resolveVizSpec(vizDefinition, dashboardResolvedParams))
    };
  }

  resolveFirstLevelValues(field: Field, values: any) {
    const fieldName = field.name;
    if (field && field.isCategoryTree() && !services.getFieldsService().isFirstLevelField(fieldName)) {
      if (typeof values === 'string') {
        values = [values];
      }
      if (!Array.isArray(values)) {
        throw new Error(`List must be an array of values`);
      }
      const topics = values.filter(value => services.getFieldsService().findFieldValue(fieldName, value)?.isLeaf());
      const topicsFromFirstLevel = values.map(value => services.getFieldsService().findFieldValue(fieldName, value))
        .filter(fieldValue => !_.isNil(fieldValue) && !fieldValue.isLeaf())
        .flatMap(fieldValue => fieldValue.flattenValues(false))
        .map(fieldValue => fieldValue.fullName)

      return topics.concat(topicsFromFirstLevel);
    }
    return values;
  }

  getDashboardData(dashboardId: string,
                   dashboardResolvedSpec: object,
                   dashboardResolvedParams: ResolvedParams): Promise<{total: number, vizResponses:object}> {
    const dashboardParamsForApi = this.getDashboardParamsForApi(dashboardId, dashboardResolvedParams);
    const reportDto = new ReportDT0();
    reportDto.dashboardParams = dashboardParamsForApi;
    reportDto.dashboardSpec = deepTransformEnums(dashboardResolvedSpec);
    return this.dashboardDataFetcher.getData(reportDto);
  }

  private getDashboardParamsForApi(dashboardId: string, dashboardResolvedParams: ResolvedParams) {
    /* parse common filters & resolve values & store it into state */
    const dashboardDefinition = services.getDashboardService().getDashboardDefinition(dashboardId);
    const resolvedCommonFilters = _.cloneDeep(dashboardDefinition.commonFilters);
    variableResolverService.resolveObject(resolvedCommonFilters, dashboardResolvedParams.valueAsPlainObject());

    const dashboardCommonFilters = parseCommonFilters(resolvedCommonFilters);
    //DateRange filter ahas to be removed because it is part of dashboardParams
    const serviceFilters: ApiFilter = services.getFilterService().getFilterForApi([SharedFilterNames.DATE_RANGE]);

    /* Merge common filters and filters from filterService  */
    const commonFilters = BoolFilter.must(dashboardCommonFilters, serviceFilters);

    const dashboardParamsForApi = services.getDashboardParamsService().getDashboardParamsForApi(dashboardDefinition,
      dashboardResolvedParams, commonFilters);
    return dashboardParamsForApi;
  }

  getVizInstanceData(vizInstance: VisualizationInstance, dashboardResolvedParams: ResolvedParams, customVizParams: any): Promise<any> {
    const vizId = vizInstance.definition.spec.id;
    const singleVizDashboardSpec = this.getSingleVizDashboardSpec(vizInstance, customVizParams, dashboardResolvedParams);
    return this.getDashboardData(vizInstance.dashboardId, singleVizDashboardSpec, dashboardResolvedParams)
      .then(d => d.vizResponses[vizId]);
  }

  private getSingleVizDashboardSpec(vizInstance: VisualizationInstance, customVizParams: any, dashboardResolvedParams: ResolvedParams) {
    const vizSpec = _.cloneDeep(vizInstance.definition.spec);
    _.assign(vizSpec.params, vizInstance.definition.params, customVizParams);

    const singleVizDashboardSpec = {
      visualizations: [services.getDashboardParamsService().resolveVizSpec(new VisualizationDefinition(vizSpec),
        dashboardResolvedParams)]
    }
    return singleVizDashboardSpec;
  }

  async refreshVizInstanceData(vizInstance: VisualizationInstance, dashboardResolvedParams: ResolvedParams): Promise<void> {
    vizInstance.data = await this.getVizInstanceData(vizInstance, dashboardResolvedParams, undefined);
  }

  async getFeedBacksQuery(vizInstance: VisualizationInstance, dashboardResolvedParams: ResolvedParams, customVizParams: any, vizFeedbacksQueryParam: VizFeedbacksQueryContext): Promise<SearchQuery> {
    const singleVizDashboardSpec = this.getSingleVizDashboardSpec(vizInstance, customVizParams, dashboardResolvedParams);
    const dashboardParamsForApi = this.getDashboardParamsForApi(vizInstance.dashboardId, dashboardResolvedParams);
    vizFeedbacksQueryParam.report = new ReportDT0();
    vizFeedbacksQueryParam.report.dashboardParams = dashboardParamsForApi;
    vizFeedbacksQueryParam.report.dashboardSpec = deepTransformEnums(singleVizDashboardSpec);
    return (this.dashboardDataFetcher.getFeedBacksQuery(vizFeedbacksQueryParam) as Promise<SearchQuery>);
  }
}

services.registerService('dashboardService', new DashboardService());
