import * as React from 'react';
import {ReactElementLike} from 'prop-types';
import {Icon as PathIcon} from '@mdi/react';
import {Colors, Icon as NamedIcon, IconSize} from '@blueprintjs/core';
import _ from "lodash";

function isPath(str) {
    if (typeof str !== 'string') return false;
    str = str.trim();
    return /^[mzlhvcsqta]\s*[-+.0-9][^mlhvzcsqta]+/i.test(str) && /[\dz]$/i.test(str) && str.length > 4;
}

interface SmartIconProps {
    icon: string | ReactElementLike,
    size?: number,
    color?: string
}

export class SmartIcon extends React.PureComponent<SmartIconProps> {

    static defaultProps = {
        size: null,
        color: Colors.GRAY1
    };

    render() {
        if (_.isString(this.props.icon)) {
            let size: string | number = this.props.size || IconSize.STANDARD;

            if (isPath(this.props.icon)) {
                size = _.isString(size) ? size : `${size}px`;
                return <span className='bp5-icon'><PathIcon path={this.props.icon as string} size={size}
                                                            color={this.props.color}/></span>;
            }
            return <NamedIcon icon={this.props.icon as ReactElementLike} iconSize={size} color={this.props.color}/>;
        }

        if (_.isNil(this.props.size)) {
            return this.props.icon;
        } else {
            const SizedIcon = this.props.icon as any;

            return <SizedIcon size={this.props.size}/>;
        }
    }
}

