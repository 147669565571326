import _ from 'lodash';

const wait = () => new Promise((r, j) => setTimeout(r, _.random(100, 200)));
const noWait = () => Promise.resolve();

const getWaiter = (shouldSimulateWait) => {
  return shouldSimulateWait ? wait : noWait;
}

const log = (group, items) => {
  console.groupCollapsed(group, 'color: gray;');
  items.forEach(it => console.log(it));
  console.groupEnd();
};

const logError = (group, items) => {
  console.groupCollapsed(group, 'color: red;');
  items.forEach(it => console.log(it));
  console.groupEnd();
};

const parseRequestError = (error) => {
  return {
    url: error.config.url,
    method: error.config.method,
    data: error.config.data,
    headers: Object.fromEntries(Object.entries(error.config.headers).map(e => {
      if (e[0] === 'Authorization') {
        return [e[0], (e[1] as string).substring(0, 25) + '...'];
      } else {
        return e;
      }
    }))
  };
};

export const fetcherProxy = (name, fetcher, prefix, shouldSimulateWait, messageHandler) => {
  const waiter = getWaiter(shouldSimulateWait);

  return new Proxy(fetcher, {
    get: function (target, property, receiver) {

      const logMessage = (state) => {
        return `${prefix} ${state} %c${name}.${property?.toString()}`;
      };

      return function (...args) {
        log(logMessage('request'), [JSON.stringify(args), args]);

        return waiter().then(() => {
          return target[property].apply(target, args);
        }).then(result => {
          log(logMessage('response'), [JSON.stringify(result), result]);

          return result;
        }).catch(error => {
          if (!error) {
            logError(logMessage('error'), ['Unknown error']);

            messageHandler.notify({
              level: 'ERROR',
              text: {
                key: `error.http.${name}.${property?.toString()}`,
                args: { args: args },
              },
              error: {
                type: 'RUNTIME',
                error: new Error('Unknown error')
              }
            });
          } else if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            const err = {
              type: 'HTTP',
              message: error.message,
              request: parseRequestError(error),
              response: {
                status: error.response.status,
                data: error.response.data,
                headers: error.response.headers
              }
            };

            logError(logMessage('error'), [err]);

            let text;
            if (err.response.status === 401 || err.response.status === 403 || err.response.status === 407) {
              text = {
                key: 'error.http.authorization'
              };
            } else {
              text = {
                key: `error.http.${name}.${property?.toString()}`,
                args: { args: args },
              };
            }

            messageHandler.notify({
              level: 'ERROR',
              text: text,
              error: err
            });
          } else if (error.request) {
            // The request was made but no response was received (`error.request` is an instance of XMLHttpRequest)

            const err = {
              type: 'HTTP',
              message: 'The server did not respond to the HTTP request',
              request: parseRequestError(error),
            };

            logError(logMessage('error'), ['No response from server', err]);

            messageHandler.notify({
              level: 'ERROR',
              text: {
                key: `error.http.${name}.${property?.toString()}`,
                args: { args: args },
              },
              error: err
            });
          } else {
            // Something happened in setting up the request that raised an Error
            logError(logMessage('error'), ['Error while setting up HTTP request', error.message]);

            messageHandler.notify({
              level: 'ERROR',
              text: {
                key: `error.http.${name}.${property?.toString()}`,
                args: { args: args },
              },
              error: {
                type: 'RUNTIME',
                error: error
              }
            });
          }

          throw error;
        });
      };
    }
  });
};