import _ from 'lodash';
import { feedbacksListState } from '../../../../application/store/state/tools/feedbacksListState';
import { SatAndTopicsAspect } from '../../../../utils/query/aspect/SatAndTopicsAspect';
import { SatTypeAspect } from '../../../../utils/query/aspect/SatTypeAspect';
import { Highlight } from '../../../../utils/query/Highlight';
import { getEditedViz } from '../../../state/selectors/dashboardSelector';
import { actionNamespacer } from '../../../state/utils/actionNamespacer';

//---

const restoreInternalState = (namespace, state) => {
  const storedInternalState = getInternalState(namespace, state);
  state.page = storedInternalState.page || 1;
  state.selectedTopics = storedInternalState.selectedTopics || [];
};

const saveInternalState = (namespace, state) => {
  _.set(state, ['internalState', namespace], {
    page: state.page,
    selectedTopics: state.selectedTopics
  });
};

const getInternalState = (namespace, state) => {
  return _.get(state, ['internalState', namespace]) || {};
};

//---

export const standardProfileEditorStateName = 'standardProfileEditor';

export const stateSlice = (rootState) => {
  return rootState[standardProfileEditorStateName];
};

export const dispatchSlice = (dispatch) => {
  return dispatch[standardProfileEditorStateName];
};

const fls = feedbacksListState(standardProfileEditorStateName, null,{
  queryBuilderDecorator(query, rootState) {
    const viz = getEditedViz(rootState);
    const state = stateSlice(rootState);
    const vizParams = viz.definition.params;

    query.addAspect(new SatTypeAspect(vizParams.satType));
    query.addAspect(new SatAndTopicsAspect(vizParams.satType, vizParams.sat, state.selectedTopics));
    query.highlight = new Highlight(true, ['topic']);

    return query;
  },
  includeGlobalFilter: true,
  namespaced: true,
  afterPageChange(payload, rootState, dispatch) {
    dispatch.saveInternalState(payload);
  }
});

export const standardProfileEditorState = () => ({
  state: { ...fls.state },
  reducers: {
    reset(state, payload) {
      state.page = 1;
      state.selectedTopics = [];
      delete state.internalState;
      return state;
    },
    restoreInternalState(state, payload) {
      restoreInternalState(payload.namespace, state);
      return state;
    },
    saveInternalState(state, payload) {
      saveInternalState(payload.namespace, state);
      return state;
    },
    setSelectedTopics(state, payload) {
      state.selectedTopics = payload;
      return state;
    },
    ...fls.reducers
  },
  effects: (dispatch) => ({
    async init(payload, rootState) {
      dispatchSlice(dispatch).restoreInternalState(payload);

      const viz = getEditedViz(rootState);

      if (_.isEmpty(getInternalState(payload.namespace, stateSlice(rootState)).selectedTopics)) {
        dispatchSlice(dispatch).setSelectedTopics([...viz.data.bestTopics]);
        dispatchSlice(dispatch).saveInternalState(payload);
      }

      await dispatchSlice(dispatch).fetchFeedbacks();
      dispatch.dashboardVizEditor.setEditValue({ kind: 'data', key: 'feedback', value: viz.data.feedback });
    },
    async changeSelectedTopics(payload, rootState) {
      const selectedTopics = payload.data;

      dispatchSlice(dispatch).setSelectedTopics(selectedTopics);
      dispatchSlice(dispatch).saveInternalState(payload);

      if (selectedTopics?.length) {
        await dispatchSlice(dispatch).changePage(actionNamespacer(payload.namespace)(1));
      } else {
        dispatchSlice(dispatch).setSearchResult()
      }
    },
    ...fls.effects(dispatch)
  })
});
