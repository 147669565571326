import _ from 'lodash';
import * as PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { DateLabel } from '../../../../component/dateLabel/DateLabel';
import { Header } from '../../../../component/Header';
import { SmartIcon } from '../../../../component/icon/SmartIcon';
import { LabelIcon } from '../../../../component/LabelIcon';
import { InnerFeedback } from '../../../../feedback/InnerFeedback';
import { SatScore } from '../../../../feedback/SatScore';
import { trueSupplier } from '../../../../utils/functionUtils';
import { Satisfaction } from '../../../model/Satisfaction';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import './standardProfileViz.scss';

function Feedback({ customerKind, feedback, title }) {
  return <>
    <div className='standard-profile-feedback-left'>
      <div className='standard-profile-feedback-left-mood'>
        <SmartIcon icon={customerKind.icon} size={40} color={customerKind.color}/>
      </div>
      <div className='standard-profile-feedback-left-bubble'>
        <SatScore size={60} feedback={feedback} color={customerKind.color}/>
      </div>
    </div>
    <div className='standard-profile-feedback-right'>
      <InnerFeedback feedback={feedback}
                     header={<Header title={title}
                                     titleStyle={{fontWeight: 600, fontSize: 18, textTransform: 'uppercase'}}
                                     rightComponent={<LabelIcon icon='time' label={<DateLabel date={feedback.date}/>}/>}
                     />}
                     centerOnOffset={true}
                     verbatimClassName='standard-profile-feedback-right-verbatim'
                     shouldHighlight={trueSupplier}
      />
    </div>
  </>;
}

function StandardProfileVizFunction({ viz }, ref) {
  const customerKind = (Satisfaction as any).getValueOf(viz.definition.params.sat).customerKind;

  const data = viz.data;

  return <div className="standard-profile">
    <div className='standard-profile-list'>
      <ul>
        {
          _.map(data.profileItems, (it, i) => {
            return <li key={i}>{it}</li>;
          })
        }
      </ul>
    </div>

    <div className='standard-profile-feedback'>
      {
        data.feedback ? <Feedback customerKind={customerKind} feedback={data.feedback} title={data.feedbackTitle}/> : ''
      }
    </div>
  </div>;
}

export const StandardProfileViz =  forwardRef(StandardProfileVizFunction);

StandardProfileViz.propTypes = {
  viz: PropTypes.instanceOf(VisualizationInstance).isRequired
};
