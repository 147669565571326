import { services } from './services';

export class UrlService {

  constructor() {
  }

  isMock(): boolean {
    if (process.env.NODE_ENV === 'production') {
      return false;
    }

    const param = this.getUrlParam('mock');
    if (param === 'true') {
      return true;
    }
    if (param === 'false') {
      return false;
    }

    const hostname = window.location.hostname;
    if (hostname === 'localhost' || hostname === '127.0.0.1' || hostname === '0.0.0.0') {
      return true;
    }

    return false;
  }

  shouldSimulateWait(): boolean {
    return this.getUrlParam('wait') !== 'false' && this.isMock();
  }


  getUrlParam(param): string | null {
    return this.getUrlParams().get(param);
  }

  getUrlParams(): URLSearchParams {
    return new URLSearchParams(window.location.search);
  }

  getUrlPathname(): string {
    return window.location.pathname;
  }


  /**
   * @param {string} param
   * @param {function(string):*} parser
   */
  parseUrlParam(param: string, parser = (s) => s) {
    return this._parseUrlParam(param, parser, this.getUrlParams());
  }

  /**
   * @param {Object<string, function(string):*>} parsers
   * @return {Object<string, *>}
   */
  parseUrl(parsers) {
    const urlParams = this.getUrlParams();

    const result = {};

    Object.entries(parsers).forEach(([param, parser]) => {
      if (urlParams.has(param)) {
        result[param] = this._parseUrlParam(param, parser, urlParams);
      }
    });

    return result;
  }

  forgeUrl(url: string, path?: string): string {
    if (!(!!path?.length)) {
      return url;
    }

    let result = url;
    if (!result.endsWith('/')) {
      result += '/';
    }

    let p = path;
    if (path.startsWith('/')) {
      p = path.substring(1);
    }
    return result + p;
  }

  /**
   * @param {string} param
   * @param {function(string):*}parser
   * @param {URLSearchParams} urlParams
   */
  private _parseUrlParam(param, parser, urlParams) {
    if (!urlParams.has(param)) {
      return null;
    }

    const paramValue = urlParams.get(param);

    try {
      return parser(paramValue);
    } catch (e) {
      console.warn(`Cannot parse URL param "${param}" with value "${paramValue}" : ${e.message}`);
      throw e;
    }
  }

  isStandAlone() {
    return !!this.getUrlParam('standalone');
  }
}

services.registerService('urlService', new UrlService());
