import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { services } from '../application/service/services';
import { BlankSeparator } from '../component/BlankSeparator';
import { InfiniteCache } from '../utils/InfiniteCache';
import { FilterLocationEnum } from './FilterLocationEnum';
import _ from "lodash";
import { FilterChangeArg } from "./SideFilter";
import { AbstractFilterCtrl } from "./model/AbstractFilterCtrl";
import { WithTranslation, withTranslation } from "react-i18next";

interface FilterBarProps extends WithTranslation{
  leftFilters: Array<AbstractFilterCtrl<unknown>>,
  rightFilters: Array<AbstractFilterCtrl<unknown>>,
  changeValue: (arg: FilterChangeArg) => void,
}

class FilterBarComponent extends PureComponent<FilterBarProps> {
  private readonly handlersCache: InfiniteCache;

  constructor(props) {
    super(props);
    this.handlersCache = new InfiniteCache(this.createHandler);
  }

  createHandler = (filter) => {
    return val => this.props.changeValue({ key: filter.id, value: filter.stateConverter().toState(val) });
  };

  renderFilters(filters) {
    if (_.isEmpty(filters)) {
      return null;
    }

    return filters.map((filter, i) => {
        return <React.Fragment key={filter.id}>
          {filter.uiComponent(this.props.t, this.handlersCache.get(filter))}
          {i < filters.length ? <BlankSeparator/> : null}
        </React.Fragment>;
      }
    );
  }

  render() {
    const { leftFilters, rightFilters } = this.props;

    if (_.isEmpty(leftFilters) && _.isEmpty(rightFilters)) {
      return null;
    }

    return <div className='vui-layout--horizontal__center vui-layout--horizontal' style={{ alignItems: 'center' }}>
      {this.renderFilters(leftFilters)}
      <div style={{ flex: 1 }}/>
      {this.renderFilters(rightFilters)}
    </div>;
  }
}

const mapState = () => {
  const currentFilter = services.getFilterService().currentFilter;
  return {
    leftFilters: currentFilter ? currentFilter.getFilters(FilterLocationEnum.TOP_START) : [],
    rightFilters: currentFilter ? currentFilter.getFilters(FilterLocationEnum.TOP_END) : []
  };
};

const mapDispatch = dispatch => {
  return {
    changeValue: dispatch.filter.changeValue
  };
};

export const FilterBar = withTranslation()(connect(mapState, mapDispatch)(FilterBarComponent));