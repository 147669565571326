import { ActionPlanViz } from './actionPlanViz';
import { ActionPlanEditor } from './actionPlanEditor';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { getTitle } from "../common";

registerVizType(
  {
    name: 'ACTION_PLAN',
    component: ActionPlanViz,
    title: getTitle,
    editor: {
      component: ActionPlanEditor,
      title: 'dashboard.viz.actionPlan.title',
      enabled: vizInstance => {
        return vizInstance.hasData();
      },
      applyOnData: (data, editedData) => {
        data.selection = editedData.selection;
      },
      focusOnMount: false,
      width: 1600
    },
    noDataPolicy: {
      hasData: vizInstance => (vizInstance.data.items &&  vizInstance.data.items.length > 0)
    }
  }
);
