import { Button, Colors, Intent, MenuItem } from '@blueprintjs/core';
import { MultiSelectProps, Suggest } from '@blueprintjs/select';
import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SmartIcon } from '../icon/SmartIcon';
import { ISelectItemRenderer, ISelectUtilProps, SelectUtil } from './utils/selectUtils';
import { SingleSelection } from './utils/SingleSelection';
import _ from 'lodash';

const whiteIcon = (icon) => {
  if (_.isString(icon)) {
    return <SmartIcon icon={icon} color={Colors.WHITE}/>;
  } else {
    return React.cloneElement(icon, { color: Colors.WHITE })
  }
}

const defaultItemRenderer = (item, { id, text, highlightedText, icon, selected }, {
  handleClick,
  modifiers,
  query
}) => {
  return (
    <MenuItem
      icon={icon && selected ? whiteIcon(icon) : icon}
      active={selected}
      disabled={modifiers.disabled}
      key={id}
      onClick={handleClick}
      text={highlightedText}
    />
  );
};

interface SelectInputProps<T> extends WithTranslation, ISelectUtilProps<T> {
  items: Array<T>;
  selectedItem: T;
  onSelectionChanged: OnChangeHandler<T>,
  placeholder?: string,
  inputStyle?: React.CSSProperties,
  itemId?: (item: T) => string,
  itemLabel?: (item: T) => string,
  itemIcon?: (item: T) => JSX.Element,
  itemRenderer: ISelectItemRenderer<T>,
  itemEqual: EqualComparator<T>,
  selectProps?: Partial<MultiSelectProps<T>>,
  allowEmptySelection?: boolean,
  disabled?: boolean,
  annihilateEnterKey: boolean
}

class SelectInputComponent<T> extends PureComponent<SelectInputProps<T>> {
  private selectUtil: SelectUtil<T>;
  private input: HTMLInputElement;

  static defaultProps: Partial<SelectInputProps<any>> = {
    items: [],
    selectProps: {},
    allowEmptySelection: true,
    disabled: false
  };
  private hiddenButtonRef: HTMLAnchorElement;

  constructor(props) {
    super(props);

    this.selectUtil = new SelectUtil(defaultItemRenderer);
  }

  private getBestInputWidth(): number | string {
    if (this.props.selectedItem) {
      return (this.selectUtil.getItemLabel(this.props.selectedItem).length + 2) + 'ch';
    } else {
      return 0;
    }
  }

  private renderInputItem = (topic): string => {
    return this.selectUtil.getItemLabel(topic);
  };

  private isValid(): boolean {
    return this.props.allowEmptySelection || !_.isNil(this.props.selectedItem);
  }

  public openPopover = (): void => {
    if (this.input) {
      this.input.focus();
    }
  }

  render() {
    const { t, selectProps, allowEmptySelection, disabled, annihilateEnterKey } = this.props;

    const singleSelection = new SingleSelection(this.props.selectedItem, this.props.onSelectionChanged, this.props.itemEqual);
    this.selectUtil.props = this.props;
    this.selectUtil.isSelectedFn = singleSelection.isSelected;

    const rightButton = this.props.selectedItem && allowEmptySelection ?
      <Button icon='cross' onClick={singleSelection.unselect} minimal={true}/> :
      <Button icon='chevron-down' onClick={this.openPopover} minimal={true}/>;

    const inputStyle = Object.assign({
      width: this.getBestInputWidth(),
      minWidth: 200
    }, this.props.inputStyle || {});

    return <>
      <Suggest
        items={this.props.items}
        selectedItem={this.props.selectedItem}
        activeItem={this.props.selectedItem}
        itemPredicate={this.selectUtil.itemPredicate}
        itemRenderer={this.selectUtil.renderItem}
        inputValueRenderer={this.renderInputItem}
        noResults={<MenuItem disabled={true} text={t('noResult')}/>}
        onItemSelect={(item, event) => {
          singleSelection.select(item);
          if (annihilateEnterKey) {
            setTimeout(() => this.hiddenButtonRef.focus(), 50);
          }
        }
        }
        disabled={disabled}
        inputProps={{
          leftIcon: this.selectUtil.getItemIcon(this.props.selectedItem),
          placeholder: this.props.placeholder,
          rightElement: rightButton,
          inputRef: ref => this.input = ref,
          style: inputStyle,
          intent: this.isValid() ? undefined : Intent.DANGER,
        }}
        popoverProps={{
          rootBoundary: 'viewport'
        }}
        {...selectProps}
        scrollToActiveItem={true}
        resetOnSelect={true}
      />
      {
        //Workaround
        //in case of enter annihilation, create a to capture key press event
        annihilateEnterKey ?
          <a tabIndex={-1} ref={ref => this.hiddenButtonRef = ref} style={{ width: 0, height: 0 }}></a> : null
      }
    </>;
  }
}

export const SelectInput = withTranslation()(SelectInputComponent);
