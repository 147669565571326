import { services } from './services';
import { DateTime, Settings } from "luxon";

const getTimeZone = () => {
  return services.getUrlService().getUrlParam('timeZone') || Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export class TimeZoneService {
  /**
   * @type {string}
   * @private
   */
  _timeZone;

  init() {
    this.timeZone = getTimeZone();
  }

  /**
   * @param {string} timeZone
   */
  set timeZone(timeZone:string) {
    this._timeZone = timeZone;
    Settings.defaultZone = timeZone;
  }

  /**
   * @return {string}
   */
  getTimeZone():string {
    return this._timeZone;
  }
}

services.registerService('timeZoneService', new TimeZoneService());
