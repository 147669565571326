import { Kpi } from "./Kpi";
import { TFunction } from "i18next";
import _ from "lodash";
import { ReactNode } from "react";
import {ChartType, ChartSerieData, YAxis} from "../../../utils/chart/SerieChartBuilder";

export interface KpiEvolution{
  type: ChartType,
  data: ChartSerieData,
  yAxis: YAxis,
  tooltipRenderer: (value: any) => ReactNode,
}

export class KpiInstance {
  kpi:Kpi;
  data:any;

  constructor(kpi:Kpi, data:any) {
    this.kpi = kpi;
    this.data = data;
  }

  getName():string {
    return this.kpi.name;
  }

  getLabel(t:TFunction):string {
    return this.kpi.getLabel(this, t);
  }

  getNumber(t:TFunction):string {
    return this.kpi.getNumber(this, t);
  }

  getLegend(t:TFunction):string {
    return this.kpi.getLegend(this, t);
  }

  getIcon():string {
    return this.kpi.getIcon(this);
  }

  getColor():string {
    return this.kpi.getColor(this);
  }

  getEvolution(t:TFunction):KpiEvolution {
    return this.kpi.getEvolution(this, t);
  }

  hasData():boolean {
    return !_.isEmpty(this.data);
  }

  hasEvolutionData():boolean {
    return this.hasData() && !_.isEmpty(this.data.evolution);
  }
}