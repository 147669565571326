import axios from "axios";
import { KafkaLog } from "../../../administration/model";

const getLogs = async (): Promise<{ [key: string]: Array<KafkaLog> }> => {
  return axios
    .get('/api/sourcing/_kafka/_logs')
    .then(response => response.data);
}


export default { getLogs };
