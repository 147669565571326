export interface ChartBubbleAxis {
    min?: number;
    max?: number;
    label: string;
}

export interface BubbleChartLine {
    value: string;
    label: string;
}

export class BubbleChartDataset {
    public xAxis: ChartBubbleAxis;
    public data: any;
    public yAxis: { label: string };
    private areas: any;
    private verticalLines: BubbleChartLine[];
    private horizontalLines: BubbleChartLine[];

    constructor(data, xAxis: ChartBubbleAxis, yAxis: ChartBubbleAxis, areas, horizontalLines: BubbleChartLine[], verticalLines: BubbleChartLine[]) {
        this.areas = areas;
        this.xAxis = xAxis;
        this.yAxis = yAxis;
        this.data = data;
        this.verticalLines = verticalLines || [];
        this.horizontalLines = horizontalLines || [];
    }

    hasData() {
        return this.data && this.data.length > 0;
    }
}