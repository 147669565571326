import { Param } from "../Param";
import { ParamValue } from "../values/ParamValue";
import _ from "lodash";
import { arrayContains, toArray } from "../../../../utils/collectionUtils";

export class ResolvedParamValue<T extends ParamValue = ParamValue> {
  public readonly param: Param<T>;
  public readonly value: OneOrMany<T>;

  constructor(param: Param<T>, value: OneOrMany<T>) {
    this.param = param;
    this.value = value;
  }

  varValueAsPlainObject(): any {
    return _.isNil(this.value) ? null : this.param.valueAsPlainObject(this.value);
  }

  valueAsPlainObject(): any {
    if (_.isNil(this.value)) {
      return null;
    }
    if (Array.isArray(this.value)) {
      return this.value.map(v => v.asPlainObject());
    }
    return this.value.asPlainObject();
  }

  isValid(): boolean {
    if(this.param.optional) return true;
    if (_.isEmpty(this.value)) {
      return this.param.optional;
    }

    return !arrayContains(toArray(this.value), (paramValue) => !paramValue.isValid());
  }
}