import React from "react";
import {Button, Popover} from "@blueprintjs/core";
import {SketchPicker} from 'react-color';
import Color from 'color';
import './colorPicker.scss';

interface ColorPickerProps {
    color: string,
    onChanged: (hexColor: string) => void
}

export class ColorPicker extends React.PureComponent<ColorPickerProps> {
    onColorChanged = ({hex}) => {
        this.props.onChanged(hex)
    }

    render() {
        const colr = Color(this.props.color);
        let clazz = 'colorPickerButton'
        if (colr.isDark()) {
            clazz += ' dark';
        }

        return <Popover content={<SketchPicker disableAlpha={true}
                                               color={this.props.color}
                                               onChangeComplete={this.onColorChanged}/>}>
            <Button className={clazz}>
                <div style={{backgroundColor: this.props.color}}>{this.props.color}</div>
            </Button>
        </Popover>
    }
}