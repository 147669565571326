import { services } from '../../application/service/services';

export interface FeedbackStatsItemDTO {
  total: number,
  notEmpty: number,
  categorized: number;
}
interface FeedbackStatsByChannelKindDTO extends FeedbackStatsItemDTO {
  channelKind: string
}
interface FeedbackStatsByMonthDTO extends FeedbackStatsItemDTO {
  date: number,
  byChannelKind: Array<FeedbackStatsByChannelKindDTO>
}
export interface FeedbackStatsDTO extends FeedbackStatsItemDTO{
  byMonth: Array<FeedbackStatsByMonthDTO>,
  byChannelKind: Array<FeedbackStatsByChannelKindDTO>
}

export class SystemService {
  _feedbackFetcher;

  init() {
    this._feedbackFetcher = services.getFetcherService().getFetcher('feedback');
  }

  isAuthorized(): boolean {
    if (this.isAuthorizedForTenantInfo() || this.isAuthorizedForStats()) {
      return true;
    }

    return false;
  }

  isAuthorizedForTenantInfo(): boolean {
    return services.getTenantService().isAuthorizedForGetTenant();
  }

  isAuthorizedForStats(): boolean {
    return services.getSecurityService().hasRole('vecko.feedback.stats');
  }

  async getFeedbackStats(): Promise<FeedbackStatsDTO> {
    return this._feedbackFetcher.getFeedbackStats();
  }

  async exportFeedbackStats(): Promise<any> {
    await this._feedbackFetcher.exportFeedbackStats();
  }
}

services.registerService('systemService', new SystemService());