export class PageIndexManager {
  readonly list: Array<any>;
  readonly page: number;
  readonly pageSize: number;
  readonly total: number;

  pageStartRealIndex: number;
  pageEndRealIndex: number;

  constructor(list, page, pageSize, total) {
    this.list = list;
    this.page = page;
    this.pageSize = pageSize;
    this.total = total;

    this.pageStartRealIndex = this.toRealIndex(0);
    this.pageEndRealIndex = this.toRealIndex(pageSize - 1);
  }

  getItemByRealIndex(realIndex) {
    if (this.isInPage(realIndex)) {
      return this.list[this.toPageIndex(realIndex)];
    }
    return null;
  }

  toRealIndex(indexInPage) {
    if (indexInPage === -1) {
      return -1;
    }
    return this.pageSize * (this.page - 1) + indexInPage;
  }

  toPageIndex(realIndex) {
    return realIndex % this.pageSize;
  }

  isInPage(realIndex) {
    return realIndex >= this.pageStartRealIndex && realIndex <= this.pageEndRealIndex;
  }

  getIndexInPage(item) {
    return this.list ? this.list.findIndex(it => it.id === item.id): -1;
  }

  getRealIndex(item) {
    return this.toRealIndex(this.getIndexInPage(item));
  }

  isFirst(realIndex) {
    return realIndex === 0;
  }

  isLast(realIndex) {
    return realIndex === this.total - 1;
  }
}