import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { MetricSelector } from '../../../../component/selector/MetricSelector';
import { Metric } from '../../../model/MetricEnum';
import { FlatMapEditor } from "../../../../component/FlatMapEditor";

interface MetricListEditorProps extends WithTranslation {
    className?: string,
    metrics: Array<Metric>,
    items: Array<Metric>,
    disabled: boolean,
    onChanged: (items: Array<Metric>) => void,
    annihilateEnterKey?: boolean
}

class MetricListEditorComponent extends React.PureComponent<MetricListEditorProps> {
    static defaultProps: Partial<MetricListEditorProps> = {
        disabled: false
    }

    keyId = (key: Metric): string => {
        return key.name;
    };

    keyLabel = (key: Metric): string => {
        const { t } = this.props;
        return key.getLabel(t);
    };

    keySelector = (items: Array<Metric>, selectedItem: Metric, onSelectionChanged: (item: Metric) => void) => {
        return <MetricSelector items={items}
                               selectedItem={selectedItem}
                               onSelectionChanged={onSelectionChanged}
                               allowEmptySelection={false}
                               annihilateEnterKey={this.props.annihilateEnterKey}/>;
    };

    render() {
        const { className, metrics, items, disabled, onChanged, annihilateEnterKey } = this.props;

        return <FlatMapEditor className={className}
                              possibleItems={metrics}
                              items={items}
                              itemId={this.keyId}
                              itemLabel={this.keyLabel}
                              itemSelector={this.keySelector.bind(this)}
                              onChanged={onChanged}
                              enableItemOrdering={true}
                              fill={false}
                              disabled={disabled}
                              annihilateEnterKey={annihilateEnterKey}
        />;
    }
}

export const MetricListEditor = withTranslation()(MetricListEditorComponent);
