import React from 'react';
import {QueryStringInput} from '../../component/input/QueryStringInput';
import {AbstractFilterCtrl, TagValue} from './AbstractFilterCtrl';
import {stateConverterRegistry} from './stateConverters/stateConverterRegistry';
import {StateConverter} from "./stateConverters/StateConverter";
import {TFunction} from "i18next";
import {Field} from "../../application/model/field/Field";
import _ from "lodash";

export class FieldQueryStringFilterCtrl extends AbstractFilterCtrl<string> {
    private _value: string = '';
    private _field: Field;

    public constructor(id: string, field: Field);
    public constructor(id: string, field: Field, displayed: () => boolean);
    public constructor(id: string, field: Field, displayed?: () => boolean) {
        super(id);
        this._field = field
        if (displayed) this.displayed = displayed;
    }

    get field(): Field {
        return this._field;
    }

    getValue(): string {
        return this._value;
    }

    setValue(value: string) {
        if (_.isArray(value)) {
            const array = value as unknown as Array<string>;
            if (array.length > 1) throw new Error("cannot set multiple values to string filter.")
            value = array[0];
        }
        this._value = value;
    }

    uiComponent(t: TFunction, onChanged, options): JSX.Element {
        return <React.Fragment key={this.id}>
            <div>{this._field.getLabel(t)}</div>
            <QueryStringInput
                value={this._value}
                expandedWidth={'calc(100% - 10px)'}
                onApply={onChanged}
                style={{margin: "3px"}}
                {...options}
            /></React.Fragment>;
    }

    stateConverter(): StateConverter<string> {
        return stateConverterRegistry.get(String) as StateConverter<string>;
    }

    getTagValues(t: TFunction): Array<TagValue<string>> {
        return [{tagValue: this._field.getLabel(t) + ":" + this.getValue(), value: this.getValue()}];
    }
}