import React from 'react';
import { Tool, ToolProps } from './Tool';
import { ButtonGroup } from "@blueprintjs/core";
import { SeparatedComponents } from "../SeparatedComponents";

export interface ITool extends ToolProps {
  id: string,
}

interface ToolsProps {
  tools?: Array<ITool>,
  minimal?: boolean,
  group?: boolean,
  className?: string,
}

export class Tools extends React.PureComponent<ToolsProps> {
  static defaultProps: Partial<ToolsProps> = {
    tools: [],
    minimal: true,
    group: true
  };

  render() {
    if (this.props.group) {
      return <ButtonGroup className={this.props.className}>
        {
          this.props.tools?.map(tool => <React.Fragment key={tool.id}>{<Tool
            minimal={this.props.minimal} {...tool}/>}</React.Fragment>)
        }
      </ButtonGroup>
    } else {
      return <div className={this.props.className}>
        <SeparatedComponents>
          {
            this.props.tools?.map(tool => <React.Fragment key={tool.id}>{<Tool
              minimal={this.props.minimal} {...tool}/>}</React.Fragment>)
          }
        </SeparatedComponents>
      </div>
    }

  }
}