import { StateConverter, StateConverterParser } from './StateConverter';

export class BooleanStateConverter extends StateConverter<Boolean> {
  clazz(): Class<Boolean> {
    return Boolean;
  }

  getParser(): Maybe<StateConverterParser<Boolean>> {
    return BooleanStateConverter.parser;
  }

  private static parser = (str: string): Maybe<Boolean> => {
    return str? JSON.parse(str.toLowerCase()): null;
  }
  fromState(obj: Maybe<OneOrMany<any>>): Maybe<OneOrMany<Boolean>> {
    return !obj? false : super.fromState(obj) ;
  }

  toState(obj: Maybe<OneOrMany<Boolean>>): Maybe<OneOrMany<any>> {
    return !obj? false : super.toState(obj);
  }
}