import { services } from '../../application/service/services';
import { StorageDirectory } from '../storage/model/StorageDirectory';

export const storageState = () => ({
  state: {
    path: StorageDirectory.root().path,
    items: [],
    selection: [],
    confirmingDelete: false,
    pathsToDelete: []
  },
  reducers: {
    setPath(state, payload) {
      state.path = payload;
      return state;
    },
    setItems(state, payload) {
      state.items = payload;
      state.selection = [];
      state.confirmingDelete = false;
      state.pathsToDelete = []
      return state;
    },
    setSelection(state, payload) {
      state.selection = payload;
      return state;
    },
    askForDelete(state, payload) {
      state.confirmingDelete = true;
      state.pathsToDelete = payload ? payload : state.selection;
      return state;
    },
    cancelDelete(state, payload) {
      state.confirmingDelete = false;
      state.pathsToDelete = [];
      return state;
    }
  },
  effects: (dispatch) => ({
    async init(payload, rootState) {
      dispatch.storage.changePath({ storageKind: payload, path: StorageDirectory.root().path });
    },
    async changePath(payload, rootState) {
      const path = payload.path;
      const items = await services.getStorageService().list(payload.storageKind, new StorageDirectory(path));
      dispatch.storage.setPath(path);
      dispatch.storage.setItems(items);
    },
    async refresh(payload, rootState) {
      const items = await services.getStorageService().list(payload.storageKind,
        new StorageDirectory(rootState.storage.path));
      dispatch.storage.setItems(items);
    },
    async downloadStoragePaths(payload, rootState) {
      await services.getStorageService().downloadStoragePaths(payload.storageKind, payload.paths);
    },
    async sourceStoragePaths(payload, rootState) {
      await services.getStorageService().sourceStoragePaths(payload.storageKind, payload.paths);
    },
    async confirmDelete(payload, rootState) {
      await services.getStorageService().deleteStoragePaths(payload.storageKind, rootState.storage.pathsToDelete);
      dispatch.storage.refresh({ storageKind: payload.storageKind });
    },
    async uploadStorageFile(payload, rootState) {
      await services.getStorageService().uploadStorageFile(payload.storageKind, rootState.storage.path, payload.file,
        payload.options, payload.progressHandler);
      dispatch.storage.refresh({ storageKind: payload.storageKind });
    }
  })
});