import _ from 'lodash';
import { CustomersToRecallViz } from './customersToRecallViz';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { VisualizationInstance } from "../../../model/viz/VisualizationInstance";
import { TFunction } from "i18next";
import { getTitle } from "../common";

registerVizType({
  name: 'CUSTOMERS_TO_RECALL',
  component: CustomersToRecallViz,
  title: (vizInstance: VisualizationInstance, t: TFunction) => {
    const title = getTitle(vizInstance, t);
    return title ? title : 'dashboard.viz.customerToRecall.title';
  },
  noDataPolicy: {
    hasData: vizInstance => {
      return _.filter(vizInstance.data, v => v.value > 0).length > 0;
    }
  }
});