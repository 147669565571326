import { services } from '../../application/service/services';
import { getResolvedParamsFromState, resolveParams } from './dashboardParamsManager';

export const dashboardParamsState = () => ({
  state: { values: {}, commonFilters: {} },
  reducers: {
    resetParamValues(state) {
      state.values = {};
      return state;
    },
    setParamValues(state, payload) {
      state.values = payload.getParamValuesByParamName();
      return state;
    },
    setParamsValidity(state, payload) {
      state.isValid = payload;
      return state;
    },
    setValues(state, payload) {
      state.values = payload;
      return state;
    },
    setAutoApply(state, payload) {
      state.autoApply = payload;
      return state;
    }
  },
  effects: (dispatch) => ({
    init(payload, rootState) {
      dispatch.dashboardParams.resetParamValues();
      const resolvedParams = resolveParams(payload, rootState.dashboardParams.values, true);

      dispatch.dashboardParams.setParamValues(resolvedParams);
      dispatch.dashboardParams.applyParamValue(resolvedParams);
    },
    changeParamValue(payload, rootState) {
      const resolvedParamsState = { ...rootState.dashboardParams.values, [payload.param.name]: payload.value };
      const resolvedParams = resolveParams(rootState.dashboard.id, resolvedParamsState, false);
      dispatch.dashboardParams.applyParamValue(resolvedParams);
    },
    applyParamValue(resolvedParams, rootState) {
      dispatch.dashboardParams.setParamValues(resolvedParams);
      const isValid = resolvedParams.isValid();
      dispatch.dashboardParams.setParamsValidity(isValid);

      if (isValid && rootState.dashboardParams.autoApply) {
        dispatch.dashboardParams.applyParams();
      }
    },
    async applyParams(payload, rootState) {
      if (rootState.dashboardParams.isValid) {
        dispatch.dashboard.closeFeedBacksPanel();
        if (payload) {
          await dispatch.dashboardVizEditor.reset();
          await dispatch.dashboardVizEditor.clear();
        }

        const dashboardId = rootState.dashboard.id;

        const resolvedParams = getResolvedParamsFromState(dashboardId, rootState.dashboardParams.values);
        const resolvedSpec = services.getDashboardService().resolveDashboardSpec(dashboardId, resolvedParams);

        dispatch.dashboard.setResolvedParams(resolvedParams.asPlainObject());
        dispatch.dashboard.setResolvedSpec(resolvedSpec);

        await dispatch.dashboardData.fetchData();
      }
    }
  })
});