import React from 'react';
import { Trans } from 'react-i18next';
import { SatTypeEnum } from '../../../../application/model/SatTypeEnum';
import { Satisfaction } from '../../../model/Satisfaction';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { getTitle } from '../common';
import { CustomerCommentEditor } from './customerCommentEditor';
import { customerCommentEditorState, customerCommentEditorStateName } from './customerCommentEditorState';
import { CustomerCommentViz } from './customerCommentViz';

const titleTrans = (vizInstance, keyPrefix, t) => {
  const title = getTitle(vizInstance, t);
  if(title){
    return title;
  }
  const satType = SatTypeEnum.valueOf(vizInstance.definition.params.satType);
  const satisfaction = Satisfaction['getValueOf'](vizInstance.definition.params.sat);
  const customerKind = satisfaction.customerKind;
  const color = customerKind.color;

  return <Trans i18nKey={keyPrefix}
                values={{ sat: satisfaction.name(), satType: satType.getAliasedName() }}>
    <span style={{ color: color }}/>
  </Trans>;
};

registerVizType({
  name: 'CUSTOMER_COMMENT',
  component: CustomerCommentViz,
  title: (vizInstance, t) => titleTrans(vizInstance, 'dashboard.viz.customerComment.title', t),
  editor: {
    component: CustomerCommentEditor,
    width:'1000px',
    title: (vizInstance,t) => titleTrans(vizInstance, 'dashboard.viz.customerComment.editor.title', t),
    enabled: vizInstance => {
      return vizInstance.hasData();
    },
    applyOnParams: (params, editedParams) => {
      Object.assign(params, editedParams);
    },
    applyOnData: (data, editedData) => {
      data.feedback = editedData.feedback;
      data.feedbacks = editedData.feedbacks;
      data.title = editedData.title;
      data.comments = editedData.comments;
      data.profileItems = editedData.profileItems;
      data.headerFields = editedData.headerFields;
    },
    focusOnMount: false,
    state: {
      name: customerCommentEditorStateName,
      create: () => customerCommentEditorState()
    }
  }
});
