import _ from 'lodash';
import {FilterLocation, FilterLocationEnum} from '../../filter/FilterLocationEnum';
import {CompositeFilterCtrl} from '../../filter/model/CompositeFilterCtrl';
import {DateRangeFilterCtrl} from '../../filter/model/DateRangeFilterCtrl';
import {FieldValueFilterBuilder} from '../../filter/model/FieldValueFilterCtrl';
import {QueryStringFilterCtrl} from '../../filter/model/QueryStringFilterCtrl';
import {last12Months} from '../../utils/dateUtils';
import {FieldDisplay, FieldDisplayKindType} from '../model/field/FieldDisplay';
import {VeckoFields} from '../model/field/VeckoFields';
import {services} from './services';
import {AbstractFilterCtrl} from "../../filter/model/AbstractFilterCtrl";
import {ApiFilter} from "../../utils/query/filter/ApiFilter";
import {VerbatimNotEmptyFilter} from "../../utils/query/filter/VerbatimNotEmptyFilter";
import {NotEmptyVerbatimsFilterCtrl} from "../../filter/model/NotEmptyVerbatimsFilterCtrl";
import {FieldQueryStringFilterCtrl} from "../../filter/model/FieldQueryStringFilterCtrl";
import {Field, FieldName} from "../model/field/Field";

export const SharedFilterNames = {
    DATE_RANGE: 'dateRange'
}

const NON_EMPTY_VERBATIM_FILTER = new VerbatimNotEmptyFilter();

export class FilterService {
    private _currentFilter: ViewFilter;
    private _viewFilters: Dict<ViewFilter> = {};
    private _additionalFilters: Dict<ApiFilter[]> = {};

    async init() {
        const sharedFilters = [
            new DateRangeFilterCtrl(SharedFilterNames.DATE_RANGE, services.getFieldsService().getField(VeckoFields.DATE), last12Months())
        ];

        const topicsViewFilter = new ViewFilter('topics', sharedFilters);// todo : rename into topicsStat
        this._registerViewFilter(topicsViewFilter);

        const analysisViewFilter = new ViewFilter('analysis', sharedFilters);
        this._registerViewFilter(analysisViewFilter);

        const feedbacksViewFilter = new ViewFilter('feedbacks', sharedFilters)
            .registerFilter(new QueryStringFilterCtrl('queryString'), FilterLocationEnum.TOP_END);
        this._registerViewFilter(feedbacksViewFilter);
        feedbacksViewFilter.registerFilter(new NotEmptyVerbatimsFilterCtrl("verbatimNotEmpty", true,
            () => services.getSecurityService().hasRole('vecko.export')), FilterLocationEnum.SIDE);
        feedbacksViewFilter.registerFilter(new FieldQueryStringFilterCtrl("system.substream",
            new Field(FieldName.system('substream')), () => services.getSecurityService().isAdmin()), FilterLocationEnum.SIDE);

        const reportViewFilter = new ViewFilter('report', sharedFilters);
        this._registerViewFilter(reportViewFilter);

        const dashboardViewFilter = new ViewFilter('vecko-dashboard', sharedFilters);
        this._registerViewFilter(dashboardViewFilter);


        // side filter

        services.getFieldsService().getAllFieldValues(FieldDisplay.FieldDisplayKind.FILTER_VIEW)
            .filter(fv => fv.values.length > 0)
            .forEach(fv => {
                const field = fv.field;

                const filter = new FieldValueFilterBuilder(field.name)
                    .fieldValues(fv)
                    .allowEmptySelection(true)
                    .allowMultiSelection(true)
                    .allowNoneFieldValue(true)
                    .build();

                topicsViewFilter.registerFilter(filter, FilterLocationEnum.SIDE);
                analysisViewFilter.registerFilter(filter, FilterLocationEnum.SIDE);
                feedbacksViewFilter.registerFilter(filter, FilterLocationEnum.SIDE);
                dashboardViewFilter.registerFilter(filter, FilterLocationEnum.SIDE);
            });
    }


    setCurrentFilter(filterId: string): void {
        this._currentFilter = this._viewFilters[filterId];
    }

    get currentFilter(): ViewFilter {
        return this._currentFilter;
    }

    /**
     * On the current filter, get the value of the given key
     * @param key
     */
    getValue<T>(key: string): T {
        return this._currentFilter.getValue(key);
    }

    /**
     * On the current filter, set the value to the given key
     * @param key
     * @param value
     */
    setValue(key: string, value: any): void {
        this._currentFilter.setValue(key, value);
    }

    getFilterForApi(excludedFilters: [string]): ApiFilter {
        return services.getFilterConverterService().convertToApiFilter(this._currentFilter.filter, excludedFilters);
    }

    private _registerViewFilter(viewFilter: ViewFilter): void {
        this._viewFilters[viewFilter.id] = viewFilter;
    }

    private _registerAdditionalFilters(displayKind: FieldDisplayKindType, additionalFilter: ApiFilter): void {
        this._additionalFilters[displayKind] = this._additionalFilters[displayKind] || [];
        this._additionalFilters[displayKind].push(additionalFilter)
    }

    getAdditionalFilters(displayKind: FieldDisplayKindType): ApiFilter[] {
        return this._additionalFilters[displayKind];
    }
}

services.registerService('filterService', new FilterService());


class ViewFilter {
    private readonly _id: string;
    private readonly _filter: CompositeFilterCtrl;
    private _filtersMap: Dict<AbstractFilterCtrl<unknown>> = {};
    private _filtersByLocation = new Map<FilterLocation, Dict<AbstractFilterCtrl<unknown>>>();

    constructor(id: string, sharedFilters: Array<AbstractFilterCtrl<unknown>> = []) {
        this._id = id;
        this._filter = new CompositeFilterCtrl(id);
        sharedFilters.forEach(f => this.registerFilter(f));
    }

    get id(): string {
        return this._id;
    }

    get filter(): CompositeFilterCtrl {
        return this._filter;
    }

    registerFilter<T>(filter: AbstractFilterCtrl<T>, location: FilterLocation = FilterLocationEnum.TOP_START): ViewFilter {
        if (_.isNil(filter)) {
            throw new Error(`Cannot register a nil filter`);
        }

        if (!(location instanceof FilterLocation)) {
            throw new Error('given location must be a FilterLocationEnum');
        }

        if (this._filtersMap.hasOwnProperty(filter.id)) {
            throw new Error(`A filter is already registered for id ${filter.id}`);
        }

        this._filtersMap[filter.id] = filter;
        this._filter.add(filter);

        let byLocations = this._filtersByLocation.get(location);
        if (_.isNil(byLocations)) {
            byLocations = {};
            this._filtersByLocation.set(location, byLocations);
        }
        byLocations[filter.id] = filter;

        return this;
    }

    getFiltersNames(location: Maybe<FilterLocation> = undefined): Array<string> {
        return Object.keys(this._getFiltersMap(location));
    }

    getFilters(location: Maybe<FilterLocation> = undefined): Array<AbstractFilterCtrl<unknown>> {
        return Object.values(this._getFiltersMap(location)).filter(filter => filter.displayed());
    }

    /**
     * list the filters controllers for which a value is specified
     */
    getFilteringFilters(location: Maybe<FilterLocation> = undefined): Array<AbstractFilterCtrl<unknown>> {
        return this.getFilters(location)
            .filter(filter => filter.hasValue());
    }

    /**
     * Clears the filter and return the list of affected filter ids
     */
    clear(location: Maybe<FilterLocation> = undefined): Array<string> {
        const result: Array<string> = [];
        this.getFilters(location)
            .filter(filter => filter.hasValue())
            .forEach(filter => {
                filter.reset();
                result.push(filter.id);
            });
        return result;
    }

    hasFilter(id: string): boolean {
        return this._filtersMap.hasOwnProperty(id);
    }

    getFilter<T>(id: string): AbstractFilterCtrl<T> {
        const filter = this._filtersMap[id];
        if (_.isNil(filter)) {
            throw new Error(`No filter registered for id ${id} in ViewFilter ${this._id}`);
        }
        return filter as AbstractFilterCtrl<T>;
    }

    getValue(key: string): any {
        if (this.hasFilter(key)) {
            return this.getFilter(key).getValue();
        } else {
            return undefined;
        }
    }

    setValue(key, value) {
        this.getFilter(key).setValue(value);
    }

    _getFiltersMap(location: Maybe<FilterLocation> = undefined): Dict<AbstractFilterCtrl<unknown>> {
        return _.isNil(location) ? this._filtersMap : (this._filtersByLocation.get(location) || {});
    }
}
