interface PromisePoolOptions {
  timeout?: number,
  interval?: number,
  timeoutMsg?: string,
}

type PromisePoolTaskState = 'queued' | 'started' | 'error' | 'done';

interface PromisePoolTask<T> {
  id: number,
  state: PromisePoolTaskState,
  promise: Promise<T>
}

export class PromisePool {
  private readonly size: number;
  private readonly options: PromisePoolOptions;

  private readonly tasks: Array<PromisePoolTask<any>> = [];
  private isStarted = false;
  private isCanceled = false;
  private runningTasksCount = 0;

  constructor(size: number, options?: PromisePoolOptions) {
    this.size = size;
    this.options = {
      timeout: 10000,
      interval: 100,
      timeoutMsg: 'Timeout!',
      ...options
    };
  }

  private readonly poolSizeCheck = () => {
    return this.runningTasksCount < this.size;
  }

  queue<T>(fn: () => T) {
    if (this.isStarted) {
      throw new Error('Cannot queue additional tasks as pool is already started.');
    }
    const taskId = this.newTaskId();

    const task: PromisePoolTask<T> = {
      id: taskId,
      state: 'queued',
      promise: null,
    };

    this.tasks.push(task);
    task.promise = this.getPromise(task, fn);
  }

  async settle() {
    this.isStarted = true;
    return await Promise.allSettled(this.tasks.map(t => t.promise));
  }

  cancel() {
    this.isCanceled = true;
  }

  //--

  private newTaskId(): number {
    return this.tasks.length + 1;
  }

  private getPromise<T>(task: PromisePoolTask<T>, fn: () => T): Promise<T> {
    return new Promise(
      (resolve, reject) => {
        const startTime = Date.now();

        const poll = async () => {
          if (this.isCanceled) {
            reject(new Error('Task canceled from pool cancellation'));
          }

          const shouldStart = this.poolSizeCheck();
          if (shouldStart) {
            this.start(task);
            try {
              const result = await fn();
              resolve(result);
              this.done(task, 'done');
            } catch (e) {
              reject(e);
              this.done(task, 'error');
            }
          } else if (Date.now() - startTime > this.options.timeout) {
            reject(new Error(this.options.timeoutMsg));
            this.done(task, 'error');
          } else {
            setTimeout(poll, this.options.interval);
          }
        };

        poll();
      });
  }

  private start(task: PromisePoolTask<any>) {
    task.state = 'started';
    this.runningTasksCount++;
    // console.log(`task ${task.id} [${task.state}]`, `runningTasks=${this.runningTasksCount}`);
  }

  private done(task: PromisePoolTask<any>, state: PromisePoolTaskState) {
    task.state = state;
    this.runningTasksCount--;
    // console.log(`task ${task.id} [${task.state}]`, `runningTasks=${this.runningTasksCount}`);
  }
}