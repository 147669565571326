import _ from 'lodash';

export const styleCssToReact = (style) => style.split(';').filter(s => s.length)
  .reduce((a, b) => {
    const keyValue = b.split(':');
    a[_.camelCase(keyValue[0])] = keyValue[1];
    return a;
  }, {});

export const styleReactToCss = (object) => {
  let result = '';
  _.map(object, (value, key) => {
    result += `${_.kebabCase(key)}:${value};`
  });
  return result;
};