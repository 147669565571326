import { normalize } from '@eptica/js-utils';
import _ from 'lodash';
import {
  getBarChartDataset,
  getLineChartDataset,
  getTimeSale,
  getYAxis,
  getYMinMax,
  getYValue,
  Y_AXIS_DEFAULT_CONFIG
} from './chartUtils';
import { ReactNode } from "react";
import { ChartBuilder } from "./ChartBuilder";
import Color from 'color';
import { services } from "../../application/service/services";
import { TFunction } from "i18next";
import { Legend, LegendItem, TooltipItem, } from "chart.js";

export enum ChartType {
  TIME_LINE = 'timeLine',
  TIME_BAR = 'timeBar'
};

const labelFontSize = 18;
export type CoordinatedData = { x: any, y: any, percentage?: number, label?: string, serie?: string, group?: string };

export type SerieData = Array<{ x: any, value: Data | MultiSerieData }>;
export type ChartSerieData = Array<number | null | undefined | CoordinatedData> | undefined;
export type Data = { "@type": string, value: number };
export type MultiSerieData = { "@type": string, values: Data, totalElement: number };

export interface ChartSerie {
  stacked?: boolean,
  stack?: string,
  label: string,
  gridLines?: boolean,
  color?: string,
  yAxisId?: string,
  type: ChartType,
  data: ChartSerieData,
  tooltipRenderer: (value: any, tooltipItem: TooltipItem<any>) => ReactNode,
  parsing?: { yAxisKey: string }
}

export interface Serie {
  name: string,
  field?: string,
  percentageMode?: boolean,
  totalElement?: number,
  stacked?: boolean,
  label: string,
  gridLines?: boolean,
  color?: string,
  yAxisId?: string,
  type: ChartType,
  data: SerieData | ChartSerieData,
  tooltipRenderer: (value: any, tooltipItem?: TooltipItem<any>) => ReactNode
}


export interface YAxis {
  stacked?: boolean,
  id?: string,
  autoAdapted?: boolean | { min: number, max: number },
  min?: any,
  max?: any
  tickLabelCallback?: (value: number | string, index?: number, values?: number[] | string[]) => string | number | null | undefined,
}

export class SerieChartBuilder extends ChartBuilder {

  private static otherKey = "dashboard.viz.distributionChart.group.other";

  _series: Serie[] = [];
  private chartSeries: ChartSerie[];
  _yAxis: YAxis = {};
  private readonly t: TFunction;
  private displayedItems: Set<string> = new Set();
  private serieIndexByGroup: { [key: string]: number } = {};
  private othersIndex: Set<Number> = new Set();
  private hasTimeLineSerie: boolean = false;
  private lastContextMenuEvent: any;
  private extractedSeries: Map<string, Map<string, string>>;

  constructor(t: TFunction) {
    super('bar');
    this.t = t;
  }

  private legendOnClickHandler = (event, legendItem, legend) => {
    if (this.othersIndex.has(legendItem.datasetIndex)) return;
    const ci = legend.chart;

    if (!legendItem.hidden) {
      this.displayedItems.delete(legendItem.text);
    } else {
      this.displayedItems.add(legendItem.text);
    }
    //remove other items from displayedItems as it will be re-computed
    this.othersIndex.forEach((value, value2, set) => {
      if (!this.displayedItems.has(this.chartSeries[value.valueOf()].label)) {
        this.displayedItems.delete(this.chartSeries[value.valueOf()].label);
      }
    });

    this.computeSeries()

    //add present other series
    this.othersIndex.forEach((value, value2, set) => {
      if (!this.displayedItems.has(this.chartSeries[value.valueOf()].label)) {
        this.displayedItems.add(this.chartSeries[value.valueOf()].label);
      }
    });

    ci.data.datasets = this.computeDatasets();
    ci.setDatasetVisibility(legendItem.datasetIndex, legendItem.hidden);
    ci.update();
  }

  private contextMenuOnClickHandler = (chart, args, pluginOptions) => {
    const event = args.event;
    if (event.type === 'contextmenu') {
      //update methode in legendOnClickHandler re throw contextMenu event. Add the following line to prevent loop
      if (this.lastContextMenuEvent === event) return;
      this.lastContextMenuEvent = event;

      const legendItem = chart.legend._getLegendItemAt(args.event.x, args.event.y);
      if (legendItem) {
        // hide all legend item except other
        chart.legend.legendItems.forEach((legendItem, index) => {
          this.displayedItems.delete(legendItem.text);
          chart.setDatasetVisibility(legendItem.datasetIndex, false);
        });
        legendItem.hidden = true;
        this.legendOnClickHandler(args.event, legendItem, chart.legend);
        return true;
      }
    }
  }


  private legendItemFilter = (legendItem: LegendItem, chartData) => {
    return chartData.datasets.map(dataset => dataset.label).lastIndexOf(legendItem.text) === legendItem.datasetIndex;
  }

  withSerie(serie: Serie) {
    this._series.push(serie);
    return this;
  }

  withYAxis(yAxis: YAxis) {
    this._yAxis = yAxis;
    if(yAxis) {
      this._yAxis.id = this._yAxis.id ? this._yAxis.id : 'yAxes';
    }
    return this;
  }

  displayLabelsInline(displayLabelsInline) {
    this._displayLabelsInline = displayLabelsInline;
    return this;
  }

  build() {
    if (_.isEmpty(this._series)) {
      return null;
    }

    this.extractSeries();

    //displayedItems is initialized because computeSeries use it to compute others series. All series are displayed by default
    this.displayedItems = new Set<string>();
    this.extractedSeries.forEach((value, key, map) => {
      value.forEach((label, group, map2) => {
        this.displayedItems.add(label)
      });
    })
    this.computeSeries();

    this.chartSeries.forEach((chartSerie, index) => {
      this.serieIndexByGroup[chartSerie.label] = index;

      //add to displayedItems because other series are not present.
      this.displayedItems.add(chartSerie.label)
    });

    const chartObject = super.build();
    const chartOptions: any = chartObject.options;

    const chartData: any = {
      datasets: this.computeDatasets()
    };

    chartOptions.plugins.tooltip.mode = 'point';
    if (chartOptions.plugins.legend && chartOptions.plugins.legend.display) {
      chartOptions.plugins.legend.onClick = this.legendOnClickHandler;
      chartOptions.plugins.legend.labels = {
        font: {
          size: 14,
          family: this.getFont()
        },
        boxHeight: 0,
        filter: this.legendItemFilter,
        generateLabels(chart: any): LegendItem[] {
          const test = Legend['defaults'].labels.generateLabels(chart);
          test.forEach(legend => {
            if (chart.data.datasets[legend.datasetIndex].type && chart.data.datasets[legend.datasetIndex].type == 'line') {
              legend.pointStyle = 'line';
              legend.strokeStyle = legend.fillStyle;
              legend.lineWidth = 4;
            } else {
              legend.pointStyle = 'line';
              legend.strokeStyle = legend.fillStyle;
              legend.lineWidth = 15;
            }
          });
          return test
        }
      };
    }

    /* manage xAxes */

    chartOptions.scales = {
      xAxes: { ...getTimeSale(this.chartSeries[0]), stacked: true },
    };

    /* manage yAxes */

    const axisKeys = [...new Set(this.chartSeries.map(serie => serie.yAxisId))];

    const drawYaxis = axisKeys.indexOf(this._yAxis.id) >= 0;
    if (axisKeys.indexOf('percentage') >= 0) {
      chartOptions.scales['percentage'] = {
        ...Y_AXIS_DEFAULT_CONFIG, min: 0, max: 100, stacked: true,
        position: drawYaxis ? 'right' : 'left',
        title: {
          display: true,
          text: '%'
        },
        grid: {
          drawOnChartArea: drawYaxis ? false : true, // only want the grid lines for one axis to show up
        },
      };
    }

    if (drawYaxis) {
      chartOptions.scales[this._yAxis.id] = getYAxis(this._yAxis, this.chartSeries);
    }

    if (this._displayLabelsInline) {
      chartOptions.plugins.datalabels = {
        ...chartOptions.plugins.datalabels,
        ...{
          color: (context): Color => {
            if (context.dataset.type === 'bar') {
              return 'white';
            } else {
              return context.dataset.borderColor
            }
          },
          formatter: (value, context) => {
            let result = value.y;
            if (context.dataset.parsing) {
              const parsedValue = _.get(value, context.dataset.parsing.yAxisKey)
              result = parsedValue ? parsedValue.toFixed(0) : null;
              if (result === null) return "";
              if (context.dataset.parsing.yAxisKey === 'percentage') {
                result = result + "﹪";
              }
            }
            return result;
          }
        }
      }

      const dataMinMax = getYMinMax(this.chartSeries);

      const axisMinMax = {
        min: _.isNil(this._yAxis.min) ? dataMinMax.min : this._yAxis.min,
        max: _.isNil(this._yAxis.max) ? dataMinMax.max : this._yAxis.max,
      }

      // normalize on //0,100
      const normalizedAxisBounds = { min: -100, max: 100 };
      const normalizedDataBounds = {
        min: normalize(dataMinMax.min, axisMinMax.min, axisMinMax.max, normalizedAxisBounds.min, normalizedAxisBounds.max),
        max: normalize(dataMinMax.max, axisMinMax.min, axisMinMax.max, normalizedAxisBounds.min, normalizedAxisBounds.max)
      };

      /***********  Add a padding top in case of heigh value for which part of the label is outside the canvas **************/
      const minTopPadding = 5;
      let padding = minTopPadding + Math.max(0, normalizedDataBounds.max - normalizedAxisBounds.max + labelFontSize + 3);
      if (this.hasTimeLineSerie) {
        let legendPadding = padding;
        chartObject.plugins.push({
          beforeInit: function (chart, options) {
            // Get reference to the original fit function
            const originalFit = chart.legend.fit;

            chart.legend.fit = function () {
              // Call original function and bind scope in order to use `this` correctly inside it
              originalFit.bind(chart.legend)();
              // Change the height
              this.height = this.height + legendPadding;
            };
          }
        });
        padding = 0;
      }
      chartOptions.layout.padding = { top: padding }

      const minBottomPadding = 0;
      chartOptions.scales.xAxes.ticks.padding = minBottomPadding + Math.max(0, normalizedAxisBounds.min - normalizedDataBounds.min + labelFontSize + 3);
    }

    chartOptions.events = ['contextmenu', 'click', 'mouseout', 'mousemove', 'touchstart', 'touchmove'];
    chartObject.plugins.push({
      id: 'myEventCatcher',
      beforeEvent: this.contextMenuOnClickHandler.bind(this)
    });

    //plugins to disable contextmenu
    chartObject.plugins.push({
      afterInit: (chart) => {
        chart.canvas.addEventListener('contextmenu', handleContextMenu, false);

        function handleContextMenu(e) {
          e.preventDefault();
          e.stopPropagation();
          return (false);
        }
      }
    });


    return { ...chartObject, data: chartData };
  }


  private computeDatasets() {

    this.hasTimeLineSerie = false;
    const seriesTypeByNames = {}

    this.chartSeries.forEach(serie => {
      const serieType = serie.data.length < 4 ? serie.type = ChartType.TIME_BAR : serie.type;

      if (!seriesTypeByNames[serie.stack] || seriesTypeByNames[serie.stack] == ChartType.TIME_BAR) {
        seriesTypeByNames[serie.stack] = serieType;
      }
    });

    return this.chartSeries.map(serie => {
      const isVisible = this.displayedItems.has(serie.label);
      const serieType = seriesTypeByNames[serie.stack];

      if (serieType.toUpperCase() === ChartType.TIME_LINE.toUpperCase()) {
        this.hasTimeLineSerie = true;
        return { ...getLineChartDataset(serie), hidden: !isVisible, parsing: serie.parsing };
      }
      if (serieType.toUpperCase() === ChartType.TIME_BAR.toUpperCase()) {
        return {
          ...getBarChartDataset(serie),
          hidden: !isVisible,
          parsing: serie.parsing,
          categoryPercentage: 0.9,
          barPercentage: 0.9
        };
      }

      throw new Error(`Unsupported type ${serie.type}`);
    });
  }

  /**
   * Convert the list of Series into a list of ChartSerie.
   * A Serie (vecko object) can result in multiple ChartSerie (serie given to chartjs)
   * Ex: Serie on field "topics" will lead to x ChartSerie, one for each topics
   *
   * @private
   */
  private computeSeries() {
    const otherSeries = [];
    this.othersIndex = new Set();
    this.chartSeries = this._series.flatMap((serie, index) => {
      const chartSeries = [];
      if (serie.data.length === 0) return null;

      if (this.isSerieData(serie)) {
        const data: SerieData = serie.data as SerieData;
        const extractedSerieValues = this.extractedSeries.get(serie.name);
        if (extractedSerieValues.size > 0) {
          //graph contains multivalued serie
          const otherChartData: Array<CoordinatedData> = [];
          const otherGroupLabel = this.t(SerieChartBuilder.otherKey);
          extractedSerieValues.forEach((serieLabel, serieValue, map) => {

            const chartData: ChartSerieData = data.map((serieData, i) => {
              const multiSerieData = (serieData.value as MultiSerieData);
              const { value ,...serieDataWithoutValue} = serieData;
              const yValue = multiSerieData.values[serieValue] ? multiSerieData.values[serieValue].value : null;
              let otherChartDataValue = otherChartData.find(value => value.x === serieData.x);
              if (!otherChartDataValue) {
                otherChartData.push({
                  ...serieDataWithoutValue,
                  x: serieData.x,
                  y: multiSerieData.totalElement,
                  percentage: 100,
                  label: otherGroupLabel,
                  group: otherGroupLabel,
                  serie: serie.name
                })
                otherChartDataValue = otherChartData.find(value => value.x === serieData.x);
              }
              if (this.displayedItems.has(serieLabel)) {
                //if the serie is a displayed one, other value is decremented with this serie's value
                otherChartDataValue.y -= yValue;
                otherChartDataValue.percentage = otherChartDataValue.y ? otherChartDataValue.y * 100 / multiSerieData.totalElement : null;
              }
              return {
                ...serieDataWithoutValue,
                x: serieData.x,
                y: yValue,
                percentage: multiSerieData.values[serieValue] ? yValue * 100 / multiSerieData.totalElement : null,
                label: serieLabel,
                serie: serie.name,
                group: serieValue
              }
            });
            /* add a serie for other values. This serie contains all the counts off the values that are not returned by the back + the counts of the hidden series */
            const chartSerie = this.prepareChartSerie(serie, chartData, services.getColorService().getColorForFieldValue(serie.field, serieValue), serieLabel);
            chartSeries.push(chartSerie);
          })
          //otherSerie
          const otherSerieData = otherChartData.filter(value => (value as CoordinatedData).y > 0);
          if (otherSerieData.length > 0) {
            const otherChartSerie = this.prepareChartSerie(serie, otherSerieData, services.getColorService().getOtherColor(), otherGroupLabel);
            otherSeries.push(otherChartSerie);
            chartSeries.push(otherChartSerie);
          }
        } else {
          const chartData: ChartSerieData = data.map((d, i) => {
          const { value ,...serieDataWithoutValue} = d;
           return {
            ...serieDataWithoutValue,
            x: d.x,
            y: (d.value as Data).value,
            label: serie.label,
            serie: serie.name,
            group: null
          }});
          const chartSerie = this.prepareChartSerie(serie, chartData, serie.color, serie.label);
          chartSeries.push(chartSerie);
        }
      } else {
        chartSeries.push(this.prepareChartSerie(serie, serie.data as ChartSerieData, serie.color, serie.label));
      }
      return chartSeries
    });

    //compute otherSeries indexes
    otherSeries.map(otherSerie => this.othersIndex.add(this.chartSeries.indexOf(otherSerie)));
  }

  private getSerieLabel(serie: Serie, extractedSerie: string) {
    return this._series.length > 1 ? serie.label + ' (' + extractedSerie + ')' : extractedSerie;
  }

  private isSerieData(serie: Serie) {
    return serie.data[0]['value'];
  }

  /**
   * Convert the Serie (vecko object) to a ChartSerie (serie given to chartjs)
   *
   * @param serie
   * @param serieData
   * @param color
   * @param label the label to apply to the chartSerie
   * @private
   */
  private prepareChartSerie(serie: Serie, serieData: ChartSerieData, color: string, label: string): ChartSerie {
    const chartserie: ChartSerie = {
      stacked: serie.stacked,
      stack: serie.name,
      label: label,
      gridLines: serie.gridLines,
      color: color || serie.color,
      yAxisId: serie.yAxisId || this._yAxis.id,
      type: serie.type,
      data: serieData,
      tooltipRenderer: null
    };

    chartserie.tooltipRenderer = serie.tooltipRenderer.bind(chartserie);
    if (serie.percentageMode) {
      chartserie.parsing = { yAxisKey: 'percentage' }
      chartserie.yAxisId = 'percentage';
    }
    return chartserie;
  }


  /**
   * Extract the series'name from de SerieData if it contains multiseries data
   * @param data
   * @private
   */
  private extractSeries(): void {
    this.extractedSeries = new Map();
    this._series.forEach(serie => {
      this.extractedSeries.set(serie.name, new Map<string, string>())
      if (this.isSerieData(serie)) {
        const labelFieldName = serie.field ? services.getFieldsService().getField(serie.field).name : serie.label;
        serie.data.forEach(serieValue => {
          const type = serieValue.value['@type'];
          if (type === 'multi') {
            Object.keys((serieValue.value as MultiSerieData).values).forEach(serieGroup => {
              if (!this.extractedSeries.get(serie.name).has(serieGroup)) {
                const fieldValue = services.getFieldsService().findFieldValue(labelFieldName, serieGroup);
                const serieGroupLabel = this.getSerieLabel(serie, fieldValue ? fieldValue.getLabel(this.t) : serieGroup);
                this.extractedSeries.get(serie.name).set(serieGroup, serieGroupLabel);
              }
            });
          }
        });
      } else {
        this.extractedSeries.get(serie.name).set(serie.label, serie.label);
      }
    })
  }

  tooltipRenderer(tooltipItem: TooltipItem<any>): (value: any, tooltipItem: TooltipItem<any>) => ReactNode {
    return this.chartSeries[tooltipItem.datasetIndex].tooltipRenderer;
  }

  tooltipValueTransformer(value: any): any {
    return getYValue(value);
  }
}