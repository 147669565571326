
export const identitySupplier: <T>(obj: T) => T = <T>(obj: T) => obj;
export const trueSupplier: BooleanSupplier = () => true;
export const falseSupplier:BooleanSupplier = () => false;
export const noop = ():any => {}
export const defaultEqualComparator = <T>(a:T, b:T):boolean => a === b;
export const equalComparator = <T extends WithEqual>(a:T, b:T):boolean => {
  if (a === b) {
    return true;
  }
  if (a === null || a === undefined) {
    return b === null || b === undefined;
  }
  a.equals(b);
}