import { VeckoColors } from "../../../style/VeckoColors";
import _ from "lodash";
import Color from "color";

export interface VizThemeDefinition {
  color: string,
  dark: boolean
}

export interface VisualizationTheme {
  color: string,
  isDark: boolean
}

const DARK_VIZ_THEME: VisualizationTheme = {
  color: VeckoColors.DARK_THEME_BG,
  isDark: true
};

const WHITE_VIZ_THEME: VisualizationTheme = {
  color: VeckoColors.WHITE,
  isDark: false
};

export const getVisualizationTheme = (themeDefinition: VizThemeDefinition): VisualizationTheme => {
  const color = _.get(themeDefinition, ['color']);
  const isDark = _.get(themeDefinition, ['dark']);

  if (!_.isNil(color)) {
    return {
      color: color,
      isDark: isDark === true ? isDark : Color(color).isDark()
    };
  }

  if (isDark === true) {
    return DARK_VIZ_THEME;
  }

  return WHITE_VIZ_THEME;
};