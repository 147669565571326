import { Tonality } from '@eptica/vecko-js-commons';
import classNames from 'classnames';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import React from 'react';
import { ColoredProgressBar } from '../../../component/progressBar/ColoredProgressBar';
import './satDistribution.scss';

interface SatDistributionProps  {
  className?: string,
  distribution: any,
  total: number,
  showNeutral?: boolean,
  showPercent?: boolean,
  showVolume?: boolean,
};

export class SatDistribution extends React.PureComponent<SatDistributionProps> {

  static defaultProps = {
    showNeutral: true,
    showPercent: true,
    showVolume: false
  };

  renderPercent(volume) {
    const { total } = this.props;
    if (total === 0) return '-';
    return _.round(volume / total * 100, 2) + '%';
  }

  getTonalities() {
    const { showNeutral } = this.props;
    const tonalities = [
      Tonality.NEGATIVE
    ];
    if (showNeutral) {
      tonalities.push(Tonality.NEUTRAL);
    }
    tonalities.push(Tonality.POSITIVE);
    return tonalities;
  }

  render() {
    const { className, distribution, total, showPercent, showVolume } = this.props;

    const clazz = classNames('satDistribution', className);
    const style = (!showPercent && !showVolume) ? {
      paddingBottom: 3
    } : null;

    return <div className={clazz} style={style}>
      {
        this.getTonalities()
          .map(tonality => {
            const volume = distribution[tonality.name()];
            const ratio = total === 0 ? 0 : volume / total;

            return <React.Fragment key={tonality.name()}>
              <div className='bar'>
                <ColoredProgressBar value={ratio} color={tonality.color}/>
              </div>
              {
                showPercent ? <div className='percent'>{this.renderPercent(volume)}</div> :  null
              }
              {
                showVolume ? <div className='volume'>{volume}</div> : null
              }
            </React.Fragment>;
          })
      }
    </div>;
  }
}