export const authMockData = [
    "c-vecko-user",
    "c-vecko-admin",
    "vecko.report.export",
    "vecko.report.data.get",
    "vecko.data.read",
    "vecko.ui.data.read",
    "vecko.topics.analysis",
    "vecko.topics.stats",
    "vecko.sequence.summarize",
    "vecko.data.write",
    "vecko.public.kb.qa",
    "vecko.feedback.export",
    "vecko.data.read",
    "vecko.data.write",
    "vecko.data.delete",
    "vecko.sourcing.mirror",
    "vecko.sourcing.counters",
    "vecko.sourcing.push_file",
    "vecko.sourcing.push_audio",
    "vecko.sourcing.push_raw",
    "vecko.sourcing.cancel",
    "vecko.sourcing.replay",
    "vecko.sourcing.delete",
    "vecko.data.reindex",
    "vecko.sourcing.cleanup",
    "vecko.sourcing.errors",
    "vecko.sourcing.logs",
    "vecko.configuration.push",
    "vecko.configuration.validate",
    "vecko.export",
    "vecko.internal.configuration.validate",
    "vecko.di.interval",
    "vecko.di.api",
    "vecko.di.vecko",
    "vecko.feedback.stats",
    "vecko.cache.invalidate",
    "vecko.feedback.purge",
    "vecko.errors.purge",
    "vecko.admin.data.multitenant",
    "vecko.admin.tenant.get",
    "vecko.admin.tenant.list",
    "vecko.admin.tenant.clear",
    "vecko.feedback.debug",
    "vecko.ui.feedbacksExplorer.showTotal",
    "vecko.storage.get",
    "vecko.storage.download",
    "vecko.storage.upload",
    "vecko.storage.delete",
    "vecko.private.cqc.score",
    "c-vecko-cqc-api"
  ];