import React from 'react';
import './actionPlanViz.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import { BubbleChart } from '../../components/bubbleChart/bubbleChart';
import { ActionPlanDatasetBuilder } from './actionPlanDataset';
import { SatTypeEnum } from "../../../../application/model/SatTypeEnum";
import { ClickableViz } from "../ClickableViz";
import { FieldValue } from "../../../../application/model/field/FieldValue";
import { FieldVizFeedbacksQueryContext, VizFeedbacksQueryContext } from "../../../model/viz/VizFeedbacksQueryContext";
import { services } from "../../../../application/service/services";
import { connect } from "react-redux";


interface ActionPlanVizProps extends WithTranslation {
  viz: VisualizationInstance,
  openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => void
}

interface StateProps {
  clickedItem: any
}


export class ActionPlanVizComponent extends React.PureComponent<ActionPlanVizProps> implements ClickableViz {

  doubleClickedElement: any;
  state: StateProps = {
    clickedItem: null,
  }

  getClickedFieldValue(): FieldValue {
    if (this.state.clickedItem) {
      return services.getFieldsService().findFieldValue('enhancement.categories.topic', this.state.clickedItem.category);
    }
    return null;
  }

  getFeedBacksQueryContext(): VizFeedbacksQueryContext {
    const result = new FieldVizFeedbacksQueryContext();
    if (this.state.clickedItem) {
      const { viz } = this.props;
      result.field = 'enhancement.categories.topic';
      result.selectedValue = this.state.clickedItem.category;
    }
    return result;
  }

  onClick(event, item: any) {
    event.preventDefault();
    event.stopPropagation();
    this.state.clickedItem = item.raw.raw;
    const { t } = this.props;
    this.props.openFeedbacksPanel(this, t(`enhancement.categories.topic/` + item.raw.raw.category, item.raw.raw.category) as string);
  }

  render() {
    let { viz, t } = this.props;
    const satType = SatTypeEnum.valueOf(viz.definition.params.satType);
    const actionPlanDataset = new ActionPlanDatasetBuilder(viz.data, satType).build(t);
    if (!viz.data.selection) {
      actionPlanDataset.keepOnlyBestPoints();
    } else {
      actionPlanDataset.keepOnly(viz.data.selection);
    }

    return <div className="action-plan">
      <div className='action-plan-bubble-chart-container'>
        <BubbleChart dataset={actionPlanDataset.bubbleChartDataset}
                     onClick={this.onClick.bind(this)}/>
      </div>
    </div>;
  }
}


const mapDispatchToProps = (dispatch, props) => {
  return {
    openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => {
      dispatch.dashboard.openFeedBackPanel({ viz, panelTitle });
    },
  }
};


export const ActionPlanViz = withTranslation(undefined, { withRef: true })(connect(null, mapDispatchToProps, null, { forwardRef: true })(ActionPlanVizComponent));


