import React from "react";
import { TFunction } from "i18next";
import { services } from "../../application/service/services";
import { Field } from "../../application/model/field/Field";
import { BarChartBuilder, DataSet } from "./BarChartBuilder";
import { Sort } from "./ChartBuilder";

export class DistributionChartBuilder extends BarChartBuilder {
  private sortByTerms: boolean;
  private order: Sort;
  private hideOtherDataset: boolean;

  constructor(viz: any, field: Field, t: TFunction) {
    super(viz, field, t);
    this.sortByTerms = this.viz.definition?.uiParams?.sortByTerms;
    this.order = this.viz.definition?.uiParams?.order || Sort.ASC;
    this.stacked = true;
    this.displayVerticalGrid = false;
    this.hideOtherDataset = this.viz.definition?.uiParams?.hideOtherCategory ?? true;
  }

  protected computeDatasetsAndLabels() {
    const datasets: DataSet[] = [];
    const labels = [];
    let index = 0;
    const otherCount = this.computeOther();
    const voidArray = [];

    let areTermsNumerical = true;
    this.data.items.forEach(value => areTermsNumerical &&= !isNaN(+value.label));
    const sortFn = areTermsNumerical ? (a, b) => parseFloat(a.label) - parseFloat(b.label) : (a, b) => (this.order === Sort.ASC ? 1 : -1) * ("" + a.label).localeCompare("" + b.label);
    const items = this.sortByTerms ?
      this.data.items.sort(sortFn) :
      this.data.items;
    items.forEach((entry) => {
      const labelWithCount = this.getLabelWithCount(entry);
      const hidden = this.displayedItems.filter(displayedItem => displayedItem === labelWithCount).length === 0
      const newDataset = {
        label: labelWithCount,
        data: hidden ? voidArray : voidArray.concat({
          ...entry,
          percentage: entry.value * 100 / this.data.totalElement,
          x: 0,
          y: 0
        }),
        backgroundColor: [services.getColorService().getColorForFieldValue(this.viz.definition.spec.params.field, entry.group)],
        parsing: { [this.parsingKey]: this.percentageMode ? 'percentage' : 'value' },
        hidden: this.displayedItems.filter(displayedItem => displayedItem === labelWithCount).length === 0,
      };

      if (!hidden) {
        labels.push(entry.label);
        voidArray.push({ value: null, percentage: null });
      }
      datasets.push(newDataset);
      index++;
    });

    if(!this.hideOtherDataset){
        this.otherIndex = datasets.length;
        this.datasetIndexByGroup[BarChartBuilder.otherKey] = index;
        this.otherGroupPresent = false;
        if (otherCount !== 0) {
          this.otherGroupPresent = true;
          const otherData = {
            group: this.getOtherGroupName(),
            label: this.getOtherGroupName(),
            value: otherCount,
            percentage: otherCount * 100 / this.data.totalElement,
            x: 0,
            y: 0
          };
          const otherDataset = {
            label: this.getLabelWithCount(otherData),
            data: voidArray.concat(otherData),
            backgroundColor: [services.getColorService().getOtherColor()],
            parsing: { [this.parsingKey]: this.percentageMode ? 'percentage' : 'value' }
          };
          datasets.push(otherDataset);
          labels.push(otherData.label);
        }
    }
    return { datasets: datasets, labels: labels };
  }

  getDataToExport(): any[][] {
    const dataToExport = [];
    //Add Header
    const header = [];
    const datasetAndLabels = this.computeDatasetsAndLabels();
    header.push(this.metricFieldLabel);
    header.push(this.t('dashboard.viz.pieChart.tooltip.count'));
    header.push(this.t('dashboard.viz.pieChart.tooltip.percentage'));
    dataToExport.push(header);

    //Add value
    datasetAndLabels.datasets
      .filter(value => !value.hidden)
      .forEach((value, index) => {
        const lineToExport = [];
        lineToExport.push(datasetAndLabels.labels[index]);
        lineToExport.push(value.data[value.data.length - 1].value);
        lineToExport.push(value.data[value.data.length - 1].percentage?.toFixed(2));
        dataToExport.push(lineToExport);
      })
    return dataToExport;
  }


}