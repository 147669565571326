import { parseFilter } from '../../utils/query/filter/parseFilter';
import { services } from './services';
import { SatType, SatTypeEnum } from "../model/SatTypeEnum";
import _ from "lodash";
import { ApiFilter } from "../../utils/query/filter/ApiFilter";
import { ExistsFilter } from "../../utils/query/filter/ExistsFilter";
import { BoolFilter } from "../../utils/query/filter/BoolFilter";
import { DistributionKind } from "../model/distribution/DistributionKind";
import { TonalitiesDistribution } from "../model/distribution/TonalitiesDistribution";
import { equalsInAnyOrder, toArray } from "../../utils/collectionUtils";
import { StandardMetadataEnum } from "../model/field/StandardMetadataEnum";
import { NoneFieldValue } from "../model/field/NoneFieldValue";
import { ChannelKindEnum } from "../model/ChannelKindEnum";
import { FieldValue } from "../model/field/FieldValue";
import { SatTypeDistribution } from "../model/distribution/SatTypeDistribution";

export class SatService {
  private _satTypes: Array<SatType>;
  private _satConfigurations: { [id: string]: SatConfiguration } = {};

  init() {
    const satConf = services.getConfigurationService().uiConfiguration.sat || {};
    this._satTypes = Object.keys(satConf).map(o => SatTypeEnum.valueOf(o));
    this._satConfigurations = Object.fromEntries(
        Object.entries(satConf)
            .map(([k, v]: [string, { filter: Object }]) => {
              return [k, SatConfiguration.parse(k, v)];
            })
    );
  }

  getSatTypes(): Array<SatType> {
    return this._satTypes;
  }

  hasSatTypes(): boolean {
    return !_.isEmpty(this._satTypes);
  }

  getSatFilter(satType: SatType): ApiFilter {
    return this._satConfigurations[satType.name].filter;
  }

  getDistributionKinds(): Array<DistributionKind> {
    const result: Array<DistributionKind> = [TonalitiesDistribution.get()];

    if (!this.hasSatTypes()) {
      return result;
    }

    let shouldAddSatTypeDistributions = false;

    const availableChannelKinds = toArray(services.getFieldsService().getFieldValues(StandardMetadataEnum.CHANNEL_KIND.fieldName).values)
        .filter(fieldValue => !(fieldValue instanceof NoneFieldValue))
        .map(fieldValue => fieldValue.name)
        .map(str => ChannelKindEnum.valueOf(str));

    if (equalsInAnyOrder(availableChannelKinds, [ChannelKindEnum.SURVEY])) {
      shouldAddSatTypeDistributions = true;
    } else {
      const selectedChannelKinds = toArray(services.getFilterService().getValue<FieldValue>(StandardMetadataEnum.CHANNEL_KIND.fieldName))
          .map(fieldValue => fieldValue.name)
          .map(str => ChannelKindEnum.valueOf(str));

      if (equalsInAnyOrder(selectedChannelKinds, [ChannelKindEnum.SURVEY])) {
        shouldAddSatTypeDistributions = true;
      }
    }

    if (shouldAddSatTypeDistributions) {
      this.getSatTypes().forEach(satType => {
        result.push(SatTypeDistribution.get(satType));
      });
    }

    return result;
  }
}

services.registerService('satService', new SatService());


class SatConfiguration {
  readonly satType:SatType;
  readonly filter:ApiFilter;

  static parse(key:string, object:any):SatConfiguration {
    const scoreField = object.scoreField;
    if (_.isNil(scoreField)) {
      throw new Error('scoreField property is mandatory for a sat configuration');
    }
    const scoreFieldExists = new ExistsFilter(scoreField);
    const filter = object.filter ? BoolFilter.must(scoreFieldExists, parseFilter(object.filter)) : scoreFieldExists

    return new SatConfiguration(SatTypeEnum.valueOf(key), filter);
  }

  constructor(satType, filter) {
    this.satType = satType;
    this.filter = filter;
  }
}
