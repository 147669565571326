import '../../administration/service/administrationService';
import '../../report/service/dashboardExportService';
import '../../report/service/dashboardParamsService';
import '../../report/service/dashboardService';
import {MessageHandler} from '../../utils/MessageHandler';
import '../fetch/fetcherService';
import {createMainNav} from '../model/nav/mainNav';
import { createReduxHistoryFn, createStore } from '../store/store';
import './analysisService';
import './configurationService';
import './feedbackService';
import './fieldsService';
import './filterConverterService';
import './filterService';
import './i18nService';
import './satService';
import './securityService';
import './sequencesService';
import './timeZoneService';
import './topicsStatService';
import './urlService';
import '../../utils/styling/ColorService';
import {Nav} from "../../component/nav/Nav";
import _ from "lodash";
import {IMessage} from "../view/message/Message";
import { History } from "history";

interface IView {
    path: string,
    stateSliceName?: string
}

type ViewsType = { [viewName: string]: IView };

export abstract class AbstractApplicationService {
    protected _store;
    protected _views: ViewsType;
    protected _messageHandler = new MessageHandler();
    protected _mainNav: Nav;
    private _history: History & { listenObject: boolean };

    abstract initServices();

    async init() {
        this._views = {
            topicsStat: {
                path: 'topics',
                stateSliceName: 'topicsStat'
            },
            analysis: {
                path: 'analysis',
                stateSliceName: 'analysis'
            },
            feedbacks: {
                path: 'feedbacks',
                stateSliceName: 'feedbacks'
            },
            dashboard: {
                path: 'vecko-dashboard',
                stateSliceName: 'dashboard'
            },
            report: {
                path: 'report',
            }
        };

        await this.initServices();

        this._mainNav = createMainNav();

        this._store = createStore();
        this._history = createReduxHistoryFn(this.store);
    }

    get store() {
        return this._store;
    }

    get history() {
        return this._history;
    }

    get views(): ViewsType {
        return this._views;
    }

    get mainNav(): Nav {
        return this._mainNav;
    }

    getViewIdByUrlPathname(urlPathname: string): string {
        const path = urlPathname.split('/')[1];
        const viewEntry = Object.entries(this._views).find(([k, v]) => v.path === path);
        if (_.isNil(viewEntry)) {
            return null;
        }
        return viewEntry[0];
    }

    getView(id: string): IView {
        return this._views[id];
    }

    notifyMessage(message: IMessage): void {
        this._messageHandler.notify(message);
    }

    addMessageHandler(handler: (message: IMessage) => void): void {
        this._messageHandler.addHandler(handler);
    }

    removeMessageHandler(handler: (message: IMessage) => void): void {
        this._messageHandler.removeHandler(handler);
    }
}
