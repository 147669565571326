import axios from "axios";

const summarize = (args) => {
  const json = JSON.stringify(args);
  return axios
    .post('/api/ui/sequence/summarize', json, {
      headers: { 'Content-type': 'application/json'  }
    })
    .then(response => response.data);
}

export default { summarize };