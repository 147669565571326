import React from 'react';
import { QueryStringInput } from '../../component/input/QueryStringInput';
import { AbstractFilterCtrl, TagValue } from './AbstractFilterCtrl';
import { stateConverterRegistry } from './stateConverters/stateConverterRegistry';
import { StateConverter } from "./stateConverters/StateConverter";
import { TFunction } from "i18next";
import _ from "lodash";

export class QueryStringFilterCtrl extends AbstractFilterCtrl<string> {
  private _value:string = '';

  constructor(id) {
    super(id);
  }

  getValue():string {
    return this._value;
  }

  setValue(value:string) {
    if(_.isArray(value)){
      const array  = value as unknown as Array<string>;
      if(array.length>1) throw new Error("cannot set multiple values to string filter.")
      value = array[0];
    }
    this._value = value;
  }

  uiComponent(t:TFunction, onChanged, options):JSX.Element {
    return <QueryStringInput key={this.id} icon={'search'} value={this._value}
                             onApply={onChanged}
                             {...options}
    />;
  }

  stateConverter():StateConverter<string> {
    return stateConverterRegistry.get(String) as StateConverter<string>;
  }

  getTagValues(t: TFunction): Array<TagValue<string>> {
    return undefined;
  }
}