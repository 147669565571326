import _ from 'lodash';
import React from 'react';
import { registerVizType } from '../../../model/viz/visualizationTypes';
import { getTitle } from '../common';
import { ActionListEditor } from './actionListEditor';
import { EmptyActionList } from './actionListEmpty';
import { ActionListViz } from './actionListViz';

const titleTrans = (vizInstance, key, t) => {
  const title = getTitle(vizInstance, t);
  return title ? title : key;
};
registerVizType({
    name: 'ACTION_LIST',
    component: ActionListViz,
    noDataPolicy: {
      component: EmptyActionList
    },
    title: (vizInstance, t) => titleTrans(vizInstance, 'dashboard.viz.actionList.title', t),
    editor: {
      component: ActionListEditor,
      title: (vizInstance, t) => titleTrans(vizInstance, 'dashboard.viz.actionList.title', t),
      applyOnData: (data, editedData) => {
        const actions = [editedData.action1, editedData.action2, editedData.action3];
        return _.filter(actions, (action) => {
          return !_.isUndefined(action) && !_.isEmpty(_.trim(action));
        });
      },
      focusOnMount: true
    }
  }
);
