import axios from 'axios';
import { TenantInfoDTO } from '../../../administration/model';

const getTenant = async ():Promise<TenantInfoDTO> => {
  return axios
    .get('/api/tenant', {
      headers: { 'Accept': 'application/json'  }
    })
    .then(response => response.data);
};

const getTenants = async (): Promise<Array<TenantInfoDTO>> => {
  return axios
    .get('/api/tenant/list', {
      headers: { 'Accept': 'application/json'  }
    })
    .then(response => response.data);
};

export default { getTenant, getTenants };
