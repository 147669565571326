import * as React from "react";
import _ from "lodash";

type ReactChildArray = ReturnType<typeof React.Children.toArray>;

export const flattenReactChildren = (children: React.ReactNode): ReactChildArray => {
  let result: ReactChildArray = [];

  React.Children.toArray(children).forEach(child => {
    if ((child as React.ReactElement).type === React.Fragment) {
      result = result.concat(flattenReactChildren((child as React.ReactElement).props.children));
    } else {
      result.push(child);
    }
  });

  return result;
};


export interface ShallowEqualConf {
  allowArrays?: boolean,
  allowEquals?: boolean
}


export const is = (x, y, allowEquals: boolean, allowArrays): boolean => {
  // same instance
  if (x === y) {
    // make sure that +0 != -0
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    if (allowArrays && _.isArray(x) && _.isArray(y)) {
      return shallowEqual(x, y, { allowEquals, allowArrays: false })
    }
    if (allowEquals && typeof x.equals === 'function') {
      return x.equals(y);
    }

    // Make sure that NaN == NaN
    return x !== x && y !== y;
  }
}

export const shallowEqual = (objA: any, objB: any, conf?: ShallowEqualConf) => {
  const allowEquals = conf?.allowEquals || false;
  const allowArrays = conf?.allowArrays || false;

  if (is(objA, objB, allowEquals, allowArrays)) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]], allowEquals, allowArrays)) {
      return false;
    }
  }

  return true;
}

export const shallowCompare = (instance, nextProps, nextState, conf?: ShallowEqualConf) => {
  return (
    !shallowEqual(instance.props, nextProps, conf) ||
    !shallowEqual(instance.state, nextState, conf)
  );
}