import { Predicate } from './Predicate';
import _ from "lodash";

export class Not<T> extends Predicate<T> {
  private readonly predicate:Predicate<T>;

  constructor(predicate:Predicate<T>) {
    super();
    if (_.isNil(predicate)) {
      throw new Error('Given predicate should not be nil');
    }
    if (!(predicate instanceof Predicate)) {
      throw new Error('Given predicate should be a Predicate');
    }
    this.predicate = predicate;
  }

  match(object:T):boolean {
    return !this.predicate.match(object);
  }

  message():string {
    return `not ${this.predicate}`;
  }
}