import React from 'react';
import { VisualizationInstance } from '../../../model/viz/VisualizationInstance';
import './metricViz.scss';
import { EnhancedMetric } from "../../../model/metric/EnhancedMetric";
import { parseMetric } from "../../../model/metric/parseMetric";
import { withTranslation, WithTranslation } from "react-i18next";
import _ from "lodash";
import { Trend } from "../../components/trend/Trend";
import { connect } from "react-redux";
import { ClickableViz } from "../ClickableViz";
import { FieldVizFeedbacksQueryContext } from "../../../model/viz/VizFeedbacksQueryContext";
import { FieldValue } from "../../../../application/model/field/FieldValue";
import { visualizationTypes } from "../../../model/viz/visualizationTypes";

interface MetricVizProps extends WithTranslation {
  viz: VisualizationInstance,
  openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => void
}


class MetricVizComponent extends React.PureComponent<MetricVizProps> implements ClickableViz {

  getFeedBacksQueryContext() {
    const result = new FieldVizFeedbacksQueryContext();
    const { viz } = this.props;
    result.field = viz.definition.params.field;
    return result;
  }

  getClickedFieldValue(): FieldValue {
    return null;
  }

  render() {
    const { t, viz } = this.props;

    const metric: EnhancedMetric = parseMetric(viz.definition.params.metric);
    const defaultValueUnit = metric.defaultUnitLabel(t);
    const supportPercentage = metric.supportPercentage();

    const uiParams = viz.definition.uiParams;

    const value = {
      value: viz.data.value.value,
      trend: viz.data.trend?.value,
      unit: `${_.isNil(uiParams.valueUnit) ? defaultValueUnit : uiParams.valueUnit}`
    };
    const percentage = {
      value: viz.data.value.percentage,
      trend: viz.data.trend ? viz.data.trend.percentage : null,
      unit: '%'
    };

    const main = supportPercentage && uiParams.percentageMode ? percentage : value;
    const secondary = supportPercentage && uiParams.percentageMode ? value : percentage;

    const showTrend = uiParams.showTrend;

    const mainFontSize = uiParams.mainFont.size;
    const mainUnitFontSize = Math.round(mainFontSize * 2 / 3);
    const trendFontSize = uiParams.trendFont.size;
    const trendUnitFontSize = Math.round(trendFontSize * 3 / 4);

    return <div className="metricViz">
      <div className="metricViz-main">
        <div className="metricViz-main-inner">
          <div className="metricViz-main-value" style={{ fontSize: mainFontSize, color: uiParams.mainFont.color }}
               onClick={(event) => {
                 const title = visualizationTypes.get('METRIC').title(viz, t);
                 this.props.openFeedbacksPanel(this, title);
                 event.stopPropagation();
                 event.preventDefault();
               }}>
            {main.value}&nbsp;<span style={{ fontSize: mainUnitFontSize }}>{main.unit}</span>
          </div>
          {
            showTrend && main.trend ? <div className="metricViz-main-trend">
              <Trend value={main.trend} iconSize={trendFontSize} labelColor={uiParams.trendFont.color}
                     labelFontSize={trendUnitFontSize}/>
            </div> : null
          }
        </div>
      </div>
      {
        supportPercentage && uiParams.showSecondary ?
          <div className="metricViz-secondary"
               style={{ fontSize: uiParams.secondaryFont.size, color: uiParams.secondaryFont.color }}>
            {secondary.value}&nbsp;{secondary.unit}
          </div> : null
      }
    </div>;
  }

}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => {
      dispatch.dashboard.openFeedBackPanel({ viz, panelTitle });
    },
  };
};

export const MetricViz = withTranslation(undefined, { withRef: true })(connect(null, mapDispatchToProps, null, {forwardRef: true})(MetricVizComponent));
