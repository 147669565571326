import { DateTime, DateTimeFormatOptions } from 'luxon';
import React from "react";
import _ from "lodash";

interface DateLabelProps {
  date: number | DateTime | Date | string,
  dateFormat?: DateTimeFormatOptions | string,
}

export class DateLabel extends React.PureComponent<DateLabelProps> {
  static defaultProps: Partial<DateLabelProps> = {
    dateFormat: DateTime.DATE_FULL
  };

  render() {
    const { date, dateFormat } = this.props;
    let datetime: DateTime;

    if (_.isNil(date)) return null;

    if (date instanceof DateTime) {
      datetime = date;
    } else if (date instanceof Date) {
      datetime = DateTime.fromJSDate(date);
    } else if (typeof date === 'number')  {
      datetime = DateTime.fromMillis(date)
    } else if (typeof date === 'string')  {
      datetime = DateTime.fromISO(date)
    }


    if (datetime && datetime.isValid) {
      if (typeof dateFormat !== 'string') {
        return datetime.toLocaleString(dateFormat);
      } else {
        return datetime.toFormat(dateFormat);
      }
    }
    return 'Invalid Date';
  }
}




