import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { MetricSelector } from '../../../../component/selector/MetricSelector';
import { Metric } from '../../../model/MetricEnum';
import { SortEditor, SortItem } from './SortEditor';

interface MetricSortEditorProps extends WithTranslation {
  className?: string,
  metrics: Array<Metric>,
  items: Array<SortItem<Metric>>,
  onChanged: (items: Array<SortItem<Metric>>) => void,
  annihilateEnterKey?: boolean
}

class MetricSortEditorComponent extends React.PureComponent<MetricSortEditorProps> {

  keyId = (key: Metric): string => {
    return key.name;
  };

  keyLabel = (key: Metric): string => {
    const { t } = this.props;
    return key.getLabel(t);
  };

  keySelector = (keys, selectedKey, onSelectionChanged) => {
    return <MetricSelector items={keys}
                           selectedItem={selectedKey}
                           onSelectionChanged={onSelectionChanged}
                           allowEmptySelection={false}/>;
  };

  render() {
    const { className, metrics, items, onChanged, annihilateEnterKey } = this.props;
    return <SortEditor className={className}
                       keys={metrics}
                       items={items}
                       onChanged={onChanged}
                       keyId={this.keyId}
                       keyLabel={this.keyLabel}
                       keySelector={this.keySelector.bind(this)}
                       annihilateEnterKey={annihilateEnterKey}
    />;
  }
}

export const MetricSortEditor = withTranslation()(MetricSortEditorComponent);
