import { services } from '../../application/service/services';
import { DateTime } from "luxon";
import { dateFormat } from "../../utils/dateUtils";

export class PurgeService {
  _purgeFetcher;

  init() {
    this._purgeFetcher = services.getFetcherService().getFetcher('purge');
  }

  isAuthorized(): boolean {
    return this.isPurgeAllAuthorized() || this.isPurgeErrorsAuthorized() || this.isPurgeByDateAuthorized() || this.isPurgeByRetentionAuthorized() || this.isPurgeByConfigurationAuthorized();
  }

  isPurgeAllAuthorized(): boolean {
    return services.getSecurityService().hasRole('vecko.feedback.purge');
  }

  isPurgeErrorsAuthorized(): boolean {
    return services.getSecurityService().hasRole('vecko.errors.purge');
  }

  isPurgeByDateAuthorized(): boolean {
    return services.getSecurityService().hasRole('vecko.feedback.purge');
  }

  isPurgeByRetentionAuthorized(): boolean {
    return services.getSecurityService().hasRole('vecko.feedback.purge');
  }

  isPurgeByConfigurationAuthorized(): boolean {
    return services.getSecurityService().hasRole('vecko.feedback.purge');
  }

  async purgeAll() {
    await this._purgeFetcher.purgeAll();
    this.displaySuccessPurgeMsg("all");
  }

  async purgeErrors() {
    await this._purgeFetcher.purgeErrors();
    this.displaySuccessPurgeMsg("errors");
  }

  async purgeByDate(date: DateTime) {
    await this._purgeFetcher.purgeByDate({ date: date.toFormat(dateFormat) });
    this.displaySuccessPurgeMsg("by_date");
  }

  async purgeByConfiguration() {
    await this._purgeFetcher.purgeByConfiguration();
    this.displaySuccessPurgeMsg("by_configuration");
  }

  async purgeByDuration(duration) {
    await this._purgeFetcher.purgeByDuration({ "@type": "duration", "duration" : "P"+duration.nbYears+"Y"+duration.nbMonths+"M"+duration.nbDays+"D" });
    this.displaySuccessPurgeMsg("by_duration");
  }

  private displaySuccessPurgeMsg(operation: string) {
    services.getApplicationService().notifyMessage({
      level: 'SUCCESS',
      text: {
        key: 'administration.system.purge.'+operation+'.request.success',
      }
    });
  }
}

services.registerService('purgeService', new PurgeService());