import * as React from 'react';
import { ReactNode } from 'react';
import { MapEditor } from '../../../../component/MapEditor';
import { SortOrderSelector } from '../../../../component/selector/SortOrderSelector';
import { SortOrder, SortOrderEnum } from '../../../../utils/query/SortOrder';

export interface SortItem<Key> {
    key: Key,
    order: SortOrder
}

interface SortEditorProps<Key> {
    className?: string,
    keys: Array<Key>,
    items: Array<SortItem<Key>>,
    keyId: (key: Key) => string,
    keyLabel: (key: Key) => string,
    keySelector: (keys: Array<Key>, selectedKey: Key, onSelectionChanged: (selection: Key) => void) => ReactNode,
    onChanged: (items: Array<SortItem<Key>>) => void,
    disabled?: boolean
    annihilateEnterKey?: boolean
}

export class SortEditor<Key> extends React.PureComponent<SortEditorProps<Key>> {
    static defaultProps:Partial<SortEditorProps<any>> = {
        disabled: false
    }

    itemKeyGetter = (item:SortItem<Key>):Key => {
        return item.key;
    };

    itemValueGetter = (item:SortItem<Key>):SortOrder => {
        return item.order;
    };

    itemEditor = (item:SortItem<Key>, key:Key, onChanged:(order:SortOrder) => void) => {
        return <SortOrderSelector items={SortOrderEnum.values}
                                  selectedItem={item.order}
                                  onSelectionChanged={onChanged}
                                  allowEmptySelection={false}
                                  disabled={this.props.disabled}
        />;
    };

    createItem = (key:Key, itemEditorOutput: SortOrder) => {
        return {
            key: key,
            order: itemEditorOutput
        };
    };

    createEmptyItem = (key:Key) => {
        return {
            key: key,
            order: SortOrderEnum.DESC
        };
    };

    render() {
        const { className, keys, items, onChanged, keyId, keyLabel, keySelector, disabled, annihilateEnterKey } = this.props;

        return <MapEditor className={className} keys={keys} items={items}
                          itemKeyGetter={this.itemKeyGetter}
                          itemValueGetter={this.itemValueGetter}
                          keyId={keyId}
                          keyLabel={keyLabel}
                          keySelector={keySelector}
                          itemEditor={this.itemEditor}
                          createItem={this.createItem}
                          createEmptyItem={this.createEmptyItem}
                          onChanged={onChanged}
                          enableItemOrdering={true}
                          fill={false}
                          disabled={disabled}
                          annihilateEnterKey={annihilateEnterKey}
        />;
    }
}
