import { Button, Checkbox, H4 } from '@blueprintjs/core';
import React, { Fragment } from 'react';
import { PaginatedList } from '../../../component/pagination/PaginatedList';
import { FeedbackForReport } from '../../../feedback/FeedbackForReport';
import './feedbacksSelector.scss';
import { Header } from "../../../component/Header";
import classnames from 'classnames';
import { WithTranslation, withTranslation } from "react-i18next";
import { ListEditor } from "../../../component/ListEditor";
import { FeedbackDTO } from "../../../application/model";

interface FeedbackToggleProps {
  id: string,
  selected?: boolean,
  feedback: FeedbackDTO,
  onClick: (event) => void,
  cross: boolean
}

const FeedbackToggle = (props: FeedbackToggleProps) => {
  const onClickBindedFn = props.onClick.bind(this, props.feedback);
  let onclickFn = (event) =>{
    onClickBindedFn();
    event.stopPropagation();
    event.preventDefault();
  }

  return <div className="feedbacksSelector-toggle" onClick={!props.cross ? onclickFn : null}>
    <div className="feedbacksSelector-toggle-left">
      {
        props.cross ?
          <Button icon='cross' onClick={onclickFn} minimal={true}/> :
          <Checkbox checked={props.selected} value={props.id} onChange={event => {
            //No need to call onclick as it will be call by div.onclick
          }}/>
      }
    </div>
    <div className="feedbacksSelector-toggle-right">
      <FeedbackForReport feedback={props.feedback} condensed={false}/>
    </div>
  </div>;
};

interface FeedbacksSelectorProps extends WithTranslation {
  fetchingData?: any,
  items?: any,
  pagination?: any,
  onSelectFeedback?: (items: FeedbackDTO[]) => void,
  className?: any,
  onPagerClick?: any,
  selectedFeedbacks: FeedbackDTO[],
  limit: number
}

interface StateProps {
  selectedItems: any[]
}

class FeedbacksSelectorComponent extends React.PureComponent<FeedbacksSelectorProps> {

  renderFeedback = (feedback) => {
    const { onSelectFeedback } = this.props;

    return <FeedbackToggle feedback={feedback}
                           id={feedback.id}
                           selected={this.props.selectedFeedbacks.map(feedback => feedback.id).indexOf(feedback.id) >= 0}
                           onClick={(item) => {
                             const itemIndex = this.props.selectedFeedbacks.findIndex(value => {
                               //2 feedbacksDTO are equals if id and date are equals
                               return value.id === item.id && value.date === item.date
                             });
                             if (itemIndex < 0) {
                               if (!this.props.limit || this.props.selectedFeedbacks.length < this.props.limit) {
                                 const updatedSelectedFeedbacks = [...this.props.selectedFeedbacks];
                                 updatedSelectedFeedbacks.push(item);
                                 onSelectFeedback(updatedSelectedFeedbacks);
                                 this.forceUpdate();
                               }
                             } else {
                               let updatedSelectedFeedbacks = [...this.props.selectedFeedbacks];
                               updatedSelectedFeedbacks.splice(itemIndex, 1);
                               onSelectFeedback(updatedSelectedFeedbacks);
                               this.forceUpdate();

                             }
                           }}
                           cross={false}/>;
  };

  renderSelectedFeedback = (feedback) => {
    const { onSelectFeedback } = this.props;

    return <FeedbackForReport feedback={feedback} condensed={false}/>
  }

  feedbackId = (feedback) => {
    return feedback.id;
  };

  render() {
    const { fetchingData, items, pagination, t, className, limit } = this.props;

    const selectedFeedbackTitleElement = <Fragment>
      <H4 className='no-margin'>{t('feedbacksSelector.selected')}</H4>
      {
        (this.props.limit && this.props.selectedFeedbacks.length >= this.props.limit) ?
          <span className='no-margin' style={{ marginLeft: 15 }}>{t('feedbacksSelector.limitReached')}</span> : null
      }
    </Fragment>;

    return <div className={classnames(className, 'feedbacksSelector')}>
      <div className='feedbacks-list vui-layout--vertical vui-layout--vertical__center'>
        <Header className='header' title={<H4 className='no-margin'>{t('feedbacksSelector.all')}</H4>}></Header>
        <PaginatedList
          items={items}
          renderItem={this.renderFeedback}
          itemId={this.feedbackId}
          isLoading={fetchingData}
          paginationProps={pagination}
        />
      </div>
      <div className='selected-list vui-layout--vertical vui-layout--vertical__center'>
        <Header className='header' title={selectedFeedbackTitleElement}></Header>
        <ListEditor items={this.props.selectedFeedbacks}
                    itemId={this.feedbackId}
                    renderItem={this.renderSelectedFeedback}
                    onChanged={(updatedItems => {
                      this.props.onSelectFeedback(updatedItems)
                    })}
                    enableItemOrdering={true}
                    style={{ boxShadow: 'none' }}
                    renderItemSeparator={true}
        />
      </div>
    </div>;
  }
}


export const FeedbacksSelector = withTranslation()(FeedbacksSelectorComponent);
