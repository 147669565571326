import { services } from '../../application/service/services';

export const administrationState = () => ({
  state: {
    view: services.getAdministrationService().getDefaultViewName()
  },
  reducers: {
    '@@router/LOCATION_CHANGE'(state, payload) {
      const view = services.getAdministrationService().getViewByUrlPath(payload.location.pathname);
      if (view && view.isAuthorized()) {
        state.view = view.name;
      } else {
        state.view = null;
      }

      return state;
    }
  },
  effects: {

  }
});