
import { ParamValue } from './ParamValue';
import { TFunction } from "i18next";
import _ from "lodash";
import { DateRange } from "../../../../application/model/DateRange";

export class DateRangeParamValue extends ParamValue {
  static parse(object: any): DateRangeParamValue {
    return new DateRangeParamValue(DateRange.parse(object));
  }

  readonly dateRange: DateRange;

  constructor(dateRange: DateRange) {
    super('dateRange');
    this.dateRange = dateRange;
  }

  getId(): string {
    return this.dateRange.toString();
  }

  getLabel(t:TFunction): string {
    return this.dateRange.toString();
  }

  getValueAsPlainObject(): any {
    return this.dateRange.toPlainObject();
  }

  asPlainObject(): any {
    return {
      ... super.asPlainObject(),
      ... this.dateRange.toPlainObject()
    };
  }

  isValid(): boolean {
    return !_.isNil(this.dateRange) ;
  }

  equals(paramValue: DateRangeParamValue): boolean {
    return paramValue instanceof DateRangeParamValue && this.dateRange.equals(paramValue.dateRange);
  }
}