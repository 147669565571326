import { Spinner } from '@blueprintjs/core';
import React from 'react';
import { connect } from 'react-redux';
import './dashboardView.scss';
import { exportedDashboard } from "../../application/fetch/mock/data/export";
import { services } from "../../application/service/services";
import { DateRange } from "../../application/model/DateRange";
import { getResolvedParamsFromState } from "../state/dashboardParamsManager";
import { ResolvedParams } from "../model/params/resolve/ResolvedParams";
import { DashboardPanel } from "./DashboardPanel";
import { withTranslation, WithTranslation } from "react-i18next";
import { SharedFilterNames } from "../../application/service/filterService";


interface DashboardExportComponentProps extends WithTranslation {
  applyingParams: any,
  hasData: boolean
  dashboard: any,
  init: (dashboardParam: any) => void
}

class DashboardExportComponent extends React.PureComponent<DashboardExportComponentProps> {
  constructor(props) {
    super(props);
    window['setDashboardConfigAndData'] = (data) => {
      let dashboardParam = data ? data : exportedDashboard;
      this.props.init(dashboardParam);
    }
    //To automatically load test data
    //setTimeout(window['setDashboardConfigAndData'](), 1000)
  }

  render() {
    const { hasData, dashboard, t } = this.props;
    let title = null;
    let parameters = [];
    const dashboardDefinitions = null;
    if (dashboard) {
      let dashboardDefinitions = services.getDashboardService().dashboardDefinitions.filter(dashboardDefinition => dashboardDefinition.id === dashboard.id);
      if (dashboardDefinitions.length > 0) {
        title = dashboardDefinitions[0].getLabel(t);
        parameters = dashboardDefinitions[0].parameters;
      }
    }
    return !hasData || !dashboard ? <Spinner/> :
      <DashboardPanel label={title} dashboard={dashboard}/>;
  }
}

const mapStateToProps = (state) => {
  return {
    hasData: state.dashboardData.hasData,
    dashboard: state.dashboardExport.dashboard,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    init: async (dashboardParam: any) => {
      const dashboardId = dashboardParam.dashboard.id;
      const dateRange = DateRange.parse(dashboardParam.filter.dateRange);

      /* init service with config. Order is important */
      services.getConfigurationService().initWithConfig({
        FIELDS: JSON.stringify(dashboardParam.fields),
        UI: JSON.stringify(dashboardParam.uiConfig)
      });
      services.getDashboardService().init();
      await services.getFieldsService().init({
        getFieldValues: async () => {
          return dashboardParam.fieldsValue;
        }
      });
      await services.getFilterService().init();
      services.getFilterService().setValue(SharedFilterNames.DATE_RANGE, dateRange);

      /* build resolved param & resolved spec */
      const parsedResolvedParam = ResolvedParams.parse(dashboardParam.dashboard.resolvedParams, services.getDashboardService().getDashboardDefinition(dashboardId).parametersMap);
      const dashboardResolvedParams = getResolvedParamsFromState(dashboardId, parsedResolvedParam.getParamValuesByParamName());
      const resolvedSpec = services.getDashboardService().resolveDashboardSpec(dashboardId, dashboardResolvedParams);

      /* fill state */
      dispatch.dashboard.setId(dashboardId);
      dispatch.dashboard.setResolvedParams(dashboardResolvedParams.asPlainObject());
      dispatch.dashboard.setResolvedSpec(resolvedSpec);
      dispatch.dashboardParams.setParamValues(parsedResolvedParam);
      dispatch.dashboardData.setData(dashboardParam.dashboard.data);

      Object.entries(dashboardParam.dashboardVizEditor.validatedData).forEach(([key, value]) => {
        dispatch.dashboardVizEditor.setCurrentViz({ vizId: key, dataInEdition: value });
        dispatch.dashboardVizEditor.setValidatedData();
      });
      dispatch.filter.setValue({ dateRange: dateRange })

      /* Build dashboard */
      dispatch.dashboardExport.buildDashboard();

    }
  };
}

export const DashboardExport = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DashboardExportComponent));
