import _ from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { services } from '../../../application/service/services';
import { Dashboard } from '../../model/dashboard/Dashboard';
import { ResolvedParams } from '../../model/params/resolve/ResolvedParams';

const getDashboardId = (state) => {
  return state?.dashboard?.id;
};
const getDashboardSpec = (state) => {
  return state?.dashboard?.resolvedSpec;
};
const getDashboardData = (state) => {
  return state?.dashboardData?.payload;
};
const getVizEditorData = (state) => {
  return state?.dashboardVizEditor?.validatedData;
};
const getCurrentEditedViz = (state) => {
  return state?.dashboardVizEditor?.vizId;
};
const getDashboardResolvedParamsState = (state) => {
  return state?.dashboard?.resolvedParams;
};

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  _.isEqual
);

export const getDashboardResolvedParams = createDeepEqualSelector(
  [getDashboardId, getDashboardResolvedParamsState],
  (dashboardId, dashboardParams) => {
    if (_.isNil(dashboardId)) {
      return undefined;
    }
    if (_.isNil(dashboardParams)) {
      return undefined;
    }

    return ResolvedParams.parse(dashboardParams, services.getDashboardService().getDashboardDefinition(dashboardId).parametersMap)
  }
)

export const getDashboard = createDeepEqualSelector(
  [getDashboardId, getDashboardResolvedParams, getDashboardSpec, getDashboardData, getVizEditorData],
  (dashboardId, dashboardResolvedParams, dashboardSpec, dashboardData, vizEditorData) => {
    if (dashboardId && dashboardResolvedParams && dashboardSpec && dashboardData) {
      //reset colorservice
      services.getColorService().reset();
      const dashboard = Dashboard.create(dashboardId, dashboardResolvedParams, dashboardSpec, dashboardData);

      //build colorservice
      services.getColorService().build();
      if (vizEditorData) {
        Object.entries(vizEditorData)
          .forEach(([vizId, d]) => {
            dashboard.getVizById(vizId)?.applyEdition(d);
          });
      }

      return dashboard;
    }
    return undefined;
  }
);


/**
 * @callback getEditedVizCallback
 * @param  {object} rootState
 * @return  {VisualizationInstance} viz instance
 */
/**
 * @type {getEditedVizCallback}
 */
export const getEditedViz = createDeepEqualSelector(
  [getDashboard, getCurrentEditedViz],
  (dashboard, currentEditedViz) => {
    if (_.isUndefined(currentEditedViz)) {
      return undefined;
    }

    return dashboard.getVizById(currentEditedViz);
  }
);