import React from 'react';
import PropTypes from 'prop-types';
import './metric.scss';
import { useTranslation } from 'react-i18next';


interface MetricProp {
  value: number,
  unit?: string
};

export function Metric(props:MetricProp) {
  const { t } = useTranslation();
  let unit = <></>;
  if (props.unit) {
    unit = <div className="metric_unit">{t(props.unit, {count: props.value})}</div>;
  }

  return <div className="metric">
    <div className="metric_value">{props.value}</div>
    {unit}
  </div>
    ;
}

