import _ from 'lodash';
import { feedbacksListState } from '../../../../application/store/state/tools/feedbacksListState';
import { SatAspect } from '../../../../utils/query/aspect/SatAspect';
import { SatTypeAspect } from '../../../../utils/query/aspect/SatTypeAspect';
import { Highlight } from '../../../../utils/query/Highlight';
import { getEditedViz } from '../../../state/selectors/dashboardSelector';
import { BoolFilter } from "../../../../utils/query/filter/BoolFilter";
import { FieldName } from "../../../../application/model/field/Field";
import { TermsFilter } from "../../../../utils/query/filter/TermsFilter";
import { FieldValue } from "../../../../application/model/field/FieldValue";
import { fieldsConfiguration } from "../../../../application/fetch/mock/configurations/fieldsConfiguration";
import { parseFilter } from "../../../../utils/query/filter/parseFilter";

//---

const restoreInternalState = (namespace, state) => {
  const storedInternalState = _.get(state, ['internalState', namespace]) || {};
  state.page = storedInternalState.page || 1;
};

const saveInternalState = (namespace, state) => {
  _.set(state, ['internalState', namespace], {
    page: state.page
  });
};

//---

export const customerCommentEditorStateName = 'customerCommentEditor';

export const stateSlice = (rootState) => {
  return rootState[customerCommentEditorStateName];
};

export const dispatchSlice = (dispatch) => {
  return dispatch[customerCommentEditorStateName];
};

const fls = feedbacksListState(customerCommentEditorStateName, null, {
  queryBuilderDecorator(query, rootState) {
    const viz = getEditedViz(rootState);
    const vizParams = viz.definition.params;

    query.addField(viz.definition.params.headerFields);
    query.addAspect(new SatTypeAspect(vizParams.satType));
    const topics:Array<FieldValue> = stateSlice(rootState).topics;
    if (topics && topics.length > 0) {
      query.filter = BoolFilter.must(query.filter, new TermsFilter(FieldName.categoryTree('topic'), topics.map(value => value.fullName)));
    }
    if(viz.definition.params.constraint) {
      query.filter = BoolFilter.must(query.filter, parseFilter(viz.definition.params.constraint));
    }
    query.addAspect(new SatAspect(vizParams.satType, vizParams.sat));
    query.highlight = new Highlight(true, ['topic']);

    return query;
  },
  includeGlobalFilter: true,
  namespaced: true,
  afterPageChange(payload, rootState, dispatch) {
    dispatch.saveInternalState(payload);
  }
});


export const customerCommentEditorState = () => ({
  state: { ...fls.state, topics: [] },
  reducers: {
    reset(state, payload) {
      state.page = 1;
      delete state.internalState;
      return state;
    },
    restoreInternalState(state, payload) {
      restoreInternalState(payload.namespace, state);
      return state;
    },
    saveInternalState(state, payload) {
      saveInternalState(payload.namespace, state);
      return state;
    },
    setTopics(state, payload) {
      state.topics = payload;
      return state;
    },
    ...fls.reducers
  },
  effects: (dispatch) => ({
    async init(payload, rootState) {
      dispatchSlice(dispatch).restoreInternalState(payload);

      const viz = getEditedViz(rootState);

      await dispatchSlice(dispatch).fetchFeedbacks();
      dispatch.dashboardVizEditor.setEditValue({ kind: 'data', key: 'feedbacks', value: viz.data.feedbacks });
      dispatch.dashboardVizEditor.setEditValue({ kind: 'params', key: 'headerFields', value: viz.definition.params.headerFields });
    },
    async changeTopics(payload, rootState) {
      dispatchSlice(dispatch).setTopics(payload);
    },
    ...fls.effects(dispatch)
  })
});
