import {Enum, EnumValue } from '../enum';

export class SortOrder extends EnumValue {
  private _icon: string;
  constructor(name, icon) {
    super(name);
    this._icon = icon
  }

  getLabel(t) {
    return t(`sortOrder.${this.name}`);
  }

  get icon():string {
    return this._icon;
  }
}

class SortOrderEnumType extends Enum<SortOrder> {
  ASC = new SortOrder('ASC', 'sort-asc');
  DESC = new SortOrder('DESC', 'sort-desc');

  constructor() {
    super();
    this.initEnum('SortOrder');
  }
}

export const SortOrderEnum = new SortOrderEnumType();