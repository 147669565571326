import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { SortOrder } from '../../utils/query/SortOrder';
import { SmartIcon } from '../icon/SmartIcon';
import { SelectInput } from '../select/SelectInput';

class SortOrderSelectorComponent extends PureComponent {
  getId = (sortOrder) => {
    return sortOrder.name;
  };

  getLabel = (sortOrder) => {
    const { t } = this.props;
    return sortOrder.getLabel(t);
  };

  getIcon = (sortOrder) => {
    if (!sortOrder) {
      return null;
    }
    return <SmartIcon icon={sortOrder.icon} />;
  };

  render() {
    const { t, disabled } = this.props;

    return <SelectInput itemLabel={this.getLabel}
                        itemId={this.getId}
                        itemIcon={this.getIcon}
                        items={this.props.items}
                        selectedItem={this.props.selectedItem}
                        onSelectionChanged={this.props.onSelectionChanged}
                        allowEmptySelection={this.props.allowEmptySelection}
                        placeholder={t('sortOrder')}
                        disabled={disabled}
    />;
  }
}

export const SortOrderSelector = withTranslation()(SortOrderSelectorComponent);

SortOrderSelector.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(SortOrder)),
  selectedItem: PropTypes.instanceOf(SortOrder),
  onSelectionChanged: PropTypes.func,
  allowEmptySelection: PropTypes.bool,
  disabled: PropTypes.bool
};

SortOrderSelector.defaultProps = {
  items: [],
  selectedItem: null,
  allowEmptySelection: false,
  disabled: false
};