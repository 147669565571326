import { Tonality } from '@eptica/vecko-js-commons/lib/satisfaction/Tonality';
import _ from 'lodash';
import React from 'react';
import { SatTypeEnum } from '../application/model/SatTypeEnum';
import { BubbleMark } from '../report/view/components/bubbleMark';
import { FeedbackDTO } from "../application/model";

interface SatScoreProps {
  feedback: FeedbackDTO,
  size?: number,
  color?: string,
}

export const SatScore = ({feedback, size, color}: SatScoreProps) => {
  if (_.isNil(feedback.satScore)) {
    return null;
  }

  const satType = feedback.satType ? SatTypeEnum.valueOf(feedback.satType) : SatTypeEnum.NPS;
  const tonality = feedback.satMood ? Tonality.getValueOf(feedback.satMood) : Tonality.NEUTRAL;

  const bgColor = _.isNil(color) ? tonality.color : color;

  return <BubbleMark size={size} mark={feedback.satScore} unit={satType.scoreMax} backgroundColor={bgColor}/>;
};